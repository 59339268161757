import React, { useMemo } from 'react';
import { RouteProps, useLocation, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';

import { AuthenticationLayout } from 'layouts/Authentication';
import { PATH } from '../constants/routes';
import { languageSelector } from 'state/recoilState';

export const AuthenticationSwitch = ({ children }: RouteProps): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const { pathname } = useLocation();

  const pathsWithFooter = useMemo(
    () => [
      PATH.SIGN_IN[language],
      PATH.SIGN_UP[language],
      PATH.PASS_RESET[language],
      PATH.PASS_RESET__CONFIRMED[language],
    ],
    [language],
  );

  const showFooter = useMemo(
    () => _.filter(pathsWithFooter, (path) => path === pathname).length > 0,
    [pathname, pathsWithFooter],
  );

  return (
    <AuthenticationLayout showFooter={showFooter}>
      <Switch>{children}</Switch>
    </AuthenticationLayout>
  );
};
