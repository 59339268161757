import React, { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useSetRecoilState } from 'recoil';
import { Flex, Heading } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useTimer } from 'use-timer';
import { RegisterOptions, useForm } from 'react-hook-form';
import _ from 'lodash';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import {
  FetchOrganizationSettingsResponse,
  OrganizationHolidaysSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { organizationHolidaysSettingsAction } from 'api/actions/settings/settingsActions';
import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { mergeRefs } from 'utils/mergeRefs';
import { Switch } from 'components/ui/Switch';
import { createEvent } from 'utils/createEvent';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';
import { addSnackbar } from 'SnackbarHub/actions';

type Props = {
  payload: OrganizationHolidaysSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  organizationHolidaysShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  organizationHolidaysFormPendingRef: React.MutableRefObject<boolean>;
  organizationHolidaysStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

type keys = keyof OrganizationHolidaysSettingsActionProps;

export const Holidays = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      organizationHolidaysShouldBlockTransitionRef,
      organizationHolidaysFormPendingRef,
      organizationHolidaysStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchOrganizationSettingsResponse>(payloadSelectorFamily('ORGANIZATION'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const { mutate } = useMutation(organizationHolidaysSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<OrganizationHolidaysSettingsActionProps>(payload);

    const {
      register,
      handleSubmit,
      reset: resetForm,
    } = useForm({ defaultValues: payload, mode: 'onTouched', reValidateMode: 'onChange' });

    const dispatchSubmitEvent = () => {
      const submitEvent = createEvent('submit');
      organizationHolidaysFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    };

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnChange = () => {
      organizationHolidaysShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    };

    const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          handleOnChange();
        },
      };
    };

    const handleFormReset = useCallback(() => {
      resetForm({ ...payload });
      organizationHolidaysShouldBlockTransitionRef.current = false;
      organizationHolidaysFormPendingRef.current = false;
    }, [organizationHolidaysFormPendingRef, organizationHolidaysShouldBlockTransitionRef, payload, resetForm]);

    const handleSubmitCallback = useCallback(
      async (data: OrganizationHolidaysSettingsActionProps) => {
        const organizationHolidaysResetObject = { name: 'ORGANIZATION_HOLIDAYS_CALLBACK', callback: handleFormReset };
        reset();

        if (!_.isEqual(data, payload)) {
          const { error } = await mutate(data);

          if (!error) {
            setResetCallbacks((prevState) => {
              if (prevState) {
                const newState = _.reject(prevState, (item) => item.name === organizationHolidaysResetObject.name);
                return newState.length ? newState : null;
              }
              return prevState;
            });
            setPayload((prevPayload) => ({ ...prevPayload, ...data }));
            mutationDataRef.current = data;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [organizationHolidaysResetObject] : [...prevState, organizationHolidaysResetObject],
            );
            return;
          }
        }

        organizationHolidaysFormPendingRef.current = false;
        organizationHolidaysShouldBlockTransitionRef.current = false;
        setTransitionPage(true);
      },
      [
        handleFormReset,
        mutate,
        organizationHolidaysFormPendingRef,
        organizationHolidaysShouldBlockTransitionRef,
        payload,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payload)) handleFormReset();
    }, [handleFormReset, payload]);

    useEffect(() => {
      organizationHolidaysStatusRef.current = status;
    }, [organizationHolidaysStatusRef, status]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    return (
      <Flex sx={{ flexDirection: 'column', flexGrow: 1, gap: 2, maxWidth: '600px' }}>
        <Heading variant="heading4">
          <Trans id="organization_settings.heading.holidays">Holidays</Trans>
        </Heading>

        <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
          <Flex sx={{ flexDirection: 'column', flexGrow: 1, gap: '0.75rem' }}>
            <Switch
              withDivider
              {...registerOnChange('showHolidaysOnAllCalendars')}
              label={t({
                id: 'organization_settings.show_holidays_on_all_calendars.label',
                message: 'Show holidays on all calendars',
              })}
              size="sm"
              bold
            />
            <Switch
              withDivider
              {...registerOnChange('autoImportHolidaysEachYear')}
              label={t({
                id: 'organization_settings.auto_import_holidays_each_year.label',
                message: 'Auto-import the same holidays for each year',
              })}
              size="sm"
              bold
            />
          </Flex>
        </form>
      </Flex>
    );
  },
);

export const MemoizedHolidays = React.memo(Holidays);
