/** @jsxImportSource theme-ui */

import React, { useMemo } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { ThemeUIStyleObject, Text } from 'theme-ui';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
  standOut?: boolean;
  to?: LinkProps['to'];
  sx?: ThemeUIStyleObject;
} & Pick<LinkProps, 'replace'>;

const defaultProps: Partial<Props> = {
  onClick: undefined,
  standOut: undefined,
  sx: undefined,
  to: undefined,
};

export const LinkAnchor = ({ children, to, standOut, sx, ...props }: Props): React.ReactElement => {
  const linkSx: ThemeUIStyleObject = useMemo(
    () => ({
      transition: 'color ease-in-out .05s',
      color: standOut ? 'skyBlues5' : 'inherit',
      '&:hover': {
        color: `skyBlues${5 - (standOut ? 2 : 0)}`,
        cursor: 'pointer',
      },
      '&:active': {
        color: `skyBlues${5 - (standOut ? 1 : 0)}`,
      },
    }),
    [standOut],
  );

  if (props.href) {
    return (
      <a sx={{ ...linkSx, ...(sx && sx) }} {...(props && props)}>
        {children}
      </a>
    );
  }
  if (to) {
    return (
      <RouterLink sx={{ ...linkSx, ...(sx && sx) }} to={to || ''} {...(props && props)}>
        {children}
      </RouterLink>
    );
  }
  return (
    <Text sx={{ ...linkSx, ...(sx && sx) }} {...(props.onClick && { onClick: props.onClick })}>
      {children}
    </Text>
  );
};

LinkAnchor.defaultProps = defaultProps;
