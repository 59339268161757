export type Integration = {
  connectedAccount: string;
  type: ServiceIntegration;
  isExpired: boolean;
  isActive: boolean;
  imageUrl: string;
};

export type FetchIntegrationsResponse = Integration[];

export type ConnectDisconnectActionCommonProps = {
  integration: number;
};

export type ConnectIntegrationsPayrollActionProps = ConnectDisconnectActionCommonProps;

export type ConnectIntegrationsPayrollResponse = {
  a: string;
};

export type DisconnectIntegrationsPayrollActionProps = ConnectDisconnectActionCommonProps;

export type DisconnectIntegrationsPayrollResponse = {
  a: string;
};

export type FetchExternalProviderResponse = [
  {
    provider: ExternalLoginProvider;
    isActive: boolean;
    connectedName: string;
    imageUrl: string;
  },
];

export type GoogleCommonActionProps = {
  googleToken: string;
};

export type GoogleLinkActionProps = GoogleCommonActionProps;

export type GoogleLoginActionProps = GoogleCommonActionProps;

export type GoogleLinkResponse = {
  a: string;
};

export type GoogleLoginResponse = {
  accessToken: string;
  accountType: number;
};

export enum ServiceIntegration {
  Quickbooks = 0,
  SquarePayroll = 1,
  Optima = 2,
  SageSymfonia = 3,
  R2Platnik = 4,
  Gratyfikant = 5,
  Xero = 6,
  ZusEzla = 8,
  Webhook = 9,
}

export enum ExternalLoginProvider {
  Google = 0,
  GoogleInewi = 1,
  GoogleTrackTime = 2,
  AppleLinkInewi = 3,
  AppleLinkTrackTime = 4,
  AppleLoginInewi = 5,
  AppleLoginTrackTime = 6,
}
