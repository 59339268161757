import { useEffect } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { windowSizeAtom } from 'state/recoilState';
import { chatWidthSelector, chatWindowIdsAtom, removeChatWindowSelector } from 'state/chat';

export const ChatWidthObserver = (): null => {
  const { width } = useRecoilValue(windowSizeAtom);
  const chatWidth = useRecoilValue(chatWidthSelector);
  const chatWindowIds = useRecoilValue(chatWindowIdsAtom);

  const removeChatWindow = useRecoilCallback(({ set }) => () => {
    set(removeChatWindowSelector(chatWindowIds[0]), null);
  });

  useEffect(() => {
    if (width && chatWidth > width) {
      removeChatWindow();
    }
  }, [chatWidth, removeChatWindow, width]);

  return null;
};
