import React, { CSSProperties, useContext, useMemo } from 'react';
import { Flex } from 'theme-ui';
import _ from 'lodash';

import { GRID_DEFAULT_HEIGHT, ITEM_DEFAULT_HEIGHT, ITEM_DEFAULT_WIDTH } from './constants';
import { MemorizedFrozenColumnContainer } from './FrozenColumnsContainer';
import { MemorizedHeader } from './Header';
import { GridRendererProps, ListRendererProps } from './types';
import { gridRenderer } from './renderers/gridRenderer';
import { StickyListContext } from './context';
import { MemorizedContentPlaceholder } from './ContentPlaceholder';

type InnerElementProps = {
  children: {
    props: GridRendererProps & ListRendererProps;
  }[];
  style: CSSProperties;
};

export const StickyListInnerElement = React.forwardRef<HTMLDivElement, InnerElementProps>(
  ({ children, style }: InnerElementProps, ref) => {
    const {
      showHeader,
      showContentPlaceholder,
      columns,
      name: listName,
      select,
      variant,
      type,
      frozenColumns,
      list,
      itemCount,
      isRowUncheckableValidator,
    } = useContext(StickyListContext);

    const showHeaderStyle: InnerElementProps['style'] = useMemo(
      () => ({
        ...style,
        height: parseInt(`${style.height}`, 10) + (type === 'list' ? ITEM_DEFAULT_HEIGHT : GRID_DEFAULT_HEIGHT),
      }),
      [style, type],
    );

    const visibleRows = useMemo(() => _.uniq(_.map(children, (child) => child.props.rowIndex)), [children]);

    const rowStyles = useMemo(
      () =>
        _.map(visibleRows, (rowIndex) => _.find(children, (child) => child.props.rowIndex === rowIndex)?.props.style),
      [children, visibleRows],
    );

    const columnsFrozen = useMemo(
      () => (frozenColumns ? _.take(columns, frozenColumns) : undefined),
      [columns, frozenColumns],
    );
    const columnsNonFrozen = useMemo(
      () => (frozenColumns ? _.slice(columns, frozenColumns) : undefined),
      [columns, frozenColumns],
    );

    const stickyContainerWidth = useMemo(
      () =>
        _.reduce(
          _.map(columnsFrozen, (col) => parseInt(`${col.width}`, 10) || ITEM_DEFAULT_WIDTH),
          (sum, n) => +sum + +n,
        ),
      [columnsFrozen],
    );

    const gridStyles: CSSProperties = useMemo(
      () => ({
        width: parseInt(`${style.width}`, 10),
        flexDirection: 'row',
        flexWrap: 'wrap',
      }),
      [style.width],
    );

    const headerStyles = useMemo(
      () => ({
        minHeight: type === 'list' ? ITEM_DEFAULT_HEIGHT : GRID_DEFAULT_HEIGHT,
      }),
      [type],
    );
    const headerStylesWithFrozenColumns = useMemo(
      () => ({
        frozenColumns: {
          minHeight: type === 'list' ? ITEM_DEFAULT_HEIGHT : GRID_DEFAULT_HEIGHT,
          width: parseInt(`${stickyContainerWidth}`, 10),
          flex: 'initial',
          zIndex: 44,
          boxShadow: 'frozenContainer',
        },
        nonFrozenColumns: {
          minHeight: type === 'list' ? ITEM_DEFAULT_HEIGHT : GRID_DEFAULT_HEIGHT,
          width: `calc(100% - ${stickyContainerWidth}px)`,
          flex: 'initial',
          zIndex: 43,
        },
      }),
      [stickyContainerWidth, type],
    );

    const innerElementStyle: InnerElementProps['style'] = useMemo(
      () => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        ...(showHeader ? showHeaderStyle : style),
        ...(type === 'grid' && gridStyles),
      }),
      [gridStyles, showHeader, showHeaderStyle, style, type],
    );

    return (
      <div ref={ref} data-test style={innerElementStyle}>
        {showHeader &&
          listName &&
          (columnsFrozen && columnsNonFrozen ? (
            <>
              <MemorizedHeader
                sx={headerStylesWithFrozenColumns.frozenColumns}
                key="FrozenHeaderColumns"
                type={type}
                columns={columnsFrozen}
                listName={listName}
                select={select}
                variant={variant}
              />
              <MemorizedHeader
                sx={headerStylesWithFrozenColumns.nonFrozenColumns}
                key="Header"
                type={type}
                columns={columnsNonFrozen}
                listName={listName}
                variant={variant}
              />
            </>
          ) : (
            columns && (
              <MemorizedHeader
                style={headerStyles}
                key="Header"
                type={type}
                columns={columns}
                listName={listName}
                select={select}
                variant={variant}
              />
            )
          ))}

        {frozenColumns && columnsFrozen && itemCount && listName && list && (
          <MemorizedFrozenColumnContainer width={stickyContainerWidth} height={style.height}>
            {_.map(visibleRows, (row, index) => (
              <Flex
                key={row}
                style={{
                  position: 'absolute',
                  top: rowStyles[index]?.top,
                }}
              >
                {_.map(columnsFrozen, (column, columnIndex) =>
                  gridRenderer({
                    data: {
                      columns: columnsFrozen,
                      itemCount,
                      list,
                      name: listName,
                      showHeader,
                      frozenColumns,
                      variant,
                      select,
                      isRowUncheckableValidator,
                    },
                    columnIndex,
                    rowIndex: row,
                    style: {
                      height: type === 'list' ? ITEM_DEFAULT_HEIGHT : GRID_DEFAULT_HEIGHT,
                      width: (column.width && parseInt(`${column.width}`, 10)) || ITEM_DEFAULT_WIDTH,
                    },
                  }),
                )}
              </Flex>
            ))}
            {showContentPlaceholder && <MemorizedContentPlaceholder type={type} sx={{ mr: 0 }} />}
          </MemorizedFrozenColumnContainer>
        )}

        {children}

        {showContentPlaceholder && <MemorizedContentPlaceholder type={type} />}
      </div>
    );
  },
);
