import React from 'react';
import { Control, UseFormRegister, useFormState } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';
import _ from 'lodash';

import { Checkbox } from 'components/ui/Checkbox';
import { MaskedTextInput } from 'components/ui/MaskedTextInput';
import { EmployeeEmploymentDetailsFormState } from 'state/team';
import { timeOffTypesDictionarySelector } from 'state/organizationSession';
import { TimeOffLimitsGroup } from 'api/actions/employees/employeesActions.types';

type Props = {
  fields: TimeOffLimitsGroup['limits'];
  nestIndex: number;
  control: Control<EmployeeEmploymentDetailsFormState>;
  register: UseFormRegister<EmployeeEmploymentDetailsFormState>;
};
export const NestedTimeOffLimits = ({ fields, nestIndex, control, register }: Props): React.ReactElement | null => {
  useLingui();
  const { errors } = useFormState({ control });

  const timeOffTypesDictionary = useRecoilValue(timeOffTypesDictionarySelector);

  if (!timeOffTypesDictionary) return null;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: 2,
        mt: 2,
      }}
    >
      {_.orderBy(fields, ({ timeOffTypeId }) => timeOffTypeId).map((field, index) => {
        const { name, abbreviation } = timeOffTypesDictionary[field.timeOffTypeId] || {};

        if (!name || !abbreviation) return <React.Fragment key={`time-off-type-${field.timeOffTypeId}`} />;

        return (
          <Flex
            key={`time-off-type--${name}-${field.timeOffTypeId}`}
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Flex sx={{ flexDirection: 'column', pr: 2 }}>
              <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>
                {t({
                  id: name,
                })}
              </Text>
              <Text sx={{ fontSize: 1, color: 'texts.lighter', lineHeight: 1 }}>
                {t({
                  id: abbreviation,
                })}
              </Text>
            </Flex>

            <Flex sx={{ alignItems: 'center', flexShrink: 0 }}>
              <Flex sx={{ width: '80px', flexShrink: 0 }}>
                <MaskedTextInput
                  mask={/^(?:36[0-6]|3[0-5][0-9]|[12][0-9][0-9]|[1-9][0-9]|[0-9])$/}
                  size="sm"
                  id={`timeOffLimits.${nestIndex}.limits.${index}.days`}
                  placeholder=""
                  variant="rounded"
                  error={!!errors.timeOffLimits?.[nestIndex]?.limits?.[index]?.days}
                  errorMessage={errors.timeOffLimits?.[nestIndex]?.limits?.[index]?.days?.message}
                  defaultValue={field.days}
                  apendWith={t({ id: 'team.time_off_limits.append_days', message: 'days' })}
                  {...register(`timeOffLimits.${nestIndex}.limits.${index}.days`)}
                />
              </Flex>

              <Checkbox
                sx={{ ml: 2 }}
                label={
                  <Text sx={{ flexShrink: 0, fontSize: 1, lineHeight: 1, display: 'block', ml: -2 }}>
                    <Trans id="team.time_off_limits.carry">
                      Carry-over <br /> unused limit
                    </Trans>
                  </Text>
                }
                defaultChecked={field.carryOverUnusedLimit}
                size="sm"
                {...register(`timeOffLimits.${nestIndex}.limits.${index}.carryOverUnusedLimit`)}
              />
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export const NestedTimeOffLimitsFieldArray = React.memo(NestedTimeOffLimits);
