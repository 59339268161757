import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Flex } from 'theme-ui';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { motion } from 'framer-motion';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { chatWindowAtomFamily, setStateChatWindowSelector, removeChatWindowSelector } from 'state/chat';
import { MemoizedChatUser } from 'chat/ChatUser/ChatUser';
import { ChatUserForFetch } from 'api/actions/chat/chatActions.types';
import {
  CHAT_WINDOW_IS_EXPANDED_HEIGHT,
  CHAT_WINDOW_IS_EXPANDED_WIDTH,
  CHAT_WINDOW_IS_VISIBLE_HEIGHT,
  CHAT_WINDOW_IS_VISIBLE_WIDTH,
  CHAT_WINDOW_WIDTH,
  CHAT_WINDOW_HEIGHT,
} from 'chat/constans';
import { MOBILE_CHAT_AVATAR_SIZE } from 'styles/theme/chat';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';

import { MessagesList } from './MessagesList';
import { MessageComposer } from './MessageComposer';

type Props = {
  id: ChatUserForFetch['sender'];
  index: number;
};

const AnimatedFlex = motion(Flex);

export const ChatWindow = ({ id, index }: Props): React.ReactElement | null => {
  const messageComposerRef = useRef<HTMLInputElement | null>(null);
  const messageListRef = useRef<HTMLInputElement | null>(null);

  const setChatWindowState = useSetRecoilState(setStateChatWindowSelector(id));
  const removeChatWindowFromRecoil = useSetRecoilState(removeChatWindowSelector(id));
  const { isVisible, isExpanded } = useRecoilValue(chatWindowAtomFamily(id));

  const { isMobileBreakpoint } = useThemeBreakpoint();
  const isMobileChat = useMemo(() => isMobileBreakpoint, [isMobileBreakpoint]);

  const removeChatWindow = useCallback(async () => {
    removeChatWindowFromRecoil(null);
  }, [removeChatWindowFromRecoil]);

  const handleIsVisible = useCallback(async () => {
    setChatWindowState({
      type: 'isVisible',
      value: !isVisible,
    });
  }, [isVisible, setChatWindowState]);

  const chatWindowSize = useCallback(() => {
    if (isMobileChat) {
      if (isVisible) {
        return {
          height: 'var(--app-height)',
          width: 'var(--app-width)',
        };
      }

      return {
        height: MOBILE_CHAT_AVATAR_SIZE,
        width: MOBILE_CHAT_AVATAR_SIZE,
      };
    }
    if (isExpanded && isVisible)
      return {
        width: CHAT_WINDOW_IS_EXPANDED_WIDTH,
        height: CHAT_WINDOW_IS_EXPANDED_HEIGHT,
      };
    if (isVisible)
      return {
        width: CHAT_WINDOW_IS_VISIBLE_WIDTH,
        height: CHAT_WINDOW_IS_VISIBLE_HEIGHT,
      };

    return {
      width: CHAT_WINDOW_WIDTH,
      height: CHAT_WINDOW_HEIGHT,
    };
  }, [isExpanded, isMobileChat, isVisible]);

  const returnChatUser = useCallback(
    () => (
      <MemoizedChatUser
        variant="chatWindow"
        id={id}
        chatWindowVisible={isVisible}
        chatWindowExpanded={isExpanded}
        onClick={handleIsVisible}
      />
    ),
    [handleIsVisible, id, isExpanded, isVisible],
  );

  useEffect(() => {
    messageComposerRef.current?.focus();
  }, [isExpanded, isVisible]);

  const renderAdditionalButtons = useCallback(() => {
    if (isVisible) {
      return (
        <>
          {!isMobileChat && (
            <Button
              variant="minimal"
              shape="rounded"
              size="sm"
              onClick={() =>
                setChatWindowState({
                  type: 'isExpanded',
                  value: !isExpanded,
                })
              }
            >
              <Icon type={isExpanded ? 'narrow' : 'expand'} />
            </Button>
          )}
          <Button variant="minimal" shape="rounded" size="sm" onClick={handleIsVisible}>
            <Icon type="chevronDown" />
          </Button>
        </>
      );
    }
    return null;
  }, [handleIsVisible, isExpanded, isMobileChat, isVisible, setChatWindowState]);

  const variants = useMemo(
    () => ({
      initial: {
        x: `${index * 100 + 100 - index * 20 - 20}%`,
      },
      visible: {
        x: '0%',
      },
    }),
    [index],
  );

  const zIndex = useMemo(() => 100 - index, [index]);

  return (
    <motion.div
      variants={variants}
      style={{
        display: 'flex',
        zIndex,
        ...(isVisible &&
          isMobileChat && {
            position: 'fixed',
            bottom: 0,
            right: 0,
            zIndex: 100 + zIndex,
          }),
      }}
      transition={{
        type: 'spring',
        duration: 0.2,
      }}
    >
      <AnimatedFlex
        data-is-visible={isVisible}
        key={`chat.window.${id}`}
        variant="chat.window.chatContainer"
        animate={chatWindowSize()}
        transition={{
          type: 'spring',
          duration: isMobileChat ? 0 : 0.15,
        }}
      >
        <Flex variant="chat.window.header" as="header">
          {returnChatUser()}
          <Flex variant="chat.window.header.buttons">
            {renderAdditionalButtons()}
            <Button variant="minimal" shape="rounded" size="sm" onClick={removeChatWindow}>
              <Icon type="x" />
            </Button>
          </Flex>
        </Flex>

        {isVisible && (
          <>
            <MessagesList ref={messageListRef} chatWindowId={id} />
            <MessageComposer messagesListRef={messageListRef} ref={messageComposerRef} receiver={id} />
          </>
        )}
      </AnimatedFlex>
    </motion.div>
  );
};
