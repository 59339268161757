import { Action } from 'react-fetching-library';

import { API_ENDPOINTS } from 'constants/api';

import {
  FetchZusCredentialsResponse,
  ZusConnectActionProps,
  ZusConnectResponse,
  ZusCredentialsActionProps,
  ZusCredentialsResponse,
  ZusManualImportResponse,
} from './zusActions.types';

export function fetchZusCredentialsAction(): Action<FetchZusCredentialsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.zusCredentials,
  };
}

export function zusManualImportAction(): Action<ZusManualImportResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.zusManualImport,
  };
}

export function zusConncectAction(body: ZusConnectActionProps): Action<ZusConnectResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.zusConnect,
    body,
  };
}

export function zusCredentialsAction(body: ZusCredentialsActionProps): Action<ZusCredentialsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.zusCredentials,
    body,
  };
}
