import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useClient } from 'react-fetching-library';
import { useHistory } from 'react-router';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { fetchTimeTrackerAction, postTimeEventAction } from 'api/actions/drawer/drawerActions';
import { actionHasBeenSentAtom, postTimeEventAtom, timeTrackerAtom } from 'state/drawer';
import { timeTrackingSettingsSelector } from 'state/organizationSession';
import { useGeoLocation } from 'Kiosk/hooks/useGeoLocation';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { TimeEventFunc } from 'layouts/AuthorizedApp/Drawer/TimeTracker/TimeEventTimer';
import { PermissionState, permissionStateAtom } from 'Kiosk/state/permissionState';
import { UUID } from 'utils/UUID';
import { DRAWER_ROUTES } from 'constants/memoryRoutes';
import { GeoLocationProvider } from 'Kiosk/context/GeoLocationProvider';
import { PermissionObserver } from 'Kiosk/observers/PermissionObserver';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

export const WorkTimeTrackerProvider = ({ children }: Props): React.ReactElement => {
  const [timeTracker, setTimeTracker] = useRecoilState(timeTrackerAtom);
  const timeTrackerSettings = useRecoilValue(timeTrackingSettingsSelector);
  const setPostTimeEvent = useSetRecoilState(postTimeEventAtom);
  const { location: locationPermission } = useRecoilValue(permissionStateAtom);
  const setActionHasBeenSent = useSetRecoilState(actionHasBeenSentAtom);

  const { location } = useGeoLocation();

  const { query } = useClient();

  const shouldPostTimeEvent = useRef<boolean>(true);

  const { push, location: routerLocation } = useHistory();

  const { addSnackbar } = useSnackbar();

  const postTimeEvent = useCallback(
    async (body: TimeEventFunc) => {
      if (!shouldPostTimeEvent.current) return;

      if (timeTrackerSettings?.isLocationRequired && locationPermission !== PermissionState.allow) {
        if (routerLocation.pathname.includes(DRAWER_ROUTES.DRAWER)) {
          push(DRAWER_ROUTES.DRAWER__TIME_TRACKER__LOCATION_PERMISSION);
        } else {
          push(DRAWER_ROUTES.LOCATION_PERMISSION);
        }
      } else if (body) {
        if (body?.initialTime && body.initialTime < 0) {
          addSnackbar({
            message: i18n._(
              t({
                id: 'time_tracker.wait_on_start',
                message: 'You can send next event after start working time!',
              }),
            ),
            variant: 'danger',
          });

          return;
        }

        shouldPostTimeEvent.current = false;

        const position = {
          longitude: location?.coords.longitude ?? undefined,
          latitude: location?.coords.latitude ?? undefined,
        };

        const { error, payload: returnedPayload } = await query(
          postTimeEventAction({
            ...body,
            ...(position.latitude && position),
            timeEventId: UUID(),
          }),
        );

        if (!error) {
          setActionHasBeenSent(true);
          addSnackbar({
            message: i18n._(
              t({
                id: 'time_tracker.submit_success',
                message: 'Successfully submitted!',
              }),
            ),
            variant: 'success',
          });

          const { error: fetchError, payload } = await query(fetchTimeTrackerAction());

          if (!fetchError && payload) {
            setTimeTracker(payload);
          }
        } else if (error) {
          let message = '';

          if (returnedPayload?.innerCode) {
            (() => {
              switch (returnedPayload.innerCode) {
                case ErrorInnerCodes.InvalidLocation:
                  message = i18n._(
                    t({
                      id: 'time_tracker.invalid_location',
                      message: 'You are not in allowed location!',
                    }),
                  );
                  break;
                case ErrorInnerCodes.TimeEventDuplicate:
                  message = i18n._(
                    t({
                      id: 'time_tracker.duplicate_request',
                      message: 'You are trying to send the same event at the same time!',
                    }),
                  );
                  break;
                case ErrorInnerCodes.TimeEventMagnet:
                default:
                  message = returnedPayload?.message || '';
                  break;
              }
            })();
          }

          addSnackbar({
            message,
            variant: 'danger',
          });
        }
      }
      setActionHasBeenSent(false);

      shouldPostTimeEvent.current = true;
    },
    [
      addSnackbar,
      location?.coords.latitude,
      location?.coords.longitude,
      locationPermission,
      push,
      query,
      routerLocation.pathname,
      setActionHasBeenSent,
      setTimeTracker,
      timeTrackerSettings?.isLocationRequired,
    ],
  );

  useEffect(() => {
    const fetchTimeTracker = async () => {
      const { error, payload } = await query(fetchTimeTrackerAction());
      if (!error && payload) {
        setTimeTracker(payload);
      }
    };
    if (!timeTracker) {
      fetchTimeTracker();
    }
  }, [query, setTimeTracker, timeTracker]);

  useEffect(() => {
    setPostTimeEvent({ postTimeEvent });
  }, [postTimeEvent, setPostTimeEvent]);

  return (
    <GeoLocationProvider>
      <>
        {timeTrackerSettings && (
          <PermissionObserver requireLocation={timeTrackerSettings.isLocationRequired} kioskAutoRedirect={false} />
        )}
        {children}
      </>
    </GeoLocationProvider>
  );
};
