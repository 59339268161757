import React from 'react';

import { Chat } from 'chat/Chat';

import { ChatWidthObserver } from './ChatWidthObserver';

type Props = {
  children: React.ReactNode;
};
export const ChatProvider = ({ children }: Props): React.ReactElement => (
  <>
    <ChatWidthObserver />
    <Chat />
    {children}
  </>
);
