import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';

import { RequestTypePresenter } from 'components/recipes/RequestTypePresenter';
import { parsedRequestDetailsSelector, requestDetailsAtom } from 'state/requests';
import { Attachment } from 'components/ui/FileUpload/Attachment';

import { RequestPerson } from './RequestPerson';
import { HistoryList } from './HistoryList';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const RequestDetailsMain = ({ children }: Props): React.ReactElement => {
  useLingui();
  const requestDetails = useRecoilValue(requestDetailsAtom);
  const parsedRequestDetails = useRecoilValue(parsedRequestDetailsSelector);

  if (!parsedRequestDetails || !requestDetails) return <></>;

  return (
    <Flex sx={{ flexDirection: 'column', gap: 4 }}>
      <RequestPerson person={parsedRequestDetails.employee} />
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          <Trans id="type" />
        </Text>
        <RequestTypePresenter {...parsedRequestDetails} />
      </Flex>
      {children}
      {requestDetails.note && (
        <>
          <Flex sx={{ flexDirection: 'column', gap: 2 }}>
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: 3,
              }}
            >
              <Trans id="add_request.note" />
            </Text>
            <Text>{requestDetails.note}</Text>
          </Flex>
        </>
      )}
      {!_.isEmpty(requestDetails.attachments) && (
        <>
          <Flex sx={{ flexDirection: 'column', gap: 2 }}>
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: 3,
              }}
            >
              <Trans id="add_request.attachments" />
            </Text>
            <Attachment name="attachments" defaultValue={requestDetails.attachments?.join(',')} />
          </Flex>
        </>
      )}
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          <Trans id="request.history">History</Trans>
        </Text>
        <HistoryList list={requestDetails.history} />
      </Flex>
    </Flex>
  );
};
