import React, { useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Button } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { PostResetPasswordPayload } from 'api/actions/password/passwordActions.types';
import { PasswordInput } from 'components/ui/PasswordInput';
import { mergeRefs } from 'utils/mergeRefs';
import { ElementGroup } from 'components/ui/ElementGroup';
import { FormCard } from 'layouts/Authentication';

const PASS_WIZARD_FORM_ID = 'password-reset-wizard-form-id';

type Props = {
  onSubmit: (props: Pick<PostResetPasswordPayload, 'password' | 'confirmPassword'>) => Promise<boolean>;
};

export const PassResetWizardForm = ({ onSubmit }: Props): React.ReactElement => {
  const newPasswordRef = useRef<HTMLInputElement | null>(null);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange' });

  const handleSubmitCallback = useCallback(
    async (props) => {
      await onSubmit(props);
    },
    [onSubmit],
  );

  const newPasswordRegister = useMemo(
    () => register('password', { required: i18n._('global.forms.required') }),
    [register],
  );

  const confirmPasswordRegister = useMemo(
    () =>
      register('confirmPassword', {
        required: i18n._('global.forms.required'),
        validate: (value) =>
          value === newPasswordRef.current?.value ||
          i18n._(
            t({
              id: 'settings.user.change_password.not_match',
              message: 'Passwords do not match',
            }),
          ),
      }),
    [register],
  );

  const setPasswordErrorCallback = useCallback(() => {
    setError('passwordInternalError' as 'password', {
      message: 'only visible in code',
    });
  }, [setError]);

  const clearPasswordErrorCallback = useCallback(() => {
    clearErrors('passwordInternalError' as 'password');
  }, [clearErrors]);

  return (
    <>
      <form id={PASS_WIZARD_FORM_ID} onSubmit={handleSubmit(handleSubmitCallback)} noValidate>
        <ElementGroup direction="column" marginValue="4">
          <PasswordInput
            id="password"
            autoComplete="newPassword"
            {...newPasswordRegister}
            ref={mergeRefs([newPasswordRegister.ref, newPasswordRef])}
            label={i18n._(
              t({
                id: 'settings.user.change_password.new_password',
                message: 'New password',
              }),
            )}
            placeholder="••••••"
            error={!!errors.password}
            errorMessage={errors?.password?.message}
            onValidError={setPasswordErrorCallback}
            onClearError={clearPasswordErrorCallback}
          />

          <TextInput
            id="confirmPassword"
            type="password"
            {...confirmPasswordRegister}
            label={i18n._(
              t({
                id: 'settings.user.change_password.confirm_password',
                message: 'Confirm password',
              }),
            )}
            placeholder="••••••"
            error={!!errors.confirmPassword}
            errorMessage={errors?.confirmPassword?.message}
          />
        </ElementGroup>
      </form>

      <FormCard.Footer>
        <Button variant="primary" size="lg" fullWidth type="submit" shape="rounded" form={PASS_WIZARD_FORM_ID}>
          <Trans id="password_reset_wizard.form.submit_button">Set new password</Trans>
        </Button>
      </FormCard.Footer>
    </>
  );
};
