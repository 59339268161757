import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { fetchNotificationSettingsAction } from 'api/actions/settings/settingsActions';
import { FetchNotificationSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { NotificationsForm } from 'layouts/Settings/Organization/forms/NotificationsForm';
import { refreshSettingAtomFamily, resetFormButtonAtom, resetFormButtonCallbacksSelector } from 'state/settings';

export const Notifications = (): React.ReactElement => {
  useLingui();
  const { query } = useClient();
  const [notificationsState, setNotificationsState] = useRecoilState(refreshSettingAtomFamily('NOTIFICATIONS'));
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);

  const payloadRef = useRef<FetchNotificationSettingsResponse | null>(null);
  const firstRenderRef = useRef<boolean>(true);

  const refresh = useCallback(async () => {
    const { payload } = await query(fetchNotificationSettingsAction());

    if (payload) {
      setNotificationsState((prevState) =>
        !_.isEqual(prevState?.payload, payload) ? { ...prevState, payload } : prevState,
      );
      payloadRef.current = payload;
    }
  }, [query, setNotificationsState]);

  useEffect(() => {
    setNotificationsState((prevState) => ({ ...prevState, refresh }));
  }, [refresh, setNotificationsState]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (
      !firstRenderRef.current &&
      notificationsState?.payload &&
      payloadRef.current &&
      !_.isEqual(payloadRef.current, notificationsState.payload)
    ) {
      refresh();
    }

    firstRenderRef.current = false;
  }, [refresh, notificationsState?.payload]);

  useEffect(
    () => () => {
      firstRenderRef.current = true;
    },
    [],
  );

  const handleResetButtonClick = () => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  };

  return (
    <>
      <HeadingTip
        label={t({
          id: 'notifications_settings.heading.notifications',
          message: 'Notifications',
        })}
        additionalInfo={t({
          id: 'notifications_settings.heading.notifications.additional_info',
          message:
            'Organization-wide default notifications settings, can be overwritten by the profiles notification preferences.',
        })}
        additionalInfoSx={{
          fontSize: 3,
          lineHeight: '1.5rem',
        }}
        displayReset={!!formResetCallbacks}
        displayOnClick={() => handleResetButtonClick()}
      />
      {notificationsState && notificationsState.payload ? (
        <NotificationsForm payload={notificationsState.payload} />
      ) : (
        <Flex
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            minHeight: '400px',
          }}
        >
          <LoadingSpinnerCss size={4} />
        </Flex>
      )}
    </>
  );
};
