import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { fetchSchedulesAction } from 'api/actions/settings/settingsActions';
import { FetchScheduleSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { MemoizedSchedulesForm } from 'layouts/Settings/Organization/forms/SchedulesForm';
import { refreshSettingAtomFamily, resetFormButtonAtom, resetFormButtonCallbacksSelector } from 'state/settings';

export const Schedules = (): React.ReactElement => {
  useLingui();
  const { query } = useClient();
  const [schedulesState, setSchedulesState] = useRecoilState(refreshSettingAtomFamily('SCHEDULES'));
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);

  const payloadRef = useRef<FetchScheduleSettingsResponse | null>(null);
  const firstRenderRef = useRef<boolean>(true);

  const refresh = useCallback(async () => {
    const { payload } = await query(fetchSchedulesAction());

    if (payload) {
      setSchedulesState((prevState) =>
        !_.isEqual(prevState?.payload, payload) ? { ...prevState, payload } : prevState,
      );
      payloadRef.current = payload;
    }
  }, [query, setSchedulesState]);

  useEffect(() => {
    setSchedulesState((prevState) => ({ ...prevState, refresh }));
  }, [refresh, setSchedulesState]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (
      !firstRenderRef.current &&
      schedulesState?.payload &&
      payloadRef.current &&
      !_.isEqual(payloadRef.current, schedulesState.payload)
    ) {
      refresh();
    }

    firstRenderRef.current = false;
  }, [refresh, schedulesState?.payload]);

  useEffect(
    () => () => {
      firstRenderRef.current = true;
    },
    [],
  );

  const handleResetButtonClick = () => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  };

  return (
    <>
      <HeadingTip
        label={t({
          id: 'schedules_settings.heading.schedules',
          message: 'Schedules',
        })}
        displayReset={!!formResetCallbacks}
        displayOnClick={() => handleResetButtonClick()}
      />
      {schedulesState && schedulesState.payload ? (
        <MemoizedSchedulesForm payload={schedulesState.payload} />
      ) : (
        <Flex
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            minHeight: '400px',
          }}
        >
          <LoadingSpinnerCss size={4} />
        </Flex>
      )}
    </>
  );
};
