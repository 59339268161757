import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Text } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';

import { Modal } from 'components/Modal/Modal';
import { getRequestsAtom, requestsAtom } from 'state/requests';
import { TextInput } from 'components/ui/TextInput';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { rejectRequestAction } from 'api/actions/requests/requestsActions';
import { RejectRequestManagementActionProps } from 'api/actions/requests/requestsActions.types';
import { addSnackbar } from 'SnackbarHub/actions';
import { useModal } from 'hooks/useModal/useModal';

const REASON_MAX_CHARACTER_COUNT = 160;

type Props = {
  onSubmitCallback?: () => Promise<void>;
};

const defaultProps = {
  onSubmitCallback: undefined,
};

export const RejectRequestModal = ({ onSubmitCallback }: Props): React.ReactElement => {
  useLingui();
  const {
    state: { id },
  } = useLocation();
  const { handleClose } = useModal();
  const requests = useRecoilValue(requestsAtom);
  const getRequests = useRecoilValue(getRequestsAtom);
  const { mutate: rejectRequest, loading } = useMutation(rejectRequestAction);

  const { register, handleSubmit, watch } = useForm();

  const reasonWatch: string = watch('reason');
  const request = useMemo(() => requests && requests.get(id), [id, requests]);

  const handleSubmitCallback = useCallback(
    async ({ reason }: RejectRequestManagementActionProps) => {
      const { error } = await rejectRequest({ id, reason });

      if (!error) {
        addSnackbar({
          message: t({
            id: 'request.snackbar_rejected',
            message: 'Request rejected!',
          }),
          variant: 'success',
        });
        if (onSubmitCallback) await onSubmitCallback();
        if (getRequests) await getRequests();
        handleClose();
      }
    },
    [getRequests, handleClose, id, onSubmitCallback, rejectRequest],
  );

  useEffect(() => {
    if (!id) handleClose();
  }, [handleClose, id]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Text>
            <Trans id="requests.request" /> {request?.number || '-'}
          </Text>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <Modal.Body>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: 3,
              }}
            >
              <Trans id="request.reject.reason">Reason (optional)</Trans>
            </Text>
            {/* TODO: Implement component for textarea after core. */}
            <TextInput
              {...register('reason')}
              id="reason"
              placeholder={t({ id: 'request.reject.placeholder', message: 'Enter your reason...' })}
              maxLength={REASON_MAX_CHARACTER_COUNT}
              sx={{ mt: 2 }}
            />
            <Text sx={{ ml: 'auto', color: 'texts.lighter', fontSize: 0, fontWeight: 'bold' }}>
              {(reasonWatch && reasonWatch.length) || 0} / {REASON_MAX_CHARACTER_COUNT}
            </Text>
          </Flex>
        </Modal.Body>
        <BasicModalFooter
          buttons={[
            {
              isLoading: loading,
              variant: 'danger',
              children: t({ id: 'requests.button.reject' }),
              type: 'submit',
            },
          ]}
        />
      </form>
    </>
  );
};

RejectRequestModal.defaultProps = defaultProps;
