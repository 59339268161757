import React, { InputHTMLAttributes } from 'react';
import { ThemeUIStyleObject, Label, Text } from 'theme-ui';

import { useTheme } from 'styles/useTheme';

export type CheckboxLogicWrapperProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  children: React.ReactNode;
  disabledButReadable?: boolean; // RFH treats disabled inputs values as undefined, use this if you need disabled styles with readable value
  label?: string | React.ReactNode;
  additionalInfo?: string | React.ReactNode;
  name: string;
  size?: 'xs' | 'sm' | 'default';
  placement?: 'left' | 'right';
  bold?: boolean;
  wrapperSx?: ThemeUIStyleObject;
  labelWrapperSx?: ThemeUIStyleObject;
  withDivider?: boolean;
};

export const CheckboxLogicWrapper = React.forwardRef<HTMLInputElement, CheckboxLogicWrapperProps>(
  (
    {
      children,
      disabledButReadable,
      name,
      label,
      additionalInfo,
      size = 'default',
      placement = 'left',
      bold,
      wrapperSx,
      labelWrapperSx,
      withDivider,
      ...props
    }: CheckboxLogicWrapperProps,
    ref,
  ) => {
    const { theme } = useTheme();

    return (
      <Label
        sx={{
          gap: 3,
          flexDirection: placement === 'right' ? 'row-reverse' : 'row',
          alignItems: 'center',
          // FIXME: cursor change does not work when pointerEvents is set to 'none'
          pointerEvents: props.disabled || disabledButReadable ? 'none' : 'all', // this is needed for disabledButReadable mode, see: AdvancedFeaturesFieldArray.tsx
          cursor: props.disabled || disabledButReadable ? 'not-allowed' : 'pointer',
          userSelect: 'none',
          ...(withDivider && {
            paddingBottom: '0.75rem',
            borderBottom: '1px solid',
            borderBottomColor: 'dropdown.dividerColors.primary',
          }),
          ...(wrapperSx && wrapperSx),
          ...((props.disabled || disabledButReadable) && {
            '&:hover': {},
            '&:focus': {},
          }),
        }}
      >
        <input
          name={name}
          ref={ref}
          type="checkbox"
          style={{ display: 'none' }}
          data-disabled-but-readable={!!disabledButReadable}
          {...props}
        />
        {children}
        {label && (
          <Text
            as="span"
            sx={{
              textAlign: 'left',
              display: 'inline-flex',
              flexGrow: 1,
              flexDirection: 'column',
              gap: '0.125rem',
              color: 'checkbox.label.default',
              fontSize: theme.forms.checkbox.labelFontSize[size],
              lineHeight: 1.2,
              ...((props.disabled || disabledButReadable) && {
                opacity: '.6',
              }),
              ...(labelWrapperSx && labelWrapperSx),
            }}
          >
            {label && (
              <>
                <Text as="span" sx={{ display: 'inline' }}>
                  {label}
                </Text>
                {additionalInfo && (
                  <Text
                    as="span"
                    sx={{
                      display: 'flex',
                      color: 'texts.lighter',
                      fontWeight: '400',
                    }}
                  >
                    {additionalInfo}
                  </Text>
                )}
              </>
            )}
          </Text>
        )}
      </Label>
    );
  },
);
