import React, { useCallback, useEffect, useState } from 'react';
import { Flex, FlexProps, Heading, HeadingProps } from 'theme-ui';
import { useHistory } from 'react-router';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { useOnOutsideClick } from 'hooks/useOnOutsideClick/useOnOutsideClick';
import { DRAWER_ROUTES, modalRoutes } from 'constants/memoryRoutes';
import { actionHasBeenSentAtom } from 'state/drawer';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

const AnimatedFlex = motion(Flex);

type Props = {
  drawerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactElement | React.ReactElement[];
};

type DrawerComponent = React.FC<Props> & {
  Header: typeof Header;
  Title: typeof Title;
  SubTitle: typeof SubTitle;
  Container: typeof Container;
};

export const Drawer: DrawerComponent = ({ drawerRef, children }: Props): React.ReactElement => {
  const timeEventActionHasBeenSent = useRecoilValue(actionHasBeenSentAtom);

  const { push, location } = useHistory();
  const [isVisible, setIsVisible] = useState(true);

  const { addSnackbar } = useSnackbar();

  const closeDrawer = useCallback(() => {
    setIsVisible(false);
  }, []);

  const onOutsideClick = useCallback(() => {
    if (!_.find(modalRoutes, (r) => _.isEqual(r, location.pathname))) closeDrawer();
    if (!timeEventActionHasBeenSent && location.pathname === DRAWER_ROUTES.DRAWER__TIME_TRACKER)
      addSnackbar({
        message: i18n._(
          t({
            id: 'time_tracker.no_event',
          }),
        ),
      });
  }, [timeEventActionHasBeenSent, addSnackbar, closeDrawer, location.pathname]);

  useOnOutsideClick(drawerRef, onOutsideClick);

  useEffect(() => {
    if (timeEventActionHasBeenSent) {
      closeDrawer();
    }
  }, [timeEventActionHasBeenSent, closeDrawer]);

  useEffect(() => {
    if (location.pathname === location.state?.from) {
      closeDrawer();
    }
  }, [location, closeDrawer]);

  return (
    <AnimatePresence
      onExitComplete={() => {
        push(DRAWER_ROUTES.CLOSED);
      }}
    >
      {isVisible && (
        <AnimatedFlex
          as="section"
          variant="drawer.container"
          key="drawer.container"
          initial={{ x: '125%' }}
          animate={{ x: 0 }}
          exit={{ x: '125%' }}
          transition={{
            type: 'spring',
            duration: 0.4,
          }}
        >
          {children}
        </AnimatedFlex>
      )}
    </AnimatePresence>
  );
};

const Header = ({ children, sx, ...props }: FlexProps): React.ReactElement => (
  <Flex
    {...props}
    as="header"
    sx={{ minHeight: '26px', mb: 3, px: 3, alignItems: 'center', flexDirection: 'row', gap: 2, ...(sx && sx) }}
  >
    {children}
  </Flex>
);

const Title = ({ children, sx, ...props }: HeadingProps): React.ReactElement => (
  <Heading {...props} as="h1" variant="heading4" sx={{ flex: '1 0', ...(sx && sx) }}>
    {children}
  </Heading>
);

const SubTitle = ({ children, sx, ...props }: HeadingProps): React.ReactElement => (
  <Heading {...props} as="h2" variant="heading5" sx={{ mb: 1, ...(sx && sx) }}>
    {children}
  </Heading>
);

const Container = ({ children, sx, ...props }: FlexProps): React.ReactElement => (
  <Flex
    {...props}
    sx={{
      flex: '1 0',
      px: 3,
      gap: 2,
      flexDirection: 'column',
      justifyContent: 'stretch',
      alignItems: 'flexStart',
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
);

Drawer.Container = Container;
Drawer.Header = Header;
Drawer.Title = Title;
Drawer.SubTitle = SubTitle;
