import React, { useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Flex } from 'theme-ui';

import { RadioButtonGroup } from 'components/ui/RadioButton/RadioButtonGroup';
import { RadioButton } from 'components/ui/RadioButton/RadioButton';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Modal } from 'components/Modal/Modal';

type Props = {
  requestType: RequestFormType;
  setRequestType: React.Dispatch<React.SetStateAction<RequestFormType>>;
};

export const AddRequestStep2 = ({ requestType, setRequestType }: Props): React.ReactElement => {
  useLingui();

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRequestType(+e.target.value);
    },
    [setRequestType],
  );

  return (
    <>
      <Modal.SubTitle>
        <Trans id="requests.add_request.select_request_Type">Select a request type</Trans>
      </Modal.SubTitle>

      <Flex sx={{ flexDirection: 'column' }}>
        <RadioButtonGroup name="radioButtonGroup" orientation="column" onChange={handleOnChange}>
          <RadioButton
            label={t({
              id: 'request.type.time_off',
            })}
            additionalLabel={t({
              id: 'request.type.time_off.description',
              message: 'for ex. PTO, vacation, sick leave',
            })}
            value={`${RequestFormType.TimeOff}`}
            defaultChecked={requestType === RequestFormType.TimeOff}
          />
          <RadioButton
            label={t({
              id: 'request.type.custom',
            })}
            additionalLabel={t({
              id: 'request.type.custom.description',
              message: 'for ex. work from home',
            })}
            value={`${RequestFormType.Custom}`}
            defaultChecked={requestType === RequestFormType.Custom}
          />
          <RadioButton
            label={t({
              id: 'request.type.business_trip',
            })}
            additionalLabel={t({
              id: 'request.type.business_trip.description',
              message: 'and all related to it',
            })}
            value={`${RequestFormType.BusinessTrip}`}
            defaultChecked={requestType === RequestFormType.BusinessTrip}
          />
          <RadioButton
            label={t({
              id: 'request.type.time_tracking',
            })}
            additionalLabel={t({
              id: 'request.type.time_tracking.description',
              message: 'add, edit or remove a time entry',
            })}
            value={`${RequestFormType.TimeTracking}`}
            defaultChecked={requestType === RequestFormType.TimeTracking}
          />
        </RadioButtonGroup>
      </Flex>
    </>
  );
};
