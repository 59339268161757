import React from 'react';
import { Flex } from 'theme-ui';

const LazyAsideFilters = React.lazy(() =>
  import('./AsideFilters').then(({ AsideFilters }) => ({
    default: AsideFilters,
  })),
);

export const AsideFiltersRenderer = React.memo(
  (): React.ReactElement => (
    <Flex as="aside" variant="layouts.authorized.aside.filters">
      <React.Suspense fallback={<></>}>
        <LazyAsideFilters />
      </React.Suspense>
    </Flex>
  ),
);
