/** @jsxImportSource theme-ui */

import React, { useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Modal } from 'components/Modal/Modal';
import { setTimeOffLimitsAction } from 'api/actions/employees/employeesActions';
import { delay } from 'utils/delay';
import { createEvent } from 'utils/createEvent';
import { getNumberField } from 'utils/getNumberField';
import { TimeOffLimitsGroupFieldArray } from 'layouts/Team/forms/formsElements/TimeOffLimitsGroupFieldArray';
import { SetTimeOffLimitsActionProps } from 'api/actions/employees/employeesActions.types';
import { useModal } from 'hooks/useModal/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { organizationSessionPropertySelectorFamily } from 'state/organizationSession';
import { FetchOrganizationSessionResponse } from 'api/actions/organizationSession/organizationSessionActions.types';
import { dateTime } from 'utils/dateTime';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

const currentYear = dateTime().year();

const forceCorrectTypes = ({
  timeOffLimits,
}: Omit<SetTimeOffLimitsActionProps, 'employeesIds'>): Omit<SetTimeOffLimitsActionProps, 'employeesIds'> => ({
  timeOffLimits: timeOffLimits?.map(({ limits, fromYear, ...limitGroup }) => ({
    ...limitGroup,
    fromYear: +fromYear,
    limits: limits.map(({ days, ...limit }) => ({
      ...limit,
      days: getNumberField(days),
    })),
  })),
});

export const SetTimeOffLimits = (): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();

  const [loading, setLoading] = useState(false);
  const { state } = useLocation<{ ids?: string[] }>();
  const { ids } = state || {};
  const formRef = useRef<HTMLFormElement | null>(null);
  const modalBodyRef = useRef<HTMLDivElement | null>(null);
  const timeOffDefaultLimits = useRecoilValue(
    organizationSessionPropertySelectorFamily('timeOffDefaultLimits') as RecoilValueReadOnly<
      FetchOrganizationSessionResponse['timeOffDefaultLimits'] | null
    >,
  );

  const { control, register, handleSubmit } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      timeOffLimits: timeOffDefaultLimits
        ? [
            {
              fromYear: currentYear,
              limits: timeOffDefaultLimits,
              id: _.uniqueId(),
            },
          ]
        : [],
    },
  });

  const { mutate } = useMutation(setTimeOffLimitsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: Omit<SetTimeOffLimitsActionProps, 'employeesIds'>): Promise<boolean> => {
      if (!ids || !ids.length) return false;
      const { error: submitError } = await mutate({
        employeesIds: (ids as string[]) || [],
        ...forceCorrectTypes(body),
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_time_off_bulk.set', message: 'Successfully set!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, ids, addSnackbar],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_time_off_bulk">Set time off limits</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modalBodyRef}>
        <form
          sx={{ width: '340px', maxWidth: '100%' }}
          ref={formRef}
          onSubmit={handleSubmit(onSubmit, onSubmitError)}
          noValidate
        >
          <TimeOffLimitsGroupFieldArray register={register} control={control} scrollParentRef={modalBodyRef} />
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'team.set_time_off_bulk.next', message: 'Next' }),
          },
        ]}
      />
    </>
  );
};
