import { useRecoilValue } from 'recoil';

import { APP_NAME, INEWI_HELP, TRACK_TIME_HELP } from 'constants/common';
import { languageSelector } from 'state/recoilState';

type Props = {
  inEwi?: {
    pl?: string;
    en?: string;
  };
  tracktime24: {
    pl?: string;
    en: string;
  };
};

export const useHelpLink = ({ inEwi, tracktime24 }: Props): string => {
  const language = useRecoilValue(languageSelector);

  if (inEwi && inEwi[language] && APP_NAME === 'inEwi') return `${INEWI_HELP}/${language}${inEwi[language]}`;

  if (tracktime24[language] && APP_NAME === 'TrackTime24')
    return `${TRACK_TIME_HELP}/${language}${tracktime24[language]}`;

  return `${TRACK_TIME_HELP}/en${tracktime24.en}`;
};
