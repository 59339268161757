import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import { overtimeSettingsAction } from 'api/actions/settings/settingsActions';
import {
  FetchTimeTrackingSettingsResponse,
  OvertimeSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { Switch } from 'components/ui/Switch';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { addSnackbar } from 'SnackbarHub/actions';
import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';

type Props = {
  payload: OvertimeSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  overTimeShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  overTimeFormPendingRef: React.MutableRefObject<boolean>;
  overTimeStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

type keys = keyof OvertimeSettingsActionProps;

const MAX_MINUTES = 480 * 60;

export const Overtime = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      overTimeShouldBlockTransitionRef,
      overTimeFormPendingRef,
      overTimeStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchTimeTrackingSettingsResponse>(payloadSelectorFamily('TIME_TRACKING'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const { mutate } = useMutation(overtimeSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<OvertimeSettingsActionProps>(payload);

    const {
      setError,
      register,
      handleSubmit,
      reset: resetForm,
      formState: { errors },
    } = useForm({ defaultValues: payload, mode: 'onTouched', reValidateMode: 'onChange' });

    const dispatchSubmitEvent = () => {
      const submitEvent = createEvent('submit');
      overTimeFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    };

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnChange = () => {
      overTimeShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    };

    const handleOnBlur = useCallback(() => {
      overTimeShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, overTimeShouldBlockTransitionRef, reset, start]);

    const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          handleOnChange();
        },
      };
    };

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      resetForm({ ...payload });
      overTimeShouldBlockTransitionRef.current = false;
      overTimeFormPendingRef.current = false;
    }, [overTimeFormPendingRef, overTimeShouldBlockTransitionRef, payload, resetForm]);

    const handleOvertimeValidation = useCallback(
      (data: OvertimeSettingsActionProps) => {
        const minOvertimeSecondsValue = data.minOvertimeSeconds;

        if (minOvertimeSecondsValue > MAX_MINUTES) {
          setError('minOvertimeSeconds', {
            type: 'validate',
            message: t({ id: 'settings.forms.time_maximum', message: 'Maximum time exceeded' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleSubmitCallback = useCallback(
      async (data: OvertimeSettingsActionProps) => {
        const validation = handleOvertimeValidation(data);
        const overtimeResetObject = { name: 'OVERTIME_CALLBACK', callback: handleFormReset };
        reset();

        if (validation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === overtimeResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(data, payload) && validation) {
          const { error } = await mutate(data);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...data }));
            mutationDataRef.current = data;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [overtimeResetObject] : [...prevState, overtimeResetObject],
            );
            return;
          }
        }

        if (!validation) {
          setResetCallbacks((prevState) => (!prevState ? [overtimeResetObject] : [...prevState, overtimeResetObject]));
          return;
        }

        overTimeShouldBlockTransitionRef.current = false;
        overTimeFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        handleFormReset,
        handleOvertimeValidation,
        mutate,
        overTimeFormPendingRef,
        overTimeShouldBlockTransitionRef,
        payload,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payload)) handleFormReset();
    }, [handleFormReset, payload]);

    useEffect(() => {
      overTimeStatusRef.current = status;
    }, [overTimeStatusRef, status]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    return (
      <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <HeadingError
            label={t({
              id: 'time_tracking_settings.heading.overtime',
              message: 'Overtime',
            })}
            errorMessage={errors.minOvertimeSeconds?.message}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <OptionLabel
              label={t({
                id: 'time_tracking_settings.min_overtime_minutes.label',
                message: 'Threshold before counting daily overtime',
              })}
              additionalLabel={
                <>
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    <Trans id="time_tracking_settings.min_overtime_minutes.additional_label">Requires schedules</Trans>
                  </Text>
                  .
                </>
              }
              apendWith={
                <DurationPicker
                  {...registerOnBlur('minOvertimeSeconds', { valueAsNumber: true })}
                  id="minOvertimeSeconds"
                  size="sm"
                  minutes
                  hours
                  seconds={false}
                  error={!!errors.minOvertimeSeconds}
                />
              }
              withDivider
            />
            <Switch
              {...registerOnChange('autoBalanceOvertime')}
              label={t({
                id: 'time_tracking_settings.auto_balance_overtime.label',
                message: 'Auto-balance overtime',
              })}
              additionalInfo={
                <Text>
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    <Trans id="time_tracking_settings.requires_schedules_and_requests">
                      Requires schedules and requests
                    </Trans>
                  </Text>
                  .{' '}
                  <Trans id="time_tracking_settings.auto_balance_overtime.additional_info">
                    Overtime will be automatically used to fill up missing scheduled work hours.
                  </Trans>
                </Text>
              }
              size="sm"
              withDivider
            />
          </Flex>
        </Flex>
      </form>
    );
  },
);

export const MemoizedOvertime = React.memo(Overtime);
