import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, ThemeUIStyleObject, Text } from 'theme-ui';
import { useRecoilState, useRecoilValue } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { ClientContext } from 'react-fetching-library';
import { i18n } from '@lingui/core';

import { windowSizeAtom } from 'state/recoilState';
import { FaceBoundingBackground } from '../../../components/FaceBoundingBackground';
import { MemorizedScanningPoints } from '../../../components/ScanningPoints';
import { DebugInitialFaceScan } from '../../../components/__debug/DebugInitialFaceScan';
import { FaceBoundingMask } from '../../../components/FaceBoundingMask';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { useCameraImageBuffer } from 'Kiosk/hooks/useCameraImageBuffer';
import { useAnimationFrame } from 'Kiosk/hooks/useAnimationFrame';
import { AnnotatedPrediction } from 'Kiosk/models/face-landmarks-detection/mediapipe-facemesh';
import { useFaceDetection } from '../../../hooks/useFaceLandmarkModel';
import { useInitialFaceScan } from '../../../hooks/useInitialFaceScan';
import {
  initialFaceScanInPlaceAtom,
  initialFaceScanStepAtom,
  InitialFaceScanSteps,
} from 'Kiosk/state/initialFaceScanState';
import { Ellipsis } from 'Kiosk/components/Ellipsis';
import { debugModeSelector } from 'Kiosk/state/kioskState';
import { initPersonModel } from 'api/actions/timeclock/timeclockActions';
import { delay } from 'utils/delay';
import {
  ELLIPSIS_FACE_ROTATION_DISTORTION,
  FACE_BOUNDING_THRESHOLD,
  INITIAL_FACE_SCAN_POINTS_NUMBER,
} from 'Kiosk/constants/constants';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { ErrorCodeInitPersonModel } from 'api/actions/timeclock/timeclockActions.types';
import { Footer } from 'Kiosk/Layout';

const MemorizedFaceBoundingBackground = React.memo(FaceBoundingBackground);
const MemorizedFaceBoundingMask = React.memo(FaceBoundingMask);

const elementsBoxSx: ThemeUIStyleObject = {
  position: 'relative',
  width: '100%',
  height: '100%',
  zIndex: 1,
};

const footerSx: ThemeUIStyleObject = {
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 2,
  textAlign: 'center',
  textShadow: '0px 0px 8px rgba(14, 23, 55, 0.7), 0px 0px 2px rgba(14, 23, 55, 0.5)',
};

type Props = {
  qrCode: string;
};

export const StepScan = ({ qrCode }: Props): React.ReactElement => {
  const [isFrontSnapshotDone, setIsFrontSnapshotDone] = useState(false);
  const [predictionWithFrame, setPredictionWithFrame] =
    useState<{ prediction: AnnotatedPrediction[]; frameBlob: Blob }>();

  const snapshotsRef = useRef<Blob[]>([]);

  const { query } = useContext(ClientContext);

  const debugMode = useRecoilValue(debugModeSelector);
  const { isMobile, isLandscape, height, width } = useRecoilValue(windowSizeAtom);
  const { source } = useRecoilValue(cameraStateAtom);
  const [, setFaceInPlace] = useRecoilState(initialFaceScanInPlaceAtom);
  const [, setStep] = useRecoilState(initialFaceScanStepAtom);
  const [, setOverlay] = useRecoilState(overlayStateAtom);

  const { getPrediction, faceBounding, pointsToScan } = useFaceDetection();
  const { getImageData, getImageBlob } = useCameraImageBuffer();
  const { addSnackbar } = useSnackbar();

  // 1. Scan for face and set predictions
  const scanForFace = async () => {
    const frame = getImageData && (await getImageData());
    const frameBlob = getImageBlob && (await getImageBlob());

    if (frame && frameBlob && getPrediction) {
      const modelPrediction = await getPrediction(frame);
      setPredictionWithFrame({
        prediction: modelPrediction,
        frameBlob,
      });
    }
  };

  const { isAnimationRunning, stopAnimation } = useAnimationFrame({
    callback: scanForFace,
    autostart: true,
  });

  // 2. Create snapshots of Initial Face Scan
  // 3. Query for initFaceModel
  const onPointCallback = useCallback(
    async (frameBlob: Blob) => {
      snapshotsRef.current.push(frameBlob);

      if (snapshotsRef.current.length > INITIAL_FACE_SCAN_POINTS_NUMBER) {
        if (isAnimationRunning) {
          stopAnimation();
        }

        const { payload, error } = await query(
          initPersonModel({ qrCode, images: snapshotsRef.current, useFaceDetection: true }),
        );

        if (!error && payload) {
          await delay(500);
          setStep(InitialFaceScanSteps.SUMMARY);
        }

        if (error && payload) {
          const getMessage = () => {
            if (payload.innerCode === ErrorCodeInitPersonModel.FACE_MODEL_PHOTOS_UPLOAD_ERROR) {
              return i18n._(
                t({
                  id: 'kiosk.initial_face_scan.error.photo_upload',
                  message: 'Error during photo upload, please try again.',
                }),
              );
            }

            if (payload.innerCode === ErrorCodeInitPersonModel.FACE_MODEL_CREATION_ERROR) {
              return i18n._(
                t({
                  id: 'kiosk.initial_face_scan.error.face_model_creation',
                  message: 'Error during face model creation, please try again.',
                }),
              );
            }

            return i18n._(
              t({
                id: 'global.error.unknown',
                message: 'Unexpected error, please contact our support team.',
              }),
            );
          };

          addSnackbar({
            message: getMessage(),
            variant: 'danger',
          });

          setOverlay({ type: KioskOverlay.start });
          setStep(InitialFaceScanSteps.START);
        }
      }
    },
    [addSnackbar, isAnimationRunning, qrCode, query, setOverlay, setStep, stopAnimation],
  );

  const { axisRotation, faceInPlace, scannedPoints } = useInitialFaceScan({
    source,
    prediction: predictionWithFrame?.prediction,
    frameBlob: predictionWithFrame?.frameBlob,
    pointsToScan,
    onPointCallback,
  });

  useEffect(() => {
    // 1.A. Take a frontal face snapshot and push it to a snapshotsRef
    const takeFrontSnapshot = async () => {
      const frameBlob = getImageBlob && (await getImageBlob());
      if (frameBlob) {
        snapshotsRef.current.push(frameBlob);
        setIsFrontSnapshotDone(true);
      }
    };

    setFaceInPlace(faceInPlace);

    if (!isFrontSnapshotDone && faceInPlace) {
      takeFrontSnapshot();
    }

    return () => {
      setFaceInPlace(false);
    };
  }, [faceInPlace, getImageBlob, isFrontSnapshotDone, setFaceInPlace]);

  const webcamDisplayAspectRatio = useMemo(
    () => (source && (isLandscape ? height && height / source.height : width && width / source.width)) || 0,
    [height, isLandscape, source, width],
  );

  const getFaceBoundingData = useCallback(() => {
    if (faceBounding) {
      const size = isLandscape
        ? faceBounding.height * webcamDisplayAspectRatio
        : faceBounding.width * webcamDisplayAspectRatio;
      const sizeThreshold =
        (isMobile ? FACE_BOUNDING_THRESHOLD * 0.5 : FACE_BOUNDING_THRESHOLD) * webcamDisplayAspectRatio;
      const thresholdAspectRatio =
        (FACE_BOUNDING_THRESHOLD / (isLandscape ? faceBounding.height : faceBounding.width)) * webcamDisplayAspectRatio;

      return {
        size,
        sizeThreshold,
        thresholdAspectRatio,
      };
    }

    return null;
  }, [faceBounding, isLandscape, isMobile, webcamDisplayAspectRatio]);
  const faceBoundingData = getFaceBoundingData();

  const scannedPointsAspectRatio = useMemo(
    () =>
      isLandscape
        ? Math.max(
            webcamDisplayAspectRatio + ((faceBoundingData && faceBoundingData.thresholdAspectRatio) || 0) * 1.5,
            1.4,
          )
        : Math.max(
            webcamDisplayAspectRatio + ((faceBoundingData && faceBoundingData.thresholdAspectRatio) || 0) * 0.65,
            0.9,
          ),
    [faceBoundingData, isLandscape, webcamDisplayAspectRatio],
  );

  const faceBoundingCircleSize = useMemo(
    () => (faceBoundingData ? Math.max(faceBoundingData.size + faceBoundingData.sizeThreshold, 280) : 0),
    [faceBoundingData],
  );

  const faceBoundingMaskSize = useMemo(
    () => (faceBoundingData ? Math.max(faceBoundingData.size + faceBoundingData.sizeThreshold, 280) : 0),
    [faceBoundingData],
  );

  // const tilt = axisRotation[2]
  const rotate = useMemo(
    () => ({
      x: 90 + axisRotation[1] * ELLIPSIS_FACE_ROTATION_DISTORTION,
      y: 90 + axisRotation[0] * ELLIPSIS_FACE_ROTATION_DISTORTION,
    }),
    [axisRotation],
  );

  return (
    <>
      <Box sx={elementsBoxSx}>
        <MemorizedFaceBoundingBackground size={faceBoundingCircleSize} faceInPlace={faceInPlace} />
        <MemorizedFaceBoundingMask size={faceBoundingMaskSize} faceInPlace={faceInPlace} />
        {faceInPlace && pointsToScan && (
          <>
            <MemorizedScanningPoints
              scannedPoints={scannedPoints}
              aspectRatio={scannedPointsAspectRatio}
              pointsToScan={pointsToScan}
            />
            <Ellipsis size={faceBoundingCircleSize} rotateX={rotate.x} />
            <Ellipsis size={faceBoundingCircleSize} rotateY={rotate.y} />
          </>
        )}

        {debugMode && predictionWithFrame && (
          <DebugInitialFaceScan prediction={predictionWithFrame.prediction} scannedPoints={scannedPoints} />
        )}
      </Box>

      <Footer sx={footerSx}>
        <Text as="h2" color="white" sx={{ fontSize: [5, null, 6] }}>
          {!faceInPlace ? (
            <Trans id="kiosk.initial_face_scan.face_not_in_place">Position your face inside the frame</Trans>
          ) : (
            <Trans id="kiosk.initial_face_scan.face_in_place">Gently move your head to complete the circle</Trans>
          )}
        </Text>
      </Footer>
    </>
  );
};
