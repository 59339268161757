import { Flex } from 'theme-ui';
import React, { MouseEventHandler } from 'react';
import _ from 'lodash';
import { Trans } from '@lingui/macro';

import { Button, ButtonProps } from 'components/ui/Buttons';
import { Modal } from 'components/Modal/Modal';
import { useModal } from 'hooks/useModal/useModal';

type Props = {
  buttons?: Omit<ButtonProps, 'shape' | 'size'>[];
  prependWith?: React.ReactElement | React.ReactElement[];
  backButtonOnClick?: MouseEventHandler<HTMLButtonElement>;
};

export type BasicModalFooterProps = Props;

const defaultProps: Partial<Props> = {
  prependWith: undefined,
  buttons: undefined,
};

export const BasicModalFooter = ({ buttons, prependWith, backButtonOnClick }: Props): React.ReactElement => {
  const { handleClose } = useModal();
  return (
    <Modal.Footer>
      <>{prependWith && <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>{prependWith}</Flex>}</>
      <Button sx={{ fontWeight: 'normal', minWidth: '100px' }} shape="rounded" variant="minimal" onClick={handleClose}>
        <Trans id="cancel">Cancel</Trans>
      </Button>
      {!!backButtonOnClick && <Modal.BackButton type="footer" onClick={backButtonOnClick} />}
      <>
        {buttons?.map(({ sx, ...rest }) => (
          <Button key={_.uniqueId()} sx={{ minWidth: '100px', ...sx }} {...rest} shape="rounded" />
        ))}
      </>
    </Modal.Footer>
  );
};

BasicModalFooter.defaultProps = defaultProps;
