import { ThemeUIStyleObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

const BACKGROUNDS = {
  icon: {
    default: 'alphas.darker2',
    hover: 'alphas.bluish4',
  },
  uploader: {
    default: 'grayBlues1',
    hover: 'whiteBlues1',
  },
  file: {
    default: 'grayBlues1',
    hover: 'whiteBlues1',
    error: 'reds0',
  },
  progressBar: {
    default: 'whiteBlues3',
    error: 'reds1',
  },
};

const TEXTS = {
  default: 'texts.default',
  error: 'texts.error',
};

const DEFAULT_TRANSITION = '0.15s ease-in-out';

const baseContainerStyles: ThemeUIStyleObject = {
  px: '0.75rem',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 3,
  maxWidth: '260px',
  width: '100%',
  height: '70px',
  borderRadius: 'sm',
  bg: BACKGROUNDS.uploader.default,
  cursor: 'pointer',
  fontSize: 2,
  fontWeight: 'bold',
  color: TEXTS.default,
  transition: `all ${DEFAULT_TRANSITION}`,
};

export const fileUploadStyles = makeTheme({
  fileUpload: {
    errorMessage: {
      display: 'flex',
      width: '100%',
      color: TEXTS.error,
      fontWeight: 'bold',
      fontSize: 2,
    },
    icon: {
      borderRadius: 'sm',
      bg: BACKGROUNDS.icon.default,
      height: '1.875rem',
      width: '1.875rem',
      justifyContent: 'center',
      transition: `all ${DEFAULT_TRANSITION}`,
    },
    smallText: {
      fontSize: 1,
      fontWeight: '400',
      color: TEXTS.default,
      opacity: 0.7,
    },
    progressBar: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      bg: BACKGROUNDS.progressBar.default,
      borderRadius: 'inherit',
      zIndex: -1,
      '&[data-has-error="true"]': {
        bg: BACKGROUNDS.progressBar.error,
      },
    },

    uploader: {
      ...baseContainerStyles,
      outline: '2px dashed',
      outlineColor: 'alphas.darker5',
      '> .heading': {
        '> span': {
          transition: `all ${DEFAULT_TRANSITION}`,
        },
      },
      '&:hover, &[data-state="droppable"]': {
        bg: BACKGROUNDS.uploader.hover,
        outlineColor: 'alphas.bluish7',
        '> .icon': {
          transform: 'scale(1.15)',
          bg: BACKGROUNDS.icon.hover,
        },
      },
      '&:hover': {
        '> .heading': {
          '> span': {
            opacity: 0.24,
            '&:first-of-type': {
              opacity: 1,
            },
          },
        },
      },
      '&[data-state="droppable"]': {
        '> .heading': {
          '> span': {
            opacity: 0.24,
            '&:last-of-type': {
              opacity: 1,
            },
          },
        },
      },
    },

    file: {
      ...baseContainerStyles,
      bg: BACKGROUNDS.file.default,
      alignItems: 'center',
      outline: '1px solid',
      outlineColor: 'alphas.darker5',
      display: 'flex',
      position: 'relative',
      cursor: 'default',
      '&[data-state="uploading"], &[data-state="withError"]': {
        zIndex: 1,
        gap: 1,
      },
      '&[data-state="withError"]': {
        outlineColor: 'reds2',
      },
      '&[data-state="uploading"]': {
        outlineColor: 'alphas.bluish7',
      },
      '&[data-state="notFound"]': {
        outlineColor: 'reds1',
        bg: BACKGROUNDS.file.error,
      },
      '&[data-state="uploaded"]': {
        cursor: 'pointer',
        '&:hover': {
          bg: BACKGROUNDS.file.hover,
          outlineColor: 'alphas.bluish5',
          boxShadow: 'dropShadow.levelOne',
          '> .icon': {
            transform: 'scale(1.15)',
            bg: BACKGROUNDS.icon.hover,
          },
        },
      },
    },
  },
});
