import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';

import { languageSelector } from 'state/recoilState';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { LanguageRoute } from 'constants/routes';
import { REDIRECT as REDIRECT_EVENT } from 'constants/customEventsNames';
import { useRedirectPath } from 'hooks/useRedirectPath/useRedirectPath';

export const RedirectObserver: React.ElementType = () => {
  const language = useRecoilValue(languageSelector);
  const { replace, push } = useHistory();
  const { redirectPath, redirectQuery } = useRedirectPath();

  useEffect(() => {
    if (redirectPath) {
      replace(`${redirectPath}${redirectQuery}`);
    }
  }, [replace, redirectPath, redirectQuery]);

  useCustomEventListener(REDIRECT_EVENT, (redirectTo) => {
    if ((redirectTo as LanguageRoute)[language]) {
      push((redirectTo as LanguageRoute)[language]);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { route, state } = redirectTo as { route: LanguageRoute; state: any };
    push(route[language], state);
  });

  return null;
};
