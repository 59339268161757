import _ from 'lodash';

import { SupportedLanguages } from './supportedLanguages';

const baseRoutes = {
  // AUTHENTICATION
  SIGN_IN: {
    pl: '/zaloguj',
    en: '/sign-in',
  },
  SIGN_IN__APPLE_REDIRECT: {
    pl: '/zaloguj/apple',
    en: '/sign-in/apple',
  },
  SIGN_OUT: {
    pl: '/wyloguj',
    en: '/sign-out',
  },
  SIGN_UP: {
    pl: '/zaloz-konto',
    en: '/sign-up',
  },
  SIGN_UP__ACCOUNT_TYPE: {
    pl: '/zaloz-konto/rodzaj-konta',
    en: '/sign-up/account-type',
  },
  SIGN_UP__EMPLOYEE__INVITED: {
    pl: '/zaloz-konto/pracownik/zaproszony',
    en: '/sign-up/employee/invited',
  },
  SIGN_UP__EMPLOYEE: {
    pl: '/zaloz-konto/pracownik',
    en: '/sign-up/employee',
  },
  SIGN_UP__EMPLOYEE__NOT_INVITED: {
    pl: '/zaloz-konto/pracownik/brak-zaproszenia',
    en: '/sign-up/employee/not-invited',
  },
  SIGN_UP__EMPLOYER: {
    pl: '/zaloz-konto/organizacja',
    en: '/sign-up/company',
  },
  SIGN_UP__EMPLOYER__STEP: {
    pl: '/zaloz-konto/organizacja/:step',
    en: '/sign-up/company/:step',
  },
  PASS_RESET: {
    pl: '/resetowanie-hasla',
    en: '/password-reset',
  },
  PASS_RESET__CONFIRMED: {
    pl: '/resetowanie-hasla/nowe-haslo',
    en: '/password-reset/new-password',
  },
  // HOME
  START: {
    pl: '/',
    en: '/',
  },
  // TEAM
  TEAM: {
    pl: '/zespol',
    en: '/team',
  },
  // WORK STATUSES
  TIME_TRACKING__WORK_STATUSES: {
    pl: '/statusy-pracy',
    en: '/work-statuses',
  },
  // TIME TRACKING
  TIME_TRACKING__CLOCK_LOG: {
    pl: '/zdarzenia',
    en: '/clock-log',
  },
  TIME_TRACKING__LOCATIONS: {
    pl: '/lokalizacje',
    en: '/locations',
  },
  TIME_TRACKING__TIME_CLOCKS: {
    pl: '/urzadzenia',
    en: '/time-clocks',
  },
  // REPORTS
  REPORTS__ATTENDANCE_LIST: {
    pl: '/raporty/lista-obecnosci',
    en: '/reports/attendance-list',
  },
  REPORTS__ATTENDANCE_LIST__DETAILS__USER_ID__DATE_UNIX: {
    pl: '/raporty/lista-obecnosci/szczegoly/:user/:unix',
    en: '/reports/attendance-list/details/:user/:unix',
  },
  REPORTS__TIMESHEETS: {
    pl: '/raporty/ewidencja-czasu-pracy',
    en: '/reports/timesheets',
  },
  REPORTS__TIMESHEETS__DETAILS__USER_ID__DATE_UNIX: {
    pl: '/raporty/ewidencja-czasu-pracy/szczegoly/:user/:unix',
    en: '/reports/timesheets/details/:user/:unix',
  },
  // SETTINGS
  SETTINGS: {
    pl: '/ustawienia',
    en: '/settings',
  },
  SETTINGS__USER: {
    pl: '/ustawienia/uzytkownik',
    en: '/settings/user',
  },
  SETTINGS__USER__PREFERENCES: {
    pl: '/ustawienia/uzytkownik/preferencje',
    en: '/settings/user/preferences',
  },
  SETTINGS__USER__NOTIFICATIONS: {
    pl: '/ustawienia/uzytkownik/powiadomienia',
    en: '/settings/user/notifications',
  },
  SETTINGS__USER__CHANGE_PASSWORD: {
    pl: '/ustawienia/uzytkownik/zmiana-hasla',
    en: '/settings/user/change-password',
  },
  SETTINGS__INTEGRATIONS: {
    pl: '/ustawienia/integracje',
    en: '/settings/integrations',
  },
  SETTINGS__ORGANIZATION: {
    pl: '/ustawienia/organizacja',
    en: '/settings/organization',
  },
  SETTINGS__ORGANIZATION__TIME_TRACKING: {
    pl: '/ustawienia/organizacja/rejestracja-czasu-pracy',
    en: '/settings/organization/time-tracking',
  },
  SETTINGS__ORGANIZATION__SCHEDULES: {
    pl: '/ustawienia/organizacja/harmonogram',
    en: '/settings/organization/schedule',
  },
  SETTINGS__ORGANIZATION__REQUESTS: {
    pl: '/ustawienia/organizacja/wnioski',
    en: '/settings/organization/requests',
  },
  SETTINGS__ORGANIZATION__NOTIFICATIONS: {
    pl: '/ustawienia/organizacja/powiadomienia',
    en: '/settings/organization/notifications',
  },
  SETTINGS__ORGANIZATION__ADVANCED: {
    pl: '/ustawienia/organizacja/zaawansowane',
    en: '/settings/organization/advanced',
  },
  // KIOSK
  KIOSK: {
    pl: '/kiosk',
    en: '/kiosk',
  },
  REQUESTS: {
    pl: '/wnioski',
    en: '/requests',
  },
  KIOSK_SUMMARY: {
    pl: '/kiosk/podsumowanie',
    en: '/kiosk/summary',
  },
  KIOSK__CAMERA_PERMISSION: {
    pl: '/kiosk/kamera-wymagana',
    en: '/kiosk/camera-permission',
  },
  KIOSK__LOCATION_PERMISSION: {
    pl: '/kiosk/lokalizacja-wymagana',
    en: '/kiosk/location-permission',
  },
  // OTHER
  PRIVATE: {
    pl: '/prywatna/edit',
    en: '/private/edit',
  },
  PUBLIC: {
    pl: '/publiczna/2',
    en: '/public/2',
  },
  // ERRORS
  SERVICE_UNAVAILABLE: {
    pl: '/503',
    en: '/503',
  },
  PAGE_NOT_FOUND: {
    pl: '/404',
    en: '/404',
  },
};

const modalRoutes = {
  EDIT_MODAL__STEP: {
    pl: '/edytuj/:step',
    en: '/edit/:step',
  },
  // KIOSK
  KIOSK_LOCATION_MODAL: {
    pl: '/lokalizacja',
    en: '/location',
  },
  KIOSK_SETTINGS_MODAL: {
    pl: '/ustawienia',
    en: '/settings',
  },
  KIOSK_HELP_CENTER_MODAL: {
    pl: '/centrum-pomocy',
    en: '/help-center',
  },
  KIOSK_HELP_CENTER_MODAL__STEP: {
    pl: '/centrum-pomocy/:step',
    en: '/help-center/:step',
  },
  // TEAMMATES
  ADD_TEAMMATE_MODAL: {
    pl: '/dodaj-pracownika',
    en: '/add-teammate',
  },
  DELETE_TEAMMATES_MODAL: {
    pl: '/usun-pracownika',
    en: '/delete-teammate',
  },
  DEACTIVATE_TEAMMATES_MODAL: {
    pl: '/dezaktywuj-pracownika',
    en: '/deactivate-teammate',
  },
  INVITE_TEAMMATES_MODAL: {
    pl: '/zapros-pracownikow',
    en: '/invite-teammates',
  },
  RE_INVITE_TEAMMATES_MODAL: {
    pl: '/ponownie-zapros-pracownikow',
    en: '/re-invite-teammates',
  },
  PRINT_QR_CARDS_MODAL: {
    pl: '/drukuj-karty-qr',
    en: '/print-qr-cards',
  },
  EDIT_TEAMMATE_MODAL__ID: {
    pl: '/edytuj-pracownika/:id',
    en: '/edit-teammate/:id',
  },
  VIEW_SETTINGS_MODAL: {
    pl: '/ustawienia-widoku',
    en: '/view-settings',
  },
  // TAGS
  MANAGE_TAGS_MODAL: {
    pl: '/zarzadzaj-tagami',
    en: '/manage-tags',
  },
  ADD_TAG_MODAL: {
    pl: '/dodaj-tag',
    en: '/add-tag',
  },
  EDIT_TAG_MODAL__ID: {
    pl: '/edytuj-tag/:id',
    en: '/edit-tag/:id',
  },
  DELETE_TAGS_MODAL: {
    pl: '/usun-tagi',
    en: '/delete-tags',
  },
  // ROLES
  MANAGE_ROLES_MODAL: {
    pl: '/zarzadzaj-rolami',
    en: '/manage-roles',
  },
  ADD_ROLE_MODAL: {
    pl: '/dodaj-role',
    en: '/add-role',
  },
  EDIT_ROLE_MODAL__ID: {
    pl: '/edytuj-role/:id',
    en: '/edit-role/:id',
  },
  PREVIEW_ROLE_MODAL__ID: {
    pl: '/podglad-roli/:id',
    en: '/preview-role/:id',
  },
  DELETE_ROLES_MODAL: {
    pl: '/usun-role',
    en: '/delete-roles',
  },
  // WORK_POSITIONS
  MANAGE_WORK_POSITIONS_MODAL: {
    pl: '/zarzadzaj-pozycjami',
    en: '/manage-positions',
  },
  ADD_WORK_POSITION_MODAL: {
    pl: '/dodaj-pozycje',
    en: '/add-position',
  },
  EDIT_WORK_POSITION_MODAL__ID: {
    pl: '/edytuj-pozycje/:id',
    en: '/edit-position/:id',
  },
  DELETE_WORK_POSITIONS_MODAL: {
    pl: '/usun-pozycje',
    en: '/delete-positions',
  },
  // BULK_ACTIONS
  BULK_ACTIONS_MODAL: {
    pl: '/akcje-zbiorowe',
    en: '/bulk-actions',
  },
  BULK_ACTIONS_MODAL__RESEND_INVITATION: {
    pl: '/akcje-zbiorowe/ponownie-wyslij-zaproszenie',
    en: '/bulk-actions/resend-invitation',
  },
  BULK_ACTIONS_MODAL__SET_POSITION: {
    pl: '/akcje-zbiorowe/ustaw-pozycje',
    en: '/bulk-actions/set-position',
  },
  BULK_ACTIONS_MODAL__CLEAR_POSITION: {
    pl: '/akcje-zbiorowe/wyczysc-pozycje',
    en: '/bulk-actions/clear-position',
  },
  BULK_ACTIONS_MODAL__SET_PAY_DETAILS: {
    pl: '/akcje-zbiorowe/ustaw-szczegoly-wyplaty',
    en: '/bulk-actions/set-pay-details',
  },
  BULK_ACTIONS_MODAL__SET_ROLE: {
    pl: '/akcje-zbiorowe/ustaw-role',
    en: '/bulk-actions/set-role',
  },
  BULK_ACTIONS_MODAL__SET_TAGS: {
    pl: '/akcje-zbiorowe/ustaw-tagi',
    en: '/bulk-actions/set-tags',
  },
  BULK_ACTIONS_MODAL__CLEAR_TAGS: {
    pl: '/akcje-zbiorowe/wyczysc-tagi',
    en: '/bulk-actions/clear-tags',
  },
  BULK_ACTIONS_MODAL__SET_TIME_OFF_LIMITS: {
    pl: '/akcje-zbiorowe/ustaw-limity-urlopow',
    en: '/bulk-actions/set-time-off-limits',
  },
  BULK_ACTIONS_MODAL__SET_ADVANCED_DETAILS: {
    pl: '/akcje-zbiorowe/ustaw-zaawansowane-szczegoly',
    en: '/bulk-actions/set-advanced-details',
  },
  // REQUESTS
  DELETE_REQUESTS_MODAL: {
    pl: '/usun-wniosek',
    en: '/delete-request',
  },
  REJECT_REQUESTS_MODAL: {
    pl: '/odrzuc-wniosek',
    en: '/reject-request',
  },
  REQUEST_OVERVIEW_MODAL: {
    pl: '/przeglad-wniosku',
    en: '/request-overview',
  },
  REQUEST_DETAILS__ID: {
    pl: '/szczegoly-wniosku/:id',
    en: '/request-details/:id',
  },
  ADD_REQUEST: {
    pl: '/dodaj-wniosek',
    en: '/add-request',
  },
  // LOCATIONS
  ADD_LOCATION_MODAL: {
    pl: '/dodaj-lokalizacje',
    en: '/add-location',
  },
  EDIT_LOCATION_MODAL__ID: {
    pl: '/edytuj-lokalizacje/:id',
    en: '/edit-location/:id',
  },
  DELETE_LOCATIONS_MODAL: {
    pl: '/usun-lokalizacje',
    en: '/delete-locations',
  },
  SHOW_LOCATION_MODAL__ID: {
    pl: '/pokaz-lokalizacje/:id',
    en: '/show-location/:id',
  },
  // HOLIDAYS
  ADD_HOLIDAY_MODAL: {
    pl: '/dodaj-swieto',
    en: '/add-holiday',
  },
  EDIT_HOLIDAY_MODAL: {
    pl: '/edytuj-swieto/:id',
    en: '/edit-holiday/:id',
  },
  IMPORT_HOLIDAYS_MODAL: {
    pl: '/import-swieta/:year',
    en: '/import-holidays/:year',
  },
  DELETE_HOLIDAY_MODAL: {
    pl: '/usun-swieto/:id',
    en: '/delete-holiday/:id',
  },
  // TIME OFF TYPES
  ADD_TIME_OFF_TYPE_MODAL: {
    pl: '/dodaj-czas-wolny-rodzaje',
    en: '/add-time-off-type',
  },
  EDIT_TIME_OFF_TYPE_MODAL: {
    pl: '/edytuj-czas-wolny-rodzaje/:id',
    en: '/edit-time-off-type/:id',
  },
  DELETE_TIME_OFF_TYPE_MODAL: {
    pl: '/usun-czas-wolny-rodzaje/usun',
    en: '/delete-time-off-type/delete',
  },
  // CUSTON REQUEST TYPES
  ADD_CUSTOM_REQUEST_TYPE_MODAL: {
    pl: '/dodaj-niestandardowe-typy-zadan',
    en: '/add-custom-request-type',
  },
  EDIT_CUSTOM_REQUEST_TYPE_MODAL: {
    pl: '/edytuj-niestandardowe-typy-zadan/:id',
    en: '/edit-custom-request-type/:id',
  },
  DELETE_CUSTOM_REQUEST_TYPE_MODAL: {
    pl: '/usun-niestandardowe-typy-zadan/usun',
    en: '/delete-custom-request-type/delete',
  },
  // WEBHOOK
  WEBHOOK_MODAL: {
    pl: '/webhook',
    en: '/webhook',
  },
  ADD_WEBHOOK_MODAL: {
    pl: '/dodaj',
    en: '/add',
  },
  EDIT_WEBHOOK_MODAL: {
    pl: '/edytuj/:id',
    en: '/edit/:id',
  },
  DELETE_WEBHOOK_MODAL: {
    pl: '/usun',
    en: '/delete',
  },
  // ZUS
  ZUS_MODAL: {
    pl: '/zus',
    en: '/zus',
  },
  // CLOCK LOG
  ADD_TIME_EVENTS_MODAL: {
    pl: '/dodaj-zdarzenia',
    en: '/add-time-events',
  },
  DELETE_TIME_EVENTS_MODAL: {
    pl: '/usun-zdarzenia',
    en: '/delete-events',
  },
  CLOCK_LOG_VIEW_SETTINGS_MODAL: {
    pl: '/ustawienia-widoku',
    en: '/view-settings',
  },
  SHOW_TIME_EVENT_PHOTO_MODAL__ID: {
    pl: '/pokaz-zdjecie/:id',
    en: '/show-photo/:id',
  },
  SHOW_MODIFICATION_TIMELINE_MODAL__ID: {
    pl: '/pokaz-historie-zmian/:id',
    en: '/show-modification-timeline/:id',
  },
  SHOW_COORDINATES_MODAL__ID: {
    pl: '/pokaz-wspolrzedne/:id',
    en: '/show-coordinates/:id',
  },
  EDIT_TIME_EVENT_MODAL__ID: {
    pl: '/edytuj-zdarzenie/:id',
    en: '/edit-event/:id',
  },
  MANAGE_WORK_STATUSES_MODAL: {
    pl: '/zarzadzaj-statusami-pracy',
    en: '/manage-work-statuses',
  },
  MANAGE_LOCATIONS_MODAL: {
    pl: '/zarzadzaj-lokalizacjami',
    en: '/manage-locations',
  },
  // WORK STATUSES
  EDIT_WORK_STATUSES_SETTINGS_MODAL: {
    pl: '/ustawienia-statusow-pracy',
    en: '/work-statuses-settings',
  },
  ADD_WORK_STATUS_MODAL: {
    pl: '/dodaj-status-pracy',
    en: '/add-work-status',
  },
  EDIT_WORK_STATUS_MODAL__ID: {
    pl: '/edytuj-status-pracy/:id',
    en: '/edit-work-status/:id',
  },
  DELETE_WORK_STATUSES_MODAL: {
    pl: '/usun-statusy-pracy',
    en: '/delete-work-statuses',
  },
  PREVIEW_WORK_STATUS_MODAL__ID: {
    pl: '/szczegoly-statusu-pracy/:id',
    en: '/work-status-details/:id',
  },
  // TIME CLOCKS
  DELETE_TIME_CLOCKS_MODAL: {
    pl: '/usun',
    en: '/delete',
  },
  EDIT_TIME_CLOCK_MODAL__ID: {
    pl: '/edytuj/:id',
    en: '/edit/:id',
  },
  ADD_TIME_CLOCK_NOTE_MODAL__ID: {
    pl: '/dodaj-notatke/:id',
    en: '/add-note/:id',
  },
  // REPORTS
  DOWNLOAD_MODAL__EXTENSTION: {
    pl: '/pobierz/:extension',
    en: '/download/:extension',
  },
  DOWNLOAD_DETAILED_MODAL__EXTENSION: {
    pl: '/pobierz-szczegolowy/:extension',
    en: '/download-detailed/:extension',
  },
  EXPORT_TO_MODAL: {
    pl: '/eksport',
    en: '/export',
  },
  EXPORT_TO_MODAL__TYPE: {
    pl: '/eksport/:type',
    en: '/export/:type',
  },
  PRINT_MODAL: {
    pl: '/drukuj',
    en: '/print',
  },
  PRINT_MODAL__TYPE: {
    pl: '/drukuj/:type',
    en: '/print/:type',
  },
  PRINT_MODAL__USER__UNIX: {
    pl: '/drukuj/:user/:unix',
    en: '/print/:user/:unix',
  },
};

// FIXME: ONLY FOR TESTING. Remember to remove!
const testPages = Array.from(Array(40)).reduce(
  (acc, page, index) => ({ ...acc, [`P${index + 1}`]: { en: `/p${index + 1}`, pl: `/p${index + 1}` } }),
  {},
);

export type LanguageRoute = {
  [K in keyof typeof SupportedLanguages]: string;
};

type AllRoutes = typeof baseRoutes & typeof modalRoutes;

export type Routes = {
  [K in keyof AllRoutes]: LanguageRoute;
};

const ROUTES: Routes = {
  ...testPages,
  ...baseRoutes,
  ...modalRoutes,
};

export const PATH = ROUTES;

export const TO = _.mapValues(ROUTES, (route) =>
  _.mapValues(route, (translatedRoute) => _.replace(translatedRoute, /(\/:).*/, '')),
);

// HELPERS
export const getRoutesRegExp = (language?: keyof typeof SupportedLanguages): RegExp =>
  new RegExp(
    `^((${_.flatMap(PATH, (pathLanguages) => {
      if (!language) {
        return _.flatMap(pathLanguages, (path) => path.replace(/(\/:[a-z0-9-]+).*?/g, '/[a-z0-9-]+'));
      }

      return pathLanguages[language].replace(/(\/:[a-z0-9-]+).*?/g, '/[a-z0-9-]+');
    }).join('|\\')})*)$`,
    'g',
  );

// REDIRECTS
const getRedirectRoute = (routes: LanguageRoute[]): LanguageRoute =>
  _.reduce(routes, (acc, r) => _.mergeWith(acc, r, _.add), {}) as LanguageRoute;

export enum RedirectKeys {
  ADD_TEAMMATE_MODAL = 0,
  EDIT_TEAMMATE = 1,
  PASS_RESET__CONFIRMED = 2,
  SIGN_UP__EMPLOYEE = 3,
}

export const REDIRECT_ROUTES = (redirectKey: RedirectKeys): LanguageRoute => {
  switch (redirectKey) {
    case RedirectKeys.ADD_TEAMMATE_MODAL:
      return getRedirectRoute([TO.TEAM, TO.ADD_TEAMMATE_MODAL]);
    case RedirectKeys.EDIT_TEAMMATE:
      return getRedirectRoute([TO.TEAM, TO.EDIT_TEAMMATE_MODAL__ID]);
    case RedirectKeys.PASS_RESET__CONFIRMED:
      return getRedirectRoute([TO.PASS_RESET__CONFIRMED]);
    case RedirectKeys.SIGN_UP__EMPLOYEE:
      return getRedirectRoute([TO.SIGN_UP__EMPLOYEE]);

    default:
      return getRedirectRoute([TO.PAGE_NOT_FOUND]);
  }
};
