import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { qrScannerWorkerAtom } from 'Kiosk/state/qrScannerState';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const QrScannerProvider = ({ children }: Props): React.ReactElement => {
  const [loaded, setLoaded] = useState(false);
  const [, setWebWorker] = useRecoilState(qrScannerWorkerAtom);

  const webWorker = useRef<Worker | null>(null);

  useEffect(() => {
    if (!loaded) {
      webWorker.current = new Worker('../workers/qrDecoder.worker.ts', { type: 'module', name: 'qrScanner' });
      setLoaded(true);
    }
  }, [loaded]);

  useEffect(() => {
    setWebWorker(webWorker.current);
  }, [setWebWorker]);

  return <>{children}</>;
};
