import * as tf from '@tensorflow/tfjs-core';
import * as tfconv from '@tensorflow/tfjs-converter';
import { BlazeFaceModel } from '@tensorflow-models/blazeface';

// const BLAZEFACE_MODEL_URL = 'https://tfhub.dev/tensorflow/tfjs-model/blazeface/1/default/1';
// const BLAZEFACE_MODEL_URL = 'https://ufoware.pl/app/tfjs_models/blazeface/model.json';
const basename = process.env.PUBLIC_URL || '';

const BLAZEFACE_MODEL_URL = `${basename}/tfjs_models/blazeface/model.json`;

interface BlazeFaceConfig {
  maxFaces?: number;
  inputWidth?: number;
  inputHeight?: number;
  iouThreshold?: number;
  scoreThreshold?: number;
  modelUrl?: string | tf.io.IOHandler;
}

/**
 * Load blazeface.
 *
 * @param config A configuration object with the following properties:
 *  `maxFaces` The maximum number of faces returned by the model.
 *  `inputWidth` The width of the input image.
 *  `inputHeight` The height of the input image.
 *  `iouThreshold` The threshold for deciding whether boxes overlap too
 * much.
 *  `scoreThreshold` The threshold for deciding when to remove boxes based
 * on score.
 */
export async function load({
  maxFaces = 1,
  inputWidth = 128,
  inputHeight = 128,
  iouThreshold = 0.5,
  scoreThreshold = 0.75,
}: BlazeFaceConfig = {}): Promise<BlazeFaceModel> {
  const blazeface = await tfconv.loadGraphModel(BLAZEFACE_MODEL_URL);

  const model = new BlazeFaceModel(blazeface, inputWidth, inputHeight, maxFaces, iouThreshold, scoreThreshold);
  return model;
}

export { BlazeFaceModel } from '@tensorflow-models/blazeface';
export type { NormalizedFace, BlazeFacePrediction } from '@tensorflow-models/blazeface';
