import React, { useCallback } from 'react';
import { Box, Flex, Heading, Image, Text } from 'theme-ui';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-fetching-library';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Tag } from 'components/Tag/Tag';
import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { getSrcSet } from 'utils/getSrcSet';
import { Button } from 'components/ui/Buttons';
import { TO } from 'constants/routes';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { languageSelector } from 'state/recoilState';
import { integrationsDisconnectAction } from 'api/actions/integrations/integrationActions';
import { refreshSelectorFamily } from 'state/settings';

import { disconnectSx } from './styles/styles';

type Props = {
  isActive: boolean;
  heading: string;
  description: string;
  image: string;
};

export const ZusIntegration = ({ isActive, heading, description, image }: Props): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const match = useCleanRouteMatch();
  const history = useHistory();
  const { mutate } = useMutation(integrationsDisconnectAction);
  const refreshIntegrations = useRecoilValue(refreshSelectorFamily('INTEGRATIONS'));

  const handleConnectButtonCallback = useCallback(
    (reconnect: boolean) => {
      history.push(`${match}${TO.ZUS_MODAL[language]}`, { reconnect });
    },
    [history, language, match],
  );

  const handleDisconnectButtonCallback = useCallback(async () => {
    const { error } = await mutate({ integration: ServiceIntegration.ZusEzla });

    if (!error) refreshIntegrations();
  }, [mutate, refreshIntegrations]);

  return (
    <Flex
      sx={{
        bg: 'whites0',
        flexWrap: 'wrap',
        flexShrink: 0,
        width: '300px',
        minHeight: '180px',
        overflow: 'hidden',
        borderRadius: 'default',
        p: 3,
        boxShadow: '0 2px 8px rgba(42, 65, 111, 0.04)',
        ...(isActive && {
          position: 'relative',
          boxSizing: 'border-box',
          border: '2px solid',
          borderColor: 'greens4',
        }),
      }}
    >
      {isActive && (
        <Tag
          title={t({ id: 'enabled' })}
          variant="solid"
          color="greens4"
          sx={{
            position: 'absolute',
            top: 3,
            right: 3,
            fontSize: 0,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'greens4',
            bg: 'greens4',
            color: 'rgba(14, 23, 55, 0.6)',
          }}
        >
          <Trans id="enabled" />
        </Tag>
      )}
      <Box>
        <Image src={image} srcSet={getSrcSet(image)} alt={heading} />

        <Heading variant="heading4" sx={{ mb: [0, 0, 0] }}>
          {heading}
        </Heading>
        <Box>
          <Text sx={{ fontSize: 2 }}>{description}</Text>
        </Box>
      </Box>
      <Box sx={{ mt: 'auto', flexBasis: '100%' }}>
        {!isActive && (
          <Button variant="success" size="sm" shape="rounded" onClick={() => handleConnectButtonCallback(false)}>
            <Trans id="connect" />
          </Button>
        )}
        {isActive && (
          <>
            <Button variant="success" size="sm" shape="rounded" onClick={() => handleConnectButtonCallback(true)}>
              <Trans id="reconnect" />
            </Button>
            <Button variant="naked" size="sm" sx={disconnectSx} onClick={handleDisconnectButtonCallback}>
              <Trans id="disconnect" />
            </Button>
          </>
        )}
      </Box>
    </Flex>
  );
};
