import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PATH } from 'constants/routes';
import { HomePage } from 'pages/Home/Home';
import { languageSelector } from 'state/recoilState';
import { UUID } from 'utils/UUID';
import { SettingsPage } from 'pages/Settings/Settings';
import { Team } from 'pages/Team/Team';
import { ClockLogRenderer } from 'pages/ClockLog/ClockLogRenderer';
import { Requests } from 'pages/Requests/Requests';
import { SignOut } from 'pages/SignOut/SignOut';
import { DayDetailsRenderer } from 'pages/reports/DayDetails';
import { AttendanceListRenderer } from 'pages/reports/AttendanceList/AttendanceListRenderer';
import { WorkStatusesRenderer } from 'pages/WorkStatuses/WorkStatusesRenderer';
import { TimeClocksRenderer } from 'pages/TimeClocks/TimeClocksRenderer';
import { LocationsRenderer } from 'pages/Locations/LocationsRenderer';
import { TimesheetsRenderer } from 'pages/reports/Timesheets/TimesheetsRenderer';

import { AuthorizedRoute } from './AuthorizedRoute';

export const AuthorizedAppRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  return (
    <>
      <Switch>
        {/* FIXME: TEST ROUTES. Remember to remove! */}
        {Array.from(Array(40)).map((n, index) => (
          <AuthorizedRoute key={UUID()} path={`/p${index + 1}`}>
            <h1>Page {index + 1}</h1>
          </AuthorizedRoute>
        ))}
        <Route exact path={PATH.SIGN_OUT[language]} component={SignOut} />
        <AuthorizedRoute path={PATH.SETTINGS[language]}>
          <SettingsPage />
        </AuthorizedRoute>
        <AuthorizedRoute path={PATH.TEAM[language]}>
          <Team />
        </AuthorizedRoute>
        <AuthorizedRoute path={PATH.TIME_TRACKING__CLOCK_LOG[language]} component={ClockLogRenderer} />{' '}
        <AuthorizedRoute path={PATH.REQUESTS[language]}>
          <Requests />
        </AuthorizedRoute>
        <AuthorizedRoute path={PATH.TIME_TRACKING__WORK_STATUSES[language]}>
          <WorkStatusesRenderer />
        </AuthorizedRoute>
        <AuthorizedRoute path={PATH.TIME_TRACKING__LOCATIONS[language]}>
          <LocationsRenderer />
        </AuthorizedRoute>
        <AuthorizedRoute path={PATH.TIME_TRACKING__TIME_CLOCKS[language]}>
          <TimeClocksRenderer />
        </AuthorizedRoute>
        <AuthorizedRoute path={[PATH.REPORTS__ATTENDANCE_LIST[language], PATH.REPORTS__TIMESHEETS[language]]}>
          <Switch>
            <AuthorizedRoute path={PATH.REPORTS__ATTENDANCE_LIST[language]}>
              <AttendanceListRenderer />
            </AuthorizedRoute>
            <AuthorizedRoute path={PATH.REPORTS__TIMESHEETS[language]}>
              <TimesheetsRenderer />
            </AuthorizedRoute>
          </Switch>

          <AuthorizedRoute
            path={[
              PATH.REPORTS__ATTENDANCE_LIST__DETAILS__USER_ID__DATE_UNIX[language],
              PATH.REPORTS__TIMESHEETS__DETAILS__USER_ID__DATE_UNIX[language],
            ]}
          >
            <DayDetailsRenderer />
          </AuthorizedRoute>
        </AuthorizedRoute>
        <AuthorizedRoute path={PATH.START[language]}>
          <HomePage />
        </AuthorizedRoute>
      </Switch>
    </>
  );
};
