import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

export const usePathnameMatch = (pathnames: string[]): boolean => {
  const { pathname } = useLocation();
  const language = useRecoilValue(languageSelector);
  const [active, setActive] = useState<boolean>(false);

  const isIncludedInPath = pathnames.some((path) => pathname.includes(path));
  const isLocationStart = pathname === TO.START[language];
  const pathnamesIsStartPathOnly = pathnames.every((path) => path === TO.START[language]);

  useEffect(() => {
    if (!isLocationStart && !pathnamesIsStartPathOnly) {
      setActive(isIncludedInPath);
    } else {
      setActive(false);
    }

    if (isLocationStart && pathnamesIsStartPathOnly) {
      setActive(isIncludedInPath);
    }
  }, [isIncludedInPath, isLocationStart, pathnamesIsStartPathOnly]);

  return active;
};
