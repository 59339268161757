import { useRecoilValue } from 'recoil';
import React, { useCallback } from 'react';
import { Text } from 'theme-ui';
import { t, plural, Trans, Plural } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { workPositionsMapSelector } from 'state/employees';
import { deleteWorkPositionsAction } from 'api/actions/workPositions/workPositionsActions';

import { DeleteDeactivateModal } from './modalsElements/DeleteDeactivateModal';

export const DeleteWorkPositionsModal = (): React.ReactElement => {
  useLingui();
  const workPositions = useRecoilValue(workPositionsMapSelector);

  const titleRenderer = useCallback(
    (count: number, name: string) =>
      t({
        id: 'team.delete_work_pos',
        message: plural(count, { one: `Delete position - ${name}?`, other: 'Delete positions?' }),
      }),
    [],
  );

  const contentRenderer = useCallback(
    (count: number, selectedNames: string[]) => (
      <Trans id="team.delete_work_pos.desc">
        <Text>
          You're about to permanently delete{' '}
          <Plural
            value={count}
            one="this position"
            other={
              <Trans>
                positions: <strong>{selectedNames.join(', ')}.</strong>
              </Trans>
            }
          />
          <strong> This action cannot be reversed.</strong>
        </Text>
        <Text>
          All users that had <Plural value={count} one="this position" other="these positions" /> set as a default will
          be switched to default unspecified position.
        </Text>
      </Trans>
    ),
    [],
  );

  return (
    <DeleteDeactivateModal
      list={workPositions}
      namePath="name"
      action={deleteWorkPositionsAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
    />
  );
};
