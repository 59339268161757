import React, { useState } from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useMutation } from 'react-fetching-library';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useGoogleSignIn } from 'hooks/useGoogleSignIn/useGoogleSignIn';
import { googleLinkAction } from 'api/actions/integrations/integrationActions';
import { GOOGLE_CLIENT_ID } from 'constants/common';

import { ExternalIntegration } from './ExternalIntegration';

type Props = {
  isActive: boolean;
  image: string;
};

export const GoogleIntegration = ({ isActive, image }: Props): React.ReactElement => {
  useLingui();
  const [active, setActive] = useState(isActive);

  const { mutate } = useMutation(googleLinkAction);

  const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('tokenId' in res) {
      const { error } = await mutate({ googleToken: res.tokenId });

      if (!error) {
        setActive(true);
      }
    }
  };

  const handleGoogleIntegrationOnClick = useGoogleSignIn(GOOGLE_CLIENT_ID, onSuccess);

  return (
    <ExternalIntegration
      heading="Google"
      image={image}
      description={t({
        id: 'settings.integrations.google',
        message: 'Use you Google Account to quickly sign into our platform.',
      })}
      isActive={active}
      connectButton={{
        text: t({ id: 'connect' }),
        onClick: handleGoogleIntegrationOnClick,
      }}
      disconnectButton={{ text: t({ id: 'disconnect' }) }}
    />
  );
};
