import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { FetchUserSessionResponse } from 'api/actions/userSession/userSessionActions.types';
import { SET_RECOIL_STATE, REDIRECT } from 'constants/customEventsNames';
import { TO } from 'constants/routes';
import { addNotification } from 'NotificationHub/actions';
import { PublisherAddNotificationProps } from 'NotificationHub/types';
import { emitCustomEvent } from 'utils/customEvents';
import { isOfType } from 'utils/isOfType';

import { actionNames } from './actionNames';
import { ChannelAction, SetRecoilStateEventAction } from './channelReducer.types';

export const broadcastChannelReducer = ({ type, payload }: ChannelAction): void => {
  let setRecoilStateAction: SetRecoilStateEventAction | null = null;
  switch (type) {
    case actionNames.REFRESH_USER_SESSION: {
      if (!payload || !isOfType<FetchUserSessionResponse>(payload, 'preferredLanguage')) break;

      setRecoilStateAction = {
        payload,
        recoilKey: 'userSession',
      };
      break;
    }
    case actionNames.CHANGE_LANGUAGE: {
      if (!payload || typeof payload !== 'string') break;
      setRecoilStateAction = {
        payload,
        recoilKey: 'language',
      };
      break;
    }
    case actionNames.SIGN_OUT: {
      emitCustomEvent(REDIRECT, { route: TO.SIGN_OUT, state: { redirectInitiator: 'BROADCAST_CHANNEL' } });
      break;
    }
    case actionNames.ADD_NOTIFICATION: {
      if (!payload || !isOfType<PublisherAddNotificationProps>(payload, 'type')) break;
      addNotification(payload);
      break;
    }
    case actionNames.ADD_REFRESH_PAGE_NOTIFICATION: {
      let message: null | string = null;
      if (typeof payload === 'string') {
        message = payload;
      }
      addNotification({
        title:
          message ||
          i18n._(
            t({
              id: 'channel_reducer.refresh_page_notification.message',
              message: 'New data is available, please refresh the page for an update.',
            }),
          ),
        isStatic: true,
        type: 'info',
        actions: [
          {
            title: i18n._(
              t({
                id: 'channel_reducer.refresh_page_notification.title',
                message: 'Refresh',
              }),
            ),
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });

      break;
    }
    default:
      break;
  }

  if (setRecoilStateAction) {
    emitCustomEvent(SET_RECOIL_STATE, setRecoilStateAction);
  }
};
