import { useEffect, useRef, useState } from 'react';

import { useQuery } from 'hooks/useQuery/useQuery';

type UseSearchParam = (param: string, once?: boolean, pathname?: string) => string | null;

export const useSearchParam: UseSearchParam = (param, once?, pathname?) => {
  const [value, setValue] = useState<string | null>(null);
  const calledOnce = useRef<boolean>(false);
  const query = useQuery(pathname);

  useEffect(() => {
    if (!once || (!calledOnce.current && once)) {
      setValue(query.get(param));
      calledOnce.current = true;
    }
  }, [once, param, query]);

  return value;
};
