import React from 'react';
import { useRecoilValue } from 'recoil';

import { PATH } from 'constants/routes';
import { WebhookModal } from 'layouts/Settings/Integrations/modals/WebhookModal';
import { ModalRoute } from 'routing/ModalRoute';
import { languageSelector } from 'state/recoilState';
import { ZusModal } from 'layouts/Settings/Integrations/modals/ZusModal';

export const ModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <ModalRoute size="xs" path={PATH.WEBHOOK_MODAL[language]} fullHeight>
        <WebhookModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.ZUS_MODAL[language]}>
        <ZusModal />
      </ModalRoute>
    </>
  );
};
