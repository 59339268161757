import { FetchOrganizationSessionResponse } from 'api/actions/organizationSession/organizationSessionActions.types';
import { OrganizationSessionAtomType } from 'state/organizationSession';

export const getParsedOrganizationSession = (
  organizationSession: FetchOrganizationSessionResponse,
): OrganizationSessionAtomType => {
  const { employees, workPositions, tags, roles } = organizationSession;

  const employeesMap = new Map(employees.map((employee) => [employee.id, employee]));
  const tagsMap = new Map(tags.map((tag) => [tag.id, tag]));
  const rolesMap = new Map(roles.map((role) => [role.id, role]));
  const workPositionsMap = new Map(workPositions.map((workPosition) => [workPosition.id, workPosition]));

  return { ...organizationSession, employeesMap, tagsMap, rolesMap, workPositionsMap };
};
