import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Heading, Text } from 'theme-ui';

import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { PreferencesForm } from 'layouts/Settings/User/forms/PreferencesForm';
import { dateFormatSelector, fullTimeFormatSelector } from 'state/recoilState';
import { resetFormButtonAtom, resetFormButtonCallbacksSelector, userPreferencesSelector } from 'state/settings';

export const Preferences = (): React.ReactElement => {
  useLingui();
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);

  const userPreferences = useRecoilValue(userPreferencesSelector);
  const dateFormat = useRecoilValue(dateFormatSelector);
  const timeFormat = useRecoilValue(fullTimeFormatSelector);

  const handleResetButtonClick = () => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  };

  return (
    <>
      <HeadingTip
        label={t({
          id: 'settings.user.preferences.header',
          message: 'Preferences',
        })}
        displayReset={!!formResetCallbacks}
        displayOnClick={() => handleResetButtonClick()}
      />
      {userPreferences ? (
        <Flex sx={{ flexDirection: 'column', maxWidth: '600px' }}>
          <PreferencesForm payload={userPreferences} />
          <Flex
            sx={{
              p: 3,
              mt: 4,
              bg: 'whites3',
              flexDirection: 'column',
              borderRadius: 'default',
              fontSize: 2,
            }}
          >
            <Heading variant="heading5.withMargins">
              <Trans id="settings.user.preferences.format_example">Format examples</Trans>
            </Heading>
            <Text mt={-2}>
              <Trans id="settings.user.preferences.time">Time</Trans> - {dayjs().format(timeFormat)}
            </Text>
            <Text>
              <Trans id="settings.user.preferences.date">Date</Trans> - {dayjs().format(dateFormat)} /{' '}
              {dayjs().format('dddd, d MMMM YYYY')}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            minHeight: '400px',
          }}
        >
          <LoadingSpinnerCss size={4} />
        </Flex>
      )}
    </>
  );
};
