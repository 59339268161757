import type { ResponseInterceptor } from 'react-fetching-library';

import { Action } from 'api/types';
import { PATH } from 'constants/routes';
import { REDIRECT, REFRESH_SESSION } from 'constants/customEventsNames';
import { emitCustomEvent } from 'utils/customEvents';

export const responseRefreshSessionInterceptor: () => ResponseInterceptor =
  () => () => async (action: Action<unknown>, response) => {
    if (response.status === 503) {
      emitCustomEvent(REDIRECT, PATH.SERVICE_UNAVAILABLE);
      return response;
    }

    const refreshSessionHeaderValue = response.headers?.get('x-refresh-session');

    if (!refreshSessionHeaderValue || (action.config && action.config.skipRefreshSession)) {
      return response;
    }

    emitCustomEvent(REFRESH_SESSION, +refreshSessionHeaderValue);

    return response;
  };
