/** @jsxImportSource theme-ui */

import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Flex } from 'theme-ui';

import { Button } from 'components/ui/Buttons/Button';
import { Icon } from 'components/Icon/Icon';
import { useTheme } from 'styles/useTheme';

type CloseButtonProps = {
  show: boolean;
} & React.ComponentProps<'button'>;

export const CloseButton = ({ show, onClick }: CloseButtonProps): React.ReactElement => {
  const { theme } = useTheme();

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ scale: 0, rotate: '-720deg' }}
          animate={{ scale: 1, rotate: '0deg', transition: { delay: 0.3 } }}
          exit={{ scale: 0, rotate: '360deg' }}
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '4px',
            left: '4px',
            transformOrigin: 'top left',
            zIndex: 1,
          }}
        >
          <Button
            variant="minimal"
            size="xs"
            shape="circle"
            aria-label="Close"
            onClick={onClick}
            bgOverwrite={{
              default: theme.colors.notification.closeButton.background.default,
              hover: theme.colors.notification.closeButton.background.hover,
              tap: theme.colors.notification.closeButton.background.tap,
              disabled: '',
            }}
            sx={{
              ml: '-50%',
              mt: '-50%',
              border: `1px solid`,
              borderColor: 'alphas.darker2',
              backdropFilter: 'blur(2px)',
              boxShadow: 'dropShadowDarker.levelOne',
            }}
          >
            <Icon type="x" fill={theme.colors.notification.closeButton.fill} />
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const Header = ({ children }: { children: React.ReactNode }): React.ReactElement => (
  <Flex
    sx={{
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </Flex>
);

export const Footer = ({ show, children }: { show: boolean; children: React.ReactNode }): React.ReactElement | null => {
  if (!show) return null;
  return (
    <Flex
      sx={{
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
        mt: 2,
      }}
    >
      {children}
    </Flex>
  );
};
