/** @jsxImportSource theme-ui */

import React from 'react';
import { Text, ThemeUIStyleObject } from 'theme-ui';

import { LoadingSpinnerCss } from './LoadingSpinnerCSS';

const wrapperSx: ThemeUIStyleObject = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'progress',
};

const containerSx: ThemeUIStyleObject = {
  position: 'relative',
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
};

const messageSx: ThemeUIStyleObject = {
  color: 'texts.lighter',
  position: 'absolute',
  top: '100%',
  mt: 3,
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
};

type Props = {
  background?: string;
  color?: string;
  size?: number;
  sx?: ThemeUIStyleObject;
  message?: string | React.ReactElement;
};

const defaultProps: Partial<Props> = {
  background: 'rgba(255, 255, 255,.8)',
  sx: undefined,
  color: '#465F86',
  size: 5,
  message: undefined,
};

export const LoadingOverlay = ({ background, size, color, message, sx, ...props }: Props): React.ReactElement => (
  <div {...props} sx={{ ...wrapperSx, ...sx, background }}>
    <div sx={containerSx}>
      <LoadingSpinnerCss size={size} color={color} />
      {message && (
        <Text as="p" variant="pLead" sx={messageSx}>
          {message}
        </Text>
      )}
    </div>
  </div>
);

LoadingOverlay.defaultProps = defaultProps;
