import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Flex } from 'theme-ui';
import { useLingui } from '@lingui/react';

import { UserChangePasswordSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { Button } from 'components/ui/Buttons';
import { PasswordInput } from 'components/ui/PasswordInput';
import { TextInput } from 'components/ui/TextInput';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';

type Props = {
  onSubmit: (body: UserChangePasswordSettingsActionProps) => Promise<boolean>;
};

export const ChangePasswordForm = ({ onSubmit }: Props): React.ReactElement => {
  useLingui();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const newPasswordRef = useRef<HTMLInputElement | null>(null);
  const confirmPasswordAfterBlurRef = useRef<boolean>(false);

  const {
    handleSubmit,
    setError,
    clearErrors,
    register,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const handleSubmitCallback = async (body: UserChangePasswordSettingsActionProps) => {
    const shouldResetForm = await onSubmit({
      ..._.omit(body, 'confirmPassword'),
    });

    if (shouldResetForm) {
      setNativeValue(newPasswordRef, '');
      confirmPasswordAfterBlurRef.current = false;
      reset();
    }
  };

  const currentPasswordRegister = useMemo(
    () => register('currentPassword', { required: t({ id: 'global.forms.required' }) }),
    [register],
  );

  const newPasswordRegister = useMemo(
    () => register('newPassword', { required: t({ id: 'global.forms.required' }) }),
    [register],
  );

  const confirmPasswordRegister = useMemo(
    () =>
      register('confirmPassword', {
        required: t({ id: 'global.forms.required' }),
        validate: (value) =>
          value === newPassword ||
          t({
            id: 'settings.user.change_password.not_match',
            message: 'Passwords do not match',
          }),
      }),
    [newPassword, register],
  );

  const setPasswordErrorCallback = useCallback(() => {
    setError('passwordInternalError' as 'newPassword', {
      message: 'only visible in code',
    });
  }, [setError]);

  const clearPasswordErrorCallback = useCallback(() => {
    clearErrors('passwordInternalError' as 'newPassword');
  }, [clearErrors]);

  const newPasswordOnChange = useCallback(
    (e) => {
      setNewPassword(e.target.value);
      newPasswordRegister.onChange(e);
      if (newPasswordRef.current && newPasswordRef.current.value.length > 0 && confirmPasswordAfterBlurRef.current)
        trigger('confirmPassword');
    },
    [newPasswordRegister, trigger],
  );

  const confirmPasswordOnBlur = useCallback(
    (e) => {
      confirmPasswordAfterBlurRef.current = true;
      confirmPasswordRegister.onBlur(e);
    },
    [confirmPasswordRegister],
  );

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)}>
      <Flex sx={{ gap: 3, flexDirection: 'column', maxWidth: '340px' }}>
        <TextInput
          id="password"
          type="password"
          {...currentPasswordRegister}
          placeholder={t({
            id: 'settings.user.change_password.current_password',
            message: 'Current password',
          })}
          label={t({
            id: 'settings.user.change_password.current_password',
            message: 'Current password',
          })}
          variant="rounded"
          error={!!errors.currentPassword}
          errorMessage={errors?.currentPassword?.message}
          size="sm"
          onChange={_.debounce((e) => {
            setCurrentPassword(e.target.value);
            currentPasswordRegister.onChange(e);
          }, 100)}
        />

        <PasswordInput
          id="newPassword"
          {...newPasswordRegister}
          ref={mergeRefs([newPasswordRegister.ref, newPasswordRef])}
          label={t({
            id: 'settings.user.change_password.new_password',
            message: 'New password',
          })}
          variant="rounded"
          placeholder={t({
            id: 'settings.user.change_password.new_password',
            message: 'New password',
          })}
          error={!!errors.newPassword}
          errorMessage={errors?.newPassword?.message}
          size="sm"
          onValidError={setPasswordErrorCallback}
          onClearError={clearPasswordErrorCallback}
          onChange={newPasswordOnChange}
          oldPassword={currentPassword}
        />

        <TextInput
          id="confirmPassword"
          type="password"
          {...confirmPasswordRegister}
          label={t({
            id: 'settings.user.change_password.confirm_password',
            message: 'Confirm password',
          })}
          variant="rounded"
          placeholder={t({
            id: 'settings.user.change_password.confirm_password',
            message: 'Confirm password',
          })}
          error={!!errors.confirmPassword}
          errorMessage={errors?.confirmPassword?.message}
          onBlur={confirmPasswordOnBlur}
          onChange={_.debounce((e) => {
            setConfirmPassword(e.target.value);
            confirmPasswordRegister.onChange(e);
          }, 100)}
          size="sm"
        />

        <Button
          variant="primary"
          type="submit"
          shape="rounded"
          disabled={
            !!(
              !_.isEmpty(errors) ||
              !_.isEqual(newPassword, confirmPassword) ||
              !confirmPassword ||
              !currentPassword ||
              !newPassword
            )
          }
          sx={{ alignSelf: 'flex-start' }}
        >
          <Trans id="settings.user.change_password.submit_button">Update password</Trans>
        </Button>
      </Flex>
    </form>
  );
};
