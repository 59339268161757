import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { Trans, Plural } from '@lingui/macro';

import { parsedEmployeesSelector } from 'state/employees';
import { deactivateEmployeesAction } from 'api/actions/employees/employeesActions';

import { DeleteDeactivateModal } from './modalsElements/DeleteDeactivateModal';

export const DeactivateTeammatesModal = (): React.ReactElement => {
  const employees = useRecoilValue(parsedEmployeesSelector);

  const titleRenderer = useCallback(
    (count: number, name: string) => (
      <Plural id="team.deactivate" value={count} one={`Deactivate - ${name}?`} other="Deactivate employees?" />
    ),
    [],
  );

  const contentRenderer = useCallback(
    () => (
      <Trans id="team.deactivate.desc">
        <Text>
          After deactivating user won’t be able to sign into any of our apps and will be moved to the Deactivated user
          group.
        </Text>
        <Text>
          <strong> This action is reversable, </strong> at any time you can re-activate users and send them new invites
          to join your team.
        </Text>
        <Text>Remember, deactivated users are still counted towards your currently paid limit.</Text>
      </Trans>
    ),
    [],
  );

  return (
    <DeleteDeactivateModal
      list={employees}
      namePath={['name.firstName', 'name.surname']}
      action={deactivateEmployeesAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      variant="DEACTIVATE"
    />
  );
};
