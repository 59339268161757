import { useCallback, useTransition } from 'react';
import { useRecoilState_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';
import _ from 'lodash';

import { DateRangeFilterAtom, dateRangeFilterAtom } from 'state/filters';

export const useDateRangeFilter = () => {
  const [dates, insideSetDates] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(dateRangeFilterAtom);

  const [inTransition, startTransition] = useTransition();

  const setDates = useCallback(
    (newState: DateRangeFilterAtom) => {
      if (!_.isEqual(dates, newState)) {
        startTransition(() => {
          insideSetDates(newState);
        });
      }
    },
    [dates, insideSetDates],
  );

  return {
    dates,
    setDates,
    inTransition,
  };
};
