import { Trans } from '@lingui/macro';
import React, { useCallback } from 'react';
import { BoxOwnProps, BoxProps, Flex, Text } from 'theme-ui';
import { useRecoilValue } from 'recoil';

import { Icon } from 'components/Icon/Icon';
import { dateTime } from 'utils/dateTime';
import { Divider } from 'components/Divider/Divider';
import { fullTimeFormatSelector } from 'state/recoilState';

import { TotalOf } from './TotalOf';

type Props = BoxOwnProps &
  BoxProps & {
    dateDetails: {
      dateRange: {
        startUnix: number;
        endUnix: number;
      };
      isDateBound: boolean;
    };
    duration: {
      days: number;
      hours: number;
      minutes: number;
    };
  };

export const DateRangeDisplay = ({ dateDetails, duration, ...props }: Props): React.ReactElement => {
  const timeFormat = useRecoilValue(fullTimeFormatSelector);
  const { dateRange, isDateBound } = dateDetails;
  const { startUnix, endUnix } = dateRange;

  const dateFormatDisplay = useCallback(
    (unix: number) => {
      if (isDateBound) return <Text sx={{ fontSize: 2 }}>{dateTime(unix).format('dd, ll')}</Text>;

      return (
        <Text>
          {dateTime(unix).format('dd, ll ')}
          <Text as="span" sx={{ fontWeight: 'bold' }}>
            {dateTime(unix).format(timeFormat)}
          </Text>
        </Text>
      );
    },
    [isDateBound, timeFormat],
  );

  return (
    <Flex {...props} sx={{ gap: 4, ...(props.sx && props.sx) }}>
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          <Trans id="request.details.time_frame.starts">Starts</Trans>
        </Text>
        <Text sx={{ fontSize: 2 }}>{dateFormatDisplay(startUnix)}</Text>
      </Flex>
      <Icon type="arrowRight" fill="whites4" />
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          <Trans id="request.details.time_frame.ends">Ends</Trans>
        </Text>
        <Text sx={{ fontSize: 2 }}>{dateFormatDisplay(endUnix)}</Text>
      </Flex>
      <Divider sx={{ height: 'auto' }} axis="vertical" />
      <TotalOf duration={duration} />
    </Flex>
  );
};
