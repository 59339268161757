import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { InvitationResendPayload } from 'api/actions/auth/authActions.types';
import { TextInput } from 'components/ui/TextInput';
import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { FormCard } from 'layouts/Authentication';
import { APP_NAME } from 'constants/common';
import { Icon } from 'components/Icon/Icon';

type Props = {
  onSubmit: (props: InvitationResendPayload) => Promise<boolean>;
  onBack: () => void;
};

const INVITED_FORM_ID = 'employee-invited-form-id';

export const EmployeeInvitedForm = ({ onSubmit, onBack }: Props): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange' });

  const handleSubmitCallback = useCallback(
    async (props) => {
      await onSubmit(props);
    },
    [onSubmit],
  );

  return (
    <>
      <FormCard.Header>
        <FormCard.Title>
          <Trans id="sing_up.employee.reinvite_title">Request a re-invite</Trans>
        </FormCard.Title>
        <FormCard.Lead>
          <Trans id="sing_up.employee.reinvite_text">
            Send a re-invite request to your company's {APP_NAME} administrator.
          </Trans>
        </FormCard.Lead>
      </FormCard.Header>

      <form id={INVITED_FORM_ID} onSubmit={handleSubmit(handleSubmitCallback)} noValidate>
        <ElementGroup marginValue="4" direction="column">
          <TextInput
            id="note"
            placeholder={i18n._(
              t({
                id: 'sing_up.employee.form.note',
                message: 'Additional note (Optional)',
              }),
            )}
            type="text"
            error={!!errors.note}
            errorMessage={errors?.note?.message}
            clearable
            {...register('note')}
          />
        </ElementGroup>

        <FormCard.Footer>
          <Button variant="minimal" shape="rounded" size="lg" onClick={() => onBack()}>
            <Trans id="global.forms.buttons.back" />
          </Button>

          <Button
            variant="primary"
            shape="rounded"
            size="lg"
            type="submit"
            form={INVITED_FORM_ID}
            apendWith={<Icon type="send" />}
          >
            <Trans id="global.forms.buttons.send">Send</Trans>
          </Button>
        </FormCard.Footer>
      </form>
    </>
  );
};
