import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo, useRef, useTransition } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { Select } from 'components/ui/Select/Select';
import { TextInput } from 'components/ui/TextInput';
import { IS_END_STATUS } from 'layouts/Requests/constans';
import { prepareTimeEventsOptionsSelector, selectedTimeEventDataSelector } from 'state/requests';
import { setNativeValue } from 'utils/setNativeValue';
import { TimeEventsHistoryList } from '../elements/TimeEventsHistoryList';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { DualCalendar } from 'components/ui/DualCalendar/DualCalendar';

type Props = {
  action: RequestActionType;
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCommonForm: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TimeTrackingForm = ({ action, setIsSaveDisabled, setShowCommonForm }: Props): React.ReactElement => {
  useLingui();
  const timeEventsTypesOptions = useRecoilValue(prepareTimeEventsOptionsSelector);
  const selectedTimeEventData = useRecoilValue(selectedTimeEventDataSelector);

  const firstRenderRef = useRef<boolean>(true);
  const actionRef = useRef<RequestActionType>(action);
  const eventTypeSelectRef = useRef<HTMLInputElement | null>(null);
  const [isInTransition, startTransition] = useTransition();

  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    setValue,
    reset,
  } = useFormContext();

  const typeIdWatch: string | undefined = watch('timeTrackingData.eventTypeId');
  const calendarWatch: [number, number] | undefined = watch('timeTrackingData.dateUnix');

  const handleEventTypeOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value.includes(IS_END_STATUS)) {
        setValue('timeTrackingData.isEnd', true);
        setValue('timeTrackingData.eventTypeId', value.replace(IS_END_STATUS, ''));
      } else {
        setValue('timeTrackingData.eventTypeId', value);
        setValue('timeTrackingData.isEnd', false);
      }
    },
    [setValue],
  );

  const shouldDisplayForm = useMemo(() => {
    if (action === RequestActionType.Edit && !selectedTimeEventData) return false;

    if (action === RequestActionType.Create && !typeIdWatch) return false;

    if (action === RequestActionType.Remove) return false;

    return true;
  }, [action, selectedTimeEventData, typeIdWatch]);

  const shouldDisplayHistory = useMemo(() => {
    switch (action) {
      case RequestActionType.Edit:
      case RequestActionType.Remove:
        return true;
      default:
        return false;
    }
  }, [action]);

  const shouldDisplayTypeSelect = useMemo(() => {
    if (action === RequestActionType.Create) return true;

    if (action === RequestActionType.Edit && selectedTimeEventData) return true;

    return false;
  }, [action, selectedTimeEventData]);

  const setCalendarErrorCallback = useCallback(() => {
    setError('calendarInternalError', {
      type: 'required',
    });
    setIsSaveDisabled(true);
  }, [setError, setIsSaveDisabled]);

  const clearCalendarErrorCallback = useCallback(() => {
    clearErrors('calendarInternalError');
  }, [clearErrors]);

  useEffect(() => {
    if (
      (calendarWatch && typeIdWatch && typeIdWatch.length && !errors.calendarInternalError) ||
      (action === RequestActionType.Remove && selectedTimeEventData)
    ) {
      setIsSaveDisabled(false);
    }
  }, [action, calendarWatch, errors.calendarInternalError, selectedTimeEventData, setIsSaveDisabled, typeIdWatch]);

  useEffect(() => {
    if (shouldDisplayForm) {
      setShowCommonForm(true);
    }
  }, [setShowCommonForm, shouldDisplayForm]);

  useEffect(() => {
    actionRef.current = action;
  }, [action]);

  useEffect(() => {
    if (!selectedTimeEventData && !firstRenderRef.current) {
      reset({ actionType: actionRef.current });
      setNativeValue(eventTypeSelectRef, '');
      setIsSaveDisabled(true);
      setShowCommonForm(false);
    }

    firstRenderRef.current = false;
  }, [reset, selectedTimeEventData, setIsSaveDisabled, setShowCommonForm, setValue]);

  useEffect(() => {
    startTransition(() => {
      if (selectedTimeEventData) {
        const {
          id,
          typeId,
          isEndStatus,
          dateTimeDetails: { dateUnix },
        } = selectedTimeEventData;

        setValue('requestId', id);

        if (shouldDisplayForm) {
          setValue('timeTrackingData.typeId', typeId);
          setValue('timeTrackingData.isEnd', isEndStatus);
          setValue('timeTrackingData.dateUnix', dateUnix);
          setNativeValue(eventTypeSelectRef, `${typeId}${isEndStatus ? IS_END_STATUS : ''}`);
        }
      }
    });
  }, [selectedTimeEventData, setValue, shouldDisplayForm]);

  return (
    <>
      {shouldDisplayHistory && (
        <TimeEventsHistoryList
          label={t({
            id: `requests.add_request.select_event.${action === RequestActionType.Edit ? 'edit' : 'delete'}`,
          })}
        />
      )}
      <Flex sx={{ flexDirection: 'column', gap: 4, position: 'relative' }}>
        {isInTransition && <LoadingOverlay sx={{ zIndex: 1000 }} />}
        {shouldDisplayTypeSelect && (
          <Flex sx={{ flexDirection: 'column' }}>
            <Text
              sx={{
                mb: 2,
                fontWeight: 'bold',
              }}
            >
              <Trans id="requests.add_request.work_status">Work status</Trans>
            </Text>
            <Select
              ref={eventTypeSelectRef}
              id="workStatus"
              onChange={handleEventTypeOnChange}
              placeholder="Time Event"
              options={timeEventsTypesOptions}
              size="sm"
              searchable
            />
          </Flex>
        )}
        {shouldDisplayForm && (
          <>
            <DualCalendar
              {...register('timeTrackingData.dateUnix', { valueAsNumber: true })}
              showTime
              onValidError={setCalendarErrorCallback}
              onClearError={clearCalendarErrorCallback}
            />
            <Flex sx={{ flexDirection: 'column' }}>
              <Text
                sx={{
                  mb: 2,
                  fontWeight: 'bold',
                }}
              >
                <Trans id="add_request.edit_event.note" />
              </Text>
              <TextInput
                id="timeTrackingData.note"
                type="text"
                {...register('eventNote')}
                placeholder={t({
                  id: 'add_request.edit_event.note',
                  message: 'Event note (optional)',
                })}
                variant="rounded"
                size="sm"
              />
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};
