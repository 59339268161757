/** @jsxImportSource theme-ui */
import { Location } from 'history';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { Status } from 'use-timer/lib/types';

import { refreshSettingAtomFamily } from 'state/settings';
import { useBlockRouteTransition } from 'hooks/useBlockTransition/useBlockRouteTransition';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import {
  BusinessTripSettingsActionProps,
  CustomSettingsActionProps,
  FetchRequestsSettingsResponse,
  RequestsTimeTrackingSettingsActionProps,
  TimeOffSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { fetchRequestsSettingsAction } from 'api/actions/settings/settingsActions';
import { createEvent } from 'utils/createEvent';
import { MemoizedCustomRequestList } from '../formElements/CustomRequestList';
import { MemoizedTimeOffList } from '../formElements/TimeOffList';

import { MemoizedBusinessTrip } from './Requests/BusinessTrip';
import { MemoizedCustom } from './Requests/Custom';
import { MemoizedTimeOff } from './Requests/TimeOff';
import { MemoizedTimeTracking } from './Requests/TimeTracking';

export const RequestsForms = (): React.ReactElement => {
  const history = useHistory();
  const { query } = useClient();
  const [requestState, setRequestState] = useRecoilState(refreshSettingAtomFamily('REQUESTS'));

  const payloadRef = useRef<FetchRequestsSettingsResponse | null>(null);
  const blockLocationPathnameRef = useRef<string | null>(null);

  const [transitionPage, setTransitionPage] = useState<boolean>(false);

  const timeOffShouldBlockTransitionRef = useRef<boolean>(false);
  const timeOffFormPendingRef = useRef<boolean>(false);
  const timeOffStatusRef = useRef<Status>('STOPPED');

  const timeTrackingShouldBlockTransitionRef = useRef<boolean>(false);
  const timeTrackingFormPendingRef = useRef<boolean>(false);
  const timeTrackingStatusRef = useRef<Status>('STOPPED');

  const businessTripShouldBlockTransitionRef = useRef<boolean>(false);
  const businessTripFormPendingRef = useRef<boolean>(false);
  const businessTripStatusRef = useRef<Status>('STOPPED');

  const customShouldBlockTransitionRef = useRef<boolean>(false);
  const customFormPendingRef = useRef<boolean>(false);
  const customStatusRef = useRef<Status>('STOPPED');

  const timeOffFormRef = useRef<HTMLFormElement | null>(null);
  const timeTrackingFormRef = useRef<HTMLFormElement | null>(null);
  const businessTripFormRef = useRef<HTMLFormElement | null>(null);
  const customFormRef = useRef<HTMLFormElement | null>(null);

  const [timeOffPayload, setTimeOffPayload] = useState<TimeOffSettingsActionProps | null>(null);
  const [timeTrackingPayload, setTimeTrackingPayload] = useState<RequestsTimeTrackingSettingsActionProps | null>(null);
  const [businessTripPayload, setBusinessTripPayload] = useState<BusinessTripSettingsActionProps | null>(null);
  const [customPayload, setCustomPayload] = useState<CustomSettingsActionProps | null>(null);

  const timeOffPayloadRef = useRef<TimeOffSettingsActionProps | null>(null);
  const timeTrackingPayloadRef = useRef<RequestsTimeTrackingSettingsActionProps | null>(null);
  const businessTripPayloadRef = useRef<BusinessTripSettingsActionProps | null>(null);
  const customPayloadRef = useRef<CustomSettingsActionProps | null>(null);

  const refresh = useCallback(async () => {
    const { payload } = await query(fetchRequestsSettingsAction());

    if (payload) {
      setRequestState((prevState) => (!_.isEqual(prevState?.payload, payload) ? { ...prevState, payload } : prevState));
      payloadRef.current = payload;
    }
  }, [query, setRequestState]);

  useEffect(() => {
    setRequestState((prevState) => ({ ...prevState, refresh }));
  }, [refresh, setRequestState]);

  useEffect(() => {
    if (!_.isEqual(payloadRef.current, requestState?.payload) && !blockLocationPathnameRef.current) {
      refresh();
    }
  }, [refresh, requestState?.payload]);

  useEffect(() => {
    if (requestState && requestState.payload) {
      const newTimeOffPayload = _.pick(requestState.payload, [
        'timeOffApprovalSettings',
        'allowCarryOverUnUsedLimits',
        'carriedOverLimitExpiresAfter',
      ]);
      const newTimeTrackingPayload = _.pick(requestState.payload, [
        'allowChangeRequestForLoggedClockInsAndOuts',
        'timeTrackingApprovalSettings',
      ]);
      const newBusinessTripPayload = _.pick(requestState.payload, [
        'allowRequestForBusinessTrips',
        'disableLocationRestrictionOnBusinessTrip',
        'businessTripsApprovalSettings',
      ]);
      const newCustomPayload = _.pick(requestState.payload, ['customRequestsApprovalSettings']);

      if (!_.isEqual(timeOffPayloadRef.current, newTimeOffPayload)) {
        setTimeOffPayload(newTimeOffPayload);
        timeOffPayloadRef.current = newTimeOffPayload;
      }
      if (!_.isEqual(timeTrackingPayloadRef.current, newTimeTrackingPayload)) {
        setTimeTrackingPayload(newTimeTrackingPayload);
        timeTrackingPayloadRef.current = newTimeTrackingPayload;
      }
      if (!_.isEqual(businessTripPayloadRef.current, newBusinessTripPayload)) {
        setBusinessTripPayload(newBusinessTripPayload);
        businessTripPayloadRef.current = newBusinessTripPayload;
      }
      if (!_.isEqual(customPayloadRef.current, newCustomPayload)) {
        setCustomPayload(newCustomPayload);
        customPayloadRef.current = newCustomPayload;
      }
    }
  }, [requestState, requestState?.payload]);

  useEffect(() => {
    if (transitionPage && blockLocationPathnameRef.current) {
      history.push(blockLocationPathnameRef.current);
    }
  }, [history, transitionPage]);

  const validatePageTransitionCallback = useCallback((location: Location) => {
    const submitEvent = createEvent('submit');
    blockLocationPathnameRef.current = location.pathname;

    if (timeOffStatusRef.current === 'RUNNING') {
      timeOffFormRef.current?.dispatchEvent(submitEvent);
    }

    if (timeOffShouldBlockTransitionRef.current || timeOffFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    if (timeTrackingStatusRef.current === 'RUNNING') {
      timeTrackingFormRef.current?.dispatchEvent(submitEvent);
    }

    if (timeTrackingShouldBlockTransitionRef.current || timeTrackingFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    if (businessTripStatusRef.current === 'RUNNING') {
      businessTripFormRef.current?.dispatchEvent(submitEvent);
    }

    if (businessTripShouldBlockTransitionRef.current || businessTripFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    if (customStatusRef.current === 'RUNNING') {
      customFormRef.current?.dispatchEvent(submitEvent);
    }

    if (customShouldBlockTransitionRef.current || customFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    return true;
  }, []);

  useBlockRouteTransition(validatePageTransitionCallback);

  return (
    <>
      {requestState && requestState.payload ? (
        <Flex sx={{ gap: '2.25rem', flexDirection: 'column', maxWidth: '600px' }}>
          {timeOffPayload && timeTrackingPayload && businessTripPayload && customPayload ? (
            <>
              <Flex sx={{ flexDirection: 'column' }}>
                <MemoizedTimeOff
                  ref={timeOffFormRef}
                  payload={timeOffPayload}
                  blockLocationPathnameRef={blockLocationPathnameRef}
                  timeOffShouldBlockTransitionRef={timeOffShouldBlockTransitionRef}
                  timeOffFormPendingRef={timeOffFormPendingRef}
                  timeOffStatusRef={timeOffStatusRef}
                  setTransitionPage={setTransitionPage}
                />
                <MemoizedTimeOffList />
              </Flex>
              <MemoizedTimeTracking
                ref={timeTrackingFormRef}
                payload={timeTrackingPayload}
                blockLocationPathnameRef={blockLocationPathnameRef}
                timeTrackingShouldBlockTransitionRef={timeTrackingShouldBlockTransitionRef}
                timeTrackingFormPendingRef={timeTrackingFormPendingRef}
                timeTrackingStatusRef={timeTrackingStatusRef}
                setTransitionPage={setTransitionPage}
              />
              <MemoizedBusinessTrip
                ref={businessTripFormRef}
                payload={businessTripPayload}
                blockLocationPathnameRef={blockLocationPathnameRef}
                businessTripShouldBlockTransitionRef={businessTripShouldBlockTransitionRef}
                businessTripFormPendingRef={businessTripFormPendingRef}
                businessTripStatusRef={businessTripStatusRef}
                setTransitionPage={setTransitionPage}
              />
              <Flex sx={{ flexDirection: 'column' }}>
                <MemoizedCustom
                  ref={customFormRef}
                  payload={customPayload}
                  blockLocationPathnameRef={blockLocationPathnameRef}
                  customShouldBlockTransitionRef={customShouldBlockTransitionRef}
                  customBlockFormPendingRef={customFormPendingRef}
                  customBlockStatusRef={customStatusRef}
                  setTransitionPage={setTransitionPage}
                />
                <MemoizedCustomRequestList />
              </Flex>
            </>
          ) : (
            <Flex
              sx={{
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px',
                width: '100%',
                minHeight: '400px',
              }}
            >
              <LoadingSpinnerCss size={4} />
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            minHeight: '400px',
          }}
        >
          <LoadingSpinnerCss size={4} />
        </Flex>
      )}
    </>
  );
};
