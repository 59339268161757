import React, { useCallback, useEffect, useState } from 'react';
import { useClient, useMutation } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Flex } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';

import { Main, FormCard } from 'layouts/Authentication';
import { APP_NAME } from 'constants/common';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { QueryParams } from 'constants/queryParams';
import { useQuery as useParams } from 'hooks/useQuery/useQuery';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { fetchEmployeeAccountRegisterAction, postEmployeeAccountRegisterAction } from 'api/actions/user/userActions';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { PostEmployeeAccountRegisterPayload } from 'api/actions/user/userActions.types';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { setAuthorized } from 'context/auth/authActionCreators/authActionCreators';

import { EmployeeForm } from './EmployeeForm';

export const Employee = (): React.ReactElement => {
  const [name, setName] = useState<string | undefined>();
  const language = useRecoilValue(languageSelector);

  const dispatch = useAuthDispatch();

  const { replace, push } = useHistory();
  const { addSnackbar } = useSnackbar();
  const { mutate } = useMutation(postEmployeeAccountRegisterAction);

  const urlParams = useParams();
  const token = urlParams.get(QueryParams.Token);
  const userId = urlParams.get(QueryParams.UserId);

  const { query } = useClient();

  useEffect(() => {
    if (!token || !userId) {
      addSnackbar({
        variant: 'danger',
        message: i18n._(
          t({
            id: 'sign_up.employee.error.missing_query',
            message: 'Missing data, check the activation link.',
          }),
        ),
      });

      replace(TO.SIGN_IN[language]);
    }
  }, [addSnackbar, language, query, replace, token, userId]);

  const handleError = useCallback(
    (innerCode: ErrorInnerCodes) => {
      let message = '';

      switch (innerCode) {
        case ErrorInnerCodes.UserNotExist:
          message = 'UserNotExist';
          break;
        case ErrorInnerCodes.UserIsInactive:
          message = 'UserIsInactive';
          break;
        case ErrorInnerCodes.UserAuthenticateError:
          message = 'UserAuthenticateError';
          break;
        case ErrorInnerCodes.UserConfirmEmailError:
          message = 'UserConfirmEmailError';
          break;
        case ErrorInnerCodes.UserAddPasswordError:
          message = 'UserAddPasswordError';
          break;
        default:
          break;
      }

      addSnackbar({
        variant: 'danger',
        message,
      });

      replace(TO.SIGN_IN[language]);
    },
    [addSnackbar, language, replace],
  );

  const getName = useCallback(async () => {
    if (token && userId) {
      const { error, payload } = await query(
        fetchEmployeeAccountRegisterAction({
          token,
          userId,
        }),
      );

      if (!error && payload) {
        setName(payload.fullName);
      }

      if (payload && payload.innerCode && error) {
        const { innerCode } = payload;
        handleError(innerCode);
      }
    }
  }, [handleError, query, token, userId]);

  useEffect(() => {
    getName();
  }, [getName]);

  const onSubmit = useCallback(
    async (body: Pick<PostEmployeeAccountRegisterPayload, 'password' | 'newsletterSubscription'>): Promise<boolean> => {
      if (!token || !userId) return false;

      const { payload, error } = await mutate({
        ...body,
        token,
        userId,
      });

      if (!error) {
        addSnackbar({
          variant: 'success',
          message: i18n._(
            t({
              id: 'sign_up.employee.success',
              message: 'Welcome! Your account is fully activated.',
            }),
          ),
        });

        dispatch(setAuthorized());
        push(TO.START[language]);

        return true;
      }

      if (payload && payload.innerCode && error) {
        const { innerCode } = payload;
        handleError(innerCode);

        return false;
      }

      addSnackbar({
        variant: 'danger',
        message: i18n._(
          t({
            id: 'form.default_error',
          }),
        ),
      });

      return false;
    },
    [addSnackbar, dispatch, handleError, language, mutate, push, token, userId],
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'sing_up.page_title', message: `Sign up - ${APP_NAME}` }))}</title>
      </Helmet>

      <Main>
        {name ? (
          <FormCard size="default">
            <FormCard.Header sx={{ gap: 3 }}>
              <FormCard.Title>
                <Trans id="sign_up.employee.title_welcome">{name}, it's so nice to meet you! 👋</Trans>
              </FormCard.Title>

              <FormCard.Lead>
                <Trans id="sign_up.employee.lead_password">Create a password for your account.</Trans>
              </FormCard.Lead>

              <FormCard.Lead>
                <Trans id="password_reset_wizard.lead" />
              </FormCard.Lead>
            </FormCard.Header>

            <EmployeeForm onSubmit={onSubmit} />
          </FormCard>
        ) : (
          <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>
            <LoadingSpinnerCss size={5} />
          </Flex>
        )}
      </Main>
    </>
  );
};
