import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useClient } from 'react-fetching-library';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { requestTimeOffRemainingLimitsAction } from 'api/actions/requests/requestsActions';
import { RemainingLimit, RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { FancyDataBox } from 'components/ui/FancyDataBox';
import { Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { INPUT_DEBOUNCE_TIME, OVER_TIME_PAYMENT_ID, VALIDATION_MAX_TIME_IN_SECONDS } from 'layouts/Requests/constans';
import {
  prepareTimeOffTypeOptionsSelector,
  selectedEmployeesAddRequestsIdsSelector,
  selectedTimeOffHistoryDataSelector,
} from 'state/requests';
import { CustomTimeOffHistoryList } from '../elements/CustomTimeOffHistoryList';
import { PersonLimitsList, RemainingLimitsMap } from '../elements/PersonLimitsList';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { DualCalendar } from 'components/ui/DualCalendar/DualCalendar';

type Props = {
  action: RequestActionType;
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCommonForm: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TimeOffForm = ({ action, setIsSaveDisabled, setShowCommonForm }: Props): React.ReactElement => {
  useLingui();
  const timeOffTypesOptions = useRecoilValue(prepareTimeOffTypeOptionsSelector);
  const selectedUsers = useRecoilValue(selectedEmployeesAddRequestsIdsSelector);
  const selectedTimeOffData = useRecoilValue(selectedTimeOffHistoryDataSelector);
  const { query } = useClient();

  const firstRenderRef = useRef<boolean>(true);
  const actionRef = useRef<RequestActionType>(action);
  const [showLimits, setShowLimits] = useState<boolean>(false);
  const [peopleLimits, setPeopleLimits] = useState<RemainingLimitsMap | null>(null);
  const [timeOffLimits, setTimeOffLimits] = useState<RemainingLimit | null>(null);
  const [isInTransition, startTransition] = useTransition();

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useFormContext();

  const forWholeDayWatch: boolean = watch('timeOffData.isDateBound');
  const typeIdWatch: string | undefined = watch('timeOffData.typeId');
  const calendarWatch: [number, number] | undefined = watch('timeOffData.datesUnix');

  const calendarRegister = useMemo(
    () =>
      register('timeOffData.datesUnix', {
        setValueAs: (v: string) => {
          if (!v || _.isEmpty(v) || _.isArray(v)) return undefined;
          return v.split(',').map((date) => parseInt(date, 10));
        },
      }),
    [register],
  );

  const handleShowLimits = useCallback(() => setShowLimits((prev) => !prev), []);

  const prepareMaxTimeLimit = useCallback(() => {
    if (!timeOffLimits) return [];

    const { hours, minutes, days } = timeOffLimits.maxLength;

    const parsedDays = days ? (
      <>
        {`${days} `}
        <Trans id={days > 1 ? 'days' : 'day'} />
      </>
    ) : undefined;

    const parsedHours = hours ? <>{` ${hours}h`}</> : undefined;

    const parsedMinutes = minutes ? <>{` ${minutes}m`}</> : undefined;

    return _.filter([parsedDays, parsedHours, parsedMinutes], (s) => s !== undefined);
  }, [timeOffLimits]);

  const shouldDisplayForm = useMemo(() => {
    if (action === RequestActionType.Edit && !selectedTimeOffData) return false;

    if (action === RequestActionType.Create && !typeIdWatch) return false;

    if (action === RequestActionType.Remove) return false;

    return true;
  }, [action, selectedTimeOffData, typeIdWatch]);

  const shouldDisplayDuration = useMemo(() => {
    if (typeIdWatch === OVER_TIME_PAYMENT_ID) return true;

    return false;
  }, [typeIdWatch]);

  const shouldDisplayTypeSelect = useMemo(() => {
    if (action === RequestActionType.Create) return true;

    if (action === RequestActionType.Edit && selectedTimeOffData) return true;

    return false;
  }, [action, selectedTimeOffData]);

  const shouldDisplayHistory = useMemo(() => {
    switch (action) {
      case RequestActionType.Edit:
      case RequestActionType.Remove:
        return true;
      default:
        return false;
    }
  }, [action]);

  const getTimeLimit = useCallback(
    async (typeId: string, peopleIds: string[], datesUnix: [number, number]) => {
      const { error, payload } = await query(
        requestTimeOffRemainingLimitsAction({
          typeId,
          peopleIds,
          datesUnix,
        }),
      );

      if (!error && payload) {
        setTimeOffLimits(payload);
        setPeopleLimits(
          new Map(
            _.map(_.orderBy(payload.peopleLimits, ['duration.days', 'duration.hours', 'duration.minutes']), (limit) => [
              limit.personId,
              { ...limit, id: limit.personId },
            ]),
          ),
        );
      }
    },
    [query],
  );

  const setCalendarErrorCallback = useCallback(() => {
    setError('calendarInternalError', {
      type: 'required',
    });
    setIsSaveDisabled(true);
  }, [setError, setIsSaveDisabled]);

  const clearCalendarErrorCallback = useCallback(() => {
    clearErrors('calendarInternalError');
  }, [clearErrors]);

  const handleDateBoundSwitchOnClick = useCallback(() => {
    setValue('timeOffData.datesUnix', undefined);
    clearErrors('timeOffData.datesUnix');
    setIsSaveDisabled(true);
  }, [clearErrors, setIsSaveDisabled, setValue]);

  useEffect(() => {
    if (!forWholeDayWatch && calendarWatch && !_.isEmpty(calendarWatch)) {
      const [startUnix, endUnix] = calendarWatch;

      if (endUnix) {
        const differenceInSeconds = endUnix - startUnix;

        if (differenceInSeconds > VALIDATION_MAX_TIME_IN_SECONDS) {
          setError('timeOffData.datesUnix', {
            type: 'validate',
            message: '24h exceeded',
          });
          setIsSaveDisabled(true);
        } else {
          clearErrors('timeOffData.datesUnix');
        }
      }
    }
  }, [calendarWatch, clearErrors, forWholeDayWatch, setError, setIsSaveDisabled]);

  useEffect(() => {
    if (
      (calendarWatch &&
        !_.isEmpty(calendarWatch) &&
        typeIdWatch &&
        typeIdWatch.length &&
        !errors.timeOffData?.datesUnix &&
        !errors.calendarInternalError) ||
      (action === RequestActionType.Remove && selectedTimeOffData)
    ) {
      setIsSaveDisabled(false);
    }
  }, [
    action,
    calendarWatch,
    setIsSaveDisabled,
    typeIdWatch,
    selectedTimeOffData,
    errors.timeOffData?.datesUnix,
    errors.calendarInternalError,
  ]);

  useEffect(() => {
    if (shouldDisplayForm) {
      setShowCommonForm(true);
    }
  }, [setShowCommonForm, shouldDisplayForm]);

  useEffect(() => {
    actionRef.current = action;
  }, [action]);

  useEffect(() => {
    if (!selectedTimeOffData && !firstRenderRef.current) {
      reset({ actionType: actionRef.current });
      setValue('timeOffData.typeId', '');
      setIsSaveDisabled(true);
      setShowCommonForm(false);
    }

    firstRenderRef.current = false;
  }, [reset, selectedTimeOffData, setIsSaveDisabled, setShowCommonForm, setValue]);

  useEffect(() => {
    if (calendarWatch && !_.isEmpty(calendarWatch) && typeIdWatch?.length && selectedUsers) {
      const [startDate, endDate] = calendarWatch;
      if (startDate < endDate) {
        getTimeLimit(typeIdWatch, selectedUsers, calendarWatch);
      }
    }
  }, [
    calendarWatch,
    errors,
    getTimeLimit,
    selectedUsers,
    typeIdWatch,
    errors.timeOffData?.datesUnix,
    errors.calendarInternalError,
  ]);

  useEffect(() => {
    startTransition(() => {
      if (selectedTimeOffData) {
        const {
          dateTimeDetails: { isDateBound, dateRange, dateUnix },
          id,
          typeId,
          ignoreWeekends,
          ignoreHolidays,
        } = selectedTimeOffData;
        setValue('requestId', id);

        if (shouldDisplayForm) {
          setValue('timeOffData.typeId', typeId);
          setValue('timeOffData.isDateBound', isDateBound);
          setValue('timeOffData.ignoreWeekends', ignoreWeekends);
          setValue('timeOffData.ignoreHolidays', ignoreHolidays);
          if (dateRange) {
            const { startUnix, endUnix } = dateRange;
            setValue('timeOffData.datesUnix', [startUnix, endUnix].join(','));
          }
          if (dateUnix) {
            setValue('timeOffData.durationUnix', dateUnix);
          }
        }
      }
    });
  }, [selectedTimeOffData, setValue, shouldDisplayForm]);

  return (
    <>
      {shouldDisplayHistory && (
        <CustomTimeOffHistoryList
          type={RequestFormType.TimeOff}
          label={t({ id: 'add_request.select_time_off_request', message: 'Select a time off request to edit' })}
        />
      )}
      <Flex sx={{ flexDirection: 'column', gap: 4, position: 'relative' }}>
        {isInTransition && <LoadingOverlay sx={{ zIndex: 1000 }} />}
        {shouldDisplayTypeSelect && (
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ mb: 2, fontWeight: 'bold' }}>
              <Trans id="requests.time_off_type">Time off request type</Trans>
            </Text>
            <Select
              {...register('timeOffData.typeId')}
              id="selectType"
              placeholder={t({ id: 'requests.time_off_type' })}
              options={timeOffTypesOptions}
              size="sm"
              searchable
            />
            {timeOffLimits && timeOffLimits.peopleLimits && (
              <>
                <Flex sx={{ alignItems: 'center', mt: 2 }}>
                  <FancyDataBox appendElements={prepareMaxTimeLimit()}>
                    <Trans id="requests.add_request.limit_length">Max length</Trans>
                  </FancyDataBox>
                  <Text
                    onClick={handleShowLimits}
                    sx={{
                      ml: 'auto',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    <Trans id={`requests.add_request.limit_length_description.${showLimits ? 'hide' : 'show'}`} />
                  </Text>
                </Flex>
                {peopleLimits && showLimits && (
                  <Flex mt={2} sx={{ minHeight: '156px' }}>
                    <PersonLimitsList remainingLimits={peopleLimits} />
                  </Flex>
                )}
              </>
            )}
          </Flex>
        )}
        {shouldDisplayForm && (
          <>
            {!shouldDisplayDuration && (
              <Switch
                {...register('timeOffData.isDateBound')}
                label={t({
                  id: 'requests.add_request.whole_day',
                  message: 'For a whole day',
                })}
                additionalInfo={
                  <Text>
                    <Trans id="requests.add_request.whole_day_description">
                      Based on users schedule or a default workday duration
                    </Trans>
                  </Text>
                }
                size="sm"
                bold
                onClick={handleDateBoundSwitchOnClick}
              />
            )}
            <DualCalendar
              {..._.omit(calendarRegister, 'onChange')}
              onChange={_.debounce((e) => calendarRegister.onChange(e), INPUT_DEBOUNCE_TIME)}
              range={!shouldDisplayDuration}
              showStartTime={!forWholeDayWatch && !shouldDisplayDuration}
              showEndTime={!forWholeDayWatch && !shouldDisplayDuration}
              maxRange={!forWholeDayWatch ? 2 : undefined}
              error={!!errors?.timeOffData?.datesUnix}
              errorMessage={errors.timeOffData?.datesUnix?.message}
              onValidError={setCalendarErrorCallback}
              onClearError={clearCalendarErrorCallback}
            />
            {shouldDisplayDuration && (
              <Flex>
                <Text sx={{ mb: 2, fontWeight: 'bold', alignSelf: 'center' }}>
                  <Trans id="requests.duration">Duration</Trans>
                </Text>
                <DurationPicker
                  {...register('timeOffData.durationUnix', { required: t({ id: 'global.forms.required' }) })}
                  id="durationUnix"
                  size="sm"
                  hours
                  minutes
                  seconds={false}
                  sx={{ ml: 'auto' }}
                />
              </Flex>
            )}
            {forWholeDayWatch && (
              <>
                <Switch
                  label={t({
                    id: 'requests.add_request.skip_weekends',
                    message: 'Skip weekends',
                  })}
                  additionalInfo={
                    <Text>
                      <Trans id="requests.add_request.skip_weekends_description">
                        All weekends will be reduced from the requests duration
                      </Trans>
                    </Text>
                  }
                  size="sm"
                  bold
                  {...register('timeOffData.ignoreWeekends')}
                />
                <Switch
                  label={t({
                    id: 'requests.add_request.skip_holidays',
                    message: 'Skip holidays',
                  })}
                  additionalInfo={
                    <Text>
                      <Trans id="requests.add_request.skip_holidays_description">
                        All holidays will be reduced from the requests duration
                      </Trans>
                    </Text>
                  }
                  size="sm"
                  bold
                  {...register('timeOffData.ignoreHolidays')}
                />
              </>
            )}
          </>
        )}
      </Flex>
    </>
  );
};
