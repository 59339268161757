/* eslint-disable no-console */
import React, { useCallback, useEffect } from 'react';
import {
  EstimateFacesConfig,
  MediaPipeFaceMesh,
} from '@tensorflow-models/face-landmarks-detection/dist/mediapipe-facemesh';
import * as tf from '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-cpu';
import Stats from 'stats.js';
import { useRecoilState, useRecoilValue } from 'recoil';

import * as faceLandmarksDetection from '../models/face-landmarks-detection';
import { debugModeSelector } from 'Kiosk/state/kioskState';
import { faceDetectionModelAtom } from 'Kiosk/state/faceDetectionState';
import { cameraStateAtom } from 'Kiosk/state/cameraState';

// import { MediaPipePrediction } from '@tensorflow-models/face-landmarks-detection/dist/types';
// import { setWasmPaths } from '@tensorflow/tfjs-backend-wasm';
// import wasmSimdPath from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm-simd.wasm';
// import wasmSimdThreadedPath from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm-threaded-simd.wasm';
// import wasmPath from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm.wasm';

let stats: Stats;
let net: MediaPipeFaceMesh;

let debug = false;
let loadModel = false;

const createStats = () => {
  stats = new Stats();
  stats.showPanel(0);
  const statsElement = document.getElementById('statsJs');

  if (document.getElementById('statsJs')) {
    document.getElementById('app')?.removeChild(statsElement as Node);
  }

  document.getElementById('app')?.appendChild(stats.dom).setAttribute('id', 'statsJs');
};

const estimateFaces = async (source: EstimateFacesConfig['input']) => {
  if (!net || !source) {
    throw new Error('There is no source or model.');
  }

  if (debug) {
    stats.begin();
  }

  const prediction = await net.estimateFaces({
    input: source,
    predictIrises: false,
    flipHorizontal: true,
  });

  if (debug) {
    stats.end();
  }

  return prediction;
};

const initializeModel = async () => {
  await tf.setBackend('webgl');
  const model = await faceLandmarksDetection.load(faceLandmarksDetection.SupportedPackages.mediapipeFacemesh, {
    maxContinuousChecks: 3,
    maxFaces: 1,
    iouThreshold: 0.3,
    scoreThreshold: 0.75,
    shouldLoadIrisModel: false,
    // detectionConfidence: 0.95,
  });

  net = model;

  return model;
};

type Props = {
  children: React.ReactNode | React.ReactNode[];
  load: boolean;
};

export const FaceDetectionProvider = ({ children, load }: Props): React.ReactElement => {
  const debugMode = useRecoilValue(debugModeSelector);
  const [, setState] = useRecoilState(faceDetectionModelAtom);
  const { source, isCameraStreaming } = useRecoilValue(cameraStateAtom);

  const warmUpModel = useCallback(
    async (model: MediaPipeFaceMesh) => {
      if (source) {
        await model
          .estimateFaces({
            input: source.video,
            predictIrises: false,
            flipHorizontal: true,
          })
          .then((res) => console.log('FaceDetectionProvider', '[MODEL WARMUP]', res));
      }
    },
    [source],
  );

  if (!debug && debugMode) {
    debug = debugMode;
    createStats();
  }

  useEffect(() => {
    const initialize = async () => {
      const model = await initializeModel();
      console.log('FaceDetectionProvider', '[MODEL INITIALIZED]');
      await warmUpModel(model);
    };

    if (!loadModel && load && isCameraStreaming) {
      initialize().then(() => {
        loadModel = load;
        setState({
          isModelLoaded: true,
          getPrediction: estimateFaces,
        });
      });
    }
  }, [isCameraStreaming, load, setState, warmUpModel]);

  return <>{children}</>;
};
