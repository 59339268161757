import { Trans } from '@lingui/macro';
import React, { useCallback, useEffect } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { fetchRequestForTimeRangeAction } from 'api/actions/requests/requestsActions';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/Modal';
import { requestOverviewAtom, requestOverviewYearStateAtom } from 'state/requests';

import { RequestOverviewHeaderMenu } from './elements/RequestOverviewHeaderMenu';

const LazyRequestOverviewList = React.lazy(() =>
  import('layouts/Requests/requestsOverview/elements/RequestOverviewList').then(({ RequestOverviewList }) => ({
    default: RequestOverviewList,
  })),
);

export const RequestOverviewModal = (): React.ReactElement => {
  const yearSelect = useRecoilValue(requestOverviewYearStateAtom);
  const setRequestOverview = useSetRecoilState(requestOverviewAtom);
  const { query } = useClient();

  const fetchRequestOverview = useCallback(
    async (year: string) => {
      const { payload, error } = await query(fetchRequestForTimeRangeAction(year));
      if (!error && payload) {
        setRequestOverview(payload);
      }
    },
    [query, setRequestOverview],
  );

  useEffect(() => {
    fetchRequestOverview(yearSelect);
  }, [fetchRequestOverview, yearSelect]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="requests.request_overview">Request overview</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        sx={{
          overflow: 'hidden',
        }}
      >
        <RequestOverviewHeaderMenu sx={{ mb: 4 }} />
        <React.Suspense
          fallback={
            <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
              <LoadingSpinnerCss size={4} color="#465F86" />
            </Flex>
          }
        >
          <LazyRequestOverviewList />
        </React.Suspense>
      </Modal.Body>
    </>
  );
};
