import dayjs, { ConfigType } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import _ from 'lodash';

interface Options {
  timezone?: string;
  disableTimezone?: boolean;
}

/**
 * Returns date object from dayJs with a Time Zone.
 *
 * @param {ConfigType} date dayjs | dateTime | number/unix | string.
 * @param {Options} Options timezone options.
 * @return {dayjs} dayjs object.
 */
export const dateTime = (
  date: ConfigType | undefined = undefined,
  { timezone, disableTimezone }: Options = { disableTimezone: false },
): dayjs.Dayjs => {
  let internalDate = date;

  if (
    (internalDate &&
      (typeof internalDate === 'number' || typeof internalDate === 'string') &&
      !!Number(internalDate)) ||
    internalDate === '0'
  ) {
    internalDate = parseInt(internalDate.toString(), 10) * 1000;
  }

  if (disableTimezone) {
    return dayjs(internalDate).tz('Europe/London').utc(true);
  }

  return dayjs(internalDate).tz(timezone);
};

/**
 * Returns time (date) object in current Time Zone from any DateTime.
 *
 * @param {ConfigType} date dayjs | dateTime | number/unix | string.
 * @return {dateTime} dateTime object.
 */
export const timeTz = (date: ConfigType): dayjs.Dayjs => {
  const currentUtcOffset = dateTime().utcOffset();
  let internalDate = date;

  if (
    (internalDate &&
      (typeof internalDate === 'number' || typeof internalDate === 'string') &&
      !!Number(internalDate)) ||
    internalDate === '0'
  ) {
    internalDate = parseInt(internalDate.toString(), 10) * 1000;
  }

  const timeFromDate = dayjs(internalDate).utc().utcOffset(currentUtcOffset);

  const timeAbsolute = dayjs(0).utc().utcOffset(currentUtcOffset).set({
    hour: timeFromDate.hour(),
    minute: timeFromDate.minute(),
    // second: timeFromDate.second(),
  });

  return timeAbsolute;
};

/**
 * Returns duration from seconds.
 *
 * @param {number} seconds number.
 * @return {Duration} duration object.
 */

export const durationFromSeconds = (seconds: number): Duration => {
  let internalDate = seconds;

  if (
    internalDate &&
    (typeof internalDate === 'number' || typeof internalDate === 'string') &&
    !!Number(internalDate)
  ) {
    internalDate = parseInt(internalDate.toString(), 10) * 1000;
  }

  if (internalDate < 0) {
    internalDate = parseInt('0', 10);
  }

  return dayjs.duration(internalDate);
};

export const formattedDurationFromSeconds = (seconds: number): string => {
  const internalSeconds = seconds < 0 ? 0 : seconds;
  const duration = durationFromSeconds(internalSeconds);

  const hours = duration.asHours();
  const minutes = duration.asMinutes();
  const sec = duration.asSeconds();

  const truncHours = Math.trunc(hours);
  const truncMinutes = Math.trunc(minutes - truncHours * 60);
  const truncSeconds = Math.trunc(sec - truncMinutes * 60 - truncHours * 3600);

  const generateString = () => {
    let str = '';

    if (truncHours > 0) {
      str += `${truncHours}h`;
    }

    if (truncMinutes > 0) {
      str += ` ${truncMinutes}min`;
    }

    if (truncSeconds > 0) {
      str += ` ${truncSeconds}s`;
    }

    return _.trim(str);
  };

  return generateString();
};
