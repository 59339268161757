import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { getRequestsAtom, requestsAtom } from 'state/requests';
import { deleteRequestsAction } from 'api/actions/requests/requestsActions';

import { ConfirmModal } from './ConfirmModal';

export const DeleteRequestsModal = (): React.ReactElement => {
  const requests = useRecoilValue(requestsAtom);
  const getRequests = useRecoilValue(getRequestsAtom);

  const titleRenderer = useCallback(
    (isMulti: boolean, number: string) => (isMulti ? 'Delete requests?' : `Delete request - ${number}?`),
    [],
  );

  const contentRenderer = useCallback(
    (isMulti: boolean, selectedRequests: string[]) => (
      <>
        <Text>
          You're about to permanently delete{' '}
          {!isMulti ? (
            'this user with all theirs data.'
          ) : (
            <>
              users: <strong>{selectedRequests.join(', ')}.</strong>
            </>
          )}{' '}
          <strong>This action cannot be reversed.</strong>
        </Text>
        <Text>All deleted requests can still be reviewed.</Text>
      </>
    ),
    [],
  );

  const actionOnSuccess = useCallback(() => {
    if (getRequests) getRequests();
  }, [getRequests]);

  return (
    <ConfirmModal
      list={requests}
      namePath="number"
      action={deleteRequestsAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      actionOnSuccess={actionOnSuccess}
      withConfirmation
      variant="DELETE"
    />
  );
};
