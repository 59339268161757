import { t } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { TextInput } from 'components/ui/TextInput';
import { CountrySelect } from 'components/ui/CountrySelect/CountrySelect';
import {
  BasicInformationSettingsActionProps,
  FetchOrganizationSettingsResponse,
} from 'api/actions/settings/settingsActions.types';
import { basicInformationSettingsAction } from 'api/actions/settings/settingsActions';
import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { Paths } from '../../utils/createUnionOfTypeKeys';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';

type Props = {
  payload: BasicInformationSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  basicInformationShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  basicInformationFormPendingRef: React.MutableRefObject<boolean>;
  basicInformationStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

type keys = Paths<BasicInformationSettingsActionProps>;

export const BasicInformation = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      basicInformationShouldBlockTransitionRef,
      basicInformationFormPendingRef,
      basicInformationStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchOrganizationSettingsResponse>(payloadSelectorFamily('ORGANIZATION'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const { mutate } = useMutation(basicInformationSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<BasicInformationSettingsActionProps>(payload);

    const {
      setError,
      register,
      handleSubmit,
      reset: resetForm,
      formState: { errors },
    } = useForm({ defaultValues: mutationDataRef.current, mode: 'onTouched', reValidateMode: 'onChange' });

    const dispatchSubmitEvent = useCallback(() => {
      const submitEvent = createEvent('submit');
      basicInformationFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    }, [basicInformationFormPendingRef]);

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnBlur = useCallback(() => {
      basicInformationShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [basicInformationShouldBlockTransitionRef, blockLocationPathnameRef, reset, start]);

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      const { countryCode, address, name, vatId } = payload;

      resetForm({ name, address: { ...address }, vatId, countryCode });
      basicInformationShouldBlockTransitionRef.current = false;
      basicInformationFormPendingRef.current = false;
    }, [basicInformationFormPendingRef, basicInformationShouldBlockTransitionRef, payload, resetForm]);

    const handleNameValidation = useCallback(
      (data: BasicInformationSettingsActionProps) => {
        const { name } = data;

        if (!name) {
          setError('name', {
            type: 'required',
            message: t({
              id: 'global.forms.required',
            }),
          });

          return false;
        }

        if (name.length > 100) {
          setError('name', {
            type: 'maxLength',
            message: t({ id: 'global.forms.too_long', message: 'Too long' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleSubmitCallback = useCallback(
      async (data: BasicInformationSettingsActionProps) => {
        const basicInformationResetObject = { name: 'BASIC_INFORMATION_CALLBACK', callback: handleFormReset };
        const nameValidation = handleNameValidation(data);
        reset();

        if (nameValidation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === basicInformationResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(data, payload) && nameValidation) {
          const { error } = await mutate(data);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...{ ...data, address: { ...data.address } } }));
            mutationDataRef.current = { ...data, address: { ...data.address } };
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [basicInformationResetObject] : [...prevState, basicInformationResetObject],
            );
            return;
          }
        }

        if (!nameValidation) {
          setResetCallbacks((prevState) =>
            !prevState ? [basicInformationResetObject] : [...prevState, basicInformationResetObject],
          );
          return;
        }

        basicInformationShouldBlockTransitionRef.current = false;
        basicInformationFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        basicInformationFormPendingRef,
        basicInformationShouldBlockTransitionRef,
        handleFormReset,
        handleNameValidation,
        mutate,
        payload,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payload)) handleFormReset();
    }, [handleFormReset, payload]);

    useEffect(() => {
      basicInformationStatusRef.current = status;
    }, [basicInformationStatusRef, status]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    return (
      <Flex sx={{ flexDirection: 'column', flexGrow: 1, gap: 2 }}>
        <HeadingError
          label={t({
            id: 'organization_settings.heading.basic_information',
            message: 'Basic information',
          })}
          errorMessage={errors.name?.message}
        />
        <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
          <Flex sx={{ flexDirection: 'column', flexGrow: 1, gap: 3, maxWidth: '340px' }}>
            <TextInput
              {...registerOnBlur('name')}
              error={!!errors.name}
              id="company_name"
              placeholder={t({
                id: 'organization_settings.texts.organization_name',
                message: 'Organization name',
              })}
              size="sm"
              label={t({
                id: 'organization_settings.texts.organization_name',
              })}
            />
            <Flex sx={{ flexDirection: 'column', gap: 1 }}>
              <TextInput
                {...registerOnBlur('address.street')}
                id="street"
                placeholder={t({
                  id: 'organization_settings.address.street',
                  message: 'Street',
                })}
                size="sm"
                label={t({
                  id: 'organization_settings.texts.address_details',
                  message: 'Address details',
                })}
                clearable
              />
              <Flex sx={{ gap: 1 }}>
                <TextInput
                  {...registerOnBlur('address.postalCode')}
                  id="zip"
                  placeholder={t({
                    id: 'organization_settings.address.postal_code',
                    message: 'ZIP',
                  })}
                  size="sm"
                  clearable
                />
                <TextInput
                  {...registerOnBlur('address.city')}
                  id="city"
                  placeholder={t({
                    id: 'organization_settings.address.city',
                    message: 'City',
                  })}
                  size="sm"
                  clearable
                />
              </Flex>
            </Flex>

            <CountrySelect
              {...registerOnBlur('countryCode')}
              id="countryCode"
              label={t({
                id: 'organization_settings.texts.country',
              })}
              placeholder={t({
                id: 'organization_settings.texts.country',
              })}
              size="sm"
              searchable
            />
            <TextInput
              {...registerOnBlur('vatId')}
              id="tax_id"
              placeholder={t({
                id: 'organization_settings.texts.tax_id',
              })}
              size="sm"
              label={t({
                id: 'organization_settings.texts.tax_id',
                message: 'Tax ID',
              })}
              clearable
            />
          </Flex>
        </form>
      </Flex>
    );
  },
);

export const MemoizedBasicInformation = React.memo(BasicInformation);
