import React from 'react';

import { RedirectObserver } from 'observers/RedirectObserver';
import { PersistenceObserver } from '../observers/PersistanceObserver';
import { SetRecoilStateEventObserver } from 'observers/SetRecoilStateEventObserver';

import { App } from './App';
import { AppContext } from './AppContext';
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary';

const debugMode = process.env.NODE_ENV !== 'production';

export const Root = (): React.ReactElement => (
  <ErrorBoundary debugMode={debugMode}>
    <AppContext>
      <App />
      <RedirectObserver />
      <PersistenceObserver />
      <SetRecoilStateEventObserver />
    </AppContext>
  </ErrorBoundary>
);
