import { hexToRgba } from 'utils/colors';
import { makeTheme } from '../makeTheme';

import { BASE_COLORS } from './colors';

export const THEME_BREAKPOINTS = [576, 768, 992, 1200];
export const THEME_NAVBAR_HEIGHT = '60px';

export enum MEDIA_BREAKPOINTS {
  XS = 0,
  SM = THEME_BREAKPOINTS[0],
  MD = THEME_BREAKPOINTS[1],
  LG = THEME_BREAKPOINTS[2],
  XL = THEME_BREAKPOINTS[3],
}

const zIndexPriority = [
  'select',
  'popper',
  'drawer',
  'snackbar',
  'modal',
  'notifications',
  'chat',
  'navBar',
  'chatMobile',
];
const zIndices = [...zIndexPriority]
  .reverse()
  .reduce((prev, current, index) => ({ ...prev, [current]: 1000 + index * 20 }), {} as { [K: string]: number });

const BASE_SHADOW = BASE_COLORS.grayBlues[7];

const baseShadows = {
  dropShadow: {
    levelZero: `0 1px 1px ${hexToRgba(BASE_SHADOW, 0.08)}, 0 1px 1px -1px ${hexToRgba(BASE_SHADOW, 0.12)}`,
    levelOne: `0 1px 4px ${hexToRgba(BASE_SHADOW, 0.08)}, 0 2px 8px ${hexToRgba(BASE_SHADOW, 0.04)}`,
    levelTwo: `0 2px 8px ${hexToRgba(BASE_SHADOW, 0.16)}, 0 4px 8px ${hexToRgba(BASE_SHADOW, 0.08)}`,
    levelThree: `0 3px 12px ${hexToRgba(BASE_SHADOW, 0.24)}, 0 6px 12px ${hexToRgba(BASE_SHADOW, 0.16)}`,
    levelMax: `0 20px 72px ${hexToRgba(BASE_SHADOW, 0.24)}, 0 16px 32px -72px ${hexToRgba(BASE_SHADOW, 0.16)}`,
    reversed: {
      levelOne: `0 -1px 4px ${hexToRgba(BASE_SHADOW, 0.08)}, 0 -2px 8px ${hexToRgba(BASE_SHADOW, 0.04)}`,
      levelMax: `0 -20px 72px ${hexToRgba(BASE_SHADOW, 0.24)}, 0 -16px 32px -72px ${hexToRgba(BASE_SHADOW, 0.16)}`,
    },
  },
  dropShadowDarker: {
    levelZero: `0 1px 1px ${hexToRgba(BASE_SHADOW, 0.16)}, 0 1px 1px -1px ${hexToRgba(BASE_SHADOW, 0.22)}`,
    levelOne: `0 1px 4px ${hexToRgba(BASE_SHADOW, 0.16)}, 0 2px 8px ${hexToRgba(BASE_SHADOW, 0.12)}`,
    levelTwo: `0 2px 8px ${hexToRgba(BASE_SHADOW, 0.24)}, 0 4px 8px ${hexToRgba(BASE_SHADOW, 0.16)}`,
    levelThree: `0 3px 12px ${hexToRgba(BASE_SHADOW, 0.32)}, 0 6px 12px ${hexToRgba(BASE_SHADOW, 0.24)}`,
    levelMax: `0 20px 72px ${hexToRgba(BASE_SHADOW, 0.32)}, 0 16px 32px -72px ${hexToRgba(BASE_SHADOW, 0.24)}`,
    reversed: {
      levelOne: `0 -1px 4px ${hexToRgba(BASE_SHADOW, 0.16)}, 0 -2px 8px ${hexToRgba(BASE_SHADOW, 0.12)}`,
      levelMax: `0 -20px 72px ${hexToRgba(BASE_SHADOW, 0.32)}, 0 -16px 32px -72px ${hexToRgba(BASE_SHADOW, 0.24)}`,
    },
  },
};

export const base = makeTheme({
  sizes: {
    container: {
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140,
    },
  },

  radii: {
    0: 0,
    xs: '4px',
    sm: '8px',
    default: '12px',
    lg: '24px',
    xl: '36px',
    circle: '50%',
    pill: '9999px',
  },

  shadows: {
    ...baseShadows,
    notificationHub: baseShadows.dropShadow.levelTwo,
    switchIndicator: baseShadows.dropShadow.levelOne,
    modal: {
      normal: baseShadows.dropShadow.levelMax,
      reversed: baseShadows.dropShadow.reversed.levelMax,
    },
    swiper: baseShadows.dropShadow.levelTwo,
    navbarTop: baseShadows.dropShadow.levelOne,
    navbarBottom: baseShadows.dropShadow.reversed.levelOne,
    dropdown: baseShadows.dropShadow.levelTwo,
    cards: {
      signUp: {
        default: baseShadows.dropShadow.levelOne,
        hover: baseShadows.dropShadow.levelTwo,
        active: 'none',
      },
    },

    drawer: baseShadows.dropShadow.levelTwo,
    attendancePill: baseShadows.dropShadow.levelOne,
    tooltip: baseShadows.dropShadow.levelTwo,
    frozenContainer: `6px 0 4px -8px ${hexToRgba(BASE_SHADOW, 0.32)}, 10px 0 8px -12px ${hexToRgba(BASE_SHADOW, 0.16)}`,
  },

  // space: [
  //   '0', // 0
  //   '0.25rem', // 4px
  //   '0.5rem', // 8px
  //   '0.75rem', // 12px
  //   '1rem', // 16px
  //   '1.5rem', // 24px
  //   '2rem', // 32px
  //   '3rem', // 48px
  // ],

  space: [
    '0', // 0
    '0.25rem', // 1
    '0.5rem', // 2
    '1rem', // 3
    '1.5rem', // 4
    '2rem', // 5
    '3rem', // 6
  ],
  breakpoints: THEME_BREAKPOINTS.map((b) => `${b}px`),
  zIndices,
});
