import React, { useCallback, useState } from 'react';
import { Container, Text, Heading, Flex } from 'theme-ui';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { Trans } from '@lingui/macro';

import { Button } from 'components/ui/Buttons/Button';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { ElementGroup } from 'components/ui/ElementGroup';
import { removeFromNotificationCenterSelector } from 'state/notification';
import { removeNotification } from 'NotificationHub/actions';
import { dateFormatSelector, fullTimeFormatSelector } from 'state/recoilState';
import { useRedirectPath } from 'hooks/useRedirectPath/useRedirectPath';

import { NotificationProps } from './types';
import { CloseButton, Footer, Header } from './NotificationElements';

const defaultProps: Partial<NotificationProps> = {
  content: undefined,
  type: 'info',
  actions: undefined,
  createdAt: undefined,
  onClick: undefined,
  variant: 'notificationHub',
  redirectTo: undefined,
};

export const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(
  (
    {
      id,
      redirectTo,
      variant = defaultProps.variant,
      type,
      title,
      content,
      actions,
      createdAt,
      onMouseEnter,
      onMouseLeave,
      ...props
    }: NotificationProps,
    ref,
  ) => {
    const [isHovered, setHovered] = useState<boolean>(false);
    const removeNotificationFromCenter = useSetRecoilState(removeFromNotificationCenterSelector);
    const dateFormat = useRecoilValue(dateFormatSelector);
    const timeFormat = useRecoilValue(fullTimeFormatSelector);
    const { redirectPath } = useRedirectPath(redirectTo);
    const { push } = useHistory();

    const internalOnMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, action: typeof onMouseEnter) => {
      setHovered(true);
      if (action) action(e);
    };

    const internalOnMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, action: typeof onMouseLeave) => {
      setHovered(false);
      if (action) action(e);
    };

    const remove = useCallback(() => {
      removeNotificationFromCenter(id);
      removeNotification(id);
    }, [id, removeNotificationFromCenter]);

    const actionWithRemove = useCallback(
      (action?: () => void) => () => {
        if (action) action();
        if (redirectTo) {
          if (redirectPath) push(redirectPath);
          else window.open(redirectTo);
        }
        remove();
      },
      [redirectPath, push, redirectTo, remove],
    );

    const typeSpecificIcon = ((): Icons => {
      switch (type) {
        case 'success':
          return 'approve';
        case 'danger':
          return 'deny';
        case 'love':
          return 'heart';
        case 'warning':
        case 'info':
        default:
          return 'info';
      }
    })();

    const prepareDate = useCallback(
      (date) => {
        const time = dayjs.unix(date).format(timeFormat);

        if (dayjs.unix(date).format(dateFormat) === dayjs().format(dateFormat)) {
          return (
            <>
              <Trans id="calendar.today" />, {time}
            </>
          );
        }

        if (dayjs.unix(date).format(dateFormat) === dayjs().add(-1, 'day').format(dateFormat)) {
          return (
            <>
              <Trans id="calendar.yesterday">Yesterday</Trans>, {time}
            </>
          );
        }

        if (dayjs.unix(date).year() === dayjs().year()) {
          return dayjs.unix(date).format('D MMM');
        }

        return dayjs.unix(date).format(dateFormat);
      },
      [dateFormat, timeFormat],
    );

    const renderActionButtons = (): React.ReactElement | null => {
      if (!actions) return null;

      return (
        <ElementGroup>
          {actions?.map((action) => (
            <Button
              key={action.title}
              shape="rounded"
              variant="minimal"
              size="sm"
              onClick={actionWithRemove(action.onClick)}
            >
              {action.title}
            </Button>
          ))}
        </ElementGroup>
      );
    };

    return (
      <Container
        sx={{ position: 'relative', maxWidth: '90vw', ...props.sx }}
        onMouseEnter={(e) => internalOnMouseEnter(e, onMouseEnter)}
        onMouseLeave={(e) => internalOnMouseLeave(e, onMouseLeave)}
        ref={ref}
        variant={`notifications.container.${variant}`}
      >
        <CloseButton show={isHovered} onClick={remove} />
        <Flex
          onClick={!actions && redirectTo ? actionWithRemove() : undefined}
          sx={{ cursor: !actions && redirectTo ? 'pointer' : 'auto' }}
        >
          {type !== 'default' && (
            <Icon
              size={32}
              type={typeSpecificIcon}
              iconSx={{
                bg: `notification.type.${type}`,
                borderRadius: 'sm',
                p: '0.25rem',
                boxShadow: 'dropShadow.levelOne',
                border: '1px solid',
                borderColor: 'rgba(0,0,0,.075)',
              }}
              wrapperSx={{
                mr: '0.75rem',
                color: 'notification.icon',
              }}
            />
          )}
          <Flex sx={{ flexDirection: 'column', justifyContent: 'center' }}>
            <Header>
              <Heading as="h4" variant="notifications.title">
                {title}
              </Heading>
              {createdAt && (
                <Text as="span" variant="notifications.date">
                  {prepareDate(createdAt)}
                </Text>
              )}
            </Header>
            {content && (
              <Text as="p" variant="notifications.message">
                {content}
              </Text>
            )}
            <Footer show={!!actions}>{renderActionButtons()}</Footer>
          </Flex>
        </Flex>
      </Container>
    );
  },
);

Notification.defaultProps = defaultProps;
