import { useEffect } from 'react';

export const useOnOudsideKeyDown = (
  ref: React.RefObject<HTMLInputElement | HTMLDivElement | HTMLButtonElement> | null,
  key: KeyboardEvent['key'],
  callback: () => void,
): void => {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === key && ref?.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [ref, callback, key]);
};
