import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { requestGetDataToModifyAction } from 'api/actions/requests/requestsActions';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { StickyList } from 'components/StickyList/StickyList';
import { StickyListProps } from 'components/StickyList/types';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { CUSTOM_TIME_OFF_HISTORY_LIST } from 'layouts/Requests/constans';
import {
  parsedRequestsCustomHistorySelector,
  parsedRequestsTimeOffHistorySelector,
  ParsedTimeOffHistoryElemment,
  requestHistoryAtom,
  selectedEmployeesAddRequestsIdsSelector,
} from 'state/requests';
import { dateTime } from 'utils/dateTime';
import { selectedRowsAtomFamily } from 'state/list';
import { fullTimeFormatSelector } from 'state/recoilState';

type Props = {
  type: RequestFormType.Custom | RequestFormType.TimeOff;
  label: string;
};

export const CustomTimeOffHistoryList = ({ type, label }: Props): React.ReactElement => {
  useLingui();
  const { query, loading } = useParameterizedQuery(requestGetDataToModifyAction);
  const setRequestHistory = useSetRecoilState(requestHistoryAtom);
  const parsedRequestsTimeOffHistory = useRecoilValue(parsedRequestsTimeOffHistorySelector);
  const parsedRequestsCustomHistory = useRecoilValue(parsedRequestsCustomHistorySelector);
  const selectedUsers = useRecoilValue(selectedEmployeesAddRequestsIdsSelector);
  const resetListSelection = useResetRecoilState(selectedRowsAtomFamily(CUSTOM_TIME_OFF_HISTORY_LIST));
  const timeFormat = useRecoilValue(fullTimeFormatSelector);

  const getHistory = useCallback(async () => {
    const personId = selectedUsers[0];

    const { error, payload } = await query({ personId, skip: 0, type });

    if (!error && payload) {
      setRequestHistory(payload);
    }
  }, [query, selectedUsers, setRequestHistory, type]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  useEffect(() => () => resetListSelection(), [resetListSelection]);

  const columns: StickyListProps<ParsedTimeOffHistoryElemment>['columns'] = [
    {
      key: 'dateTimeDetails',
      sortableValue: (dateTimeDetails: ParsedTimeOffHistoryElemment['dateTimeDetails']) =>
        `${dateTimeDetails.dateRange?.startUnix}`,
      customCellRenderer: (dateTimeDetails: ParsedTimeOffHistoryElemment['dateTimeDetails']) => {
        const { dateRange, duration, dateUnix, isDateBound } = dateTimeDetails;

        if (!dateRange && !duration && !dateUnix) return <></>;

        const displayDate = (() => {
          if (dateRange) {
            const { startUnix, endUnix } = dateRange;
            const dateFormat = `MMM DD${!isDateBound ? `, ${timeFormat}` : ''}`;

            return `${dateTime(startUnix).format(dateFormat)} - ${dateTime(endUnix).format(dateFormat)}`;
          }

          if (dateUnix) {
            return dateTime(dateUnix).format('MMM DD');
          }

          return '';
        })();

        const displayDuration = (() => {
          if (!duration || (duration.days === 0 && duration.hours === 0 && duration.minutes === 0)) return <></>;

          return (
            <Text sx={{ color: 'grayBlues3' }}>
              {' '}
              ({duration.days !== 0 && `${duration.days} days `}
              {duration.hours !== 0 && `${duration.hours}h`}
              {duration.minutes !== 0 && `${duration.minutes}min`})
            </Text>
          );
        })();

        return (
          <TextEllipsis title={displayDate}>
            {displayDate}
            {displayDuration}
          </TextEllipsis>
        );
      },
    },
    {
      key: 'requestType',
      customCellRenderer: (requestType: ParsedTimeOffHistoryElemment['requestType']) => {
        if (!requestType) return <></>;

        const { name } = requestType;

        return <TextEllipsis title={t({ id: name })}>{t({ id: name })}</TextEllipsis>;
      },
    },
  ];

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ fontWeight: 'bold', mb: 2 }}>{label}</Text>
      <Flex sx={{ minHeight: '230px', width: '100%' }}>
        <StickyList
          name={CUSTOM_TIME_OFF_HISTORY_LIST}
          columns={columns}
          list={(type === RequestFormType.Custom ? parsedRequestsCustomHistory : parsedRequestsTimeOffHistory) || []}
          variant="inverted"
          select="radio"
          emptyListMessage={loading && t({ id: 'global.loading' })}
        />
      </Flex>
    </Flex>
  );
};
