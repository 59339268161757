import React from 'react';

import { withPopper } from 'components/ui/PopperProvider/withPopper';
import { PopperProviderProps } from 'components/ui/PopperProvider/PopperProvider';

import { Dropdown, DropdownProps } from './Dropdown';

type WithAdditionalProps = {
  dropdownProps?: DropdownProps;
  popperProps?: Omit<PopperProviderProps, 'children' | 'content'>;
};

const defaultProps: Partial<WithAdditionalProps> = {
  popperProps: undefined,
  dropdownProps: undefined,
};

export function withDropdown<T>(
  Component: React.ComponentType<T & WithAdditionalProps>,
): (props: T & WithAdditionalProps) => React.ReactElement {
  const ComponentWithPopper = withPopper(Component);

  const ComponentWithDropdown = ({
    dropdownProps,
    popperProps,
    ...props
  }: T & WithAdditionalProps): React.ReactElement => (
    <ComponentWithPopper
      {...(props as unknown as T)}
      popperProps={
        {
          trigger: 'click',
          placement: 'bottom-end',
          hideAfterPopperClick: true,
          withPortal: true,
          popperMargin: 0,
          ...popperProps,
          content: <Dropdown {...dropdownProps} />,
        } as PopperProviderProps
      }
    />
  );
  ComponentWithDropdown.defaultProps = defaultProps;

  return ComponentWithDropdown;
}
