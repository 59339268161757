/** @jsxImportSource theme-ui */

import React from 'react';
import { Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { useMutation } from 'react-fetching-library';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Checkbox } from 'components/ui/Checkbox';
import { clearTagsAction } from 'api/actions/employees/employeesActions';
import { delay } from 'utils/delay';
import { useModal } from 'hooks/useModal/useModal';
import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { useSelectedIdsModalLogic } from 'layouts/Team/hooks/useSelectedIdsModalLogic';
import { parsedEmployeesSelector } from 'state/employees';

export const ClearTags = (): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const employees = useRecoilValue(parsedEmployeesSelector);
  const { handleClose, baseRoute } = useModal();
  const { selectedIds, selectedNames, loading, setLoading, confirmed, setConfirmed } = useSelectedIdsModalLogic(
    employees,
    ['name.firstName', 'name.surname'],
  );
  const { mutate } = useMutation(clearTagsAction);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
  };

  const handleClear = async () => {
    setLoading(true);

    const { error: submitError } = await mutate(selectedIds);

    if (!submitError) {
      if (handleClose) {
        handleClose();
      }
    }
    setLoading(false);
    if (!submitError) {
      await delay(100);
      addSnackbar({
        message: t({ id: 'team.clear_tags_bulk.cleared', message: 'Successfully cleared!' }),
        variant: 'success',
      });
    }
  };
  if (!selectedIds.length) return <Redirect to={baseRoute} />;
  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.clear_tags_bulk">Clear tags</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans id="team.clear_tags_bulk.desc">
          <Text mb={2}>
            You're about to clear tags for users: <strong>{selectedNames.join(', ')}. </strong>
          </Text>

          <Text mb={4}>All users that were selected will have no tags.</Text>
        </Trans>
        <Checkbox
          onChange={handleCheckboxChange}
          checked={confirmed}
          name="confirm"
          label={t({ id: 'team.clear_tags_bulk.confirm', message: "I understand, let's proceed." })}
        />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            disabled: !confirmed,
            isLoading: loading,
            onClick: handleClear,
            variant: 'primary',
            children: t({ id: 'team.clear_tags_bulk.clear', message: 'Clear' }),
          },
        ]}
      />
    </>
  );
};
