import React from 'react';

const LazyDayDetails = React.lazy(() =>
  import('./DayDetails').then(({ DayDetails }) => ({
    default: DayDetails,
  })),
);

export const DayDetailsRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<></>}>
      <LazyDayDetails />
    </React.Suspense>
  ),
);
