import React from 'react';

const LazyWorkStatuses = React.lazy(() =>
  import('./WorkStatuses').then(({ WorkStatuses }) => ({
    default: WorkStatuses,
  })),
);

export const WorkStatusesRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<></>}>
      <LazyWorkStatuses />
    </React.Suspense>
  ),
);
