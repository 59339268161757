import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';
import { To } from 'history';
import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';

import { fetchWebhooksAction } from 'api/actions/webhook/webhookActions';
import { Webhook } from 'api/actions/webhook/webhooksActions.types';
import { Icon } from 'components/Icon/Icon';
import { StickyListProps } from 'components/StickyList/types';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { PATH, TO } from 'constants/routes';
import { ManageModal } from 'layouts/Team/modals/modalsElements/ManageModal';
import { ModalRoute } from 'routing/ModalRoute';
import { languageSelector } from 'state/recoilState';
import { refreshSettingAtomFamily } from 'state/settings';

import { AddWebhookModal } from './AddWebhookModal';
import { DeleteWebhooksModal } from './DeleteWebhooksModal';
import { EditWebhookModal } from './EditWebhookModal';
import { webhookTypeTranslationId } from './webhookTypeTranslationId';

export const WebhookModal = (): React.ReactElement => {
  useLingui();
  const history = useHistory();
  const language = useRecoilValue(languageSelector);
  const [webhooks, setWebhooks] = useRecoilState<{ payload?: Webhook[]; refresh?: () => void } | null>(
    refreshSettingAtomFamily('WEBHOOKS'),
  );
  const { query } = useClient();

  const [webhooksMap, setWebhookMap] = useState<Map<string, Webhook> | null>(null);

  const getWebhooks = useCallback(async () => {
    const { payload } = await query(fetchWebhooksAction());

    if (payload) {
      setWebhooks((prevState) => (!_.isEqual(prevState?.payload, payload) ? { ...prevState, payload } : prevState));
    }
  }, [query, setWebhooks]);

  useEffect(() => {
    setWebhooks((prevState) => (!prevState?.refresh ? { ...prevState, refresh: getWebhooks } : prevState));
  }, [getWebhooks, setWebhooks]);

  useEffect(() => {
    getWebhooks();
  }, [getWebhooks]);

  useEffect(() => {
    const newWebhookMap: Map<string, Webhook> = new Map();

    if (webhooks?.payload) {
      webhooks.payload.forEach((item) => newWebhookMap.set(item.id, item));
      setWebhookMap(newWebhookMap);
    }
  }, [webhooks?.payload]);

  const listPropsGenerator = useCallback(
    (
      match: string,
      replaceMode: boolean,
      preferredHistoryMethod: (to: To, state?: unknown) => void,
    ): StickyListProps<Webhook> => ({
      name: 'WEBHOOK',
      emptyListMessage: !webhooksMap && t({ id: 'global.loading' }),
      select: 'checkbox',
      showHeader: true,
      list: webhooksMap || new Map(),
      columns: [
        {
          key: 'type',
          title: 'Type',
          sortableValue: (type: Webhook['type']) => `${webhookTypeTranslationId[type]}`,
          customCellRenderer: (type: Webhook['type']) => (
            <TextEllipsis title={t({ id: webhookTypeTranslationId[type] })}>
              {t({ id: webhookTypeTranslationId[type] })}
            </TextEllipsis>
          ),
          columnGrow: 2,
        },
        {
          key: 'id',
          width: '40px',
          sx: { overflow: 'visible' },
          customCellRenderer: (id: Webhook['id']) => (
            <Flex
              sx={{ '&:hover': { cursor: 'pointer' } }}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                history.push(`${match}${TO.DELETE_WEBHOOK_MODAL[language]}`, { ids: [id] });
              }}
            >
              <Icon type="delete" />
            </Flex>
          ),
        },
      ],
      onRowClick: (listItemId) => preferredHistoryMethod(`${match}${TO.EDIT_WEBHOOK_MODAL[language]}/${listItemId}`),
    }),
    [webhooksMap, history, language],
  );

  const modalRoutesRenderer = useCallback(
    () => (
      <>
        <ModalRoute size="sm" path={PATH.ADD_WEBHOOK_MODAL[language]}>
          <AddWebhookModal />
        </ModalRoute>
        <ModalRoute size="sm" path={PATH.EDIT_WEBHOOK_MODAL[language]}>
          <EditWebhookModal />
        </ModalRoute>
        <ModalRoute size="sm" path={PATH.DELETE_WEBHOOK_MODAL[language]}>
          <DeleteWebhooksModal />
        </ModalRoute>
      </>
    ),
    [language],
  );

  const modalLinksPaths = useMemo(
    () => ({
      add: TO.ADD_WEBHOOK_MODAL[language],
      delete: TO.DELETE_WEBHOOK_MODAL[language],
    }),
    [language],
  );

  return (
    <ManageModal
      allResources={webhooksMap}
      title={t({ id: 'settings.integrations.webhooks_heading' })}
      modalRoutesRenderer={modalRoutesRenderer}
      listPropsGenerator={listPropsGenerator}
      modalLinksPaths={modalLinksPaths}
    />
  );
};
