import { FlexProps } from 'theme-ui';
import React from 'react';

import { DropdownProps } from 'components/Dropdown/Dropdown';
import { LinkButtonProps } from 'components/ui/Buttons';
import { Icons } from 'components/Icon/Icon.types';

export type FilterGroupProps = Pick<FlexProps, 'sx'> & {
  name: FilterGroupNames;
  title: string;
  filters?: PassedFilter[];
  dropdownProps?: DropdownProps;
  additionalLinks?: LinkButtonProps[];
  hideOptionAll?: boolean;
  openByDefault?: boolean;
  hideResetButton?: boolean;
  customContent?: React.ReactElement;
  selectType?: 'multi' | 'single';
};

export type PassedFilter = Omit<FilterProps, 'groupName'>;

export type FilterProps = Pick<FlexProps, 'sx'> & {
  groupName: FilterGroupProps['name'];
  id: string | TeammatesFilters | TagsFilters | WorkStatusesFilters | DateRangeFilters | null;
  label: string;
  dropdownProps?: DropdownProps;
  color?: string;
  appendWith?: React.ReactNode;
  prependWith?: 'icon' | 'dot' | 'square' | 'roundedSquare';
  isOutlined?: boolean;
  iconType?: Icons;
  selectType?: FilterGroupProps['selectType'];
};

export enum FilterGroupNames {
  DATE_RANGE = 'DATE_RANGE',
  REQUEST_STATE = 'REQUEST_STATE',
  REQUEST_TYPES = 'REQUEST_TYPES',
  TEAMMATES = 'TEAMMATES',
  TAGS = 'TAGS',
  ROLES = 'ROLES',
  REPORTS_ATTENDANCE_STATE = 'REPORTS_ATTENDANCE_STATE',
  WORK_STATUSES_STATE = 'WORK_STATUSES_STATE',
  REPORTS_HIDE_EMPTY = 'REPORTS_HIDE_EMPTY',
  WORK_STATUSES = 'WORK_STATUSES',
  TIME_CLOCKS__STATE = 'TIME_CLOCKS__IS_ONLINE__STATE',
  TIME_CLOCKS__SYSTEM = 'TIME_CLOCKS__SYSTEM',
  LOCATIONS = 'LOCATIONS',
}

//
// REPORTS
//
export enum ReportDayStateFilters {
  Present = 'PRESENT',
  IncompleteShift = 'INCOMPLETE_SHIFT',
  Overtime = 'OVERTIME',
  TimeOff = 'TIME_OFF',
  Absent = 'ABSENT',
  CustomRequest = 'CUSTOM_REQUEST',
  BusinessTrip = 'BUSINESS_TRIP',
  Error = 'ERROR',
}

export enum ReportHideEmptyFilter {
  IsActive = 'isActive',
}

//
// TEAMMATES TYPES
//
export enum TeammatesFilters {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  HIDDEN = 'HIDDEN',
  DEACTIVATED = 'DEACTIVATED',
}

export enum DateRangeFilters {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  PAY_PERIOD = 'PAY_PERIOD',
  CUSTOM = 'CUSTOM',
}

export enum TagsFilters {
  NO_TAGS = 'NO_TAGS',
}

//
// WORK STATUSES
//
export enum WorkStatusesFilters {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

//
// TIME CLOCKS
//
export enum TimeClocksStateFilters {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum TimeClocksSystemFilters {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}
