import _ from 'lodash';

export const getNumberField = (value?: string | number): number | undefined => {
  if (_.isNumber(value)) {
    return value;
  }
  if (_.isUndefined(value)) {
    return undefined;
  }
  if (_.isString(value) && value.match(/^[0-9,.]+$/) != null) {
    return !_.isNaN(+value) ? +value : undefined;
  }
  return undefined;
};
