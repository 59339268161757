/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    _customEventTargetElement: undefined | HTMLDivElement;
  }
}

export const getCustomEventTargetElement = (): HTMLDivElement => {
  if (!window._customEventTargetElement) {
    window._customEventTargetElement = document.createElement('div');
  }

  return window._customEventTargetElement;
};

export const emitCustomEvent = (eventName: string, data?: unknown): void => {
  const element = getCustomEventTargetElement();
  const event = new CustomEvent(eventName, { detail: data });
  element.dispatchEvent(event);
};
