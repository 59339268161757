import { dateTime } from 'utils/dateTime';

export const getCurrentUtcOffset = (): number => dateTime().utcOffset();

/**
 * Returns a time in a unix format.
 *
 * @param {number} hours Hours.
 * @param {number} minutes Minutes.
 * @param {number | undefined} seconds Seconds - Optional.
 * @return {number} UNIX from day0 after timezone manipulations.
 */
export const getTimeUnix = (hours: number, minutes: number, seconds = 0): number => {
  const currentUtcOffset = getCurrentUtcOffset();
  const dateManipulation = dateTime(0)
    .utc()
    .utcOffset(currentUtcOffset)
    .set({
      hour: hours,
      minute: minutes,
      second: seconds,
    })
    .unix();

  return dateManipulation;
};

/**
 * Returns a Local Time Object {hours, minutes, seconds, am} ready for displaying.
 *
 * @param {string | number} unix time.
 * @param {'12' | '24'} timeFormat 12 or 24.
 * @return {} Object with hours, minutes and am/pm?.
 */
export const getLocalTimeObjectFromUnix = (
  unix: string | number,
  timeFormat: string,
): { hours: string; minutes: string; seconds: string; am: 'AM' | 'PM' | undefined } => {
  const currentUtcOffset = getCurrentUtcOffset();
  const internalDate = dateTime(unix).utc(true).utcOffset(-currentUtcOffset).utc();

  const timeObject = {
    hours: internalDate.format(timeFormat === '12' ? 'hh' : 'HH'),
    minutes: internalDate.format('mm'),
    seconds: internalDate.format('ss'),
    am: timeFormat === '12' ? (internalDate.format('A') as 'AM' | 'PM') : undefined,
  };

  return timeObject;
};
