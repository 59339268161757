import React from 'react';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-fetching-library';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, LinkButton } from 'components/ui/Buttons';
import { Icon } from 'components/Icon/Icon';
import { languageSelector } from 'state/recoilState';
import { TO } from 'constants/routes';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { activateEmployeesAction } from 'api/actions/employees/employeesActions';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { Main } from 'layouts/AuthorizedApp';

const ButtonWithDropdown = withDropdown(Button);

type Props = {
  selectorFamilyID: string;
};

export const HeaderMenu = ({ selectorFamilyID }: Props): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const selectedEmployeesIds = useRecoilValue(selectedRowsIdsSelectorFamily(selectorFamilyID));
  const language = useRecoilValue(languageSelector);
  const match = useCleanRouteMatch();
  const { mutate: activateEmployee } = useMutation(activateEmployeesAction);

  const toValidation = () => {
    if (!selectedEmployeesIds.length) {
      addSnackbar({
        message: t({ id: 'team.no_employees_selected', message: 'No employees selected!' }),
        variant: 'default',
      });

      return false;
    }
    return true;
  };

  return (
    <Main.Header.Menu>
      <LinkButton
        size="sm"
        variant="primary"
        shape="rounded"
        prependWith={<Icon type="plus" />}
        to={`${match}${TO.ADD_TEAMMATE_MODAL[language]}`}
      >
        <Trans id="team.add_teammate">Add teammate</Trans>
      </LinkButton>

      <Main.Header.Menu.ButtonsGroup>
        <ButtonWithDropdown
          dropdownProps={{
            links: [
              {
                to: '/p17',
                label: t({ id: 'team.header.import.xls', message: 'from .xls file' }),
              },
              {
                to: '/p17',
                label: t({ id: 'team.header.import.google_workspace', message: 'from Google Workspace' }),
              },
              {
                to: '/p17',
                label: t({ id: 'team.header.import.comarch_optima', message: 'from Comarch Optima' }),
              },
            ],
          }}
          size="sm"
          variant="grey"
          shape="rounded"
          prependWith={<Icon type="download" />}
        >
          <Trans id="team.import">Import</Trans>
        </ButtonWithDropdown>

        <LinkButton
          size="sm"
          variant="grey"
          shape="rounded"
          prependWith={<Icon type="print" />}
          toValidation={toValidation}
          to={{
            pathname: `${match}${TO.PRINT_QR_CARDS_MODAL[language]}`,
            state: { ids: selectedEmployeesIds },
          }}
        >
          <Trans id="team.bulk.print_qr">Print QR cards</Trans>
        </LinkButton>
      </Main.Header.Menu.ButtonsGroup>

      <ButtonWithDropdown
        dropdownProps={{
          links: [
            {
              label: t({ id: 'team.bulk_actions', message: 'Bulk actions' }),
              icon: 'rocket',
              to: {
                pathname: `${match}${TO.BULK_ACTIONS_MODAL[language]}`,
                state: { ids: selectedEmployeesIds },
              },
              toValidation,
            },
            {
              label: t({ id: 'team.bulk.activate', message: 'Activate' }),
              icon: 'unLock',
              onClick: async () => {
                const valid = toValidation();
                if (!valid) return;
                const { error: submitError } = await activateEmployee(selectedEmployeesIds);
                if (!submitError) {
                  addSnackbar({
                    message: 'Successfully activated!',
                    variant: 'success',
                  });
                }
              },
            },
            {
              label: t({ id: 'team.bulk.deactivate', message: 'Deactivate' }),
              icon: 'lock',
              to: {
                pathname: `${match}${TO.DEACTIVATE_TEAMMATES_MODAL[language]}`,
                state: { ids: selectedEmployeesIds },
              },
              toValidation,
            },
            {
              label: t({ id: 'team.bulk.delete', message: 'Delete' }),
              icon: 'delete',
              toValidation,
              to: {
                pathname: `${match}${TO.DELETE_TEAMMATES_MODAL[language]}`,
                state: { ids: selectedEmployeesIds },
              },
            },
            {
              prependWithDivider: true,
              label: t({ id: 'team.view_settings', message: 'View settings' }),
              icon: 'settings',
              to: `${match}${TO.VIEW_SETTINGS_MODAL[language]}`,
            },
            {
              label: t({ id: 'team.bulk.manage_positions', message: 'Manage positions' }),
              icon: 'edit',
              to: {
                pathname: `${match}${TO.MANAGE_WORK_POSITIONS_MODAL[language]}`,
                state: { ids: selectedEmployeesIds },
              },
            },
          ],
        }}
        shape="rounded"
        size="sm"
        variant="grey"
      >
        <Icon type="more" />
      </ButtonWithDropdown>
    </Main.Header.Menu>
  );
};
