import { useRouteMatch } from 'react-router-dom';

export const useCleanRouteMatch = (): string => {
  const { url } = useRouteMatch();

  if (url.length > 1 && url.charAt(url.length - 1) === '/') {
    return url.slice(0, -1);
  }

  return url === '/' ? '' : url;
};
