import React from 'react';

import { ModalRoute } from 'routing/ModalRoute';
import { DRAWER_ROUTES } from 'constants/memoryRoutes';

import { LocationRequiredErrorModal } from './modals/LocationRequiredErrorModal';

export const LocationPermissionRoutes = (): React.ReactElement => (
  <ModalRoute
    size="default"
    path={[DRAWER_ROUTES.LOCATION_PERMISSION, DRAWER_ROUTES.DRAWER__TIME_TRACKER__LOCATION_PERMISSION]}
  >
    <LocationRequiredErrorModal />
  </ModalRoute>
);
