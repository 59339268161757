import type { QueryResponse, ResponseInterceptor } from 'react-fetching-library';

import { SET_UNAUTHORIZED, SET_TOKEN } from '../../context/auth/authReducer/authReducer';
import { Action } from '../types';
import { AuthDispatchContextProps } from 'context/auth/authContext/AuthContext.types';
import { RefreshAccessTokenAccountTypeCodes, RefreshAccessTokenResponse } from 'api/actions/auth/authActions.types';
import { refreshAccessTokenAction } from 'api/actions/auth/authActions';
import { setIsSignUpInProgress } from 'context/auth/authActionCreators/authActionCreators';
import { API_ENDPOINTS } from 'constants/api';

let refreshAccessTokenPromise: Promise<QueryResponse<RefreshAccessTokenResponse>> | null = null;

export const responseRefreshAccessTokenInterceptor: (dispatch: AuthDispatchContextProps) => ResponseInterceptor = (
  dispatch,
) => (client) => async (action: Action<unknown>, response) => {
  if (action.config && action.config.skipAuthorization) {
    return response;
  }

  if (response.status === 401 && action.endpoint !== API_ENDPOINTS.refreshAccessToken) {
    if (!refreshAccessTokenPromise) {
      refreshAccessTokenPromise = client.query(refreshAccessTokenAction()).then((refreshAccessTokenResponse) => {
        if (refreshAccessTokenResponse.error || !refreshAccessTokenResponse.payload) {
          dispatch({
            type: SET_UNAUTHORIZED,
          });

          refreshAccessTokenPromise = null;
          return refreshAccessTokenResponse;
        }

        dispatch({
          type: SET_TOKEN,
          accessToken: refreshAccessTokenResponse.payload.accessToken,
        });

        refreshAccessTokenPromise = null;

        return refreshAccessTokenResponse;
      });
    }
    const refreshAccessTokenResponse = await refreshAccessTokenPromise;

    if ((refreshAccessTokenResponse && refreshAccessTokenResponse.error) || !refreshAccessTokenResponse.payload) {
      return response;
    }
    if (
      refreshAccessTokenResponse.payload &&
      refreshAccessTokenResponse.payload.accountType === RefreshAccessTokenAccountTypeCodes.SIGN_UP_IN_PROGRESS
    ) {
      dispatch(setIsSignUpInProgress(true));
      return response;
    }

    return client.query({
      ...action,
      headers: {
        ...action.headers,
        Authorization: refreshAccessTokenResponse.payload && `Bearer ${refreshAccessTokenResponse.payload.accessToken}`,
      },
      config: {
        ...(action.config && action.config),
        skipAuthorization: true,
        skipHost: true,
        resentTimes: 0,
      },
    });
  }
  return response;
};
