import React, { useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { en, pl } from 'make-plural';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';

import { languageSelector } from 'state/recoilState';
import { startingWeekDaySelector } from 'state/organizationSession';
import { DayOfWeek } from 'api/actions/organizationSession/organizationSessionActions.types';

i18n.loadLocaleData({
  en: { plurals: en },
  pl: { plurals: pl },
});

export async function setLanguage(locale: string, weekStart: DayOfWeek | null): Promise<void> {
  let catalog = { messages: {} };

  if (process.env.NODE_ENV === 'test') {
    catalog = await import(`locales/${locale}/messages.js`);
  } else {
    catalog = await import(`@lingui/loader!locales/${locale}/messages.po`);
  }

  const { messages } = catalog;

  i18n.load(locale, messages);
  i18n.activate(locale);

  if (locale !== 'en') {
    await import(`dayjs/locale/${locale}.js`);
  }

  dayjs.locale(locale);
  // TODO: Experimental
  // 2022-03-18 - Tested by @jpytlik, did not found any unexpected behavior
  dayjs.updateLocale(locale, {
    weekStart,
  });
}

type Props = {
  children: React.ReactElement[] | React.ReactElement;
};

export const LanguageProvider = ({ children }: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const startingWeekDay = useRecoilValue(startingWeekDaySelector);

  useEffect(() => {
    setLanguage(language, startingWeekDay);
  }, [language, startingWeekDay]);

  return (
    <>
      <Helmet>
        <html lang={language} />
      </Helmet>
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        {children}
      </I18nProvider>
    </>
  );
};
