import React, { Suspense } from 'react';
import { Flex } from 'theme-ui';

import { AttendancePillPersonContentProps } from './AttendancePillPersonContent';

const LazyAttendancePillPersonContent = React.lazy(() =>
  import('./AttendancePillPersonContent').then(({ AttendancePillPersonContent: AttendancePillPerson }) => ({
    default: AttendancePillPerson,
  })),
);

type Props = AttendancePillPersonContentProps & {
  style: React.CSSProperties;
};

export const AttendancePillPerson = ({ style, person, ...props }: Props): React.ReactElement => (
  <Flex variant="attendancePillPerson.container" style={style} {...props}>
    <Suspense fallback={<></>}>
      <LazyAttendancePillPersonContent person={person} />
    </Suspense>
  </Flex>
);

export const MemoizedAttendancePillPerson = React.memo(AttendancePillPerson);
