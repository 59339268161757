import React from 'react';

import { Button, ButtonProps } from 'components/ui/Buttons/Button';
import { Avatar, AvatarProps } from 'components/Avatar/Avatar';
import { Icon } from 'components/Icon/Icon';
import { PopperState } from 'components/ui/PopperProvider/PopperProvider';
import { useTheme } from 'styles/useTheme';

type Props = Omit<ButtonProps, 'name'> &
  Omit<AvatarProps, 'circle' | 'sx'> & {
    popperState?: PopperState;
  };

const defaultProps: Partial<Props> = {
  popperState: undefined,
};

export const AvatarButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ onClick, image, name, popperState, ...props }: Props, ref) => {
    const { theme } = useTheme();

    return (
      <Button
        shape="pill"
        variant="grey"
        size="sm"
        ref={ref}
        onClick={onClick}
        prependWith={<Avatar image={image} name={name} circle size={36} />}
        {...props}
        bgOverwrite={{
          default: theme.colors.navbar.iconButton.background.default,
          hover: theme.colors.navbar.iconButton.background.hover,
          tap: theme.colors.navbar.iconButton.background.tap,
          disabled: theme.colors.navbar.iconButton.background.disabled,
        }}
        sx={{
          pl: '2px',
          py: '2px',
          ...(popperState?.isVisible && {
            color: 'navbar.iconButton.color.active',
            backgroundColor: `${theme.colors.navbar.iconButton.background.active} !important`,
          }),
          ...(props.sx && props.sx),
        }}
      >
        <Icon type="menu" size={24} />
      </Button>
    );
  },
);

AvatarButton.defaultProps = defaultProps;
