import { Action, Location } from 'history';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  transitionValidator: (location: Location, action: Action) => boolean;
};

export const useBlockRouteTransition = (transitionValidator: Props['transitionValidator']): void => {
  const history = useHistory();
  const transitionValidatorRef = useRef(transitionValidator);

  transitionValidatorRef.current = transitionValidator;

  useEffect(() => {
    const unblock = history.block(transitionValidatorRef.current);

    return () => {
      unblock();
    };
  }, [history]);
};
