/** @jsxImportSource theme-ui */

import React, { useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { Heading } from 'theme-ui';
import { useForm } from 'react-hook-form';
import { useLingui } from '@lingui/react';

import { TextInput } from 'components/ui/TextInput';
import { WorkPositionDetails } from 'api/actions/workPositions/workPositionsActions.types';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

type Props = {
  defaultValues?: WorkPositionDetails;
  onSubmit: (props: WorkPositionDetails) => Promise<boolean>;
  setLoading: (loading: boolean) => void;
};

const defaultProps: Partial<Props> = {
  defaultValues: undefined,
};

export const AddEditWorkPosition = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, defaultValues, setLoading }: Props, ref) => {
    useLingui();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: 'onTouched',
      reValidateMode: 'onChange',
      defaultValues,
    });

    const handleSubmitCallback = useCallback(
      (body: WorkPositionDetails) => {
        onSubmit(body);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    return (
      <form ref={ref} onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)} noValidate>
        <Heading variant="heading4.withMargins">
          <Trans id="team.add_edit_work_pos.basic_details">Basic details</Trans>
        </Heading>

        <TextInput
          clearable
          size="sm"
          id="name"
          placeholder={t({ id: 'team.add_edit_work_pos.name', message: 'Name' })}
          variant="rounded"
          error={!!errors.name}
          errorMessage={errors?.name?.message}
          {...register('name', validationFactory({ ...VALIDATION_RULES.WORK_POSITION_NAME, required: true }))}
        />
      </form>
    );
  },
);

AddEditWorkPosition.defaultProps = defaultProps;
