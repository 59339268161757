import React, { useEffect } from 'react';
import { Text, Image, Flex } from 'theme-ui';
import { Trans } from '@lingui/macro';
import { useHistory } from 'react-router';

import { AnchorButton } from 'components/ui/Buttons';
import { images } from 'Kiosk/constants/images';
import { Modal } from 'components/Modal/Modal';
import { PermissionState } from 'Kiosk/state/permissionState';
import { usePermissions } from 'Kiosk/hooks/usePermissions';

export const LocationRequiredErrorModal = (): React.ReactElement => {
  const { location } = usePermissions();

  const history = useHistory();

  useEffect(() => {
    if (location === PermissionState.allow) {
      history.go(-1);
    }
  }, [location, history]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="error.something_went_wrong" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ textAlign: 'center', alignItems: 'center', pb: 6 }}>
        <Image
          width="256px"
          alt="Camera"
          src={images.location['1x']}
          srcSet={`${images.location['2x']}, ${images.location['3x']}`}
        />
        <Text variant="heading2" as="h2">
          <Trans id="kiosk.home.location_permission_modal.title" />
        </Text>
        <Text variant="pLead">
          <Trans id="kiosk.home.location_permission_modal.message" />
        </Text>
        <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: 3, gap: 2 }}>
          <AnchorButton href="#TODO:-dodac-linka-do-pomocy" target="_blank" variant="primary" size="lg" shape="pill">
            <Trans id="kiosk.home.location_permission_modal.how_to_enable" />
          </AnchorButton>
        </Flex>
      </Modal.Body>
    </>
  );
};
