import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Flex, FlexProps, Text } from 'theme-ui';

import { Participant } from 'api/actions/requests/requestsActions.types';
import { FancyPersonBox } from 'components/ui/FancyPersonBox';
import { UUID } from 'utils/UUID';

type Props = FlexProps & {
  participants: Participant[];
};

export const Participants = ({ participants, ...props }: Props): React.ReactElement => {
  useLingui();

  return (
    <Flex {...props} sx={{ flexDirection: 'column', gap: 2, ...(props.sx && props.sx) }}>
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: 3,
        }}
      >
        <Trans id="request.details.section.participants">Other participants</Trans>
      </Text>
      <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
        {participants.map((participant) => (
          <FancyPersonBox key={UUID()} employee={participant.person} avatarUrl={participant.avatarUrl} />
        ))}
      </Flex>
    </Flex>
  );
};
