import { useEffect, useState } from 'react';

import { getAxisPointsToScan, getAxisXY, isFaceInPlace } from 'Kiosk/helpers';
import { Awaited } from 'utils/custom.types';
import { AXIS_ROTATION_THRESHOLD } from 'Kiosk/constants/constants';
import { AnnotatedPrediction } from 'Kiosk/models/face-landmarks-detection/mediapipe-facemesh';
import { CameraState } from 'Kiosk/state/cameraState';

import { UseFaceDetectionReturnValue } from './useFaceLandmarkModel';

let scannedPoints: number[][] = [];

export const resetScannedPoints = (): void => {
  scannedPoints = [];
};

const checkIfFaceInPointPosition = (
  faceInPlace: boolean,
  axisX: number,
  axisY: number,
  pointsToScan: ReturnType<typeof getAxisPointsToScan>,
  onPointCallback: Props['onPointCallback'],
  frameBlob: Blob,
) => {
  if (faceInPlace) {
    const { points } = pointsToScan;
    const newScannedPoints = points.filter((value) => {
      const { axisBoundary, point } = value;
      const { min, max } = axisBoundary;

      if (!scannedPoints.includes(point) && axisX >= min[0] && axisX <= max[0] && axisY >= min[1] && axisY <= max[1]) {
        onPointCallback(frameBlob);
      }
      return !scannedPoints.includes(point) && axisX >= min[0] && axisX <= max[0] && axisY >= min[1] && axisY <= max[1];
    });

    return newScannedPoints.length > 0 ? [...scannedPoints, newScannedPoints[0].point] : [...scannedPoints];
  }

  return scannedPoints;
};

type DetectProps = Required<Props> & {
  prediction: AnnotatedPrediction[];
};

type DetectReturnValue = Awaited<ReturnType<typeof detect>>;

const detect = async ({ source, prediction, pointsToScan, onPointCallback, frameBlob }: DetectProps) => {
  // get Axises
  const { degreeX, degreeY, tilt } = getAxisXY(prediction);

  // check if face is in PLACE
  const faceInPlace = isFaceInPlace(prediction, source.video);

  // check if face is in POINT
  scannedPoints = checkIfFaceInPointPosition(faceInPlace, degreeX, degreeY, pointsToScan, onPointCallback, frameBlob);

  return { axisRotation: [degreeY, degreeX, tilt], faceInPlace };
};

type Props = Pick<UseFaceDetectionReturnValue, 'pointsToScan'> &
  Pick<CameraState, 'source'> & {
    prediction?: AnnotatedPrediction[];
    frameBlob?: Blob;
    onPointCallback: (frameBlob: Blob) => Promise<void>;
  };

type ReturnValue = DetectReturnValue & { scannedPoints: number[][] };

export const useInitialFaceScan = ({
  source,
  prediction,
  frameBlob,
  pointsToScan,
  onPointCallback,
}: Props): ReturnValue => {
  const [status, setStatus] = useState<DetectReturnValue>({
    axisRotation: [0, 0, 0],
    faceInPlace: false,
  });

  const axisThresholdGuard = (newAxis: number, oldAxis: number) => {
    if (Math.abs(newAxis - oldAxis) > AXIS_ROTATION_THRESHOLD) {
      return newAxis;
    }
    return oldAxis;
  };

  useEffect(() => {
    scannedPoints = [];

    return () => {
      scannedPoints = [];
    };
  }, []);

  useEffect(() => {
    const getDetect = async (props: Required<Props>) => {
      const { axisRotation, faceInPlace } = await detect({
        source: props.source,
        prediction: props.prediction,
        pointsToScan: props.pointsToScan,
        onPointCallback: props.onPointCallback,
        frameBlob: props.frameBlob,
      });

      setStatus((prevState) => {
        axisRotation.forEach((axis, index, array) => {
          array[index] = axisThresholdGuard(axis, prevState.axisRotation[index]);
        });

        return {
          axisRotation,
          faceInPlace,
        };
      });
    };

    if (prediction && source && pointsToScan && frameBlob) {
      getDetect({
        pointsToScan,
        prediction,
        source,
        onPointCallback,
        frameBlob,
      });
    }
  }, [onPointCallback, pointsToScan, prediction, source, frameBlob]);

  return { ...status, scannedPoints };
};
