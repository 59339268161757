import React, { useMemo } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Text } from 'theme-ui';
import { Plural, Trans } from '@lingui/macro';

import { selectAllAtomFamily, selectedRowsAtomFamily, selectedRowsIdsSelectorFamily } from 'state/list';
import { LinkAnchor } from 'components/ui/LinkAnchor';

type Props = {
  selectorFamilyID: string;
};

export const DeselectMenu = ({ selectorFamilyID }: Props): React.ReactElement => {
  const selectedIds = useRecoilValue(selectedRowsIdsSelectorFamily(selectorFamilyID));
  const resetSelectedIds = useResetRecoilState(selectedRowsAtomFamily(selectorFamilyID));
  const resetGlobalSelect = useResetRecoilState(selectAllAtomFamily(selectorFamilyID));

  const translatedSelected = useMemo(() => {
    switch (selectorFamilyID) {
      case 'TEAM':
        return <Plural id="team.deselect_menu.selected" value={selectedIds.length} other="selected" />;
      case 'WORK_STATUSES':
        return <Plural id="work_statuses.deselect_menu.selected" value={selectedIds.length} other="selected" />;
      case 'LOCATIONS':
        return <Plural id="locations.selected" value={selectedIds.length} other="selected" />;
      case 'TIME_CLOCKS':
        return <Plural id="time_clocks.selected" value={selectedIds.length} other="selected" />;
      case 'CLOCK_LOG':
        return <Plural id="clock_log.selected" value={selectedIds.length} other="selected" />;
      default:
        return <Plural id="team.deselect_menu.selected" value={selectedIds.length} other="selected" />;
    }
  }, [selectedIds.length, selectorFamilyID]);

  return (
    <Text sx={{ color: 'texts.lighter', fontSize: 2 }}>
      {selectedIds.length} {translatedSelected}
      {!!selectedIds.length && (
        <>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <LinkAnchor
            standOut
            onClick={() => {
              resetSelectedIds();
              resetGlobalSelect();
            }}
          >
            <Trans id="team.deselect">Deselect</Trans>
          </LinkAnchor>
        </>
      )}
    </Text>
  );
};
