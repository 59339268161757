import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { ListColumn } from 'components/StickyList/types';

type Props = Pick<ListColumn, 'width' | 'columnGrow'> & FlexProps;

const defaultProps: Partial<Props> = {
  width: undefined,
  columnGrow: 1,
};

export const Cell = React.forwardRef<HTMLDivElement, Props>(
  ({ children, sx, width, columnGrow, ...props }: Props, ref): React.ReactElement => (
    <Flex
      ref={ref}
      sx={{
        bg: 'inherit',
        ...(width ? { flex: '0 0 auto', width } : { flex: `${columnGrow} 0` }),
        alignItems: 'center',
        gap: '0.125rem',
        overflow: 'hidden',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Flex>
  ),
);

Cell.defaultProps = defaultProps;
