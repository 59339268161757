import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { AnnotatedPrediction } from '@tensorflow-models/face-landmarks-detection/dist/mediapipe-facemesh';

import { drawBoundingBox, drawBoxForCheckIfFaceIsInPlace, drawPointsForAxisXY, drawMesh } from 'Kiosk/helpers';
import { windowSizeAtom } from 'state/recoilState';
import { cameraStateAtom } from 'Kiosk/state/cameraState';

type Props = {
  meshDraw?: boolean;
  prediction: AnnotatedPrediction[];
};

const defaultProps = {
  meshDraw: false,
};

export const DebugFaceLandmarkModel = ({ prediction, meshDraw }: Props): React.ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { isLandscape } = useRecoilValue(windowSizeAtom);

  const { source } = useRecoilValue(cameraStateAtom);

  useEffect(() => {
    if (canvasRef.current && source) {
      canvasRef.current.width = source.video.videoWidth;
      canvasRef.current.height = source.video.videoHeight;
    }
  }, [source]);

  useEffect(() => {
    let requestId: number;

    const ctx = canvasRef?.current?.getContext('2d');

    if (ctx && source) {
      const renderDraw = () => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawBoxForCheckIfFaceIsInPlace(ctx, source.video, true);
        drawBoundingBox(prediction, ctx);
        drawPointsForAxisXY(prediction, ctx);
        if (meshDraw) drawMesh(prediction, ctx);

        requestId = requestAnimationFrame(renderDraw);
      };

      renderDraw();
    }

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [prediction, meshDraw, source]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: isLandscape ? '100%' : 'auto',
        width: isLandscape ? 'auto' : '100%',
        zIndex: -3,
      }}
    />
  );
};

DebugFaceLandmarkModel.defaultProps = defaultProps;
