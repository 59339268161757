import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';

import { useCheckPermissions } from 'Kiosk/hooks/useCheckPermissions';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { PermissionState, permissionStateAtom } from 'Kiosk/state/permissionState';
import { useCamera } from 'Kiosk/hooks/useCamera';
import { PERMISSION_OBSERVER_INTERVAL } from 'Kiosk/constants/constants';

type Props = {
  requireLocation: boolean;
  intervalTime?: number;
  kioskAutoRedirect?: boolean;
};

const defaultProps = {
  intervalTime: PERMISSION_OBSERVER_INTERVAL,
  kioskAutoRedirect: true,
};

export const PermissionObserver = ({
  requireLocation,
  intervalTime = PERMISSION_OBSERVER_INTERVAL,
  kioskAutoRedirect,
}: Props): React.ReactElement => {
  const [camera, setCamera] = useState<PermissionState>(PermissionState.checking);
  const { location } = useCheckPermissions({ intervalTime, skipCamera: true });

  const { isCameraReady, source } = useCamera();

  useEffect(() => {
    if (isCameraReady) {
      setCamera(PermissionState.allow);

      source?.stream.addEventListener('ended', () => setCamera(PermissionState.deny));
    }

    return () => {
      source?.stream.removeEventListener('ended', () => setCamera(PermissionState.deny));
    };
  }, [isCameraReady, source?.stream]);

  const [permissions, setPermissions] = useRecoilState(permissionStateAtom);

  useEffect(() => {
    setPermissions({
      camera,
      location,
    });
  }, [setPermissions, location, camera]);

  const { push } = useHistory();

  const language = useRecoilValue(languageSelector);

  useEffect(() => {
    if (kioskAutoRedirect) {
      if (permissions.camera === PermissionState.deny) push(`${TO.KIOSK__CAMERA_PERMISSION[language]}`);

      if (requireLocation) {
        if (permissions.location === PermissionState.deny) push(`${TO.KIOSK__LOCATION_PERMISSION[language]}`);
      }
    }
  }, [language, push, requireLocation, permissions.camera, permissions.location, kioskAutoRedirect]);

  return <></>;
};

PermissionObserver.defaultProps = defaultProps;
