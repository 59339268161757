import React from 'react';

const LazyClockLog = React.lazy(() => import('./ClockLog').then(({ ClockLog }) => ({ default: ClockLog })));

export const ClockLogRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<></>}>
      <LazyClockLog />
    </React.Suspense>
  ),
);
