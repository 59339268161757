import React, { useCallback, useEffect, useMemo } from 'react';
import { Heading, Flex, Text } from 'theme-ui';
import { useClient, useQuery } from 'react-fetching-library';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';
import { t, Trans } from '@lingui/macro';

import { fetchExternalProviderAction, fetchIntegrationsAction } from 'api/actions/integrations/integrationActions';
import {
  ExternalLoginProvider,
  Integration,
  ServiceIntegration,
} from 'api/actions/integrations/integrationActions.types';
import { HeadingTip } from '../HeadingTip';
import { languageSelector } from 'state/recoilState';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { TO } from 'constants/routes';
import { refreshSettingAtomFamily } from 'state/settings';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';

import { AppleIntegration } from './AppleIntegration';
import { GoogleIntegration } from './GoogleIntegration';
import { IntegrationForm } from './forms/IntegrationForm';
import { ZusIntegration } from './ZusIntegration';

export const IntegrationsList = (): React.ReactElement => {
  useLingui();
  const { payload: externalPayload } = useQuery(fetchExternalProviderAction());
  const language = useRecoilValue(languageSelector);
  const match = useCleanRouteMatch();
  const history = useHistory();
  const [integrationsState, setIntegrationsState] = useRecoilState<{
    payload?: Integration[];
    refresh?: (() => void) | undefined;
  } | null>(refreshSettingAtomFamily('INTEGRATIONS'));
  const { query } = useClient();

  const webhookHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/webhook-7t24qv/',
      en: '/article/webhook-o7fe3y/',
    },
    tracktime24: { en: '/article/webhook-1o8zdhm/' },
  });

  const gratyfikantHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/integracja-aplikacji-inewi-z-programem-gratyfikant-gt-15p4wq6/',
      en: '/article/inewi-application-integration-with-gratyfikant-gt-so6fn1/',
    },
    tracktime24: {
      en: '',
    },
  });

  const getIntegrations = useCallback(async () => {
    const { payload } = await query(fetchIntegrationsAction());

    if (payload) {
      setIntegrationsState((prevState) =>
        !_.isEqual(prevState?.payload, payload) ? { ...prevState, payload } : prevState,
      );
    }
  }, [query, setIntegrationsState]);

  useEffect(() => {
    setIntegrationsState((prevState) => (!prevState?.refresh ? { ...prevState, refresh: getIntegrations } : prevState));
  }, [getIntegrations, setIntegrationsState]);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  const integrations = useMemo(
    () => ({
      Quickbooks: _.find(integrationsState?.payload, ['type', ServiceIntegration.Quickbooks]) || null,
      SquarePayroll: _.find(integrationsState?.payload, ['type', ServiceIntegration.SquarePayroll]) || null,
      Optima: _.find(integrationsState?.payload, ['type', ServiceIntegration.Optima]) || null,
      SageSymfonia: _.find(integrationsState?.payload, ['type', ServiceIntegration.SageSymfonia]) || null,
      R2Platnik: _.find(integrationsState?.payload, ['type', ServiceIntegration.R2Platnik]) || null,
      Gratyfikant: _.find(integrationsState?.payload, ['type', ServiceIntegration.Gratyfikant]) || null,
      Xero: _.find(integrationsState?.payload, ['type', ServiceIntegration.Xero]) || null,
      ZusEzla: _.find(integrationsState?.payload, ['type', ServiceIntegration.ZusEzla]) || null,
      Webhook: _.find(integrationsState?.payload, ['type', ServiceIntegration.Webhook]) || null,
    }),
    [integrationsState?.payload],
  );

  const externalProviders = useMemo(
    () => ({
      GoogleInewi: _.find(externalPayload, ['provider', ExternalLoginProvider.GoogleInewi]) || null,
      AppleLoginInewi: _.find(externalPayload, ['provider', ExternalLoginProvider.AppleLoginInewi]) || null,
    }),
    [externalPayload],
  );

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Heading variant="heading4" mb={2}>
        <Trans id="settings.integrations.integrate_with_us">Integrate with us</Trans>
      </Heading>
      {integrations.Webhook && (
        <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
          {integrations.Webhook && (
            <IntegrationForm
              type={integrations.Webhook.type}
              heading={t({ id: 'settings.integrations.webhooks_heading', message: 'Webhooks' })}
              description={t({
                id: 'settings.integrations.webhooks',
                message: 'Access the data on predefined events from our platform.',
              })}
              image={integrations.Webhook.imageUrl}
              button={{
                connectText: t({ id: 'configuration', message: 'Configuration' }),
                customOnClick: () => history.push(`${match}${TO.WEBHOOK_MODAL[language]}`),
              }}
              additionalButton={{
                text: t({ id: 'settings.learn_more' }),
                to: webhookHelpLink,
              }}
            />
          )}
        </Flex>
      )}

      <Heading variant="heading4" mt={4} mb={2}>
        <Trans id="settings.integrations.sign_in_with">Sign in with</Trans>
      </Heading>
      <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
        {externalProviders.GoogleInewi && (
          <GoogleIntegration
            image={externalProviders.GoogleInewi.imageUrl}
            isActive={externalProviders.GoogleInewi.isActive}
          />
        )}
        {externalProviders.AppleLoginInewi && (
          <AppleIntegration
            image={externalProviders.AppleLoginInewi.imageUrl}
            isActive={externalProviders.AppleLoginInewi.isActive}
          />
        )}
      </Flex>

      {!_.isEmpty(integrations) && (
        <>
          {integrations.ZusEzla && (
            <>
              <HeadingTip
                label={t({ id: 'settings.integrations.eurzad', message: 'E-Urząd' })}
                variant="heading4"
                additionalInfo={
                  <Trans id="settings.integrations.visible_managers_administrators">
                    <Text>
                      <Text as="span" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        Only visible to Managers and Administrators.
                      </Text>{' '}
                      Organization-wide integrations, when enabled/connected will be available for all your teammates
                      according to theirs roles etc.
                    </Text>
                  </Trans>
                }
                additionalInfoSx={{ fontSize: 2 }}
                sx={{ mt: 4, mb: 3 }}
              />
              <ZusIntegration
                heading="ZUS e-ZLA"
                description={t({ id: 'settings.integrations.zus', message: 'Sync time off requests.' })}
                image={integrations.ZusEzla.imageUrl}
                isActive={integrations.ZusEzla.isActive}
              />
            </>
          )}

          <HeadingTip
            label={t({ id: 'settings.integrations.payrolls', message: 'Payrolls' })}
            variant="heading4"
            additionalInfo={
              <Trans id="settings.integrations.visible_managers_administrators">
                <Text>
                  <Text as="span" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    Only visible to Managers and Administrators.
                  </Text>{' '}
                  Organization-wide integrations, when enabled/connected will be available for all your teammates
                  according to theirs roles etc.
                </Text>
              </Trans>
            }
            additionalInfoSx={{ fontSize: 2 }}
            sx={{ mt: 4, mb: 3 }}
          />
          <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
            {integrations.Xero && (
              <IntegrationForm
                type={integrations.Xero.type}
                heading="Xero"
                description={t({
                  id: 'settings.integrations.xero',
                  message: 'Sync employees and export timesheets with schedules to Xero.',
                })}
                image={integrations.Xero.imageUrl}
                isActive={integrations.Xero.isActive}
                isExpired={integrations.Xero.isExpired}
                button={{
                  connectText: t({ id: 'connect', message: 'Connect' }),
                  disconnectText: t({ id: 'disconnect', message: 'Disconnect' }),
                  expiredText: t({ id: 'reconnect', message: 'Reconnect' }),
                }}
              />
            )}
            {integrations.Quickbooks && (
              <IntegrationForm
                type={integrations.Quickbooks.type}
                heading="QuickBooks"
                description={t({
                  id: 'settings.integrations.quickbooks',
                  message: 'Sync employees and export your timesheets to QuickBooks.',
                })}
                image={integrations.Quickbooks.imageUrl}
                isActive={integrations.Quickbooks.isActive}
                isExpired={integrations.Quickbooks.isExpired}
                button={{
                  connectText: t({ id: 'connect' }),
                  disconnectText: t({ id: 'disconnect' }),
                  expiredText: t({ id: 'reconnect' }),
                }}
              />
            )}
            {integrations.SquarePayroll && (
              <IntegrationForm
                type={integrations.SquarePayroll.type}
                heading="Square"
                description={t({
                  id: 'settings.integrations.squarepayroll',
                  message: 'Sync employees and streamline timesheet export to Square.',
                })}
                image={integrations.SquarePayroll.imageUrl}
                isActive={integrations.SquarePayroll.isActive}
                isExpired={integrations.SquarePayroll.isExpired}
                button={{
                  connectText: t({ id: 'connect' }),
                  disconnectText: t({ id: 'disconnect' }),
                  expiredText: t({ id: 'reconnect' }),
                }}
              />
            )}
            {integrations.Optima && (
              <IntegrationForm
                type={integrations.Optima.type}
                heading="Comarch Optima"
                description={t({
                  id: 'settings.integrations.optima',
                  message: 'Sync employees, export timesheets, requests and schedules.',
                })}
                image={integrations.Optima.imageUrl}
                isActive={integrations.Optima.isActive}
                isExpired={integrations.Optima.isExpired}
                button={{
                  connectText: t({ id: 'enable', message: 'Enable' }),
                  disconnectText: t({ id: 'disable', message: 'Disable' }),
                  expiredText: t({ id: 'reconnect' }),
                }}
              />
            )}
            {integrations.SageSymfonia && (
              <IntegrationForm
                type={integrations.SageSymfonia.type}
                heading="Sage Symfonia"
                description={t({
                  id: 'settings.integrations.sagesymfonia',
                  message: 'Sync employees, export timesheets, requests and schedules.',
                })}
                image={integrations.SageSymfonia.imageUrl}
                isActive={integrations.SageSymfonia.isActive}
                isExpired={integrations.SageSymfonia.isExpired}
                button={{
                  connectText: t({ id: 'enable' }),
                  disconnectText: t({ id: 'disable' }),
                  expiredText: t({ id: 'reconnect' }),
                }}
              />
            )}
            {integrations.R2Platnik && (
              <IntegrationForm
                type={integrations.R2Platnik.type}
                heading="R2płatnik"
                description={t({ id: 'settings.integrations.r2platnik', message: 'Export timesheets.' })}
                image={integrations.R2Platnik.imageUrl}
                isActive={integrations.R2Platnik.isActive}
                isExpired={integrations.R2Platnik.isExpired}
                button={{
                  connectText: t({ id: 'enable' }),
                  disconnectText: t({ id: 'disable' }),
                  expiredText: t({ id: 'reconnect' }),
                }}
              />
            )}
            {integrations.Gratyfikant && (
              <IntegrationForm
                type={integrations.Gratyfikant.type}
                heading="Gratyfikant GT"
                description={t({
                  id: 'settings.integrations.gratyfikant',
                  message: 'Sync employees, export timesheets and requests.',
                })}
                image={integrations.Gratyfikant.imageUrl}
                additionalButton={{
                  text: t({ id: 'settings.learn_more' }),
                  to: gratyfikantHelpLink,
                }}
              />
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
