import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { RequestType } from 'api/actions/organizationSession/organizationSessionActions.types';
import {
  acceptRequestManagementAction,
  fetchRequestsAction,
  requestDownloadAction,
  requestPrintAction,
} from 'api/actions/requests/requestsActions';
import { RequestFormType, RequestState } from 'api/actions/requests/requestsActions.types';
import { Avatar } from 'components/Avatar/Avatar';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { RequestStatusBadge } from 'components/recipes/RequestStatusBadge';
import { RequestTypePresenter } from 'components/recipes/RequestTypePresenter';
import { StickyListProps } from 'components/StickyList/types';
import { Button } from 'components/ui/Buttons';
import { FullName } from 'components/utils/FullName';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { APP_NAME } from 'constants/common';
import { TO } from 'constants/routes';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { Main } from 'layouts/AuthorizedApp';
import { REQUESTS_LIST } from 'layouts/Requests/constans';
import { RequestsHeaderMenu } from 'layouts/Requests/RequestsHeaderMenu';
import { addSnackbar } from 'SnackbarHub/actions';
import { fullTimeFormatSelector, languageSelector } from 'state/recoilState';
import { filteredRequestsSelector, getRequestsAtom, ParsedRequest, parsedRequestsSelector } from 'state/requests';
import { useTheme } from 'styles/useTheme';
import { LazyComponentType } from 'utils/custom.types';
import { dateTime } from 'utils/dateTime';
import { isOfType } from 'utils/isOfType';

import { RequestsModalRoutes } from './RequestsModalRoutes';

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<ParsedRequest>>;

const ButtonWithDropdown = withDropdown(Button);

export const Requests = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const timeFormat = useRecoilValue(fullTimeFormatSelector);
  const getFullName = useNameDisplayOrder();
  const { push } = useHistory();
  const match = useCleanRouteMatch();
  const { theme } = useTheme();
  const { query, loading } = useParameterizedQuery(fetchRequestsAction);
  const { mutate: approveMutate } = useMutation(acceptRequestManagementAction);
  const { mutate: mutatePrintRequests } = useMutation(requestPrintAction);
  const { mutate: mutateDownloadRequests } = useMutation(requestDownloadAction);
  const filteredRequests = useRecoilValue(filteredRequestsSelector);
  const setRequestsList = useSetRecoilState(parsedRequestsSelector);
  const setGetRequests = useSetRecoilState(getRequestsAtom);

  const getRequestsCallback = useCallback(async () => {
    const { error, payload } = await query({});

    if (!error && _.isArray(payload)) {
      setRequestsList(payload);
    }
  }, [query, setRequestsList]);

  const approveRequest = useCallback(
    async (id: string) => {
      const { error: submitError } = await approveMutate([id]);
      if (!submitError) {
        addSnackbar({
          message: t({
            id: 'request.snackbar_approved',
            message: 'Request approved!',
          }),
          variant: 'success',
        });
        getRequestsCallback();
      }
    },
    [approveMutate, getRequestsCallback],
  );

  const handlePrintOnClick = useCallback(
    async (id: string) => {
      const { error } = await mutatePrintRequests([id]);

      if (error) {
        addSnackbar({
          message: t({ id: 'error.something_went_wrong' }),
        });
      }
    },
    [mutatePrintRequests],
  );

  const handleDownloadOnClick = useCallback(
    async (id: string) => {
      const { error } = await mutateDownloadRequests([id]);

      if (error) {
        addSnackbar({
          message: t({ id: 'error.something_went_wrong' }),
        });
      }
    },
    [mutateDownloadRequests],
  );

  useEffect(() => {
    getRequestsCallback();
    setGetRequests(() => getRequestsCallback);
  }, [getRequestsCallback, setGetRequests]);

  const columns: StickyListProps<ParsedRequest>['columns'] = [
    {
      key: ['employee'],
      customCellRenderer: (item: [ParsedRequest['employee']]) => (
        <Avatar size={24} image={item[0].avatarUrl} name={item[0].name} />
      ),
      width: '24px',
    },
    {
      title: t({ id: 'team.list.employee_name' }),
      key: 'employee',
      sortableValue: ({ name }: ParsedRequest['employee']) => getFullName(name.firstName, name.surname),
      customCellRenderer: ({ name }: ParsedRequest['employee']) => (
        <TextEllipsis title={getFullName(name.firstName, name.surname)}>
          <FullName firstName={name.firstName} surname={name.surname} />
        </TextEllipsis>
      ),
      columnGrow: 3,
    },
    {
      title: t({ id: 'requests.request_no', message: 'Request NO.' }),
      key: 'number',
      sortableValue: (number: ParsedRequest['number']) => `${number}`,
      customCellRenderer: (number: ParsedRequest['number']) => <TextEllipsis title={number}>{number}</TextEllipsis>,
      columnGrow: 2,
    },
    {
      title: t({ id: 'requests.date_range_duration', message: 'Date range (duration)' }),
      key: ['dateTimeDetails', 'type'],
      sortableValue: ([dateTimeDetails]: [ParsedRequest['dateTimeDetails']]) =>
        `${dateTimeDetails.dateRange?.startUnix} ${dateTimeDetails.dateUnix}`,
      customCellRenderer: ([dateTimeDetails, type]: [ParsedRequest['dateTimeDetails'], ParsedRequest['type']]) => {
        const { dateUnix, dateRange, duration, isDateBound } = dateTimeDetails;

        const displayDate = (() => {
          if (dateRange) {
            const { startUnix, endUnix } = dateRange;
            const startDate = dateTime(startUnix).format(`MMM DD${!isDateBound ? `, ${timeFormat}` : ''}`);
            const endDate = dateTime(endUnix).format(`MMM DD${!isDateBound ? `, ${timeFormat}` : ''}`);

            return `${startDate} - ${endDate}`;
          }

          if (dateUnix) {
            if (type === RequestFormType.TimeTracking) return `${dateTime(dateUnix).format(`MMM DD, ${timeFormat}`)}`;

            return `${dateTime(dateUnix).format(`MMM DD`)}`;
          }

          return '';
        })();

        const displayDuration = (() => {
          if (!duration) return <></>;

          return (
            <Text sx={{ color: 'grayBlues3' }}>
              {' '}
              ({duration.days !== 0 && `${duration.days} days `}
              {duration.hours !== 0 && `${duration.hours}h`}
              {duration.minutes !== 0 && `${duration.minutes}min`})
            </Text>
          );
        })();

        return (
          <TextEllipsis title={displayDate}>
            {displayDate}
            {displayDuration}
          </TextEllipsis>
        );
      },
      columnGrow: 4,
    },
    {
      title: t({ id: 'type' }),
      key: ['requestType', 'typeDescription', 'actionType', 'type'],
      sortableValue: ([type, typeDescription]: [ParsedRequest['type'], ParsedRequest['typeDescription']]) =>
        `${type} ${typeDescription}`,
      customCellRenderer: ([requestType, typeDescription, actionType, type]: [
        ParsedRequest['requestType'],
        ParsedRequest['typeDescription'],
        ParsedRequest['actionType'],
        ParsedRequest['type'],
      ]) => {
        const parsedTypeDescription =
          requestType && isOfType<RequestType>(requestType, 'abbreviation') && t({ id: requestType.abbreviation });

        return (
          <TextEllipsis title={parsedTypeDescription || typeDescription}>
            <RequestTypePresenter
              typeDescription={parsedTypeDescription || typeDescription}
              type={type}
              requestType={requestType}
              actionType={actionType}
            />
          </TextEllipsis>
        );
      },
      columnGrow: 6,
    },
    {
      title: t({ id: 'request.filters.state' }),
      key: ['isDeleted', 'state'],
      sortableValue: ([isDeleted, state]: [ParsedRequest['isDeleted'], ParsedRequest['state']]) =>
        isDeleted ? `${t({ id: 'request.filters.deleted' })} ${state}` : `${state}`,
      customCellRenderer: ([isDeleted, state]: [ParsedRequest['isDeleted'], ParsedRequest['state']]) => (
        <>
          {isDeleted && <RequestStatusBadge requestState={3} />}
          <RequestStatusBadge requestState={state} />
        </>
      ),
      columnGrow: 4,
    },
    {
      key: ['id', 'isDeleted', 'state', 'modificationAllowed', 'deleteAllowed'],
      width: '100px',
      sx: { display: 'flex', justifyContent: 'flex-end', overflow: 'visible' },
      customCellRenderer: ([id, isDeleted, state, modificationAllowed, deleteAllowed]: [
        ParsedRequest['id'],
        ParsedRequest['isDeleted'],
        ParsedRequest['state'],
        ParsedRequest['modificationAllowed'],
        ParsedRequest['deleteAllowed'],
      ]) => (
        <>
          {state === RequestState.Pending && !isDeleted && modificationAllowed && (
            <>
              <Button
                variant="success"
                size="xs"
                shape="rounded"
                prependWith={<Icon size={18} type="approve" />}
                onClick={(e) => {
                  e.stopPropagation();
                  approveRequest(id);
                }}
              />
              <Button
                variant="danger"
                size="xs"
                shape="rounded"
                prependWith={<Icon size={18} type="deny" />}
                onClick={(e) => {
                  e.stopPropagation();
                  push(`${match}${TO.REJECT_REQUESTS_MODAL[language]}`, { id });
                }}
              />
            </>
          )}
          <ButtonWithDropdown
            dropdownProps={{
              links: [
                {
                  label: t({ id: 'global.print' }),
                  icon: 'print',
                  onClick: () => handlePrintOnClick(id),
                },
                {
                  label: t({ id: 'global.download', message: 'Download' }),
                  icon: 'download',
                  onClick: () => handleDownloadOnClick(id),
                },
                {
                  prependWithDivider: true,
                  disabled: isDeleted || !deleteAllowed,
                  label: t({ id: 'delete' }),
                  icon: 'delete',
                  to: {
                    pathname: `${match}${TO.DELETE_REQUESTS_MODAL[language]}`,
                    state: { ids: [id] },
                  },
                },
              ],
            }}
            shape="rounded"
            size="sm"
            variant="minimal"
            onClick={(e) => e.stopPropagation()}
          >
            <Icon type="more" />
          </ButtonWithDropdown>
        </>
      ),
    },
  ];

  const handleOnRowClick: StickyListProps['onRowClick'] = (id) => {
    push(`${match}${TO.REQUEST_DETAILS__ID[language]}/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>{t({ id: 'requests.page_title', message: `Requests - ${APP_NAME}` })}</title>
      </Helmet>

      <Main>
        <Main.Header>
          <Main.Header.Title>
            <Trans id="navbar.menu.requests" />
          </Main.Header.Title>
          <Main.Header.DateRangeBadge />
          <Main.Header.Deselect selectorFamilyID={REQUESTS_LIST} />
          <RequestsHeaderMenu />
        </Main.Header>
        <Flex sx={{ flexGrow: 1, mx: -4 }}>
          <React.Suspense
            fallback={
              <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <LoadingSpinnerCss size={4} />
              </Flex>
            }
          >
            <LazyStickyList
              name={REQUESTS_LIST}
              select="checkbox"
              list={filteredRequests}
              columns={columns}
              onRowClick={handleOnRowClick}
              emptyListMessage={
                loading
                  ? t({ id: 'global.loading' })
                  : `${t({ id: 'requests.empty_list', message: 'There is nothing to show.' })} :(`
              }
              showHeader
              showContentPlaceholder
              style={{
                paddingLeft: theme.space[4],
                paddingRight: theme.space[4],
              }}
            />
          </React.Suspense>
        </Flex>
      </Main>
      <RequestsModalRoutes />
    </>
  );
};
