import React from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { useMutation } from 'react-fetching-library';
import { Redirect } from 'react-router';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { parsedEmployeesSelector } from 'state/employees';
import { Modal } from 'components/Modal/Modal';
import { delay } from 'utils/delay';
import { inviteEmployeesAction } from 'api/actions/employees/employeesActions';
import { useSelectedIdsModalLogic } from 'layouts/Team/hooks/useSelectedIdsModalLogic';
import { useModal } from 'hooks/useModal/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

export const ResendInvitation = (): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const employees = useRecoilValue(parsedEmployeesSelector);
  const { handleClose, baseRoute } = useModal();
  const { selectedIds, selectedNames, loading, setLoading } = useSelectedIdsModalLogic(employees, [
    'name.firstName',
    'name.surname',
  ]);

  const { mutate } = useMutation(inviteEmployeesAction);

  const handleReInvite = async () => {
    setLoading(true);

    const inviteEmployeesActionProps = selectedIds.map((employeeId) => ({ employeeId }));

    const { error: submitError } = await mutate(inviteEmployeesActionProps);

    if (!submitError) {
      if (handleClose) {
        handleClose();
      }
    }
    setLoading(false);
    if (!submitError) {
      await delay(100);
      addSnackbar({
        message: t({ id: 'team.reinvite_bulk.reinvited', message: 'Successfully re-invited!' }),
        variant: 'success',
      });
    }
  };
  if (!selectedIds.length) return <Redirect to={baseRoute} />;

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.reinvite">Resend invitation</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans id="team.reinvite_bulk.info">
          <Text>
            Please,
            <strong> check if all teammates have correct e-mail addresses.</strong> New invitations will be send only to
            those teammates who have an expired ones.
          </Text>{' '}
          <Text sx={{ display: 'block' }} mt={2}>
            You are re-inviting users: <strong>{selectedNames.join(', ')}.</strong>
          </Text>
          <Text sx={{ display: 'block' }} mt={3}>
            <strong> Hit the Send button </strong>
            and we will take care of the rest. 😊
          </Text>
        </Trans>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleReInvite,
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
