export enum ToleranceThresholds {
  oneHundred = 100,
  threeHundred = 300,
  fiveHundred = 500,
  oneThousand = 1000,
  twoThousand = 2000,
  fiveThousand = 5000,
}
// list name
export const LOCATIONS = 'LOCATIONS';
