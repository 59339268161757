import React, { CSSProperties, RefObject, useMemo } from 'react';
import { FlexOwnProps, Flex, InputProps } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { i18n } from '@lingui/core';

import { Avatar } from 'components/Avatar/Avatar';
import { useTheme } from 'styles/useTheme';
import { Tag } from 'components/Tag/Tag';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { TextEllipsis } from 'components/utils/TextEllipsis';

import { ITEM_SIZE_DEFAULT } from './constants';
import { EmployeeOption } from './types';

type OptionProps = Omit<FlexOwnProps, 'color'> &
  Omit<EmployeeOption, 'id'> &
  Pick<React.ComponentPropsWithoutRef<'div'>, 'style'> & {
    onClick: () => void;
    active?: boolean;
    fontSize: number;
  };

type InnerElementProps = {
  style: CSSProperties;
};

const defaultOptionProps: Partial<OptionProps> = {
  active: false,
};

const Option = ({
  label,
  name,
  avatar,
  role,
  tags,
  onClick,
  active,
  style,
  fontSize,
  ...props
}: OptionProps): React.ReactElement => (
  <Flex
    variant={!active ? 'forms.select.option' : 'forms.select.option.active'}
    onClick={onClick}
    style={style}
    sx={{ ...props.sx, whiteSpace: 'nowrap', fontSize }}
    {...props}
  >
    <Avatar circle size={21} image={avatar} name={{ ...name }} sx={{ flexShrink: 0, alignSelf: 'center' }} />
    <TextEllipsis sx={{ ml: 2, alignSelf: 'center' }} title={label}>
      {label}
    </TextEllipsis>

    <Flex sx={{ ml: 5 }}>
      <Tag
        key={role.name}
        variant="outline"
        title={i18n._(
          t({
            id: role.name,
          }),
        )}
        color={UserSelectableColor[role.color]}
        sx={{ alignSelf: 'center' }}
      >
        {i18n._(
          t({
            id: role.name,
          }),
        )}
      </Tag>

      {tags &&
        tags.map(
          (tag) =>
            tag && (
              <Tag
                key={tag.name}
                title={tag.name}
                color={UserSelectableColor[tag.color]}
                sx={{ ml: '.125rem', alignSelf: 'center' }}
              >
                {tag.name}
              </Tag>
            ),
        )}
    </Flex>
  </Flex>
);

Option.defaultProps = defaultOptionProps;

const EmptyList = (): React.ReactElement => (
  <Flex
    sx={{ flexGrow: 1, textAlign: 'center', cursor: 'not-allowed', alignItems: 'center', justifyContent: 'center' }}
  >
    <Trans id="select.empty_list">No more results</Trans>
  </Flex>
);

type OptionListWrapperProps = FlexOwnProps &
  React.ComponentPropsWithRef<'div'> & {
    children: React.ReactNode[] | React.ReactNode;
  };

const OptionListWrapper = ({ children, ...props }: OptionListWrapperProps): React.ReactElement => (
  <Flex
    sx={{
      height: '300px',
      width: '100%',
      bg: 'dropdown.background',
      boxShadow: 'dropdown',
      borderRadius: 'sm',
      overflow: 'hidden',
    }}
    {...props}
  >
    {children}
  </Flex>
);

type OptionListProps = {
  filteredList: EmployeeOption[];
  size: 'default' | 'sm' | 'xs';
  fixedSizeListRef: RefObject<FixedSizeList>;
  innerRef: RefObject<HTMLDivElement>;
  selectedOption: InputProps['value'];
  onOptionClick: (option: EmployeeOption) => void;
};

const OptionList = ({
  filteredList,
  size,
  fixedSizeListRef,
  innerRef,
  selectedOption,
  onOptionClick,
}: OptionListProps): React.ReactElement => {
  const { theme } = useTheme();

  const { fontSize } = useMemo(() => theme.forms.input.sizes[size], [size, theme.forms.input.sizes]);

  const rowRenderer = ({ index, style, ...injectedProps }: ListChildComponentProps) => {
    const active = selectedOption === filteredList[index].id;

    const filteredOption = filteredList[index];

    return (
      <Option
        {...injectedProps}
        key={filteredOption.id}
        label={filteredOption.label}
        name={filteredOption.name}
        avatar={filteredOption.avatar}
        role={filteredOption.role}
        tags={filteredOption.tags}
        active={active}
        onClick={() => onOptionClick(filteredOption)}
        fontSize={fontSize}
        style={style}
      />
    );
  };

  const innerElementType = React.forwardRef<HTMLDivElement, InnerElementProps>(
    ({ style, ...rest }: InnerElementProps, ref) => (
      <div
        ref={ref}
        style={{
          position: 'relative',
          ...style,
        }}
        {...rest}
      />
    ),
  );

  return (
    <OptionListWrapper>
      {filteredList.length === 0 && <EmptyList />}
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            ref={fixedSizeListRef}
            innerRef={innerRef}
            itemSize={ITEM_SIZE_DEFAULT}
            innerElementType={innerElementType}
            height={height}
            itemCount={filteredList.length}
            width={width}
            overscanCount={Math.floor(height / ITEM_SIZE_DEFAULT)}
            style={{
              padding: theme.space[1],
            }}
          >
            {rowRenderer}
          </FixedSizeList>
        )}
      </AutoSizer>
    </OptionListWrapper>
  );
};

export const MemoizedOptionList = React.memo(OptionList);
