import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { Status } from 'use-timer/lib/types';
import { Location } from 'history';
import { useHistory } from 'react-router';

import { fetchOrganizationSettingsAction } from 'api/actions/settings/settingsActions';
import {
  BasicInformationSettingsActionProps,
  Currencies,
  FetchOrganizationSettingsResponse,
  OrganizationHolidaysSettingsActionProps,
  PreferencesSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { InputOption } from 'components/ui/Select/Select';
import { useBlockRouteTransition } from 'hooks/useBlockTransition/useBlockRouteTransition';
import { refreshSettingAtomFamily } from 'state/settings';
import { createEvent } from 'utils/createEvent';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

import { MemoizedBasicInformation } from './Organization/BasicInformation';
import { MemoizedHolidays } from './Organization/Holidays';
import { MemoizedPreferences } from './Organization/Preferences';

export const OrganizationForms = (): React.ReactElement => {
  const [organizationState, setOrganizationState] = useRecoilState(refreshSettingAtomFamily('ORGANIZATION'));
  const { query } = useClient();
  const history = useHistory();

  const payloadRef = useRef<FetchOrganizationSettingsResponse | null>(null);
  const blockLocationPathnameRef = useRef<string | null>(null);

  const [transitionPage, setTransitionPage] = useState<boolean>(false);

  const basicInformationShouldBlockTransitionRef = useRef<boolean>(false);
  const basicInformationFormPendingRef = useRef<boolean>(false);
  const basicInformationStatusRef = useRef<Status>('STOPPED');

  const preferencesShouldBlockTransitionRef = useRef<boolean>(false);
  const preferencesFormPendingRef = useRef<boolean>(false);
  const preferencesStatusRef = useRef<Status>('STOPPED');

  const organizationHolidaysShouldBlockTransitionRef = useRef<boolean>(false);
  const organizationHolidaysFormPendingRef = useRef<boolean>(false);
  const organizationHolidaysStatusRef = useRef<Status>('STOPPED');

  const basicInformationFormRef = useRef<HTMLFormElement | null>(null);
  const preferencesFormRef = useRef<HTMLFormElement | null>(null);
  const organizationHolidaysFormRef = useRef<HTMLFormElement | null>(null);

  const [basicInformationPayload, setBasicInformationPayload] =
    useState<BasicInformationSettingsActionProps | null>(null);
  const [preferencesPayload, setPreferencesPayload] = useState<PreferencesSettingsActionProps | null>(null);
  const [organizationHolidaysPayload, setOrganizationHolidaysPayload] =
    useState<OrganizationHolidaysSettingsActionProps | null>(null);

  const basicInformationPayloadRef = useRef<BasicInformationSettingsActionProps | null>(null);
  const preferencesPayloadRef = useRef<PreferencesSettingsActionProps | null>(null);
  const organizationHolidaysPayloadRef = useRef<OrganizationHolidaysSettingsActionProps | null>(null);

  const refresh = useCallback(async () => {
    const { payload } = await query(fetchOrganizationSettingsAction());

    if (payload) {
      setOrganizationState((prevState) =>
        !_.isEqual(prevState?.payload, payload) ? { ...prevState, payload } : prevState,
      );
      payloadRef.current = payload;
    }
  }, [query, setOrganizationState]);

  useEffect(() => {
    setOrganizationState((prevState) => ({ ...prevState, refresh }));
  }, [refresh, setOrganizationState]);

  useEffect(() => {
    if (!_.isEqual(payloadRef.current, organizationState?.payload) && !blockLocationPathnameRef.current) {
      refresh();
    }
  }, [refresh, organizationState?.payload]);

  useEffect(() => {
    if (organizationState && organizationState.payload) {
      const newBasicInformationPayload = _.pick(organizationState.payload, ['name', 'address', 'countryCode', 'vatId']);
      const newPreferencesPayload = _.pick(organizationState.payload, [
        'timeZoneId',
        'startingWeekDay',
        'displayCurrency',
      ]);
      const newOrganizationHolidaysPayload = _.pick(organizationState.payload, [
        'showHolidaysOnAllCalendars',
        'autoImportHolidaysEachYear',
      ]);

      if (!_.isEqual(basicInformationPayloadRef.current, newBasicInformationPayload)) {
        setBasicInformationPayload(newBasicInformationPayload);
        basicInformationPayloadRef.current = newBasicInformationPayload;
      }
      if (!_.isEqual(preferencesPayloadRef.current, newPreferencesPayload)) {
        setPreferencesPayload(newPreferencesPayload);
        preferencesPayloadRef.current = newPreferencesPayload;
      }
      if (!_.isEqual(organizationHolidaysPayloadRef.current, newOrganizationHolidaysPayload)) {
        setOrganizationHolidaysPayload(newOrganizationHolidaysPayload);
        organizationHolidaysPayloadRef.current = newOrganizationHolidaysPayload;
      }
    }
  }, [organizationState]);

  useEffect(() => {
    if (transitionPage && blockLocationPathnameRef.current) {
      history.push(blockLocationPathnameRef.current);
    }
  }, [history, transitionPage]);

  const validatePageTransitionCallback = useCallback((location: Location) => {
    const submitEvent = createEvent('submit');
    blockLocationPathnameRef.current = location.pathname;

    if (basicInformationStatusRef.current === 'RUNNING') {
      basicInformationFormRef.current?.dispatchEvent(submitEvent);
    }

    if (basicInformationShouldBlockTransitionRef.current || basicInformationFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    if (preferencesStatusRef.current === 'RUNNING') {
      preferencesFormRef.current?.dispatchEvent(submitEvent);
    }

    if (preferencesShouldBlockTransitionRef.current || preferencesFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    if (organizationHolidaysStatusRef.current === 'RUNNING') {
      organizationHolidaysFormRef.current?.dispatchEvent(submitEvent);
    }

    if (organizationHolidaysShouldBlockTransitionRef.current || organizationHolidaysFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    return true;
  }, []);

  useBlockRouteTransition(validatePageTransitionCallback);

  const currencyOptions: InputOption[] = useMemo(() => {
    if (organizationState && organizationState.payload) {
      const currenciesPayload: Currencies = organizationState.payload.currencies;

      if (currenciesPayload) {
        return currenciesPayload.map((item) => ({
          label: item.value,
          id: item.key,
        }));
      }
    }

    return [];
  }, [organizationState]);

  return (
    <>
      {organizationState && organizationState.payload ? (
        <>
          {basicInformationPayload && (
            <MemoizedBasicInformation
              ref={basicInformationFormRef}
              payload={basicInformationPayload}
              blockLocationPathnameRef={blockLocationPathnameRef}
              basicInformationShouldBlockTransitionRef={basicInformationShouldBlockTransitionRef}
              basicInformationFormPendingRef={basicInformationFormPendingRef}
              basicInformationStatusRef={basicInformationStatusRef}
              setTransitionPage={setTransitionPage}
            />
          )}
          {preferencesPayload && (
            <MemoizedPreferences
              ref={preferencesFormRef}
              payload={preferencesPayload}
              blockLocationPathnameRef={blockLocationPathnameRef}
              preferencesShouldBlockTransitionRef={preferencesShouldBlockTransitionRef}
              preferencesFormPendingRef={preferencesFormPendingRef}
              preferencesStatusRef={preferencesStatusRef}
              currencyOptions={currencyOptions}
              setTransitionPage={setTransitionPage}
            />
          )}
          {organizationHolidaysPayload && (
            <MemoizedHolidays
              ref={organizationHolidaysFormRef}
              payload={organizationHolidaysPayload}
              blockLocationPathnameRef={blockLocationPathnameRef}
              organizationHolidaysShouldBlockTransitionRef={organizationHolidaysShouldBlockTransitionRef}
              organizationHolidaysFormPendingRef={organizationHolidaysFormPendingRef}
              organizationHolidaysStatusRef={organizationHolidaysStatusRef}
              setTransitionPage={setTransitionPage}
            />
          )}
        </>
      ) : (
        <Flex
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            minHeight: '400px',
          }}
        >
          <LoadingSpinnerCss size={4} />
        </Flex>
      )}
    </>
  );
};
