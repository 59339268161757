/* eslint-disable @typescript-eslint/no-explicit-any */
import { FixedSizeGridProps, FixedSizeListProps, GridChildComponentProps, ListChildComponentProps } from 'react-window';
import { ThemeUIStyleObject } from 'theme-ui';
import React from 'react';

import { MergedProperties } from 'utils/custom.types';

type KeyType<K> = K extends K ? keyof K : never;
export type ListItem = {
  id: string;
  isNonEditable?: boolean;
  [key: string]: React.ReactNode;
};
type Renderer<T = any> = (
  item: T,
  itemId: string,
  rowRef?: React.MutableRefObject<HTMLDivElement | null>,
  isSortedBy?: boolean,
) => React.ReactNode;

type SortableValue<T = any> = ((item: T) => string | number) | null;
type OnRowClick = (id: ListItem['id']) => void;
export type IsRowUncheckableValidator = (id: ListItem['id']) => boolean;

export type StickyListProps<T = any, K = MergedProperties<T, { [K: string | number]: any }>> = {
  name: string;
  columns: ListColumn<K>[];
  list: ListItem[] | Map<string, ListItem>;
  showContentPlaceholder?: boolean;
  select?: 'checkbox' | 'radio';
  emptyListMessage?: React.ReactNode;
  variant?: 'default' | 'inverted';
  showHeader?: boolean;
  disableNativeKeyboardScrolling?: boolean;
  onRowClick?: OnRowClick;
  isRowUncheckableValidator?: IsRowUncheckableValidator;
  fallbackSortableValueGetter?: SortableValue;
} & (
  | {
      type?: 'list';
      style?: FixedSizeListProps['style'];
      frozenColumns?: never; // column index
      onRowClick?: OnRowClick;
    }
  | {
      type?: 'grid';
      frozenColumns?: number; // no of columns to froze
      style?: FixedSizeGridProps['style'];
      onRowClick?: never;
    }
);

export type ListColumn<K = { [K: string]: any }> = {
  key: KeyType<K> | KeyType<K>[];
  sortableValue?: SortableValue;
  title?: string;
  customCellRenderer?: Renderer;
  customHeaderRenderer?: (title?: string) => React.ReactNode;
  sx?: ThemeUIStyleObject;
  width?: number | string | (number | string | null)[];
  columnGrow?: number;
};

export type RowProps = {
  id: ListItem['id'];
  data: ListItem;
  listName: StickyListProps['name'];
  onRowClick?: OnRowClick;
  uncheckable?: boolean;
} & Pick<StickyListProps, 'columns' | 'select' | 'variant' | 'frozenColumns'>;

export type HeaderProps = Omit<RowProps, 'data' | 'onRowClick' | 'id'> &
  Pick<StickyListProps, 'type'> & {
    style?: FixedSizeListProps['style'] | FixedSizeGridProps['style'];
    sx?: ThemeUIStyleObject;
  };

export type GridCellProps = Omit<RowProps, 'columns'> & {
  isSticky?: boolean;
  columnData: ListColumn;
};

type StickyListDataProps = Pick<
  StickyListProps,
  | 'name'
  | 'columns'
  | 'select'
  | 'showHeader'
  | 'list'
  | 'onRowClick'
  | 'variant'
  | 'isRowUncheckableValidator'
  | 'frozenColumns'
> & {
  itemCount: number;
};

export type StickyListContextProps = {
  showHeader?: boolean;
  showContentPlaceholder?: boolean;
  listStyle?: StickyListProps['style'];
} & Partial<StickyListDataProps> &
  Partial<Pick<StickyListProps, 'variant' | 'frozenColumns' | 'name' | 'type' | 'disableNativeKeyboardScrolling'>>;

export type ListRendererProps = ListChildComponentProps<StickyListDataProps>;
export type GridRendererProps = GridChildComponentProps<Omit<StickyListDataProps, 'onRowClick'>>;

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type ColumnHeaderProps = {
  columnKey: ListColumn['key'];
  isSortable: boolean;
  children?: ListColumn['title'] | React.ReactNode;
  hasCustomRenderer?: boolean;
} & Pick<ListColumn, 'title'> &
  Pick<HeaderProps, 'listName'>;
