import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { Status } from 'use-timer/lib/types';
import { useHistory } from 'react-router';
import { Location } from 'history';

import { refreshSettingAtomFamily } from 'state/settings';
import {
  AdvancedSettingsActionProps,
  FetchTimeTrackingSettingsResponse,
  OvertimeSettingsActionProps,
  PayPeriodSettingsActionProps,
  TimeClocksSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { fetchTimeTrackingSettingsAction } from 'api/actions/settings/settingsActions';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { useBlockRouteTransition } from 'hooks/useBlockTransition/useBlockRouteTransition';
import { createEvent } from 'utils/createEvent';

import { MemoizedAdvanced } from './TimeTracking/Advanced';
import { MemoizedOvertime } from './TimeTracking/Overtime';
import { MemoizedPayPeriod } from './TimeTracking/PayPeriod';
import { MemoizedTimeClocks } from './TimeTracking/TimeClocks';

export const TimeTrackingForms = (): React.ReactElement => {
  const history = useHistory();
  const { query } = useClient();

  const [timeTrackingState, setTimeTrackingState] = useRecoilState(refreshSettingAtomFamily('TIME_TRACKING'));
  const payloadRef = useRef<FetchTimeTrackingSettingsResponse | null>(null);
  const blockLocationPathnameRef = useRef<string | null>(null);

  const [transitionPage, setTransitionPage] = useState<boolean>(false);

  const advancedShouldBlockTransitionRef = useRef<boolean>(false);
  const advancedFormPendingRef = useRef<boolean>(false);
  const advancedStatusRef = useRef<Status>('STOPPED');

  const overTimeShouldBlockTransitionRef = useRef<boolean>(false);
  const overTimeFormPendingRef = useRef<boolean>(false);
  const overTimeStatusRef = useRef<Status>('STOPPED');

  const payPeriodShouldBlockTransitionRef = useRef<boolean>(false);
  const payPeriodFormPendingRef = useRef<boolean>(false);
  const payPeriodStatusRef = useRef<Status>('STOPPED');

  const timeClocksShouldBlockTransitionRef = useRef<boolean>(false);
  const timeClocksFormPendingRef = useRef<boolean>(false);
  const timeClocksStatusRef = useRef<Status>('STOPPED');

  const advancedFormRef = useRef<HTMLFormElement | null>(null);
  const overTimeFormRef = useRef<HTMLFormElement | null>(null);
  const payPeriodFormRef = useRef<HTMLFormElement | null>(null);
  const timeClocksFormRef = useRef<HTMLFormElement | null>(null);

  const [advancedPayload, setAdvancedPayload] = useState<AdvancedSettingsActionProps | null>(null);
  const [overtimePayload, setOvertimePayload] = useState<OvertimeSettingsActionProps | null>(null);
  const [payPeriodPayload, setPayPeriodPayload] = useState<PayPeriodSettingsActionProps | null>(null);
  const [timeClocksPayload, setTimeClocksPayload] = useState<TimeClocksSettingsActionProps | null>(null);

  const advancedPayloadRef = useRef<AdvancedSettingsActionProps | null>(null);
  const overtimePayloadRef = useRef<OvertimeSettingsActionProps | null>(null);
  const payPeriodPayloadRef = useRef<PayPeriodSettingsActionProps | null>(null);
  const timeClocksPayloadRef = useRef<TimeClocksSettingsActionProps | null>(null);

  const refresh = useCallback(async () => {
    const { payload } = await query(fetchTimeTrackingSettingsAction());

    if (payload) {
      setTimeTrackingState((prevState) =>
        !_.isEqual(prevState?.payload, payload) ? { ...prevState, payload } : prevState,
      );
      payloadRef.current = payload;
    }
  }, [query, setTimeTrackingState]);

  useEffect(() => {
    setTimeTrackingState((prevState) => ({ ...prevState, refresh }));
  }, [refresh, setTimeTrackingState]);

  useEffect(() => {
    if (!_.isEqual(payloadRef.current, timeTrackingState?.payload) && !blockLocationPathnameRef.current) {
      refresh();
    }
  }, [refresh, timeTrackingState?.payload]);

  useEffect(() => {
    if (timeTrackingState && timeTrackingState.payload) {
      const newAdvancedPayload = _.pick(timeTrackingState.payload, [
        'typeOfWorktimeWithoutSchedule',
        'countNightHours',
        'nightHoursStartSeconds',
        'nightHoursDurationSeconds',
      ]);
      const newOvertimePayload = _.pick(timeTrackingState.payload, ['minOvertimeSeconds', 'autoBalanceOvertime']);
      const newPayPeriodPayload = _.pick(timeTrackingState.payload, ['payrollPeriod', 'overtimePeriod']);
      const newTimeClocksPayload = _.pick(timeTrackingState.payload, [
        'showPaidBreak',
        'limitPaidBreak',
        'paidBreakLimitSeconds',
        'showUnPaidBreak',
        'enableFaceVerification',
        'restrictLocations',
        'enableOfflineMode',
        'sendEventPhotos',
        'allowTimeRounding',
        'roundToMinutes',
      ]);

      if (!_.isEqual(advancedPayloadRef.current, newAdvancedPayload)) {
        setAdvancedPayload(newAdvancedPayload);
        advancedPayloadRef.current = newAdvancedPayload;
      }
      if (!_.isEqual(overtimePayloadRef.current, newOvertimePayload)) {
        setOvertimePayload(newOvertimePayload);
        overtimePayloadRef.current = newOvertimePayload;
      }
      if (!_.isEqual(payPeriodPayloadRef.current, newPayPeriodPayload)) {
        setPayPeriodPayload(newPayPeriodPayload);
        payPeriodPayloadRef.current = newPayPeriodPayload;
      }
      if (!_.isEqual(timeClocksPayloadRef.current, newTimeClocksPayload)) {
        setTimeClocksPayload(newTimeClocksPayload);
        timeClocksPayloadRef.current = newTimeClocksPayload;
      }
    }
  }, [timeTrackingState]);

  useEffect(() => {
    if (transitionPage && blockLocationPathnameRef.current) {
      history.push(blockLocationPathnameRef.current);
    }
  }, [history, transitionPage]);

  const validatePageTransitionCallback = useCallback((location: Location) => {
    const submitEvent = createEvent('submit');
    blockLocationPathnameRef.current = location.pathname;

    if (timeClocksStatusRef.current === 'RUNNING') {
      timeClocksFormRef.current?.dispatchEvent(submitEvent);
    }

    if (timeClocksShouldBlockTransitionRef.current || timeClocksFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    if (overTimeStatusRef.current === 'RUNNING') {
      overTimeFormRef.current?.dispatchEvent(submitEvent);
    }

    if (overTimeShouldBlockTransitionRef.current || overTimeFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    if (payPeriodStatusRef.current === 'RUNNING') {
      payPeriodFormRef.current?.dispatchEvent(submitEvent);
    }

    if (payPeriodShouldBlockTransitionRef.current || payPeriodFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    if (advancedStatusRef.current === 'RUNNING') {
      advancedFormRef.current?.dispatchEvent(submitEvent);
    }

    if (advancedShouldBlockTransitionRef.current || advancedFormPendingRef.current) {
      setTransitionPage(false);
      return false;
    }

    return true;
  }, []);

  useBlockRouteTransition(validatePageTransitionCallback);

  return (
    <>
      {timeTrackingState && timeTrackingState.payload ? (
        <Flex sx={{ gap: '2.25rem', flexDirection: 'column', maxWidth: '600px' }}>
          {timeClocksPayload && (
            <MemoizedTimeClocks
              ref={timeClocksFormRef}
              payload={timeClocksPayload}
              blockLocationPathnameRef={blockLocationPathnameRef}
              timeClocksShouldBlockTransitionRef={timeClocksShouldBlockTransitionRef}
              timeClocksFormPendingRef={timeClocksFormPendingRef}
              timeClocksStatusRef={timeClocksStatusRef}
              setTransitionPage={setTransitionPage}
            />
          )}
          {overtimePayload && (
            <MemoizedOvertime
              ref={overTimeFormRef}
              payload={overtimePayload}
              blockLocationPathnameRef={blockLocationPathnameRef}
              overTimeShouldBlockTransitionRef={overTimeShouldBlockTransitionRef}
              overTimeFormPendingRef={overTimeFormPendingRef}
              overTimeStatusRef={overTimeStatusRef}
              setTransitionPage={setTransitionPage}
            />
          )}
          {payPeriodPayload && (
            <MemoizedPayPeriod
              ref={payPeriodFormRef}
              payload={payPeriodPayload}
              blockLocationPathnameRef={blockLocationPathnameRef}
              payPeriodShouldBlockTransitionRef={payPeriodShouldBlockTransitionRef}
              payPeriodFormPendingRef={payPeriodFormPendingRef}
              payPeriodStatusRef={payPeriodStatusRef}
              setTransitionPage={setTransitionPage}
            />
          )}
          {advancedPayload && (
            <MemoizedAdvanced
              ref={advancedFormRef}
              payload={advancedPayload}
              blockLocationPathnameRef={blockLocationPathnameRef}
              advancedShouldBlockTransitionRef={advancedShouldBlockTransitionRef}
              advancedFormPendingRef={advancedFormPendingRef}
              advancedStatusRef={advancedStatusRef}
              setTransitionPage={setTransitionPage}
            />
          )}
        </Flex>
      ) : (
        <Flex
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            minHeight: '400px',
          }}
        >
          <LoadingSpinnerCss size={4} />
        </Flex>
      )}
    </>
  );
};
