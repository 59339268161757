import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo, useRef, useTransition } from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { TextInput } from 'components/ui/TextInput';
import { BusinessHistoryList } from '../elements/BusinessHistoryList';
import { selectedBusinessHistoryDataSelector } from 'state/requests';
import { INPUT_DEBOUNCE_TIME } from 'layouts/Requests/constans';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { DualCalendar } from 'components/ui/DualCalendar/DualCalendar';

type Props = {
  action: RequestActionType;
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCommonForm: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BusinessTripForm = ({ action, setIsSaveDisabled, setShowCommonForm }: Props): React.ReactElement => {
  useLingui();
  const selectedBusinessData = useRecoilValue(selectedBusinessHistoryDataSelector);

  const firstRenderRef = useRef<boolean>(true);
  const actionRef = useRef<RequestActionType>(action);
  const [isInTransition, startTransition] = useTransition();

  const {
    register,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useFormContext();

  const calendarWatch: [number, number] | undefined = watch('businessTripData.datesUnix');
  const nameWatch: string | undefined = watch('businessTripData.name');
  const placeWatch: string | undefined = watch('businessTripData.place');

  const nameRegister = useMemo(
    () => register('businessTripData.name', { required: t({ id: 'global.forms.required' }) }),
    [register],
  );
  const placeRegister = useMemo(
    () => register('businessTripData.place', { required: t({ id: 'global.forms.required' }) }),
    [register],
  );
  const calendarRegister = useMemo(
    () =>
      register('businessTripData.datesUnix', {
        setValueAs: (v: string) => {
          if (!v || _.isEmpty(v) || _.isArray(v)) return undefined;
          return v.split(',').map((date) => parseInt(date, 10));
        },
      }),
    [register],
  );

  const shouldDisplayForm = useMemo(() => {
    if (action === RequestActionType.Edit && !selectedBusinessData) return false;

    if (action === RequestActionType.Remove) return false;

    return true;
  }, [action, selectedBusinessData]);

  const shouldDisplayHistory = useMemo(() => {
    switch (action) {
      case RequestActionType.Edit:
      case RequestActionType.Remove:
        return true;
      default:
        return false;
    }
  }, [action]);

  useEffect(() => {
    if (
      (calendarWatch && nameWatch && nameWatch.length && placeWatch && placeWatch.length) ||
      (action === RequestActionType.Remove && selectedBusinessData)
    ) {
      setIsSaveDisabled(false);
    }
  }, [action, calendarWatch, nameWatch, placeWatch, selectedBusinessData, setIsSaveDisabled]);

  useEffect(() => {
    if (shouldDisplayForm) {
      setShowCommonForm(true);
    }
  }, [setShowCommonForm, shouldDisplayForm]);

  useEffect(() => {
    actionRef.current = action;
  }, [action]);

  useEffect(() => {
    if (!selectedBusinessData && !firstRenderRef.current) {
      reset({ actionType: actionRef.current, processedById: '', optionalStepProcessedById: '' });
      setValue('businessTripData.name', '');
      setValue('businessTripData.place', '');
      setValue('businessTripData.datesUnix', []);
      setIsSaveDisabled(true);
    }

    firstRenderRef.current = false;
  }, [reset, selectedBusinessData, setIsSaveDisabled, setShowCommonForm, setValue]);

  useEffect(() => {
    startTransition(() => {
      if (selectedBusinessData) {
        const {
          id,
          name,
          place,
          dateTimeDetails: { dateRange },
        } = selectedBusinessData;

        setValue('requestId', id);

        if (shouldDisplayForm) {
          setValue('businessTripData.name', name);
          setValue('businessTripData.place', place);
          if (dateRange) {
            const { startUnix, endUnix } = dateRange;
            setValue('businessTripData.datesUnix', [startUnix, endUnix].join(','));
          }
        }
      }
    });
  }, [selectedBusinessData, setValue, shouldDisplayForm]);

  return (
    <>
      {shouldDisplayHistory && <BusinessHistoryList label="Select an business request to edit" />}
      {shouldDisplayForm && (
        <Flex sx={{ flexDirection: 'column', gap: 4, position: 'relative' }}>
          {isInTransition && <LoadingOverlay sx={{ zIndex: 1000 }} />}
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ mb: 2, fontWeight: 'bold' }}>
              <Trans id="global.forms.name" />
            </Text>
            <TextInput
              id="bname"
              type="text"
              {..._.omit(nameRegister, 'onChange')}
              onChange={_.debounce((e) => {
                nameRegister.onChange(e);
              }, INPUT_DEBOUNCE_TIME)}
              placeholder={t({
                id: 'add_request.business_trip.name.placeholder',
                message: 'Business trip fancy name',
              })}
              variant="rounded"
              size="sm"
              error={!!errors.businessTripData?.name}
              errorMessage={errors.businessTripData?.name?.message}
            />
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ mb: 2, fontWeight: 'bold' }}>
              <Trans id="add_request.business_trip.place">Place</Trans>
            </Text>
            <TextInput
              id="bplace"
              type="text"
              {..._.omit(placeRegister, 'onChange')}
              onChange={_.debounce((e) => placeRegister.onChange(e), INPUT_DEBOUNCE_TIME)}
              placeholder={t({
                id: 'add_request.business_trip.place',
                message: 'Street, City',
              })}
              variant="rounded"
              size="sm"
              error={!!errors.businessTripData?.place}
              errorMessage={errors.businessTripData?.place?.message}
            />
          </Flex>
          <DualCalendar
            {..._.omit(calendarRegister, 'onChange')}
            onChange={_.debounce((e) => calendarRegister.onChange(e), INPUT_DEBOUNCE_TIME)}
            range
          />
        </Flex>
      )}
    </>
  );
};
