import React, { useCallback, useEffect, useRef } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { fetchHolidayImportLocationsAction } from 'api/actions/holidays/holidaysActions';
import { countryListAtom, countrySelectSelector } from 'state/settings';
import { delay } from 'utils/delay';
import { emitUpdateFieldView } from 'utils/emitUpdateFieldView';
import { mergeRefs } from 'utils/mergeRefs';
import { Select, SelectProps } from '../Select/Select';

type Props = Omit<SelectProps, 'options'>;

export const CountrySelect = React.forwardRef<HTMLInputElement, Props>(
  ({ ...props }: Props, ref): React.ReactElement => {
    const { query } = useClient();
    const [countryList, setCountryList] = useRecoilState(countryListAtom);
    const countrySelectOptions = useRecoilValue(countrySelectSelector);

    const selectRef = useRef<HTMLInputElement | null>(null);

    const getCountryList = useCallback(async () => {
      const { payload, error } = await query(fetchHolidayImportLocationsAction());

      if (payload && !error) {
        const updateSelectView = async () => {
          await delay(0);
          emitUpdateFieldView(selectRef);
        };

        setCountryList(payload);
        updateSelectView();
      }
    }, [query, setCountryList]);

    useEffect(() => {
      if (!countryList) getCountryList();
    }, [countryList, getCountryList]);

    return <Select ref={mergeRefs([ref, selectRef])} {...props} options={countrySelectOptions || []} />;
  },
);
