import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { useFaceDetection } from 'Kiosk/hooks/useFaceLandmarkModel';

import { MemorizedPoint } from './Point';

const scanningPointsSx: ThemeUIStyleObject = {
  position: 'fixed',
  zIndex: 4,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

type Props = Required<Pick<ReturnType<typeof useFaceDetection>, 'pointsToScan'>> & {
  scannedPoints: number[][];
  aspectRatio: number;
};

export const ScanningPoints = React.forwardRef<HTMLDivElement, Props>(
  ({ scannedPoints, pointsToScan, aspectRatio }: Props, ref) => {
    const renderPointsToScan = () => {
      const { points, centerX, centerY } = pointsToScan;

      return points.map((point) => {
        const { drawPoint } = point;
        const [x, y] = drawPoint;
        const isScanned = scannedPoints.includes(point.point);

        return (
          <MemorizedPoint
            key={`${point.point}`}
            x={x - centerX}
            y={y - centerY}
            aspectRatio={aspectRatio}
            isScanned={isScanned}
          />
        );
      });
    };

    return (
      <Box ref={ref} sx={{ ...scanningPointsSx }}>
        {renderPointsToScan()}
      </Box>
    );
  },
);

export const MemorizedScanningPoints = React.memo(ScanningPoints);
