import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  FetchEmployeeDetailsResponse,
  FetchEmployeeDetailsActionProps,
  EditEmployeeDetailsActionProps,
  EditEmployeeDetailsResponse,
  AddEmployeeResponse,
  AddEmployeeActionProps,
  FetchTagInheritedFeaturesResponse,
  DeleteEmployeesActionProps,
  DeleteEmployeesResponse,
  DeactivateEmployeesResponse,
  DeactivateEmployeesActionProps,
  InviteEmployeesResponse,
  InviteEmployeesActionProps,
  PrintQrCardsActionProps,
  PrintQrCardsResponse,
  EditTeamListViewSettingActionProps,
  FetchTagInheritedFeaturesActionProps,
  EditTeamListViewSettingResponse,
  SetWorkPositionsActionProps,
  SetWorkPositionsResponse,
  SetTagsActionProps,
  SetTagsResponse,
  SetRoleActionProps,
  SetRoleResponse,
  SetPermissionsActionProps,
  SetPermissionsResponse,
  SetPayDetailsActionProps,
  SetPayDetailsResponse,
  SetTimeOffLimitsActionProps,
  SetTimeOffLimitsResponse,
  ActivateEmployeesResponse,
  ActivateEmployeesActionProps,
  ClearTagsActionProps,
  ClearTagsResponse,
  ClearWorkPositionActionProps,
  ClearWorkPositionResponse,
} from './employeesActions.types';

export function fetchEmployeeDetailsAction({
  employeeId,
}: FetchEmployeeDetailsActionProps): Action<FetchEmployeeDetailsResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.employee}/${employeeId}`,
  };
}

export function editEmployeeDetailsAction({
  employeeId,
  employee,
  avatar,
}: EditEmployeeDetailsActionProps): Action<EditEmployeeDetailsResponse> {
  const formData = new FormData();
  if (avatar) {
    formData.append('avatar', avatar);
  }

  formData.append('request', JSON.stringify(employee));
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.employeeManagement}/${employeeId}`,
    body: formData,
  };
}

export function addEmployeeAction({ avatar, ...rest }: AddEmployeeActionProps): Action<AddEmployeeResponse> {
  const formData = new FormData();
  if (avatar) {
    formData.append('avatar', avatar);
  }

  formData.append('request', JSON.stringify(rest));

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeManagement,
    body: formData,
  };
}

export function deleteEmployeesAction(body: DeleteEmployeesActionProps): Action<DeleteEmployeesResponse> {
  return {
    method: 'DELETE',
    endpoint: API_ENDPOINTS.employeeManagement,
    body,
  };
}

export function activateEmployeesAction(body: ActivateEmployeesActionProps): Action<ActivateEmployeesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeActivate,
    body,
  };
}

export function deactivateEmployeesAction(body: DeactivateEmployeesActionProps): Action<DeactivateEmployeesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeDeactivate,
    body,
  };
}

export function inviteEmployeesAction(body: InviteEmployeesActionProps): Action<InviteEmployeesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeInvite,
    body,
  };
}

export function printQrCardsAction(body: PrintQrCardsActionProps): Action<PrintQrCardsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.printQr,
    body,
    config: {
      customFetchTimeout: 5000,
    },
  };
}

export function fetchTagInheritedFeaturesAction(
  body: FetchTagInheritedFeaturesActionProps,
): Action<FetchTagInheritedFeaturesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.tagInheritedFeatures,
    body,
  };
}

export function editTeamListViewSettingAction(
  viewSettings: EditTeamListViewSettingActionProps,
): Action<EditTeamListViewSettingResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.viewsSettings,
    body: {
      team: viewSettings,
    },
  };
}

//
// BULK_ACTIONS
//

export function setWorkPositionsAction(body: SetWorkPositionsActionProps): Action<SetWorkPositionsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setWorkPosition,
    body,
  };
}

export function clearWorkPositionAction(body: ClearWorkPositionActionProps): Action<ClearWorkPositionResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.clearWorkPositions,
    body,
  };
}

export function setTagsAction(body: SetTagsActionProps): Action<SetTagsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setTags,
    body,
  };
}

export function clearTagsAction(body: ClearTagsActionProps): Action<ClearTagsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.clearTags,
    body,
  };
}

export function setRoleAction(body: SetRoleActionProps): Action<SetRoleResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setRole,
    body,
  };
}

export function setPermissionsAction(body: SetPermissionsActionProps): Action<SetPermissionsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setPermissions,
    body,
  };
}

export function setPayDetailsAction(body: SetPayDetailsActionProps): Action<SetPayDetailsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setPayDetails,
    body,
  };
}

export function setTimeOffLimitsAction(body: SetTimeOffLimitsActionProps): Action<SetTimeOffLimitsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setTimeOffLimits,
    body,
  };
}
