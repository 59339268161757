import { useRecoilValue } from 'recoil';

import {
  geoLocationPositionAtom,
  geoLocationProviderAtom,
  GeoLocationProviderState,
} from 'Kiosk/state/geoLocationState';

type ReturnValue = GeoLocationProviderState & {
  location?: GeolocationPosition;
};

export const useGeoLocation = (): ReturnValue => {
  const location = useRecoilValue(geoLocationPositionAtom);
  const state = useRecoilValue(geoLocationProviderAtom);

  return {
    location,
    ...state,
  };
};
