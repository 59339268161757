import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { CheckboxLogicWrapper, CheckboxLogicWrapperProps } from 'components/utils/CheckboxLogicWrapper';

type Props = Omit<CheckboxLogicWrapperProps, 'children'> & {
  prependSwitchWith?: React.ReactNode;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  prependSwitchWith: undefined,
  sx: undefined,
};

export const Switch = React.forwardRef<HTMLInputElement, Props>(
  ({ size = 'default', placement = 'right', prependSwitchWith, wrapperSx, sx, ...props }: Props, ref) => (
    <CheckboxLogicWrapper
      {...props}
      ref={ref}
      size={size}
      placement={placement || 'right'}
      wrapperSx={{
        fontWeight: '700',
        '&:hover': {
          '> .switch': {
            bg: 'switch.background.hover',
            '> span': {
              boxShadow: 'switchIndicator',
            },
          },
        },
        ...(wrapperSx && wrapperSx),
        ...(sx && sx),
      }}
    >
      <Box as="span" className="switch" variant={`forms.switch.${size}`}>
        <Box as="span" />
      </Box>
      {prependSwitchWith && prependSwitchWith}
    </CheckboxLogicWrapper>
  ),
);

Switch.defaultProps = defaultProps;
