import React from 'react';
import { Flex } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { useTheme } from 'styles/useTheme';
import { LinkButtonProps, Button, ButtonProps, LinkButton } from 'components/ui/Buttons';
import { Dot } from 'components/Dot/Dot';
import { DropdownLinks } from 'components/Dropdown/types';
import { usePathnameMatch } from 'hooks/usePathnameMatch/usePathnameMatch';
import { PopperState } from 'components/ui/PopperProvider/PopperProvider';

type Props = Omit<
  LinkButtonProps & ButtonProps,
  'apendWith' | 'prependWith' | 'onClick' | 'size' | 'bgOverwrite' | 'to'
> & {
  icon: Icons;
  to?: string;
  dropdownLinks?: DropdownLinks;
  withDot?: boolean;
  popperState?: PopperState;
};

const defaultProps: Partial<Props> = {
  withDot: false,
  to: undefined,
  dropdownLinks: undefined,
  popperState: undefined,
};

export const MenuButton = React.forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>(
  ({ to, icon, children, dropdownLinks, withDot, popperState, ...props }: Props, ref) => {
    const isActive = usePathnameMatch(dropdownLinks ? dropdownLinks.map((link) => link.to) : [to]);

    const { theme } = useTheme();
    const commonProps: Partial<LinkButtonProps & ButtonProps> = {
      bgOverwrite: {
        default: theme.colors.navbar.menuButton.background.default,
        hover: theme.colors.navbar.menuButton.background.hover,
        tap: theme.colors.navbar.menuButton.background.tap,
        disabled: theme.colors.navbar.menuButton.background.disabled,
      },
      prependWith: <Icon type={icon} size={24} data-icon />,
      ...(!to &&
        dropdownLinks && {
          apendWith: (
            <Icon
              type={popperState?.isVisible ? 'chevronUp' : 'chevronDown'}
              size={15}
              fill={theme.colors.texts.lighter}
              data-chevron
            />
          ),
        }),
      ...props,
      sx: {
        borderRadius: ['sm', null, null, 'sm'],
        px: 2,
        py: [1, null, null, 2],
        gap: 1,
        userSelect: 'none',
        ...(popperState?.isVisible && {
          backgroundColor: `${theme.colors.navbar.menuButton.background.tap} !important`,
        }),
        ...(isActive && { color: 'navbar.menuButton.color.active' }),
        '@media screen and (max-width: 991px)': {
          fontSize: '10px',
          fontWeight: 'normal',
          flexDirection: 'column',
          minWidth: '50px',
          gap: 0,
          '> span': {
            '&[data-chevron]': {
              display: 'none',
            },
            '&[data-icon] > svg': {
              width: '28px',
              height: '28px',
            },
          },
        },
        ...(props.sx && !!to && props.sx),
      },
    };
    return (
      <ConditionalWrapper
        condition={!!dropdownLinks || !!withDot}
        wrapper={({ children: wrapperChildren }) => (
          <Flex sx={{ position: 'relative', ...(props.sx && props.sx) }}>
            {wrapperChildren}
            {withDot && <Dot />}
          </Flex>
        )}
      >
        {!to && dropdownLinks ? (
          <Button ref={ref} {...commonProps}>
            {children}
          </Button>
        ) : (
          <>
            {to && (
              <LinkButton ref={ref} {...commonProps} to={to}>
                {children}
              </LinkButton>
            )}
          </>
        )}
      </ConditionalWrapper>
    );
  },
);

MenuButton.defaultProps = defaultProps;
