import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import { advancedSettingsAction } from 'api/actions/settings/settingsActions';
import {
  AdvancedSettingsActionProps,
  FetchTimeTrackingSettingsResponse,
  WorktimeWithoutSchedule,
} from 'api/actions/settings/settingsActions.types';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { createEvent } from 'utils/createEvent';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { InputOption, Select } from 'components/ui/Select/Select';
import { Divider } from 'components/Divider/Divider';
import { Switch } from 'components/ui/Switch';
import { TimePicker } from 'components/ui/TimePicker/TimePicker';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';
import { mergeRefs } from 'utils/mergeRefs';
import { addSnackbar } from 'SnackbarHub/actions';

type Props = {
  payload: AdvancedSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  advancedShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  advancedFormPendingRef: React.MutableRefObject<boolean>;
  advancedStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

type keys = keyof AdvancedSettingsActionProps;

export const Advanced = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      advancedShouldBlockTransitionRef,
      advancedFormPendingRef,
      advancedStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchTimeTrackingSettingsResponse>(payloadSelectorFamily('TIME_TRACKING'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const { mutate } = useMutation(advancedSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<AdvancedSettingsActionProps>(payload);

    const {
      setError,
      watch,
      register,
      handleSubmit,
      reset: resetForm,
      formState: { errors },
    } = useForm({ defaultValues: payload, mode: 'onTouched', reValidateMode: 'onChange' });

    const countNightHoursWatch = watch('countNightHours');

    const dispatchSubmitEvent = () => {
      const submitEvent = createEvent('submit');
      advancedFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    };

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnChange = useCallback(() => {
      advancedShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [advancedShouldBlockTransitionRef, blockLocationPathnameRef, reset, start]);

    const handleOnBlur = useCallback(() => {
      advancedShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [advancedShouldBlockTransitionRef, blockLocationPathnameRef, reset, start]);

    const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          handleOnChange();
        },
      };
    };

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      const { typeOfWorktimeWithoutSchedule, countNightHours, nightHoursDurationSeconds, nightHoursStartSeconds } =
        payload;

      resetForm({ typeOfWorktimeWithoutSchedule, countNightHours, nightHoursDurationSeconds, nightHoursStartSeconds });
      advancedShouldBlockTransitionRef.current = false;
      advancedFormPendingRef.current = false;
    }, [advancedFormPendingRef, advancedShouldBlockTransitionRef, payload, resetForm]);

    const handleNightHoursValidation = useCallback(
      (data: AdvancedSettingsActionProps) => {
        const nightHoursDurationSecondsValue = data.nightHoursDurationSeconds;
        const countNightHoursValue = data.countNightHours;

        if (countNightHoursValue && _.isNaN(nightHoursDurationSecondsValue)) {
          setError('nightHoursDurationSeconds', {
            type: 'required',
            message: t({ id: 'settings.forms.field_empty', message: 'Field cannot be empty' }),
          });

          return false;
        }

        if (nightHoursDurationSecondsValue < 60) {
          setError('nightHoursDurationSeconds', {
            type: 'required',
            message: t({ id: 'settings.forms.time_one_minute', message: 'Time cannot be lower than 1 minute' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleSubmitCallback = useCallback(
      async (data: AdvancedSettingsActionProps) => {
        const validation = handleNightHoursValidation(data);
        const advancedResetObject = { name: 'ADVANCED_CALLBACK', callback: handleFormReset };
        reset();

        if (validation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === advancedResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(data, payload) && validation) {
          const { error } = await mutate(data);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...data }));
            mutationDataRef.current = data;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [advancedResetObject] : [...prevState, advancedResetObject],
            );
            return;
          }
        }

        if (!validation) {
          setResetCallbacks((prevState) => (!prevState ? [advancedResetObject] : [...prevState, advancedResetObject]));
          return;
        }

        advancedShouldBlockTransitionRef.current = false;
        advancedFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        advancedFormPendingRef,
        advancedShouldBlockTransitionRef,
        handleFormReset,
        handleNightHoursValidation,
        mutate,
        payload,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payload)) handleFormReset();
    }, [handleFormReset, payload]);

    useEffect(() => {
      advancedStatusRef.current = status;
    }, [advancedStatusRef, status]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    const worktimeWithoutScheduleOptions: InputOption[] = useMemo(
      () => [
        {
          label: t({ id: 'settings.select.work_time', message: 'Work time' }),
          id: WorktimeWithoutSchedule.CountAsWorkTime.toString(),
        },
        {
          label: t({ id: 'settings.select.overtime', message: 'Overtime' }),
          id: WorktimeWithoutSchedule.CountAsOvertime.toString(),
        },
        {
          label: t({ id: 'settings.select.count_separately', message: 'Count separately' }),
          id: WorktimeWithoutSchedule.CountSeparately.toString(),
        },
        {
          label: t({ id: 'settings.select.dont_count', message: "Don't count" }),
          id: WorktimeWithoutSchedule.DontCount.toString(),
        },
      ],
      [],
    );

    return (
      <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <HeadingError
            label={t({
              id: 'time_tracking_settings.heading.advanced',
              message: 'Advanced',
            })}
            errorMessage={errors.nightHoursDurationSeconds?.message}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <OptionLabel
              label={t({
                id: 'time_tracking_settings.type_of_worktime_without_schedule.label',
                message: 'Count work time without schedule as',
              })}
              additionalLabel={
                <>
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    <Trans id="time_tracking_settings.requires_schedules_and_requests" />
                  </Text>
                  .
                </>
              }
              apendWith={
                <Select
                  {...registerOnBlur('typeOfWorktimeWithoutSchedule', {
                    required: true,
                    valueAsNumber: true,
                  })}
                  id="typeOfWorktimeWithoutSchedule"
                  placeholder={t({
                    id: 'time_tracking_settings.type_of_worktime_without_schedule.label',
                    message: 'Count work time without schedule as',
                  })}
                  options={worktimeWithoutScheduleOptions}
                  size="sm"
                  error={!!errors.typeOfWorktimeWithoutSchedule}
                  sx={{ maxWidth: '188px' }}
                />
              }
              withDivider
            />
            <Switch
              {...registerOnChange('countNightHours')}
              label={t({
                id: 'time_tracking_settings.count_night_hours.label',
                message: 'Count night hours',
              })}
              additionalInfo={t({
                id: 'time_tracking_settings.count_night_hours.additional_info',
                message: 'All reports will have additional column with the sum of night hours for each employee.',
              })}
              size="sm"
            />
            {countNightHoursWatch && (
              <Flex>
                <Text sx={{ fontSize: 2, fontWeight: 'bold', alignSelf: 'center', mr: 3 }}>
                  <Trans id="time_tracking_settings.texts.night_hours">Night hours begin at</Trans>
                </Text>
                <TimePicker
                  {...registerOnBlur('nightHoursStartSeconds', {
                    valueAsNumber: true,
                  })}
                  id="nightHoursStartSeconds"
                  size="sm"
                />
                <Text sx={{ fontSize: 2, fontWeight: 'bold', alignSelf: 'center', mx: 3 }}>
                  <Trans id="time_tracking_settings.texts.last_for">and last for</Trans>
                </Text>
                <DurationPicker
                  {...registerOnBlur('nightHoursDurationSeconds', { valueAsNumber: true })}
                  id="nightHoursDurationSeconds"
                  size="sm"
                  hours
                  minutes
                  seconds={false}
                  error={!!errors.nightHoursDurationSeconds}
                />
              </Flex>
            )}
            <Divider />
          </Flex>
        </Flex>
      </form>
    );
  },
);

export const MemoizedAdvanced = React.memo(Advanced);
