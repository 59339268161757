import { t, Trans } from '@lingui/macro';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Input, Link, Text, Flex } from 'theme-ui';
import { RegisterOptions, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useMutation } from 'react-fetching-library';
import { useSetRecoilState } from 'recoil';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import { Divider } from 'components/Divider/Divider';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import {
  FetchTimeTrackingSettingsResponse,
  MagnetInterval,
  TimeClocksSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { createEvent } from 'utils/createEvent';
import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { timeClocksSettingsAction } from 'api/actions/settings/settingsActions';
import { wrapperLinkSx } from '../../styles/wrappers';
import { useTheme } from 'styles/useTheme';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { mergeRefs } from 'utils/mergeRefs';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';
import { addSnackbar } from 'SnackbarHub/actions';

type Props = {
  payload: TimeClocksSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  timeClocksShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  timeClocksFormPendingRef: React.MutableRefObject<boolean>;
  timeClocksStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

type keys = keyof TimeClocksSettingsActionProps;

const MIN_MINUTES = 1 * 60;
const MAX_MINUTES = 480 * 60;

export const TimeClocks = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      timeClocksShouldBlockTransitionRef,
      timeClocksFormPendingRef,
      timeClocksStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchTimeTrackingSettingsResponse>(payloadSelectorFamily('TIME_TRACKING'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const { theme } = useTheme();
    const { mutate } = useMutation(timeClocksSettingsAction);
    const faceRecognitionLink = useHelpLink({ inEwi: { pl: '/', en: '/' }, tracktime24: { en: '/' } });
    const enableOfflineModeLink = useHelpLink({
      inEwi: {
        pl: '/article/jakie-wymagania-powinno-spelniac-urzadzenie-do-rejestracji-czasu-pracy-1roedlt/',
        en: '/article/inewi-rcp-requirements-1e3ujo0/',
      },
      tracktime24: { en: '/article/tracktime24-mobile-time-clock-app-requirements-19z7gfm/' },
    });

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<TimeClocksSettingsActionProps>(payload);

    const payloadComparisonObject: TimeClocksSettingsActionProps = useMemo(
      () => ({ ...payload, paidBreakLimitSeconds: payload?.paidBreakLimitSeconds }),
      [payload],
    );

    const {
      setError,
      register,
      watch,
      handleSubmit,
      reset: resetForm,
      formState: { errors },
    } = useForm({ defaultValues: payload, mode: 'onTouched', reValidateMode: 'onChange' });

    const showPaidBreakWatch = watch('showPaidBreak');
    const limitPaidBreakWatch = watch('limitPaidBreak');
    const allowTimeRoundingWatch = watch('allowTimeRounding');
    const roundToMinutesWatch = watch('roundToMinutes');

    const dispatchSubmitEvent = useCallback(() => {
      const submitEvent = createEvent('submit');
      timeClocksFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    }, [timeClocksFormPendingRef]);

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnChange = () => {
      timeClocksShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    };

    const handleOnBlur = useCallback(() => {
      timeClocksShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, reset, start, timeClocksShouldBlockTransitionRef]);

    const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          handleOnChange();
        },
      };
    };

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handlePaidBreakValidation = useCallback(
      (data: TimeClocksSettingsActionProps) => {
        const showPaidBreakValue = data.showPaidBreak;
        const limitPaidBreakValue = data.limitPaidBreak;
        const paidBreakLimitSecondsValue = data.paidBreakLimitSeconds;

        if (showPaidBreakValue && limitPaidBreakValue && _.isNaN(paidBreakLimitSecondsValue)) {
          setError('paidBreakLimitSeconds', {
            type: 'required',
            message: t({ id: 'settings.forms.field_empty' }),
          });

          return false;
        }

        if (paidBreakLimitSecondsValue && paidBreakLimitSecondsValue < MIN_MINUTES) {
          setError('paidBreakLimitSeconds', {
            type: 'validate',
            message: t({ id: 'settings.forms.time_one_minute' }),
          });

          return false;
        }

        if (paidBreakLimitSecondsValue && paidBreakLimitSecondsValue > MAX_MINUTES) {
          setError('paidBreakLimitSeconds', {
            type: 'validate',
            message: t({ id: 'settings.forms.time_maximum' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleRoundToMinutesValidation = useCallback(
      (data: TimeClocksSettingsActionProps) => {
        const roundToMinutesValue = data.roundToMinutes;
        const allowTimeRoundingValue = data.allowTimeRounding;

        if (allowTimeRoundingValue && !roundToMinutesValue) {
          setError('roundToMinutes', {
            type: 'required',
            message: t({ id: 'settings.forms.field_empty' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleFormReset = useCallback(() => {
      resetForm({
        ...payload,
      });
      timeClocksShouldBlockTransitionRef.current = false;
      timeClocksFormPendingRef.current = false;
    }, [payload, resetForm, timeClocksFormPendingRef, timeClocksShouldBlockTransitionRef]);

    const handleSubmitCallback = useCallback(
      async (data: TimeClocksSettingsActionProps) => {
        const validation = handlePaidBreakValidation(data);
        const roundValidation = handleRoundToMinutesValidation(data);
        const timeClocksResetObject = { name: 'TIME_CLOCKS_CALLBACK', callback: handleFormReset };
        const dataComparisonObject: TimeClocksSettingsActionProps = {
          ...data,
          paidBreakLimitSeconds: data.limitPaidBreak ? data.paidBreakLimitSeconds : undefined,
        };
        reset();

        if (validation && roundValidation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === timeClocksResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(dataComparisonObject, payloadComparisonObject) && validation && roundValidation) {
          const { error } = await mutate(data);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...dataComparisonObject }));
            mutationDataRef.current = dataComparisonObject;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [timeClocksResetObject] : [...prevState, timeClocksResetObject],
            );
            return;
          }
        }

        if (!validation || !roundValidation) {
          setResetCallbacks((prevState) =>
            !prevState ? [timeClocksResetObject] : [...prevState, timeClocksResetObject],
          );
          return;
        }

        timeClocksShouldBlockTransitionRef.current = false;
        timeClocksFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        handleFormReset,
        handlePaidBreakValidation,
        handleRoundToMinutesValidation,
        mutate,
        payloadComparisonObject,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
        timeClocksFormPendingRef,
        timeClocksShouldBlockTransitionRef,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payloadComparisonObject)) {
        handleFormReset();
      }
    }, [handleFormReset, payloadComparisonObject]);

    useEffect(() => {
      timeClocksStatusRef.current = status;
    }, [status, timeClocksStatusRef]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    return (
      <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <HeadingError
            label={t({
              id: 'time_tracking_settings.heading.time_clocks',
              message: 'Time Clocks',
            })}
            errorMessage={errors.paidBreakLimitSeconds?.message || errors.roundToMinutes?.message}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <Switch
              {...registerOnChange('showPaidBreak')}
              label={t({
                id: 'time_tracking_settings.show_paid_break.label',
                message: 'Show Paid Break',
              })}
              additionalInfo={
                <Text>
                  <Trans id="time_tracking_settings.show_paid_break.additionalInfo">
                    <Text as="span" sx={{ textDecoration: 'underline' }}>
                      DOESN'T
                    </Text>
                    pause work timer.
                  </Trans>
                </Text>
              }
              size="sm"
            />
            {showPaidBreakWatch && (
              <>
                <Switch
                  {...registerOnChange('limitPaidBreak')}
                  label={t({
                    id: 'time_tracking_settings.limit_paid_break.label',
                    message: 'Limit the maximum allowed duration of Paid Break',
                  })}
                  size="sm"
                  bold
                />
                {limitPaidBreakWatch && (
                  <OptionLabel
                    label={t({
                      id: 'time_tracking_settings.paid_break_limit_minutes.label',
                      message: 'Maximum allowed duration of Paid Break',
                    })}
                    apendWith={
                      <DurationPicker
                        {...registerOnBlur('paidBreakLimitSeconds', { valueAsNumber: true })}
                        id="paidBreakLimitSeconds"
                        size="sm"
                        minutes
                        hours
                        seconds={false}
                        error={!!errors.paidBreakLimitSeconds}
                      />
                    }
                  />
                )}
              </>
            )}
            <Divider />
            <Switch
              {...registerOnChange('showUnPaidBreak')}
              label={t({
                id: 'time_tracking_settings.show_un_paid_break.label',
                message: 'Show Unpaid Break',
              })}
              additionalInfo={t({
                id: 'time_tracking_settings.show_un_paid_break.additionalInfo',
                message: 'Pauses work timer.',
              })}
              size="sm"
              withDivider
            />
            <Switch
              {...registerOnChange('enableFaceVerification')}
              label={t({
                id: 'time_tracking_settings.enable_face_verification.label',
                message: 'Face Recognition and Fraud Detection',
              })}
              additionalInfo={
                <Text>
                  <Trans id="settings.currently_in_preview" />
                  <span> </span>
                  <Link href={faceRecognitionLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                    <Trans id="settings.learn_more" />
                  </Link>
                  .
                </Text>
              }
              size="sm"
              withDivider
            />
            <Switch
              {...registerOnChange('restrictLocations')}
              label={t({
                id: 'time_tracking_settings.restrict_locations.label',
                message: 'Limit only to allowed locations',
              })}
              additionalInfo={t({
                id: 'time_tracking_settings.restrict_locations.additional_info',
                message: 'All clock-ins and outs not from the allowed locations will be rejected.',
              })}
              size="sm"
              withDivider
            />
            <Switch
              {...registerOnChange('enableOfflineMode')}
              label={t({
                id: 'time_tracking_settings.enable_offline_mode.label',
                message: 'Allow offline mode on TimeClock Mobile App',
              })}
              additionalInfo={
                <Text>
                  <Trans id="time_tracking_settings.enable_offline_mode.additional_info">
                    Clock-ins from offline mode will be allowed.
                  </Trans>
                  <span> </span>
                  <Link
                    href={enableOfflineModeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ ...wrapperLinkSx }}
                  >
                    <Trans id="settings.learn_more" />
                  </Link>
                  .
                </Text>
              }
              size="sm"
              withDivider
            />
            <Switch
              {...registerOnChange('sendEventPhotos')}
              label={t({
                id: 'time_tracking_settings.send_event_photos.label',
                message: 'Save photos of all clock-ins and outs',
              })}
              additionalInfo={t({
                id: 'time_tracking_settings.send_event_photos.additional_info',
                message: 'All clock-ins in clock log will have a photo from the moment of the event.',
              })}
              size="sm"
              withDivider
            />
            <Switch
              {...registerOnChange('allowTimeRounding')}
              label={t({
                id: 'time_tracking_settings.allow_time_rounding.label',
                message: 'Allow time rounding',
              })}
              size="sm"
              bold
            />
            {allowTimeRoundingWatch && (
              <OptionLabel
                label={t({
                  id: 'time_tracking_settings.round_to_minutes.label',
                  message: 'Round up or down to',
                })}
                additionalLabel={t({
                  id: 'time_tracking_settings.round_to_minutes.additional_label',
                  message: 'Clock-in on 9:50 will be rounded to 10:00 and 10:05 to 10:00.',
                })}
                secondAdditionalLabel={t({
                  id: 'time_tracking_settings.round_to_minutes.second_additional_label',
                  message: 'Clock-Out on 18:10 will be rounded to 18:00, and 18:20 to 18:15.',
                })}
                apendWith={
                  <Select
                    {...registerOnBlur('roundToMinutes', {
                      valueAsNumber: true,
                    })}
                    id="roundToMinutes"
                    placeholder={t({
                      id: 'time_tracking_settings.round_to_minutes.label',
                    })}
                    size="sm"
                    options={[
                      {
                        label: '5 min',
                        id: MagnetInterval.Minutes5.toString(),
                      },
                      {
                        label: '10 min',
                        id: MagnetInterval.Minutes10.toString(),
                      },
                      {
                        label: '15 min',
                        id: MagnetInterval.Minutes15.toString(),
                      },
                      {
                        label: '30 min',
                        id: MagnetInterval.Minutes30.toString(),
                      },
                      {
                        label: '60 min',
                        id: MagnetInterval.Minutes60.toString(),
                      },
                    ]}
                    error={!!errors.roundToMinutes}
                    sx={{ minWidth: '125px' }}
                    customContent={
                      <>
                        <Input
                          name="minutes"
                          value={roundToMinutesWatch}
                          sx={{
                            ...theme.forms.timePicker.twoDigitInput.sm,
                            pointerEvents: 'none',
                          }}
                          readOnly
                        />
                        <Text sx={{ ml: 1, mr: 2 }}>min</Text>
                      </>
                    }
                  />
                }
              />
            )}
            <Divider />
          </Flex>
        </Flex>
      </form>
    );
  },
);

export const MemoizedTimeClocks = React.memo(TimeClocks);
