import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { languageSelector } from 'state/recoilState';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { TO } from 'constants/routes';

export const SignUpRoute: React.ElementType = (props: RouteProps) => {
  const language = useRecoilValue(languageSelector);
  const { isSignUpInProgress } = useAuthState();

  if (isSignUpInProgress) {
    return <Route {...props} />;
  }

  return <Redirect to={TO.SIGN_UP[language]} />;
};
