import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { cameraImageBufferAtom, CameraImageBufferState } from 'Kiosk/state/cameraImageBufferState';

type ReturnValue = CameraImageBufferState;

export const useCameraImageBuffer = (): ReturnValue => {
  const snapshotsBlobUrlsRef = useRef<string[]>([]);

  const getSnapshotsBlobUrlsCall = useCallback(() => snapshotsBlobUrlsRef.current, []);

  const { source, isCameraStreaming } = useRecoilValue(cameraStateAtom);
  const [
    {
      imageBufferCanvasCallback,
      imageBufferReady,
      getImageData: getImageDataFromAtom,
      getImageBlob: getImageBlobFromAtom,
      getBlob,
    },
    setCameraImageBufferState,
  ] = useRecoilState(cameraImageBufferAtom);

  const webpSupport = useMemo(
    () => imageBufferCanvasCallback()?.current?.toDataURL('image/webp').indexOf('data:image/webp') === 0,
    [imageBufferCanvasCallback],
  );

  const getCanvasImage = useCallback(async () => {
    if (imageBufferReady && imageBufferCanvasCallback && isCameraStreaming && source && source.video) {
      const canvas = imageBufferCanvasCallback();

      if (canvas && canvas.current) {
        const ctx = canvas.current.getContext('2d');

        if (ctx) {
          ctx.drawImage(source.video, 0, 0, ctx.canvas.width, ctx.canvas.height);
          const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);

          return new Promise<{ imageData: ImageData; imageBlob: Blob }>((resolve) => {
            if (canvas && canvas.current) {
              canvas.current.toBlob(
                async (snap) => {
                  const url = URL.createObjectURL(snap);
                  const snapshots = snapshotsBlobUrlsRef.current;

                  if (snapshots.length >= 30) {
                    URL.revokeObjectURL(snapshots[0]);
                    snapshots.shift();
                  }

                  snapshots.push(url);
                  if (snap) {
                    resolve({
                      imageData,
                      imageBlob: snap,
                    });
                  }
                },
                webpSupport ? 'image/webp' : 'image/jpeg',
                webpSupport ? 0.5 : 0.7,
              );
            }
          });
        }
      }
    }

    return undefined;
  }, [imageBufferCanvasCallback, imageBufferReady, isCameraStreaming, source, webpSupport]);

  const getImageData = useCallback(async () => {
    const canvasImage = await getCanvasImage();
    if (canvasImage) {
      const { imageData } = canvasImage;

      return imageData;
    }

    return undefined;
  }, [getCanvasImage]);

  const getImageBlob = useCallback(async () => {
    const canvasImage = await getCanvasImage();
    if (canvasImage) {
      const { imageBlob } = canvasImage;

      return imageBlob;
    }

    return undefined;
  }, [getCanvasImage]);

  useEffect(() => {
    setCameraImageBufferState((prevState) => ({
      ...prevState,
      getBlob: getSnapshotsBlobUrlsCall,
      getImageData,
      getImageBlob,
    }));
  }, [getCanvasImage, getImageBlob, getImageData, getSnapshotsBlobUrlsCall, setCameraImageBufferState]);

  return {
    imageBufferReady,
    imageBufferCanvasCallback,
    getBlob,
    getImageData: getImageDataFromAtom,
    getImageBlob: getImageBlobFromAtom,
  };
};
