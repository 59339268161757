import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Flex } from 'theme-ui';
import dayjs from 'dayjs';
import { range } from 'lodash';

import { Icon } from 'components/Icon/Icon';
import { Select } from 'components/ui/Select/Select';
import { Button } from 'components/ui/Buttons/Button';
import { setNativeValue } from 'utils/setNativeValue';
import { dateTime } from 'utils/dateTime';

import { ControlsProps } from './types';

type Props = ControlsProps;

export const Controls = ({
  maxDate,
  minDate,
  currentMonth,
  currentYear,
  onMonthChange,
  onYearChange,
  onNext,
  onPrev,
  hideArrow,
  variant,
}: Props): React.ReactElement => {
  const currentDate = useMemo(() => dateTime([currentYear, currentMonth]), [currentMonth, currentYear]);
  const minYear = useMemo(() => minDate.year(), [minDate]);
  const yearLength = useMemo(() => maxDate.year() - minYear, [maxDate, minYear]);

  const monthSelectRef = useRef<HTMLInputElement | null>(null);
  const yearSelectRef = useRef<HTMLInputElement | null>(null);
  const getMonths = useMemo(
    () =>
      dayjs.months().flatMap((month, index) => {
        if (
          dayjs([currentYear, index]).isSameOrBefore(maxDate, 'month') &&
          dayjs([currentYear, index]).isSameOrAfter(minDate, 'month')
        ) {
          return { id: `${index}`, label: month };
        }
        return [];
      }),
    [currentYear, maxDate, minDate],
  );

  const getYears = useMemo(
    () =>
      range(yearLength + 1).map((year) => ({
        id: `${minYear + year}`,
        label: `${minYear + year}`,
      })),
    [minYear, yearLength],
  );

  useEffect(() => {
    if (monthSelectRef.current && monthSelectRef.current.value !== `${currentMonth}`) {
      setNativeValue(monthSelectRef, `${currentMonth}`);
    }

    if (yearSelectRef.current && yearSelectRef.current.value !== `${currentYear}`) {
      setNativeValue(yearSelectRef, `${currentYear}`);
    }
  }, [currentMonth, currentYear]);

  const onMonthChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const month = e.target.value;
      const monthNumber = parseInt(month, 10);
      if (monthNumber !== currentMonth) {
        onMonthChange(monthNumber);
      }
    },
    [currentMonth, onMonthChange],
  );

  const onYearChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const year = e.target.value;
      const yearNumber = parseInt(year, 10);
      if (yearNumber !== currentYear) {
        onYearChange(yearNumber);
      }
    },
    [currentYear, onYearChange],
  );

  return (
    <Flex as="header" variant={`forms.calendar.${variant}.controls.container`}>
      <Button
        shape="rounded"
        type="button"
        size="sm"
        variant="minimal"
        prependWith={<Icon type="arrowLeft" />}
        onClick={onPrev}
        disabled={currentDate.isSame(minDate, 'month')}
        sx={{ variant: `forms.calendar.${variant}.controls.arrowButton` }}
        data-is-hidden={hideArrow === 'left'}
      />

      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Select
          ref={monthSelectRef}
          size="sm"
          onChange={onMonthChangeCallback}
          id="month"
          placeholder={`${getMonths[currentMonth]?.label}`}
          options={getMonths}
          sxOverwrite={{
            variant: `forms.calendar.${variant}.controls.select`,
          }}
        />
        {/* TODO: Find a better way to fix size of years input */}
        <Flex sx={{ width: variant === 'dropdown' ? '130px' : '100px' }}>
          <Select
            ref={yearSelectRef}
            size="sm"
            onChange={onYearChangeCallback}
            id="year"
            placeholder={`${currentYear}`}
            options={getYears}
            sxOverwrite={{
              variant: `forms.calendar.${variant}.controls.select`,
            }}
          />
        </Flex>
      </Flex>
      <Button
        shape="rounded"
        type="button"
        size="sm"
        variant="minimal"
        prependWith={<Icon type="arrowRight" />}
        onClick={onNext}
        disabled={currentDate.isSame(maxDate, 'month')}
        sx={{ variant: `forms.calendar.${variant}.controls.arrowButton` }}
        data-is-hidden={hideArrow === 'right'}
      />
    </Flex>
  );
};
