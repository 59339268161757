import { t } from '@lingui/macro';
import _ from 'lodash';
import { atom, atomFamily, DefaultValue, RecoilState, selector, selectorFamily } from 'recoil';

import {
  RequestType,
  TimeEventType,
  TimeOffType,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import {
  BusinessTravelsData,
  CommonEditRemoveRequestData,
  CustomRequestsData,
  DateTimeDetails,
  EventsData,
  FetchRequestDetailsResponse,
  FetchRequestsOverviewForTimeRangeResponse,
  Request,
  RequestFormType,
  RequestGetDataToModifyResponse,
  RequestOverviewElement,
  RequestOverviewElementRequest,
  RequestState,
  TimeOffsData,
} from 'api/actions/requests/requestsActions.types';
import { InputOption } from 'components/ui/Select/Select';
import { ENTRY_TIME_EVENT_ID, EXIT_TIME_EVENT_ID } from 'Kiosk/constants/constants';
import {
  DateRangeFilters,
  FilterGroupNames,
  TagsFilters,
  TeammatesFilters,
} from 'layouts/AuthorizedApp/AsideFilters/types';
import {
  ADD_REQUEST_PICK_TEAMMATES_LIST,
  BUSINESS_HISTORY_LIST,
  CUSTOM_TIME_OFF_HISTORY_LIST,
  IS_END_STATUS,
  REQUESTS_LIST,
  TIME_EVENTS_HISTORY_LIST,
} from 'layouts/Requests/constans';
import { dateTime } from 'utils/dateTime';
import { guardRecoilDefaultValue } from 'utils/guardRecoilDefaultValue';
import { isOfType } from 'utils/isOfType';
import { getStringWithReducedWhiteSpaces } from 'utils/whiteSpaceReducer';

import { ParsedEmployee, parsedEmployeesSelector } from './employees';
import {
  dateRangeFilterAtom,
  FilterGroupState,
  filterGroupStateAtomFamily,
  GroupName,
  IsActiveFilterConfig,
  parsedSearchFilterValueSelector,
} from './filters';
import { selectedRowsIdsSelectorFamily } from './list';
import { organizationSessionAtom } from './organizationSession';
import { languageSelector } from './recoilState';

/* 
***********************************************

      ATOM FOR GETTING NEW REQUESTS

***********************************************
*/
export const getRequestsAtom = atom<(() => Promise<void>) | null>({
  key: 'getRequests',
  default: null,
});

/* 
***********************************************

      REQUEST ATOM, PARSED REQUEST OBJECTS

***********************************************
*/
export type ParsedRequest = Omit<Request, 'personId'> & {
  requestType?: RequestType | TimeOffType | TimeEventType;
  employee: Omit<
    ParsedEmployee,
    'employeeId' | 'note' | 'email' | 'phoneNumber' | 'workPosition' | 'defaultWorkPosition'
  >;
};

type ParsedRequestsMap = Map<Request['id'], ParsedRequest>;

export const requestsAtom = atom<ParsedRequestsMap>({
  key: 'requestsList',
  default: new Map<Request['id'], ParsedRequest>(),
});

export const getParsedRequestTypeSelector = selectorFamily<
  ParsedRequest['requestType'] | null,
  Request['typeId'] | string
>({
  key: 'getParsedRequestType',
  get:
    (typeId) =>
    ({ get }) => {
      const organizationSession = get(organizationSessionAtom);

      if (!organizationSession) {
        return null;
      }

      const { customRequestTypes, timeOffTypes, timeEventTypes } = organizationSession;

      const requestType = _.find(customRequestTypes, (type) => type.id === typeId);
      const timeOffType = _.find(timeOffTypes, (type) => type.id === typeId);
      const timeEventType = _.find(timeEventTypes, (type) => type.id === typeId);

      return requestType ?? timeOffType ?? timeEventType ?? null;
    },
});

export const getParsedRequestSelector = selectorFamily<ParsedRequest | null, Request>({
  key: 'getParsedRequestType',
  get:
    (request) =>
    ({ get }) => {
      const employees = get(parsedEmployeesSelector);

      if (!employees) {
        return null;
      }

      const employee = employees.get(request.personId);
      const requestType = get(getParsedRequestTypeSelector(request.typeId));

      if (!employee) {
        return null;
      }

      const { id, avatarUrl, name, isActive, role, roleId, tags, tagsIds, isHidden, invitationState } = employee;
      const { personId, ...rest } = request;

      const parsedRequest = {
        ...rest,
        employee: {
          id,
          avatarUrl,
          name,
          isActive,
          role,
          roleId,
          tags,
          tagsIds,
          isHidden,
          invitationState,
        },
        requestType: requestType || undefined,
      };

      return parsedRequest;
    },
});

export const parsedRequestsSelector = selector<Request[] | null>({
  key: 'parsedRequests',
  get: () => null,
  set: ({ get, set }, newRequests) => {
    const employees = get(parsedEmployeesSelector);

    if (!newRequests || newRequests instanceof DefaultValue || !employees) {
      return;
    }

    const parsedRequests: ParsedRequestsMap = new Map();

    newRequests.forEach((request) => {
      const parsedRequest = get(getParsedRequestSelector(request));

      if (parsedRequest) parsedRequests.set(request.id, parsedRequest);
    });

    set(requestsAtom, parsedRequests);
  },
});

/* 
***********************************************

      REQUEST LIST AND LIST FUNCTIONALITY

***********************************************
*/

export const requestStateDescriptionSelector = selectorFamily<string, RequestState>({
  key: 'requestStateDescription',
  get:
    (id) =>
    ({ get }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const language = get(languageSelector);

      const requestStateDescription = (() => {
        switch (id) {
          case RequestState.Accepted:
            return t({
              id: 'request.state.approved',
              message: 'Approved',
            });
          case RequestState.Rejected:
            return t({
              id: 'request.state.rejected',
              message: 'Rejected',
            });
          case RequestState.Deleted:
            return t({
              id: 'request.state.deleted',
              message: 'Deleted',
            });
          default:
            return t({
              id: 'request.state.pending',
              message: 'Pending',
            });
        }
      })();

      return requestStateDescription;
    },
});

// FILTERED REQUEST LIST
export const filteredRequestsSelector = selector<ParsedRequestsMap>({
  key: 'filteredRequests',
  get: ({ get }) => {
    const requests = get(requestsAtom);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);
    const teammatesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES));
    const rolesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.ROLES));
    const tagsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TAGS));
    const requestTypeFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.REQUEST_TYPES));
    const requestStateFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.REQUEST_STATE));
    const searchFilterState = get(parsedSearchFilterValueSelector);
    const dateRangeFilterDates = get(dateRangeFilterAtom);

    const filterBySearchInput = (request: ParsedRequest) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const { id, number, requestType, state, typeDescription } = request;
      const requestStateDescription = get(requestStateDescriptionSelector(state));

      const {
        name,
        role: { name: roleName },
        tags,
        id: employeeId,
      } = request.employee;

      const searchableEmployee = _.flatMapDeep([
        `${name.firstName} ${name.surname}`,
        roleName,
        _.map(tags, (tag) => tag?.name),
        employeeId,
        id,
        requestType &&
          `${t({
            id: requestType.name,
          })}  ${
            isOfType<RequestType>(requestType, 'abbreviation') &&
            t({
              id: requestType.abbreviation,
            })
          }`,
        number,
        requestStateDescription,
        typeDescription,
      ]);

      valid = _.every(
        searchFilterState.map((searchQuery) => {
          let subValid = false;

          _.forEach(searchableEmployee, (searchableValue) => {
            subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
          });

          return subValid;
        }),
      );

      return valid;
    };

    const filterByTags = (employee: ParsedRequest['employee']) => {
      let valid = false;

      if (tagsFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(tagsFilterState)) {
        tagsFilterState.forEach((tagId) => {
          valid = valid || !!employee.tagsIds.includes(tagId);

          if (tagId === TagsFilters.NO_TAGS && _.isEmpty(employee.tagsIds)) {
            valid = true;
          }
        });
      }

      return valid;
    };

    const filterByRole = (employee: ParsedRequest['employee']) => {
      let valid = false;

      if (rolesFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(rolesFilterState)) {
        rolesFilterState.forEach((roleId) => {
          valid = valid || !!(employee.roleId === roleId);
        });
      }

      return valid;
    };

    const filterByTeammates = (employee: ParsedRequest['employee']) => {
      let valid = false;

      if (teammatesFilterState === null) {
        valid = true;
        return valid;
      }
      if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
        valid = valid || !!employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
        valid = valid || !!employee.isHidden;
      }
      if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
        valid = valid || !employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.INVITED)) {
        valid = valid || !!employee.invitationState;
      }

      return valid;
    };

    const filterByState = (state: ParsedRequest['state'], isDeleted: ParsedRequest['isDeleted']) => {
      if (
        requestStateFilterState === null ||
        requestStateFilterState.includes(`${state}`) ||
        (requestStateFilterState.includes(RequestState.Deleted.toString()) && isDeleted)
      ) {
        return true;
      }

      return false;
    };

    const filterByType = (type: ParsedRequest['type']) => {
      if (requestTypeFilterState === null || requestTypeFilterState.includes(`${type}`)) {
        return true;
      }

      return false;
    };

    const filterByDateRange = (startDate: number | undefined, endDate: number | undefined) => {
      if (!startDate || !endDate || !dateRangeFilterDates) return false;

      const { startDateUnix, endDateUnix } = dateRangeFilterDates;

      if (
        (startDate >= startDateUnix && startDate <= endDateUnix) ||
        (endDate >= startDateUnix && endDate <= endDateUnix)
      ) {
        return true;
      }

      return false;
    };

    const filteredMap = new Map(requests);

    filteredMap.forEach((request, requestId) => {
      let valid = false;
      const { employee, type, state, isDeleted } = request;
      const dateUnix = request.dateTimeDetails?.dateUnix;
      const startUnix = request.dateTimeDetails.dateRange?.startUnix;
      const endUnix = request.dateTimeDetails.dateRange?.endUnix;

      valid =
        filterByTeammates(employee) &&
        filterByRole(employee) &&
        filterByTags(employee) &&
        filterBySearchInput(request) &&
        filterByType(type) &&
        filterByState(state, isDeleted) &&
        filterByDateRange(dateUnix || startUnix, dateUnix || endUnix);

      if (!valid) filteredMap.delete(requestId);
    });

    return filteredMap;
  },
});

export const selectedRequestsIdsSelector = selector<ParsedRequest['id'][]>({
  key: 'selectedRequestsIds',
  get: ({ get }) => get(selectedRowsIdsSelectorFamily(REQUESTS_LIST)),
});

/* 
***********************************************

      REQUEST OVERVIEW LIST AND LIST FUNCTIONALITY

***********************************************
*/

type ParsedRequestOverviewElementRequest = Omit<RequestOverviewElementRequest, 'typeId'> & {
  typeDetails: ParsedRequest['requestType'];
};

export type ParsedRequestOverviewElement = Omit<RequestOverviewElement, 'personId' | 'requests'> & {
  requests: ParsedRequestOverviewElementRequest[];
  avatarUrl: ParsedEmployee['avatarUrl'];
  role: ParsedEmployee['role'];
  tags: ParsedEmployee['tags'];
  name: ParsedEmployee['name'];
  isActive: ParsedEmployee['isActive'];
  isHidden: ParsedEmployee['isHidden'];
  invitationState: ParsedEmployee['invitationState'];
  id: ParsedRequest['employee']['id'];
};

export type ParsedRequestOverviewMap = Map<RequestOverviewElement['personId'], ParsedRequestOverviewElement>;

export const requestOverviewAtom = atom<FetchRequestsOverviewForTimeRangeResponse | null>({
  key: 'requestOverview',
  default: null,
});

export const requestOverviewYearStateAtom = atom<string>({
  key: 'requestOverviewYearState',
  default: dateTime().get('year').toString(),
});

export const parsedRequestOverviewSelector = selector<ParsedRequestOverviewMap | null>({
  key: 'parsedRequestOverview',
  get: ({ get }) => {
    const requestOverview = get(requestOverviewAtom);
    const employees = get(parsedEmployeesSelector);

    if (!requestOverview || !employees) return null;

    const parsedRequestOverview: ParsedRequestOverviewMap = new Map();

    requestOverview.forEach((overviewElement) => {
      const employee = employees.get(overviewElement.personId);

      if (employee) {
        const parsedRequests: ParsedRequestOverviewElementRequest[] = _.reduce(
          overviewElement.requests,
          (acc, request) => {
            const { typeId, ...rest } = request;
            const requestType = get(getParsedRequestTypeSelector(typeId));

            if (requestType) {
              acc.push({ ...rest, typeDetails: requestType });
            }

            return acc;
          },
          [] as ParsedRequestOverviewElementRequest[],
        );

        const { id, avatarUrl, name, isActive, role, tags, isHidden, invitationState } = employee;
        const { personId, requests, ...rest } = overviewElement;

        const parsedElement = {
          ...rest,
          avatarUrl,
          name,
          isActive,
          isHidden,
          invitationState,
          role,
          tags,
          id,
          requests: parsedRequests,
        };

        parsedRequestOverview.set(personId, parsedElement);
      }
    });

    return parsedRequestOverview;
  },
});

const requestOverviewSearchFilterValueAtom = atom<string>({
  key: 'requestOverviewSearchFilterValue',
  default: '',
});

export const requestOverviewSearchFilterValueSelector = selector<string>({
  key: 'requestOverviewSearchFilterValueSelector',
  get: ({ get }) => get(requestOverviewSearchFilterValueAtom),
  set: ({ set, get }, newValue) => {
    if (guardRecoilDefaultValue(newValue)) {
      return;
    }
    const searchFilterValue = get(requestOverviewSearchFilterValueAtom);

    if (
      getStringWithReducedWhiteSpaces(newValue.replaceAll(',', '')) !==
      getStringWithReducedWhiteSpaces(searchFilterValue.replaceAll(',', ''))
    ) {
      set(requestOverviewSearchFilterValueAtom, newValue);
    }
  },
});

export const parsedRequestOverviewSearchFilterValueSelector = selector<string[]>({
  key: 'parsedRequestOverviewSearchFilterValue',
  get: ({ get }) =>
    getStringWithReducedWhiteSpaces(get(requestOverviewSearchFilterValueAtom))
      .trim()
      .split(',')
      .map((v) => v.trim())
      .filter((v) => !!v),
});

export const requestOverviewLimitedRequestsFilterAtom = atom<boolean>({
  key: 'requestOverviewLimitedRequestsFilter',
  default: false,
});

const getRequestOverviewFilterGroupStateAtomDefaultValue = (
  groupName: GroupName,
): RecoilState<FilterGroupState> | [DateRangeFilters.PAY_PERIOD] | null => {
  if (groupName === FilterGroupNames.TEAMMATES) {
    return filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES);
  }

  return null;
};

export const requestOverviewFilterGroupStateAtomFamily = atomFamily<FilterGroupState, GroupName>({
  key: 'requestOverviewFilterGroupState',
  default: getRequestOverviewFilterGroupStateAtomDefaultValue,
});

export const requestOverviewIsActiveFilterSelectorFamily = selectorFamily<boolean, IsActiveFilterConfig>({
  key: 'requestOverviewIsActiveFilter',
  get:
    ({ groupName, filterId }) =>
    ({ get }) => {
      const filterGroupState = get(requestOverviewFilterGroupStateAtomFamily(groupName));

      if (filterGroupState === null) {
        return filterGroupState === filterId;
      }
      if (filterId === null) return false;

      return filterGroupState.includes(filterId);
    },
  set:
    ({ groupName, filterId }) =>
    ({ set, get }, newValue) => {
      const filterGroupStateAtom = requestOverviewFilterGroupStateAtomFamily(groupName);
      const filterGroupState = get(filterGroupStateAtom);

      if (!newValue) {
        if (filterId === null) {
          return;
        }

        if (filterGroupState === null) {
          set(filterGroupStateAtom, [filterId]);
          return;
        }

        const newFilterGroupState = filterGroupState.filter((id) => id !== filterId);
        set(filterGroupStateAtom, newFilterGroupState.length ? newFilterGroupState : null);
        return;
      }

      if (filterId === null) {
        set(filterGroupStateAtom, filterId);
        return;
      }

      if (typeof filterGroupState === 'string' || filterGroupState === null) {
        set(filterGroupStateAtom, [filterId]);
        return;
      }

      set(filterGroupStateAtom, [...filterGroupState, filterId]);
    },
});

export const getFilteredRequestOverviewSelector = selector<ParsedRequestOverviewMap | null>({
  key: 'getFilteredRequestOverview',
  get: ({ get }) => {
    const requestOverview = get(parsedRequestOverviewSelector);
    const teammatesFilterState = get(requestOverviewFilterGroupStateAtomFamily(FilterGroupNames.TEAMMATES));
    const searchFilterState = get(parsedRequestOverviewSearchFilterValueSelector);

    if (!requestOverview) {
      return null;
    }

    const filterByTeammates = (isActive: ParsedEmployee['isActive'], isHidden: ParsedEmployee['isHidden']) => {
      let valid = false;

      if (teammatesFilterState === null) {
        valid = true;
        return valid;
      }
      if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
        valid = valid || isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
        valid = valid || isHidden;
      }
      if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
        valid = valid || !isActive;
      }

      return valid;
    };

    const filterBySearchInput = (element: ParsedRequestOverviewElement) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const { id } = element;

      const {
        name,
        role: { name: roleName },
        tags,
        id: employeeId,
      } = element;

      const searchableEmployee = _.flatMapDeep([
        `${name.firstName} ${name.surname}`,
        roleName,
        _.map(tags, (tag) => tag?.name),
        employeeId,
        id,
      ]);

      valid = _.every(
        searchFilterState.map((searchQuery) => {
          let subValid = false;

          _.forEach(searchableEmployee, (searchableValue) => {
            subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
          });

          return subValid;
        }),
      );

      return valid;
    };

    const filteredMap = new Map(requestOverview);

    requestOverview.forEach((element, employeeId) => {
      let valid = false;

      valid = filterByTeammates(element.isActive, element.isHidden) && filterBySearchInput(element);

      if (!valid) filteredMap.delete(employeeId);
    });

    return filteredMap;
  },
});

export const requestOverviewColumnNamesSelector = selector<string[]>({
  key: 'requestOverviewColumnNames',
  get: ({ get }) => {
    const requestOverview = get(parsedRequestOverviewSelector);
    const requestTypeFilter = get(requestOverviewFilterGroupStateAtomFamily(FilterGroupNames.REQUEST_TYPES));
    const requestOverviewLimitedFilter = get(requestOverviewLimitedRequestsFilterAtom);

    if (!requestOverview || requestOverview.size === 0 || !requestOverview.values().next().value.requests) {
      return [];
    }

    // Get first map element which includes time off and custom columns that will occur in the list.
    const firstMapElement: IteratorResult<ParsedRequestOverviewElement, ParsedRequestOverviewElement> = requestOverview
      .values()
      .next();

    const filterByType = (type: ParsedRequest['type']) => {
      if (requestTypeFilter !== null && requestTypeFilter.includes(`${type}`)) {
        return true;
      }

      return false;
    };

    const filterByLimited = (requestType: ParsedRequest['requestType']) => {
      if (
        (isOfType<RequestType>(requestType, 'isLimitable') || isOfType<TimeOffType>(requestType, 'isLimitable')) &&
        !requestType.isLimitable
      )
        return true;

      return false;
    };

    const transformResult = _.transform(
      firstMapElement.value.requests,
      (result: string[], request) => {
        if (filterByType(request.type) || !request.typeDetails) return;
        if (filterByLimited(request.typeDetails) && requestOverviewLimitedFilter) return;

        result.push(request.typeDetails.name);
      },
      [],
    );

    return transformResult;
  },
});

export const requestOverviewHiddenColumnsSelector = selector<string[]>({
  key: 'requestOverviewHiddenColumns',
  get: ({ get }) => {
    const eventRequestsFilter = get(
      requestOverviewIsActiveFilterSelectorFamily({
        groupName: FilterGroupNames.REQUEST_TYPES,
        filterId: `${RequestFormType.TimeTracking}`,
      }),
    );
    const businessRequestsFilter = get(
      requestOverviewIsActiveFilterSelectorFamily({
        groupName: FilterGroupNames.REQUEST_TYPES,
        filterId: `${RequestFormType.BusinessTrip}`,
      }),
    );
    const customRequestsFilter = get(
      requestOverviewIsActiveFilterSelectorFamily({
        groupName: FilterGroupNames.REQUEST_TYPES,
        filterId: `${RequestFormType.Custom}`,
      }),
    );
    const timeOffRequestsFilter = get(
      requestOverviewIsActiveFilterSelectorFamily({
        groupName: FilterGroupNames.REQUEST_TYPES,
        filterId: `${RequestFormType.TimeOff}`,
      }),
    );

    const hiddenColumns: string[] = [];

    if (eventRequestsFilter) hiddenColumns.push('timeEventsCount');
    if (businessRequestsFilter) hiddenColumns.push('businessTravelsCount');
    if (customRequestsFilter && timeOffRequestsFilter) hiddenColumns.push('requests');

    return hiddenColumns;
  },
});

/* 
***********************************************

      REQUEST DETAILS

***********************************************
*/
export const requestDetailsAtom = atom<FetchRequestDetailsResponse | null>({
  key: 'requestDetails',
  default: null,
});

export const requestDetailsDateDetailsSelector = selector<DateTimeDetails | null>({
  key: 'requestDetailsDateDetails',
  get: ({ get }) => {
    const requestDetails = get(requestDetailsAtom);

    if (!requestDetails) return null;

    const { dateTimeDetails } = requestDetails;

    return dateTimeDetails;
  },
});

export const parsedRequestDetailsSelector = selector<ParsedRequest | null>({
  key: 'parsedRequestDetails',
  get: ({ get }) => {
    const requestDetails = get(requestDetailsAtom);

    if (!requestDetails) return null;

    const {
      id,
      personId,
      number,
      type,
      typeId,
      state,
      isDeleted,
      dateTimeDetails,
      typeDescription,
      actionType,
      deleteAllowed,
      modificationAllowed,
    } = requestDetails;

    const parsedRequest: ParsedRequest | null = get(
      getParsedRequestSelector({
        id,
        personId,
        number,
        type,
        typeId,
        state,
        isDeleted,
        dateTimeDetails,
        typeDescription,
        actionType,
        deleteAllowed,
        modificationAllowed,
      }),
    );

    return parsedRequest;
  },
});

/* 
***********************************************

      ADD REQUEST

***********************************************
*/
const addRequestSearchFilterValueAtom = atom<string>({
  key: 'addRequestSearchFilterValue',
  default: '',
});

export const addRequestSearchFilterValueSelector = selector<string>({
  key: 'addRequestSearchFilterValueSelector',
  get: ({ get }) => get(addRequestSearchFilterValueAtom),
  set: ({ set, get }, newValue) => {
    if (guardRecoilDefaultValue(newValue)) return;
    const searchFilterValue = get(addRequestSearchFilterValueAtom);

    if (
      getStringWithReducedWhiteSpaces(newValue.replaceAll(',', '')) !==
      getStringWithReducedWhiteSpaces(searchFilterValue.replaceAll(',', ''))
    ) {
      set(addRequestSearchFilterValueAtom, newValue);
    }
  },
});

export const parsedAddRequestSearchFilterValueSelector = selector<string[]>({
  key: 'parsedAddRequestSearchFilterValue',
  get: ({ get }) =>
    getStringWithReducedWhiteSpaces(get(addRequestSearchFilterValueAtom))
      .trim()
      .split(',')
      .map((v) => v.trim())
      .filter((v) => !!v),
});

// TEAMMATES filter state for add request list. At the start it takes value of aside filters teammate filter state.
export const filterGroupTeammatesAtom = atom<FilterGroupState>({
  key: 'filterGroupState',
  default: filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES),
});

export const isActiveTeammatesFilterSelectorFamily = selectorFamily<boolean, TeammatesFilters>({
  key: 'isActiveTeammatesFilter',
  get:
    (filterId) =>
    ({ get }) => {
      const filterGroupState = get(filterGroupTeammatesAtom);

      if (filterGroupState === null) {
        return filterGroupState === filterId;
      }
      if (filterId === null) return false;

      return filterGroupState.includes(filterId);
    },
  set:
    (filterId) =>
    ({ set, get }, newValue) => {
      const filterGroupState = get(filterGroupTeammatesAtom);

      if (!newValue) {
        if (filterId === null) {
          return;
        }

        if (filterGroupState === null) {
          set(filterGroupTeammatesAtom, [filterId]);
          return;
        }

        const newFilterGroupState = filterGroupState.filter((id) => id !== filterId);
        set(filterGroupTeammatesAtom, newFilterGroupState.length ? newFilterGroupState : null);
        return;
      }

      if (filterId === null) {
        set(filterGroupTeammatesAtom, filterId);
        return;
      }

      if (typeof filterGroupState === 'string' || filterGroupState === null) {
        set(filterGroupTeammatesAtom, [filterId]);
        return;
      }

      set(filterGroupTeammatesAtom, [...filterGroupState, filterId]);
    },
});

export const filteredAddRequestEmployeesSelector = selector<Map<ParsedEmployee['id'], ParsedEmployee>>({
  key: 'filteredAddRequestEmployees',
  get: ({ get }) => {
    const employeesMap = get(parsedEmployeesSelector);
    if (!employeesMap) return new Map();

    const teammatesFilterState = get(filterGroupTeammatesAtom);

    const searchFilterState = get(parsedAddRequestSearchFilterValueSelector);

    const filterBySearchInput = (employee: ParsedEmployee) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const {
        name,
        role: { name: roleName },
        tags,
        customEmployeeId,
      } = employee;
      const searchableEmployee = _.flatMapDeep([
        `${name.firstName} ${name.surname}`,
        roleName,
        _.map(tags, (tag) => tag?.name),
        customEmployeeId,
      ]);

      valid = _.every(
        searchFilterState.map((searchQuery) => {
          let subValid = false;

          _.forEach(searchableEmployee, (searchableValue) => {
            subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
          });

          return subValid;
        }),
      );

      return valid;
    };

    const filterByTeammates = (employee: ParsedEmployee) => {
      let valid = false;

      if (teammatesFilterState === null) {
        valid = true;
        return valid;
      }
      if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
        valid = valid || !!employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
        valid = valid || !!employee.isHidden;
      }
      if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
        valid = valid || !employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.INVITED)) {
        valid = valid || !!employee.invitationState;
      }

      return valid;
    };

    const newMap = new Map(employeesMap);

    newMap.forEach((employee, employeeId) => {
      let valid = false;

      valid = filterByTeammates(employee) && filterBySearchInput(employee);

      if (!valid) newMap.delete(employeeId);
    });

    return newMap;
  },
});

export const selectedEmployeesAddRequestsIdsSelector = selector<ParsedRequest['id'][]>({
  key: 'selectedEmployeesAddRequestsIds',
  get: ({ get }) => get(selectedRowsIdsSelectorFamily(ADD_REQUEST_PICK_TEAMMATES_LIST)),
});

/* 
***********************************************

      SELECTS

***********************************************
*/

export const prepareTimeOffTypeOptionsSelector = selector<InputOption[]>({
  key: 'prepareTimeOffTypeOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) {
      return [];
    }

    const { timeOffTypes } = organizationSession;

    return _.map(timeOffTypes, ({ abbreviation, name, id }) => ({
      label: `${t({
        id: abbreviation,
      })} - ${t({
        id: name,
      })}`,
      id,
    }));
  },
});

export const prepareCustomRequestTypeOptionsSelector = selector<InputOption[]>({
  key: 'prepareCustomRequestTypeOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) return [];

    const { customRequestTypes } = organizationSession;

    return _.map(customRequestTypes, ({ abbreviation, name, id }) => ({
      label: `${t({
        id: abbreviation,
      })} - ${t({
        id: name,
      })}`,
      id,
    }));
  },
});

export const prepareTimeEventsOptionsSelector = selector<InputOption[]>({
  key: 'prepareTimeEventsOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) {
      return [];
    }

    const { timeEventTypes } = organizationSession;

    const selectOptions: InputOption[] = [];

    timeEventTypes.forEach(({ name, id }) => {
      if (id === ENTRY_TIME_EVENT_ID || id === EXIT_TIME_EVENT_ID) {
        selectOptions.push({
          label: t({
            id: name,
          }),
          id,
        });
        return;
      }

      selectOptions.push({
        label: t({
          id: name,
        }),
        id,
      });

      selectOptions.push({
        label: `${t({
          id: name,
        })} ${t({ id: 'requests.is_end', message: '(End)' })}`,
        id: `${id}${IS_END_STATUS}`,
      });
    });

    return selectOptions;
  },
});

/* 
***********************************************

      HISTORY ATOM, SELECTORS

***********************************************
*/

// History Atom
export const requestHistoryAtom = atom<RequestGetDataToModifyResponse | null>({
  key: 'requestHistory',
  default: null,
});
// ********************************************
// PARSED TYPES
export type ParsedCustomHistoryElement = Omit<CustomRequestsData, 'typeId'> & {
  requestType: ParsedRequest['requestType'];
};

export type ParsedTimeOffHistoryElemment = Omit<TimeOffsData, 'typeId'> & {
  requestType: ParsedRequest['requestType'];
};

export type ParsedTimeEventHistoryElement = Omit<EventsData, 'typeId'> & {
  requestType: ParsedRequest['requestType'];
};
// ********************************************
// Raw data selectors
export const businessHistorySelector = selector<BusinessTravelsData[] | null>({
  key: 'businessHistory',
  get: ({ get }) => {
    const requestHistory = get(requestHistoryAtom);

    if (!requestHistory || !requestHistory.businessTravelsData || _.isEmpty(requestHistory.businessTravelsData))
      return null;

    return requestHistory.businessTravelsData;
  },
});

export const timeEventsHistorySelector = selector<EventsData[] | null>({
  key: 'timeEventsHistory',
  get: ({ get }) => {
    const requestHistory = get(requestHistoryAtom);

    if (!requestHistory || !requestHistory.eventsData || _.isEmpty(requestHistory.eventsData)) return null;

    return requestHistory.eventsData;
  },
});

export const customHistorySelector = selector<CustomRequestsData[] | null>({
  key: 'customHistory',
  get: ({ get }) => {
    const requestHistory = get(requestHistoryAtom);

    if (!requestHistory || !requestHistory.customRequestsData || _.isEmpty(requestHistory.customRequestsData))
      return null;

    return requestHistory.customRequestsData;
  },
});

export const timeOffHistorySelector = selector<TimeOffsData[] | null>({
  key: 'timeOffHistory',
  get: ({ get }) => {
    const requestHistory = get(requestHistoryAtom);

    if (!requestHistory || !requestHistory.timeOffsData || _.isEmpty(requestHistory.timeOffsData)) return null;

    return requestHistory.timeOffsData;
  },
});
// ********************************************
// Maps with parsed data selectors
export const parsedRequestsCustomHistorySelector = selector<Map<string, ParsedCustomHistoryElement> | null>({
  key: 'parsedRequestsCustomHistory',
  get: ({ get }) => {
    const customHistory = get(customHistorySelector);

    if (!customHistory) return null;

    const parsedMap = new Map<string, ParsedCustomHistoryElement>();

    customHistory.forEach((element) => {
      const requestType = get(getParsedRequestTypeSelector(element.typeId));
      const { typeId, id, ...rest } = element;

      if (requestType) {
        parsedMap.set(id, {
          ...rest,
          id,
          requestType,
        });
      }
    });

    return parsedMap;
  },
});

export const parsedRequestsTimeOffHistorySelector = selector<Map<string, ParsedTimeOffHistoryElemment> | null>({
  key: 'parsedRequestsTimeOffHistory',
  get: ({ get }) => {
    const timeOffHistory = get(timeOffHistorySelector);

    if (!timeOffHistory) return null;

    const parsedMap = new Map<string, ParsedTimeOffHistoryElemment>();

    timeOffHistory.forEach((element) => {
      const requestType = get(getParsedRequestTypeSelector(element.typeId));
      const { typeId, id, ...rest } = element;

      if (requestType) {
        parsedMap.set(id, {
          ...rest,
          id,
          requestType,
        });
      }
    });

    return parsedMap;
  },
});

export const parsedTimeEventsHistorySelector = selector<Map<string, ParsedTimeEventHistoryElement> | null>({
  key: 'parsedTimeEventsHistory',
  get: ({ get }) => {
    const timeEventsHistory = get(timeEventsHistorySelector);

    if (!timeEventsHistory) return null;

    const parsedMap = new Map<string, ParsedTimeEventHistoryElement>();

    timeEventsHistory.forEach((element) => {
      const requestType = get(getParsedRequestTypeSelector(element.typeId));
      const { typeId, id, ...rest } = element;

      if (requestType) {
        parsedMap.set(id, {
          ...rest,
          id,
          requestType,
        });
      }
    });

    return parsedMap;
  },
});
// ********************************************
// Single data of the picked object in the history list
export const selectedTimeOffHistoryDataSelector = selector<TimeOffsData | null>({
  key: 'selectedTimeOffHistoryData',
  get: ({ get }) => {
    const timeOffHistory = get(timeOffHistorySelector);
    const selectedListRows = get(selectedRowsIdsSelectorFamily(CUSTOM_TIME_OFF_HISTORY_LIST));
    const selectedHistory = selectedListRows[0];

    if (!selectedHistory) return null;

    const timeOffHistoryElement = _.find(timeOffHistory, (item) => item.id === selectedHistory);

    return timeOffHistoryElement || null;
  },
});

export const selectedCustomHistoryDataSelector = selector<CustomRequestsData | null>({
  key: 'selectedCustomHistoryData',
  get: ({ get }) => {
    const customfHistory = get(customHistorySelector);
    const selectedListRows = get(selectedRowsIdsSelectorFamily(CUSTOM_TIME_OFF_HISTORY_LIST));
    const selectedHistory = selectedListRows[0];

    if (!selectedHistory) return null;

    const customfHistoryElement = _.find(customfHistory, (item) => item.id === selectedHistory);

    return customfHistoryElement || null;
  },
});

export const selectedBusinessHistoryDataSelector = selector<BusinessTravelsData | null>({
  key: 'selectedBusinessHistoryData',
  get: ({ get }) => {
    const businessHistory = get(businessHistorySelector);
    const selectedListRows = get(selectedRowsIdsSelectorFamily(BUSINESS_HISTORY_LIST));
    const selectedHistory = selectedListRows[0];

    if (!selectedHistory) return null;

    const businessHistoryElement = _.find(businessHistory, (item) => item.id === selectedHistory);

    return businessHistoryElement || null;
  },
});

export const selectedTimeEventDataSelector = selector<EventsData | null>({
  key: 'selectedTimeEventData',
  get: ({ get }) => {
    const timeEventsHistory = get(timeEventsHistorySelector);
    const selectedListRows = get(selectedRowsIdsSelectorFamily(TIME_EVENTS_HISTORY_LIST));
    const selectedHistory = selectedListRows[0];

    if (!selectedHistory) return null;

    const businessHistoryElement = _.find(timeEventsHistory, (item) => item.id === selectedHistory);

    return businessHistoryElement || null;
  },
});
// ********************************************
// Edit common data type
type CommonEditRequestData = Pick<
  CommonEditRemoveRequestData,
  'processedById' | 'optionalStepProcessedById' | 'note' | 'fileIds'
>;

// Selectors containing common data
export const timeOffCommonEditRequestDataSelector = selector<CommonEditRequestData | null>({
  key: 'timeOffCommonEditRequestData',
  get: ({ get }) => {
    const selectedTimeOffHistoryData = get(selectedTimeOffHistoryDataSelector);

    if (!selectedTimeOffHistoryData) return null;

    const commonData = _.pick(selectedTimeOffHistoryData, [
      'processedById',
      'optionalStepProcessedById',
      'note',
      'fileIds',
    ]);

    return commonData;
  },
});

export const customCommonEditRequestDataSelector = selector<CommonEditRequestData | null>({
  key: 'customfCommonEditRequestData',
  get: ({ get }) => {
    const selectedCustomHistoryData = get(selectedCustomHistoryDataSelector);

    if (!selectedCustomHistoryData) return null;

    const commonData = _.pick(selectedCustomHistoryData, [
      'processedById',
      'optionalStepProcessedById',
      'note',
      'fileIds',
    ]);

    return commonData;
  },
});

export const businessCommonEditRequestDataSelector = selector<CommonEditRequestData | null>({
  key: 'businessCommonEditRequestData',
  get: ({ get }) => {
    const selectedBusinessHistoryData = get(selectedBusinessHistoryDataSelector);

    if (!selectedBusinessHistoryData) return null;

    const commonData = _.pick(selectedBusinessHistoryData, [
      'processedById',
      'optionalStepProcessedById',
      'note',
      'fileIds',
    ]);

    return commonData;
  },
});

export const timeEventCommonEditRequestDataSelector = selector<CommonEditRequestData | null>({
  key: 'timeEventCommonEditRequestData',
  get: ({ get }) => {
    const selectedTimeEventData = get(selectedTimeEventDataSelector);

    if (!selectedTimeEventData) return null;

    const commonData = _.pick(selectedTimeEventData, ['processedById', 'optionalStepProcessedById', 'note', 'fileIds']);

    return commonData;
  },
});

export const commonEditRequestDataSelector = selector<CommonEditRequestData | null>({
  key: 'commonEditRequestData',
  get: ({ get }) => {
    const timeOffCommonEditRequestData = get(timeOffCommonEditRequestDataSelector);
    const businessCommonEditRequestData = get(businessCommonEditRequestDataSelector);
    const customCommonEditRequestData = get(customCommonEditRequestDataSelector);
    const timeEventCommonEditRequestData = get(timeEventCommonEditRequestDataSelector);

    return (
      timeOffCommonEditRequestData ||
      businessCommonEditRequestData ||
      customCommonEditRequestData ||
      timeEventCommonEditRequestData
    );
  },
});
// ********************************************
