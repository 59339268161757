import React from 'react';

const LazyTimesheets = React.lazy(() =>
  import('./Timesheets').then(({ Timesheets }) => ({
    default: Timesheets,
  })),
);

export const TimesheetsRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<></>}>
      <LazyTimesheets />
    </React.Suspense>
  ),
);
