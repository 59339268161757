import React, { useCallback, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Flex, Input, InputProps, Text } from 'theme-ui';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { postHasBeenReadAction, postMessageAction } from 'api/actions/chat/chatActions';
import {
  addOwnMessageSelector,
  hasBeenUnreadMessagesSelector,
  MessageSendStatus,
  updateOwnMessageSelector,
} from 'state/chat';
import { ChatUserForFetch } from 'api/actions/chat/chatActions.types';
import { employeeByIdSelector } from 'state/employees';
import { userSessionAtom } from 'state/userSession';
import { mergeRefs } from 'utils/mergeRefs';
import { createEvent } from 'utils/createEvent';
import { MAX_MESSAGE_LENGTH } from 'chat/constans';

type Props = {
  receiver: ChatUserForFetch['sender'];
  messagesListRef: React.MutableRefObject<HTMLInputElement | null>;
};

export const MessageComposer = React.forwardRef<HTMLInputElement, Props>(
  ({ receiver, messagesListRef }: Props, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [message, setMessage] = useState('');
    const [lengthError, setLengthError] = useState(0);

    const addOwnMessage = useSetRecoilState(addOwnMessageSelector);
    const updateOwnMessage = useSetRecoilState(updateOwnMessageSelector);
    const [hasBeenUnreadMessages, setHasBeenUnreadMessages] = useRecoilState(hasBeenUnreadMessagesSelector(receiver));
    const employee = useRecoilValue(employeeByIdSelector(receiver));
    const userSession = useRecoilValue(userSessionAtom);

    const { mutate } = useMutation(postMessageAction);
    const { mutate: hasBeenReadMutate } = useMutation(postHasBeenReadAction);

    const hasBeenRead = useCallback(async () => {
      if (hasBeenUnreadMessages) {
        const { error } = await hasBeenReadMutate(receiver);
        if (!error) {
          setHasBeenUnreadMessages(false);
        }
      }
    }, [hasBeenUnreadMessages, receiver, hasBeenReadMutate, setHasBeenUnreadMessages]);

    const handleMessageInputChange: InputProps['onInput'] = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { innerText } = e.target;

        e.target.scrollTo({ top: e.target.scrollHeight });

        setMessage(innerText);

        if (innerText.length > MAX_MESSAGE_LENGTH) {
          setLengthError(MAX_MESSAGE_LENGTH - innerText.length);
          return;
        }

        if (lengthError) setLengthError(0);
      },
      [lengthError],
    );

    const debounceUpdateOwnMessage = _.debounce((msg) => {
      updateOwnMessage(msg);
    }, 1000);

    const submitMessage = useCallback(async () => {
      if (message.length > 0 && message.trim() && receiver && userSession?.personId && lengthError === 0) {
        const parsedMessage = message.replace(/^\s*/, '').replace(/\s*$/, '');

        const postMessagePayload = {
          message: parsedMessage,
          receiver,
          id: uuidv1(),
          createdUnix: dayjs().unix(),
          sender: userSession.personId,
        };

        addOwnMessage(postMessagePayload);
        debounceUpdateOwnMessage({ ...postMessagePayload, status: MessageSendStatus.SENDING });
        setMessage('');
        if (inputRef.current) inputRef.current.innerText = '';
        messagesListRef?.current?.scrollTo(0, 0);

        const { error: submitError, payload } = await mutate(_.omit(postMessagePayload, ['createdUnix', 'sender']));

        if (!submitError && payload) {
          debounceUpdateOwnMessage({ ...payload, status: undefined });
        }

        if (submitError) {
          debounceUpdateOwnMessage({ ...postMessagePayload, status: MessageSendStatus.UNSENT });
        }
      }
    }, [message, receiver, userSession, lengthError, addOwnMessage, debounceUpdateOwnMessage, messagesListRef, mutate]);

    const submitMessageByEnter: InputProps['onKeyDown'] = useCallback(
      (e) => {
        if (!e.shiftKey && e.keyCode === 13) {
          e.preventDefault();
          submitMessage();
        }
      },
      [submitMessage],
    );

    const onPaste: InputProps['onPaste'] = useCallback((e) => {
      e.preventDefault();

      const clipboard = e.clipboardData.getData('text/plain');
      const selection = window.getSelection();

      if (selection) {
        if (!selection.rangeCount) return false;

        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(clipboard));
        selection.collapseToEnd();
      }

      const blurEvent = createEvent('input');
      if (inputRef.current) inputRef.current.dispatchEvent(blurEvent);

      return true;
    }, []);

    useEffect(() => {
      if (inputRef.current) inputRef.current.focus();
    }, []);

    return (
      <Flex variant="chat.messageComposer.container">
        <Input
          autoComplete="off"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          as="div"
          contentEditable
          suppressContentEditableWarning
          ref={mergeRefs([inputRef, ref])}
          id="message"
          type="text"
          variant="chat.messageComposer.input"
          value={message}
          onKeyDown={submitMessageByEnter}
          onFocus={hasBeenRead}
          onInput={handleMessageInputChange}
          onPaste={onPaste}
          data-placeholder={`${i18n._(
            t({
              id: ' messageComposer.input.placeholder',
              message: 'Message',
            }),
          )} ${employee?.name.firstName} ${employee?.name.surname}`}
        />
        <Flex variant="chat.messageComposer.sendButtonContainer">
          <Button
            disabled={!(message.length > 0 && lengthError === 0)}
            variant="success"
            shape="rounded"
            size="sm"
            type="submit"
            onClick={submitMessage}
          >
            <Icon type="send" />
          </Button>
        </Flex>
        {lengthError < 0 && (
          <Text
            as="span"
            sx={{
              position: 'absolute',
              bottom: 2,
              right: 3,
              fontSize: 1,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'texts.error',
              userSelect: 'none',
            }}
          >
            {lengthError}
          </Text>
        )}
      </Flex>
    );
  },
);
