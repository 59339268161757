export type GetSettingsResponse = {
  isLocationRequired: boolean;
  isCameraRequired: boolean;
  isFaceRecognitionRequired: boolean;
  summaryTimeSeconds: number;
  sleepModeTimeOutMinutes: number;
  isSleepModeEnabled: boolean;
  organizationName: string;
};

export interface GetInfoProps {
  qrCode: string;
  images: Blob[];
}

export type GetInfoResponse = {
  lastEnterUnix?: number;
  personName: string;
  workTimeSeconds: number;
  suggestedTimeEvent: TimeEvent & {
    includeWorkTime: boolean;
    remainingTimeAllowedSeconds?: number;
  };
  timeEventTypes: TimeEventType[];
  recentTimeEvents: [
    TimeEvent & {
      maxDurationAllowedSeconds?: number;
    },
  ];
};

export type GetInfoErrorData = {
  personName: string;
  workTimeSeconds: number;
};

export type TimeEventType = {
  id: string;
  name: string;
  isEnd: boolean;
};

export type TimeEvent = {
  type: TimeEventType;
  durationSeconds?: number; // UNIX
};

export type InitPersonModelProps = GetInfoProps & {
  useFaceDetection?: boolean;
};
export type InitPersonModelResponse = GetInfoResponse & {
  innerCode?: ErrorCodeInitPersonModel;
  message?: string;
};

export type SubmitTimeEventProps = {
  qrCode: string;
  timeEventId: string; // GUID/UUID - srv side duplicate detection
  timeEventTypeId: string; // UUID
  timeUtcUnix: number;
  isEnd: boolean;
  latitude?: number;
  longitude?: number;
  isOffline: boolean;
  photo: Blob;
  timeZoneId: string;
};

export type SubmitTimeEventResponse = {
  innerCode?: ErrorCodeTimeclock;
  message?: string;
};

export enum ErrorCodeTimeclock {
  OK = 0,

  BAD_QR_CODE = 2100,
  INVALID_PERSON_FACE = 2101,
  NO_FACE_MODEL = 2102,
  BAD_DEVICE_TIME = 2103,
  INVALID_LOCATION = 2104,

  DEVICE_DELETED = 2105,
  OFFLINE_MODE_DISABLED = 2106,
  DUPLICATE_REQUEST = 2107,
  MAGNET = 2108,

  CLOCK_IN_NOT_ALLOWED = 2115,

  INVALID_SUBSCRIPTION = 2,
  BAD_REQUEST = 3,
  BAD_CRED_CODE = 8,
}

export enum ErrorCodeInitPersonModel {
  FACE_MODEL_PHOTOS_UPLOAD_ERROR = 2200,
  FACE_MODEL_CREATION_ERROR = 2201,
}

export enum OsType {
  ANDROID,
  IOS,
}

export enum OnlineState {
  OFFLINE,
  ONLINE,
}

export type TimeClock = {
  id: string;
  name: string;
  appVersion: string;
  osType: OsType;
  osVersion: string;
  model: string | null;
  state: OnlineState;
};

export type GetTimeClocksResponse = TimeClock[];

export type DeleteTimeClocksActionProps = TimeClock['id'][];

export type DeleteTimeClocksResponse = null;

export type EditTimeClockActionProps = Partial<TimeClock>;

export type EditTimeClockResponse = null;
