import React from 'react';

const LazyLocations = React.lazy(() =>
  import('./Locations').then(({ Locations }) => ({
    default: Locations,
  })),
);

export const LocationsRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<></>}>
      <LazyLocations />
    </React.Suspense>
  ),
);
