import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  SignInPayload,
  AuthorizeResponse,
  RefreshAccessTokenResponse,
  SignOutResponse,
  SignUpResponse,
  SignUpPayload,
  InvitationResendPayload,
  InvitationJoinRequestPayload,
  SignUpResponseErrorData,
  SignUpErrorCodes,
} from './authActions.types';

export function signInAction(requestBody: SignInPayload): Action<AuthorizeResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.authorize,

    body: {
      ...requestBody,
    },
  };
}

export function signOutAction(accessToken?: string | null): Action<SignOutResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.signOut,

    body: {
      accessToken,
    },
  };
}

export function refreshAccessTokenAction(): Action<RefreshAccessTokenResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.refreshAccessToken,
  };
}

export function signUpAction(
  requestBody: SignUpPayload,
): Action<SignUpResponse, SignUpResponseErrorData, SignUpErrorCodes> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.signUp,
    body: {
      ...requestBody,
    },
  };
}

export function invitationResendAction(requestBody: InvitationResendPayload): Action<unknown> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.invitationResend,
    body: {
      ...requestBody,
    },
  };
}

export function invitationJoinRequestAction(requestBody: InvitationJoinRequestPayload): Action<unknown> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.invitationJoinRequest,
    body: {
      ...requestBody,
    },
  };
}
