import { selector, selectorFamily } from 'recoil';
import _ from 'lodash';

import { FilterGroupNames, TagsFilters, TeammatesFilters } from 'layouts/AuthorizedApp/AsideFilters/types';
import { Employee, Tag, Role, WorkPosition } from 'api/actions/organizationSession/organizationSessionActions.types';

import { filterGroupStateAtomFamily, parsedSearchFilterValueSelector } from './filters';
import { organizationSessionAtom, OrganizationSessionAtomType } from './organizationSession';
import { selectedRowsIdsSelectorFamily } from './list';

export type ParsedEmployee = Employee & {
  workPosition?: string;
  tags: (Tag | undefined)[];
  role: Role;
};

// 1.
export const parsedEmployeesSelector = selector({
  key: 'parsedEmployees',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) return null;

    const { employeesMap, workPositionsMap, tagsMap, rolesMap } = organizationSession as Omit<
      typeof organizationSession,
      'employeesMap'
    > & { employeesMap: Map<ParsedEmployee['id'], ParsedEmployee> };

    employeesMap.forEach((employee, employeeId) => {
      const employeeTags = employee.tagsIds.flatMap((tagId) => tagsMap.get(tagId));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const employeeRole = rolesMap.get(employee.roleId)!;
      const employeeWorkPosition =
        employee.defaultWorkPositionId && workPositionsMap.get(employee.defaultWorkPositionId)?.name;

      employeesMap.set(employeeId, {
        ...employee,
        tags: employeeTags,
        role: employeeRole,
        workPosition: employeeWorkPosition,
      });
    });

    return employeesMap;
  },
});

type EmployeeSelectorConfig = Pick<ParsedEmployee, 'id'>;

export const employeeSelectorFamily = selectorFamily<ParsedEmployee | null, EmployeeSelectorConfig>({
  key: 'employee',
  get:
    ({ id }) =>
    ({ get }) => {
      const employees = get(parsedEmployeesSelector);
      if (!employees) return null;

      return employees.get(id) || null;
    },
});

export const tagsSelector = selector<Tag[] | null>({
  key: 'tags',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { tags } = organizationSession;
    return tags;
  },
});

export const tagsMapSelector = selector<OrganizationSessionAtomType['tagsMap'] | null>({
  key: 'tagsMap',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { tagsMap } = organizationSession;
    return tagsMap;
  },
});

export const rolesSelector = selector<Role[] | null>({
  key: 'roles',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { roles } = organizationSession;
    return roles;
  },
});

export const rolesMapSelector = selector<OrganizationSessionAtomType['rolesMap'] | null>({
  key: 'rolesMap',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { rolesMap } = organizationSession;
    return rolesMap;
  },
});

export const workPositionsSelector = selector<WorkPosition[] | null>({
  key: 'workPositions',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { workPositions } = organizationSession;
    return workPositions;
  },
});

export const workPositionsMapSelector = selector<OrganizationSessionAtomType['workPositionsMap'] | null>({
  key: 'workPositionsMap',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { workPositionsMap } = organizationSession;
    return workPositionsMap;
  },
});

// 2
export const filteredEmployeesSelector = selector<Map<ParsedEmployee['id'], ParsedEmployee>>({
  key: 'filteredEmployees',
  get: ({ get }) => {
    const employeesMap = get(parsedEmployeesSelector);
    if (!employeesMap) return new Map();

    const teammatesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES));
    const rolesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.ROLES));
    const tagsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TAGS));
    const searchFilterState = get(parsedSearchFilterValueSelector);

    const filterBySearchInput = (employee: ParsedEmployee) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const {
        email,
        name,
        phoneNumber,
        role: { name: roleName },
        tags,
        customEmployeeId,
        note,
        workPosition,
      } = employee;
      const searchableEmployee = _.flatMapDeep([
        email,
        `${name.firstName} ${name.surname}`,
        phoneNumber,
        roleName,
        _.map(tags, (tag) => tag?.name),
        customEmployeeId,
        note,
        workPosition,
      ]);

      valid = _.every(
        searchFilterState.map((searchQuery) => {
          let subValid = false;

          _.forEach(searchableEmployee, (searchableValue) => {
            subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
          });

          return subValid;
        }),
      );

      return valid;
    };

    const filterByTags = (employee: ParsedEmployee) => {
      let valid = false;

      if (tagsFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(tagsFilterState)) {
        tagsFilterState.forEach((tagId) => {
          valid = valid || !!employee.tagsIds.includes(tagId);

          if (tagId === TagsFilters.NO_TAGS && _.isEmpty(employee.tagsIds)) {
            valid = true;
          }
        });
      }

      return valid;
    };

    const filterByRole = (employee: ParsedEmployee) => {
      let valid = false;

      if (rolesFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(rolesFilterState)) {
        rolesFilterState.forEach((roleId) => {
          valid = valid || !!(employee.roleId === roleId);
        });
      }

      return valid;
    };

    const filterByTeammates = (employee: ParsedEmployee) => {
      let valid = false;

      if (teammatesFilterState === null) {
        valid = true;
        return valid;
      }
      if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
        valid = valid || !!employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
        valid = valid || !!employee.isHidden;
      }
      if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
        valid = valid || !employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.INVITED)) {
        valid = valid || !!employee.invitationState;
      }

      return valid;
    };

    const newMap = new Map(employeesMap);

    newMap.forEach((employee, employeeId) => {
      let valid = false;

      valid =
        filterByTeammates(employee) &&
        filterByRole(employee) &&
        filterByTags(employee) &&
        filterBySearchInput(employee);

      if (!valid) newMap.delete(employeeId);
    });

    return newMap;
  },
});

// 3
export const selectedEmployeesIdsSelector = selector<ParsedEmployee['id'][]>({
  key: 'selectedEmployeesIds',
  get: ({ get }) => {
    const selectedRowsIds = get(selectedRowsIdsSelectorFamily('TEAM'));

    return selectedRowsIds;
  },
});

export const employeeByIdSelector = selectorFamily<ParsedEmployee | null, Employee['id'] | undefined>({
  key: 'employeeById',
  get:
    (id) =>
    ({ get }) => {
      const parsedEmployees = get(parsedEmployeesSelector);
      if (!parsedEmployees || !id) return null;

      const employee = parsedEmployees.get(id);

      if (!employee) return null;

      return employee;
    },
});
