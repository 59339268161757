import React from 'react';
import { Text, Flex } from 'theme-ui';

import { TimeClock } from 'components/TimeClock/TimeClock';
import { dateTime } from 'utils/dateTime';

type Props = {
  nightMode: boolean;
};

export const Clock = ({ nightMode }: Props): React.ReactElement => (
  <Flex
    sx={{
      p: 4,
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      ...(!nightMode
        ? {
            textShadow: '0px 0px 8px rgba(14, 23, 55, 0.7), 0px 0px 2px rgba(14, 23, 55, 0.5)',
          }
        : {
            '@media (max-height: 399px)': {
              textShadow: '0px 0px 8px rgba(14, 23, 55, 0.7), 0px 0px 2px rgba(14, 23, 55, 0.5)',
              color: 'white',
            },
          }),
      transition: 'color ease-in-out .2s',
      userSelect: 'none',
    }}
  >
    <TimeClock
      sx={{
        fontSize: '5rem',
        lineHeight: 1,
        '@media (min-height: 400px)': {
          letterSpacing: ['0.25rem', null, '0.5rem'],
          fontSize: ['5rem', null, '7rem'],
        },
      }}
    />
    <Text
      sx={{
        fontSize: '1.25rem',
        '@media (min-height: 400px)': {
          fontSize: ['1.25rem', null, '1.75rem'],
        },
      }}
    >
      {dateTime().format('dddd,')} {dateTime().format('DD MMMM')}
    </Text>
  </Flex>
);
