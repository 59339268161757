import React from 'react';
import { Container, ThemeUIStyleObject, Text, Flex } from 'theme-ui';
import _ from 'lodash';

import { Button } from 'components/ui/Buttons/Button';
import { useTheme } from 'styles/useTheme';

import { SnackbarProps } from './types';

const defaultProps: Partial<SnackbarProps> = {
  action: undefined,
  isStatic: false,
  prependWith: undefined,
  variant: 'default',
  placement: 'top',
};

const containerSx: ThemeUIStyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'all',
  borderRadius: 'xl',
  width: 'auto',
  maxWidth: '496px',
  textAlign: 'center',
  boxShadow: `dropShadow.levelThree`,
  p: 2,
};

export const messageSx: ThemeUIStyleObject = {
  fontSize: 2,
  fontWeight: 'bold',
  lineHeight: 'heading',
  wordWrap: 'break-word',
  py: 1,
  mx: 3,
};

const Snackbar = ({ message, onRemove, action, prependWith, variant }: SnackbarProps): React.ReactElement => {
  const { theme } = useTheme();

  const actionWithRemove =
    (snackbarAction: () => void): (() => void) =>
    () => {
      snackbarAction();
      if (onRemove) onRemove();
    };

  return (
    <Container
      sx={{
        ...containerSx,
      }}
      variant={`snackbars.${variant}`}
    >
      {prependWith && <Flex ml={2}>{prependWith}</Flex>}
      {_.isString(message) ? <Text sx={messageSx}>{message}</Text> : message}
      {action && (
        <Button
          shape="pill"
          size="sm"
          onClick={actionWithRemove(action.onClickCallback)}
          bgOverwrite={{
            default: theme.colors.alphas.lighter4,
            hover: theme.colors.alphas.lighter2,
            tap: theme.colors.alphas.lighter5,
            disabled: theme.colors.grays[3],
          }}
        >
          {action.title}
        </Button>
      )}
    </Container>
  );
};
Snackbar.defaultProps = defaultProps;

export const MemoizedSnackbar = React.memo(Snackbar);
