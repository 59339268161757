import { atom, atomFamily, selector, selectorFamily } from 'recoil';

import {
  DateRangeFilters,
  FilterGroupNames,
  FilterProps,
  TeammatesFilters,
  WorkStatusesFilters,
} from 'layouts/AuthorizedApp/AsideFilters/types';
import { guardRecoilDefaultValue } from 'utils/guardRecoilDefaultValue';
import { getStringWithReducedWhiteSpaces } from 'utils/whiteSpaceReducer';

//
// SEARCH FILTER
//

const searchFilterValueAtom = atom<string>({
  key: 'searchFilterValue',
  default: '',
});

export const searchFilterValueSelector = selector<string>({
  key: 'searchFilterValueSelector',
  get: ({ get }) => get(searchFilterValueAtom),
  set: ({ set, get }, newValue) => {
    if (guardRecoilDefaultValue(newValue)) return;
    const searchFilterValue = get(searchFilterValueAtom);

    if (
      getStringWithReducedWhiteSpaces(newValue.replaceAll(',', '')) !==
      getStringWithReducedWhiteSpaces(searchFilterValue.replaceAll(',', ''))
    ) {
      set(searchFilterValueAtom, newValue);
    }
  },
});

export const parsedSearchFilterValueSelector = selector<string[]>({
  key: 'parsedSearchFilterValueSelector',
  get: ({ get }) =>
    getStringWithReducedWhiteSpaces(get(searchFilterValueAtom))
      .trim()
      .split(',')
      .map((v) => v.trim())
      .filter((v) => !!v),
});

//
// UNIVERSAL FILTER
// GROUP FILTER
//

export type GroupName = FilterProps['groupName'];

export type IsActiveFilterConfig = {
  filterId: FilterProps['id'];
  groupName: GroupName;
};

export type FilterGroupState = string[] | null;

export const getFilterGroupStateAtomDefaultValue = (
  groupName: GroupName,
): [TeammatesFilters.ACTIVE] | [WorkStatusesFilters.ACTIVE] | [DateRangeFilters.MONTH] | null => {
  if (groupName === FilterGroupNames.TEAMMATES) return [TeammatesFilters.ACTIVE];
  if (groupName === FilterGroupNames.WORK_STATUSES_STATE) return [WorkStatusesFilters.ACTIVE];
  if (groupName === FilterGroupNames.DATE_RANGE) return [DateRangeFilters.MONTH];
  return null;
};

export const filterGroupStateAtomFamily = atomFamily<FilterGroupState, GroupName>({
  key: 'filterGroupState',
  default: getFilterGroupStateAtomDefaultValue,
  effects: [
    ({ node, setSelf, onSet }) => {
      const { key } = node;
      if (key.includes(FilterGroupNames.DATE_RANGE)) {
        const savedValue = localStorage.getItem(key);

        if (savedValue != null) {
          setSelf(JSON.parse(savedValue));
        }

        onSet((newValue, _, isReset) =>
          isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue)),
        );
      }
    },
  ],
});

export const isActiveFilterSelectorFamily = selectorFamily<boolean, IsActiveFilterConfig>({
  key: 'isActiveFilter',
  get:
    ({ groupName, filterId }) =>
    ({ get }) => {
      const filterGroupState = get(filterGroupStateAtomFamily(groupName));

      if (filterGroupState === null) {
        return filterGroupState === filterId;
      }
      if (filterId === null) return false;

      return filterGroupState.includes(filterId);
    },
  set:
    ({ groupName, filterId }) =>
    ({ set, get }, newValue) => {
      const filterGroupStateAtom = filterGroupStateAtomFamily(groupName);
      const filterGroupState = get(filterGroupStateAtom);

      if (!newValue) {
        if (filterId === null) {
          return;
        }

        if (filterGroupState === null) {
          set(filterGroupStateAtom, [filterId]);
          return;
        }

        const newFilterGroupState = filterGroupState.filter((id) => id !== filterId);
        set(filterGroupStateAtom, newFilterGroupState.length ? newFilterGroupState : null);
        return;
      }

      if (filterId === null) {
        set(filterGroupStateAtom, filterId);
        return;
      }

      if (
        typeof filterGroupState === 'string' ||
        filterGroupState === null ||
        groupName === FilterGroupNames.DATE_RANGE
      ) {
        set(filterGroupStateAtom, [filterId]);
        return;
      }

      set(filterGroupStateAtom, [...filterGroupState, filterId]);
    },
});

//
// DATE RANGE FILTER
//

export type DateRangeFilterAtom = {
  startDateUnix: number;
  endDateUnix: number;
};

export const dateRangeFilterAtom = atom<DateRangeFilterAtom | null>({
  key: 'dateRangeFilterAtom',
  default: null,
});
