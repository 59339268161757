import React, { useEffect, useContext } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { ClientContext } from 'react-fetching-library';

import { signOutAction } from 'api/actions/auth/authActions';
import { TO } from '../../constants/routes';
import { signOut } from '../../context/auth/authActionCreators/authActionCreators';
import { signOut as channelSignOut } from 'broadcastChannel/channelActionCreators/channelActionCreators';
import { authStorage } from '../../context/auth/authStorage/AuthStorage';
import { useAuthDispatch } from '../../hooks/useAuthDispatch/useAuthDispatch';
import { languageSelector } from '../../state/recoilState';
import { removeAllNotifications } from 'NotificationHub/actions';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { broadcastChannel } from 'observers/BroadcastChannelObserver';
import { userSessionAtom } from 'state/userSession';
import { organizationSessionAtom } from 'state/organizationSession';
import { timeTrackerAtom } from 'state/drawer';

interface LocationState extends Location {
  state?: {
    redirectInitiator?: string;
  };
}

type Props = Omit<RouteProps, 'location'> & {
  location?: LocationState;
};

const defaultProps: Partial<Props> = {
  location: undefined,
};

export const SignOut = (props: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const { removeAllSnackbars } = useSnackbar();
  const dispatch = useAuthDispatch();
  const resetUserSessionState = useResetRecoilState(userSessionAtom);
  const resetOrganizationSessionState = useResetRecoilState(organizationSessionAtom);
  const resetTimeTrackerState = useResetRecoilState(timeTrackerAtom);
  const { query } = useContext(ClientContext);
  const redirectInitiator = props.location?.state?.redirectInitiator;

  useEffect(() => {
    authStorage.accessToken = null;
    resetUserSessionState();
    resetOrganizationSessionState();
    resetTimeTrackerState();
    setTimeout(() => {
      localStorage.clear();
    }, 0);

    removeAllNotifications();
    removeAllSnackbars();
    dispatch(signOut());
    query(signOutAction());
    if (redirectInitiator !== 'BROADCAST_CHANNEL') {
      broadcastChannel?.postMessage(channelSignOut());
    }
  });

  return <Redirect to={TO.SIGN_IN[language]} />;
};

SignOut.defaultProps = defaultProps;
