import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'theme-ui';

import { stateInitializer } from '../state/stateInitializer';
import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { LanguageProvider } from 'context/language/LanguageProvider';
import { SnackbarProvider } from 'context/snackbar/SnackbarProvider';
import { DateTimeZoneProvider } from 'context/dateTimeZone/DateTimeZoneProvider';
import { theme } from 'styles/theme/index';
import { UserSessionInitializer } from 'context/session/userSessionInitializer/UserSessionInitializer';

type Props = {
  children: React.ReactElement[];
};

const basename = process.env.PUBLIC_URL || '/';

export const AppContext = ({ children }: Props): React.ReactElement => (
  <ThemeProvider theme={theme}>
    <RecoilRoot initializeState={stateInitializer}>
      <LanguageProvider>
        <DateTimeZoneProvider>
          <SnackbarProvider>
            <AuthContextController>
              <ClientContextController>
                <UserSessionInitializer>
                  <Router basename={basename}>{children}</Router>
                </UserSessionInitializer>
              </ClientContextController>
            </AuthContextController>
          </SnackbarProvider>
        </DateTimeZoneProvider>
      </LanguageProvider>
    </RecoilRoot>
  </ThemeProvider>
);
