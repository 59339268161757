import { useRecoilValue } from 'recoil';

import { useSearchParam } from 'hooks/useSearchParam/useSearchParam';
import { REDIRECT_ROUTES } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { QueryParams } from 'constants/queryParams';

type Return = {
  redirectPath: string | null;
  redirectQuery: string;
};

export const useRedirectPath = (redirectTo?: string): Return => {
  const language = useRecoilValue(languageSelector);

  const redirectParam = useSearchParam(QueryParams.Redirect, false, redirectTo);
  const idParam = useSearchParam(QueryParams.Id, false);
  const tokenParam = useSearchParam(QueryParams.Token, false);
  const userIdParam = useSearchParam(QueryParams.UserId, false);
  const alertParam = useSearchParam(QueryParams.Alert, false);

  const redirectRoute = !redirectParam ? null : REDIRECT_ROUTES(parseInt(redirectParam, 10))[language];

  let redirectStr = redirectRoute;
  let queryStr = '';

  if (idParam) {
    redirectStr += `/${idParam}`;
  }

  if (tokenParam || userIdParam || alertParam) {
    queryStr += `?`;
    if (tokenParam) queryStr += `${QueryParams.Token}=${tokenParam}&`;
    if (userIdParam) queryStr += `${QueryParams.UserId}=${userIdParam}&`;
    if (alertParam) queryStr += `${QueryParams.Alert}=${alertParam}&`;
  }

  return {
    redirectPath: redirectStr,
    redirectQuery: queryStr,
  };
};
