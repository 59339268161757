import { makeTheme } from '../makeTheme';

const PILL_BACKGROUND = {
  default: 'backgrounds.levelTwo',
  hover: 'backgrounds.levelZero',
  active: 'alphas.bluish4',
  activeHover: 'alphas.bluish2',
};

const SHADOW = 'dropShadow.levelZero';

export const PILL_PERSON_HEIGHT = 51;
const PILL_PERSON_MARGIN = 1;

export const attendancePillStyles = makeTheme({
  attendancePill: {
    container: {
      gap: 2,
      p: 2,
      flexDirection: 'column',
      bg: PILL_BACKGROUND.default,
      borderRadius: 'default',
      transition: 'background 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
      '&:hover': {
        bg: PILL_BACKGROUND.hover,
        boxShadow: SHADOW,
      },
      '&[data-is-empty="true"]': {
        pointerEvents: 'none',
        opacity: 0.35,
      },
      '&[data-is-open="true"]': {
        bg: PILL_BACKGROUND.active,
        '&:hover': {
          bg: PILL_BACKGROUND.activeHover,
        },
      },
    },
    containerHeader: {
      cursor: 'pointer',
      fontSize: 2,
      alignItems: 'center',
      gap: '0.75rem',
      m: -2,
      p: '0.75rem',
    },
    containerCounter: {
      color: 'attendancePill.counterText',
      fontWeight: 'bold',
      borderRadius: 'sm',
      width: '2rem',
      height: '2rem',
      alignItems: 'center',
      justifyContent: 'center',
      bg: 'attendancePill.bg.default',
    },
    containerTitle: {
      flex: '1 0',
      fontWeight: 'bold',
    },
  },
  attendancePillPerson: {
    container: {
      bg: 'rgba(255,255,255,.75)',
      maxHeight: PILL_PERSON_HEIGHT - PILL_PERSON_MARGIN,
      width: '100%',
      px: 2,
      py: 1,
      mb: `${PILL_PERSON_MARGIN}px`,
      alignItems: 'center',
      gap: 2,
      fontSize: 2,
      transition: 'background 0.2s ease-in-out',
      ':hover': {
        bg: 'rgba(255,255,255,.45)',
      },
    },
    additionalInfo: {
      fontSize: 1,
    },
  },
});
