import { Trans } from '@lingui/macro';
import React from 'react';
import { Flex, Heading } from 'theme-ui';

import { AdvancedDeleteFaceModels } from 'layouts/Settings/Organization/forms/AdvancedDeleteFaceModels';
import { AdvancedDeleteOrganization } from 'layouts/Settings/Organization/forms/AdvancedDeleteOrganization';
import { AdvancedDeletePhotosForm } from 'layouts/Settings/Organization/forms/AdvancedDeletePhotosForm';

export const Advanced = (): React.ReactElement => (
  <>
    <Heading variant="heading3">
      <Trans id="advanced_settings.heading.advanced">Advanced</Trans>
    </Heading>
    <Flex sx={{ gap: '2.25rem', flexDirection: 'column' }}>
      <AdvancedDeletePhotosForm />
      <AdvancedDeleteFaceModels />
      <AdvancedDeleteOrganization />
    </Flex>
  </>
);
