import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Trans } from '@lingui/macro';

import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { useFaceDetection } from 'Kiosk/hooks/useFaceLandmarkModel';
import { settingsAtom } from 'Kiosk/state/settingsState';
import { useQrScanner } from 'Kiosk/hooks/useQrScanner';

import { TextMessage } from './TextMessage';

export const KioskLoadingSplash = (): React.ReactElement => {
  const [isDone, setIsDone] = useState(false);
  const { isCameraReady } = useRecoilValue(cameraStateAtom);
  const { isModelLoaded } = useFaceDetection();
  const { isQrScannerRunning } = useQrScanner({});
  const settings = useRecoilValue(settingsAtom);

  const renderMessage = () => (
    <>
      <TextMessage done={!!settings}>
        <Trans id="kiosk.loading.settings">Settings loaded</Trans>
      </TextMessage>
      <TextMessage done={isCameraReady}>
        <Trans id="kiosk.loading.camera">Camera initialized</Trans>
      </TextMessage>
      <TextMessage done={isQrScannerRunning}>
        <Trans id="kiosk.loading.qr">QR scanner initialized</Trans>
      </TextMessage>
      {settings && settings.isFaceRecognitionRequired && (
        <TextMessage done={isModelLoaded}>
          <Trans id="kiosk.loading.face">Face detection initialized</Trans>
        </TextMessage>
      )}
    </>
  );

  useEffect(() => {
    if (
      !isDone &&
      isCameraReady &&
      settings &&
      ((settings.isFaceRecognitionRequired && isModelLoaded) || !settings.isFaceRecognitionRequired)
    ) {
      setIsDone(true);
    }
  }, [isDone, isCameraReady, isModelLoaded, settings]);

  return !isDone ? <LoadingSplash sx={{ zIndex: 1020 }} message={renderMessage()} /> : <></>;
};
