import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Flex } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon } from 'components/Icon/Icon';
import { LinkButton } from 'components/ui/Buttons/LinkButton';
import { TO } from 'constants/routes';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { languageSelector } from 'state/recoilState';
import { customRequestTypesSelector } from 'state/organizationSession';
import { ListItem, StickyListProps } from 'components/StickyList/types';
import { CustomRequestTypes, RequestType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Switch } from 'components/ui/Switch';
import { Checkbox } from 'components/ui/Checkbox';
import { setCustomRequestTypesStateAction } from 'api/actions/customRequest.ts/customRequestActions';
import {
  CustomRequestTypeState,
  SetCustomRequestTypesStateActionProps,
} from 'api/actions/customRequest.ts/customRequestActions.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { LazyComponentType } from 'utils/custom.types';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { Divider } from 'components/Divider/Divider';

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<RequestType>>;

export const CustomRequestList = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const match = useCleanRouteMatch();
  const history = useHistory();
  const customRequestTypes: StickyListProps<RequestType>['list'] | null = useRecoilValue(customRequestTypesSelector);
  const { mutate } = useMutation(setCustomRequestTypesStateAction);

  const [list, setList] = useState<StickyListProps<RequestType | { isNonEditable: boolean }>['list'] | null>(null);
  const [customRequestTypesStates, setCustomRequestTypesStates] = useState<CustomRequestTypeState[]>([]);
  const customRequestTypesStatesRef = useRef<CustomRequestTypeState[]>([]);

  const handlePropagationOnClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const handleDeleteOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      handlePropagationOnClick(e);
      history.push(`${match}${TO.DELETE_CUSTOM_REQUEST_TYPE_MODAL[language]}`, { ids: [id] });
    },
    [handlePropagationOnClick, history, language, match],
  );

  const handleSwitchOnChange = useMemo(
    () =>
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const timeOffTypeState: CustomRequestTypeState = {
          id: e.target.name,
          state: e.target.checked,
        };
        const filteredTimeOffTypesStates = customRequestTypesStatesRef.current.filter(
          ({ id: currentId }) => currentId !== timeOffTypeState.id,
        );
        const newTimeOffTypesStates = [...filteredTimeOffTypesStates, timeOffTypeState];

        setCustomRequestTypesStates(newTimeOffTypesStates);
        customRequestTypesStatesRef.current = newTimeOffTypesStates;
      }, 200),
    [],
  );

  const debounceMutateCustomRequestTypesStates = useMemo(
    () =>
      _.debounce(() => {
        const mutateData: SetCustomRequestTypesStateActionProps = {
          customRequestTypesStates: customRequestTypesStatesRef.current,
        };

        mutate(mutateData);
      }, 200),
    [mutate],
  );

  useEffect(() => {
    if (customRequestTypesStates.length) {
      debounceMutateCustomRequestTypesStates();
    }
  }, [debounceMutateCustomRequestTypesStates, customRequestTypesStates]);

  const columns: StickyListProps<RequestType>['columns'] = useMemo(
    () => [
      {
        key: 'isActive',
        title: 'Active',
        width: '40px',
        customCellRenderer: (isActive: RequestType['isActive'], id: RequestType['id']) => (
          <Flex onClick={handlePropagationOnClick}>
            <Switch name={id} defaultChecked={isActive} size="sm" onChange={handleSwitchOnChange} />
          </Flex>
        ),
      },
      {
        key: 'abbreviation',
        title: 'Abbrev.',
        width: '60px',
        sortableValue: (abbreviation: RequestType['abbreviation']) =>
          t({
            id: abbreviation,
          }),
        customCellRenderer: (abbreviation: RequestType['abbreviation']) => (
          <TextEllipsis
            title={t({
              id: abbreviation,
            })}
          >
            <Trans id={abbreviation} />
          </TextEllipsis>
        ),
      },
      {
        key: 'name',
        title: 'Name',
        sortableValue: (name: RequestType['name']) =>
          t({
            id: name,
          }),
        customCellRenderer: (name: RequestType['name']) => (
          <TextEllipsis
            title={t({
              id: name,
            })}
          >
            <Trans id={name} />
          </TextEllipsis>
        ),
      },
      {
        key: 'isLimitable',
        title: 'Limitable',
        width: '40px',
        customCellRenderer: (isLimitable: RequestType['isLimitable']) => (
          <Checkbox name="placeholder" checked={isLimitable} size="sm" disabled />
        ),
      },
      {
        key: 'id',
        width: '40px',
        customCellRenderer: (id: RequestType['id']) =>
          !_.includes(CustomRequestTypes, id) ? (
            <Flex
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleDeleteOnClick(e, id)}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <Icon type="delete" />
            </Flex>
          ) : null,
      },
    ],
    [handleDeleteOnClick, handlePropagationOnClick, handleSwitchOnChange],
  );

  const handleOnRowClick = (id: ListItem['id']) => {
    history.push(`${match}${TO.EDIT_CUSTOM_REQUEST_TYPE_MODAL[language]}/${id}`);
  };

  useEffect(() => {
    const setListAsync = async () => {
      if (customRequestTypes) {
        const newList: StickyListProps<RequestType | { isNonEditable: boolean }>['list'] = [];

        customRequestTypes.forEach((item) =>
          newList.push({
            ...item,
            isNonEditable: _.includes(CustomRequestTypes, item.id),
          }),
        );

        setList(newList);
      }
    };

    setListAsync();
  }, [customRequestTypes]);

  return (
    <Flex sx={{ flexDirection: 'column', mt: '0.75rem' }}>
      <OptionLabel
        label={t({
          id: 'requests_settings.texts.custom_request_types',
          message: 'Custom request types',
        })}
        additionalLabel={t({
          id: 'requests_settings.texts.custom_request_types.additional_label',
          message: 'Add custom types of requests or set the active/available types for the whole organization.',
        })}
      />
      <Box mt={2}>
        <LinkButton
          size="sm"
          shape="rounded"
          variant="grey"
          prependWith={<Icon type="plus" />}
          to={`${match}${TO.ADD_CUSTOM_REQUEST_TYPE_MODAL[language]}`}
        >
          Add
        </LinkButton>
      </Box>

      <Flex sx={{ minHeight: '400px' }}>
        <React.Suspense
          fallback={
            <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
              <LoadingSpinnerCss size={4} />
            </Flex>
          }
        >
          {list && (
            <LazyStickyList
              name="CUSTOM_REQUEST_TYPE"
              list={list}
              columns={columns}
              showHeader
              onRowClick={handleOnRowClick}
            />
          )}
        </React.Suspense>
      </Flex>
      <Divider sx={{ mt: '0.75rem' }} />
    </Flex>
  );
};

export const MemoizedCustomRequestList = React.memo(CustomRequestList);
