import { Trans } from '@lingui/macro';
import React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { notificationCenterAtom } from 'state/notification';
import { Notification } from 'components/Notification/Notification';
import { Button } from 'components/ui/Buttons';
import { Drawer } from '../Drawer';

export const NotificationCenter = (): React.ReactElement => {
  const notifications = useRecoilValue(notificationCenterAtom);
  const resetNotifications = useResetRecoilState(notificationCenterAtom);

  return (
    <>
      <Drawer.Header>
        <Drawer.Title>
          <Trans id="drawer.header.notification_center">Notification Center</Trans>
        </Drawer.Title>
        {notifications.length > 0 && (
          <Button variant="grey" size="xs" shape="pill" sx={{ px: 2 }} onClick={resetNotifications}>
            <Trans id="drawer.header.notification_center.button.clean">Clean all</Trans>
          </Button>
        )}
      </Drawer.Header>

      <Drawer.Container>
        {notifications.length > 0 ? (
          notifications.map((n) => <Notification key={n.id} {...n} />)
        ) : (
          <Text variant="notificationCenter.message">
            <Trans id="drawer.header.notification_center.empty.message">There are no new notifications for now.</Trans>
          </Text>
        )}
      </Drawer.Container>
    </>
  );
};
