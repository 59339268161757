import React, { useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { To } from 'history';
import _ from 'lodash';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { selectAllAtomFamily, selectedRowsAtomFamily, selectedRowsIdsSelectorFamily } from 'state/list';
import { Button, LinkButton } from 'components/ui/Buttons';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Icon } from 'components/Icon/Icon';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { Modal } from 'components/Modal/Modal';
import { useModal } from 'hooks/useModal/useModal';
import { StickyList } from 'components/StickyList/StickyList';
import { StickyListProps } from 'components/StickyList/types';
import { useTheme } from 'styles/useTheme';
import { useUnmount } from 'hooks/useUnmount/useUnmount';

type Props = {
  title: string;
  modalLinksPaths: {
    add: string;
    delete: string;
  };
  listPropsGenerator: (
    match: string,
    replaceMode: boolean,
    preferredHistoryMethod: (to: To, state?: unknown) => void,
  ) => StickyListProps;
  modalRoutesRenderer: (match: string) => React.ReactNode;
  restrictedIds?: string[];
  allResources: Map<string, { id: string }> | null;
};

const defaultProps = {
  restrictedIds: undefined,
};

export const ManageModal = ({
  title,
  restrictedIds,
  listPropsGenerator,
  modalRoutesRenderer,
  modalLinksPaths,
  allResources,
}: Props): React.ReactElement => {
  useLingui();
  const match = useCleanRouteMatch();
  const { replaceMode, baseRoute } = useModal();
  const { push, replace } = useHistory();
  const { theme } = useTheme();
  const preferredHistoryMethod = useMemo(() => (replaceMode ? replace : push), [replaceMode, replace, push]);

  const listProps = useMemo(
    () => listPropsGenerator(match, replaceMode, preferredHistoryMethod),
    [listPropsGenerator, match, preferredHistoryMethod, replaceMode],
  );
  const selectedIds = useRecoilValue(selectedRowsIdsSelectorFamily(listProps.name));
  const resetSelectedIds = useResetRecoilState(selectedRowsAtomFamily(listProps.name));
  const resetGlobalSelect = useResetRecoilState(selectAllAtomFamily(listProps.name));

  useUnmount(() => {
    resetSelectedIds();
    resetGlobalSelect();
  });

  const filteredSelectedIds = useMemo(
    () => _.difference(selectedIds, restrictedIds || []).filter((id) => !!allResources?.get(id)),
    [selectedIds, restrictedIds, allResources],
  );

  const handleDelete = () => {
    preferredHistoryMethod(`${match}${modalLinksPaths.delete}`, {
      ids: filteredSelectedIds,
    });
  };

  if (!listProps.list) return <Redirect to={baseRoute} />;

  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ pt: 0, display: 'flex', flexDirection: 'column' }}>
        <ElementGroup showAsList marginValue={1}>
          <LinkButton
            replace={replaceMode}
            size="sm"
            variant="grey"
            shape="rounded"
            prependWith={<Icon type="plus" />}
            to={`${match}${modalLinksPaths.add}`}
          >
            <Trans id="team.manage_modal.add">Add</Trans>
          </LinkButton>
          <Button
            disabled={!filteredSelectedIds.length}
            size="sm"
            variant="grey"
            shape="rounded"
            prependWith={<Icon type="delete" />}
            onClick={handleDelete}
          >
            <Trans id="team.manage_modal.delete">Delete</Trans>
          </Button>
        </ElementGroup>
        <Flex sx={{ flex: '1 0', mx: -4, mb: '-.9rem' }}>
          <StickyList
            {...listProps}
            variant={listProps.variant || 'inverted'}
            style={{ ...listProps.style, padding: theme.space[4], paddingTop: 0 }}
          />
        </Flex>
      </Modal.Body>
      {modalRoutesRenderer(match)}
    </>
  );
};

ManageModal.defaultProps = defaultProps;
