export const getSrcSet = (baseSrc: string): string | undefined => {
  const baseSrcArr = [...baseSrc.split('.')];
  if (baseSrcArr.length < 2) {
    return undefined;
  }
  const extension = baseSrcArr.pop();

  const srcSetArr: string[] = [];
  const descriptors = ['2x', '3x'];

  descriptors.forEach((d) => {
    const srcWithDescriptor = `${baseSrcArr.join('.')}@${d}.${extension} ${d}`;
    srcSetArr.push(srcWithDescriptor);
  });

  return srcSetArr.join();
};
