import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'theme-ui';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';
import { useClient } from 'react-fetching-library';
import _ from 'lodash';
import { useLingui } from '@lingui/react';
import { t, Trans } from '@lingui/macro';

import { ListItem, StickyListProps } from 'components/StickyList/types';
import { Icon } from 'components/Icon/Icon';
import { InputOption, Select } from 'components/ui/Select/Select';
import { LinkButton } from 'components/ui/Buttons';
import { languageSelector } from 'state/recoilState';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { TO } from 'constants/routes';
import { LazyComponentType } from 'utils/custom.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { fetchHolidaysYearAction } from 'api/actions/holidays/holidaysActions';
import { Holiday } from 'api/actions/holidays/holidaysActions.types';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { Divider } from 'components/Divider/Divider';
import { refreshSettingAtomFamily } from 'state/settings';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { dateTime } from 'utils/dateTime';

type HolidayListProps = Omit<Holiday, 'isDefault'> & { isNonEditable: boolean };

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<HolidayListProps>>;

export const HolidayList = (): React.ReactElement => {
  useLingui();
  const { query } = useClient();
  const [holidaysListState, setHolidaysListState] = useRecoilState<{
    payload?: Holiday[];
    refresh?: (() => void) | undefined;
  } | null>(refreshSettingAtomFamily('HOLIDAYS_LIST'));
  const language = useRecoilValue(languageSelector);
  const match = useCleanRouteMatch();
  const history = useHistory();
  const currentYear = new Date().getFullYear();

  const [pickedYear, setPickedYear] = useState<string>(currentYear.toString());
  const [holidayList, setHolidayList] = useState<StickyListProps<HolidayListProps>['list'] | null>(null);

  const selectedYearRef = useRef<HTMLInputElement | null>(null);

  const getHolidaysList = useCallback(async () => {
    const { payload } = await query(fetchHolidaysYearAction({ year: pickedYear }));

    if (payload) {
      setHolidaysListState((prevState) =>
        !_.isEqual(prevState?.payload, payload) ? { ...prevState, payload } : prevState,
      );
    }
  }, [pickedYear, query, setHolidaysListState]);

  useEffect(() => {
    setHolidaysListState((prevState) => ({ ...prevState, refresh: getHolidaysList }));
  }, [getHolidaysList, setHolidaysListState]);

  useEffect(() => {
    getHolidaysList();
  }, [getHolidaysList]);

  const handleDelete = (id: string) => {
    history.push(`${match}${TO.DELETE_HOLIDAY_MODAL[language]}/${id}`);
  };

  const handlePropagationOnClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const columns: StickyListProps<HolidayListProps>['columns'] = [
    {
      key: 'dateUnix',
      title: t({ id: 'global.date', message: 'Date' }),
      sortableValue: (date: HolidayListProps['dateUnix']) => `${date}`,
      customCellRenderer: (dateUnix: HolidayListProps['dateUnix']) => {
        const displayDate = dateTime(dateUnix).format('MMM DD');
        const displayDateUpperCase = displayDate.replace(/^./, (str) => str.toUpperCase());

        return <TextEllipsis title={displayDateUpperCase}>{displayDateUpperCase}</TextEllipsis>;
      },
      columnGrow: 1,
    },
    {
      key: 'name',
      title: t({ id: 'global.forms.name', message: 'Name' }),
      sortableValue: (name: HolidayListProps['name']) => `${name}`,
      customCellRenderer: (name: HolidayListProps['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
      columnGrow: 2,
    },
    {
      key: ['id', 'isNonEditable'],
      width: '40px',
      sx: { overflow: 'visible' },
      customCellRenderer: (items: [HolidayListProps['id'], HolidayListProps['isNonEditable']]) =>
        !items[1] && (
          <Icon
            type="delete"
            wrapperSx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={(e) => {
              handlePropagationOnClick(e);
              handleDelete(items[0]);
            }}
          />
        ),
    },
  ];

  const handleOnBlur = () => {
    if (selectedYearRef.current && selectedYearRef.current.value) {
      setPickedYear(selectedYearRef.current.value);
    }
  };

  useEffect(() => {
    getHolidaysList();
  }, [getHolidaysList]);

  useEffect(() => {
    if (holidaysListState?.payload) {
      const newHolidayList: HolidayListProps[] = [];

      holidaysListState.payload.forEach((holiday) =>
        newHolidayList.push({
          id: holiday.id,
          name: holiday.name,
          dateUnix: holiday.dateUnix,
          isNonEditable: holiday.isDefault,
        }),
      );

      setHolidayList(newHolidayList);
    }
  }, [holidaysListState?.payload]);

  const handleOnRowClick = useCallback(
    (id: ListItem['id']) => {
      history.push(`${match}${TO.EDIT_HOLIDAY_MODAL[language]}/${id}`);
    },
    [history, language, match],
  );

  const pickYearOptions: InputOption[] = useMemo(
    () => [
      {
        label: currentYear.toString(),
        id: currentYear.toString(),
      },
      {
        label: (currentYear + 1).toString(),
        id: (currentYear + 1).toString(),
      },
    ],
    [currentYear],
  );

  return (
    <Flex sx={{ flexDirection: 'column', gap: 2, flexGrow: 1, mt: 3, maxWidth: '600px' }}>
      <OptionLabel
        label={t({ id: 'settings.holidays.list_title', message: 'List of holidays' })}
        additionalLabel={t({
          id: 'settings.holidays.list_description',
          message: 'Add custom dates or import from Google for the whole organization',
        })}
      />
      <Flex sx={{ gap: 2 }}>
        <Select
          ref={selectedYearRef}
          onBlur={handleOnBlur}
          id="year"
          placeholder={t({ id: 'global.year', message: 'Year' })}
          size="sm"
          options={pickYearOptions}
          defaultValue={pickedYear}
          sx={{ maxWidth: '80px' }}
        />
        <LinkButton
          size="sm"
          shape="rounded"
          variant="grey"
          prependWith={<Icon type="plus" />}
          to={`${match}${TO.ADD_HOLIDAY_MODAL[language]}`}
        >
          <Trans id="add" />
        </LinkButton>
        <LinkButton
          size="sm"
          shape="rounded"
          variant="grey"
          replace
          prependWith={<Icon type="download" />}
          to={`${match}${TO.IMPORT_HOLIDAYS_MODAL[language]}/${pickedYear}`}
        >
          <Trans id="settings.holidays.import_from_google">Import from Google</Trans>
        </LinkButton>
      </Flex>
      <Flex sx={{ minHeight: '360px' }}>
        <React.Suspense
          fallback={
            <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', height: '100px', width: '100%' }}>
              <LoadingSpinnerCss size={4} />
            </Flex>
          }
        >
          <LazyStickyList
            name="HOLIDAYS"
            list={holidayList || []}
            columns={columns}
            showHeader
            onRowClick={handleOnRowClick}
          />
        </React.Suspense>
      </Flex>
      <Divider />
    </Flex>
  );
};
