/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import { rest } from 'msw';
import _ from 'lodash';

import blazefaceModel from '../../public/tfjs_models/blazeface/model.json';
import facemeshModel from '../../public/tfjs_models/facemesh/model.json';
import { API_ENDPOINTS } from 'constants/api';
import { RefreshAccessTokenAccountTypeCodes } from 'api/actions/auth/authActions.types';
import AttendancePill from '../jsons/AttendancePill.json';
// import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { RefreshSessionCode } from 'observers/RefreshSessionObserver';
import MessagesResponse from '../jsons/MessagesResponse.json';
import MessageResponse from '../jsons/MessageResponse.json';
import ChatUsers from '../jsons/ChatUsers.json';
import {
  FetchReportActionProps,
  FetchReportResponse,
  ReportDayDataType,
  ReportSummaryType,
} from 'api/actions/reports/reportsActions.types';
import { dateTime } from 'utils/dateTime';
import { NormalPayrollPeriod } from 'api/actions/settings/settingsActions.types';

import {
  KIOSK,
  NOTIFICATIONS_SETTINGS,
  ORGANIZATION_SETTINGS,
  REQUEST_SETTINGS,
  SCHEDULES,
  SIGN_UP,
  TIME_TRACKING,
  EMPLOYEE_DETAILS,
  TAG_INHERITED_FEATURES,
  TAG_DETAILS,
  ROLE_DETAILS,
  IMPORT_HOLIDAYS_GOOGLE,
  IMPORT_LOCATIONS,
  HOLIDAYS_2021,
  HOLIDAYS_2022,
  HOLIDAYS_2023,
  LOCATIONS,
  SINGLE_LOCATION,
  FILE1,
  FILE2,
  TIME_TRACKER,
  EXTERNAL_PROVIDER,
  INTEGRATIONS,
  WEBHOOKS,
  notificationsSettings,
  USER_INFO,
  TIME_EVENTS,
  TIME_EVENT_HISTORY,
  TIME_CLOCKS,
} from './responses';
// import ORGANIZATION_SESSION_JSON from './jsonResponses/organizationSession.json';
import ORGANIZATION_SESSION_JSON from './jsonResponses/organizationSession_1k.json';
// import ORGANIZATION_SESSION_JSON from './jsonResponses/organizationSession_20k.json';
// import ORGANIZATION_SESSION_JSON from './jsonResponses/organizationSession_100k.json';
import USER_SESSION_JSON from './jsonResponses/userSession.json';
import { MOCK_REFRESH_TOKEN_SIGN_UP_IN_PROGRESS, MOCK_SIGN_UP_ERROR_INVITED } from './config';
import REQUESTS_LIST from './jsonResponses/requestsList.json';
import APPROVAL_LISTS from './jsonResponses/approvalLists.json';
import REQUEST_OVERVIEW from './jsonResponses/requestOverview.json';
import REQUEST_DETAILS from './jsonResponses/requestDetails.json';
import TIME_OFF_LIMITS from './jsonResponses/timeOffLimits.json';
import TIME_EVENTS_HISTORY from './jsonResponses/timeEventHistory.json';

const ACCESS_TOKEN = 'access_token_example';
const REFRESH_TOKEN = 'refresh_token_example';

let reportsData: FetchReportResponse | null = null;
let reportsStartDate: number | null = null;

const getCookie = (name: string): string | undefined => {
  const matches = document.cookie.match(
    // eslint-disable-next-line no-useless-escape
    new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const handlers = [
  rest.post(API_ENDPOINTS.authorize, (req, res, ctx) => {
    document.cookie = `refreshToken=${REFRESH_TOKEN}`;

    return res(
      ctx.status(200),
      ctx.json({
        accessToken: ACCESS_TOKEN,
      }),
    );
  }),

  rest.post(API_ENDPOINTS.signOut, (req, res, ctx) => {
    document.cookie = `refreshToken=`;
    return res(ctx.status(200));
  }),

  rest.post(API_ENDPOINTS.signUp, (req, res, ctx) => {
    document.cookie = `refreshToken=${REFRESH_TOKEN}`;
    if (MOCK_SIGN_UP_ERROR_INVITED) {
      return res(ctx.status(403), ctx.json(SIGN_UP.ERROR_INVITED));
    }
    return res(
      ctx.status(200),
      ctx.json({
        accessToken: ACCESS_TOKEN,
      }),
    );
  }),

  rest.get(API_ENDPOINTS.refreshAccessToken, (req, res, ctx) => {
    if (getCookie('refreshToken') === REFRESH_TOKEN) {
      const responseBody = {
        accessToken: ACCESS_TOKEN,
        accountType: MOCK_REFRESH_TOKEN_SIGN_UP_IN_PROGRESS
          ? RefreshAccessTokenAccountTypeCodes.SIGN_UP_IN_PROGRESS
          : '',
      };

      return res(ctx.status(200), ctx.json(responseBody));
    }
    return res(
      ctx.status(401),
      ctx.json({
        invalidRefreshTokenCookie: true,
      }),
    );
  }),
  rest.get(API_ENDPOINTS.userSession, (req, res, ctx) => {
    if (MOCK_REFRESH_TOKEN_SIGN_UP_IN_PROGRESS) {
      return res(ctx.status(401));
    }
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(USER_SESSION_JSON));
    }
    return res(ctx.status(401));
  }),

  rest.put(API_ENDPOINTS.userSession, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.tagInheritedFeatures, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TAG_INHERITED_FEATURES));
    }
    return res(ctx.status(401));
  }),

  rest.get(`${API_ENDPOINTS.employee}/1`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(EMPLOYEE_DETAILS));
    }
    return res(ctx.status(401));
  }),

  rest.get(`${API_ENDPOINTS.tag}/1`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TAG_DETAILS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.employeeManagement, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.role, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(`${API_ENDPOINTS.role}/5`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(`${API_ENDPOINTS.workPosition}/1`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.workPosition, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.delete(API_ENDPOINTS.workPosition, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.setWorkPositionsStates, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(`${API_ENDPOINTS.role}/5`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(ROLE_DETAILS));
    }
    return res(ctx.status(401));
  }),

  rest.delete(API_ENDPOINTS.role, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(`${API_ENDPOINTS.tagManagement}/1`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.tagManagement, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.delete(API_ENDPOINTS.tagManagement, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.viewsSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.setWorkPosition, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.setTags, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.setPermissions, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.setRole, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.setPayDetails, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.setTimeOffLimits, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.employeeActivate, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.employeeDeactivate, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.employeeInvite, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.printQr, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.set('Content-Type', 'application/pdf'), ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.delete(API_ENDPOINTS.employeeManagement, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.employeeManagement}/1`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.organizationSession, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      const response = {
        ...ORGANIZATION_SESSION_JSON,
        displayCurrency: 'PLN',
        payrollPeriod: NormalPayrollPeriod.Weeks2,
      };
      return res(ctx.status(200), ctx.json(response));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.accountInfo, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.invitationResend, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.invitationJoinRequest, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.timeZoneList, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(SIGN_UP.TIME_ZONE_LIST));
    }
    return res(ctx.status(401));
  }),
  rest.get(API_ENDPOINTS.industryList, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(SIGN_UP.INDUSTRY_LIST));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.schedulesSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(SCHEDULES));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.integrations, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(INTEGRATIONS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.schedulesSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.integrationsConnectPayroll, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.notificationSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(NOTIFICATIONS_SETTINGS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.notificationSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.advancedOrganizationSettingsDeletePhotos, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.advancedOrganizationSettingsDeleteFaceModels, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.advancedOrganizationSettingsDeleteOrganization, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.timeTrackingSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_TRACKING));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeTrackingSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.organizationSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(ORGANIZATION_SETTINGS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.organizationSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.organizationSettingsChangeAvatar, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.organizationSettingsDeleteAvatar, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.requestSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(REQUEST_SETTINGS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.requestSettings, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.integrationsDisconnect, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeOffType, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.externalProvider, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(EXTERNAL_PROVIDER));
    }
    return res(ctx.status(401));
  }),

  rest.delete(API_ENDPOINTS.timeOffType, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.webhook, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(WEBHOOKS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.updateTimeOffType, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.webhookAdd, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.setTimeOffTypesState, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.customRequestType, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.updateCustomRequestType, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.delete(API_ENDPOINTS.customRequestType, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.setCustomRequestTypesState, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.get(API_ENDPOINTS.importLocations, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(IMPORT_LOCATIONS));
    }
    return res(ctx.status(401));
  }),

  rest.get(`${API_ENDPOINTS.holiday}/2021`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(HOLIDAYS_2021));
    }
    return res(ctx.status(401));
  }),

  rest.get(`${API_ENDPOINTS.holiday}/2022`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(HOLIDAYS_2022));
    }
    return res(ctx.status(401));
  }),

  rest.get(`${API_ENDPOINTS.holiday}/2023`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(HOLIDAYS_2023));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.holiday, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.delete(API_ENDPOINTS.holiday, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.delete(`${API_ENDPOINTS.holiday}/0`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.importPreviewHolidays, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(IMPORT_HOLIDAYS_GOOGLE));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.importHolidays, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.webhookDelete, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  //
  //
  // FILE UPLOAD
  //
  //
  //
  rest.get(`${API_ENDPOINTS.file}/59caa210-fd81-4f3a-a1e1-99fb8cb03e01`, (req, res, ctx) => {
    if (req?.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(FILE1));
    }
    return res(ctx.status(401));
  }),
  rest.get(`${API_ENDPOINTS.file}/59caa210-fd81-4f3a-a1e1-99fb8cb03e02`, (req, res, ctx) => {
    if (req?.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(FILE2));
    }
    return res(ctx.status(401));
  }),
  rest.get(`${API_ENDPOINTS.file}/a09def36-8eda-46a5-92ff-1c8015fc15df`, (req, res, ctx) =>
    // if (req?.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
    //   return res(ctx.status(200), ctx.json(FILE3));
    // }
    res(ctx.status(401)),
  ),
  rest.post(API_ENDPOINTS.fileUpload, (req, res, ctx) => {
    if (req?.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      const { file } = req.body;
      return res(
        ctx.status(200),
        ctx.json({
          id: file.name + file.size,
          name: file.name,
          type: 14,
          size: file.size,
          url: 'www.test.test',
        }),
      );
    }
    return res(ctx.status(401));
  }),

  //
  //
  //
  // TIME CLOCK API ENDPOINTS
  //

  rest.get(API_ENDPOINTS.timeclock.getSettings, (req, res, ctx) => {
    if (req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(KIOSK.SETTINGS));
    }
    return res(ctx.status(403));
  }),

  rest.post(API_ENDPOINTS.timeclock.getInfo, (req, res, ctx) => {
    if (req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}` && req.body) {
      // return res(ctx.delay(300), ctx.status(403), ctx.json(KIOSK.GET_INFO_ERROR));
      return res.once(ctx.status(403), ctx.json(KIOSK.GET_INFO_ERROR));
    }

    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeclock.getInfo, (req, res, ctx) => {
    if (req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}` && req.body) {
      return res(ctx.status(200), ctx.json(KIOSK.GET_INFO));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeclock.initPersonModel, (req, res, ctx) => {
    if (req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}` && req.body) {
      return res(ctx.status(200), ctx.json(KIOSK.GET_INFO));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeclock.submitTimeEvent, (req, res, ctx) => {
    if (req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}` && req.body) {
      return res.once(ctx.status(403), ctx.json(KIOSK.SUBMIT_TIME_EVENT_ERROR));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeclock.submitTimeEvent, (req, res, ctx) => {
    if (req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}` && req.body) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  //
  // json files
  //

  rest.get('/tfjs_models/blazeface/model.json', (req, res, ctx) => res(ctx.json(blazefaceModel))),
  rest.get('/tfjs_models/facemesh/model.json', (req, res, ctx) => res(ctx.json(facemeshModel))),

  //
  // SETTINGS
  //

  //
  // USER
  //
  rest.post(API_ENDPOINTS.userNotifications, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.userNotifications, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(notificationsSettings));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.userChangePassword, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.userInfoChangeAvatar, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.set('x-refresh-session', `${RefreshSessionCode.ORGANIZATION}`));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.userInfoDeleteAvatar, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.set('x-refresh-session', `${RefreshSessionCode.ORGANIZATION}`));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.userPreferences, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.set('x-refresh-session', `${RefreshSessionCode.USER_AND_ORGANIZATION}`));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.userInfo, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(USER_INFO));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.userInfo, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  //
  // DRAWER
  //

  rest.get(API_ENDPOINTS.attendancePill, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(AttendancePill));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeTracker, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_TRACKER));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeEventSubmit, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  // ZUS
  rest.post(API_ENDPOINTS.zusConnect, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),
  rest.post(API_ENDPOINTS.zusCredentials, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  //
  // NOTIFICATIONS
  //

  rest.get(API_ENDPOINTS.notification, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res.once((ctx.status(200), ctx.json(NOTIFICATIONS_SETTINGS)));
    }
    return res(ctx.status(401));
  }),

  //
  // CHAT
  //

  rest.get(API_ENDPOINTS.chat.chatUsers, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(ChatUsers));
    }
    return res(ctx.status(401));
  }),

  rest.get(API_ENDPOINTS.chat.messages, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(MessagesResponse));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.chat.message, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(MessageResponse));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.chat.deleteMessage, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.chat.hasBeenRead, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  //
  // WORK STATUSES
  //

  rest.post(API_ENDPOINTS.workStatuses, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204), ctx.set('x-refresh-session', `${RefreshSessionCode.ORGANIZATION}`));
    }
    return res(ctx.status(401));
  }),

  rest.post(`${API_ENDPOINTS.workStatuses}/1840417b-98b8-483f-9c60-92eacaa30075`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204), ctx.set('x-refresh-session', `${RefreshSessionCode.ORGANIZATION}`));
    }
    return res(ctx.status(401));
  }),

  rest.delete(API_ENDPOINTS.workStatuses, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204), ctx.set('x-refresh-session', `${RefreshSessionCode.ORGANIZATION}`));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.workStatuses, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204), ctx.set('x-refresh-session', `${RefreshSessionCode.ORGANIZATION}`));
    }
    return res(ctx.status(401));
  }),

  //
  //
  // Requests
  //
  //

  rest.get(API_ENDPOINTS.requests, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res.once((ctx.status(200), ctx.json(REQUESTS_LIST)));
    }
    return res(ctx.status(401));
  }),
  rest.get(`${API_ENDPOINTS.getApprovalLists}/:personId/:requestType`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res.once((ctx.status(200), ctx.json(APPROVAL_LISTS)));
    }
    return res(ctx.status(401));
  }),

  rest.get(`${API_ENDPOINTS.requestOverview}/:year`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res.once((ctx.status(200), ctx.json(REQUEST_OVERVIEW)));
    }
    return res(ctx.status(401));
  }),

  rest.get(`${API_ENDPOINTS.requests}/:id`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res.once((ctx.status(200), ctx.json(REQUEST_DETAILS)));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.addRequest, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.deleteRequestManagement, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.rejectRequestManagement, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.acceptRequestManagement, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeOffRemainingLimits, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_OFF_LIMITS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.customRemainingLimits, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_OFF_LIMITS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.availableOvertime, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_OFF_LIMITS));
    }
    return res(ctx.status(401));
  }),

  rest.post(API_ENDPOINTS.timeEventsHistory, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_EVENTS_HISTORY));
    }
    return res(ctx.status(401));
  }),
  // locations
  //

  rest.get(API_ENDPOINTS.location, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res((ctx.status(200), ctx.json(LOCATIONS)));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.addLocation}`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.editLocation}/180f32b8-86d0-41f8-9eab-b1cb9c25626e`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.delete(API_ENDPOINTS.deleteLocation, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.get(`${API_ENDPOINTS.singleLocation}/180f32b8-86d0-41f8-9eab-b1cb9c25626e`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res((ctx.status(200), ctx.json(SINGLE_LOCATION)));
    }
    return res(ctx.status(401));
  }),

  //
  //
  // TimeEvents (Clock Log)
  //
  //

  rest.post(API_ENDPOINTS.timeEvent.getTimeEvents, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res((ctx.status(200), ctx.json(TIME_EVENTS)));
    }
    return res(ctx.status(401));
  }),
  rest.delete(API_ENDPOINTS.timeEvent.deleteTimeEvents, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.get(`${API_ENDPOINTS.timeEventHistory}/9ce0ed2c-17ed-4c7b-937d-82c667791021`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_EVENT_HISTORY));
    }
    return res(ctx.status(401));
  }),
  rest.get(`${API_ENDPOINTS.timeEventHistory}/9ce0ed2c-17ed-4c7b-937d-82c667791022`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_EVENT_HISTORY));
    }
    return res(ctx.status(401));
  }),
  rest.get(`${API_ENDPOINTS.timeEventHistory}/9ce0ed2c-17ed-4c7b-937d-82c667791023`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(200), ctx.json(TIME_EVENT_HISTORY));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.editTimeEvent}/9ce0ed2c-17ed-4c7b-937d-82c667791021`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.editTimeEvent}/9ce0ed2c-17ed-4c7b-937d-82c667791022`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.editTimeEvent}/9ce0ed2c-17ed-4c7b-937d-82c667791023`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  // REPORTS
  //
  //

  rest.post(API_ENDPOINTS.report, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      const { body } = req;
      const { endDateUnix, startDateUnix } = body as FetchReportActionProps;

      const diff = dateTime(endDateUnix).diff(dateTime(startDateUnix), 'day');
      const totalDays = diff + 1;
      const startDate = dateTime(startDateUnix, { disableTimezone: true }).startOf('day');

      const holidays: FetchReportResponse['holidays'] = _.times(3, (i) => [
        +startDate.add(_.random(diff), 'day').unix(),
        `Holiday_${i}`,
      ]);

      const randomizer = (max: number) => (_.random(1) > 0 ? _.random(max) : 0);
      const doubleRandom = (max: number) => randomizer(randomizer(max));

      const generateDayReport = (index: number): ReportDayDataType => ({
        errors: _.times(doubleRandom(2), () => _.random(0, 11)),
        workTime: doubleRandom(index) * 60 * 60 * 60,
        overtime: doubleRandom(index) * 60 * 60 * 60,
        nightTime: doubleRandom(index) * 60 * 60 * 60,
        withoutSchedule: doubleRandom(index) * 60,
        missingWorkTime: doubleRandom(index) * 60,
        autoBreak: doubleRandom(index) * 60,
        payroll: {
          regular: doubleRandom(index) * 10,
          overtime: doubleRandom(index) * 10,
          total: doubleRandom(index) * 10,
        },
        // BALANCED
        autoBalance:
          doubleRandom(index) > 0
            ? {
                available: _.random(index + 7) * 60 * 60,
                given: _.times(_.random(3), (i) => ({
                  dateUnix: startDate.add(index * (i + 2), 'day').unix(),
                  duration: _.random(1, 6) * 60 * 60,
                })),
                taken: _.times(_.random(3), (i) => ({
                  dateUnix: startDate.add(index * (i + 2), 'day').unix(),
                  duration: _.random(1, 6) * 60 * 60,
                })),
              }
            : undefined,
        // REQUESTS
        requests: _.times(doubleRandom(30), (i) => {
          const genType = () => {
            const type = _.random(3);
            const timeEventTypeIds = _.map(ORGANIZATION_SESSION_JSON.timeEventTypes, (timeEvent) => timeEvent.id);
            const timeOffTypeIds = _.map(ORGANIZATION_SESSION_JSON.timeOffTypes, (timeOff) => timeOff.id);
            const customRequestTypeIds = _.map(
              ORGANIZATION_SESSION_JSON.customRequestTypes,
              (customReq) => customReq.id,
            );

            // TimeTracking
            if (type === 1) {
              return {
                type,
                typeId: _.sample(timeEventTypeIds),
              };
            }
            // BusinessTrip
            if (type === 2) {
              return {
                type,
                typeDescription: `BUSINESS_TRIP_${type * _.random(10)}`,
                duration: {
                  dayCount: _.random(1, 8),
                  seconds: _.random(1, 8) * 60 * 60,
                },
              };
            }
            // CustomRequest
            if (type === 3) {
              return {
                type,
                typeId: _.sample(customRequestTypeIds),
                duration: {
                  dayCount: _.random(1, 8),
                  seconds: _.random(1, 8) * 60 * 60,
                },
              };
            }

            // TimeOff
            // type === 0
            return {
              type,
              typeId: _.sample(timeOffTypeIds),
              duration: {
                dayCount: _.random(1, 8),
                seconds: _.random(1, 8) * 60 * 60,
              },
            };
          };

          return {
            id: `${i}`,
            number: `2022/${i * _.random(20, 50)}`,
            actionType: _.random(2),
            state: _.random(2),
            ...genType(),
          };
        }),
        // SCHEDULES
        schedules: _.times(doubleRandom(20), (i) => ({
          id: `${i}`,
          ...(doubleRandom(1) > 0
            ? {
                isDateBound: true,
                workTimeUnix: doubleRandom(1) > 0 ? 8 * 60 * 60 : 2 * 60 * 60,
              }
            : {
                isDateBound: false,
                startDateTimeUnix: _.random(1, index + 3) * 60 * 60 * 60,
                endDateTimeUnix: _.random(1, index + 3) * 60 * 60 * 60,
                workTimeUnix: doubleRandom(1) > 0 ? 8 * 60 * 60 : index * 60 * 60,
              }),
          color: _.random(1, 20),
        })),

        clockLog:
          _.random(index + 3) > 0
            ? _.times(doubleRandom(9), (i) => {
                const genTypeId = () => {
                  const timeEventTypeIds = _.map(ORGANIZATION_SESSION_JSON.timeEventTypes, (timeEvent) => timeEvent.id);

                  return {
                    typeId: _.sample(timeEventTypeIds),
                    isEndStatus: _.random(1) > 0,
                  };
                };

                return {
                  id: `${i}`,
                  timeUtc: _.random(1, index + 3) * 60 * 60 * 60,
                  timeRealUtc: _.random(index + 3) * 60 * 60 * 60,
                  photoUrl: _.random(1) > 0 ? `https://picsum.photos/40` : undefined,
                  ...genTypeId(),
                };
              })
            : undefined,
        workStatuses:
          _.random(3) > 0
            ? _.chain(_.map(ORGANIZATION_SESSION_JSON.timeEventTypes, (timeEvent) => timeEvent.id))
                .sampleSize(3)
                .map((id) => ({
                  id,
                  duration: _.random(1, 8) * 60 * 60,
                }))
                .value()
            : undefined,
      });

      const generateDays = (times: number) =>
        _.times(times, (i) => [
          +startDate.add(i + 1, 'day').unix(),
          {
            ...generateDayReport(i),
          },
        ]);

      const generateSummaryReport = (): ReportSummaryType => ({
        scheduledTimeDuration: _.random(30) * 8 * 60 * 60,
        timers: {
          ...(_.random(3) > 0
            ? {
                autoBreakDuration: _.random(30) * 0.5 * 60 * 60,
                workTimeDuration: _.random(30) * 8 * 60 * 60,
                overtimeDuration: _.random(30) * 0.5 * 60 * 60,
                missingWorkTimeDuration: _.random(30) * 0.25 * 60 * 60,
                nightTimeDuration: _.random(30) * 60 * 60,
                withoutScheduleDuration: _.random(30) * 60 * 60,
              }
            : undefined),
        },
        payroll:
          _.random(3) > 0
            ? {
                regular: 20 * 8 * _.random(30),
                overtime: 20 * 2 * _.random(30),
                total: 20 * 10 * _.random(30),
              }
            : undefined,
        dayStates:
          _.random(3) > 0
            ? {
                absentCount: _.random(30),
                businessTripCount: _.random(30),
                customRequestCount: _.random(30),
                incompleteShiftCount: _.random(30),
                overtimeCount: _.random(30),
                presentCount: _.random(30),
                timeOffCount: _.random(30),
              }
            : undefined,
        timeOffs:
          _.random(3) > 0
            ? _.chain(_.map(ORGANIZATION_SESSION_JSON.timeOffTypes, (timeOff) => timeOff.id))
                .sampleSize(10)
                .map((id) => ({
                  id,
                  duration: {
                    dayCount: _.random(1, 30),
                    seconds: _.random(1, 30) * 8 * 60 * 60,
                  },
                }))
                .value()
            : undefined,
        customRequests:
          _.random(3) > 0
            ? _.chain(_.map(ORGANIZATION_SESSION_JSON.customRequestTypes, (custom) => custom.id))
                .sampleSize(3)
                .map((id) => ({
                  id,
                  duration: {
                    dayCount: _.random(1, 30),
                    seconds: _.random(1, 30) * 8 * 60 * 60,
                  },
                }))
                .value()
            : undefined,
        workStatuses:
          _.random(3) > 0
            ? _.chain(_.map(ORGANIZATION_SESSION_JSON.timeEventTypes, (timeEvent) => timeEvent.id))
                .sampleSize(3)
                .map((id) => ({
                  id,
                  duration: _.random(1, 8) * 60 * 60,
                }))
                .value()
            : undefined,
      });

      const employeesData: FetchReportResponse['employeesData'] = _.times(100, (i) => [
        `${i}`,
        {
          hasErrors: _.random(1) > 0,
          days: _.random(3) > 0 ? generateDays(_.random(3, totalDays)) : undefined,
          summary: generateSummaryReport(),
        },
      ]);

      const report: FetchReportResponse = {
        holidays,
        employeesData,
      };

      console.log(report);

      if (!reportsData || reportsStartDate !== startDateUnix) {
        reportsData = report;
        reportsStartDate = startDateUnix;
      }

      return res((ctx.status(200), ctx.json(reportsData)));
    }
    return res(ctx.status(401));
  }),

  //
  //
  // TimeClocks
  //
  //

  rest.get(API_ENDPOINTS.device, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res((ctx.status(200), ctx.json(TIME_CLOCKS)));
    }
    return res(ctx.status(401));
  }),
  rest.delete(API_ENDPOINTS.device, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.device}/1234567`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.device}/1234568`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),
  rest.post(`${API_ENDPOINTS.device}/1234569`, (req, res, ctx) => {
    if (req.headers && req.headers.get('authorization') === `Bearer ${ACCESS_TOKEN}`) {
      return res(ctx.status(204));
    }
    return res(ctx.status(401));
  }),

  // TMP
  // IMAGES for avatars
  // rest.get('**avatarfiles.alphacoders**', (req, res, ctx) => res(ctx.json({}))),
];
