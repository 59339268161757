import { Action } from 'react-fetching-library';

import { FETCH_TIMEOUT_EXCEEDED_RESPONSE_STATUS, FETCH_TIMEOUT_THRESHOLD } from 'constants/common';

const fetchPromiseTimeout = (ms: number, promise: Promise<Response>, controller: AbortController): Promise<Response> =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      controller.abort();
      const timeoutExceededResponse = new Response(null, { status: FETCH_TIMEOUT_EXCEEDED_RESPONSE_STATUS });
      resolve(timeoutExceededResponse);
    }, ms);

    promise
      .then((value) => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch((reason) => {
        clearTimeout(timer);
        reject(reason);
      });
  });

export const buildCustomFetch =
  () =>
  async (init: RequestInfo, options?: Partial<Action> & RequestInit): Promise<Response> => {
    const url = init as string;
    const body = options && options.body;
    const customFetchTimeout = options?.config?.customFetchTimeout;

    const config: RequestInit = {
      ...options,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        ...(options && options.headers),
      },
      body,
    };

    const controller = new AbortController();
    return fetchPromiseTimeout(
      customFetchTimeout || FETCH_TIMEOUT_THRESHOLD,
      fetch(url, { ...config, signal: controller.signal }),
      controller,
    ).then(async (response) => response);
  };
