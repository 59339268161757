import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Flex } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useMutation } from 'react-fetching-library';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon } from 'components/Icon/Icon';
import { LinkButton } from 'components/ui/Buttons/LinkButton';
import { TO } from 'constants/routes';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { languageSelector } from 'state/recoilState';
import { timeOffTypesSelector } from 'state/organizationSession';
import { ListItem, StickyListProps } from 'components/StickyList/types';
import { TimeOffType, TimeOffTypes } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Switch } from 'components/ui/Switch';
import { Checkbox } from 'components/ui/Checkbox';
import { SetTimeOffTypesStateActionProps, TimeOffTypeState } from 'api/actions/timeoff/timeOffActions.types';
import { setTimeOffTypesStateAction } from 'api/actions/timeoff/timeOffActions';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { LazyComponentType } from 'utils/custom.types';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { Divider } from 'components/Divider/Divider';

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<TimeOffType>>;

export const TimeOffList = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const match = useCleanRouteMatch();
  const history = useHistory();
  const timeOffTypes: StickyListProps<TimeOffType>['list'] | null = useRecoilValue(timeOffTypesSelector);
  const { mutate } = useMutation(setTimeOffTypesStateAction);

  const [list, setList] = useState<StickyListProps<TimeOffType | { isNonEditable: boolean }>['list'] | null>(null);
  const [timeOffTypesStates, setTimeOffTypesStates] = useState<TimeOffTypeState[]>([]);
  const timeOffTypesStatesRef = useRef<TimeOffTypeState[]>([]);

  const handlePropagationOnClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const handleDeleteOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      handlePropagationOnClick(e);
      history.push(`${match}${TO.DELETE_TIME_OFF_TYPE_MODAL[language]}`, { ids: [id] });
    },
    [handlePropagationOnClick, history, language, match],
  );

  const handleSwitchOnChange = useMemo(
    () =>
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const timeOffTypeState: TimeOffTypeState = {
          id: e.target.name,
          state: e.target.checked,
        };
        const filteredTimeOffTypesStates = timeOffTypesStatesRef.current.filter(
          ({ id: currentId }) => currentId !== timeOffTypeState.id,
        );
        const newTimeOffTypesStates = [...filteredTimeOffTypesStates, timeOffTypeState];

        setTimeOffTypesStates(newTimeOffTypesStates);
        timeOffTypesStatesRef.current = newTimeOffTypesStates;
      }, 200),
    [],
  );

  const debounceMutateTimeOffTypesStates = useMemo(
    () =>
      _.debounce(() => {
        const mutateData: SetTimeOffTypesStateActionProps = {
          timeOffTypesStates: timeOffTypesStatesRef.current,
        };

        mutate(mutateData);
      }, 200),
    [mutate],
  );

  useEffect(() => {
    if (timeOffTypesStates.length) {
      debounceMutateTimeOffTypesStates();
    }
  }, [debounceMutateTimeOffTypesStates, timeOffTypesStates]);

  const columns: StickyListProps<TimeOffType>['columns'] = useMemo(
    () => [
      {
        key: 'isActive',
        title: 'Active',
        width: '40px',
        customCellRenderer: (isActive: TimeOffType['isActive'], id: TimeOffType['id']) => (
          <Flex onClick={handlePropagationOnClick}>
            <Switch name={id} defaultChecked={isActive} size="sm" onChange={handleSwitchOnChange} />
          </Flex>
        ),
      },
      {
        key: 'abbreviation',
        title: 'Abbrev.',
        width: '60px',
        sortableValue: (abbreviation: TimeOffType['abbreviation']) =>
          t({
            id: abbreviation,
          }),
        customCellRenderer: (abbreviation: TimeOffType['abbreviation']) => (
          <TextEllipsis
            title={t({
              id: abbreviation,
            })}
          >
            <Trans id={abbreviation} />
          </TextEllipsis>
        ),
      },
      {
        key: 'name',
        title: 'Name',
        sortableValue: (name: TimeOffType['name']) =>
          t({
            id: name,
          }),
        customCellRenderer: (name: TimeOffType['name']) => (
          <TextEllipsis
            title={t({
              id: name,
            })}
          >
            <Trans id={name} />
          </TextEllipsis>
        ),
      },
      {
        key: 'isPaid',
        title: 'Paid',
        width: '40px',
        customCellRenderer: (isPaid: TimeOffType['isPaid']) => (
          <Checkbox name="placeholder" checked={isPaid} size="sm" disabled />
        ),
      },
      {
        key: 'isLimitable',
        title: 'Limitable',
        width: '40px',
        customCellRenderer: (isLimitable: TimeOffType['isLimitable']) => (
          <Checkbox name="placeholder" checked={isLimitable} size="sm" disabled />
        ),
      },
      {
        key: 'id',
        width: '40px',
        customCellRenderer: (id: TimeOffType['id']) =>
          !_.includes(TimeOffTypes, id) ? (
            <Flex
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleDeleteOnClick(e, id)}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <Icon type="delete" />
            </Flex>
          ) : null,
      },
    ],
    [handleDeleteOnClick, handleSwitchOnChange, handlePropagationOnClick],
  );

  const handleOnRowClick = useCallback(
    (id: ListItem['id']) => {
      history.push(`${match}${TO.EDIT_TIME_OFF_TYPE_MODAL[language]}/${id}`);
    },
    [history, language, match],
  );

  useEffect(() => {
    const setListAsync = async () => {
      if (timeOffTypes) {
        const newList: StickyListProps<TimeOffType | { isNonEditable: boolean }>['list'] = [];

        timeOffTypes.forEach((item) =>
          newList.push({
            ...item,
            isNonEditable: _.includes(TimeOffTypes, item.id),
          }),
        );

        setList(newList);
      }
    };

    setListAsync();
  }, [timeOffTypes]);

  return (
    <Flex sx={{ flexDirection: 'column', mt: '0.75rem' }}>
      <OptionLabel
        label={t({
          id: 'requests_settings.texts.time_off',
          message: 'Time off request types',
        })}
        additionalLabel={t({
          id: 'requests_settings.texts.time_off.additional_label',
          message: 'Add custom types of time off or set the active/available types for the whole organization.',
        })}
      />
      <Box mt={2}>
        <LinkButton
          size="sm"
          shape="rounded"
          variant="grey"
          prependWith={<Icon type="plus" />}
          to={`${match}${TO.ADD_TIME_OFF_TYPE_MODAL[language]}`}
        >
          Add
        </LinkButton>
      </Box>
      {list && (
        <Flex sx={{ minHeight: '400px' }}>
          <React.Suspense
            fallback={
              <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <LoadingSpinnerCss size={4} />
              </Flex>
            }
          >
            <LazyStickyList
              name="TIME_OFF_TYPE_LIST"
              list={list}
              columns={columns}
              showHeader
              onRowClick={handleOnRowClick}
            />
          </React.Suspense>
        </Flex>
      )}
      <Divider sx={{ mt: '0.75rem' }} />
    </Flex>
  );
};

export const MemoizedTimeOffList = React.memo(TimeOffList);
