import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Location } from 'history';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Flex, Link } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';

import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { useBlockRouteTransition } from 'hooks/useBlockTransition/useBlockRouteTransition';
import { Switch } from 'components/ui/Switch';
import { FetchScheduleSettingsResponse, ScheduleSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { scheduleSettingsAction } from 'api/actions/settings/settingsActions';
import { createEvent } from 'utils/createEvent';
import { wrapperLinkSx } from '../styles/wrappers';

import { TIMER_END_TIME, TIMER_INTERVAL } from './constants';

type Props = {
  payload: FetchScheduleSettingsResponse;
};

type keys = keyof FetchScheduleSettingsResponse;

export const SchedulesForm = ({ payload }: Props): React.ReactElement => {
  useLingui();
  const history = useHistory();
  const setPayload = useSetRecoilState<FetchScheduleSettingsResponse>(payloadSelectorFamily('SCHEDULES'));
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const { mutate } = useMutation(scheduleSettingsAction);

  const showLaborCodeAlertsLink = useHelpLink({
    inEwi: { pl: '/article/alerty-kodeksu-pracy-odpoczynek-dobowy-i-tygodniowy-1qkdi39/' },
    tracktime24: { en: '/' },
  });

  const mutationDataRef = useRef<FetchScheduleSettingsResponse>(payload);
  const formRef = useRef<HTMLFormElement | null>(null);
  const blockLocationPathnameRef = useRef<string | null>(null);
  const shouldBlockTransitionRef = useRef<boolean>(false);
  const formPendingRef = useRef<boolean>(false);
  const statusRef = useRef<Status>('STOPPED');

  const {
    register,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues: payload,
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const dispatchSubmitEvent = useCallback(() => {
    const submitEvent = createEvent('submit');
    formPendingRef.current = true;
    formRef.current?.dispatchEvent(submitEvent);
  }, []);

  const { start, reset, status } = useTimer({
    endTime: TIMER_END_TIME,
    interval: TIMER_INTERVAL,
    onTimeOver: () => dispatchSubmitEvent(),
  });

  const handleOnChange = () => {
    shouldBlockTransitionRef.current = true;
    blockLocationPathnameRef.current = null;
    reset();
    start();
  };

  const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
    const { onChange, ...restRegister } = register(registerName, registerOptions);

    return {
      ...restRegister,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        handleOnChange();
      },
    };
  };

  const handleFormReset = useCallback(() => {
    resetForm({ ...payload });
    shouldBlockTransitionRef.current = false;
    formPendingRef.current = false;
  }, [payload, resetForm]);

  const handleOnSubmitCallback = useCallback(
    async (data: ScheduleSettingsActionProps) => {
      if (!_.isEqual(data, payload)) {
        const { error } = await mutate(data);
        const schedulesResetObject = { name: 'SCHEDULES_CALLBACK', callback: handleFormReset };

        if (!error) {
          setResetCallbacks(null);
          setPayload(data);
          mutationDataRef.current = data;
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_success',
            }),
            variant: 'success',
          });
        } else {
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_fail',
            }),
            variant: 'danger',
          });
          setResetCallbacks((prevState) =>
            !prevState ? [schedulesResetObject] : [...prevState, schedulesResetObject],
          );
          return;
        }
      }

      formPendingRef.current = false;
      shouldBlockTransitionRef.current = false;
      if (blockLocationPathnameRef.current) {
        history.push(blockLocationPathnameRef.current);
      }
    },
    [handleFormReset, history, mutate, payload, setPayload, setResetCallbacks],
  );

  useEffect(() => {
    if (!_.isEqual(mutationDataRef.current, payload)) handleFormReset();
  }, [handleFormReset, payload]);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

  const validatePageTransitionCallback = useCallback(
    (location: Location) => {
      blockLocationPathnameRef.current = location.pathname;

      if (statusRef.current === 'RUNNING') {
        reset();
        dispatchSubmitEvent();
      }

      if (shouldBlockTransitionRef.current || formPendingRef.current) return false;

      return true;
    },
    [dispatchSubmitEvent, reset],
  );

  useBlockRouteTransition(validatePageTransitionCallback);

  return (
    <form ref={formRef} onSubmit={handleSubmit(handleOnSubmitCallback)}>
      <Flex sx={{ flexDirection: 'column', gap: '0.75rem', maxWidth: '600px' }}>
        <Switch
          {...registerOnChange('showLaborCodeAlerts')}
          label={t({
            id: 'schedules_settings.show_labor_code_alerts.label',
            message: 'Show Labor Code alerts',
          })}
          additionalInfo={
            <>
              <Link href={showLaborCodeAlertsLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                <Trans id="settings.learn_more">Learn more</Trans>
              </Link>
              .
            </>
          }
          bold
          size="sm"
          withDivider
        />
        <Switch
          {...registerOnChange('showVersionControl')}
          label={t({
            id: 'schedules_settings.show_version_control.label',
            message: 'Show version control',
          })}
          additionalInfo={t({
            id: 'schedules_settings.show_version_control.additional_info',
            message: 'Managers and Administrators will be able to see through all changes in time in schedules.',
          })}
          bold
          size="sm"
          withDivider
        />
      </Flex>
    </form>
  );
};

export const MemoizedSchedulesForm = React.memo(SchedulesForm);
