/** @jsxImportSource theme-ui */

import React, { useCallback, useEffect, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui';
import { useForm, Resolver } from 'react-hook-form';
import _ from 'lodash';
import { useLingui } from '@lingui/react';

import { TextInput } from 'components/ui/TextInput';
import { EmployeeEmploymentDetailsFormState } from 'state/team';
import { getNumberField } from 'utils/getNumberField';
import { getTextField } from 'utils/getTextField';
import { LimitPeriods, periodsMax } from 'api/actions/organizationSession/organizationSessionActions.types';
import { validationFactory, validationResolver, VALIDATION_RULES } from 'constants/validationRules';

const LazyTimeOffLimitsGroupFieldArray = React.lazy(() =>
  import('./formsElements/TimeOffLimitsGroupFieldArray').then(({ TimeOffLimitsGroupFieldArray }) => ({
    default: TimeOffLimitsGroupFieldArray,
  })),
);
const LazyWorkdayDurationsFieldArray = React.lazy(() =>
  import('./formsElements/WorkdayDurationsFieldArray').then(({ WorkdayDurationsFieldArray }) => ({
    default: WorkdayDurationsFieldArray,
  })),
);
const LazyRequestLimitsGroupFieldArray = React.lazy(() =>
  import('./formsElements/RequestLimitsGroupFieldArray').then(({ RequestLimitsGroupFieldArray }) => ({
    default: RequestLimitsGroupFieldArray,
  })),
);
const LazyRatesFieldArray = React.lazy(() =>
  import('./formsElements/RatesFieldArray').then(({ RatesFieldArray }) => ({
    default: RatesFieldArray,
  })),
);

const fieldGroupSx: ThemeUIStyleObject = {
  mt: 5,
};

const forceCorrectTypes = ({
  rates,
  timeOffLimits,
  customRequestsLimits,
  workdayDurations,
  customEmployeeId,
  ...restValues
}: Partial<EmployeeEmploymentDetailsFormState>): EmployeeEmploymentDetailsFormState => ({
  ...restValues,
  rates: rates?.map(({ normal, overtime, startDateUnix, ...rate }) => ({
    ...rate,
    startDateUnix: +startDateUnix,
    normal: getNumberField(normal),
    overtime: getNumberField(overtime),
  })),
  timeOffLimits: timeOffLimits?.map(({ limits, fromYear, ...limitGroup }) => ({
    ...limitGroup,
    fromYear: +fromYear,
    limits: limits.map(({ days, ...limit }) => ({
      ...limit,
      days: getNumberField(days),
    })),
  })),
  customRequestsLimits: customRequestsLimits?.map(({ limits, fromYear, ...limitGroup }) => ({
    ...limitGroup,
    fromYear: +fromYear,
    limits: limits.map(({ days, period, ...limit }) => ({
      ...limit,
      period: getNumberField(period) as LimitPeriods,
      days: getNumberField(days),
    })),
  })),
  workdayDurations: workdayDurations?.map(({ workdayDurationSeconds, fromYear, ...rest }) => ({
    workdayDurationSeconds: +workdayDurationSeconds,
    fromYear: +fromYear,
    ...rest,
  })),
  customEmployeeId: getTextField(customEmployeeId),
});

type Props = {
  setLoading: (isLoading: boolean) => void;
  defaultValues: Partial<EmployeeEmploymentDetailsFormState>;
  onSubmit: (props: EmployeeEmploymentDetailsFormState) => Promise<boolean>;
  onSubmitError?: () => void;
  saveFormState?: (formState: Partial<EmployeeEmploymentDetailsFormState>) => void;
  scrollParentRef: React.MutableRefObject<HTMLDivElement | null>;
};

const defaultProps = {
  saveFormState: undefined,
  onSubmitError: undefined,
};

export const UserEmploymentDetails = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, onSubmitError, defaultValues, saveFormState, setLoading, scrollParentRef }: Props, ref) => {
    useLingui();
    const {
      trigger,
      getValues,
      control,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<EmployeeEmploymentDetailsFormState>({
      defaultValues,
      mode: 'onTouched',
      reValidateMode: 'onChange',
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resolver,
    });
    const handleSubmitCallback = useCallback(
      (props) => {
        onSubmit(forceCorrectTypes(props));
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
      if (onSubmitError) {
        onSubmitError();
      }
    }, [setLoading, onSubmitError]);

    const fieldArrayProps = useMemo(
      () => ({
        register,
        control,
      }),
      [register, control],
    );

    useEffect(
      () => () => {
        if (saveFormState) {
          saveFormState(forceCorrectTypes(getValues()));
        }
      },
      [saveFormState, getValues],
    );

    return (
      <form
        sx={{ width: '100%' }}
        ref={ref}
        onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)}
        noValidate
      >
        <Heading mb={0} variant="heading4">
          <Trans id="team.user.employment.id">Employee ID</Trans>
        </Heading>
        <Text sx={{ color: 'texts.lighter', fontSize: 2 }}>
          <Trans id="team.user.employment.id.info">Mostly used by external Payroll systems.</Trans>
        </Text>
        <Flex sx={{ flexDirection: 'column' }}>
          <Flex sx={{ width: '100%', mt: 2 }}>
            <TextInput
              size="sm"
              id="customEmployeeId"
              placeholder={t({ id: 'team.user.employment.id.ex', message: 'for ex. 123-qwe' })}
              variant="rounded"
              error={!!errors.customEmployeeId}
              errorMessage={errors?.customEmployeeId?.message}
              {...register('customEmployeeId')}
            />
          </Flex>

          <React.Suspense fallback={<></>}>
            <LazyWorkdayDurationsFieldArray sx={fieldGroupSx} {...fieldArrayProps} scrollParentRef={scrollParentRef} />
          </React.Suspense>

          <React.Suspense fallback={<></>}>
            <LazyTimeOffLimitsGroupFieldArray
              {...fieldArrayProps}
              sx={fieldGroupSx}
              scrollParentRef={scrollParentRef}
            />
          </React.Suspense>

          <React.Suspense fallback={<></>}>
            <LazyRequestLimitsGroupFieldArray
              {...fieldArrayProps}
              sx={fieldGroupSx}
              trigger={trigger}
              scrollParentRef={scrollParentRef}
            />
          </React.Suspense>

          <React.Suspense fallback={<></>}>
            <LazyRatesFieldArray {...fieldArrayProps} sx={fieldGroupSx} scrollParentRef={scrollParentRef} />
          </React.Suspense>
        </Flex>
      </form>
    );
  },
);

UserEmploymentDetails.defaultProps = defaultProps;

const resolver: Resolver<EmployeeEmploymentDetailsFormState> = async (values) => {
  const required = t({
    id: 'global.forms.required',
  });

  const { workdayDurations, customRequestsLimits, rates, customEmployeeId } = values;

  const workdayDurationsErrors = workdayDurations?.reduce(
    (acc, { workdayDurationSeconds }, index) =>
      !workdayDurationSeconds ||
      +workdayDurationSeconds < VALIDATION_RULES.WORKDAY_DURATION_SECONDS.min ||
      +workdayDurationSeconds > VALIDATION_RULES.WORKDAY_DURATION_SECONDS.max
        ? {
            ...acc,
            [index]: {
              workdayDurationSeconds: {
                message: required,
              },
            },
          }
        : acc,
    {},
  );

  const customRequestLimitsErrors = customRequestsLimits?.reduce((acc, { limits }, index) => {
    const requestTypesErrors = limits.reduce(
      (requestTypeErrors, requestType, nestedIndex) =>
        !_.isNil(requestType.period) && requestType.days && +requestType.days > periodsMax[+requestType.period]
          ? {
              ...requestTypeErrors,
              [nestedIndex]: {
                days: {
                  message: t({
                    id: 'team.user.employment.req_limit',
                    message: `max: ${periodsMax[+requestType.period]}`,
                  }),
                },
              },
            }
          : requestTypeErrors,
      {},
    );

    return !_.isEmpty(requestTypesErrors) ? { ...acc, [index]: { limits: requestTypesErrors } } : acc;
  }, {});

  const isValid = (v?: string | number) => !_.isNil(v) && v !== '';

  const ratesErrors = rates?.reduce(
    (acc, { normal, overtime }, index) => {
      const newErrors =
        !isValid(normal) || !isValid(overtime)
          ? {
              [index]: {
                ...(!isValid(normal) && {
                  normal: {
                    message: required,
                  },
                }),
                ...(!isValid(overtime) && {
                  overtime: {
                    message: required,
                  },
                }),
              },
            }
          : null;
      return {
        ...acc,
        ...(newErrors && newErrors),
      };
    },

    {},
  );

  const customEmployeeIdValidationConfig = validationFactory(VALIDATION_RULES.CUSTOM_EMPLOYEE_ID);

  const { setValueAs } = customEmployeeIdValidationConfig;

  const parsedCustomEmployeeId = customEmployeeId ? setValueAs(customEmployeeId) : customEmployeeId;

  const customEmployeeIdError = validationResolver(customEmployeeId, customEmployeeIdValidationConfig);

  return {
    errors: {
      ...(!_.isEmpty(customRequestLimitsErrors) && { customRequestsLimits: customRequestLimitsErrors }),
      ...(!_.isEmpty(workdayDurationsErrors) && { workdayDurations: workdayDurationsErrors }),
      ...(!_.isEmpty(ratesErrors) && { rates: ratesErrors }),
      ...(customEmployeeIdError && { customEmployeeId: { message: customEmployeeIdError } }),
    },
    values: {
      ...values,
      customEmployeeId: parsedCustomEmployeeId,
    },
  };
};
