/* eslint-disable no-console */
import React from 'react';

import { ErrorPanel } from './ErrorPanel';
import { CurrentError, ErrorInfo } from './ErrorBoundary.types';

interface Props {
  children: React.ReactElement;
  debugMode: boolean;
}

interface State {
  currentError: CurrentError | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state = {
    currentError: null,
    errorInfo: null,
    hasError: false,
  };

  static getDerivedStateFromError(): Partial<State> | null {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.log('[ErrorBoundary] Caught an error!');

    this.setState({
      currentError: error,
      errorInfo: info,
    });
  }

  render(): React.ReactElement {
    const { children } = this.props;
    const { currentError, errorInfo, hasError } = this.state;

    if (hasError) {
      return <ErrorPanel {...this.props} currentError={currentError} errorInfo={errorInfo} />;
    }

    return <>{children}</>;
  }
}
