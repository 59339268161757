import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  FetchExternalProviderResponse,
  FetchIntegrationsResponse,
  GoogleLinkResponse,
  GoogleLoginResponse,
  ConnectIntegrationsPayrollActionProps,
  ConnectIntegrationsPayrollResponse,
  DisconnectIntegrationsPayrollActionProps,
  DisconnectIntegrationsPayrollResponse,
  GoogleLinkActionProps,
  GoogleLoginActionProps,
} from './integrationActions.types';

export function fetchIntegrationsAction(): Action<FetchIntegrationsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.integrations,
  };
}

export function connectIntegrationsPayrollAction({
  integration,
}: ConnectIntegrationsPayrollActionProps): Action<ConnectIntegrationsPayrollResponse> {
  const queryParams = new URLSearchParams({
    integration: integration.toString(),
  });

  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.integrationsConnectPayroll}?${queryParams}`,
  };
}

export function integrationsDisconnectAction({
  integration,
}: DisconnectIntegrationsPayrollActionProps): Action<DisconnectIntegrationsPayrollResponse> {
  const queryParams = new URLSearchParams({
    integration: integration.toString(),
  });

  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.integrationsDisconnect}?${queryParams}`,
  };
}

export function fetchExternalProviderAction(): Action<FetchExternalProviderResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.externalProvider,
  };
}

export function googleLinkAction({ googleToken }: GoogleLinkActionProps): Action<GoogleLinkResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.googleLink,
    body: { googleToken },
  };
}

export function googleLoginAction({ googleToken }: GoogleLoginActionProps): Action<GoogleLoginResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.googleLogin,
    body: { googleToken },
  };
}
