import { FetchUserSessionResponse } from 'api/actions/userSession/userSessionActions.types';
import { actionNames } from 'broadcastChannel/channelReducer/actionNames';
import { ChannelMessage } from 'broadcastChannel/channelReducer/channelReducer.types';
import { TAB_ID } from 'constants/common';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { PublisherAddNotificationProps } from 'NotificationHub/types';

export const refreshUserSession: (userSession: FetchUserSessionResponse) => ChannelMessage = (userSession) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.REFRESH_USER_SESSION,
    payload: userSession,
  },
});

export const changeLanguage: (language: keyof typeof SupportedLanguages) => ChannelMessage = (language) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.CHANGE_LANGUAGE,
    payload: language,
  },
});

export const signOut: () => ChannelMessage = () => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.SIGN_OUT,
  },
});

export const addNotification: (notification: PublisherAddNotificationProps) => ChannelMessage = (notification) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.ADD_REFRESH_PAGE_NOTIFICATION,
    payload: notification,
  },
});

export const addRefreshPageNotification: (message?: string) => ChannelMessage = (message) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.ADD_REFRESH_PAGE_NOTIFICATION,
    payload: message,
  },
});
