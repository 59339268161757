import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import objectSupport from 'dayjs/plugin/objectSupport';
import arraySupport from 'dayjs/plugin/arraySupport';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import toObject from 'dayjs/plugin/toObject';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import { timezoneSelector } from 'state/recoilState';
import { DEFAULT_TIMEZONE } from 'constants/common';

type Props = {
  children: React.ReactElement[] | React.ReactElement;
};

export const DateTimeZoneProvider = ({ children }: Props): React.ReactElement => {
  const tz = useRecoilValue(timezoneSelector);

  dayjs.extend(isLeapYear);
  dayjs.extend(objectSupport);
  dayjs.extend(arraySupport);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(localeData);
  dayjs.extend(weekday);
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  dayjs.extend(toObject);
  dayjs.extend(duration);
  dayjs.extend(customParseFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);

  useEffect(() => {
    if (tz) {
      dayjs.tz.setDefault(tz);
    } else {
      dayjs.tz.setDefault(DEFAULT_TIMEZONE);
    }

    // FIXME: delete after tests are done
    // dayjs.tz.setDefault('America/New_York');
  }, [tz]);

  return <>{children}</>;
};
