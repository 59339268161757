import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Badge, BadgeProps } from 'components/Badge/Badge';
import { RequestState } from 'api/actions/requests/requestsActions.types';
import { requestStateDescriptionSelector } from 'state/requests';
import { IconProps } from 'components/Icon/Icon';

type Props = {
  requestState: RequestState;
  type?: 'icon' | 'text';
} & Omit<BadgeProps, 'variant' | 'icon' | 'title' | 'children'>;

export const RequestStatusBadge = ({ requestState, type = 'text', sx, ...props }: Props): React.ReactElement => {
  const requestStateDescription = useRecoilValue(requestStateDescriptionSelector(requestState));

  const variant = useMemo((): BadgeProps['variant'] => {
    switch (requestState) {
      case RequestState.Accepted:
        return 'success';

      case RequestState.Rejected:
        return 'danger';

      case RequestState.Deleted:
        return 'warning';

      case RequestState.Pending:
      default:
        return 'default';
    }
  }, [requestState]);

  const iconType = useMemo((): IconProps['type'] | undefined => {
    switch (requestState) {
      case RequestState.Accepted:
        return 'approve';

      case RequestState.Rejected:
        return 'deny';

      case RequestState.Deleted:
        return 'delete';

      default:
        return undefined;
    }
  }, [requestState]);

  return (
    <Badge
      variant={variant}
      title={requestStateDescription}
      icon={type === 'icon' ? iconType : undefined}
      sx={{
        ...(type === 'icon' && {
          px: 1,
        }),
        ...sx,
      }}
      {...props}
    >
      {type === 'text' && requestStateDescription}
    </Badge>
  );
};
