/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';

import {
  ErrorCodeTimeclock,
  GetInfoErrorData,
  GetInfoResponse,
  GetSettingsResponse,
  SubmitTimeEventResponse,
} from 'api/actions/timeclock/timeclockActions.types';
import { EXIT_TIME_EVENT_ID, ENTRY_TIME_EVENT_ID } from 'Kiosk/constants/constants';

export const SIGN_UP = {
  ERROR_INVITED: {
    message: 'Already invited',
    innerCode: 2307,
    invitationEmailDetails: {
      email: 'qwe@gmail.com',
      title: 'inEwi zaproszenie',
      sentAt: 1612863305,
    },
  },
  TIME_ZONE_LIST: [
    { label: 'label 1', id: 'qwe/qwe' },
    { label: '(UTC +01:00) Europa / Polska / Warszawa', id: 'Europe/Warsaw' },
    { label: 'label 3', id: 'zxc/zxc' },
  ],
  INDUSTRY_LIST: [
    {
      label: 'Administracja publiczna',
      id: 100,
      subcategories: [],
    },
    {
      label: 'Automatyka i robotyka',
      id: 200,
      subcategories: [],
    },
    {
      label: 'Budownictwo',
      id: 300,
      subcategories: [
        {
          label: 'Budowa budynków mieszkalnych',
          id: 301,
        },
        {
          label: 'Dostawa materiałów',
          id: 302,
        },
        {
          label: 'Usługi budowlane/wykończeniowe',
          id: 303,
        },
        {
          label: 'Inne',
          id: 1,
        },
      ],
    },
    {
      label: 'Edukacja / Szkolnictwo',
      id: 400,
      subcategories: [],
    },
    {
      label: 'Finanse i ubezpieczenia',
      id: 500,
      subcategories: [
        {
          label: 'Bankowość',
          id: 501,
        },
        {
          label: 'Doradztwo inwestycyjne',
          id: 502,
        },
        {
          label: 'Ubezpieczenia',
          id: 503,
        },
        {
          label: 'Inne',
          id: 1,
        },
      ],
    },
    {
      label: 'Gastronomia',
      id: 600,
      subcategories: [
        {
          label: 'Bar',
          id: 601,
        },
        {
          label: 'Catering',
          id: 605,
        },
        {
          label: 'Kawiarnia',
          id: 604,
        },
        {
          label: 'Pizzeria',
          id: 602,
        },
        {
          label: 'Restauracja',
          id: 603,
        },
        {
          label: 'Inne',
          id: 1,
        },
      ],
    },
  ],
};

const mockFeatures = [
  {
    type: 0,
    inheritFromTag: true,
    inheritedValue: false,
    value: true,
  },
  {
    type: 1,
    inheritFromTag: false,
    inheritedValue: true,
    value: false,
  },
  {
    type: 2,
    inheritFromTag: true,
    inheritedValue: false,
    value: true,
  },
  {
    type: 3,
    inheritFromTag: true,
    inheritedValue: false,
    value: true,
  },
  {
    type: 4,
    inheritFromTag: false,
    inheritedValue: false,
    value: true,
  },
  {
    type: 5,
    inheritFromTag: false,
    inheritedValue: true,
    value: true,
  },
  {
    type: 7,
    inheritFromTag: false,
    inheritedValue: true,
    value: false,
  },
];

const mockTagFeatures = [
  {
    type: 0,
    value: true,
  },
  {
    type: 1,
    value: true,
  },
  {
    type: 2,
    value: false,
  },
  {
    type: 3,
    value: true,
  },
  {
    type: 4,
    value: false,
  },
  {
    type: 5,
    value: true,
  },
  {
    type: 7,
    value: true,
  },
];

export const TAG_DETAILS = {
  name: 'TAG',
  additionalInfo: 'some note',
  color: 6,
  features: mockTagFeatures,
  visibilityLevel: 12,
};

export const TAG_INHERITED_FEATURES = {
  features: mockFeatures,
  visibilityLevelInheritedFromTag: 2,
};

export const EMPLOYEE_DETAILS = {
  employeeInfo: {
    firstName: 'John',
    surname: 'Polish',
    phoneNumber: '+223 22 34 23 42',
    email: 'jpolish@ww.ww',
    note: 'Some note',
    address: {
      postalCode: '37-409',
      city: 'Niu Jork',
      street: 'Stritowa 3',
    },
  },
  advancedEmployeeInfo: {
    customEmployeeId: 'employee id',
    identity: '88102344555',
    hideOnList: true,
    features: mockFeatures,
    visibilityLevel: 6,
    visibilityLevelInheritedFromTag: 3,
  },
  workdayDurations: [
    {
      id: '1',
      fromYear: 2020,
      workdayDurationSeconds: 2000,
    },
  ],
  timeOffLimits: [
    {
      id: '1',
      fromYear: 2020,
      limits: [
        {
          id: 'b80d9fa1-51a3-4a29-9c2e-fa5b64c16686',
          days: 4,
          carryOverUnusedLimit: false,
          timeOffTypeId: '426a12a1-10d4-4d81-baf4-52510f849d24',
        },
      ],
    },
  ],
  customRequestsLimits: [
    {
      id: '1',
      fromYear: 2020,
      limits: [
        {
          id: 'e3e1dfc3-817e-4268-babc-f9507526a07c1',
          days: 0,
          period: 1,
          customRequestTypeId: 'e3e1dfc3-817e-4268-babc-f9507526a07c',
        },
      ],
    },
  ],

  rates: [
    {
      id: 'a8bd2091-e6ca-4f6d-91b5-b4002a4abc5a',
      startDateUnix: 1626912000,
      normal: 21,
      overtime: 30,
    },
  ],
  tagsIds: ['1', '2'],
  roleId: '2',
  defaultWorkPositionId: '1',
};

export const TEAM_LIST_VIEW_SETTINGS = {
  roleAndTags: true,
  workPosition: true,
  email: true,
  phoneNumber: true,
  employeeId: false,
};

type KioskTypes = {
  GET_INFO: GetInfoResponse;
  GET_INFO_ERROR: {
    innerCode: ErrorCodeTimeclock;
    message?: string;
    data: GetInfoErrorData;
  };
  SETTINGS: GetSettingsResponse;
  SUBMIT_TIME_EVENT_ERROR: SubmitTimeEventResponse;
};

export const KIOSK: KioskTypes = {
  SETTINGS: {
    isLocationRequired: false,
    isCameraRequired: true,
    isFaceRecognitionRequired: true,
    summaryTimeSeconds: 4,
    sleepModeTimeOutMinutes: 30,
    isSleepModeEnabled: true,
    organizationName: 'InEwi',
  },
  SUBMIT_TIME_EVENT_ERROR: {
    message: 'Spróbuj ponownie za 10min',
    innerCode: ErrorCodeTimeclock.INVALID_LOCATION,
  },
  GET_INFO_ERROR: {
    data: {
      personName: 'Edward',
      workTimeSeconds: 0,
    },
    message: 'Odpal InitalFaceScan',
    // innerCode: ErrorCodeTimeclock.BAD_QR_CODE,
    // innerCode: ErrorCodeTimeclock.INVALID_PERSON_FACE,
    innerCode: ErrorCodeTimeclock.NO_FACE_MODEL,
  },
  GET_INFO: {
    personName: 'Janusz',
    lastEnterUnix: 0,
    workTimeSeconds: 66666,
    suggestedTimeEvent: {
      type: {
        id: `GUID_PRZERWA`, // custom
        // id: ENTRY_TIME_EVENT_ID, // ENTER
        // id: EXIT_TIME_EVENT_ID, // EXIT
        name: 'Przerwa',
        isEnd: false,
      },
      durationSeconds: 666,
      includeWorkTime: true,
      remainingTimeAllowedSeconds: 66,
    },
    timeEventTypes: [
      {
        id: 'GUID1',
        name: 'Przerwa',
        isEnd: false,
      },
      {
        id: 'GUID2',
        name: 'Wyjście prywatne',
        isEnd: false,
      },
      {
        id: 'GUID3',
        name: 'Praca na maszynie a',
        isEnd: false,
      },
      {
        id: 'GUID4',
        name: 'Praca na maszynie b',
        isEnd: false,
      },
      {
        id: 'GUID5',
        name: 'Wyjście służbowe',
        isEnd: false,
      },
    ],
    recentTimeEvents: [
      {
        type: {
          id: 'GUID1',
          name: 'Przerwa',
          isEnd: false,
        },
        durationSeconds: 60,
        maxDurationAllowedSeconds: 6000,
      },
    ],
  },
};
export const SCHEDULES = {
  showLaborCodeAlerts: true,
  showVersionControl: false,
};

export const NOTIFICATIONS_SETTINGS = {
  schedulesEmails: true,
  schedulesTextMessages: true,
  requestsEmails: true,
  requestsTextMessages: true,
  messagesEmails: true,
  promotionsEmails: true,
};

export const TIME_TRACKING = {
  showPaidBreak: true,
  limitPaidBreak: true,
  paidBreakLimitSeconds: 120,
  showUnPaidBreak: true,
  enableFaceVerification: true,
  restrictLocations: true,
  enableOfflineMode: true,
  sendEventPhotos: true,
  allowTimeRounding: true,
  roundToMinutes: 10,
  minOvertimeSeconds: 120,
  autoBalanceOvertime: true,
  payrollPeriod: 1,
  overtimePeriod: 1,
  typeOfWorktimeWithoutSchedule: 0,
  countNightHours: true,
  nightHoursStartSeconds: 0,
  nightHoursDurationSeconds: 60,
};

export const ORGANIZATION_SETTINGS = {
  name: 'Organization name',
  address: {
    // postalCode: '43-300',
    // city: 'Bielsko-Biała',
    // street: '1 Maja 15',
    postalCode: '',
    city: '',
    street: '',
  },
  countryCode: 'pl',
  vatId: 'ID423521523985',
  timeZoneId: 'Europe/Warsaw',
  startingWeekDay: 0,
  displayCurrency: 'pln',
  holidays: [
    {
      year: 2021,
      holidays: [
        {
          id: '0',
          dateUnix: 1635372000,
          name: 'string',
          isDefault: true,
        },
        {
          id: '1',
          dateUnix: 1635372000,
          name: 'string',
          isDefault: true,
        },
      ],
    },
  ],
  showHolidaysOnAllCalendars: true,
  autoImportHolidaysEachYear: true,
  currencies: [
    {
      key: 'pln',
      value: 'PLN - Złoty',
    },
    {
      key: 'eur',
      value: 'EUR - Euro',
    },
    {
      key: 'usd',
      value: 'USD - Dolar',
    },
  ],
};

export const HOLIDAYS_2021 = [
  {
    id: '0',
    dateUnix: 1635372000,
    name: 'string',
    isDefault: false,
  },
  {
    id: '1',
    dateUnix: 1635371000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '3',
    dateUnix: 1635372000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '4',
    dateUnix: 1635371000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '5',
    dateUnix: 1635372000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '6',
    dateUnix: 1635371000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '7',
    dateUnix: 1635372000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '8',
    dateUnix: 1635371000,
    name: 'string',
    isDefault: true,
  },
];

export const HOLIDAYS_2022 = [
  {
    id: '0',
    dateUnix: 1635372000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '1',
    dateUnix: 1635371000,
    name: 'string',
    isDefault: true,
  },
];

export const HOLIDAYS_2023 = [
  {
    id: '0',
    dateUnix: 1635372000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '1',
    dateUnix: 1635371000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '3',
    dateUnix: 1635372000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '4',
    dateUnix: 1635371000,
    name: 'string',
    isDefault: true,
  },
  {
    id: '5',
    dateUnix: 1635372000,
    name: 'string',
    isDefault: true,
  },
];

export const REQUEST_SETTINGS = {
  allowCarryOverUnUsedLimits: false,
  carriedOverLimitExpiresAfter: 0,
  disableLocationRestrictionOnBusinessTrip: true,
  allowChangeRequestForLoggedClockInsAndOuts: true,
  allowRequestForBusinessTrips: true,
  timeOffApprovalSettings: {
    twoStepApprovalFlow: false,
    firstStepRoleId: '1',
    secondStepRoleId: '2',
  },
  timeTrackingApprovalSettings: {
    twoStepApprovalFlow: false,
    firstStepRoleId: '2',
    secondStepRoleId: '1',
  },
  businessTripsApprovalSettings: {
    twoStepApprovalFlow: false,
    firstStepRoleId: '2',
    secondStepRoleId: '1',
  },
  customRequestsApprovalSettings: {
    twoStepApprovalFlow: false,
    firstStepRoleId: '2',
    secondStepRoleId: '1',
  },
};

export const IMPORT_HOLIDAYS_GOOGLE = [
  {
    holidayId: '1',
    dateUnix: 1635372000,
    name: 'string',
    isDateBound: true,
  },
  {
    holidayId: '2',
    dateUnix: 1635372000,
    name: 'string',
    isDateBound: true,
  },
  {
    holidayId: '3',
    dateUnix: 1635372000,
    name: 'string',
    isDateBound: true,
  },
  {
    holidayId: '4',
    dateUnix: 1635372000,
    name: 'string',
    isDateBound: true,
  },
  {
    holidayId: '5',
    dateUnix: 1635372000,
    name: 'string',
    isDateBound: true,
  },
  {
    holidayId: '6',
    dateUnix: 1635372000,
    name: 'string',
    isDateBound: true,
  },
];

export const IMPORT_LOCATIONS = [
  'ad',
  'ae',
  'af',
  'ag',
  'ai',
  'al',
  'am',
  'ao',
  'ar',
  'as',
  'at',
  'au',
  'aw',
  'az',
  'ba',
  'bb',
  'bd',
  'be',
  'bf',
  'bg',
  'bh',
  'bi',
  'bj',
  'bl',
  'bm',
  'bn',
  'bo',
  'br',
  'bs',
  'bt',
  'bw',
  'by',
  'bz',
  'ca',
  'cd',
  'cf',
  'cg',
  'ch',
  'ci',
  'ck',
  'cl',
  'cm',
  'cn',
  'co',
  'cr',
  'cu',
  'cv',
  'cw',
  'cy',
  'cz',
  'de',
  'dj',
  'dk',
  'dm',
  'do',
  'dz',
  'ec',
  'ee',
  'eg',
  'er',
  'es',
  'et',
  'fi',
  'fj',
  'fk',
  'fm',
  'fo',
  'fr',
  'ga',
  'gb',
  'gd',
  'ge',
  'gf',
  'gg',
  'gh',
  'gi',
  'gl',
  'gm',
  'gn',
  'gp',
  'gq',
  'gr',
  'gt',
  'gu',
  'gw',
  'gy',
  'hk',
  'hn',
  'hr',
  'ht',
  'hu',
  'id',
  'ie',
  'il',
  'im',
  'in',
  'iq',
  'ir',
  'is',
  'it',
  'je',
  'jm',
  'jo',
  'jp',
  'ke',
  'kg',
  'kh',
  'ki',
  'km',
  'kn',
  'kp',
  'kr',
  'kw',
  'ky',
  'kz',
  'la',
  'lb',
  'lc',
  'li',
  'lk',
  'lr',
  'ls',
  'lt',
  'lu',
  'lv',
  'ly',
  'ma',
  'mc',
  'md',
  'me',
  'mf',
  'mg',
  'mh',
  'mk',
  'ml',
  'mm',
  'mn',
  'mo',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'na',
  'nc',
  'ne',
  'ng',
  'ni',
  'nl',
  'no',
  'np',
  'nr',
  'nz',
  'om',
  'pa',
  'pe',
  'pf',
  'pg',
  'ph',
  'pk',
  'pl',
  'pm',
  'pr',
  'pt',
  'pw',
  'py',
  'qa',
  're',
  'ro',
  'rs',
  'ru',
  'rw',
  'sa',
  'sb',
  'sc',
  'sd',
  'se',
  'sg',
  'sh',
  'si',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sr',
  'ss',
  'st',
  'sv',
  'sx',
  'sy',
  'sz',
  'tc',
  'td',
  'tg',
  'th',
  'tj',
  'tl',
  'tm',
  'tn',
  'to',
  'tr',
  'tt',
  'tv',
  'tw',
  'tz',
  'ua',
  'ug',
  'us',
  'uy',
  'uz',
  'va',
  'vc',
  've',
  'vg',
  'vi',
  'vn',
  'vu',
  'wf',
  'ws',
  'xk',
  'ye',
  'yt',
  'za',
  'zm',
  'zw',
];

export const INTEGRATIONS = [
  {
    connectedAccount: 'string',
    type: 0,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 1,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 2,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 3,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 4,
    isExpired: true,
    isActive: true,
  },
  {
    connectedAccount: 'string',
    type: 5,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 6,
    isExpired: true,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 7,
    isExpired: false,
    isActive: true,
  },
  {
    connectedAccount: 'string',
    type: 8,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 9,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 10,
    isExpired: false,
    isActive: false,
  },
];

export const INTEGRATIONS_PL = [
  {
    connectedAccount: 'string',
    type: 2,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 3,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 4,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 5,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 8,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 9,
    isExpired: false,
    isActive: false,
  },
  {
    connectedAccount: 'string',
    type: 10,
    isExpired: false,
    isActive: false,
  },
];

export const EXTERNAL_PROVIDER = [
  {
    provider: 1,
    isActive: false,
    connectedName: 'string',
  },
  {
    provider: 2,
    isActive: false,
    connectedName: 'string',
  },
  {
    provider: 5,
    isActive: false,
    connectedName: 'string',
  },
  {
    provider: 6,
    isActive: false,
    connectedName: 'string',
  },
];

export const ROLE_DETAILS = {
  name: 'ROLE',
  description: 'some description',
  color: 8,
  systemManagement: _.times(5).map((i) => ({ type: i, value: true })),
  modules: _.times(4).map((i) => ({ type: i, value: true })),
};
export const NOTIFICATIONS = [
  {
    id: '22dsa2',
    type: 'info',
    createdAt: 1628758975,
    title: 'To jest test1',
    content: 'To jest test to jest testto jest testto jest testto jest test',
    redirectTo: 'https://localhost:3000/app/?redirect=0',
  },
  {
    id: '22d3232sa2',
    type: 'danger',
    createdAt: 1628758975,
    title: 'Edytuj pracownika',
    content: 'To jest test to jest testto jest testto jest testto jest test',
    redirectTo: 'https://localhost:3000/app/?redirect=2&id=1',
  },
  {
    id: '22111dsa2',
    type: 'success',
    createdAt: 1628758975,
    title: 'To jest test3',
    content: 'To jest test to jest testto jest testto jest testto jest test',
    redirectTo: 'https://inewi.pl',
  },
];

export const LOCATIONS = [
  {
    id: '180f32b8-86d0-41f8-9eab-b1cb9c25626e',
    name: 'Inewi HQ',
    address: {
      street: '1 maja 15',
      city: 'Bielsko-Biała',
      postalCode: '43-100',
    },
    latitude: 49.813651,
    longitude: 19.08738,
    range: 500,
  },
  {
    id: '180f32b8-86d0-41f8-9eab-b1cb9c256262',
    name: 'Sąsiedzi',
    address: {
      street: '1 maja 16',
      city: 'Bielsko-Biała',
      postalCode: '43-100',
    },
    latitude: 49.5,
    longitude: 19.1,
    range: 500,
  },
  {
    id: '180f32b8-86d0-41f8-9eab-b1cb9c256263',
    name: 'Kraków',
    address: {
      street: 'Śląska 5',
      city: 'Kraków',
      postalCode: '42-300',
    },
    latitude: 49.813651,
    longitude: 19.08738,
    range: 1000,
  },
  {
    id: '180f32b8-86d0-41f8-9eab-b1cb9c256264',
    name: 'Czwarta',
    address: {
      street: 'Krakowska 2',
      city: 'Kielce',
      postalCode: '25-001',
    },
    latitude: 50.8650253,
    longitude: 20.6261933,
    range: 5000,
  },
  {
    id: '180f32b8-86d0-41f8-9eab-b1cb9c256265',
    name: 'Piąta',
    address: {
      street: '3 maja 230',
      city: 'Siemianowice Śląskie',
      postalCode: '40-333',
    },
    latitude: 49.813651,
    longitude: 19.08738,
    range: 500,
  },
  {
    id: '180f32b8-86d0-41f8-9eab-b1cb9c256266',
    name: 'Szósta',
    address: {
      street: 'Warszawska 13',
      city: 'Świętochłowice',
      postalCode: '40-222',
    },
    latitude: 49.813651,
    longitude: 19.08738,
    range: 500,
  },
];

export const SINGLE_LOCATION = {
  id: '180f32b8-86d0-41f8-9eab-b1cb9c25626e',
  name: 'Inewi HQ',
  address: {
    street: '1 maja 15',
    city: 'Bielsko-Biała',
    postalCode: '43-100',
  },
  latitude: 49.813651,
  longitude: 19.08738,
  range: 500,
};

export const FILE1 = {
  id: '59caa210-fd81-4f3a-a1e1-99fb8cb03e01',
  name: 'test-file',
  type: 14,
  size: 3333333,
  url: 'www.test-file.test',
};
export const FILE2 = {
  id: '59caa210-fd81-4f3a-a1e1-99fb8cb03e02',
  name: 'test-file2',
  type: 14,
  size: 222222,
  url: 'www.test-file2.test',
};
export const FILE3 = {
  id: '59caa210-fd81-4f3a-a1e1-99fb8cb03e03',
  name: 'test-file3',
  type: 14,
  size: 4333555,
  url: 'www.test-file3.test',
};

export const TIME_TRACKER = {
  lastEnterUnix: 1628758975,
  workTimeSeconds: 232,
  suggestedTimeEvent: {
    type: {
      id: '1bc7e5f6-03fe-4648-9793-66f6b64427db',
      name: 'Praca',
      isEnd: false,
    },
    includeWorkTime: false,
    remainingTimeAllowedSeconds: 28800,
    durationSeconds: 0,
  },
  timeEventTypes: [
    {
      id: '1bc7e5f6-aa03fe-4648-9793-66f6b64427db',
      name: 'Praca',
      isEnd: false,
    },
    {
      id: 'cff9adce-ad84-4dsa7b-a017-0161e13634a7',
      name: 'mykmykmykmyk',
      isEnd: false,
    },
    {
      id: '421db62e-4de7-450e-8dsa841-095f197a0148',
      name: 'STATUS_EXIT',
      isEnd: false,
    },
    {
      id: '1bdsac7e5f6-03fe-4648-9793-66f6b64427db',
      name: 'Przerwa',
      isEnd: false,
    },
  ],
  recentTimeEvents: [
    {
      durationSeconds: 60,
      maxDurationAllowedSeconds: 6000,
      type: {
        id: '421db62e-4ddsae7-450e-8841-095f197a0148',
        name: 'Break',
        isEnd: true,
      },
    },
    {
      durationSeconds: 60,
      maxDurationAllowedSeconds: 6000,
      type: {
        id: '421db62e-4ddsae7-450e-8841-095f1sds97a0148',
        name: 'FFF',
        isEnd: false,
      },
    },
  ],
};

export const WEBHOOKS = [
  {
    id: '643gtdfgdsds',
    type: 0,
    url: 'https://domain.com',
    token: 'type 0',
  },
  {
    id: 'rgfsfsdaf32-kjhgudsa',
    type: 1,
    url: 'https://domain.com',
    token: 'type 1',
  },
  {
    id: 'rdfe242134-fafafsa',
    type: 2,
    url: 'https://domain.com',
    token: 'type 2',
  },
];
export const notificationsSettings = {
  schedulesEmails: true,
  schedulesTextMessages: true,
  requestsEmails: true,
  requestsTextMessages: false,
  messagesEmails: true,
  promotionsEmails: true,
};

export const userAddress = {
  postalCode: '37-409',
  city: 'Niu Jork',
  street: 'Stritowa 3',
};

export const USER_INFO = {
  firstName: 'Carl',
  surname: 'Johnson',
  email: 'demo@mail.com',
  phoneNumber: '12345678912',
  address: {
    street: 'street',
    city: 'city',
    postalCode: 'postal Code',
  },
};

export const TIME_EVENTS = [
  {
    id: '9ce0ed2c-17ed-4c7b-937d-82c667791021',
    coordinates: { latitude: 52.22977, longitude: 21.01178 },
    typeId: '511db62e-4de7-450e-8841-095f197a0146',
    isEndStatus: false,
    timeUtc: 1645603200,
    timeRealUtc: 1645603380,
    photoUrl: 'https://avatarfiles.alphacoders.com/120/thumb-120127.jpg',
    note: 'Lorem ipsum dolor sit amet',
    personId: '4',
    device: { id: '123', name: 'System' },
    location: { id: '180f32b8-86d0-41f8-9eab-b1cb9c25626e', name: 'Inewi HQ' },
    isModified: false,
  },
  {
    id: '9ce0ed2c-17ed-4c7b-937d-82c667791022',
    coordinates: { latitude: 52.22977, longitude: 21.01178 },
    typeId: '7082451c-a65c-41de-9713-961fe8dd946e',
    isEndStatus: true,
    timeUtc: 1645632000,
    timeRealUtc: 1645632180,
    photoUrl: 'https://avatarfiles.alphacoders.com/120/thumb-120128.jpg',
    note: '',
    personId: '4',
    device: { id: '123', name: 'System' },
    location: { id: '180f32b8-86d0-41f8-9eab-b1cb9c256263', name: 'Kraków' },
    isModified: true,
  },
  {
    id: '9ce0ed2c-17ed-4c7b-937d-82c667791023',
    coordinates: { latitude: 52.22977, longitude: 21.01178 },
    typeId: '511db62e-4de7-450e-8841-095f197a0146',
    isEndStatus: false,
    timeUtc: 1645603200,
    timeRealUtc: 1645603380,
    photoUrl: 'https://avatarfiles.alphacoders.com/120/thumb-120127.jpg',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    personId: '4',
    device: { id: '123', name: 'System' },
    location: { id: '', name: '' },
    isModified: false,
  },
];

export const TIME_EVENT_HISTORY = [
  {
    previous: {
      id: '9ce0ed2c-17ed-4c7b-937d-82c667791021',
      modificationDateTime: 1645632000,
      modificationPersonName: {
        firstName: 'Jan',
        surname: 'Polski',
      },
      deviceName: 'System',
      isRequest: false,
      typeName: 'Clock-In',
      isEndType: false,
      note: 'notatka',
    },
    current: {
      id: '9ce0ed2c-17ed-4c7b-937d-82c667791021',
      modificationDateTime: 1645632000,
      modificationPersonName: {
        firstName: 'Jan',
        surname: 'Polski',
      },
      deviceName: 'System',
      isRequest: false,
      typeName: 'Clock-In',
      isEndType: false,
      note: 'notatka',
    },
    hasTimeEventChanged: false,
  },
  {
    previous: {
      id: '9ce0ed2c-17ed-4c7b-937d-82c667791021',
      modificationDateTime: 1645632000,
      modificationPersonName: {
        firstName: 'Jan',
        surname: 'Polski',
      },
      deviceName: 'System',
      isRequest: false,
      typeName: 'Clock-In',
      isEndType: false,
      note: 'Lorem ipsum dolor sit amet. Eos soluta corrupti est error omnis qui officiis quasi qui error voluptatem! Ex quisquam beatae et placeat aperiam At veritatis quaerat rem porro repellendus ex velit nihil. At ducimus ratione nam consequatur numquam quo delectus quisquam vel mollitia dolore. Voluptatem illo qui quibusdam magni qui illum unde ut animi voluptatum et facere asperiores et autem recusandae in provident suscipit? Aut dolorum eius ut sunt nobis non omnis cumque ut possimus dolorem sed nobis vero. Cum doloribus dolorum qui perferendis minima et eaque odio aut tenetur galisum et obcaecati minima ut corporis sunt ut impedit repellendus.',
    },
    current: {
      id: '9ce0ed2c-17ed-4c7b-937d-82c667791022',
      modificationDateTime: 1645634000,
      modificationPersonName: {
        firstName: 'Jan',
        surname: 'Polski',
      },
      deviceName: 'System',
      isRequest: false,
      typeName: 'Clock-In',
      isEndType: false,
      note: 'poprawiona notatka',
    },
    hasTimeEventChanged: true,
  },
  {
    previous: {
      id: '9ce0ed2c-17ed-4c7b-937d-82c667791022',
      modificationDateTime: 1645634000,
      modificationPersonName: {
        firstName: 'Jan',
        surname: 'Polski',
      },
      deviceName: 'System',
      isRequest: false,
      typeName: 'Clock-In',
      isEndType: false,
      note: 'poprawiona notatka',
    },
    current: {
      id: '9ce0ed2c-17ed-4c7b-937d-82c667791023',
      modificationDateTime: 1645644000,
      modificationPersonName: {
        firstName: 'Jan',
        surname: 'Polski',
      },
      deviceName: 'PRACOROBOT',
      isRequest: false,
      typeName: 'Clock-In',
      isEndType: false,
      note: 'gdzie tu się zmienia czas wydarzenia',
    },
    hasTimeEventChanged: true,
  },
  {
    previous: {
      id: '9ce0ed2c-17ed-4c7b-937d-82c667791023',
      modificationDateTime: 1645634000,
      modificationPersonName: {
        firstName: 'Jan',
        surname: 'Polski',
      },
      deviceName: 'System',
      isRequest: false,
      typeName: 'Clock-In',
      isEndType: false,
      note: 'poprawiona notatka',
    },
    current: {
      id: '9ce0ed2c-17ed-4c7b-937d-82c667791024',
      modificationDateTime: 1645644000,
      modificationPersonName: {
        firstName: 'Jan',
        surname: 'Polski',
      },
      deviceName: 'PRACOROBOT',
      isRequest: false,
      typeName: 'Clock-In',
      isEndType: false,
      note: 'gdzie tu się zmienia czas wydarzenia',
    },
    hasTimeEventChanged: true,
  },
];

export const TIME_CLOCKS = [
  {
    id: '1234567',
    name: 'Telefon Mietka',
    appVersion: '3.0.0',
    osType: 0,
    osVersion: '4.4.4',
    model: 'Samsung Galaxy 15000000',
    state: 1,
  },
  {
    id: '1234568',
    name: 'Ajfon Stanisława',
    appVersion: '3.1.0',
    osType: 1,
    osVersion: '15.0.1',
    model: null,
    state: 0,
  },
  {
    id: '1234569',
    name: 'Stara Nokia',
    appVersion: '3.0.1',
    osType: 0,
    osVersion: '5.5.5',
    model: 'Nokia 1',
    state: 1,
  },
];
