import React, { useCallback, useState } from 'react';
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui';
import { useHistory, useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { Timer } from 'components/utils/Timer';
import { Icon } from 'components/Icon/Icon';
import { useTheme } from 'styles/useTheme';
import { activeTimeEventSelector } from 'state/drawer';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';

export type NavbarWorkProps = {
  to?: string;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<NavbarWorkProps> = {
  sx: undefined,
  to: undefined,
};

export const NavbarTimeEventTimer = ({ sx, to }: NavbarWorkProps): React.ReactElement => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const activeTimeEvent = useRecoilValue(activeTimeEventSelector);
  const { theme } = useTheme();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const onMouseOver = useCallback(() => setIsHovered(true), []);
  const onMouseOut = useCallback(() => setIsHovered(false), []);

  const handleActive = useCallback(() => {
    if (activeTimeEvent) {
      const { postTimeEvent, id, isActive, variant, initialTime } = activeTimeEvent;
      if (isActive && variant === 'work' && to) {
        push(to, { from: pathname });
      } else {
        setIsLoading(true);
        postTimeEvent({
          timeEventTypeId: id,
          isEnd: isActive,
          initialTime,
        }).finally(() => setIsLoading(false));
      }
    }
  }, [activeTimeEvent, pathname, push, to]);

  const returnIconFill = useCallback(() => {
    if (!activeTimeEvent) return theme.colors.workTimer.icon.fill.default;

    const { variant, isActive } = activeTimeEvent;

    if (variant === 'work' && isHovered && isActive) return theme.colors.workTimer.icon.fill.activeHover;
    if (variant === 'work' && !isHovered && isActive) return theme.colors.workTimer.icon.fill.active;
    if (variant === 'work' && !isHovered) return theme.colors.workTimer.icon.fill.default;
    if (variant === 'work' && isHovered) return theme.colors.workTimer.icon.fill.hover;
    return theme.colors.workTimer.icon.fill.custom;
  }, [
    activeTimeEvent,
    isHovered,
    theme.colors.workTimer.icon.fill.active,
    theme.colors.workTimer.icon.fill.activeHover,
    theme.colors.workTimer.icon.fill.custom,
    theme.colors.workTimer.icon.fill.default,
    theme.colors.workTimer.icon.fill.hover,
  ]);

  if (!activeTimeEvent) return <></>;

  const { isActive, initialTime, variant, name } = activeTimeEvent;

  return (
    <Flex
      data-hover={isHovered}
      data-active={isActive}
      variant={`navbarTimeEventTimer.container.${variant}`}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={handleActive}
      sx={{
        ...(sx && sx),
      }}
    >
      {isLoading && <LoadingOverlay size={2} />}
      <Icon size={36} type={isActive ? 'stopCircle' : 'playCircle'} fill={returnIconFill()} />
      <Flex variant={`navbarTimeEventTimer.content.${variant}`} data-hover={isHovered} data-active={isActive}>
        <TextEllipsis sx={{ p: 0, m: 0, fontSize: 0, lineHeight: 'workTimer' }}>
          {i18n._(
            t({
              id: name,
            }),
          )}
        </TextEllipsis>
        <Text variant="navbarTimeEventTimer.timer">
          <Timer initialTime={initialTime} active={isActive} />
        </Text>
      </Flex>
    </Flex>
  );
};

NavbarTimeEventTimer.defaultProps = defaultProps;
