import React from 'react';
import { useRecoilValue } from 'recoil';

import { PATH } from 'constants/routes';
import { ModalRoute } from 'routing/ModalRoute';
import { languageSelector } from 'state/recoilState';
import { SettingsModal } from 'Kiosk/modals/SettingsModal';
import { HelpCenterModal } from 'Kiosk/modals/HelpCenterModal';
import { LocationModal } from 'Kiosk/modals/LocationModal';

export const ModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <ModalRoute size="sm" fullHeight path={PATH.KIOSK_SETTINGS_MODAL[language]}>
        <SettingsModal />
      </ModalRoute>
      <ModalRoute path={PATH.KIOSK_LOCATION_MODAL[language]}>
        <LocationModal />
      </ModalRoute>
      <ModalRoute size="sm" fullHeight path={PATH.KIOSK_HELP_CENTER_MODAL__STEP[language]}>
        <HelpCenterModal />
      </ModalRoute>
    </>
  );
};
