import { useEffect } from 'react';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

/**
 * Use to dispatch internal field errors to parent form
 */
export const useFieldErrorDispatcher = (
  fieldError: boolean,
  onValidError?: () => void,
  onClearError?: () => void,
): void => {
  useEffect(() => {
    if (onValidError && onClearError) {
      if (fieldError) {
        onValidError();
      } else {
        onClearError();
      }
    }
  }, [fieldError, onValidError, onClearError]);

  const onClearErrorRef = useCallbackRef(onClearError);

  useEffect(
    () => () => {
      if (onClearErrorRef.current) {
        onClearErrorRef.current();
      }
    },
    [onClearErrorRef],
  );
};
