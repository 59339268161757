import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { Trans, t } from '@lingui/macro';
import { Flex, InputProps, ThemeUIStyleObject, Box } from 'theme-ui';
import { i18n } from '@lingui/core';
import _ from 'lodash';

import { mergeRefs } from 'utils/mergeRefs';
import { TextInput, TextInputProps } from '../TextInput';
import { setNativeValue } from 'utils/setNativeValue';
import { createEvent } from 'utils/createEvent';
import { Icon } from 'components/Icon/Icon';
import { delay } from 'utils/delay';
import { useForceUpdate } from 'hooks/useForceUpdate/useForceUpdate';
import { useTheme } from 'styles/useTheme';
import { withPopper } from '../PopperProvider/withPopper';
import { useIsMountedRef } from 'hooks/useIsMountedRef/useIsMountedRef';
import { emitUpdateFieldView } from 'utils/emitUpdateFieldView';
import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';
import { useOnUpdateFieldView } from 'hooks/useOnUpdateFieldView/useOnUpdateFieldView';

import { Option, OptionList } from './Option';
import { SelectedMultiOption } from './SelectedMultiOption';

const FlexWithPopper = withPopper(Flex);

export type InputOption = { label: string; id: string; isCreatable?: boolean };

type Props = Omit<TextInputProps, 'type'> & {
  options: InputOption[];
  searchable?: boolean;
  creatable?: boolean;
  multi?: boolean;
  alwaysHideOptions?: boolean;
  ignoreOptions?: boolean;
  sx?: ThemeUIStyleObject;
  onCreate?: (createdOption: InputOption) => void;
  onUpdateFieldView?: (value: string) => void;
};

export type SelectProps = Props;

const defaultProps: Partial<Props> = {
  onUpdateFieldView: undefined,
  onCreate: undefined,
  creatable: false,
  searchable: false,
  alwaysHideOptions: false,
  multi: false,
  ignoreOptions: false,
  sx: undefined,
};

export const Select = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      onChange,
      options,
      creatable = false,
      searchable = false,
      alwaysHideOptions = false,
      multi = false,
      ignoreOptions = false,
      sx,
      onCreate,
      onUpdateFieldView,
      onBlur,
      name,
      value,
      size = 'default',
      defaultValue,
      ...props
    }: Props,
    ref,
  ) => {
    const forceUpdate = useForceUpdate();
    const [showOptions, setShowOptions] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [optionsFilter, setOptionsFilter] = useState<string | undefined>(undefined);
    const [inputValue, setInputValue] = useState<InputOption[] | InputOption | null>(null);
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const { theme } = useTheme();

    const isMountedRef = useIsMountedRef();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const hiddenRef = useRef<HTMLInputElement | null>(null);
    const optionsListRef = useRef<HTMLDivElement | null>(null);

    const hiddenInputValueRef = useRef<string>('');

    const isFocusRef = useRef<boolean>(false);

    const selectedOptionsRef = useRef<InputProps['value'][]>([]);
    const focusedOptionIndexRef = useRef<number | null>(null);

    const resetFocusedOptionIndex = () => {
      focusedOptionIndexRef.current = null;
      optionsListRef.current?.childNodes.forEach((child) => {
        const option = child as HTMLLIElement;
        option.setAttribute('aria-selected', 'false');
      });
    };

    const { fontSize } = useMemo(() => theme.forms.input.sizes[size], [size, theme.forms.input.sizes]);

    const onOutsideClick = useCallback(() => {
      setShowOptions(false);
    }, []);

    const onClick = () => {
      if (!props.disabled) setShowOptions(!showOptions);
    };

    const onMouseDown = async () => {
      await delay(0);
      isFocusRef.current = true;
    };

    const dispatchBlurEvent = () => {
      const blurEvent = createEvent('focusout');
      if (hiddenRef.current) hiddenRef.current.dispatchEvent(blurEvent);
    };

    const setSelectedOptions = useCallback(
      (option?: InputOption) => {
        if (!option) {
          selectedOptionsRef.current = [];
          setNativeValue(hiddenRef, '');
          dispatchBlurEvent();
          return;
        }

        if (multi) {
          if (!selectedOptionsRef.current.includes(option.id)) {
            selectedOptionsRef.current.push(option.id);
          }
          setNativeValue(hiddenRef, selectedOptionsRef.current);
          return;
        }
        setSearchInputValue(option.label);
        selectedOptionsRef.current.pop();
        selectedOptionsRef.current.push(option.id);
        setNativeValue(hiddenRef, option.id);
      },
      [multi],
    );

    const onOptionClick = useCallback(
      async (option: InputOption) => {
        setSelectedOptions(option);
        if (!multi) {
          setInputValue(option);
          setIsSearching(false);
        } else {
          let newInputValue: typeof inputValue;
          if (Array.isArray(inputValue)) {
            newInputValue = [...inputValue, option];
            setInputValue(newInputValue);
          } else if (inputValue) {
            newInputValue = [inputValue, option];
            setInputValue(newInputValue);
          } else {
            newInputValue = [option];
            setInputValue(newInputValue);
          }
        }
        setShowOptions(false);
        await delay(0);
        dispatchBlurEvent();
      },
      [inputValue, multi, setSelectedOptions],
    );

    const onClearCallback = useCallback(() => {
      setInputValue(null);
      setSelectedOptions();
      setShowOptions(true);
    }, [setSelectedOptions]);

    const prepareSearchInputValue = useCallback(() => {
      if (isSearching) {
        return searchInputValue;
      }

      if (inputValue) {
        if (Array.isArray(inputValue)) {
          if (inputValue.length === 0) {
            return '';
          }

          const newInputValue: string[] = [];

          inputValue.forEach((option) => {
            newInputValue.push(option.label);
          });

          return newInputValue;
        }
        return inputValue.label;
      }

      return '';
    }, [isSearching, inputValue, searchInputValue]);

    const initializeSelect = useCallback(
      async ({ initialValue }: { initialValue: string }) => {
        // Causes infinite loop at add request - edit. During fast pick between two elements from the list at request edit rhf ends up looping between two values.
        // await delay(0);
        if (!isMountedRef.current) return;
        const initialValues = initialValue.split(',');
        const initialOptions: Props['options'] = [];

        initialValues.forEach((initValue) => {
          initialOptions.push(...options.filter((option) => option.id === initValue));
        });

        if (initialOptions.length > 0) {
          setInputValue(initialOptions);
          initialOptions.forEach((initialOption) => {
            setSelectedOptions(initialOption);
          });
        }
      },
      [options, setSelectedOptions, isMountedRef],
    );

    const renderOptions = useCallback(
      (filter?: string) => {
        if (!options.length) {
          return (
            <OptionList ref={optionsListRef}>
              <Box as="li" sx={{ px: 3, py: 4, textAlign: 'center', cursor: 'not-allowed', fontSize }}>
                <Trans id="select.no_options">No options</Trans>
              </Box>
            </OptionList>
          );
        }
        const hasFilteredElement =
          creatable &&
          !!filter &&
          !!options.find((option) => {
            if (option.label.toLocaleLowerCase() === filter.toLocaleLowerCase()) {
              return option.label;
            }
            return option.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
          });

        const isNotFiltered = filter === undefined || filter === '';

        return (
          <OptionList ref={optionsListRef}>
            {!creatable || hasFilteredElement || isNotFiltered
              ? [
                  ...(creatable && filter && !options.find(({ label }) => _.trim(label) === _.trim(filter))
                    ? [
                        <Option
                          key="create"
                          label={`${i18n._(t({ id: 'forms.select.option_create', message: 'Create' }))} "${filter}"`}
                          onClick={() => onOptionClick({ label: filter, id: filter, isCreatable: true })}
                          onMouseEnter={() => resetFocusedOptionIndex()}
                          sx={{ fontSize }}
                        />,
                      ]
                    : []),
                  ...options.map((option) => {
                    const active = selectedOptionsRef.current.includes(option.id);

                    if (multi && active) {
                      return null;
                    }

                    if (filter && !option.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) {
                      return null;
                    }

                    return (
                      <Option
                        key={option.label}
                        label={option.label}
                        active={active}
                        onClick={() => onOptionClick(option)}
                        onMouseEnter={() => resetFocusedOptionIndex()}
                        sx={{ fontSize }}
                      />
                    );
                  }),
                ]
              : filter && (
                  <Option
                    key="create"
                    label={`${i18n._(t({ id: 'forms.select.option_create', message: 'Create' }))} "${filter}"`}
                    onClick={() => onOptionClick({ label: filter, id: filter, isCreatable: true })}
                    onMouseEnter={() => resetFocusedOptionIndex()}
                    sx={{ fontSize }}
                  />
                )}
          </OptionList>
        );
      },
      [creatable, fontSize, multi, onOptionClick, options],
    );

    const renderSelectedMultiOptions = () => {
      if (!inputValue || inputValue === []) {
        return null;
      }

      const unselectOption = (option: InputOption) => {
        if (inputValue === option || (Array.isArray(inputValue) && inputValue.includes(option))) {
          selectedOptionsRef.current = selectedOptionsRef.current.filter((optionValue) => optionValue !== option.id);
          setNativeValue(hiddenRef, selectedOptionsRef.current);

          const newInputValues =
            Array.isArray(inputValue) && inputValue.filter((optionValue) => optionValue.label !== option.label);

          setInputValue(newInputValues || null);

          dispatchBlurEvent();
        }
      };

      const iconProps = {
        size: 18,
        fontSize,
      };

      if (Array.isArray(inputValue)) {
        return inputValue.map((selectedOption) => (
          <SelectedMultiOption
            iconProps={iconProps}
            onClick={() => unselectOption(selectedOption)}
            key={`${selectedOption.label}`}
            sx={{ ...(selectedOption.isCreatable && { bg: 'skyBlues5' }), fontSize: fontSize - 1 }}
          >
            {selectedOption.label}
          </SelectedMultiOption>
        ));
      }

      return (
        <SelectedMultiOption iconProps={iconProps} onClick={() => unselectOption(inputValue)}>
          {inputValue.label}
        </SelectedMultiOption>
      );
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Enter':
          e.preventDefault();
          return;
        case 'ArrowUp':
          e.preventDefault();
          return;
        case 'ArrowDown':
          e.preventDefault();
          return;
        default:
          break;
      }

      if (searchable && isSearching) {
        setOptionsFilter(e.currentTarget.value);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (props.disabled) return;

      const optionsNode = optionsListRef.current?.childNodes;
      const maxIndex = optionsNode ? optionsNode.length - 1 : 0;

      const handleFocusOption = () =>
        optionsNode?.forEach((child, index) => {
          const option = child as HTMLLIElement;

          if (index === focusedOptionIndexRef.current) {
            option.setAttribute('aria-selected', 'true');
          } else {
            option.setAttribute('aria-selected', 'false');
          }
        });

      const handleEnterOnOption = () => {
        if (optionsNode && focusedOptionIndexRef.current !== null) {
          const clickEvent = createEvent('click');
          optionsNode[focusedOptionIndexRef.current].dispatchEvent(clickEvent);
          inputRef.current?.blur();
        }
      };

      switch (e.key) {
        case 'Enter':
          e.preventDefault();
          handleEnterOnOption();
          return;
        case 'ArrowUp':
          e.preventDefault();
          if (focusedOptionIndexRef.current === null) {
            focusedOptionIndexRef.current = maxIndex;
          } else if (focusedOptionIndexRef.current <= maxIndex && focusedOptionIndexRef.current > 0) {
            focusedOptionIndexRef.current -= 1;
          }

          handleFocusOption();
          return;
        case 'ArrowDown':
          e.preventDefault();
          if (focusedOptionIndexRef.current === null) {
            focusedOptionIndexRef.current = 0;
          } else if (focusedOptionIndexRef.current < maxIndex) {
            focusedOptionIndexRef.current += 1;
          }

          handleFocusOption();
          return;
        case 'Escape':
          e.preventDefault();
          if (showOptions) {
            setShowOptions(false);
            if (inputRef.current) inputRef.current.blur();
          }
          break;
        default:
          break;
      }
    };

    const handleBlur = async () => {
      isFocusRef.current = false;
      resetFocusedOptionIndex();

      await delay(0);
      if (!isFocusRef.current) {
        if (searchable && isSearching) {
          setIsSearching(false);
          setTimeout(() => {
            setOptionsFilter(undefined);
          }, 200);
        }
        dispatchBlurEvent();
      }
    };

    const handleFocus = () => {
      isFocusRef.current = true;
      if (searchable && !isSearching) {
        setIsSearching(true);
        if (multi && inputRef.current) inputRef.current.value = '';
      }
    };

    const wrapperSx: ThemeUIStyleObject = useMemo(
      () => ({
        cursor: props.disabled ? 'auto' : 'pointer',
        width: props.customContent ? 'fit-content' : '100%',
        input: {
          cursor: 'inherit',
        },
      }),
      [props.customContent, props.disabled],
    );

    const handleHiddenInputChange = useCallback(
      (e?: React.ChangeEvent<HTMLInputElement>, passedValue?: string) => {
        const newValue = passedValue || e?.target.value || '';

        if (newValue !== hiddenInputValueRef.current || passedValue) {
          hiddenInputValueRef.current = newValue;
          const matchedOptions = options.filter((op) => op.id === newValue);
          if (!ignoreOptions && !multi) {
            if (!matchedOptions.length) {
              const shouldForceUpdate = !!selectedOptionsRef.current.length;
              selectedOptionsRef.current = [];
              setSearchInputValue('');
              setInputValue(inputValue);
              if (shouldForceUpdate) forceUpdate();
            } else {
              setSearchInputValue(matchedOptions[0].label);
              setSelectedOptions(matchedOptions[0]);
            }
          }
          if (multi) {
            setSearchInputValue('');
          }

          if (newValue) {
            initializeSelect({ initialValue: newValue });
          } else {
            initializeSelect({ initialValue: '' });
            setInputValue(null);
          }
        }

        if (onChange && e && !passedValue) {
          onChange(e);
        }
      },
      [multi, ignoreOptions, onChange, initializeSelect, options, setSelectedOptions, inputValue, forceUpdate],
    );

    const handleUpdateFieldView = useCallback(
      (newValue: string) => {
        handleHiddenInputChange(undefined, newValue);
        if (onUpdateFieldView) {
          onUpdateFieldView(newValue);
        }
      },
      [handleHiddenInputChange, onUpdateFieldView],
    );

    useOnUpdateFieldView(hiddenRef, handleUpdateFieldView);

    useEffect(() => {
      if (hiddenRef.current) {
        const initialValue = hiddenRef.current.value;
        if (initialValue) {
          emitUpdateFieldView(hiddenRef);
        }
      }
    }, []);

    const handleHiddenInputFocus = useCallback(() => {
      if (!props.customContent && !props.focusThiefElement) {
        inputRef.current?.focus();
      }
      if (props.focusThiefElement) {
        props.focusThiefElement.focus();
      }
      if (!alwaysHideOptions) {
        setShowOptions(true);
      }
    }, [props.customContent, props.focusThiefElement, alwaysHideOptions]);

    const handleHiddenInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (!ignoreOptions && !multi && !isFocusRef.current) {
        if (selectedOptionsRef.current.length) {
          const matchedOptions = options.filter((op) => op.id === selectedOptionsRef.current[0]);
          if (matchedOptions.length) {
            setSearchInputValue(matchedOptions[0].label);
          }
        } else {
          setSearchInputValue('');
        }
      }

      if (multi) {
        setSearchInputValue('');
      }

      if (onBlur) {
        onBlur(e);
      }
    };

    const onOwnValueChange = useCallback(
      (newValue: InputOwnValue) => {
        let parsedNewValue = newValue;
        if (_.isNil(newValue)) {
          parsedNewValue = '';
        }
        if (_.isArray(newValue)) {
          parsedNewValue = newValue.join(',');
        }
        handleUpdateFieldView(`${parsedNewValue}`);
      },
      [handleUpdateFieldView],
    );

    useOnOwnValueChange(hiddenRef, onOwnValueChange);

    return (
      <>
        <input
          name={name}
          ref={mergeRefs([ref, hiddenRef])}
          onChange={handleHiddenInputChange}
          onFocus={handleHiddenInputFocus}
          onBlur={handleHiddenInputBlur}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
          defaultValue={defaultValue}
          readOnly
        />
        <FlexWithPopper
          sx={{ ...wrapperSx, ...(sx && sx) }}
          popperProps={{
            popperContainerSx: {
              minWidth: '100px',
            },
            popperGuardValue: true,
            content: renderOptions(optionsFilter),
            placement: 'bottom-start',
            trigger: 'manual',
            popperMargin: -0.3,
            widthLikeReferenceElement: true,
            onOutsideClick,
            visible: !alwaysHideOptions && showOptions && !props.disabled && !!options.length,
          }}
          onMouseDown={onMouseDown}
          onClick={onClick}
        >
          <TextInput
            {...props}
            usedAsDisplay
            controllerHasValue={!!hiddenRef.current?.value}
            sxOverwrite={{
              ...(!(creatable || searchable) && {
                cursor: 'pointer !important',
              }),
              ...props.sxOverwrite,
            }}
            size={size}
            ref={inputRef}
            id={id}
            type="text"
            inputProps={{
              readOnly: !(creatable || searchable),
              ...(props.inputProps && props.inputProps),
              sx: {
                ...(multi && !isSearching && { color: 'transparent !important' }),
                ...(props.inputProps?.sx && props.inputProps.sx),
              },
              value: prepareSearchInputValue(),
              onChange: (e) => setSearchInputValue(e.target.value),
            }}
            autoComplete="new-password"
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onClearCallback={onClearCallback}
            prependWith={props.prependWith || (multi && renderSelectedMultiOptions())}
            apendWith={
              !alwaysHideOptions ? (
                <Icon type={showOptions ? 'chevronUp' : 'chevronDown'} wrapperSx={{ cursor: 'pointer' }} />
              ) : undefined
            }
          />
        </FlexWithPopper>
      </>
    );
  },
);

Select.defaultProps = defaultProps;
