import React from 'react';
import { Flex, Text, FlexOwnProps, FlexProps } from 'theme-ui';

type Props = {
  variant?: 'solid' | 'outline';
  color: string;
  children: React.ReactNode;
  title: FlexProps['title'];
} & FlexOwnProps;

export type TagProps = Props;

const defaultProps: Partial<Props> = {
  variant: 'solid',
};

export const Tag = ({ children, color, variant, sx, ...props }: Props): React.ReactElement => (
  <Flex
    as="span"
    variant={`tag.${variant}`}
    {...props}
    sx={{
      ...(variant === 'solid' ? { bg: color } : { borderColor: color, color, bg: 'alphas.lighter8' }),
      ...(sx && sx),
    }}
  >
    <Text as="span" data-text>
      {children}
    </Text>
  </Flex>
);

Tag.defaultProps = defaultProps;
