import _ from 'lodash';

import { FetchUserSessionResponse } from 'api/actions/userSession/userSessionActions.types';

import { SupportedLanguages } from './supportedLanguages';

export const LANGUAGE_DEFAULT_VALUE = SupportedLanguages.en;

export const USER_SESSION_DEFAULT_VALUE: FetchUserSessionResponse = {
  personId: '',
  timeZoneId: '',
  preferredLanguage: LANGUAGE_DEFAULT_VALUE,
  dateFormat: 0,
  timeFormat: 0,
  nameDisplayOrder: 0,
};

export const FEATURES_DEFAULT_VALUE = _.times(7).map((f, i) => ({
  type: i === 6 ? 7 : i,
  value: true,
}));
