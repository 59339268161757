import { motion, MotionProps } from 'framer-motion';
import React, { useMemo } from 'react';
import { BoxOwnProps, Flex, Text } from 'theme-ui';
import { Link, useLocation } from 'react-router-dom';

import { useTheme } from 'styles/useTheme';
import { Icon } from 'components/Icon/Icon';
import { Avatar, AvatarProps } from 'components/Avatar/Avatar';

const AnimatedBox = motion(Flex as React.FC<unknown>) as React.FC<AnimatedBoxProps>;

type AnimatedBoxProps = MotionProps | BoxOwnProps | { disabled: boolean };

type Props = BoxOwnProps & {
  label: string;
  to: string;
  shape?: 'rounded' | 'square';
  additionalLabel?: string;
  disabled?: boolean;
  avatarProps?: Pick<AvatarProps, 'name' | 'image'>;
};

const defaultProps: Partial<Props> = {
  shape: 'square',
  additionalLabel: undefined,
  disabled: false,
  avatarProps: undefined,
};

export const NavLink = ({
  label,
  to,
  shape,
  additionalLabel,
  avatarProps,
  disabled,
  sx,
  ...props
}: Props): React.ReactElement => {
  const { theme } = useTheme();

  const { pathname } = useLocation();

  const isActive = useMemo(() => !disabled && to === pathname, [disabled, pathname, to]);

  return (
    <Link to={to} style={{ cursor: 'default' }} onClick={disabled || isActive ? (e) => e.preventDefault() : undefined}>
      <AnimatedBox
        {...props}
        whileTap={{
          scale: isActive ? 1 : 0.985,
        }}
        transition={{ duration: 0.1 }}
        disabled={disabled}
        sx={{
          display: 'inline-flex',
          minWidth: 'auto',
          appearance: 'none',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'nowrap',
          textAlign: 'center',
          fontFamily: 'inherit',
          textDecoration: 'none',
          fontSize: 'inherit',
          border: 0,
          outline: 'none',
          fontWeight: 'bold',
          transition: 'background 0.2s ease-in-out',
          p: '0.75rem',
          color: isActive ? 'settingsNavLink.text.active' : 'settingsNavLink.text.default',
          height: avatarProps ? '75px' : '45px',
          bg: isActive ? 'settingsNavLink.background.active' : 'settingsNavLink.background.default',
          borderRadius: shape === 'rounded' ? 'default' : 0,
          ...(!disabled && !isActive && { cursor: 'pointer' }),
          '&:hover, &:focus': {
            bg: isActive ? 'settingsNavLink.background.active' : 'settingsNavLink.background.hover',
          },
          '&:active': {
            bg: 'settingsNavLink.background.active',
            '& > span': {
              color: 'settingsNavLink.text.active',
            },
            '& > div > span': {
              color: 'settingsNavLink.text.active',
            },
            '& > span > svg': {
              fill: 'settingsNavLink.text.active',
            },
          },
          '&:disabled, &[disabled]': {
            pointerEvents: 'none',
            opacity: 0.4,
          },
          ...(sx && sx),
        }}
      >
        {avatarProps && (
          <Avatar
            {...avatarProps}
            circle
            size={48}
            sx={{
              minWidth: '48px',
              color: isActive ? 'settingsNavLink.text.active' : 'avatar.text',
              mr: theme.buttons.sizes.default.py,
              ...(isActive && { svg: { color: 'settingsNavLink.text.active' } }),
            }}
          />
        )}
        <Flex
          sx={{
            flexWrap: 'wrap',
            flexGrow: 1,
            mr: theme.buttons.sizes.default.py,
          }}
        >
          {label && (
            <Text
              sx={{
                fontSize: avatarProps ? 3 : 2,
                flexBasis: '100%',
                textAlign: 'left',
              }}
            >
              {label}
            </Text>
          )}
          {additionalLabel && (
            <Text
              sx={{ fontWeight: 400, fontSize: 1, ...(!isActive && { color: 'settingsNavLink.text.additionalLabel' }) }}
            >
              {additionalLabel}
            </Text>
          )}
        </Flex>
        <Icon
          type="chevronRight"
          fill={isActive ? 'settingsNavLink.text.active' : 'settingsNavLink.text.additionalLabel'}
        />
      </AnimatedBox>
    </Link>
  );
};

NavLink.defaultProps = defaultProps;
