import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import { requestsTimeTrackingSettingsAction } from 'api/actions/settings/settingsActions';
import {
  FetchRequestsSettingsResponse,
  RequestsTimeTrackingSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { Divider } from 'components/Divider/Divider';
import { Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { payloadSelectorFamily, resetFormButtonAtom, rolesApproveRequestsSelectOptionsSelector } from 'state/settings';
import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { Paths } from '../../utils/createUnionOfTypeKeys';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';
import { addSnackbar } from 'SnackbarHub/actions';

type keys = Paths<RequestsTimeTrackingSettingsActionProps>;

type Props = {
  payload: RequestsTimeTrackingSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  timeTrackingShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  timeTrackingFormPendingRef: React.MutableRefObject<boolean>;
  timeTrackingStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TimeTracking = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      timeTrackingShouldBlockTransitionRef,
      timeTrackingFormPendingRef,
      timeTrackingStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchRequestsSettingsResponse>(payloadSelectorFamily('REQUESTS'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const rolesSelectOptions = useRecoilValue(rolesApproveRequestsSelectOptionsSelector);
    const { mutate } = useMutation(requestsTimeTrackingSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<RequestsTimeTrackingSettingsActionProps>(payload);

    const { twoStepApprovalFlow, firstStepRoleId, secondStepRoleId } = payload.timeTrackingApprovalSettings;
    const payloadComparisonObject: RequestsTimeTrackingSettingsActionProps = useMemo(
      () => ({ ...payload, timeTrackingApprovalSettings: { twoStepApprovalFlow, firstStepRoleId, secondStepRoleId } }),
      [firstStepRoleId, payload, secondStepRoleId, twoStepApprovalFlow],
    );

    const {
      register,
      handleSubmit,
      setError,
      watch,
      reset: resetForm,
      formState: { errors },
    } = useForm({
      defaultValues: payload,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const twoStepApprovalWatch = watch('timeTrackingApprovalSettings.twoStepApprovalFlow');
    const firstStepRoleIdWatch = watch('timeTrackingApprovalSettings.firstStepRoleId');
    const secondStepRoleIdWatch = watch('timeTrackingApprovalSettings.secondStepRoleId');

    const dispatchSubmitEvent = useCallback(() => {
      const submitEvent = createEvent('submit');
      timeTrackingFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    }, [timeTrackingFormPendingRef]);

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnChange = useCallback(() => {
      timeTrackingShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, reset, start, timeTrackingShouldBlockTransitionRef]);

    const handleOnBlur = useCallback(() => {
      timeTrackingShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, reset, start, timeTrackingShouldBlockTransitionRef]);

    const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          handleOnChange();
        },
      };
    };

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      resetForm({
        ...payload,
      });

      timeTrackingShouldBlockTransitionRef.current = false;
      timeTrackingFormPendingRef.current = false;
    }, [payload, resetForm, timeTrackingFormPendingRef, timeTrackingShouldBlockTransitionRef]);

    const handleValidation = useCallback(
      (data: RequestsTimeTrackingSettingsActionProps) => {
        const dataFirstStepRoleId = data.timeTrackingApprovalSettings.firstStepRoleId;
        const dataSecondStepRoleId = data.timeTrackingApprovalSettings.secondStepRoleId;
        const dataTwoStepApprovalFlow = data.timeTrackingApprovalSettings.twoStepApprovalFlow;

        if (dataTwoStepApprovalFlow && (!dataFirstStepRoleId || !dataSecondStepRoleId)) {
          setError('timeTrackingApprovalSettings.firstStepRoleId', {
            type: 'validate',
          });
          setError('timeTrackingApprovalSettings.secondStepRoleId', {
            type: 'validate',
            message: t({ id: 'settings.forms.step_empty' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleSubmitCallback = useCallback(
      async (data: RequestsTimeTrackingSettingsActionProps) => {
        const validation = handleValidation(data);
        const timeTrackingResetObject = { name: 'TIME_TRACKING_CALLBACK', callback: handleFormReset };
        const dataComparisonObject: RequestsTimeTrackingSettingsActionProps = {
          ...data,
          timeTrackingApprovalSettings: {
            twoStepApprovalFlow: data.timeTrackingApprovalSettings.twoStepApprovalFlow,
            firstStepRoleId: data.timeTrackingApprovalSettings.firstStepRoleId?.length
              ? data.timeTrackingApprovalSettings.firstStepRoleId
              : undefined,
            secondStepRoleId: data.timeTrackingApprovalSettings.secondStepRoleId?.length
              ? data.timeTrackingApprovalSettings.secondStepRoleId
              : undefined,
          },
        };
        reset();

        if (validation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === timeTrackingResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(dataComparisonObject, payloadComparisonObject) && validation) {
          const { error } = await mutate(dataComparisonObject);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...dataComparisonObject }));
            mutationDataRef.current = dataComparisonObject;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [timeTrackingResetObject] : [...prevState, timeTrackingResetObject],
            );
            return;
          }
        }

        if (!validation) {
          setResetCallbacks((prevState) =>
            !prevState ? [timeTrackingResetObject] : [...prevState, timeTrackingResetObject],
          );
          return;
        }

        timeTrackingShouldBlockTransitionRef.current = false;
        timeTrackingFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        handleFormReset,
        handleValidation,
        mutate,
        payloadComparisonObject,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
        timeTrackingFormPendingRef,
        timeTrackingShouldBlockTransitionRef,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payloadComparisonObject)) handleFormReset();
    }, [handleFormReset, payload, payloadComparisonObject]);

    useEffect(() => {
      timeTrackingStatusRef.current = status;
    }, [status, timeTrackingStatusRef]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    return (
      <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <HeadingError
            label={t({
              id: 'requests_settings.heading.time_tracking',
              message: 'Time Tracking',
            })}
            errorMessage={errors.timeTrackingApprovalSettings?.secondStepRoleId?.message}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <Switch
              {...registerOnChange('allowChangeRequestForLoggedClockInsAndOuts')}
              label={t({
                id: 'requests_settings.allow_change_request_for_logged.label',
                message: 'Allow change request for logged Clock-Ins and Outs',
              })}
              size="sm"
              bold
              withDivider
            />
            <Switch
              {...registerOnChange('timeTrackingApprovalSettings.twoStepApprovalFlow')}
              label={t({
                id: 'requests_settings.two_step_approval_flow.label',
              })}
              additionalInfo={
                <Trans id="requests_settings.two_step_approval_flow.additionalInfo">
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    Changing this setting will affect all not accepted requests
                  </Text>
                  .
                </Trans>
              }
              size="sm"
            />
            {twoStepApprovalWatch && (
              <>
                <OptionLabel
                  label={t({
                    id: 'requests_settings.first_step_role_id.label',
                  })}
                  apendWith={
                    <>
                      {rolesSelectOptions && (
                        <Select
                          {...registerOnBlur('timeTrackingApprovalSettings.firstStepRoleId')}
                          id="timeTrackingApprovalSettings.firstStepRoleId"
                          placeholder={t({
                            id: 'requests_settings.first_step_role_id.label',
                          })}
                          options={_.filter(rolesSelectOptions, (item) => item.id !== secondStepRoleIdWatch)}
                          size="sm"
                          error={!!errors.timeTrackingApprovalSettings?.firstStepRoleId}
                          sx={{ maxWidth: '188px' }}
                        />
                      )}
                    </>
                  }
                />
                <OptionLabel
                  label={t({
                    id: 'requests_settings.second_step_role_id.label',
                  })}
                  apendWith={
                    <>
                      {rolesSelectOptions && (
                        <Select
                          {...registerOnBlur('timeTrackingApprovalSettings.secondStepRoleId')}
                          id="timeTrackingApprovalSettings.secondStepRoleId"
                          placeholder={t({
                            id: 'requests_settings.second_step_role_id.label',
                          })}
                          options={_.filter(rolesSelectOptions, (item) => item.id !== firstStepRoleIdWatch)}
                          size="sm"
                          error={!!errors.timeTrackingApprovalSettings?.secondStepRoleId}
                          sx={{ maxWidth: '188px' }}
                        />
                      )}
                    </>
                  }
                />
              </>
            )}
            <Divider />
          </Flex>
        </Flex>
      </form>
    );
  },
);

export const MemoizedTimeTracking = React.memo(TimeTracking);
