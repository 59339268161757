import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import { LinkProps } from 'react-router-dom';

import { AnchorButton, AnchorButtonProps } from './AnchorButton';

type Props = AnchorButtonProps & {
  to: LinkProps['to'];
  toValidation?: () => boolean;
  replace?: boolean;
};

export type LinkButtonProps = Props;

const defaultProps: Partial<Props> = {
  toValidation: undefined,
  replace: false,
};

export const LinkButton = React.forwardRef<HTMLAnchorElement, Props>(
  ({ to, children, onClick, toValidation, replace, ...props }: Props, ref) => {
    const { push, replace: replaceHistory, location } = useHistory();

    const internalOnClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      if (toValidation && !toValidation()) {
        return;
      }

      if (replace) {
        replaceHistory(to);
      } else {
        push(to, { from: location.pathname });
      }

      if (onClick) onClick(e);
    };
    return (
      <AnchorButton ref={ref} onClick={internalOnClick} {...props} href={_.get(to, 'pathname') || to}>
        {children}
      </AnchorButton>
    );
  },
);

LinkButton.defaultProps = defaultProps;
