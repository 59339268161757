import _ from 'lodash';

import { makeTheme } from 'styles/makeTheme';

import { attendancePillStyles } from './attendancePill';
import { base } from './base';
import { buttons } from './buttons';
import { cards } from './cards';
import { chatStyles } from './chat';
import { colors } from './colors';
import { customComponents } from './customComponents';
import { fileUploadStyles } from './fileUpload';
import { forms } from './forms';
import { layouts } from './layouts';
import { reportStyles } from './reports';
import { stickyListStyles } from './stickyList';
import { text } from './text';

const mergedTheme = _.merge(
  {
    ...colors,
  },
  {
    ...base,
    ...buttons,
    ...cards,
    ...customComponents,
    ...layouts,
    ...chatStyles,
    ...stickyListStyles,
    ...attendancePillStyles,
    ...fileUploadStyles,
    ...reportStyles,
    ...forms,
    ...text,
  },
);

export const theme = makeTheme(mergedTheme);
