import { Trans } from '@lingui/macro';
import _ from 'lodash';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Flex, FlexProps } from 'theme-ui';

import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { TeammatesFilters } from 'layouts/AuthorizedApp/AsideFilters/types';
import { ADD_REQUEST_PICK_TEAMMATES_LIST } from 'layouts/Requests/constans';
import { addRequestSearchFilterValueSelector, isActiveTeammatesFilterSelectorFamily } from 'state/requests';
import { DeselectMenu } from 'layouts/AuthorizedApp/Main/Header/DeselectMenu';
import { Modal } from 'components/Modal/Modal';

const ButtonWithDropdown = withDropdown(Button);

export const AddRequestStep1HeaderMenu = ({ sx, ...props }: FlexProps): React.ReactElement => {
  const setSearchFilterValue = useSetRecoilState(addRequestSearchFilterValueSelector);

  const saveInputValueToRecoil = _.debounce((value: string) => {
    setSearchFilterValue(value);
  }, 400);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    saveInputValueToRecoil(value);
  };

  const [isActiveTeammatesHidden, setIsActiveTeammatesHidden] = useRecoilState(
    isActiveTeammatesFilterSelectorFamily(TeammatesFilters.HIDDEN),
  );
  const [isActiveTeammatesDeactivated, setIsActiveTeammatesDeactivated] = useRecoilState(
    isActiveTeammatesFilterSelectorFamily(TeammatesFilters.DEACTIVATED),
  );

  return (
    <Flex
      sx={{
        alignItems: 'center',
        ...sx,
      }}
      {...props}
    >
      <Flex sx={{ flexGrow: 1, flexDirection: 'column' }}>
        <Modal.SubTitle>
          <Trans id="requests.add_request.pick_teammates">Pick teammate(s)</Trans>
        </Modal.SubTitle>
        <DeselectMenu selectorFamilyID={ADD_REQUEST_PICK_TEAMMATES_LIST} />
      </Flex>

      <Flex sx={{ gap: 1 }}>
        <ButtonWithDropdown
          dropdownProps={{
            links: [
              {
                label: isActiveTeammatesHidden ? 'Hide hidden teammates' : 'Show hidden teammates',
                onClick: () => setIsActiveTeammatesHidden(!isActiveTeammatesHidden),
              },
              {
                label: isActiveTeammatesDeactivated ? 'Hide deactivated teammates' : 'Show deactivated teammates',
                onClick: () => setIsActiveTeammatesDeactivated(!isActiveTeammatesDeactivated),
              },
            ],
          }}
          shape="rounded"
          size="sm"
          variant="grey"
          prependWith={<Icon type="filter" />}
        >
          <Trans id="filters">Filters</Trans>
        </ButtonWithDropdown>

        <TextInput
          onChange={handleSearchInputChange}
          size="xs"
          id="searchInput"
          placeholder="Search"
          icon="search"
          clearable
          sx={{
            borderRadius: 'xs',
          }}
        />
      </Flex>
    </Flex>
  );
};
