import React from 'react';
import { Global, keyframes } from '@emotion/react';
import { Flex } from 'theme-ui';
import { RouteProps } from 'react-router';

import { Logo } from 'components/Logo/Logo';

import { Footer } from './Footer';

type Props = {
  children: RouteProps['children'];
  showFooter?: boolean;
  showLogo?: boolean;
};

const defaultProps: Partial<Props> = {
  showFooter: false,
  showLogo: true,
};

const positionAnimation = keyframes({
  '0%': {
    backgroundPosition: '25% 50%',
  },
  '100%': {
    backgroundPosition: '75% 50%',
  },
});
const sizeAnimation = keyframes({
  '0%': {
    backgroundSize: '180% 180%',
  },
  '100%': {
    backgroundSize: '120% 180%',
  },
});

export const AuthenticationLayout = ({ children, showFooter, showLogo }: Props): React.ReactElement => (
  <>
    <Global
      styles={(theme) => ({
        'html, body': {
          backgroundAttachment: 'fixed',
          background: 'white',
          '@media screen and (min-width: 768px)': {
            background: `linear-gradient(300deg, ${theme.colors.grayBlues[4]}, ${theme.colors.whites[4]}, ${theme.colors.whites[1]}, ${theme.colors.whites[4]}, ${theme.colors.grayBlues[4]})`,
            animation: `${positionAnimation} 18s ease-in-out infinite alternate, ${sizeAnimation} 9s ease infinite alternate`,
          },
        },
      })}
    />
    <Flex
      sx={{
        minHeight: '100%',
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      {showLogo && (
        <Flex sx={{ width: '100% ', pt: [4, null, '3.25rem'], pb: [null, null, 3], justifyContent: 'center' }}>
          <Logo sx={{ fill: 'skyBlues5', height: 48, width: 'auto' }} />
        </Flex>
      )}

      <Flex sx={{ flexDirection: 'row', flexGrow: 1, justifyContent: 'center' }}>{children}</Flex>

      {showFooter && <Footer />}
    </Flex>
  </>
);

AuthenticationLayout.defaultProps = defaultProps;
