import React, { useCallback, useRef, useMemo, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { RegisterOptions, useForm } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useMutation } from 'react-fetching-library';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import { CustomSettingsActionProps, FetchRequestsSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { payloadSelectorFamily, resetFormButtonAtom, rolesApproveRequestsSelectOptionsSelector } from 'state/settings';
import { Paths } from '../../utils/createUnionOfTypeKeys';
import { createEvent } from 'utils/createEvent';
import { Switch } from 'components/ui/Switch';
import { Divider } from 'components/Divider/Divider';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { Select } from 'components/ui/Select/Select';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { customSettingsAction } from 'api/actions/settings/settingsActions';
import { mergeRefs } from 'utils/mergeRefs';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';
import { addSnackbar } from 'SnackbarHub/actions';

type keys = Paths<CustomSettingsActionProps>;

type Props = {
  payload: CustomSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  customShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  customBlockFormPendingRef: React.MutableRefObject<boolean>;
  customBlockStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Custom = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      customShouldBlockTransitionRef,
      customBlockFormPendingRef,
      customBlockStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchRequestsSettingsResponse>(payloadSelectorFamily('REQUESTS'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const rolesSelectOptions = useRecoilValue(rolesApproveRequestsSelectOptionsSelector);
    const { mutate } = useMutation(customSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<CustomSettingsActionProps>(payload);

    const { twoStepApprovalFlow, firstStepRoleId, secondStepRoleId } = payload.customRequestsApprovalSettings;
    const payloadComparisonObject: CustomSettingsActionProps = useMemo(
      () => ({ customRequestsApprovalSettings: { twoStepApprovalFlow, firstStepRoleId, secondStepRoleId } }),
      [firstStepRoleId, secondStepRoleId, twoStepApprovalFlow],
    );

    const {
      register,
      handleSubmit,
      setError,
      watch,
      reset: resetForm,
      formState: { errors },
    } = useForm({
      defaultValues: payload,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const twoStepApprovalWatch = watch('customRequestsApprovalSettings.twoStepApprovalFlow');
    const firstStepRoleIdWatch = watch('customRequestsApprovalSettings.firstStepRoleId');
    const secondStepRoleIdWatch = watch('customRequestsApprovalSettings.secondStepRoleId');

    const dispatchSubmitEvent = useCallback(() => {
      const submitEvent = createEvent('submit');
      customBlockFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    }, [customBlockFormPendingRef]);

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnChange = useCallback(() => {
      customShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, customShouldBlockTransitionRef, reset, start]);

    const handleOnBlur = useCallback(() => {
      customShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, customShouldBlockTransitionRef, reset, start]);

    const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          handleOnChange();
        },
      };
    };

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      resetForm({ ...payload });
      customShouldBlockTransitionRef.current = false;
      customBlockFormPendingRef.current = false;
    }, [customBlockFormPendingRef, customShouldBlockTransitionRef, payload, resetForm]);

    const handleValidation = useCallback(
      (data: CustomSettingsActionProps) => {
        const dataFirstStepRoleId = data.customRequestsApprovalSettings.firstStepRoleId;
        const dataSecondStepRoleId = data.customRequestsApprovalSettings.secondStepRoleId;
        const dataTwoStepApprovalFlow = data.customRequestsApprovalSettings.twoStepApprovalFlow;

        if (dataTwoStepApprovalFlow && (!dataFirstStepRoleId || !dataSecondStepRoleId)) {
          setError('customRequestsApprovalSettings.firstStepRoleId', {
            type: 'validate',
          });
          setError('customRequestsApprovalSettings.secondStepRoleId', {
            type: 'validate',
            message: t({ id: 'settings.forms.step_empty' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleSubmitCallback = useCallback(
      async (data: CustomSettingsActionProps) => {
        const validation = handleValidation(data);
        const customResetObject = { name: 'CUSTOM_CALLBACK', callback: handleFormReset };
        const dataComparisonObject: CustomSettingsActionProps = {
          customRequestsApprovalSettings: {
            twoStepApprovalFlow: data.customRequestsApprovalSettings.twoStepApprovalFlow,
            firstStepRoleId: data.customRequestsApprovalSettings.firstStepRoleId?.length
              ? data.customRequestsApprovalSettings.firstStepRoleId
              : undefined,
            secondStepRoleId: data.customRequestsApprovalSettings.secondStepRoleId?.length
              ? data.customRequestsApprovalSettings.secondStepRoleId
              : undefined,
          },
        };
        reset();

        if (validation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === customResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(dataComparisonObject, payloadComparisonObject) && validation) {
          const { error } = await mutate(data);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...dataComparisonObject }));
            mutationDataRef.current = dataComparisonObject;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) => (!prevState ? [customResetObject] : [...prevState, customResetObject]));
            return;
          }
        }

        if (!validation) {
          setResetCallbacks((prevState) => (!prevState ? [customResetObject] : [...prevState, customResetObject]));
          return;
        }

        customShouldBlockTransitionRef.current = false;
        customBlockFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        customBlockFormPendingRef,
        customShouldBlockTransitionRef,
        handleFormReset,
        handleValidation,
        mutate,
        payloadComparisonObject,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payloadComparisonObject)) handleFormReset();
    }, [handleFormReset, payload, payloadComparisonObject]);

    useEffect(() => {
      customBlockStatusRef.current = status;
    }, [status, customBlockStatusRef]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    return (
      <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <HeadingError
            label={t({
              id: 'requests_settings.heading.custom',
              message: 'Custom',
            })}
            errorMessage={errors.customRequestsApprovalSettings?.secondStepRoleId?.message}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <Switch
              {...registerOnChange('customRequestsApprovalSettings.twoStepApprovalFlow')}
              label={t({
                id: 'requests_settings.two_step_approval_flow.label',
              })}
              additionalInfo={
                <Trans id="requests_settings.two_step_approval_flow.additionalInfo">
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    Changing this setting will affect all not accepted requests
                  </Text>
                  .
                </Trans>
              }
              size="sm"
            />
            {twoStepApprovalWatch && (
              <>
                <OptionLabel
                  label={t({
                    id: 'requests_settings.first_step_role_id.label',
                  })}
                  apendWith={
                    <>
                      {rolesSelectOptions && (
                        <Select
                          {...registerOnBlur('customRequestsApprovalSettings.firstStepRoleId')}
                          id="customRequestsApprovalSettings.firstStepRoleId"
                          placeholder={t({
                            id: 'requests_settings.first_step_role_id.label',
                          })}
                          options={_.filter(rolesSelectOptions, (item) => item.id !== secondStepRoleIdWatch)}
                          size="sm"
                          error={!!errors.customRequestsApprovalSettings?.firstStepRoleId}
                          sx={{ maxWidth: '188px' }}
                        />
                      )}
                    </>
                  }
                />
                <OptionLabel
                  label={t({
                    id: 'requests_settings.second_step_role_id.label',
                  })}
                  apendWith={
                    <>
                      {rolesSelectOptions && (
                        <Select
                          {...registerOnBlur('customRequestsApprovalSettings.secondStepRoleId')}
                          id="customRequestsApprovalSettings.secondStepRoleId"
                          placeholder={t({
                            id: 'requests_settings.second_step_role_id.label',
                          })}
                          options={_.filter(rolesSelectOptions, (item) => item.id !== firstStepRoleIdWatch)}
                          size="sm"
                          error={!!errors.customRequestsApprovalSettings?.secondStepRoleId}
                          sx={{ maxWidth: '188px' }}
                        />
                      )}
                    </>
                  }
                />
              </>
            )}
            <Divider />
          </Flex>
        </Flex>
      </form>
    );
  },
);

export const MemoizedCustom = React.memo(Custom);
