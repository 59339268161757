import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { Plural, t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { deleteWebhookAction } from 'api/actions/webhook/webhookActions';
import { Webhook } from 'api/actions/webhook/webhooksActions.types';
import { useModal } from 'hooks/useModal/useModal';
import { DeleteDeactivateModal } from 'layouts/Team/modals/modalsElements/DeleteDeactivateModal';
import { payloadSelectorFamily, refreshSelectorFamily } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';

import { webhookTypeTranslationId } from './webhookTypeTranslationId';

export const DeleteWebhooksModal = (): React.ReactElement => {
  useLingui();
  const { state } = useLocation<{ ids: string[] }>();
  const { handleClose } = useModal();
  const webhooks = useRecoilValue<Webhook[]>(payloadSelectorFamily('WEBHOOKS'));
  const refreshWebhooks = useRecoilValue(refreshSelectorFamily('WEBHOOKS'));

  const titleRenderer = useCallback(
    (idsCount: number) =>
      idsCount > 1
        ? t({ id: 'settings.webhook.delete_webhooks', message: 'Delete Webhooks?' })
        : t({ id: 'settings.webhook.delete_webhook', message: `Delete Webhook?` }),
    [],
  );

  const contentRenderer = useCallback(
    (count: number, selectedNames: string[]) => (
      <Trans id="settings.webhook.delete_description">
        <Text>
          You're about to permanently delete{' '}
          <Plural
            value={count}
            one="this webhook."
            other={
              <Trans>
                webhooks: <strong>{selectedNames.join(', ')}.</strong>
              </Trans>
            }
          />{' '}
          <strong>This action cannot be reversed.</strong>
        </Text>
      </Trans>
    ),
    [],
  );

  const nameParser = useCallback(
    (id: string) => {
      const webhook = webhooks.find(({ id: itemId }) => itemId === id);
      return webhook ? t({ id: webhookTypeTranslationId[webhook.type] }) : '';
    },
    [webhooks],
  );

  const onResponseCallback = (error: boolean) => {
    if (!error) {
      refreshWebhooks();
    }

    if (error) {
      addSnackbar({
        message: t({
          id: 'settings.webhook.delete_webhook_danger',
          message: "Couldn't delete webhook",
        }),
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (!state && handleClose) {
      handleClose();
    }
  }, [handleClose, state]);

  return (
    <DeleteDeactivateModal
      list={webhooks}
      action={deleteWebhookAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
      nameParser={nameParser}
      onResponseCallback={onResponseCallback}
    />
  );
};
