import { atom, selector } from 'recoil';
import { t } from '@lingui/macro';
import _ from 'lodash';

import { guardRecoilDefaultValue } from 'utils/guardRecoilDefaultValue';
import {
  EmployeeInfo,
  FetchEmployeeDetailsResponse,
  Address,
  AdvancedEmployeeInfo,
} from 'api/actions/employees/employeesActions.types';
import { InputOption } from 'components/ui/Select/Select';

import { rolesSelector, tagsSelector, workPositionsSelector } from './employees';
import { languageSelector } from './recoilState';
import { userSessionAtom } from './userSession';

export const hiddenColumnsTeamViewSelector = selector({
  key: 'hiddenColumnsTeamView',
  get: ({ get }) => {
    const defaultValue = ['customEmployeeId'];
    const userSession = get(userSessionAtom);
    const hiddenColumns = userSession?.viewsSettings?.team?.hiddenColumns;

    return hiddenColumns || defaultValue;
  },
});

export const editedEmployeeDetailsAtom = atom<
  | (Omit<FetchEmployeeDetailsResponse, 'defaultWorkPositionId'> & {
      defaultWorkPosition: FetchEmployeeDetailsResponse['defaultWorkPositionId'];
    })
  | null
>({
  key: 'editedEmployeeDetailsSate',
  default: null,
});
export const editEmployeeFormStateAtom = atom<FetchEmployeeDetailsResponse | null>({
  key: 'editEmployeeFormState',
  default: null,
});

export type ProfileFormState = Omit<EmployeeInfo, 'address'> &
  Address &
  Pick<FetchEmployeeDetailsResponse, 'tagsIds' | 'roleId'> & {
    defaultWorkPosition: FetchEmployeeDetailsResponse['defaultWorkPositionId'];
  };

export const profileFormStateSelector = selector<ProfileFormState | null>({
  key: 'profileFormState',
  get: ({ get }) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails) return null;

    const {
      employeeInfo: { firstName, surname, phoneNumber, email, note, address },
      tagsIds,
      roleId,
      defaultWorkPosition,
    } = editedEmployeeDetails;

    return {
      firstName,
      surname,
      phoneNumber,
      email,
      note,
      postalCode: address?.postalCode,
      city: address?.city,
      street: address?.street,
      tagsIds,
      roleId,
      defaultWorkPosition,
    };
  },
  set: ({ set, get }, newValue) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails || !newValue || guardRecoilDefaultValue(newValue)) return;
    const {
      firstName,
      surname,
      phoneNumber,
      email,
      note,
      postalCode,
      city,
      street,
      tagsIds,
      roleId,
      defaultWorkPosition,
    } = newValue;

    set(editedEmployeeDetailsAtom, {
      ...editedEmployeeDetails,
      employeeInfo: {
        firstName,
        surname,
        phoneNumber,
        email,
        note,
        address: {
          postalCode,
          city,
          street,
        },
      },
      tagsIds,
      roleId,
      defaultWorkPosition,
    });
  },
});

export type EmployeeAdvancedDetailsFormState = AdvancedEmployeeInfo;
export const employeeAdvancedDetailsFormStateSelector = selector<EmployeeAdvancedDetailsFormState | null>({
  key: 'employeeAdvancedDetailsFormState',
  get: ({ get }) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails) return null;

    const {
      advancedEmployeeInfo: { features, hideOnList, identity, visibilityLevel, visibilityLevelInheritedFromTag },
    } = editedEmployeeDetails;

    return {
      hideOnList,
      identity,
      features,
      visibilityLevel,
      visibilityLevelInheritedFromTag,
    };
  },
  set: ({ set, get }, newValue) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails || !newValue || guardRecoilDefaultValue(newValue)) return;
    const { advancedEmployeeInfo, ...restEditedEmployeeDetailsAtom } = editedEmployeeDetails;

    set(editedEmployeeDetailsAtom, {
      ...restEditedEmployeeDetailsAtom,
      advancedEmployeeInfo: {
        ...advancedEmployeeInfo,
        ...newValue,
      },
    });
  },
});

export enum EmployeeEmploymentDetailsFieldNames {
  Rates = 'rates',
  WorkdayDuration = 'workdayDurations',
  CustomRequestsLimits = 'customRequestsLimits',
  TimeOffLimits = 'timeOffLimits',
  CustomEmployeeId = 'customEmployeeId',
}

export type EmployeeEmploymentDetailsFormState = {
  [EmployeeEmploymentDetailsFieldNames.Rates]: FetchEmployeeDetailsResponse['rates'];
  [EmployeeEmploymentDetailsFieldNames.WorkdayDuration]: FetchEmployeeDetailsResponse['workdayDurations'];
  [EmployeeEmploymentDetailsFieldNames.CustomRequestsLimits]: FetchEmployeeDetailsResponse['customRequestsLimits'];
  [EmployeeEmploymentDetailsFieldNames.TimeOffLimits]: FetchEmployeeDetailsResponse['timeOffLimits'];
  [EmployeeEmploymentDetailsFieldNames.CustomEmployeeId]: AdvancedEmployeeInfo['customEmployeeId'];
};

export const employeeEmploymentDetailsFormStateSelector = selector<EmployeeEmploymentDetailsFormState | null>({
  key: 'employeeEmploymentDetailsFormState',
  get: ({ get }) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails) return null;

    const {
      workdayDurations,
      rates,
      customRequestsLimits,
      timeOffLimits,
      advancedEmployeeInfo: { customEmployeeId },
    } = editedEmployeeDetails;

    return {
      workdayDurations: _.orderBy(workdayDurations, ({ fromYear }) => fromYear),
      rates: _.orderBy(rates, ({ startDateUnix }) => startDateUnix),
      customRequestsLimits: _.orderBy(customRequestsLimits, ({ fromYear }) => fromYear),
      timeOffLimits: _.orderBy(timeOffLimits, ({ fromYear }) => fromYear),
      customEmployeeId,
    };
  },
  set: ({ set, get }, newValue) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails || !newValue || guardRecoilDefaultValue(newValue)) return;

    const { customEmployeeId, ...restNewValue } = newValue;
    const { advancedEmployeeInfo, ...restEditedEmployeeDetails } = editedEmployeeDetails;
    set(editedEmployeeDetailsAtom, {
      ...restEditedEmployeeDetails,
      ...restNewValue,
      advancedEmployeeInfo: {
        ...advancedEmployeeInfo,
        customEmployeeId,
      },
    });
  },
});

export const tagsSelectOptionsSelector = selector<InputOption[] | null>({
  key: 'tagsSelectOptions',
  get: ({ get }) => {
    const tags = get(tagsSelector);
    if (!tags) return null;

    return _.orderBy(
      tags.map(({ name, id }) => ({ label: name, id })),
      ({ label }) => label,
    );
  },
});

export const rolesSelectOptionsSelector = selector<InputOption[] | null>({
  key: 'rolesSelectOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    const roles = get(rolesSelector);
    if (!roles) return null;

    return _.orderBy(
      roles.map(({ name, id }) => ({
        label: t({
          id: name,
        }),
        id,
      })),
      ({ label }) => label,
    );
  },
});
export const workPositionsSelectOptionsSelector = selector<InputOption[] | null>({
  key: 'workPositionsSelectOptions',
  get: ({ get }) => {
    const workPositions = get(workPositionsSelector);
    if (!workPositions) return null;

    return _.orderBy(
      workPositions.filter(({ isActive }) => isActive).map(({ name, id }) => ({ label: name, id })),
      ({ label }) => label,
    );
  },
});
