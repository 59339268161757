import React from 'react';
import { Box, Text, BoxOwnProps } from 'theme-ui';
import { motion, MotionProps } from 'framer-motion';

import { useTheme } from 'styles/useTheme';

import { ButtonSharedProps } from './types';
import { setAnimations, setBgOverwriteSx, setOutlineSx, setShape } from './internals';

const AnimatedBox = motion(Box as React.FC<unknown>) as React.FC<AnimatedBoxButtonProps>;

type AnimatedBoxButtonProps =
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
  | MotionProps
  | BoxOwnProps
  | { ref?: React.ForwardedRef<HTMLAnchorElement> };

interface Props
  extends Omit<ButtonSharedProps, 'isLoading'>,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'color'> {}

export type AnchorButtonProps = Props;

const defaultProps: Partial<Props> = {
  variant: 'default',
  size: 'default',
  shape: 'pill',
  outline: false,
  fullWidth: false,
  prependWith: undefined,
  apendWith: undefined,
  bgOverwrite: undefined,
};

export const AnchorButton = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      variant = 'default',
      size = 'default',
      shape = 'pill',
      outline,
      fullWidth,
      prependWith,
      apendWith,
      sx,
      bgOverwrite,
      ...props
    }: Props,
    ref,
  ) => {
    const { theme } = useTheme();

    const motionAnimations = setAnimations({ bgOverwrite, variant, theme });

    return (
      <AnimatedBox
        as="a"
        initial={motionAnimations.initial}
        whileHover={motionAnimations.hover}
        whileTap={{ scale: !fullWidth ? 0.975 : 0.985, ...motionAnimations.tap }}
        transition={{ duration: 0.1 }}
        variant={`buttons.${variant}`}
        ref={ref}
        sx={{
          width: fullWidth ? '100%' : 'auto',
          gap: theme.buttons.sizes[size].py,
          ...(bgOverwrite && setBgOverwriteSx({ bgOverwrite })),
          ...(outline &&
            setOutlineSx({
              textColor: theme.colors.buttons[variant].text.reversed,
              radii:
                shape !== 'rounded'
                  ? theme.buttons.shapes[shape].borderRadius
                  : theme.buttons.shapeRoundedSizes[size].borderRadius,
            })),
          ...theme.buttons.sizes[size],
          ...setShape({ shape, size, theme }),
          ...sx,
        }}
        {...props}
      >
        {prependWith && prependWith}
        {children && (
          <Text
            as="span"
            sx={{
              display: 'inline-flex',
              position: 'relative',
              whiteSpace: 'nowrap',
              minWidth: 'auto',
              width: '100%',
            }}
          >
            {children}
          </Text>
        )}
        {apendWith && apendWith}
      </AnimatedBox>
    );
  },
);

AnchorButton.defaultProps = defaultProps;
