import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Location } from 'history';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';

import { resetFormButtonAtom, settingsPreferencesNameDisplayOrderOptionsSelector } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';
import { TIMER_END_TIME, TIMER_INTERVAL } from 'layouts/Settings/Organization/forms/constants';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { useBlockRouteTransition } from 'hooks/useBlockTransition/useBlockRouteTransition';
import { Switch } from 'components/ui/Switch';
import { Select } from 'components/ui/Select/Select';
import { UserPreferencesActionProps } from 'api/actions/settings/settingsActions.types';
import { userPreferencesAction } from 'api/actions/settings/settingsActions';
import { createEvent } from 'utils/createEvent';
import { TimeZoneSelect } from 'components/ui/TimeZoneSelect/TimeZoneSelect';

import { dateFormatOptions, languageListOptions } from './preferencesFormSelectOptions';

type Props = {
  payload: UserPreferencesActionProps;
};

type keys = keyof UserPreferencesActionProps;

export const PreferencesForm = ({ payload }: Props): React.ReactElement => {
  useLingui();
  const nameDisplayOrderOptions = useRecoilValue(settingsPreferencesNameDisplayOrderOptionsSelector);
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const history = useHistory();
  const { mutate } = useMutation(userPreferencesAction);

  const formRef = useRef<HTMLFormElement | null>(null);
  const blockLocationPathnameRef = useRef<string | null>(null);
  const shouldBlockRef = useRef<boolean>(false);
  const formPendingRef = useRef<boolean>(false);
  const statusRef = useRef<Status>('STOPPED');

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: payload,
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const dispatchSubmitEvent = useCallback(() => {
    const submitEvent = createEvent('submit');
    formPendingRef.current = true;
    formRef.current?.dispatchEvent(submitEvent);
  }, []);

  const { start, reset, status } = useTimer({
    endTime: TIMER_END_TIME,
    interval: TIMER_INTERVAL,
    onTimeOver: () => dispatchSubmitEvent(),
  });

  const handleOnChange = () => {
    shouldBlockRef.current = true;
    blockLocationPathnameRef.current = null;
    reset();
    start();
  };

  const handleOnBlur = useCallback(() => {
    shouldBlockRef.current = true;
    blockLocationPathnameRef.current = null;
    reset();
    start();
  }, [reset, start]);

  const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
    const { onChange, ...restRegister } = register(registerName, registerOptions);

    return {
      ...restRegister,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        handleOnChange();
      },
    };
  };

  const registerOnBlur = useCallback(
    (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onBlur, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          onBlur(e);
          handleOnBlur();
        },
      };
    },
    [handleOnBlur, register],
  );

  const handleFormReset = useCallback(() => {
    const { language } = payload;
    const { timeZoneId } = payload;
    const { nameDisplayOrder } = payload;
    const { dateFormat } = payload;
    const { is24HourFormat } = payload;

    resetForm({ language, timeZoneId, nameDisplayOrder, dateFormat, is24HourFormat });
    shouldBlockRef.current = false;
    formPendingRef.current = false;
  }, [payload, resetForm]);

  const handleOnSubmitCallback = useCallback(
    async (data: UserPreferencesActionProps) => {
      if (!_.isEqual(data, payload)) {
        const { error } = await mutate(data);
        const PreferencesResetObject = { name: 'PREFERENCES_CALLBACK', callback: handleFormReset };

        if (!error) {
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_success',
            }),
            variant: 'success',
          });
          setResetCallbacks(null);
        } else {
          shouldBlockRef.current = true;
          setResetCallbacks((prevState) =>
            !prevState ? [PreferencesResetObject] : [...prevState, PreferencesResetObject],
          );
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_fail',
            }),
            variant: 'danger',
          });
          return;
        }
      }

      formPendingRef.current = false;
      shouldBlockRef.current = false;
      if (blockLocationPathnameRef.current) {
        history.push(blockLocationPathnameRef.current);
      }
    },
    [handleFormReset, history, mutate, payload, setResetCallbacks],
  );

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

  const handlePageTransitionCallback = useCallback(
    (location: Location) => {
      blockLocationPathnameRef.current = location.pathname;

      if (statusRef.current === 'RUNNING') {
        reset();
        dispatchSubmitEvent();
      }

      if (shouldBlockRef.current || formPendingRef.current) return false;

      return true;
    },
    [dispatchSubmitEvent, reset],
  );

  useBlockRouteTransition(handlePageTransitionCallback);

  return (
    <form ref={formRef} onSubmit={handleSubmit(handleOnSubmitCallback)}>
      <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
        <OptionLabel
          label={t({
            id: 'settings.user.preferences.app_language',
            message: 'App language',
          })}
          apendWith={
            <Select
              sx={{
                minWidth: '300px',
              }}
              id="language"
              placeholder={t({
                id: 'settings.user.preferences.app_language',
                message: 'App language',
              })}
              error={!!errors.language}
              errorMessage={errors?.language?.message}
              options={languageListOptions || []}
              size="sm"
              {...registerOnBlur('language', {
                setValueAs: (v) => parseInt(v, 10),
              })}
            />
          }
          withDivider
        />
        <OptionLabel
          label={t({
            id: 'sign_up.employer.form.timezone',
            message: 'Your timezone',
          })}
          apendWith={
            <TimeZoneSelect
              {...registerOnBlur('timeZoneId')}
              id="timeZoneId"
              placeholder={t({
                id: 'sign_up.employer.form.timezone',
                message: 'Your timezone',
              })}
              searchable
              size="sm"
              error={!!errors.timeZoneId}
              errorMessage={errors?.timeZoneId?.message}
              sx={{
                minWidth: '300px',
              }}
            />
          }
          withDivider
        />
        <OptionLabel
          label={t({
            id: 'settings.user.preferences.date_format',
            message: 'Date format',
          })}
          apendWith={
            <Select
              sx={{
                ml: 'auto',
                minWidth: '300px',
              }}
              id="dateFormat"
              placeholder={t({
                id: 'settings.user.preferences.date_format',
                message: 'Date format',
              })}
              error={!!errors.timeZoneId}
              errorMessage={errors?.timeZoneId?.message}
              options={dateFormatOptions || []}
              size="sm"
              {...registerOnBlur('dateFormat', {
                setValueAs: (v) => parseInt(v, 10),
              })}
            />
          }
          withDivider
        />
        <OptionLabel
          label={t({
            id: 'settings.user.preferences.person_display_type',
            message: 'Names displayed as',
          })}
          apendWith={
            <Select
              sx={{
                ml: 'auto',
                minWidth: '300px',
              }}
              id="nameDisplayOrder"
              placeholder={t({
                id: 'settings.user.preferences.name_display_order',
                message: 'Names displayed as',
              })}
              error={!!errors.timeZoneId}
              errorMessage={errors?.timeZoneId?.message}
              options={nameDisplayOrderOptions || []}
              size="sm"
              {...registerOnBlur('nameDisplayOrder', {
                setValueAs: (v) => parseInt(v, 10),
              })}
            />
          }
          withDivider
        />
        <Switch
          {...registerOnChange('is24HourFormat')}
          label={t({
            id: 'settings.user.preferences.time_format',
            message: '24-hours time format',
          })}
          bold
          size="sm"
          withDivider
        />
      </Flex>
    </form>
  );
};
