export const API_ENDPOINTS = {
  // AUTHENTICATION
  authorize: '/connect/token',
  refreshAccessToken: '/connect/refresh',
  signOut: '/connect/revoke',
  signUp: '/user',
  signUpEmployee: '/User/InvitationRegister',
  invitationResend: '/User/RenewInvitationRequest',
  invitationJoinRequest: '/User/InviteRequest',
  accountInfo: '/organization',
  // PASSWORD RECOVERY
  forgotPassword: '/user/forgotPassword',
  resetPassword: '/user/resetPassword',
  // INTEGRATIONS
  integrations: '/integrations',
  integrationsConnectPayroll: '/integrations/connectPayroll',
  integrationsDisconnect: '/integrations/disconnect',
  externalProvider: '/externalProvider',
  googleLink: '/ExternalProvider/google/link',
  googleLogin: '/ExternalProvider/google/login',
  appleLink: '/ExternalProvider/apple/link',
  appleLogin: '/ExternalProvider/apple/login',
  // WEBHOOK
  webhook: '/webhook',
  webhookGetAvailable: '/webhook/getAvailable',
  webhookAdd: '/webhook/add',
  webhookEdit: '/webhook/edit',
  webhookDelete: '/webhook/delete',
  // USER_SESSION
  userSession: '/session/userSession',
  viewsSettings: '/viewsSettings',
  // ORGANIZATION_SESSION
  organizationSession: '/session/organizationSession',
  // EMPLOYEES
  employee: '/employee',
  employeeManagement: '/employeeManagement',
  employeeActivate: '/employeeManagement/activate',
  employeeDeactivate: '/employeeManagement/deactivate',
  employeeInvite: '/employeeManagement/invite',
  printQr: '/employee/printQr',
  setWorkPosition: '/workPosition/setWorkPositions',
  clearWorkPositions: '/workPosition/clearWorkPositions',
  setTags: '/tagManagement/setTags',
  clearTags: '/tagManagement/clearTags',
  setPermissions: '/permissions/updatePeople',
  setRole: '/role/updateUsersRole',
  setPayDetails: '/employeeManagement/setPayDetails',
  setTimeOffLimits: '/employeeManagement/setTimeOffLimits',
  // TAGS
  tag: '/tag',
  tagManagement: '/tagManagement',
  tagInheritedFeatures: '/employeeManagement/getDefaultFeatures',
  // ROLES
  role: '/role',
  updateUsersRole: '/role/updateUsersRole',
  // WORK_POSITIONS
  workPosition: '/workPosition',
  setWorkPositionsStates: '/workPosition/setWorkPositionState',
  // TEAM
  timeZoneList: '/timeZone',
  industryList: '/organization/industries',
  schedulesSettings: '/schedulesSettings',
  notificationSettings: '/notificationSettings',
  timeTrackingSettings: '/timeTrackingSettings',
  organizationSettings: '/organizationSettings',
  // ORGANIZATION_SETTINGS
  organizationSettingsChangeAvatar: '/organizationSettings/changeAvatar',
  organizationSettingsDeleteAvatar: '/organizationSettings/deleteAvatar',
  // HOLIDAYS
  holiday: '/holiday',
  importLocations: '/holiday/importLocations',
  importPreviewHolidays: '/holiday/importPreview',
  importHolidays: '/holiday/import',
  // TIME_OFF_TYPE
  timeOffType: '/timeOffType',
  setTimeOffTypesState: '/timeOffType/setTimeOffTypesState',
  updateTimeOffType: '/timeOffType/:id', // TODO: handlers endpoint. Remove it later.
  // CUSTOM_REQUEST_TYPE
  customRequestType: '/customRequestType',
  setCustomRequestTypesState: '/customRequestType/setCustomRequestTypesState',
  updateCustomRequestType: '/customRequestType/:id', // TODO: handlers endpoint. Remove it later.
  // REQUESTS_SETTINGS
  requestSettings: '/requestsSettings',
  // ADVANCED_SETTINGS
  advancedOrganizationSettingsDeletePhotos: '/advancedOrganizationSettings/deletePhotos',
  advancedOrganizationSettingsDeleteFaceModels: '/advancedOrganizationSettings/deleteFaceModels',
  advancedOrganizationSettingsDeleteOrganization: '/advancedOrganizationSettings/deleteOrganization',
  // USER_SETTINGS
  userInfo: '/userInfo',
  userInfoChangeAvatar: '/userInfo/changeAvatar',
  userInfoDeleteAvatar: '/userInfo/deleteAvatar',
  userPreferences: '/userPreferences',
  userNotifications: '/userNotificationSettings',
  userChangePassword: '/user/changePassword',
  // ZUS
  zusCredentials: '/zus/credentials',
  zusManualImport: '/zus/manualImport',
  zusConnect: '/zus/connect',
  // KIOSK
  timeclock: {
    getSettings: '/settings/kiosk',
    getInfo: '/WebTimeClock/verification',
    submitTimeEvent: '/WebTimeClock/submit',
    initPersonModel: '/user/face',
  },
  // CHAT
  signalR: '/hubs/chat',
  chat: {
    chatUsers: '/Chat/Users',
    messages: '/Chat/UserChats?id=',
    message: '/Chat',
    deleteMessage: '/Chat/DeleteMessage?id=',
    hasBeenRead: '/Chat/MarkAsRead?id=',
  },
  // FILE UPLOAD
  file: '/file',
  fileUpload: '/file/upload',
  // DRAWER
  attendancePill: '/attendanceOverview',
  timeTracker: '/OnlineTimeClock/verification',
  timeEventSubmit: '/OnlineTimeClock/submit',
  // NOTIFICATIONS
  notification: '/notification',
  // REQUESTS
  requests: '/request',
  timeOffRemainingLimits: '/request/getTimeOffRemainingLimits',
  timeEventsHistory: '/timeEvent/timeEventsForRequest',
  customRemainingLimits: '/request/getCustomRemainingLimits',
  requestOverview: '/request/getForTimeRange',
  availableOvertime: '/request/getAvailableOvertime',
  addRequest: '/request/add',
  getApprovalLists: '/request/getApprovalLists',
  deleteRequestManagement: '/requestManagement/delete',
  rejectRequestManagement: '/requestManagement/reject',
  acceptRequestManagement: '/requestManagement/accept',
  requestGetDataToModify: '/request/getDataToModify',
  printRequests: '/request/print',
  // WORK STATUSES
  workStatuses: '/timeEventType',
  // LOCATION
  location: '/location',
  addLocation: '/locationManagement/add',
  editLocation: '/locationManagement/edit',
  deleteLocation: '/locationManagement',
  singleLocation: '/location/show/',
  // TIME EVENTS
  // !! use submitTimeEvent endpoint above to add time event?
  timeEvent: {
    getTimeEvents: '/timeEvent/list',
    deleteTimeEvents: '/timeEvent',
  },
  // timeEvent: '/timeEvent/list',
  timeEventHistory: '/timeEvent/history',
  editTimeEvent: '/timeEvent/edit',
  // EXPORT
  getExportOptions: '/export/getExportOptions',
  export: '/export/export',
  exportForIntegration: '/export/exportForIntegration',
  exportTimeActivityPreview: '/export/exportTimeActivityPreview',
  exportTimeActivity: '/export/exportTimeActivity',
  // REPORT
  report: '/report',
  // TIME CLOCKS
  device: '/device',
};
