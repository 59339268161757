import { atom } from 'recoil';

export type QrScannerState = {
  isQrScannerRunning: boolean;
  startQrScanner?: () => void;
  stopQrScanner?: () => void;
  toggleQrScanner?: () => void;
  decode?: (imageData: ImageData) => void;
  // __FOR_DEBUG_MODE
  pingWorker?: () => void;
};

export const qrScannerStateAtom = atom<QrScannerState>({
  key: 'kiosk__qrScannerState',
  default: {
    isQrScannerRunning: false,
  },
});

type QrScannerWorker = Worker | null;

export const qrScannerWorkerAtom = atom<QrScannerWorker>({
  key: 'kiosk__qrScannerWorker',
  default: null,
});
