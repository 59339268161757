import React, { useLayoutEffect, useRef } from 'react';

/**
 * This hook will save the provided function to a ref, update the ref on function change and return that ref.
 */
export function useCallbackRef<T>(callback: T): React.MutableRefObject<T> {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  return callbackRef;
}
