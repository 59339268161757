import { t } from '@lingui/macro';

import { InputOption } from '../Select/Select';

type GetInputsValuesFromSecondsReturnValue = {
  hours: string;
  minutes: string;
  seconds: string;
};

export const getInputsValuesFromSeconds = (s: number): GetInputsValuesFromSecondsReturnValue => {
  const getSeconds = `0${s % 60}`.slice(-2);
  const getMinutes = `0${Math.floor(s / 60) % 60}`.slice(-2);
  const getHours = `0${Math.floor(s / 3600)}`.slice(-2);

  return { hours: getHours, minutes: getMinutes, seconds: getSeconds };
};

export const getSelectOptions = (secondsArray: undefined | number[]): InputOption[] => {
  if (!secondsArray) {
    return [];
  }

  return secondsArray
    .sort((a, b) => +b - +a)
    .map((sec) => {
      const newValues = getInputsValuesFromSeconds(sec);

      const h = +newValues.hours > 0 ? `${+newValues.hours}h ` : '';
      const m = +newValues.minutes > 0 ? `${+newValues.minutes}min ` : '';
      const s =
        +newValues.seconds > 0
          ? `${+newValues.seconds}${t({
              id: 'duration_picker.sec',
            })}`
          : '';

      return {
        id: `${sec}`,
        label: h + m + s,
      };
    });
};

export const getSecondsFromHours = (h: number): number => h * 3600;
export const getSecondsFromMinutes = (m: number): number => m * 60;
