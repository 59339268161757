import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Location } from 'history';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Flex, Link, Text } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';

import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { useBlockRouteTransition } from 'hooks/useBlockTransition/useBlockRouteTransition';
import { Switch } from 'components/ui/Switch';
import { Divider } from 'components/Divider/Divider';
import {
  FetchNotificationSettingsResponse,
  NotificationSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { notificationSettingsAction } from 'api/actions/settings/settingsActions';
import { createEvent } from 'utils/createEvent';
import { HeadingTip } from '../../HeadingTip';
import { wrapperLinkSx } from '../styles/wrappers';

import { TIMER_END_TIME, TIMER_INTERVAL } from './constants';

type Props = {
  payload: FetchNotificationSettingsResponse;
};

type keys = keyof NotificationSettingsActionProps;

export const NotificationsForm = ({ payload }: Props): React.ReactElement => {
  useLingui();
  const history = useHistory();
  const setPayload = useSetRecoilState(payloadSelectorFamily('NOTIFICATIONS'));
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const { mutate } = useMutation(notificationSettingsAction);

  const smsLink = useHelpLink({
    inEwi: { pl: '/article/centrum-powiadomien-1co78i1/', en: '/article/notifications-13a7q2e/' },
    tracktime24: { en: '/article/notifications-6xib4x/' },
  });

  const mutationDataRef = useRef<FetchNotificationSettingsResponse>(payload);
  const formRef = useRef<HTMLFormElement | null>(null);
  const blockLocationPathnameRef = useRef<string | null>(null);
  const shouldBlockTransitionRef = useRef<boolean>(false);
  const formPendingRef = useRef<boolean>(false);
  const statusRef = useRef<Status>('STOPPED');

  const {
    register,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues: payload,
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const dispatchSubmitEvent = useCallback(() => {
    const submitEvent = createEvent('submit');
    formPendingRef.current = true;
    formRef.current?.dispatchEvent(submitEvent);
  }, []);

  const { start, reset, status } = useTimer({
    endTime: TIMER_END_TIME,
    interval: TIMER_INTERVAL,
    onTimeOver: () => dispatchSubmitEvent(),
  });

  const handleOnChange = () => {
    shouldBlockTransitionRef.current = true;
    blockLocationPathnameRef.current = null;
    reset();
    start();
  };

  const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
    const { onChange, ...restRegister } = register(registerName, registerOptions);

    return {
      ...restRegister,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        handleOnChange();
      },
    };
  };

  const handleFormReset = useCallback(() => {
    resetForm({
      ...payload,
    });
    shouldBlockTransitionRef.current = false;
    formPendingRef.current = false;
  }, [payload, resetForm]);

  const handleSubmitCallback = useCallback(
    async (data: NotificationSettingsActionProps) => {
      if (!_.isEqual(data, payload)) {
        const { error } = await mutate(data);
        const notificationsResetObject = { name: 'NOTIFICATIONS_CALLBACK', callback: handleFormReset };

        if (!error) {
          setResetCallbacks(null);
          setPayload(data);
          mutationDataRef.current = data;
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_success',
            }),
            variant: 'success',
          });
        } else {
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_fail',
            }),
            variant: 'danger',
          });
          setResetCallbacks((prevState) =>
            !prevState ? [notificationsResetObject] : [...prevState, notificationsResetObject],
          );
          return;
        }
      }

      formPendingRef.current = false;
      shouldBlockTransitionRef.current = false;
      if (blockLocationPathnameRef.current) {
        history.push(blockLocationPathnameRef.current);
      }
    },
    [handleFormReset, history, mutate, payload, setPayload, setResetCallbacks],
  );

  useEffect(() => {
    if (!_.isEqual(mutationDataRef.current, payload)) handleFormReset();
  }, [handleFormReset, payload]);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

  const validatePageTransitionCallback = useCallback(
    (location: Location) => {
      blockLocationPathnameRef.current = location.pathname;

      if (statusRef.current === 'RUNNING') {
        reset();
        dispatchSubmitEvent();
      }

      if (shouldBlockTransitionRef.current || formPendingRef.current) return false;

      return true;
    },
    [dispatchSubmitEvent, reset],
  );

  useBlockRouteTransition(validatePageTransitionCallback);

  return (
    <form ref={formRef} onSubmit={handleSubmit(handleSubmitCallback)}>
      <Flex sx={{ gap: '2.25rem', flexDirection: 'column', maxWidth: '600px' }}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <HeadingTip
            label={t({
              id: 'notifications_settings.heading.schedules',
              message: 'Schedules',
            })}
            additionalInfo={t({
              id: 'notifications_settings.heading.schedules.additional_info',
              message: 'Receive updates when there is a new version of yours schedule published.',
            })}
            variant="heading4"
            additionalInfoSx={{
              fontSize: '0.875rem',
              lineHeight: '1.3125rem',
            }}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <Switch
              {...registerOnChange('schedulesEmails')}
              label={t({ id: 'notifications_settings.email' })}
              bold
              size="sm"
            />
            <Divider />
            <Switch
              {...registerOnChange('schedulesTextMessages')}
              label={t({
                id: 'notifications_settings.text_messages',
                message: 'Text messages',
              })}
              additionalInfo={
                <Text>
                  <Trans id="settings.currently_in_preview">Currently in preview.</Trans>
                  <span> </span>
                  <Link href={smsLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                    <Trans id="settings.learn_more" />
                  </Link>
                  .
                </Text>
              }
              bold
              size="sm"
            />
            <Divider />
          </Flex>
        </Flex>

        <Flex sx={{ flexDirection: 'column', gap: 2, width: '100%' }}>
          <HeadingTip
            label={t({
              id: 'notifications_settings.heading.requests',
              message: 'Requests',
            })}
            additionalInfo={t({
              id: 'notifications_settings.heading.requests.additional_info',
              message:
                'Receive updates when there is a new request (Supervisors, Managers, and Administrators) or when your request has been updated.',
            })}
            variant="heading4"
            additionalInfoSx={{
              fontSize: '0.875rem',
              lineHeight: '1.3125rem',
            }}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <Switch
              {...registerOnChange('requestsEmails')}
              label={t({ id: 'notifications_settings.email' })}
              bold
              size="sm"
            />
            <Divider />
            <Switch
              {...registerOnChange('requestsTextMessages')}
              label={t({
                id: 'notifications_settings.text_messages',
              })}
              additionalInfo={
                <Text>
                  <Trans id="settings.currently_in_preview" />
                  <span> </span>
                  <Link href={smsLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                    <Trans id="settings.learn_more" />
                  </Link>
                  .
                </Text>
              }
              bold
              size="sm"
            />
            <Divider />
          </Flex>
        </Flex>

        <Flex sx={{ flexDirection: 'column', gap: 2, width: '100%' }}>
          <HeadingTip
            label={t({
              id: 'notifications_settings.heading.messages',
              message: 'Messages',
            })}
            additionalInfo={t({
              id: 'notifications_settings.heading.messages.additional_info',
              message: 'Receive updates about messages from your teammates when you are not in the app.',
            })}
            variant="heading4"
            additionalInfoSx={{
              fontSize: '0.875rem',
              lineHeight: '1.3125rem',
            }}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <Switch
              {...registerOnChange('messagesEmails')}
              label={t({ id: 'notifications_settings.email' })}
              bold
              size="sm"
            />
            <Divider />
            <HeadingTip
              label={t({
                id: 'notifications_settings.heading.promotions_and_tips',
                message: 'Promotions and tips',
              })}
              additionalInfo={
                <Trans id="notifications_settings.heading.promotions_and_tips.additional_info">
                  Receive coupons & promotions from us and only us. We do not SPAM our clients.
                  <Link href="https://inewi.pl" target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                    Terms of the newsletter
                  </Link>
                  .
                </Trans>
              }
              variant="heading4"
              additionalInfoSx={{
                fontSize: '0.875rem',
                lineHeight: '1.3125rem',
              }}
            />
            <Switch
              {...registerOnChange('promotionsEmails')}
              label={t({ id: 'notifications_settings.email' })}
              bold
              size="sm"
            />
            <Divider />
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};
