/** @jsxImportSource theme-ui */
import React, { forwardRef } from 'react';

import { FileUpload } from './FileUpload';
import { AttachmentProps } from './types';

// Changed props to work without rhf. Now accepts defaultValue prop and sends it to the hidden input
export const Attachment = forwardRef<HTMLInputElement, AttachmentProps>(
  ({ name, defaultValue }: AttachmentProps, ref): React.ReactElement => (
    <FileUpload isStatic ref={ref} name={name} defaultValue={defaultValue} />
  ),
);
