import { atom, selector, selectorFamily } from 'recoil';
import _ from 'lodash';

import {
  ApprovalSettings,
  DayOfWeek,
  Employee,
  FetchOrganizationSessionResponse,
  RequestType,
  Role,
  Tag,
  TimeEventType,
  TimeOffType,
  TimeTrackingSettings,
  WorkPosition,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { NormalPayrollPeriod } from 'api/actions/settings/settingsActions.types';

export type OrganizationSessionAtomType = FetchOrganizationSessionResponse & {
  employeesMap: Map<string, Employee>;
  tagsMap: Map<string, Tag>;
  rolesMap: Map<string, Role>;
  workPositionsMap: Map<string, WorkPosition>;
};

export const organizationSessionAtom = atom<OrganizationSessionAtomType | null>({
  key: 'organizationSession',
  default: null,
});

// use with type assertion
export const organizationSessionPropertySelectorFamily = selectorFamily<unknown | null, string>({
  key: 'organizationSessionPropertySelector',
  get:
    (propertyName) =>
    ({ get }) => {
      const organizationSession = get(organizationSessionAtom);
      if (!organizationSession) return null;
      const selectedPropertyValue = _.get(organizationSession, propertyName);
      if (!selectedPropertyValue) return null;
      return selectedPropertyValue;
    },
});

export const organizationPayrollPeriodSelector = selector<NormalPayrollPeriod | null>({
  key: 'organizationSession__payrollPeriod',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;

    const { payrollPeriod } = organizationSession;
    return payrollPeriod;
  },
});

export const organizationDisplayCurrencySelector = selector<string | null>({
  key: 'organizationSession__displayCurrency',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;

    const { displayCurrency } = organizationSession;
    return displayCurrency;
  },
});

export const timeOffTypesSelector = selector<TimeOffType[] | null>({
  key: 'timeOffType',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeOffTypes } = organizationSession;

    return timeOffTypes;
  },
});

export const customRequestTypesSelector = selector<RequestType[] | null>({
  key: 'customRequestType',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { customRequestTypes } = organizationSession;

    return customRequestTypes;
  },
});

export const startingWeekDaySelector = selector<DayOfWeek | null>({
  key: 'startingWeekDay',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { startingWeekDay } = organizationSession;

    return startingWeekDay;
  },
});

export const taxIdSelector = selector<string | null>({
  key: 'taxId',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { taxId } = organizationSession;

    return taxId;
  },
});

export type TimeOffTypesDictionarySelectorType = Record<string, TimeOffType> | null;

export const timeOffTypesDictionarySelector = selector<TimeOffTypesDictionarySelectorType>({
  key: 'timeOffTypesDictionary',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeOffTypes } = organizationSession;

    return _.keyBy(timeOffTypes, 'id');
  },
});

export type CustomRequestTypesDictionarySelectorType = Record<string, RequestType> | null;

export const customRequestTypesDictionarySelector = selector<CustomRequestTypesDictionarySelectorType>({
  key: 'customRequestTypesDictionary',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { customRequestTypes } = organizationSession;

    return _.keyBy(customRequestTypes, 'id');
  },
});

export type TimeEventTypesDictionarySelectorType = Record<string, TimeEventType> | null;

export const timeEventTypesDictionarySelector = selector<TimeEventTypesDictionarySelectorType>({
  key: 'timeEventTypesDictionary',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeEventTypes } = organizationSession;

    return _.keyBy(timeEventTypes, 'id');
  },
});

export const timeTrackingSettingsSelector = selector<TimeTrackingSettings | null>({
  key: 'timeTrackingSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);

    return organizationSession?.timeTrackingSettings ?? null;
  },
});

export const defaultTagFeaturesSelector = selector<OrganizationSessionAtomType['defaultTagFeatures'] | null>({
  key: 'defaultTagFeatures',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { defaultTagFeatures } = organizationSession;

    return defaultTagFeatures;
  },
});

export const timeOffApprovalSettingsSelector = selector<ApprovalSettings | null>({
  key: 'timeOffApprovalSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeOffApprovalSettings } = organizationSession;

    return timeOffApprovalSettings;
  },
});

export const timeTrackingApprovalSettingsSelector = selector<ApprovalSettings | null>({
  key: 'timeTrackingApprovalSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeTrackingApprovalSettings } = organizationSession;

    return timeTrackingApprovalSettings;
  },
});

export const businessTripsApprovalSettingsSelector = selector<ApprovalSettings | null>({
  key: 'businessTripsApprovalSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { businessTripsApprovalSettings } = organizationSession;

    return businessTripsApprovalSettings;
  },
});

export const customRequestsApprovalSettingsSelector = selector<ApprovalSettings | null>({
  key: 'customRequestsApprovalSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { customRequestsApprovalSettings } = organizationSession;

    return customRequestsApprovalSettings;
  },
});

export const organizationDetailsSelector = selector<OrganizationSessionAtomType['details'] | null>({
  key: 'organizationDetails',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { details } = organizationSession;

    return details;
  },
});
