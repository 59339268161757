import { ThemeUIStyleObject, Flex, BoxProps } from 'theme-ui';
import React, { useRef } from 'react';

import { Icon } from 'components/Icon/Icon';
import { Button, ButtonProps } from 'components/ui/Buttons';
import { delay } from 'utils/delay';

type Props = {
  leftButtonProps?: ButtonProps;
  rightButtonProps: ButtonProps;
} & BoxProps;

const buttonSx: ThemeUIStyleObject = {
  p: '.34rem',
  fontSize: 1,
};

const defaultProps: Partial<Props> = {
  leftButtonProps: undefined,
};

export const ButtonPair = ({ leftButtonProps, rightButtonProps, sx }: Props): React.ReactElement => {
  const isAccidentalClickRef = useRef(false); // to prevent accidental onClick calls when fast clicking

  const handleLeftClick = async () => {
    isAccidentalClickRef.current = true;
    if (leftButtonProps.onClick) {
      leftButtonProps.onClick();
    }
    await delay(200);
    isAccidentalClickRef.current = false;
  };
  const handleRightClick = () => {
    if (rightButtonProps.onClick && !isAccidentalClickRef.current) {
      rightButtonProps.onClick();
    }
  };

  return (
    <Flex sx={sx}>
      {leftButtonProps && (
        <Button
          sx={{
            ...(rightButtonProps && { borderTopRightRadius: 0, borderBottomRightRadius: 0 }),
            ...buttonSx,
          }}
          size="sm"
          shape="rounded"
          variant="grey"
          {...leftButtonProps}
          onClick={handleLeftClick}
        >
          <Icon type="plus" />
        </Button>
      )}
      {rightButtonProps && (
        <Button
          sx={{
            ...(leftButtonProps && { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, ml: '1px' }),
            ...buttonSx,
          }}
          size="sm"
          shape="rounded"
          variant="grey"
          {...rightButtonProps}
          onClick={handleRightClick}
        >
          <Icon type="delete" />
        </Button>
      )}
    </Flex>
  );
};

ButtonPair.defaultProps = defaultProps;
