import { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';

import { createEvent } from 'utils/createEvent';
import { setNativeValue } from 'utils/setNativeValue';
import { silentSetValue } from 'utils/silentSetValue';
import { fullTimeFormatSelector, timeFormatSelector } from 'state/recoilState';
import { timeTz } from 'utils/dateTime';
import { InputOption } from '../Select/Select';
import { useFieldErrorDispatcher } from 'hooks/useFieldErrorDipatcher/useFieldErrorDispatcher';

import { getLocalTimeObjectFromUnix, getTimeUnix } from './utils';
import { AmOptions, InputNames, TimePickerOwnProps } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTimePickerLogic = ({ minTime, maxTime, onValidError, onClearError }: TimePickerOwnProps) => {
  const timeFormat = useRecoilValue(timeFormatSelector);
  const fullTimeFormat = useRecoilValue(fullTimeFormatSelector);

  const [timePickerError, setTimePickerError] = useState(false);
  const [timePickerErrorMessage, setTimePickerErrorMessage] = useState<string | undefined>(undefined);

  const hiddenRef = useRef<HTMLInputElement | null>(null);

  const hoursRef = useRef<HTMLInputElement | null>(null);
  const minutesRef = useRef<HTMLInputElement | null>(null);
  const amRef = useRef<HTMLSelectElement | null>(null);

  const dispatchBlurEvent = () => {
    const blurEvent = createEvent('focusout');
    if (hiddenRef.current) hiddenRef.current.dispatchEvent(blurEvent);
  };

  const isHoursFilled = (fully?: boolean) => {
    if (hoursRef.current && hoursRef.current.value && hoursRef.current.value.length) {
      return fully ? hoursRef.current.value.length > 1 : true;
    }
    return false;
  };
  const isMinutesFilled = (fully?: boolean) => {
    if (minutesRef.current && minutesRef.current.value && minutesRef.current.value.length) {
      return fully ? minutesRef.current.value.length > 1 : true;
    }
    return false;
  };

  const isPickerFilled = useCallback((fully?: boolean) => isHoursFilled(fully) && isMinutesFilled(fully), []);

  const isPickerEmpty = useCallback(() => !isHoursFilled() && !isMinutesFilled(), []);

  const getFirstUnfilledInput = () => {
    if (isHoursFilled()) {
      return minutesRef.current;
    }
    return hoursRef.current;
  };

  const getSelectOptions = (timesArray: undefined | (string | number)[]): InputOption[] => {
    if (!timesArray) {
      return [];
    }
    return timesArray
      .map((timeItem) => {
        const newDate = timeTz(timeItem);

        return {
          id: `${newDate.unix()}`,
          label: `${newDate.format(fullTimeFormat)}`,
        };
      })
      .sort((a, b) => +a.id - +b.id);
  };

  const getNextInput = (currentInputName: string) => {
    switch (currentInputName) {
      case InputNames.hours:
        if (minutesRef.current) {
          return minutesRef.current;
        }
        return null;
      case InputNames.minutes:
        if (amRef.current) {
          return amRef.current;
        }
        return null;
      default:
        return null;
    }
  };

  const getPreviousInput = (currentInputName: string) => {
    switch (currentInputName) {
      case InputNames.minutes:
        if (hoursRef.current) {
          return hoursRef.current;
        }
        return null;
      case InputNames.am:
        if (minutesRef.current) {
          return minutesRef.current;
        }
        return null;
      default:
        return null;
    }
  };

  const getInputRefByName = useCallback(
    (inputName: string) => (inputName === InputNames.hours ? hoursRef : minutesRef),
    [],
  );

  const getLocalTimeObjectFromUnixCallback = useCallback(
    (unix: string | number) => getLocalTimeObjectFromUnix(unix, timeFormat),
    [timeFormat],
  );

  const getUnixFromUserInput = useCallback(() => {
    if (!isPickerFilled()) {
      return null;
    }

    if (hoursRef.current && minutesRef.current) {
      let hours = parseInt(hoursRef.current.value, 10);

      if (timeFormat === '12') {
        hours = hours === 12 ? 0 : hours;
        hours = amRef?.current?.value === AmOptions.AM ? hours : hours + 12;
      }
      const minutes = parseInt(minutesRef.current.value, 10);

      return `${getTimeUnix(hours, minutes)}`;
    }

    return null;
  }, [isPickerFilled, timeFormat]);

  const setTimeInputs = useCallback(
    (unix: string) => {
      const isEmpty = !unix || _.isNaN(+unix);
      const newValues = isEmpty ? null : getLocalTimeObjectFromUnixCallback(unix);

      silentSetValue(hoursRef, newValues?.hours || '');
      silentSetValue(minutesRef, newValues?.minutes || '');

      const newAmValue = newValues?.am;
      const amSelect = amRef.current;

      if (!newAmValue || !amSelect) return;

      amSelect.value = newAmValue;
    },
    [getLocalTimeObjectFromUnixCallback],
  );

  const formatUserInput = useCallback(() => {
    if (isPickerEmpty()) {
      return;
    }

    const hoursInput = hoursRef.current;
    const minutesInput = minutesRef.current;

    if (!hoursInput || !minutesInput) {
      return;
    }
    if (!isHoursFilled()) {
      silentSetValue(hoursRef, '00');
    }
    if (!isMinutesFilled()) {
      silentSetValue(minutesRef, '00');
    }
    if (hoursInput.value.length === 1) {
      silentSetValue(hoursRef, `0${hoursInput.value}`);
    }
    if (minutesInput.value.length === 1) {
      silentSetValue(minutesRef, `0${minutesInput.value}`);
    }
  }, [isPickerEmpty]);

  const clearTimePickerError = useCallback(() => {
    setTimePickerError(false);
    setTimePickerErrorMessage(undefined);
  }, []);

  const validate = useCallback(
    (unix: string) => {
      if (!unix || _.isNaN(+unix)) {
        clearTimePickerError();
        return true;
      }
      const unixTz = timeTz(unix);
      const minTimeTz = minTime && timeTz(minTime);
      const maxTimeTz = maxTime && timeTz(maxTime);

      if (minTimeTz && unixTz.isBefore(minTimeTz, 'minutes')) {
        setTimePickerError(true);
        setTimePickerErrorMessage(`Min: ${minTimeTz.format(fullTimeFormat)}`);
        return false;
      }

      if (maxTimeTz && unixTz.isAfter(maxTimeTz, 'minutes')) {
        setTimePickerError(true);
        setTimePickerErrorMessage(`Max: ${maxTimeTz.format(fullTimeFormat)}`);
        return false;
      }

      clearTimePickerError();
      return true;
    },
    [fullTimeFormat, maxTime, minTime, clearTimePickerError],
  );

  const updateHiddenInputValue = useCallback(
    _.debounce(() => {
      if (!isPickerFilled(true)) {
        setNativeValue(hiddenRef, '');
        return;
      }
      const newValue = getUnixFromUserInput();
      setNativeValue(hiddenRef, newValue || '');
    }, 300),
    [getUnixFromUserInput, isPickerFilled],
  );

  useFieldErrorDispatcher(timePickerError, onValidError, onClearError);

  return {
    timeFormat,
    timePickerError,
    timePickerErrorMessage,
    hiddenRef,
    hoursRef,
    minutesRef,
    amRef,
    dispatchBlurEvent,
    formatUserInput,
    getFirstUnfilledInput,
    getSelectOptions,
    isPickerEmpty,
    getNextInput,
    getPreviousInput,
    getInputRefByName,
    getUnixFromUserInput,
    setTimeInputs,
    updateHiddenInputValue,
    validate,
  };
};
