import { atom, selector } from 'recoil';
import _ from 'lodash';

import { AttendancePill, FetchTimeTracker, ParsedAttendancePill } from 'api/actions/drawer/drawerActions.types';
import { PersonName, TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { timeTz } from 'utils/dateTime';
import { TimeEventFunc } from 'layouts/AuthorizedApp/Drawer/TimeTracker/TimeEventTimer';
import { ENTRY_TIME_EVENT_ID, EXIT_TIME_EVENT_ID } from 'Kiosk/constants/constants';
import { stopwatchTimeFormat } from 'utils/stopwatchTimeFormat';

import { organizationSessionAtom } from './organizationSession';
import { nameDisplayOrderSelector } from './userSession';

export const attendancePillAtom = atom<AttendancePill | null>({
  key: 'attendancePill',
  default: null,
});

export const drawerSearchInputAtom = atom<string>({
  key: 'drawerSearchInput',
  default: '',
});

export const parsedDrawerSearchInputSelector = selector<string>({
  key: 'parsedDrawerSearchInput',
  get: ({ get }) => {
    const query = get(drawerSearchInputAtom);
    return query.replace(/ /g, '');
  },
});

export const parsedAndFilteredAttendancePillSelector = selector<ParsedAttendancePill | null>({
  key: 'parsedAndFilteredAttendancePill',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    const attendancePill = get(attendancePillAtom);
    const search = get(parsedDrawerSearchInputSelector);
    const { sortBy } = get(nameDisplayOrderSelector);

    if (!organizationSession || !attendancePill) return null;

    const { employeesMap, tagsMap, rolesMap } = organizationSession;

    const searchingFor = (person: PersonName) => {
      const string = `${person.firstName}${person.surname}${person.surname}${person.firstName}`;
      return string.toLowerCase().includes(search.toLowerCase());
    };

    const parsedAttendancePill = _.mapValues(attendancePill, (_pill, key: keyof AttendancePill) => {
      if (key === 'locations') {
        return _.orderBy(
          _.map(attendancePill[key], ({ locationName, people }) => ({
            locationName,
            people: _(
              _.map(people, (p) => {
                const employee = employeesMap.get(p.personId);

                if (!employee) return null;

                return {
                  ..._.omit(p, ['personId']),
                  name: employee.name,
                  tags: employee.tagsIds.flatMap((tagId) => tagsMap.get(tagId)),
                  role: rolesMap.get(employee.roleId),
                  avatarUrl: employee.avatarUrl,
                };
              }),
            )
              .filter((u) => (u ? searchingFor(u.name) : false))
              .orderBy([`name.${sortBy}`])
              .value(),
          })),
          ['locationName'],
        );
      }

      return _(
        _.map(attendancePill[key], (p) => {
          const employee = employeesMap.get(p.personId);

          if (!employee) return null;

          return {
            ..._.omit(p, ['personId']),
            name: employee.name,
            tags: employee.tagsIds.flatMap((tagId) => tagsMap.get(tagId)),
            role: rolesMap.get(employee.roleId),
            avatarUrl: employee.avatarUrl,
          };
        }),
      )
        .filter((u) => (u ? searchingFor(u.name) : false))
        .orderBy([`name.${sortBy}`])
        .value();
    }) as unknown as ParsedAttendancePill;

    return parsedAttendancePill;
  },
});

export const timeTrackerAtom = atom<FetchTimeTracker | null>({
  key: 'timeTracker',
  default: null,
});

export const actionHasBeenSentAtom = atom<boolean>({
  key: 'actionHasBeenSent',
  default: false,
});

type PostTimeEventFunc = {
  postTimeEvent: (body: TimeEventFunc) => Promise<void>;
};

export const postTimeEventAtom = atom<PostTimeEventFunc | null>({
  key: 'postTimeEvent',
  default: null,
});

export type ParsedTimeEvent = {
  variant: 'work' | 'custom';
  initialTime: number;
} & Pick<TimeEventType, 'id' | 'name'> & {
    isActive: boolean;
  } & PostTimeEventFunc;

type ParsedRecentTimeEvent = ParsedTimeEvent & {
  maxDurationAllowedSeconds?: string;
};

type ParsedWorkTime = {
  workStartTime: string;
} & ParsedTimeEvent;

type ParsedTimeTracker = {
  workTime: ParsedWorkTime;
  recentTimeEventsList: ParsedRecentTimeEvent[];
  availableTimeEvents: TimeEventType[];
  postTimeEvent: (body: TimeEventFunc) => Promise<void>;
} & Pick<FetchTimeTracker, 'suggestedTimeEvent'>;

export const parsedTimeTrackerSelector = selector<ParsedTimeTracker | null>({
  key: 'parsedTimeTracker',
  get: ({ get }) => {
    const timeTracker = get(timeTrackerAtom);
    const organizationSession = get(organizationSessionAtom);
    const postTimeEventFunc = get(postTimeEventAtom);

    if (!timeTracker || !organizationSession || !postTimeEventFunc) return null;

    const { postTimeEvent } = postTimeEventFunc;
    const { timeEventTypes } = organizationSession;
    const { workTimeSeconds, lastEnterUnix, recentTimeEvents, suggestedTimeEvent } = timeTracker;

    const workTime: ParsedWorkTime = {
      id: workTimeSeconds ? EXIT_TIME_EVENT_ID : ENTRY_TIME_EVENT_ID,
      initialTime: workTimeSeconds,
      isActive: !!lastEnterUnix,
      workStartTime: `${timeTz(lastEnterUnix).format('HH:mm')}`,
      variant: 'work',
      postTimeEvent,
      name: 'drawer.time_event.work_time',
    };

    const availableTimeEvents: TimeEventType[] = _.filter(timeEventTypes, (tE) => {
      const findTimeEvent = _.find(recentTimeEvents, (recentTE) => tE.id === recentTE.type.id && !tE.isDeleted);

      return !(
        findTimeEvent ||
        _.isEqual(tE.id, EXIT_TIME_EVENT_ID) ||
        _.isEqual(tE.id, ENTRY_TIME_EVENT_ID) ||
        tE.isDeleted
      );
    });

    const recentTimeEventsList: ParsedRecentTimeEvent[] = _.map(
      recentTimeEvents,
      ({ durationSeconds, maxDurationAllowedSeconds, type }) => ({
        initialTime: durationSeconds,
        ...(maxDurationAllowedSeconds && { maxDurationAllowedSeconds: stopwatchTimeFormat(maxDurationAllowedSeconds) }),
        isActive: false,
        variant: 'custom',
        postTimeEvent,
        ...type,
      }),
    );
    return { workTime, availableTimeEvents, recentTimeEventsList, postTimeEvent, suggestedTimeEvent };
  },
});

export const activeTimeEventSelector = selector<ParsedTimeEvent | null>({
  key: 'activeTimeEvent',
  get: ({ get }) => {
    const timeTracker = get(parsedTimeTrackerSelector);

    if (!timeTracker) return null;

    const { workTime, recentTimeEventsList, suggestedTimeEvent } = timeTracker;

    const additionalEvent = _.find(recentTimeEventsList, ({ id }) => id === suggestedTimeEvent.type.id);

    if (additionalEvent) {
      return { ...additionalEvent, isActive: true };
    }

    return workTime;
  },
});
