import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { fetchUserNotificationsSettingsAction } from 'api/actions/settings/settingsActions';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { NotificationsForm } from 'layouts/Settings/User/forms/NotificationsForm';
import { resetFormButtonAtom, resetFormButtonCallbacksSelector } from 'state/settings';

export const Notifications = (): React.ReactElement => {
  useLingui();
  const { payload } = useQuery(fetchUserNotificationsSettingsAction());
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);

  const handleResetButtonClick = () => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  };

  return (
    <>
      <HeadingTip
        label={t({
          id: 'settings.user.notifications.header',
          message: 'Notifications',
        })}
        displayReset={!!formResetCallbacks}
        displayOnClick={() => handleResetButtonClick()}
      />
      {payload ? (
        <NotificationsForm payload={payload} />
      ) : (
        <Flex
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            minHeight: '400px',
          }}
        >
          <LoadingSpinnerCss size={4} />
        </Flex>
      )}
    </>
  );
};
