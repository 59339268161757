/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import { useLocation } from 'react-router';
import { ClientContext } from 'react-fetching-library';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Logo } from 'components/Logo/Logo';
import { nightModeSelector } from 'Kiosk/state/kioskState';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { CameraViewButton, Clock, Footer, OverlayContainer } from 'Kiosk/Layout';
import { ElementGroup } from 'components/ui/ElementGroup';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { settingsAtom } from 'Kiosk/state/settingsState';
import { useGeoLocation } from 'Kiosk/hooks/useGeoLocation';
import { useAnimationFrame } from 'Kiosk/hooks/useAnimationFrame';
import { useQrScanner } from 'Kiosk/hooks/useQrScanner';
import { useFaceDetection } from 'Kiosk/hooks/useFaceLandmarkModel';
import { useCameraImageBuffer } from 'Kiosk/hooks/useCameraImageBuffer';
import { useCamera } from 'Kiosk/hooks/useCamera';
import { DEFAULT_FPS, FACE_DETECT_MINIMUM_CONFIDENCE } from 'Kiosk/constants/constants';
import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { getInfo } from 'api/actions/timeclock/timeclockActions';
import { ErrorCodeTimeclock } from 'api/actions/timeclock/timeclockActions.types';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { ModalRoutes } from './ModalRoutes';

export const Start = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  const [nightMode, setNightMode] = useRecoilState(nightModeSelector);
  const language = useRecoilValue(languageSelector);
  const settings = useRecoilValue(settingsAtom);
  const [, setOverlay] = useRecoilState(overlayStateAtom);

  const { location } = useGeoLocation();

  const match = useCleanRouteMatch();
  const { pathname } = useLocation();
  const { query } = useContext(ClientContext);

  const { getPrediction } = useFaceDetection();
  const { getBlob, getImageData } = useCameraImageBuffer();
  const { isCameraStreaming, toggleVideoPlayback } = useCamera();

  const { addSnackbar } = useSnackbar();

  useLingui();

  // 2. onSuccessful scan
  // If Face (if req) and QR present
  const onSuccessfulScan = async (qr: string) => {
    if (qr !== '') {
      const filteredSnapshots = getBlob && _.filter(getBlob(), (snap, index) => index % DEFAULT_FPS === 0);

      const imagesBlob = await Promise.all(
        _.map(filteredSnapshots, async (snap) => {
          const snapFetch = await fetch(snap);
          const snapBlob = await snapFetch.blob();

          return snapBlob;
        }),
      );

      if (toggleVideoPlayback) toggleVideoPlayback(false);
      setIsLoading(true);

      const { payload, error } = await query(getInfo({ qrCode: qr, images: imagesBlob }));

      if (payload) {
        if (error) {
          const { innerCode, data } = payload;

          setIsLoading(false);

          if (innerCode === ErrorCodeTimeclock.NO_FACE_MODEL && data) {
            if (!settings?.isFaceRecognitionRequired) {
              window.location.href = `${window.location.href}?alert=alert_kiosk_session_refresh`;
              return;
            }

            stopAnimation();
            if (toggleVideoPlayback) toggleVideoPlayback(true);
            setOverlay({
              type: KioskOverlay.initialFaceScan,
              state: {
                name: data.personName,
                qrCode: qr,
              },
            });
          }

          if (innerCode === ErrorCodeTimeclock.INVALID_PERSON_FACE) {
            if (toggleVideoPlayback) toggleVideoPlayback(true);
            addSnackbar({
              message: i18n._(
                t({
                  id: 'kiosk.camera_view.invalid_face_notification.title',
                  message: 'Your face was not recognized!',
                }),
              ),
              variant: 'danger',
            });
          }

          if (innerCode === ErrorCodeTimeclock.BAD_QR_CODE) {
            if (toggleVideoPlayback) toggleVideoPlayback(true);
            addSnackbar({
              message: t({
                id: 'kiosk.camera_view.bad_qr_notification',
                message: 'Wrong QR code!',
              }),
              variant: 'danger',
            });
          }

          if (innerCode === ErrorCodeTimeclock.CLOCK_IN_NOT_ALLOWED) {
            if (toggleVideoPlayback) toggleVideoPlayback(true);
            addSnackbar({
              message: t({
                id: 'kiosk.camera_view.clock_in_not_allowed',
                message: `You aren't allowed to Clock In via Kiosk.`,
              }),
              variant: 'danger',
            });
          }

          return;
        }

        setIsLoading(false);
        setOverlay({
          type: KioskOverlay.summary,
          state: {
            getInfoPayload: payload,
            qrCode: qr,
            eventImage: imagesBlob[0],
          },
        });
      }
    }
  };

  const { decode } = useQrScanner({ onDecode: onSuccessfulScan });

  // 1. scan for QR and Face (if req); check if geolocation required
  const scanForQrAndFace = async () => {
    let faceConfidence = 0;
    const frame = getImageData && (await getImageData());

    if (frame && getPrediction && settings && settings.isFaceRecognitionRequired) {
      const faceDetect = await getPrediction(frame);
      faceConfidence = faceDetect.length > 0 ? faceDetect[0].faceInViewConfidence : 0;
    }

    if (frame && decode) {
      if (
        settings &&
        ((settings.isFaceRecognitionRequired && faceConfidence >= FACE_DETECT_MINIMUM_CONFIDENCE) ||
          !settings.isFaceRecognitionRequired) &&
        ((settings.isLocationRequired && location) || !settings.isLocationRequired)
      ) {
        decode(frame);
      }
    }
  };

  const { startAnimation, stopAnimation } = useAnimationFrame({
    callback: scanForQrAndFace,
  });

  useEffect(() => {
    if (isCameraStreaming && pathname === TO.KIOSK[language]) {
      startAnimation();
      return;
    }

    stopAnimation();
  }, [isCameraStreaming, language, pathname, startAnimation, stopAnimation]);

  return (
    <>
      {isLoading && (
        <LoadingSplash
          background="rgba(0,0,0,.75)"
          color="white"
          sx={{
            zIndex: 1,
            '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
              backgroundColor: 'rgba(0,0,0,.55)',
              backdropFilter: 'blur(14px)',
            },
          }}
        />
      )}

      <OverlayContainer sxInner={{ justifyContent: 'space-between' }}>
        <Clock nightMode={nightMode} />

        <Footer
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...(isLoading && { pointerEvents: 'none' }),
          }}
        >
          <ElementGroup showAsList marginValue={2}>
            <CameraViewButton icon="settings" to={`${match}${TO.KIOSK_SETTINGS_MODAL[language]}`} />
            <CameraViewButton icon="help" to={`${match}${TO.KIOSK_HELP_CENTER_MODAL[language]}/1`} />
            <CameraViewButton
              icon={location ? 'location' : 'locationOff'}
              to={`${match}${TO.KIOSK_LOCATION_MODAL[language]}`}
              warning={!location && !!settings && settings.isLocationRequired}
            />
            <CameraViewButton
              active={nightMode}
              icon={nightMode ? 'nightModeFull' : 'nightMode'}
              onClick={() => {
                setNightMode(!nightMode);
              }}
            />
          </ElementGroup>

          <Logo fill="currentColor" width={[100, 140]} />
        </Footer>
      </OverlayContainer>
      <ModalRoutes />
    </>
  );
};
