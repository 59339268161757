import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { windowSizeAtom } from 'state/recoilState';

export const useOnOutsideClick = (
  ref: React.RefObject<HTMLInputElement | HTMLDivElement | HTMLButtonElement> | null,
  callback: () => void,
): void => {
  const { isMobile } = useRecoilValue(windowSizeAtom);

  useEffect(() => {
    function handleClickOutside(event: TouchEvent | MouseEvent) {
      if (!ref?.current) return;
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    if (isMobile) {
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (isMobile) {
        document.removeEventListener('touchstart', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [ref, callback, isMobile]);
};
