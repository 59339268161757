import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

interface Props extends FlexProps {
  children: React.ReactElement[] | React.ReactElement;
  gutter?: string;
  fullWidth?: boolean;
}

const defaultProps: Partial<Props> = {
  gutter: '0.75rem',
  fullWidth: false,
};

export const FlexGrid = React.forwardRef<HTMLDivElement, Props>(
  ({ children, gutter, fullWidth, sx, ...props }: Props, ref) => (
    <Flex ref={ref} {...props} sx={{ flexGrow: 1, flexWrap: 'wrap', ...(fullWidth && { mx: `-${gutter}` }), ...sx }}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          sx: { ...child.props.sx, px: gutter },
        }),
      )}
    </Flex>
  ),
);

FlexGrid.defaultProps = defaultProps;
