import React, { useEffect } from 'react';
import { Text, Image, Flex } from 'theme-ui';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Trans } from '@lingui/macro';

import { Container } from 'components/ui/Container';
import { AnchorButton, LinkButton } from 'components/ui/Buttons';
import { languageSelector } from 'state/recoilState';
import { TO } from 'constants/routes';
import { images } from 'Kiosk/constants/images';
import { usePermissions } from 'Kiosk/hooks/usePermissions';
import { PermissionState } from 'Kiosk/state/permissionState';

export const LocationRequiredErrorPage = (): React.ReactElement => {
  const { location } = usePermissions();

  const { push } = useHistory();
  const language = useRecoilValue(languageSelector);

  useEffect(() => {
    if (location === PermissionState.allow) {
      push(TO.KIOSK[language]);
    }
  }, [location, language, push]);

  return (
    <Container size="sm" sx={{ textAlign: 'center', alignItems: 'center', alignSelf: 'center' }}>
      <Image
        width="256px"
        alt="Camera"
        src={images.location['1x']}
        srcSet={`${images.location['2x']}, ${images.location['3x']}`}
      />
      <Text variant="heading2" as="h2">
        <Trans id="kiosk.home.location_permission_modal.title">Location access is required</Trans>
      </Text>
      <Text variant="pLead">
        <Trans id="kiosk.home.location_permission_modal.message">
          Your organization requires the location services in order to use the app.
        </Trans>
      </Text>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: 3, gap: 2 }}>
        <AnchorButton
          href="#TODO:-dodac-linka-do-pomocy"
          target="_blank"
          variant="primary"
          size="lg"
          shape="pill"
          fullWidth
        >
          <Trans id="kiosk.home.location_permission_modal.how_to_enable">Check how to enable location</Trans>
        </AnchorButton>
        <LinkButton to="/" variant="minimal" shape="pill" size="lg">
          <Trans id="global.go_back_to_app">Go back to the app</Trans>
        </LinkButton>
      </Flex>
    </Container>
  );
};
