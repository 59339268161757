import React, { useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { Action, useMutation } from 'react-fetching-library';

import { Checkbox } from 'components/ui/Checkbox';
import { Modal } from 'components/Modal/Modal';
import { delay } from 'utils/delay';
import { useModal } from 'hooks/useModal/useModal';
import { BasicModalFooter, BasicModalFooterProps } from 'components/recipes/BasicModalFooter';
import { useSelectedIdsModalLogic } from 'layouts/Team/hooks/useSelectedIdsModalLogic';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

type UnknownObject = {
  [key: string]: unknown;
};

type ActionProps = string[];

type Props = {
  list: Map<string, unknown> | null;
  namePath: string | [string, string];
  action: (body: ActionProps) => Action<UnknownObject>;
  actionOnSuccess?: (ids: string[]) => void;
  titleRenderer: (isMulti: boolean, name: string) => string;
  contentRenderer: (
    isMulti: boolean,
    selectedNames: string[],
    selectedIds: string[],
    baseRoute: string,
  ) => React.ReactElement;
  withConfirmation?: boolean;
  variant?: 'DELETE' | 'APPROVE' | 'REJECT';
};

const defaultProps: Omit<Partial<Props>, 'variant'> & Required<Pick<Props, 'variant'>> = {
  variant: 'DELETE',
  withConfirmation: undefined,
  actionOnSuccess: undefined,
};

export const ConfirmModal = ({
  list,
  action,
  actionOnSuccess,
  titleRenderer,
  contentRenderer,
  withConfirmation,
  variant = defaultProps.variant,
  namePath,
}: Props): React.ReactElement => {
  const { addSnackbar } = useSnackbar();
  const { baseRoute, handleClose } = useModal();
  const { selectedIds, selectedNames, isMulti, loading, setLoading, confirmed, setConfirmed } =
    useSelectedIdsModalLogic(list, namePath);

  const { mutate } = useMutation(action);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
  };

  const handleClick = useCallback(async () => {
    setLoading(true);

    const { error: submitError } = await mutate(selectedIds);

    if (!submitError) {
      if (handleClose) {
        handleClose();
      }
    }
    setLoading(false);
    if (!submitError) {
      if (actionOnSuccess) actionOnSuccess(selectedIds);
      await delay(100);
      addSnackbar({
        message: (() => {
          switch (variant) {
            case 'DELETE':
              return 'Successfully deleted!';
            case 'APPROVE':
              return 'Successfully approved!';
            case 'REJECT':
              return 'Successfully rejected!';
            default:
              return '';
          }
        })(),
        variant: 'success',
      });
    }
  }, [setLoading, mutate, selectedIds, handleClose, actionOnSuccess, addSnackbar, variant]);

  const buttons: BasicModalFooterProps['buttons'] = useMemo(
    () => [
      {
        ...(withConfirmation && { disabled: !confirmed }),
        isLoading: loading,
        onClick: handleClick,
        variant: (() => {
          switch (variant) {
            case 'DELETE':
              return 'danger';
            case 'REJECT':
              return 'danger';
            case 'APPROVE':
              return 'success';
            default:
              return 'primary';
          }
        })(),
        children: (() => {
          switch (variant) {
            case 'DELETE':
              return 'Delete';
            case 'REJECT':
              return 'Reject';
            case 'APPROVE':
              return 'Approve';
            default:
              return '';
          }
        })(),
      },
    ],
    [withConfirmation, confirmed, loading, handleClick, variant],
  );

  if (!selectedIds.length) {
    return <Redirect to={baseRoute} />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{titleRenderer(isMulti, selectedNames[0])}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        sx={{
          '>span': {
            display: 'block',
            '+span': {
              mt: 4,
            },
          },
        }}
      >
        {contentRenderer(isMulti, selectedNames, selectedIds, baseRoute)}

        {withConfirmation && (
          <Checkbox
            onChange={handleCheckboxChange}
            checked={confirmed}
            name="confirm"
            label="I understand, let's proceed."
            sx={{ mt: 4 }}
          />
        )}
      </Modal.Body>
      <BasicModalFooter buttons={buttons} />
    </>
  );
};

ConfirmModal.defaultProps = defaultProps;
