/* eslint-disable @typescript-eslint/no-explicit-any */
import { atomFamily, selectorFamily, selector, atom } from 'recoil';
import _ from 'lodash';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { UserProfileSettingsActionProps, UserPreferences } from 'api/actions/settings/settingsActions.types';
import { NameDisplayOrder, TimeFormat } from 'api/actions/userSession/userSessionActions.types';
import { REAL_TIMEZONE } from 'constants/common';
import {
  Employee,
  OrganizationDetails,
  PersonName,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { InputOption } from 'components/ui/Select/Select';

import { userSessionAtom } from './userSession';
import { organizationSessionAtom } from './organizationSession';
import { languageSelector, ParsedPersonName, nameDisplayOrderWithInitialsSelector } from './recoilState';
import { rolesSelector } from './employees';

type SettingsName = string;

export const refreshSettingAtomFamily = atomFamily<{ payload?: any; refresh?: () => void } | null, SettingsName>({
  key: 'refreshSetting',
  default: null,
});

export const payloadSelectorFamily = selectorFamily<any, SettingsName>({
  key: 'payload',
  get:
    (settingsName) =>
    ({ get }) => {
      const refreshSettingAtom = refreshSettingAtomFamily(settingsName);
      const refreshSetting = get(refreshSettingAtom);

      if (!refreshSetting) return null;

      const { payload } = refreshSetting;

      return payload;
    },
  set:
    (settingsName) =>
    ({ set, get }, newPayload) => {
      const refreshSettingAtom = refreshSettingAtomFamily(settingsName);
      const refreshSetting = get(refreshSettingAtom);

      set(refreshSettingAtom, { ...refreshSetting, payload: newPayload });
    },
});

export const refreshSelectorFamily = selectorFamily<any, SettingsName>({
  key: 'refresh',
  get:
    (settingsName) =>
    ({ get }) => {
      const refreshSettingAtom = refreshSettingAtomFamily(settingsName);
      const refreshSetting = get(refreshSettingAtom);

      if (!refreshSetting) return null;

      const { refresh } = refreshSetting;

      return refresh;
    },
});

export const userPreferencesSelector = selector<UserPreferences | null>({
  key: 'userPreferences',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    if (!userSession) return null;

    const { preferredLanguage, nameDisplayOrder, dateFormat, timeFormat, timeZoneId } = userSession;

    // eslint-disable-next-line no-underscore-dangle
    const is24HourFormat = _.isEqual(timeFormat, TimeFormat._24h);

    return {
      language: preferredLanguage,
      nameDisplayOrder,
      dateFormat,
      is24HourFormat,
      timeZoneId: timeZoneId || REAL_TIMEZONE,
    };
  },
});

type SettingsPageData = {
  user: ParsedPersonName;
  organization: Required<Omit<OrganizationDetails, 'createDateUtcUnix'>>;
};

export const settingsPageDataSelector = selector<SettingsPageData | null>({
  key: 'settingsPageData',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const organizationSession = get(organizationSessionAtom);

    if (!userSession || !organizationSession) return null;

    const { details, employeesMap } = organizationSession;
    const user = employeesMap.get(userSession.personId);
    if (!user) return null;
    const name = get(nameDisplayOrderWithInitialsSelector(user.name));

    return {
      organization: _.omit(details, ['createDateUtcUnix']),
      user: name,
    };
  },
});

type UserProfileDefaultValues = Omit<UserProfileSettingsActionProps, 'address'> &
  Pick<Employee, 'avatarUrl' | 'id'> &
  PersonName;

export const settingsProfileFormDefaultValuesSelector = selector<UserProfileDefaultValues | null>({
  key: 'settingsProfileFormDefaultValues',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const organizationSession = get(organizationSessionAtom);

    if (!userSession || !organizationSession) return null;

    const { employeesMap } = organizationSession;
    const user = employeesMap.get(userSession.personId);
    if (!user) return null;
    const { avatarUrl, name, id, email, phoneNumber } = user;
    return {
      ...name,
      avatarUrl,
      id,
      email,
      phoneNumber,
    };
  },
});

export const settingsPreferencesNameDisplayOrderOptionsSelector = selector<InputOption[]>({
  key: 'settingsPreferencesNameDisplayOrderOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: i18n._(
          t({
            id: 'name_display_order.name_first',
            message: 'Name and surname',
          }),
        ),
        id: `${NameDisplayOrder.NameFirst}`,
      },
      {
        label: i18n._(
          t({
            id: 'name_display_order.surname_first',
            message: 'Surname and name',
          }),
        ),
        id: `${NameDisplayOrder.SurnameFirst}`,
      },
    ];
  },
});

export const resetFormButtonAtom = atom<{ name: string; callback: () => void }[] | null>({
  key: 'resetFormButton',
  default: null,
});

export const resetFormButtonCallbacksSelector = selector<(() => void)[] | null>({
  key: 'resetFormButtonCallbacks',
  get: ({ get }) => {
    const resetFormAtom = get(resetFormButtonAtom);

    if (!resetFormAtom) return null;

    return resetFormAtom.map((item) => item.callback);
  },
});

export const countryListAtom = atom<string[] | null>({
  key: 'countryList',
  default: null,
});

export const countrySelectSelector = selector<InputOption[] | null>({
  key: 'countrySelect',
  get: ({ get }) => {
    const countryList = get(countryListAtom);
    const language = get(languageSelector);
    const regionNames = new Intl.DisplayNames([language], { type: 'region' });

    if (!countryList || !language) return null;

    const options: InputOption[] = [];

    countryList.forEach((item) => options.push({ label: regionNames.of(item.toUpperCase()), id: item }));

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  },
});

export const rolesApproveRequestsSelectOptionsSelector = selector<InputOption[] | null>({
  key: 'rolesApproveRequestsSelectOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    const roles = get(rolesSelector);
    if (!roles) return null;

    return _.orderBy(
      roles
        .filter(({ canApproveRequests }) => canApproveRequests)
        .map(({ name, id }) => ({
          label: i18n._(
            t({
              id: name,
            }),
          ),
          id,
        })),
      ['label'],
    );
  },
});
