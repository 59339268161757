import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Flex, Input, ThemeUIStyleObject, Text } from 'theme-ui';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';

import { delay } from 'utils/delay';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { Select } from '../Select/Select';
import { useTheme } from 'styles/useTheme';
import { silentSetValue } from 'utils/silentSetValue';
import { focusInput } from 'utils/focusInput';

import { getSelectOptions } from './utils';
import { DurationPickerProps, InputNames } from './types';
import { useDurationPickerLogic } from './useDurationPickerLogic';

const intervalTypeTextSx: ThemeUIStyleObject = {
  ml: 1,
  mr: 2,
};

const defaultProps: Partial<DurationPickerProps> = {
  onValidError: undefined,
  onClearError: undefined,
  hours: true,
  minutes: true,
  seconds: true,
  quickSelectOptions: undefined,
  minDuration: undefined,
  maxDuration: undefined,
  isStatic: false,
};

export const DurationPicker = React.forwardRef<HTMLInputElement, DurationPickerProps>(
  (
    {
      isStatic,
      minDuration,
      maxDuration,
      quickSelectOptions,
      size = 'default',
      hours,
      minutes,
      seconds,
      onChange,
      onBlur,
      onValidError,
      onClearError,
      ...props
    }: DurationPickerProps,
    ref,
  ) => {
    useLingui();
    const [afterFirstBlur, setAfterFirstBlur] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);

    const isFocusRef = useRef<boolean>(false);
    const isBackspaceModeRef = useRef(false);

    const { theme } = useTheme();

    const {
      durationPickerError,
      durationPickerErrorMessage,
      hiddenRef,
      hoursRef,
      minutesRef,
      secondsRef,
      dispatchBlurEvent,
      isPickerEmpty,
      getFirstUnfilledInput,
      getSecondsFromUserInput,
      getNextInput,
      getPreviousInput,
      getInputRefByName,
      setInputsValues,
      formatUserInput,
      validate,
      updateHiddenInputValue,
    } = useDurationPickerLogic({
      minDuration,
      maxDuration,
      hours,
      minutes,
      seconds,
      onValidError,
      onClearError,
    });

    const handleUserChange = useCallback(
      ({ target: { value, name: inputName, selectionStart: cursorPosition } }: React.ChangeEvent<HTMLInputElement>) => {
        const inputsUpdates: {
          inputRef: React.MutableRefObject<HTMLInputElement | null>;
          newValue?: string;
          newCursorPosition?: number;
        }[] = [];

        const currentInputRef = getInputRefByName(inputName);
        const prevInputRef = getPreviousInput(inputName);
        const nextInputRef = getNextInput(inputName);

        const minutesSecondsValueParser = (newValue: string) => {
          if (newValue.length === 2) {
            return `${Math.min(+newValue[0], 5)}${newValue[1]}`;
          }
          return newValue;
        };

        switch (value.length) {
          case 0:
            if (prevInputRef) {
              inputsUpdates.push({
                inputRef: prevInputRef,
                newCursorPosition: 2,
              });
            }
            break;
          case 1:
            break;
          case 2:
            if (inputName !== InputNames.hours && +value > 59) {
              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue: '59',
              });
            }

            if (cursorPosition === 2 && nextInputRef) {
              inputsUpdates.push({
                inputRef: nextInputRef,
                newCursorPosition: 0,
              });
            }
            break;
          default:
            if (cursorPosition && cursorPosition > 2) {
              if (isBackspaceModeRef.current && nextInputRef) {
                const currentInputNewValue = value.substring(0, 2);
                const nextInputFirstChar = value[2];

                const nextInputValue = nextInputRef?.current?.value;

                const nextInputNewValue = nextInputValue
                  ? `${nextInputFirstChar}${nextInputValue.substring(1)}`
                  : nextInputFirstChar;

                inputsUpdates.push({
                  inputRef: currentInputRef,
                  newValue: currentInputNewValue,
                });

                inputsUpdates.push({
                  inputRef: nextInputRef,
                  newValue: minutesSecondsValueParser(nextInputNewValue),
                  newCursorPosition: 1,
                });

                break;
              }

              const newValue = value.slice(-2);

              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue: inputName === InputNames.hours ? newValue : minutesSecondsValueParser(newValue),
              });

              break;
            } else if (cursorPosition) {
              const filteredValue = [...value].filter((v, i) => i !== cursorPosition).join('');

              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue: inputName === InputNames.hours ? filteredValue : minutesSecondsValueParser(filteredValue),
                newCursorPosition: cursorPosition !== 2 ? cursorPosition : undefined,
              });

              if (cursorPosition === 2 && nextInputRef) {
                inputsUpdates.push({
                  inputRef: nextInputRef,
                  newCursorPosition: 0,
                });
              }

              break;
            }
        }

        inputsUpdates.forEach(({ inputRef, newValue, newCursorPosition }) => {
          if (!_.isNil(newValue)) {
            silentSetValue(inputRef, newValue);
          }

          if (!_.isNil(newCursorPosition)) {
            focusInput(inputRef, newCursorPosition, newCursorPosition);
          }
        });

        updateHiddenInputValue();

        isBackspaceModeRef.current = false;
      },
      [updateHiddenInputValue, getInputRefByName, getNextInput, getPreviousInput],
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.disabled) return;
        const { key, target, ctrlKey } = e;
        const { name: inputName, value, selectionStart: cursorPosition } = target as HTMLInputElement;

        const prevInput = getPreviousInput(inputName);
        const nextInput = getNextInput(inputName);
        const selectedText = window?.getSelection()?.toString() || '';

        if (
          !/[0-9]/.test(key) &&
          key !== 'Tab' &&
          key !== 'ArrowLeft' &&
          key !== 'Backspace' &&
          key !== 'Ctrl' &&
          key !== 'ArrowRight' &&
          key !== 'ArrowUp' &&
          key !== 'ArrowDown' &&
          !ctrlKey
        ) {
          e.preventDefault();
        }

        switch (key) {
          case 'ArrowRight':
            if (cursorPosition === value.length && nextInput) {
              e.preventDefault();
              focusInput(nextInput);
            }
            break;

          case 'ArrowLeft':
            if (cursorPosition === 0 && prevInput) {
              e.preventDefault();
              focusInput(prevInput);
            }
            break;

          case 'Backspace':
            if (cursorPosition === 0 && selectedText.length === 0 && prevInput) {
              e.preventDefault();
              focusInput(prevInput, 2, 2);
            }
            break;

          default:
        }
      },
      [getNextInput, getPreviousInput, props.disabled],
    );

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
          isBackspaceModeRef.current = true;
          e.preventDefault();
          return;
        case 'ArrowRight':
          e.preventDefault();
          return;
        case 'ArrowLeft':
          e.preventDefault();
          break;
        default:
          break;
      }
    };

    const handleFocus = () => {
      setHasFocus(true);
      isFocusRef.current = true;
    };

    const handleHiddenInputBlur = useCallback(
      (e) => {
        isBackspaceModeRef.current = false;
        setAfterFirstBlur(true);
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur],
    );

    const handleBlur = useCallback(async () => {
      setHasFocus(false);
      isFocusRef.current = false;
      await delay(0);

      if (!isFocusRef.current) {
        isBackspaceModeRef.current = false;
        if (!isPickerEmpty()) {
          formatUserInput();
          const newHiddenInputValue = getSecondsFromUserInput();
          if (`${newHiddenInputValue}` !== `${hiddenRef.current?.value}`) {
            setNativeValue(hiddenRef, newHiddenInputValue);
          }
        }

        setAfterFirstBlur(true);
        dispatchBlurEvent();
      }
    }, [formatUserInput, getSecondsFromUserInput, dispatchBlurEvent, isPickerEmpty, hiddenRef]);

    const handleHiddenInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (onChange) {
          onChange(e);
        }

        if (value.length) {
          const secondsFormUserInput = getSecondsFromUserInput();
          if (value !== `${secondsFormUserInput})`) {
            setInputsValues(value);
          }
        }

        validate(value);
      },
      [setInputsValues, onChange, validate, getSecondsFromUserInput],
    );

    const updateView = useCallback(
      (value: string) => {
        setInputsValues(value);
        const valid = validate(value);
        setAfterFirstBlur(!valid);
      },
      [setInputsValues, validate],
    );

    const inputProps = useMemo(
      () => ({
        sx: theme.forms.timePicker.twoDigitInput[size],
        autoComplete: 'off',
        onKeyUp: handleKeyUp,
        onKeyDown: handleKeyDown,
        onChange: handleUserChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
        readOnly: isStatic,
      }),
      [handleBlur, handleUserChange, handleKeyDown, isStatic, size, theme.forms.timePicker.twoDigitInput],
    );

    return (
      <Select
        {...props}
        ref={mergeRefs([ref, hiddenRef])}
        placeholder=""
        size={size}
        focusThiefElement={!hasFocus ? getFirstUnfilledInput() || undefined : undefined}
        alwaysHideOptions={!quickSelectOptions}
        ignoreOptions={!quickSelectOptions}
        error={props.error || (durationPickerError && afterFirstBlur)}
        errorMessage={durationPickerErrorMessage || props.errorMessage}
        options={getSelectOptions(quickSelectOptions)}
        onBlur={handleHiddenInputBlur}
        onChange={handleHiddenInputChange}
        onUpdateFieldView={updateView}
        customContent={
          <Flex sx={{ alignItems: 'center', borderRadius: 'inherit' }}>
            {hours && (
              <>
                <Input {...inputProps} name={InputNames.hours} ref={hoursRef} />
                <Text sx={intervalTypeTextSx}>
                  <Trans id="duration_picker.h">h</Trans>
                </Text>
              </>
            )}
            {minutes && (
              <>
                <Input {...inputProps} name={InputNames.minutes} ref={minutesRef} />
                <Text sx={intervalTypeTextSx}>
                  <Trans id="duration_picker.minutes">min</Trans>
                </Text>
              </>
            )}
            {seconds && (
              <>
                <Input {...inputProps} name={InputNames.seconds} ref={secondsRef} />
                <Text sx={intervalTypeTextSx}>
                  <Trans id="duration_picker.sec">sec</Trans>
                </Text>
              </>
            )}
          </Flex>
        }
      />
    );
  },
);

DurationPicker.defaultProps = defaultProps;
