import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { filteredAddRequestEmployeesSelector } from 'state/requests';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { ADD_REQUEST_PICK_TEAMMATES_LIST } from 'layouts/Requests/constans';

import { AddRequestStep1HeaderMenu } from './elements/AddRequestStep1HeaderMenu';

const LazyPickTeammatesList = React.lazy(() =>
  import('layouts/Requests/modals/addRequest/elements/PickTeammatesList').then(({ PickTeammatesList }) => ({
    default: PickTeammatesList,
  })),
);

export const AddRequestStep1 = (): React.ReactElement => {
  const filteredEmployee = useRecoilValue(filteredAddRequestEmployeesSelector);

  return (
    <>
      <AddRequestStep1HeaderMenu />
      <Flex
        sx={{
          mx: -4,
          flexGrow: 1,
          position: 'relative',
        }}
      >
        <React.Suspense fallback={<LoadingOverlay sx={{ zIndex: 1000 }} />}>
          {filteredEmployee && (
            <LazyPickTeammatesList employees={filteredEmployee} listName={ADD_REQUEST_PICK_TEAMMATES_LIST} />
          )}
        </React.Suspense>
      </Flex>
    </>
  );
};
