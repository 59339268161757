import { RequestInterceptor } from 'react-fetching-library';

import { Action } from '../types';
import { REAL_TIMEZONE } from 'constants/common';

export const requestTimezoneInterceptor: () => RequestInterceptor = () => () => async (action: Action<unknown>) => {
  if (action.config && !action.config.sendTimezone) {
    return action;
  }
  return {
    ...action,
    headers: {
      Timezone: REAL_TIMEZONE,
      ...action.headers,
    },
  };
};
