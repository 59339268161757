import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { requestGetDataToModifyAction } from 'api/actions/requests/requestsActions';
import { BusinessTravelsData, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { StickyList } from 'components/StickyList/StickyList';
import { StickyListProps } from 'components/StickyList/types';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { BUSINESS_HISTORY_LIST } from 'layouts/Requests/constans';
import { selectedRowsAtomFamily } from 'state/list';
import { businessHistorySelector, requestHistoryAtom, selectedEmployeesAddRequestsIdsSelector } from 'state/requests';
import { dateTime } from 'utils/dateTime';

type Props = {
  label: string;
};

export const BusinessHistoryList = ({ label }: Props): React.ReactElement => {
  useLingui();
  const { query, loading } = useParameterizedQuery(requestGetDataToModifyAction);
  const setRequestHistory = useSetRecoilState(requestHistoryAtom);
  const selectedUsers = useRecoilValue(selectedEmployeesAddRequestsIdsSelector);
  const businessHistory = useRecoilValue(businessHistorySelector);
  const resetListSelection = useResetRecoilState(selectedRowsAtomFamily(BUSINESS_HISTORY_LIST));

  const getHistory = useCallback(async () => {
    const personId = selectedUsers[0];

    const { error, payload } = await query({ personId, skip: 0, type: RequestFormType.BusinessTrip });

    if (!error && payload) {
      setRequestHistory(payload);
    }
  }, [query, selectedUsers, setRequestHistory]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  useEffect(() => () => resetListSelection(), [resetListSelection]);

  const columns: StickyListProps<BusinessTravelsData>['columns'] = [
    {
      key: 'dateTimeDetails',
      sortableValue: (dateTimeDetails: BusinessTravelsData['dateTimeDetails']) =>
        `${dateTimeDetails.dateRange?.startUnix}`,
      customCellRenderer: (dateTimeDetails: BusinessTravelsData['dateTimeDetails']) => {
        const { dateRange, duration } = dateTimeDetails;
        if (!dateRange || !duration) return <></>;

        const { startUnix, endUnix } = dateRange;
        const { days } = duration;

        const renderDate = () => `${dateTime(startUnix).format('MMM DD')} - ${dateTime(endUnix).format('MMM DD')}`;

        const renderDuration = () => (
          <Text sx={{ color: 'grayBlues3' }}>
            {' '}
            ({days} {t({ id: 'days' })})
          </Text>
        );

        return (
          <TextEllipsis title={renderDate()}>
            {renderDate()}
            {renderDuration()}
          </TextEllipsis>
        );
      },
    },
    {
      key: 'name',
      customCellRenderer: (name: BusinessTravelsData['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
    },
  ];

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ fontWeight: 'bold', mb: 2 }}>{label}</Text>
      <Flex sx={{ minHeight: '230px', width: '100%' }}>
        <StickyList
          name={BUSINESS_HISTORY_LIST}
          columns={columns}
          list={businessHistory || []}
          variant="inverted"
          select="radio"
          emptyListMessage={loading && t({ id: 'global.loading' })}
        />
      </Flex>
    </Flex>
  );
};
