import React from 'react';
import { css, Global } from '@emotion/react';

import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props): React.ReactElement => {
  const { width, height } = useWindowSize({ useRecoil: true });

  return (
    <>
      <Global
        styles={(theme) => ({
          ':root': {
            '--app-height': `${height}px`,
            '--app-width': `${width}px`,
          },
          'html, body': {
            display: 'flex',
            fontFamily: theme.fonts.body,
            lineHeight: theme.lineHeights.body,
            fontWeight: theme.fontWeights.body,
          },
          html: {
            justifyContent: 'stretch',
            alignItems: 'stretch',
            width: '100%',
            height: '100%',
          },
          body: {
            flexGrow: 1,
            maxHeight: '100%',
            overflow: 'hidden',
          },
          ul: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
          },
          a: {
            color: 'inherit',
            textDecoration: 'inherit',
          },
          '#app': {
            display: 'flex',
            flexGrow: 1,
            overflow: 'auto',
            overflowX: 'hidden',
          },
          '#modal-root': {
            position: 'relative',
            zIndex: theme.zIndices.modal,
          },
          '#snackbar-root': {
            position: 'relative',
            zIndex: theme.zIndices.snackbar,
          },
          '#notification-root': {
            position: 'fixed',
            top: '100px',
            right: '30px',
            zIndex: theme.zIndices.notifications,
            '@media screen and (max-width: 500px) ': {
              right: '50%',
              transform: 'translateX(50%)',
            },
          },
          '#popper-root': {
            position: 'relative',
            zIndex: theme.zIndices.popper,
          },
          '#chat-root': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: theme.zIndices.chatMobile,
            pointerEvents: 'none',
            height: 0,
            overflow: 'visible',
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'flex-end',
            '@media screen and (max-width: 991px) ': {
              zIndex: theme.zIndices.chat,
            },
          },
        })}
      />
      <Global
        styles={css`
          @font-face {
            font-family: 'Proxima Nova Soft';
            src: url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.woff2') format('woff2'),
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.woff') format('woff'),
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.ttf') format('truetype');
            font-weight: 400;
            font-style: normal;
            font-display: fallback;
          }
          @font-face {
            font-family: 'Proxima Nova Soft';
            src: url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.woff2') format('woff2'),
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.woff') format('woff'),
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.ttf') format('truetype');
            font-weight: 700;
            font-style: normal;
            font-display: fallback;
          }
        `}
      />
      {children}
    </>
  );
};
