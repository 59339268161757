import React from 'react';
import { ThemeUIStyleObject, Flex } from 'theme-ui';

type Props = {
  children: React.ReactNode;
  sxOuter?: ThemeUIStyleObject;
  sxInner?: ThemeUIStyleObject;
  blurBackground?: boolean;
};

const defaultProps: Partial<Props> = {
  sxOuter: undefined,
  sxInner: undefined,
  blurBackground: false,
};

export const OverlayContainer = ({
  children,
  sxOuter,
  sxInner,
  blurBackground,
  ...props
}: Props): React.ReactElement => (
  <Flex
    {...props}
    as="main"
    sx={{
      justifyContent: 'center',
      alignItems: 'stretch',
      position: 'relative',
      height: '100%',
      zIndex: 1,
      ...(sxOuter && sxOuter),
      ...(blurBackground && {
        pt: [null, null, '5rem'],
        backgroundColor: 'rgba(0,0,0,.75)',
        '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
          backgroundColor: 'rgba(0,0,0,.55)',
          backdropFilter: 'blur(14px)',
        },
      }),
    }}
  >
    <Flex
      {...props}
      sx={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        overflowY: 'auto',
        ...(sxInner && sxInner),
      }}
    >
      {children}
    </Flex>
  </Flex>
);

OverlayContainer.defaultProps = defaultProps;
