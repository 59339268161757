import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useMutation } from 'react-fetching-library';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ElementGroup } from 'components/ui/ElementGroup';
import { Switch } from 'components/ui/Switch';
import { hiddenColumnsTeamViewSelector } from 'state/team';
import { Modal } from 'components/Modal/Modal';
import { delay } from 'utils/delay';
import { editTeamListViewSettingAction } from 'api/actions/employees/employeesActions';
import { useModal } from 'hooks/useModal/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

export const ViewSettingsModal = (): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { handleClose } = useModal();
  const hiddenColumns = useRecoilValue(hiddenColumnsTeamViewSelector);

  const { watch, register } = useForm({
    defaultValues: {
      roleAndTags: !hiddenColumns.includes('roleAndTags'),
      workPosition: !hiddenColumns.includes('workPosition'),
      email: !hiddenColumns.includes('email'),
      phoneNumber: !hiddenColumns.includes('phoneNumber'),
      customEmployeeId: !hiddenColumns.includes('customEmployeeId'),
    },
  });

  const formState = watch();
  const newHiddenColumns = useMemo(() => _.keys(_.pickBy(formState, (val, key) => !val && key)), [formState]);

  const { mutate } = useMutation(editTeamListViewSettingAction);

  const handleSave = async () => {
    setLoading(true);
    const { error: submitError } = await mutate({
      hiddenColumns: newHiddenColumns,
    });
    if (!submitError) {
      if (handleClose) {
        handleClose();
      }
    }
    setLoading(false);
    if (!submitError) {
      await delay(100);
      addSnackbar({
        message: t({ id: 'team.view_settings.edited', message: 'Successfully edited!' }),
        variant: 'success',
      });
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.view_settings">View settings</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ElementGroup showAsList marginValue="0.5rem" direction="column">
          <Switch
            size="sm"
            {...register('roleAndTags')}
            label={t({ id: 'team.view_settings.show_role_tags', message: 'Show Role & tags' })}
            bold
          />

          <Switch
            size="sm"
            {...register('workPosition')}
            label={t({ id: 'team.view_settings.show_position', message: 'Show Position' })}
            bold
          />

          <Switch
            size="sm"
            {...register('email')}
            label={t({ id: 'team.view_settings.show_email', message: 'Show E-mail' })}
            bold
          />

          <Switch
            size="sm"
            {...register('phoneNumber')}
            label={t({ id: 'team.view_settings.phone_no', message: 'Phone number' })}
            bold
          />

          <Switch
            size="sm"
            {...register('customEmployeeId')}
            label={t({ id: 'team.view_settings.empl_id', message: 'Employee ID' })}
            bold
          />
        </ElementGroup>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            disabled: _.isEqual(hiddenColumns, newHiddenColumns),
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
