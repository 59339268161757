import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import parsePhoneNumber, { AsYouType, isValidNumber } from 'libphonenumber-js';
import { t } from '@lingui/macro';
import { Image } from 'theme-ui';
import { useLingui } from '@lingui/react';
import _ from 'lodash';

import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { FLAG_CDN } from 'constants/common';
import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';

import { TextInput, TextInputProps } from './TextInput';

type Props = Omit<TextInputProps, 'type'> & {
  onValidError?: () => void;
  onClearError?: () => void;
};

const defaultProps: Partial<Props> = {
  onValidError: undefined,
  onClearError: undefined,
};

// TODO: this component needs refactoring
export const PhoneInput = React.forwardRef<HTMLInputElement, Props>(
  ({ id, onValidError, onClearError, onChange, onBlur, ...props }: Props, ref) => {
    useLingui();
    const [phoneCountry, setPhoneCountry] = useState<null | string>(null);
    const [afterFirstBlur, setAfterFirstBlur] = useState(false);
    const [phoneInputError, setPhoneInputError] = useState(false);
    const [phoneInputErrorMessage, setPhoneInputErrorMessage] = useState<string | undefined>(undefined);
    const lastInputValueRef = useRef('');

    const inputRef = useRef<HTMLInputElement | null>(null);
    // FIXME: check if needed
    // useLayoutEffect(() => {
    //   const input = inputRef.current;
    //   if (input) {
    //     const country = parsePhoneNumber(input.value)?.country;
    //     setPhoneCountry(country || null);
    //   }
    // }, []);

    useEffect(() => {
      if (onValidError && onClearError) {
        if (phoneInputError) {
          onValidError();
        } else {
          onClearError();
        }
      }
    }, [phoneInputError, onValidError, onClearError]);

    function setInputValue(value: string, cursorPosition?: number, passedInput?: HTMLInputElement): void {
      const input = passedInput || inputRef.current;

      if (input) {
        const nativeInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');
        if (nativeInputValue) {
          const nativeInputValueSetter = nativeInputValue.set;

          if (nativeInputValueSetter) {
            lastInputValueRef.current = value;
            nativeInputValueSetter.call(input, ` ${value}`);
            nativeInputValueSetter.call(input, value);
            if (cursorPosition) {
              input.selectionStart = cursorPosition;
              input.selectionEnd = cursorPosition;
            }

            const event = createEvent('input');

            input.dispatchEvent(event);
          }
        }
      }
    }

    const clearValidationMessages = () => {
      setPhoneInputError(false);
      setPhoneInputErrorMessage(undefined);
    };

    const validate = (value: string) => {
      if (isValidNumber(value)) {
        return;
      }
      setPhoneInputError(true);
      setPhoneInputErrorMessage(
        t({
          id: 'phone_input.invalid',
          message: 'INVALID PHONE NUMBER',
        }),
      );
    };

    const getLongestFormattedNumber = (value: string) => {
      const unformattedLeftovers: string[] = [];
      const formattedCharacters = value.split('');

      let formattingApplied = false;
      value.split('').forEach(() => {
        if (formattingApplied) {
          return;
        }
        const lastCharacter = formattedCharacters.pop() || '';
        const valueWithoutLastChar = formattedCharacters.join('');
        unformattedLeftovers.unshift(lastCharacter);
        if (valueWithoutLastChar.replaceAll(' ', '').length + 1 < new AsYouType().input(valueWithoutLastChar).length) {
          formattingApplied = true;
        }
      });
      const longestFormattedNumber =
        new AsYouType().input(formattedCharacters.join('')) + unformattedLeftovers.join('');
      if (longestFormattedNumber.length < new AsYouType().input(value).length) {
        return new AsYouType().input(value);
      }
      return longestFormattedNumber;
    };

    const getNewCursorPosition = useCallback((value: string, formattedValue: string, cursorPosition: number) => {
      let newCursorPosition = cursorPosition;
      if (
        value.charAt(newCursorPosition - 1) === ' ' &&
        formattedValue.charAt(newCursorPosition - 1) === ' ' &&
        formattedValue.charAt(newCursorPosition) === value.charAt(newCursorPosition)
      ) {
        if (lastInputValueRef.current.length >= value.length) {
          newCursorPosition -= 1;
        } else if (
          formattedValue.charAt(newCursorPosition - 1) === ' ' &&
          lastInputValueRef.current.charAt(newCursorPosition) !== ' '
        ) {
          if (lastInputValueRef.current.charAt(newCursorPosition - 1) !== ' ') {
            newCursorPosition += 1;
          } else {
            newCursorPosition += 1;
          }
        }
      }
      return newCursorPosition;
    }, []);

    const handleInputChange = useCallback(
      (value: string, passedInput?: HTMLInputElement): void => {
        clearValidationMessages();

        if (_.isUndefined(value)) {
          return;
        }

        const cleanValue = value
          .replace(/[^\d+ ]/g, '')
          .replace(/(?!^)\+/g, '')
          .replace(/  +/g, ' ')
          .replace('+ ', '+');

        const country = parsePhoneNumber(cleanValue)?.country;
        setPhoneCountry(country || null);

        if (cleanValue === '' || !cleanValue.replace(/\s/g, '').length) {
          setInputValue('', undefined, passedInput);
          return;
        }

        validate(cleanValue);

        if (cleanValue.length === 1 && cleanValue !== '+') {
          setInputValue(`+${cleanValue}`, undefined, passedInput);
          return;
        }
        if (!cleanValue.includes('+')) {
          setInputValue(`+${cleanValue}`, undefined, passedInput);
          return;
        }

        const formattedValue = getLongestFormattedNumber(cleanValue);

        let cursorAtEnd = true;
        const input = inputRef.current;
        let cursorPosition = value.length;

        if (input && input.selectionStart && input.selectionStart !== cleanValue.length) {
          cursorPosition = input.selectionStart;
          cursorAtEnd = false;
          cursorPosition = getNewCursorPosition(cleanValue, formattedValue, cursorPosition);
        }

        setInputValue(formattedValue, cursorAtEnd ? undefined : cursorPosition, passedInput);
      },
      [getNewCursorPosition],
    );

    useLayoutEffect(() => {
      const input = inputRef.current;
      if (input?.value) {
        handleInputChange(input.value);
      }
    }, [handleInputChange]);

    const onOwnValueChange = useCallback(
      (newValue: InputOwnValue, input: HTMLInputElement) => {
        let parsedNewValue = newValue;
        if (_.isNil(newValue)) {
          parsedNewValue = '';
        }
        handleInputChange(`${parsedNewValue}`, input);
      },
      [handleInputChange],
    );

    useOnOwnValueChange(inputRef, onOwnValueChange);

    return (
      <TextInput
        {...props}
        id={id}
        ref={mergeRefs([ref, inputRef])}
        type="tel"
        clearable
        apendWith={
          phoneCountry ? (
            <Image
              sx={{ height: '15px', pl: 1 }}
              src={`${FLAG_CDN}/h20/${phoneCountry.toLowerCase()}.png`}
              alt={`${phoneCountry} flag`}
              title={phoneCountry}
            />
          ) : undefined
        }
        error={props.error || (phoneInputError && afterFirstBlur)}
        errorMessage={phoneInputErrorMessage || props.errorMessage}
        onChange={(e) => {
          handleInputChange(e.target.value, e.target);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          setAfterFirstBlur(true);
          if (onBlur) {
            onBlur(e);
          }
        }}
      />
    );
  },
);

PhoneInput.defaultProps = defaultProps;
