import React from 'react';
import { Flex } from 'theme-ui';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Avatar } from 'components/Avatar/Avatar';
import { ParsedRequest } from 'state/requests';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { Tag } from 'components/Tag/Tag';
import { UserSelectableColor } from 'constants/userSelectableColors';

type Props = { person: ParsedRequest['employee'] };

export const RequestPerson = ({ person }: Props): React.ReactElement => {
  const renderFullName = useNameDisplayOrder();
  const { name, avatarUrl, role, tags } = person;

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Avatar
        size={34}
        image={avatarUrl}
        name={name}
        sx={{
          flexShrink: 0,
        }}
      />
      <TextEllipsis
        sx={{ ml: 3, width: 'auto' }}
        title={renderFullName(name.firstName, name.surname)}
      >{`${renderFullName(name.firstName, name.surname)}`}</TextEllipsis>
      <Tag
        sx={{
          ml: 3,
          height: '18px',
          cursor: 'default',
        }}
        key={role.name}
        title={i18n._(
          t({
            id: role.name,
          }),
        )}
        color={UserSelectableColor[role.color]}
        variant="outline"
      >
        {i18n._(
          t({
            id: role.name,
          }),
        )}
      </Tag>
      {tags &&
        tags.map(
          (tag) =>
            tag && (
              <Tag
                sx={{
                  ml: '2px',
                  cursor: 'default',
                }}
                key={tag.name}
                title={i18n._(
                  t({
                    id: tag.name,
                  }),
                )}
                color={UserSelectableColor[tag.color]}
              >
                {i18n._(
                  t({
                    id: tag.name,
                  }),
                )}
              </Tag>
            ),
        )}
    </Flex>
  );
};
