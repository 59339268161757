import React, { useCallback, useState, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { AddEditTag } from '../forms/AddEditTag';
import { addTagAction } from 'api/actions/tags/tagsActions';
import { createEvent } from 'utils/createEvent';
import { AddTagActionProps } from 'api/actions/tags/tagsActions.types';
import { Modal } from 'components/Modal/Modal';
import { delay } from 'utils/delay';
import { useModal } from 'hooks/useModal/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { defaultTagFeaturesSelector } from 'state/organizationSession';

export const AddTagModal = (): React.ReactElement => {
  useLingui();
  const defaultTagFeatures = useRecoilValue(defaultTagFeaturesSelector);
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { mutate } = useMutation(addTagAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: AddTagActionProps): Promise<boolean> => {
      const { error: submitError } = await mutate(body);
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(0);
        addSnackbar({
          message: t({ id: 'team.add_tag.added', message: 'Tag successfully added!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, addSnackbar],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.add_tag">Add tag</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddEditTag
          ref={formRef}
          defaultValues={defaultTagFeatures || undefined}
          onSubmit={onSubmit}
          setLoading={setLoading}
        />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
