import React, { useContext, useEffect, useRef } from 'react';
import { ClientContext } from 'react-fetching-library';

import { NotificationHub } from 'NotificationHub/NotificationHub';
import { fetchNotifications } from 'api/actions/notification/notificationActions';
import { addNotification } from 'NotificationHub/actions';
import { NOTIFICATION_FETCH_INTERVAL } from 'constants/common';

type Props = {
  children?: React.ReactElement[] | React.ReactElement | React.ReactNode | React.ReactNode[];
};

const defaultProps: Partial<Props> = {
  children: undefined,
};

export const NotificationProvider = ({ children }: Props): React.ReactElement => {
  const { query } = useContext(ClientContext);
  const intervalSetRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const getNotifications = async () => {
      const { payload, error } = await query(fetchNotifications());
      if (!error && payload instanceof Array) {
        payload.map((n) => addNotification(n));
      }
    };
    if (!intervalSetRef.current) {
      getNotifications();
      intervalSetRef.current = setInterval(getNotifications, NOTIFICATION_FETCH_INTERVAL);
    }

    return () => {
      if (intervalSetRef.current) {
        clearInterval(intervalSetRef.current);
      }
    };
  }, [query]);
  return (
    <>
      <NotificationHub />
      {children}
    </>
  );
};

NotificationProvider.defaultProps = defaultProps;
