import React from 'react';
import { useRecoilValue } from 'recoil';

import { PATH } from 'constants/routes';
import { ModalRoute } from 'routing/ModalRoute';
import { languageSelector } from 'state/recoilState';
import { AddTeammateModal } from 'layouts/Team/modals/AddTeammate';
import { PrintQrCardsModal } from 'layouts/Team/modals/PrintQrCards';
import { ReInviteTeammatesModal } from 'layouts/Team/modals/ReInviteTeammates';
import { DeactivateTeammatesModal } from 'layouts/Team/modals/DeactivateTeammates';
import { DeleteTeammatesModal } from 'layouts/Team/modals/DeleteTeammates';
import { EditTeammateModal } from 'layouts/Team/modals/EditTeammate';
import { ViewSettingsModal } from 'layouts/Team/modals/ViewSettings';
import { ManageWorkPositionsModal } from 'layouts/Team/modals/ManageWorkPositions';
import { AddWorkPositionModal } from 'layouts/Team/modals/AddWorkPosition';
import { EditWorkPositionModal } from 'layouts/Team/modals/EditWorkPosition';
import { BulkActionsModal } from 'layouts/Team/modals/BulkAction/BulkActions';

export const ModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  return (
    <>
      {/* TEAMMATES */}
      <ModalRoute size="sm" path={PATH.ADD_TEAMMATE_MODAL[language]} fullHeight>
        <AddTeammateModal />
      </ModalRoute>
      <ModalRoute path={PATH.EDIT_TEAMMATE_MODAL__ID[language]} fullHeight>
        <EditTeammateModal />
      </ModalRoute>
      <ModalRoute size="xs" path={PATH.DELETE_TEAMMATES_MODAL[language]}>
        <DeleteTeammatesModal />
      </ModalRoute>
      <ModalRoute size="xs" path={PATH.DEACTIVATE_TEAMMATES_MODAL[language]}>
        <DeactivateTeammatesModal />
      </ModalRoute>
      <ModalRoute size="xs" path={PATH.INVITE_TEAMMATES_MODAL[language]}>
        <ReInviteTeammatesModal isInvite />
      </ModalRoute>
      <ModalRoute size="xs" path={PATH.RE_INVITE_TEAMMATES_MODAL[language]}>
        <ReInviteTeammatesModal />
      </ModalRoute>
      <ModalRoute size="xs" path={PATH.PRINT_QR_CARDS_MODAL[language]}>
        <PrintQrCardsModal />
      </ModalRoute>

      {/* WORK_POSITIONS */}
      <ModalRoute path={PATH.MANAGE_WORK_POSITIONS_MODAL[language]} fullHeight>
        <ManageWorkPositionsModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.ADD_WORK_POSITION_MODAL[language]}>
        <AddWorkPositionModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.EDIT_WORK_POSITION_MODAL__ID[language]}>
        <EditWorkPositionModal />
      </ModalRoute>

      {/* BULK_ACTIONS */}
      <ModalRoute size="sm" path={PATH.BULK_ACTIONS_MODAL[language]}>
        <BulkActionsModal />
      </ModalRoute>

      {/* VIEW_SETTINGS */}
      <ModalRoute size="xs" path={PATH.VIEW_SETTINGS_MODAL[language]} fullHeight>
        <ViewSettingsModal />
      </ModalRoute>
    </>
  );
};
