import React from 'react';
import { Flex } from 'theme-ui';

import { Logo } from 'components/Logo/Logo';
import { THEME_NAVBAR_HEIGHT } from 'styles/theme/base';

import { Menu } from './Menu';
import { SideMenu } from './SideMenu';

type Props = Record<string, unknown>;

export const Navbar = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Flex
    ref={ref}
    sx={{
      bg: 'navbar.background',
      boxShadow: 'navbarTop',
      zIndex: 'navBar',
      position: 'fixed',
      top: 0,
      width: '100%',
      height: THEME_NAVBAR_HEIGHT,
      justifyContent: 'space-between',
    }}
  >
    <Flex
      as="nav"
      sx={{
        boxShadow: ['navbarBottom', null, null, 'none'],
        bg: 'inherit',
        px: [2, null, 3],
        width: ['100%', null, null, 'initial'],
        height: [THEME_NAVBAR_HEIGHT, null, null, 'initial'],
        alignItems: 'center',
        position: ['fixed', null, null, 'initial'],
        justifyContent: ['space-between', null, null, 'initial'],
        bottom: [0, null, null, 'initial'],
      }}
    >
      <Logo clickable fill="skyBlues5" sx={{ mr: [0, null, null, 3] }} width={[70, null, 92]} />
      <Menu />
    </Flex>

    <SideMenu />
  </Flex>
));
