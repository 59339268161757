/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react';
import { Heading, Text, Flex, Image } from 'theme-ui';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';
import { t, Trans } from '@lingui/macro';

import { Button } from 'components/ui/Buttons';
import {
  connectIntegrationsPayrollAction,
  integrationsDisconnectAction,
} from 'api/actions/integrations/integrationActions';
import {
  ConnectDisconnectActionCommonProps,
  ServiceIntegration,
} from 'api/actions/integrations/integrationActions.types';
import { createEvent } from 'utils/createEvent';
import { Tag } from 'components/Tag/Tag';
import { getSrcSet } from 'utils/getSrcSet';
import { disconnectSx, expiredSx } from '../styles/styles';
import { refreshSelectorFamily } from 'state/settings';

type Props = {
  type: ServiceIntegration;
  heading: string;
  description: string;
  isActive: boolean;
  isExpired: boolean;
  image: string;
  button?: {
    connectText: string;
    customOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disconnectText?: string;
    expiredText?: string;
  };
  additionalButton?: {
    text: string;
    to: string;
  };
};

const defaultProps: Partial<Props> = {
  button: undefined,
  additionalButton: undefined,
};

export const IntegrationForm = ({
  type,
  heading,
  description,
  image,
  button,
  additionalButton,
  isActive,
  isExpired,
}: Props): React.ReactElement => {
  useLingui();
  const [connected, setConnected] = useState<boolean>(isActive);
  const [expired, setExpired] = useState<boolean>(isExpired);
  const [dispatchConnectSubmit, setdispatchConnectSubmit] = useState<boolean | null>(null);
  const refreshIntegrations = useRecoilValue(refreshSelectorFamily('INTEGRATIONS'));

  const firstRenderRef = useRef(true);
  const formRef = useRef<HTMLFormElement | null>(null);

  const { register, handleSubmit } = useForm();

  const { mutate: connectPayrollMutate } = useMutation(connectIntegrationsPayrollAction);
  const { mutate: disconnectMutate } = useMutation(integrationsDisconnectAction);

  const dispatchSubmitForm = () => {
    const submitEvent = createEvent('submit');
    formRef.current?.dispatchEvent(submitEvent);
  };

  const handleConnectButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (button?.customOnClick) {
      const { customOnClick } = button;
      customOnClick(e);
    } else {
      setdispatchConnectSubmit(true);
    }
  };

  const handleDisconnectButtonClick = () => {
    setdispatchConnectSubmit(false);
  };

  const handlePayrollSubmit = async (data: ConnectDisconnectActionCommonProps) => {
    const { error } = await connectPayrollMutate(data);
    if (!error) {
      refreshIntegrations();
      setConnected(true);
      setExpired(false);
    }
  };

  const handleDisconnectSubmit = async (data: ConnectDisconnectActionCommonProps) => {
    const { error } = await disconnectMutate(data);
    if (!error) {
      refreshIntegrations();
      setConnected(false);
      setExpired(false);
    }
  };

  useEffect(() => {
    if (!firstRenderRef.current) {
      dispatchSubmitForm();
    }
    firstRenderRef.current = false;
  }, [dispatchConnectSubmit]);

  return (
    <form
      ref={formRef}
      onSubmit={dispatchConnectSubmit ? handleSubmit(handlePayrollSubmit) : handleSubmit(handleDisconnectSubmit)}
    >
      <input
        {...register('integration')}
        type="number"
        defaultValue={type}
        readOnly
        sx={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
      />
      <Flex
        sx={{
          bg: 'whites0',
          flexWrap: 'wrap',
          flexShrink: 0,
          width: '300px',
          minHeight: '180px',
          overflow: 'hidden',
          borderRadius: 'default',
          p: 3,
          border: '2px solid transparent',
          boxShadow: '0 2px 8px rgba(42, 65, 111, 0.04)',
          ...(connected &&
            !expired && {
              position: 'relative',
              boxSizing: 'border-box',
              borderColor: 'greens4',
            }),
          ...(expired && {
            position: 'relative',
            boxSizing: 'border-box',
            borderColor: 'yellows4',
          }),
        }}
      >
        {connected && !expired && (
          <Tag
            title={t({ id: 'enabled', message: 'Enabled' })}
            variant="solid"
            color="greens4"
            sx={{
              position: 'absolute',
              top: 3,
              right: 3,
              fontSize: 0,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: 'greens4',
              bg: 'greens4',
              color: 'rgba(14, 23, 55, 0.6)',
            }}
          >
            <Trans id="enabled" />
          </Tag>
        )}
        {expired && (
          <Tag
            title={t({ id: 'connection_expired', message: 'Connection Expired' })}
            variant="solid"
            color="yellows4"
            sx={{
              position: 'absolute',
              top: 3,
              right: 3,
              fontSize: 0,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: 'yellows4',
              bg: 'yellows4',
              color: 'rgba(14, 23, 55, 0.6)',
            }}
          >
            <Trans id="connection_expired" />
          </Tag>
        )}
        <Flex sx={{ flexDirection: 'column' }}>
          <Image
            sx={{ alignSelf: 'flex-start' }}
            src={image}
            srcSet={getSrcSet(image)}
            alt={ServiceIntegration[type]}
          />

          <Heading variant="heading4" sx={{ mb: [0, 0, 0] }}>
            {heading}
          </Heading>
          <Text sx={{ fontSize: 2 }}>{description}</Text>
        </Flex>
        <Flex sx={{ mt: 'auto', flexBasis: '100%' }}>
          {button && !connected && !expired && (
            <Button variant="success" size="sm" shape="rounded" onClick={handleConnectButtonClick}>
              {button.connectText}
            </Button>
          )}
          {button && button.disconnectText && connected && !expired && (
            <Button variant="naked" onClick={handleDisconnectButtonClick} size="sm" sx={disconnectSx}>
              {button.disconnectText}
            </Button>
          )}
          {button && button.expiredText && !connected && expired && (
            <>
              <Button variant="naked" onClick={handleConnectButtonClick} size="sm" sx={expiredSx}>
                {button.expiredText}
              </Button>
              {button.disconnectText && (
                <Button variant="naked" onClick={handleDisconnectButtonClick} size="sm" sx={disconnectSx}>
                  {button.disconnectText}
                </Button>
              )}
            </>
          )}
          {button && button.expiredText && connected && expired && (
            <>
              <Button variant="naked" onClick={handleConnectButtonClick} size="sm" sx={expiredSx}>
                {button.expiredText}
              </Button>
              {button.disconnectText && (
                <Button variant="naked" onClick={handleDisconnectButtonClick} size="sm" sx={disconnectSx}>
                  {button.disconnectText}
                </Button>
              )}
            </>
          )}
          {additionalButton && (
            <Button variant="grey" size="sm" shape="rounded" ml={button ? 1 : 0}>
              <a href={additionalButton.to} target="_blank" rel="noreferrer">
                {additionalButton.text}
              </a>
            </Button>
          )}
        </Flex>
      </Flex>
    </form>
  );
};

IntegrationForm.defaultProps = defaultProps;
