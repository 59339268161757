import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'theme-ui';
import dayjs from 'dayjs';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import _ from 'lodash';
import { MaskedRange } from 'imask';
import { useLingui } from '@lingui/react';

import { MaskedTextInput, MaskedTextInputProps } from '../MaskedTextInput';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from 'constants/common';
import { createEvent } from 'utils/createEvent';
import { withPopper } from '../PopperProvider/withPopper';
import { delay } from 'utils/delay';
import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';
import { silentSetValue } from 'utils/silentSetValue';
import { dateTime } from 'utils/dateTime';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useFieldErrorDispatcher } from 'hooks/useFieldErrorDipatcher/useFieldErrorDispatcher';

import { Calendar, getSelectedDates, getSelectedTimes } from './Calendar/Calendar';
import { getDateStringsFromMaskedValue, getDatesWithTime, getMaskedValue, parsedHiddenInputValue } from './helpers';
import {
  DAY_PLACEHOLDER,
  DEFAULT_DATE_FORMAT,
  MONTH_PLACEHOLDER,
  RANGE_DIVIDER,
  YEAR_PLACEHOLDER,
} from './Calendar/constants';
import { CalendarProps } from './Calendar/types';

const FlexWithPopper = withPopper(Flex);

type ErrorDetails = [boolean | undefined, string | undefined];

type Props = Omit<CalendarProps, 'selectedDateTimes' | 'onTimeChange' | 'onChange'> &
  Omit<MaskedTextInputProps, 'placeholder' | 'max' | 'min'> & {
    onValidError?: () => void;
    onClearError?: () => void;
    placeholder?: string;
    isStatic?: boolean;
  };

const defaultProps: Partial<Props> = {
  isStatic: false,
  placeholder: undefined,
  onValidError: undefined,
  onClearError: undefined,
};

export const DatePicker = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      isStatic = false,
      placeholder,
      range,
      maxDate = DEFAULT_MAX_DATE,
      minDate = DEFAULT_MIN_DATE,
      showStartTime,
      showEndTime,
      showQuickSelect,
      error,
      errorMessage,
      excludedDates,
      onValidError,
      onClearError,
      onChange,
      onBlur,
      ...props
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const [afterFirstBlur, setAfterFirstBlur] = useState(false);
    const [datePickerErrorMessage, setDatePickerErrorMessage] = useState<string | undefined>(undefined);

    const withTimeRef = useRef(!!showStartTime || !!showEndTime);
    const [showCalendar, setShowCalendar] = useState(false);

    const [selectedDateTimes, setSelectedDateTimes] = useState<number[]>([]);
    const [selectedTimes, setSelectedTimes] = useState<number[]>([]);

    const selectedTimesRef = useRef<number[]>([]);

    const maskedRef = useRef<HTMLInputElement>(null);
    const hiddenRef = useRef<HTMLInputElement>(null);

    const maskPlaceholder = useMemo(
      () => (range ? `${DEFAULT_DATE_FORMAT}${RANGE_DIVIDER}${DEFAULT_DATE_FORMAT}` : DEFAULT_DATE_FORMAT),
      [range],
    );
    const pattern = useMemo(() => maskPlaceholder.split('-').join('-`'), [maskPlaceholder]);

    const hasFocusRef = useRef<boolean>(false);

    const [computedError, computedErrorMessage] = useMemo(() => {
      const formError: ErrorDetails = [error, errorMessage];
      if (!datePickerErrorMessage) return formError;

      const maskedValue = maskedRef.current?.value;

      // TODO: find a better way for checking if maskedInput is empty
      const parsedMaskPlaceholder = maskPlaceholder
        .split(RANGE_DIVIDER)
        .map((v) => `20${v.slice(2)}`)
        .join(RANGE_DIVIDER);

      const maskedIsEmpty =
        !maskedValue || maskedValue === '' || maskedValue === maskPlaceholder || maskedValue === parsedMaskPlaceholder;

      if (maskedIsEmpty) return formError;

      const datePickerError: ErrorDetails = [true, datePickerErrorMessage];

      return datePickerError;
    }, [error, errorMessage, datePickerErrorMessage, maskPlaceholder]);

    const dispatchBlurEvent = () => {
      const blurEvent = createEvent('focusout');
      if (hiddenRef.current) hiddenRef.current.dispatchEvent(blurEvent);
    };

    const handleMaskedInputFocus = useCallback(() => {
      hasFocusRef.current = true;
    }, []);

    const handleHiddenInputFocus = useCallback(() => {
      maskedRef.current?.focus();

      if (!showCalendar) {
        setShowCalendar(true);
      }
    }, [showCalendar]);

    const onClick = useCallback(() => {
      if (!showCalendar && !props.disabled) {
        setShowCalendar(true);
        handleHiddenInputFocus();
      }
    }, [showCalendar, props.disabled, handleHiddenInputFocus]);

    const clearDatePickerError = useCallback(() => {
      setDatePickerErrorMessage(undefined);
    }, []);

    const setDatePickerErrorCallback = useCallback(({ id, message }: MessageDescriptor, appendWith?: string) => {
      setDatePickerErrorMessage(
        `${t({
          id,
          message,
        })}${appendWith || ''}`,
      );
    }, []);

    const validate = useCallback(
      async (dateTimes: number[]) => {
        await delay(100);

        const minDateExceeded = !!dateTimes.find((unix) => unix < minDate.unix());

        if (minDateExceeded) {
          setDatePickerErrorCallback({
            message: `Min: ${minDate.format(DEFAULT_DATE_FORMAT)}`,
          });
          return false;
        }

        const maxDateExceeded = !!dateTimes.find((unix) => unix > maxDate.unix());

        if (maxDateExceeded) {
          setDatePickerErrorCallback({
            message: `Max: ${maxDate.format(DEFAULT_DATE_FORMAT)}`,
          });
          return false;
        }

        const selectedStartTime = selectedTimesRef.current[0];

        if (showStartTime && (_.isNil(selectedStartTime) || _.isNaN(selectedStartTime))) {
          setDatePickerErrorCallback({
            id: 'date_picker.time_required',
            message: 'Time required',
          });
          return false;
        }

        const selectedEndTime = selectedTimesRef.current[1];
        if (showEndTime && (_.isNil(selectedEndTime) || _.isNaN(selectedEndTime))) {
          setDatePickerErrorCallback({
            id: 'date_picker.time_required',
            message: 'Time required',
          });
          return false;
        }

        const isSameDay = dateTime(dateTimes[0]).isSame(dateTime(dateTimes[1]), 'day');
        const isFirstDateBigger = dateTimes[0] > dateTimes[1];

        if (isSameDay && isFirstDateBigger && range) {
          setDatePickerErrorCallback({
            id: 'date_picker.invalid_time',
            message: 'Invalid start time',
          });
          return false;
        }

        if ((range && !dateTimes[1]) || (!range && !dateTimes[0])) {
          setDatePickerErrorCallback({
            id: 'date_picker.invalid_date',
            message: `Invalid date`,
          });
          return false;
        }

        clearDatePickerError();
        return true;
      },
      [clearDatePickerError, maxDate, minDate, range, setDatePickerErrorCallback, showStartTime, showEndTime],
    );

    const validateMaskedInputValue = useCallback(
      (unixDates: number[]) => {
        if (!unixDates.length || (range && unixDates.length !== 2)) {
          setDatePickerErrorCallback({
            id: 'date_picker.invalid_date',
            message: 'Invalid date',
          });
          return false;
        }

        const excludedDatesStartDay = getSelectedDates(excludedDates);
        const excludedDateMatch = excludedDatesStartDay?.find((excludedDate) => unixDates.includes(excludedDate));

        if (excludedDateMatch) {
          setDatePickerErrorCallback(
            {
              id: 'date_picker.excluded_date',
              message: `excluded date: `,
            },
            `${dateTime(excludedDateMatch).format(DEFAULT_DATE_FORMAT)}`,
          );
          return false;
        }

        const minDateExceeded = !!unixDates.find((unix) => unix < minDate.unix());

        if (minDateExceeded) {
          setDatePickerErrorCallback({
            message: `Min: ${minDate.format(DEFAULT_DATE_FORMAT)}`,
          });
          return false;
        }

        const maxDateExceeded = !!unixDates.find((unix) => unix > maxDate.unix());

        if (maxDateExceeded) {
          setDatePickerErrorCallback({
            message: `Max: ${maxDate.format(DEFAULT_DATE_FORMAT)}`,
          });
          return false;
        }
        return true;
      },
      [excludedDates, maxDate, minDate, range, setDatePickerErrorCallback],
    );

    const setMaskedValue = useCallback(
      async (unixArray: number[]) => {
        const maskedValue = getMaskedValue(unixArray, isStatic, maskedRef?.current?.value);
        await delay(0);
        silentSetValue(maskedRef, maskedValue);
      },
      [isStatic],
    );

    const onOutsideClick = useCallback(async () => {
      await delay(withTimeRef.current ? 300 : 50);
      setShowCalendar(false);

      const hiddenValue = hiddenRef.current?.value;
      const unixDates = parsedHiddenInputValue(hiddenValue);

      const isFull = (range && unixDates.length === 2) || (!range && unixDates.length);

      if (isFull && !datePickerErrorMessage) {
        setMaskedValue(unixDates);
      }

      if (hasFocusRef.current || showCalendar) {
        setAfterFirstBlur(true);
        dispatchBlurEvent();
      }
    }, [showCalendar, range, datePickerErrorMessage, setMaskedValue]);

    const onCalendarChange = useCallback(
      (unixDates: number[]) => {
        let newDateTimes = unixDates;
        const isSameDay = unixDates.length !== 2 ? false : dateTime(unixDates[0]).isSame(dateTime(unixDates[1]), 'day');

        if (!isSameDay) {
          newDateTimes = unixDates.sort((a, b) => a - b);
        }

        if (_.isEqual(newDateTimes, selectedDateTimes)) return;

        setNativeValue(hiddenRef, newDateTimes);
      },
      [selectedDateTimes],
    );

    const onTimeChange = useCallback(
      (times: number[]) => {
        setSelectedTimes(times);
        selectedTimesRef.current = times;

        if (withTimeRef.current) {
          validate(selectedDateTimes);
        }

        const datesWithTimes = getDatesWithTime(selectedDateTimes, times);
        setNativeValue(hiddenRef, datesWithTimes);
      },
      [selectedDateTimes, validate],
    );

    const handleMaskedInputChange = useCallback(
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        if (isStatic) return;

        const { value } = e.target;

        // TODO: find a better way for checking if maskedInput is empty
        const parsedMaskPlaceholder = maskPlaceholder
          .split(RANGE_DIVIDER)
          .map((v) => `20${v.slice(2)}`)
          .join(RANGE_DIVIDER);

        if (value === '' || value === maskPlaceholder || value === parsedMaskPlaceholder) {
          clearDatePickerError();
          setSelectedDateTimes([]);
          setNativeValue(hiddenRef, '');
          return;
        }

        const isFullyFilled = ![YEAR_PLACEHOLDER, MONTH_PLACEHOLDER, DAY_PLACEHOLDER].some((v) => value.includes(v));

        if (!isFullyFilled) return;
        const unixDates: number[] = [];

        const datesFromValue = getDateStringsFromMaskedValue(value);

        datesFromValue.forEach((date) => {
          const dateTz = dayjs(date, DEFAULT_DATE_FORMAT, true).utc(true).startOf('day');

          if (dateTz.isValid()) unixDates.push(dateTz.unix());
        });

        const isValid = validateMaskedInputValue(unixDates);

        if (!isValid) {
          setSelectedDateTimes([]);
          return;
        }

        unixDates.sort((a, b) => a - b);

        const datesWithTimes = getDatesWithTime(unixDates, selectedTimes);
        setNativeValue(hiddenRef, datesWithTimes);
      }, 200),

      [isStatic, range, selectedTimes],
    );

    const updateView = useCallback(
      (value: string, ignoreTime?: boolean) => {
        if (!value) return;

        const unixDates = parsedHiddenInputValue(value);

        setMaskedValue(unixDates);
        setSelectedDateTimes(unixDates);

        if (!ignoreTime) {
          const newTimes = getSelectedTimes(unixDates).filter((time) => !_.isNaN(time));

          setSelectedTimes((prevTimes) => {
            let newSelectedTimes = newTimes;
            if (newTimes.length !== 2 || !_.isNil(prevTimes[1])) {
              newSelectedTimes = [newTimes[0], prevTimes[1]];
            }
            selectedTimesRef.current = newSelectedTimes;
            return newSelectedTimes;
          });
        }

        validate(unixDates);
      },
      [setMaskedValue, validate],
    );

    const handleHiddenInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        updateView(value, true);

        if (onChange) {
          onChange(e);
        }
      },
      [onChange, updateView],
    );

    const updateViewRef = useCallbackRef(updateView);

    // INITIALIZE DATE_PICKER
    useEffect(() => {
      const hiddenInputValue = hiddenRef?.current?.value;
      if (hiddenInputValue) {
        updateViewRef.current(hiddenInputValue);
      }
    }, [updateViewRef]);

    const onOwnValueChange = useCallback(
      (newValue: InputOwnValue) => {
        let parsedNewValue = newValue;
        if (_.isNil(newValue)) {
          parsedNewValue = '';
        }
        if (_.isArray(newValue)) {
          parsedNewValue = newValue.join(',');
        }
        updateView(`${parsedNewValue}`);
      },
      [updateView],
    );

    useOnOwnValueChange(hiddenRef, onOwnValueChange);

    useFieldErrorDispatcher(!!computedError, onValidError, onClearError);

    // TODO: check if it is possible to create an interactive validators for inputs
    const maskedInputProps: Partial<MaskedTextInputProps> = {
      ...(!isStatic && {
        returnMaskedValue: true,
        autoComplete: 'off',
        mask: Date,
        showMask: true,
        pattern,
        overwrite: true,
        format: (dates: dayjs.Dayjs[]) => dates,
        parse: (str: string) => str,
        blocks: {
          YYYY: {
            mask: MaskedRange,
            from: minDate.year(),
            // TODO: clearing not working in this implementation
            to: maxDate.year(),
            placeholderChar: YEAR_PLACEHOLDER,
          },
          MM: {
            mask: MaskedRange,
            from: 1,
            to: 12,
            placeholderChar: MONTH_PLACEHOLDER,
          },
          DD: {
            mask: MaskedRange,
            from: 1,
            to: 31,
            placeholderChar: DAY_PLACEHOLDER,
          },
        },
      }),
    };

    return (
      <>
        <input
          name={name}
          onBlur={onBlur}
          onFocus={handleHiddenInputFocus}
          onChange={handleHiddenInputChange}
          ref={mergeRefs([ref, hiddenRef])}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
          readOnly
        />
        <FlexWithPopper
          sx={{ flexDirection: 'column', ...(props.sx && props.sx) }}
          popperProps={{
            popperGuardValue: true,
            trigger: 'manual',
            placement: 'bottom-start',
            visible: showCalendar,
            popperMargin: 0,
            onOutsideClick,
            withPortal: true,
            popperContainerSx: {
              background: 'white',
              width: '100%',
              maxWidth: '324px',
              boxShadow: 'dropShadow.levelOne',
              borderRadius: 'sm',
            },
            content: (
              <Calendar
                onChange={onCalendarChange}
                onTimeChange={onTimeChange}
                selectedDateTimes={selectedDateTimes}
                initialSelectedTimes={selectedTimes}
                range={range}
                maxDate={maxDate}
                minDate={minDate}
                showEndTime={showEndTime}
                showStartTime={showStartTime}
                showQuickSelect={showQuickSelect}
                excludedDates={excludedDates}
              />
            ),
          }}
          onClick={onClick}
        >
          <MaskedTextInput
            {...props}
            {...maskedInputProps}
            readOnly={isStatic}
            onChange={handleMaskedInputChange}
            onFocus={handleMaskedInputFocus}
            id={`${name}_mask`}
            error={error || (computedError && afterFirstBlur)}
            errorMessage={computedErrorMessage}
            placeholder={placeholder || maskPlaceholder}
            ref={maskedRef}
          />
        </FlexWithPopper>
      </>
    );
  },
);

DatePicker.defaultProps = defaultProps;
