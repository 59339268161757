import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { AddEditWebhookForm } from '../forms/AddEditWebhookForm';
import { useModal } from 'hooks/useModal/useModal';
import { addWebhookAction } from 'api/actions/webhook/webhookActions';
import { Webhook, AddWebhookActionProps } from 'api/actions/webhook/webhooksActions.types';
import { createEvent } from 'utils/createEvent';
import { refreshSelectorFamily } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';

export const AddWebhookModal = (): React.ReactElement => {
  useLingui();
  const { handleClose } = useModal();
  const { mutate } = useMutation(addWebhookAction);
  const refreshPayload = useRecoilValue(refreshSelectorFamily('WEBHOOKS'));

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: AddWebhookActionProps | Webhook): Promise<boolean> => {
      const { error: submitError } = await mutate(data);

      if (!submitError) {
        if (handleClose) {
          addSnackbar({
            message: t({
              id: 'settings.webhook.add_webhook_success',
              message: 'Added new webhook',
            }),
            variant: 'success',
          });
          refreshPayload();
          handleClose();
        }
      }

      if (submitError) {
        addSnackbar({
          message: t({
            id: 'settings.webhook.add_webhook_danger',
            message: "Couldn't add new webhook",
          }),
          variant: 'danger',
        });
      }
      setLoading(false);
      return true;
    },
    [handleClose, mutate, refreshPayload],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.webhook.add_webhook">Add Webhook</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddEditWebhookForm ref={formRef} onSubmit={onSubmit} setLoading={setLoading} />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
