import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { dateTime } from 'utils/dateTime';
import { UUID } from 'utils/UUID';

import { API_ENDPOINTS } from './api';

dayjs.extend(timezone);
dayjs.extend(utc);

export const ACCESS_TOKEN_KEY = 'accessToken';
export const TAB_ID = UUID();
export const REACT_APP_HOSTNAME = { inewi: 'inewi.pl', tracktime24: 'tracktime24.com' };
export const FETCH_TIMEOUT_THRESHOLD = parseInt(process.env.REACT_APP_FETCH_TIMEOUT_THRESHOLD || '500', 10);
export const FETCH_TIMEOUT_REQUESTS_MAX = parseInt(process.env.REACT_APP_FETCH_TIMEOUT_REQUESTS_MAX || '5', 10);
export const FETCH_TIMEOUT_EXCEEDED_RESPONSE_STATUS = 512;
export const REAL_TIMEZONE = dayjs.tz.guess();
export const REAL_LOCALE = Intl.DateTimeFormat().resolvedOptions().locale || '';
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

let { hostname } = window.location;

if (process.env.NODE_ENV !== 'production') {
  hostname = process.env.HOSTNAME || 'inewi.pl';
}

export const HOSTNAME = hostname;
export const APP_NAME = hostname === 'inewi.pl' ? 'inEwi' : 'TrackTime24';

export const APPLE_CLIENT_ID =
  (hostname === 'inewi.pl'
    ? process.env.REACT_APP_APPLE_CLIENT_ID_INEWI
    : process.env.REACT_APP_APPLE_CLIENT_ID_GLOBAL) || '';
export const GOOGLE_CLIENT_ID =
  (hostname === 'inewi.pl'
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID_INEWI
    : process.env.REACT_APP_GOOGLE_CLIENT_ID_GLOBAL) || '';

// export const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const DEFAULT_TIMEZONE = REAL_TIMEZONE;
export const DEFAULT_MIN_DATE = dateTime('01/01/2015');
export const DEFAULT_MAX_DATE = dateTime().startOf('day').add(10, 'year').endOf('day');

export const NOTIFICATION_FETCH_INTERVAL = 60000;

export const TRACK_TIME_HELP = 'https://help.tracktime24.com';
export const INEWI_HELP = 'https://pomoc.inewi.pl';

export const APPLE_LINK_URL = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.appleLink}`;
export const APPLE_LOGIN_URL = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.appleLogin}`;

export const APP_STORE_DOWNLOAD_APP_LINK =
  hostname === 'inewi.pl'
    ? 'https://apps.apple.com/pl/app/inewi-grafik-pracy/id1342633929'
    : 'https://apps.apple.com/us/app/tracktime24-staff-scheduling/id1376435052';

export const APP_STORE_DOWNLOAD_RCP_APP_LINK =
  hostname === 'inewi.pl'
    ? 'https://apps.apple.com/pl/app/inewi-rcp/id1198108254'
    : 'https://apps.apple.com/us/app/tracktime24-timeclock/id1290678274';

export const GOOGLE_PLAY_DOWNLOAD_APP_LINK =
  hostname === 'inewi.pl'
    ? 'https://play.google.com/store/apps/details?id=com.inewi.inewiemplo'
    : 'https://play.google.com/store/apps/details?id=com.tracktime24.employee';

export const GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK =
  hostname === 'inewi.pl'
    ? 'https://play.google.com/store/apps/details?id=com.inewi.terminal'
    : 'https://play.google.com/store/apps/details?id=com.tracktime24.timetracker';

export const FLAG_CDN = 'https://flagcdn.com';

// this is used to validate roles names when adding new ones, array includes all default roles names with all the translations
// update whet new default role or language added
export const DEFAULT_ROLES_NAMES = [
  // en
  'Administrator',
  'Employee',
  'Manager',
  'Supervisor',
  // pl
  'Pracownik',
  'Menadżer',
  'Przełożony',
];

export const DEFAULT_WORK_STATUSES_NAMES = [
  // en
  'Break',
  'Break Unpaid',
  // pl
  'Przerwa',
  'Przerwa bezpłatna',
];

export const DEFAULT_WORK_STATUSES_IDS = {
  ENTER: '1840417B-98B8-483F-9C60-92EACAA30075',
  EXIT: '421DB62E-4DE7-450E-8841-095F197A0148',
  PAID_BREAK: '511DB62E-4DE7-450E-8841-095F197A0146',
  UNPAID_BREAK: '721DB62E-4DE7-450E-8841-095F197A0147',
};

export const MAIN_STATUSES_IDS_ARRAY = [DEFAULT_WORK_STATUSES_IDS.ENTER, DEFAULT_WORK_STATUSES_IDS.EXIT];
