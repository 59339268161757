import { atom, selector } from 'recoil';

import { GetSettingsResponse } from 'api/actions/timeclock/timeclockActions.types';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

type Props = GetSettingsResponse;

export const settingsAtom = atom<Props | null>({
  key: 'kiosk__settings',
  default: null,
});

type mutableSelectorProps = Pick<Props, 'summaryTimeSeconds' | 'sleepModeTimeOutMinutes' | 'isSleepModeEnabled'> | null;

export const mutableSettingsSelector = selector<mutableSelectorProps>({
  key: 'kiosk__mutableSettingsSelector',
  get: ({ get }) => {
    const kioskSettings = get(settingsAtom);

    if (kioskSettings) {
      const { summaryTimeSeconds, sleepModeTimeOutMinutes, isSleepModeEnabled } = kioskSettings;

      return {
        summaryTimeSeconds,
        sleepModeTimeOutMinutes,
        isSleepModeEnabled,
      };
    }

    return null;
  },
  set: ({ get, set }, newSettings) => {
    const kioskSettings = get(settingsAtom);

    if (kioskSettings) {
      const { sleepModeTimeOutMinutes, isSleepModeEnabled, summaryTimeSeconds } = kioskSettings;
      const currentSettings = {
        sleepModeTimeOutMinutes,
        isSleepModeEnabled,
        summaryTimeSeconds,
      };

      if (newSettings !== currentSettings && !isRecoilDefaultValue(newSettings)) {
        set(settingsAtom, {
          ...kioskSettings,
          ...newSettings,
        });
      }
    }
  },
});
