import { UserSelectableColor } from 'constants/userSelectableColors';

enum NormalPayrollPeriod {
  Months1 = 1,
  Weeks1 = 7,
  Weeks2 = 8,
}

export type FetchOrganizationSessionResponse = {
  details: OrganizationDetails;
  employees: Employee[];
  tags: Tag[];
  roles: Role[];
  timeTrackingSettings: TimeTrackingSettings;
  workPositions: WorkPosition[];
  timeEventTypes: TimeEventType[];
  customRequestTypes: RequestType[];
  customRequestDefaultLimits: RequestLimit[];
  timeOffTypes: TimeOffType[];
  timeOffDefaultLimits: TimeOffLimit[];
  timeOffApprovalSettings: ApprovalSettings;
  timeTrackingApprovalSettings: ApprovalSettings;
  businessTripsApprovalSettings: ApprovalSettings;
  customRequestsApprovalSettings: ApprovalSettings;
  startingWeekDay: DayOfWeek;
  taxId: string;
  defaultTagFeatures: DefaultTagFeatures;
  deprecated: DeprecatedFeatures;
  payrollPeriod: NormalPayrollPeriod;
  displayCurrency: string; // format: 'USD' | 'PLN' | 'AMD'...
};

type DefaultTagFeatures = {
  features: Pick<Feature, 'type' | 'value'>[];
  visibilityLevel: VisibilityLevels;
};

export type Feature = {
  type: FeaturesTypes;
  inheritFromTag: boolean;
  inheritedValue: boolean;
  value: boolean;
};

export type TimeTrackingSettings = {
  isLocationRequired: boolean;
  isFaceRecognitionRequired: boolean;
  isPhotoRecognitionRequired: boolean;
  showPaidBreak: boolean;
  showUnpaidBreak: boolean;
  allowTimeRounding: boolean;
};

export type ApprovalSettings = {
  twoStepApprovalFlow: boolean;
  firstStepRoleId: string;
  secondStepRoleId: string;
};

export type OrganizationDetails = {
  name: string;
  avatarUrl: string;
  createDateUnix: number;
};

export type RequestLimit = {
  id: string;
  days?: number;
  period: LimitPeriods;
  customRequestTypeId: string;
};

export type TimeOffLimit = {
  id: string;
  days?: number;
  carryOverUnusedLimit: boolean;
  timeOffTypeId: string;
};

export type TimeOffType = {
  id: string;
  name: string;
  abbreviation: string;
  isPaid: boolean;
  isLimitable: boolean;
  isActive: boolean;
};

export type RequestType = {
  id: string;
  name: string;
  abbreviation: string;
  isLimitable: boolean;
  isActive: boolean;
};

export type TimeEventType = {
  id: string;
  name: string;
  isEnd: boolean;
  isDefault: boolean;
  isDeleted: boolean;
  endDateUtc?: number;
  isDateBound?: boolean; // is endDateUtc final - if not, it needs to be changed to local time
  pauseWorkTime: boolean;
  limitMaximumDuration: boolean;
  maximumTimeAllowedSeconds?: number;
  requiresMinimumWorkTime?: boolean;
  minimumWorkTimeSeconds?: number;
};

export type PersonName = {
  firstName: string;
  surname: string;
};

export type Tag = {
  id: string;
  name: string;
  color: UserSelectableColor;
  additionalInfo?: string;
};

export type Role = {
  id: string;
  name: string;
  color: UserSelectableColor;
  description: string;
  canApproveRequests: boolean;
};

export type WorkPosition = {
  id: string;
  name: string;
  isActive: boolean;
};

export type Employee = {
  id: string;
  name: PersonName;
  email: string;
  phoneNumber: string;
  tagsIds: Tag['id'][];
  roleId: Role['id'];
  defaultWorkPositionId?: string;
  avatarUrl?: string;
  isActive: boolean;
  isHidden: boolean;
  invitationState?: InvitationStates;
  customEmployeeId?: string;
  note?: string;
};

type DeprecatedFeatures = {
  workStatusMinTime: boolean;
};

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
}

export enum FeaturesTypes {
  clockInsMobileApp = 0, // AddEventsByWorkerApp
  clockInsMobileTimeClock = 1, // AddEventsByMobileTimeClock
  clockInsKioskMode = 2, // AddEventsByKiosk
  clockInsWebApp = 3, // AddEventsByVrcp
  showFullTeammatesData = 4, // ShowAllTeammatesInfo
  turnOffLocationLimits = 5, // DisableLocationLimitation
  limitDataVisibility = 7, // LimitDataVisibility // this is not a mistake, for backend compatibility
}

export enum VisibilityLevels {
  WithoutRestrictions = 0,
  OneMonth = 1,
  TwoMonths = 2,
  ThreeMonths = 3,
  SixMonths = 6,
  Year = 12,
}

export enum LimitPeriods {
  Weekly,
  Monthly,
  Annually,
}

export const periodsMax: Record<number, number> = {
  [LimitPeriods.Weekly]: 7,
  [LimitPeriods.Monthly]: 31,
  [LimitPeriods.Annually]: 366,
};

export enum OrganizationLocations {
  'pl' = 0,
  'us',
  'gb',
  'es',
  'de',
  'fr',
}

export enum InvitationStates {
  PENDING = 1,
  EXPIRED = 2,
}

export enum DefaultRole {
  Admin = 1,
  Manager = 2,
  Supervisor = 3,
  Employee = 4,
}

export enum DefaultWorkStatuses {
  Break = 1,
  BreakUnpaid = 2,
}

// SickLeave i ZusSickLeave powinny mieć ten sam klucz tłumaczenia
export enum TimeOffTypes {
  // Urlop wypoczynkowy
  AnnualLeave = 'b1241962-d9b4-4d62-a902-d6f6f9dd00f7',
  // Urlop wypoczynkowy na żądanie
  LeaveOnRequest = '426a12a1-10d4-4d81-baf4-52510f849d24',
  // Urlop macierzyński/tacierzyński (Um)
  MaternityLeave = 'abb6f8d0-1710-4dc5-ba48-3e6a43d44a35',
  // Urlop wychowawczy
  ParentalLeave = '0c944fdb-4986-4f67-a5bb-bd416e81d34b',
  // Urlop okolicznościowy
  OccasionalHolidays = '853e538b-c81d-4c5f-8b43-6e0458e409ff',
  // Urlop chorobowy
  SickLeave = '9db2b5ee-8bcb-43e6-82be-dd680bc51973',
  // Urlop bezpłatny
  UnpaidLeave = '274f3c6b-7b66-4bbe-83b5-923fcdfd114d',
  // Świadczenie rehabilitacyjne
  RehabilitationBenefit = '6eb1220f-18e0-47e9-9e96-2a4b5c88d2bc',
  // Opieka nad dzieckiem
  ChildCare = 'f3622805-5e09-49c4-9af6-233763afccd6',
  // Świadek w sądzie
  CourtWitness = '830a2edf-e673-4b45-ae12-00c6b8a50ff3',
  // Oddanie krwi
  DonateBlood = '3BC216C2-3E56-4E50-BBA3-4BDC57F40C36',
  // Wolne z tytoło 5 dniowego tygodnia pracy
  W5 = '856015ee-4f25-42f5-8e92-85e128051800',
  // Wolne za nadgodziny
  OvertimeDayOff = '68463ffd-ef3a-4424-b8a0-b0accde10fc9',
  // Wolne za święto
  HolidayDayOff = '07908b7c-eae3-473e-89c0-eefac9dbd754',
  // Urlop chorobowy ZUS
  ZusSickLeave = 'a3b5fa76-91e6-4553-8082-b0accde10fc9',
  // Zwolnienie lekarskie - opieka (L4+Op)
  ZusSickLeaveForCare = '9cc5fa76-91e6-4553-8082-2a4b5c88d2bc',
  // Wypłata za nadgodziny
  OvertimePayment = '496c593e-4bb7-48a1-a010-b1268e5e68a1',

  // urlopy zagraniczne

  // Urlop bezpłatny
  UnpaidEn = '33908b7c-eae3-473e-89c0-eefac9dbd754',
  // Urlop płatny
  PaidEn = '12908b7c-eae3-473e-89c0-eefac9dbd754',
  // Urlop chorobowy
  SickEn = '16908b7c-eae3-473e-89c0-eefac9dbd754',
  // Urlop
  VacationEn = '44908b7c-eae3-473e-89c0-eefac9dbd754',
  // Zwolnienie lekarskie - opieka (L4+Op)
  SickLeaveForCare = 'c47b3f37-7077-49ee-aa36-7957e526d001',
  // Urlop ojcowski
  PaternityLeave = '7fb5fa76-91e6-4553-8082-4ee52a14296e',
}

export enum CustomRequestTypes {
  // Kwarantanna z L4 (K+L4)
  QuarantineWithSickLeave = 'e3e1dfc3-817e-4268-babc-f9507526a07c',
  // Kwarantanna z pracą zdalną (K+PZ)
  QuarantineWithRemoteWork = 'c0ff2c9f-7779-478c-8acb-d64fec760755',
  // Praca zdalna (PZ)
  RemoteWork = 'e8cf78e0-b9cd-4c99-aae0-4a2bb88c096c',
  // Wniosek dot. pracy w godzinach nadliczbowych
  WorkInOvertime = '7be44a17-102d-4e5f-82ec-07d0a40842b5',
  // Wniosek o wypłatę nadgodzin (Dn)
  OvertimePayment = '9f546b87-06ea-4577-a3c7-c3cac05cd625',
  // Wolne wg grafiku
  LeaveAsSchedule = '719ce20c-f37c-4595-8657-b6700fe73a35',
}
