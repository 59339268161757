import { Flex, FlexProps, Text, ThemeUIStyleObject } from 'theme-ui';
import React from 'react';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';

type Props = {
  label: string;
} & Pick<FlexProps, 'sx' | 'onClick'>;

const containerSx: ThemeUIStyleObject = {
  gap: '0.75rem',
  p: '0.75rem',
  alignItems: 'center',
  alignSelf: 'flex-start',
  cursor: 'pointer',
  borderRadius: 'sm',
  '&:hover': {
    bg: 'alphas.darker1',
  },
};

export const ShowFieldsButton = ({ onClick, label, sx }: Props): React.ReactElement => (
  <Flex onClick={onClick} sx={{ ...containerSx, ...(sx && sx) }}>
    <Button sx={{ p: 0 }} size="sm" shape="rounded" variant="grey">
      <Icon type="plus" />
    </Button>
    <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>{label}</Text>
  </Flex>
);
