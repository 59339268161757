import React, { useMemo, useRef } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import _ from 'lodash';

import { languageSelector } from 'state/recoilState';
import { PATH, TO } from 'constants/routes';
import { Main, FormCard } from 'layouts/Authentication';
import { APP_NAME } from 'constants/common';

import { EmployerStep1 } from './EmployerStep1';
import { EmployerStep2 } from './EmployerStep2';
import { EmployerStep3 } from './EmployerStep3';

export const Employer = (): React.ReactElement => {
  const { pathname, state } = useLocation();
  const language = useRecoilValue(languageSelector);

  const formRef = useRef<HTMLFormElement | null>(null);

  const currentStep = useMemo(() => {
    const partUrl = `${pathname.split('/').pop()}`;
    return parseInt(_.isNaN(parseInt(partUrl, 10)) ? '0' : partUrl, 10);
  }, [pathname]);

  const toObjectGenerator = (to: string) => ({
    pathname: to,
    state: {
      signUp: {
        inProgress: true,
      },
    },
  });

  if (!state?.signUp?.inProgress) {
    return <Redirect to={TO.SIGN_UP[language]} />;
  }

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'sing_up.page_title', message: `${APP_NAME} - Sign up` }))}</title>
      </Helmet>

      <Main>
        <FormCard size="md">
          <FormCard.ProgressBar steps={3} completed={currentStep} />
          <Switch>
            <Route path={PATH.SIGN_UP__EMPLOYER__STEP[language]}>
              <Switch>
                <Route exact path={`${TO.SIGN_UP__EMPLOYER__STEP[language]}/1`}>
                  <EmployerStep1 ref={formRef} />
                </Route>
                <Route exact path={`${TO.SIGN_UP__EMPLOYER__STEP[language]}/2`}>
                  <EmployerStep2 ref={formRef} />
                </Route>
                <Route exact path={`${TO.SIGN_UP__EMPLOYER__STEP[language]}/3`}>
                  <EmployerStep3 />
                </Route>
              </Switch>
            </Route>

            <Route exact path={PATH.SIGN_UP__EMPLOYER[language]}>
              <Redirect to={toObjectGenerator(`${TO.SIGN_UP__EMPLOYER__STEP[language]}/1`)} />
            </Route>
          </Switch>
        </FormCard>
      </Main>
    </>
  );
};
