import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Heading, Flex, Container as ThemeUiContainer, ThemeUIStyleObject } from 'theme-ui';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';

import { Icon } from 'components/Icon/Icon';
import { useKeyboardEvent } from 'hooks/useKeybordEvent/useKeybordEvent';
import { Button } from 'components/ui/Buttons';
import { useModal } from 'hooks/useModal/useModal';
import { useTheme } from 'styles/useTheme';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { emitCustomEvent } from 'utils/customEvents';
import { MODAL_CLOSE } from 'constants/customEventsNames';
import { delay } from 'utils/delay';

import {
  BackButtonComponent,
  BackButtonProps,
  BodyComponent,
  BodyProps,
  FooterComponent,
  FooterProps,
  HeaderComponent,
  HeaderProps,
  ModalComponent,
  ModalProps,
  SubTitleComponent,
  TitleComponent,
  TitleProps,
} from './types';
import { ModalContext } from './ModalContext';
import { ModalBackdrop } from './ModalBackdrop';
import { ModalContainer } from './ModalContainer';

const getInstanceNumber = (() => {
  let counter = 0;
  return () => {
    counter += 1;
    return counter;
  };
})();

const modalRoot = document.getElementById('modal-root');

export const Modal: ModalComponent = ({
  children,
  size = 'default',
  unClosable,
  onCloseRoute,
  sx,
  replaceMode,
  fullHeight,
  ...props
}: ModalProps): React.ReactElement | null => {
  const [isOpen, setIsOpen] = useState(true);
  const [isExitCompleted, setIsExitCompleted] = useState(false);

  const [wrapperSx, setWrapperSx] = useState<ThemeUIStyleObject | undefined>(undefined);
  const [isFullHeight, setIsFullHeight] = useState<boolean | undefined>(undefined);
  const [customHandleClose, setCustomHandleClose] = useState<((close: () => void) => void) | undefined>(undefined);
  const [closeOnBackdrop, setCloseOnBackdrop] = useState<boolean | undefined>(true);

  const { theme } = useTheme();

  const instanceNumber = useMemo(getInstanceNumber, []);

  const handleClose = useCallback(() => {
    if (!unClosable) emitCustomEvent(MODAL_CLOSE, instanceNumber);
  }, [instanceNumber, unClosable]);

  const innerHandleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClose = useCallback(() => {
    if (customHandleClose) {
      customHandleClose(innerHandleClose);
    } else {
      innerHandleClose();
    }
  }, [customHandleClose, innerHandleClose]);

  useCustomEventListener(MODAL_CLOSE, (instance) => {
    if (instance === instanceNumber) {
      onClose();
    }
  });

  useEffect(() => {
    if (isExitCompleted) {
      if (replaceMode) {
        props.history.replace(onCloseRoute);
        return;
      }

      props.history.push(onCloseRoute);
    }
  }, [isExitCompleted, onCloseRoute, props.history, replaceMode]);

  const handleExitComplete = async (): Promise<void> => {
    await delay(50); // Didn't found a better way to do this.
    setIsExitCompleted(true);
  };

  useKeyboardEvent('Escape', () => {
    handleClose();
  });

  const modalContextValue = useMemo(
    () => ({
      setWrapperSx,
      setIsFullHeight,
      setCustomHandleClose,
      setCloseOnBackdrop,
      handleClose,
      replaceMode: !!replaceMode,
      baseRoute: onCloseRoute,
    }),
    [handleClose, replaceMode, onCloseRoute],
  );

  return (
    modalRoot &&
    createPortal(
      <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
        {isOpen && (
          <ModalContext.Provider value={modalContextValue}>
            <ModalBackdrop
              sx={{ zIndex: theme.zIndices.modal + instanceNumber }}
              handleClose={closeOnBackdrop ? handleClose : undefined}
            >
              <ModalContainer
                key="modal"
                size={size}
                fullHeight={isFullHeight || fullHeight}
                sx={{
                  transition: 'width linear .075s, height linear .075s, max-height linear .075s ',
                  ...theme.modal[size],
                  ...(sx && sx),
                  ...(wrapperSx && wrapperSx),
                }}
              >
                {children}
              </ModalContainer>
            </ModalBackdrop>
          </ModalContext.Provider>
        )}
      </AnimatePresence>,
      modalRoot,
    )
  );
};

const Header: HeaderComponent = ({ children }: HeaderProps): React.ReactElement => {
  const { handleClose } = useModal();

  return (
    <Flex sx={{ px: 4, gap: 2, minHeight: '60px', alignItems: 'center' }}>
      {children}
      <Button
        onClick={handleClose}
        variant="minimal"
        shape="circle"
        sx={{
          mr: '-0.75rem',
        }}
      >
        <Icon size={28} type="x" />
      </Button>
    </Flex>
  );
};

const Title: TitleComponent = ({ children, sx }: TitleProps): React.ReactElement => (
  <Heading
    as="h3"
    variant="heading3"
    sx={{
      mb: `0 !important`,
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      ...(sx && sx),
    }}
  >
    {children}
  </Heading>
);

const SubTitle: SubTitleComponent = ({ children, sx }: TitleProps): React.ReactElement => (
  <Heading
    as="h4"
    variant="heading4"
    sx={{
      mb: 2,
      ...(sx && sx),
    }}
  >
    {children}
  </Heading>
);

const BackButton: BackButtonComponent = ({ onClick, type = 'header' }: BackButtonProps): React.ReactElement => {
  const history = useHistory();
  const handleBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (onClick) {
      onClick(e);
      return;
    }

    history.go(-1);
  };

  return type === 'header' ? (
    <Button
      onClick={handleBack}
      variant="minimal"
      size="sm"
      shape="circle"
      type="button"
      sx={{ ml: '-0.75rem' }}
      aria-label={i18n._(
        t({
          id: 'global.forms.buttons.back',
          message: 'Back',
        }),
      )}
    >
      <Icon size={28} type="chevronLeft" />
    </Button>
  ) : (
    <Button
      onClick={handleBack}
      variant="lightGrey"
      size="default"
      shape="rounded"
      type="button"
      aria-label={i18n._(
        t({
          id: 'global.forms.buttons.back',
          message: 'Back',
        }),
      )}
      sx={{
        pl: 2,
      }}
    >
      <Icon wrapperSx={{ mr: 2 }} type="arrowLeft" /> <Trans id="global.forms.buttons.back" />
    </Button>
  );
};

const Body: BodyComponent = React.forwardRef<HTMLDivElement, BodyProps>(({ children, sx, ...rest }: BodyProps, ref) => (
  <ThemeUiContainer
    sx={{
      display: 'flex',
      flexDirection: 'column',
      m: 0,
      flexGrow: 1,
      px: 4,
      py: 3,
      overflowY: 'auto',
      ...(sx && sx),
    }}
    {...rest}
    ref={ref}
  >
    {children}
  </ThemeUiContainer>
));

const Footer: FooterComponent = ({ children, sx, ...rest }: FooterProps): React.ReactElement => (
  <Flex
    sx={{
      px: 4,
      py: 3,
      gap: 2,
      justifyContent: 'flex-end',
      ...(sx && sx),
    }}
    {...rest}
  >
    {children}
  </Flex>
);

Footer.BackButton = BackButton;

Modal.Header = Header;
Modal.Title = Title;
Modal.SubTitle = SubTitle;
Modal.BackButton = BackButton;
Modal.Body = Body;
Modal.Footer = Footer;
