/** @jsxImportSource theme-ui */

import { motion, MotionProps, Transition } from 'framer-motion';
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

type Props = {
  /**
   * Parsed to rem value. For ex.
   * ```jsx
   * <LoadingSpinner size={2} />
   * ```
   * Will produce:
   * ```css
   * width: 2rem
   * ```
   */
  sx?: ThemeUIStyleObject;
  size?: number;
  color?: string;
} & React.ComponentPropsWithRef<'div'> &
  MotionProps;

export type LoadingSpinnerProps = Props;

const defaultProps: Partial<Props> = {
  sx: undefined,
  size: 2,
  color: 'black',
};

const containerAnimationVariants = {
  initial: {
    transition: {
      staggerChildren: 0.25,
    },
  },
  animation: {
    transition: {
      staggerChildren: 0.25,
    },
  },
};

const dotAnimationVariants = {
  initial: {
    y: ['0%', '0%'],
    opacity: [1, 1],
  },
  animation: {
    y: ['-50%', '50%'],
    opacity: [0.3, 1],
    scale: [0.95, 1.05],
  },
};

const dotAnimationTransition: Transition = {
  duration: 0.5,
  repeat: Infinity,
  repeatType: 'reverse',
  ease: 'easeInOut',
};

export const LoadingSpinner = React.forwardRef<HTMLDivElement, Props>(
  ({ sx, size = 2, color = 'black' }: Props, ref) => {
    const containerSx: ThemeUIStyleObject = {
      width: `${size}rem`,
      height: `${(size / 2) * 1.5}rem`,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    };

    const dotSx: ThemeUIStyleObject = {
      display: 'block',
      width: `${size * 0.25}rem`,
      height: `${size * 0.25}rem`,
      backgroundColor: color,
      borderRadius: '50%',
    };

    return (
      <motion.div
        ref={ref}
        sx={{ ...containerSx, ...(sx && sx) }}
        variants={containerAnimationVariants}
        initial="initail"
        animate="animation"
        aria-label="Loading..."
      >
        <motion.span sx={dotSx} variants={dotAnimationVariants} transition={dotAnimationTransition} />
        <motion.span sx={dotSx} variants={dotAnimationVariants} transition={dotAnimationTransition} />
        <motion.span sx={dotSx} variants={dotAnimationVariants} transition={dotAnimationTransition} />
      </motion.div>
    );
  },
);

LoadingSpinner.defaultProps = defaultProps;
