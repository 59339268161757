import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { t, plural, Trans, Plural } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { parsedEmployeesSelector } from 'state/employees';
import { deleteEmployeesAction } from 'api/actions/employees/employeesActions';

import { DeleteDeactivateModal } from './modalsElements/DeleteDeactivateModal';

export const DeleteTeammatesModal = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const employees = useRecoilValue(parsedEmployeesSelector);

  const titleRenderer = useCallback(
    (count: number, name: string) =>
      t({
        id: 'team.delete_teammates',
        message: plural(count, { one: `Delete - ${name}?`, other: 'Delete employees?' }),
      }),
    [],
  );

  const contentRenderer = useCallback(
    (count: number, selectedNames: string[], selectedIds: string[], baseRoute: string) => {
      const allEmployeesDeactivated =
        selectedIds.filter((id) => !employees?.get(id)?.isActive).length === selectedIds.length;
      return (
        <>
          <Trans id="team.delete_teammates.desc1">
            <Text>
              You're about to permanently delete{' '}
              <Plural
                value={count}
                one="this user with all their data."
                other={
                  <Trans>
                    users: <strong>{selectedNames.join(', ')}.</strong>
                  </Trans>
                }
              />
              <strong> This action cannot be reversed.</strong>
            </Text>
          </Trans>
          {!allEmployeesDeactivated && (
            <Trans id="team.delete_teammates.desc2">
              <Text>
                If you want to keep <Plural value={count} one="this user" other="these users" /> in your records,
                consider{' '}
                <LinkAnchor
                  standOut
                  sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
                  to={{
                    pathname: `${baseRoute}${TO.DEACTIVATE_TEAMMATES_MODAL[language]}`,
                    state: { ids: selectedIds },
                  }}
                >
                  Deactivating
                </LinkAnchor>{' '}
                <Plural value={count} one="him or her" other="them" /> instead.
              </Text>
            </Trans>
          )}
        </>
      );
    },
    [language, employees],
  );

  return (
    <DeleteDeactivateModal
      list={employees}
      namePath={['name.firstName', 'name.surname']}
      action={deleteEmployeesAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
    />
  );
};
