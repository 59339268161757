import { ThemeUICSSObject, ThemeUIStyleObject } from 'theme-ui';

import { hexToRgba } from 'utils/colors';
import { makeTheme } from '../makeTheme';

import { BASE_COLORS } from './colors';

const BACKGROUNDS = {
  default: {
    cell: 'transparent',
    header: 'whites1',
    bookmark: 'alphas.darker7',
    details: hexToRgba(BASE_COLORS.grayBlues[7], 0.02),
  },
  secondary: {
    cell: hexToRgba(BASE_COLORS.grayBlues[7], 0.02),
    header: 'whites3',
    details: hexToRgba(BASE_COLORS.grayBlues[7], 0.07),
  },
  weekend: {
    cell: 'alphas.darker2',
    header: 'whites5',
    details: 'alphas.darker2',
  },
  holiday: {
    cell: 'alphas.bluish4',
    header: 'whiteBlues4',
    bookmark: 'softBlues3',
  },
  today: 'reds4',
};

const TEXTS = {
  default: 'texts.default',
  secondary: hexToRgba(BASE_COLORS.grays[7], 0.6),
  holiday: 'texts.lighter',
  weekend: 'texts.lighter',
  dayDetailsState: 'rgba(0,0,0,.8)',
  error: 'reports.error',
};

const REPORTS_COLORS = {
  present: 'reports.present',
  incompleteShift: 'reports.incompleteShift',
  overtime: 'reports.overtime',
  absent: 'reports.absent',
  timeOff: 'reports.timeOff',
  customRequest: 'reports.customRequest',
  businessTrip: 'reports.businessTrip',
  error: 'reports.error',
  annotation: 'alphas.darker6',
  alphas: {
    present: 'reports.cells.present',
    incompleteShift: 'reports.cells.incompleteShift',
    overtime: 'reports.cells.overtime',
    absent: 'reports.cells.absent',
    timeOff: 'reports.cells.timeOff',
    error: 'reports.cells.error',
  },
};

const bookmarkStyles: ThemeUIStyleObject = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  height: '1rem',
  transform: 'translateY(-100%)',
  px: 2,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 1,
  fontWeight: '700',
  textAlign: 'center',
  color: TEXTS.default,
  borderTopLeftRadius: 'xs',
  borderTopRightRadius: 'xs',
  boxShadow: 'inset 0 -2px 6px -1px rgba(0,0,0,.2)',
  '> span': {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    opacity: 0.8,
  },
};

const cellDiverPseudoStyles: ThemeUICSSObject = {
  '&[data-divider="true"]': {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-1px',
      top: '-1px',
      bottom: '-1px',
      bg: 'alphas.darker4',
      width: '1px',
    },

    '> .bookmark': {
      left: '-1px',
    },
  },
};

const timesheetsStyles: ThemeUICSSObject = {
  summaryHeaderCell: {
    bg: BACKGROUNDS.default.header,
    height: '100%',
    position: 'relative',
    alignSelf: 'center',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    px: '0.75rem',
    color: TEXTS.default,
    fontSize: 0,
    fontWeight: 'bold',
    lineHeight: 'heading',
    textAlign: 'center',
    '> .bookmark': {
      ...bookmarkStyles,
      fontSize: 0,
      bg: BACKGROUNDS.default.bookmark,
    },
    ...cellDiverPseudoStyles,
    '&[data-type="secondary"]': {
      bg: BACKGROUNDS.secondary.header,
      color: TEXTS.secondary,
    },
    '&[data-type="present"]': {
      bg: REPORTS_COLORS.present,
    },
    '&[data-type="absent"]': {
      bg: REPORTS_COLORS.absent,
    },
    '&[data-type="businessTrip"]': {
      color: REPORTS_COLORS.businessTrip,
      bg: BACKGROUNDS.secondary.header,
    },
    '&[data-type="customRequest"]': {
      color: REPORTS_COLORS.customRequest,
      bg: BACKGROUNDS.secondary.header,
    },
    '&[data-type="incompleteShift"]': {
      bg: REPORTS_COLORS.incompleteShift,
    },
    '&[data-type="overtime"]': {
      bg: REPORTS_COLORS.overtime,
    },
    '&[data-type="timeOff"]': {
      bg: REPORTS_COLORS.timeOff,
    },
  },
  summaryCell: {
    bg: BACKGROUNDS.default.cell,
    height: '100%',
    position: 'relative',
    alignSelf: 'center',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    p: '0.75rem',
    mx: '-0.75rem',
    textAlign: 'center',
    ...cellDiverPseudoStyles,
    '&[data-type="secondary"]': {
      bg: BACKGROUNDS.secondary.cell,
      color: TEXTS.secondary,
    },
    '&[data-type="present"]': {
      bg: REPORTS_COLORS.alphas.present,
    },
    '&[data-type="absent"]': {
      bg: REPORTS_COLORS.alphas.absent,
    },
    '&[data-type="businessTrip"]': {
      color: REPORTS_COLORS.businessTrip,
      bg: BACKGROUNDS.secondary.cell,
    },
    '&[data-type="customRequest"]': {
      color: REPORTS_COLORS.customRequest,
      bg: BACKGROUNDS.secondary.cell,
    },
    '&[data-type="incompleteShift"]': {
      bg: REPORTS_COLORS.alphas.incompleteShift,
    },
    '&[data-type="overtime"]': {
      bg: REPORTS_COLORS.alphas.overtime,
    },
    '&[data-type="timeOff"]': {
      bg: REPORTS_COLORS.alphas.timeOff,
    },
    // FOR DETAILS CELLS
    '&[data-details="true"]': {
      bg: BACKGROUNDS.default.details,
      '&[data-type="secondary"]': {
        bg: BACKGROUNDS.secondary.details,
        color: TEXTS.secondary,
      },
      '&[data-type="present"]': {
        bg: REPORTS_COLORS.present,
      },
      '&[data-type="absent"]': {
        bg: REPORTS_COLORS.absent,
      },
      '&[data-type="businessTrip"]': {
        bg: REPORTS_COLORS.businessTrip,
      },
      '&[data-type="customRequest"]': {
        bg: REPORTS_COLORS.customRequest,
      },
      '&[data-type="incompleteShift"]': {
        bg: REPORTS_COLORS.incompleteShift,
      },
      '&[data-type="overtime"]': {
        bg: REPORTS_COLORS.overtime,
      },
      '&[data-type="timeOff"]': {
        bg: REPORTS_COLORS.timeOff,
      },
      // Special cases
      '&[data-weekend="true"]:not([data-holiday="true"]):not([data-errors="true"])': {
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: '0',
          top: '0',
          bottom: '0',
          right: '0',
          bg: BACKGROUNDS.weekend.details,
          zIndex: -1,
        },
      },
      '&[data-holiday="true"]:not([data-errors="true"])': {
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: '0',
          top: '0',
          bottom: '0',
          right: '0',
          bg: BACKGROUNDS.holiday.cell,
          zIndex: -1,
        },
      },
    },
    '&[data-errors="true"]': {
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
        right: '0',
        bg: REPORTS_COLORS.alphas.error,
        zIndex: -1,
      },
    },
  },
  summaryCellValue: {
    '&[data-fancy="true"]': {
      color: TEXTS.default,
    },
  },
  summaryDayCell: {
    bg: BACKGROUNDS.default.details,
    height: '100%',
    position: 'relative',
    alignSelf: 'center',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    px: '1.5rem',
    mx: '-0.75rem',
    color: TEXTS.default,
    textTransform: 'none',
    fontSize: 2,
    lineHeight: 'heading',
    '> .weekday': {
      fontWeight: '700',
    },
    '&[data-errors="true"]': {
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
        right: '0',
        bg: REPORTS_COLORS.alphas.error,
        zIndex: -1,
      },
    },
    '&[data-weekend="true"]:not([data-holiday="true"]):not([data-errors="true"])': {
      bg: BACKGROUNDS.weekend.details,
      color: TEXTS.weekend,
      '> span': {
        color: 'inherit',
      },
    },
    '&[data-holiday="true"]': {
      '&:not([data-errors="true"])': {
        bg: BACKGROUNDS.holiday.cell,
      },
      '> .bookmark': {
        position: 'absolute',
        left: 2,
        top: '50%',
        transform: 'translateY(-50%)',
        px: 2,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 1,
        fontWeight: '700',
        textAlign: 'center',
        color: TEXTS.default,
        borderRadius: 'pill',
        bg: BACKGROUNDS.holiday.bookmark,
        maxWidth: '160px',
        '> span': {
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          opacity: 0.8,
        },
      },
    },
    '&[data-today="true"]': {
      '&::after': {
        content: '""',
        display: 'block',
        height: '2px',
        position: 'absolute',
        top: 20,
        bottom: 20,
        right: 0,
        bg: BACKGROUNDS.today,
      },
    },
  },
};

export const reportStyles = makeTheme({
  reports: {
    ...timesheetsStyles,
    dayHeaderCell: {
      bg: BACKGROUNDS.default.header,
      height: '100%',
      position: 'relative',
      alignSelf: 'center',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-end',
      px: '0.75rem',
      color: TEXTS.default,
      textTransform: 'none',
      fontSize: 2,
      lineHeight: 'heading',
      '> .weekday': {
        fontWeight: '700',
      },
      '&[data-weekend="true"]:not([data-holiday="true"])': {
        bg: BACKGROUNDS.weekend.header,
        color: TEXTS.weekend,
        '> span': {
          color: 'inherit',
        },
      },
      '&[data-holiday="true"]': {
        bg: BACKGROUNDS.holiday.header,
        '> .bookmark': {
          ...bookmarkStyles,
          bg: BACKGROUNDS.holiday.bookmark,
        },
      },
      '&[data-today="true"]': {
        '&::after': {
          content: '""',
          display: 'block',
          height: '2px',
          position: 'absolute',
          left: 20,
          right: 20,
          bottom: 0,
          bg: BACKGROUNDS.today,
        },
      },
    },
    dayCell: {
      position: 'relative',
      mx: '-0.75rem',
      flex: 1,
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&[data-details-open="true"]': {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          border: '3px solid',
          borderColor: 'rgba(0,0,0,.65)',
          bg: 'alphas.lighter1',
        },
      },
      '&[data-weekend="true"]': {
        bg: BACKGROUNDS.weekend.cell,
      },
      '&[data-holiday="true"]': {
        bg: BACKGROUNDS.holiday.cell,
      },
      '&:hover': {
        '> div': {
          opacity: 0.8,
        },
      },
    },
    dayCellState: {
      overflow: 'hidden',
      flexDirection: 'column',
      flex: 1,
      alignSelf: 'stretch',
      my: 1,
      outline: '1px solid',
      outlineColor: 'transparent',
      '&[data-start="true"]': {
        ml: 1,
        '&[data-type="present"], &[data-type="incompleteShift"], &[data-type="overtime"]': {
          borderTopLeftRadius: 'sm',
          borderBottomLeftRadius: 'sm',
        },
        '&[data-type="absent"], &[data-type="timeOff"]': {
          borderTopLeftRadius: 'pill',
          borderBottomLeftRadius: 'pill',
        },
      },
      '&[data-end="true"]': {
        mr: 1,
        '&[data-type="present"], &[data-type="incompleteShift"], &[data-type="overtime"]': {
          borderTopRightRadius: 'sm',
          borderBottomRightRadius: 'sm',
        },
        '&[data-type="absent"], &[data-type="timeOff"]': {
          borderTopRightRadius: 'pill',
          borderBottomRightRadius: 'pill',
        },
      },
      '&[data-today="true"]': {
        // TODO:
      },
      '&[data-type="present"]': {
        bg: REPORTS_COLORS.present,
        outlineColor: REPORTS_COLORS.present,
      },
      '&[data-type="incompleteShift"]': {
        bg: REPORTS_COLORS.incompleteShift,
        outlineColor: REPORTS_COLORS.incompleteShift,
      },
      '&[data-type="overtime"]': {
        bg: REPORTS_COLORS.overtime,
        outlineColor: REPORTS_COLORS.overtime,
      },
      '&[data-type="timeOff"]': {
        bg: REPORTS_COLORS.timeOff,
        outlineColor: REPORTS_COLORS.timeOff,
      },
      '&[data-type="absent"]': {
        bg: REPORTS_COLORS.absent,
        outlineColor: REPORTS_COLORS.absent,
      },
    },
    dayStateRibbon: {
      textAlign: 'center',
      fontSize: 0,
      fontWeight: '700',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flex: '1 0 25%',
      '&[data-title]': {
        bg: REPORTS_COLORS.annotation,
        color: 'white',
        '&[data-type="timeOff"]': {
          bg: 'reports.alphas.timeOff',
        },
        '&[data-type="businessTrip"]': {
          bg: REPORTS_COLORS.businessTrip,
        },
        '&[data-type="customRequest"]': {
          bg: 'reports.alphas.customRequest',
        },
      },
    },
    dayStateBadge: {
      px: 1,
      borderRadius: 'xs',
      textTransform: 'uppercase',
      fontSize: 2,
      fontWeight: 'bold',
      border: '1px solid',
      borderColor: 'transparent',
      bg: 'transparent',
      color: TEXTS.dayDetailsState,
      alignItems: 'center',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      '> span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },

      '&[data-type="PRESENT"]': {
        bg: REPORTS_COLORS.present,
      },
      '&[data-type="INCOMPLETE_SHIFT"]': {
        bg: REPORTS_COLORS.incompleteShift,
      },
      '&[data-type="OVERTIME"]': {
        bg: REPORTS_COLORS.overtime,
      },
      '&[data-type="TIME_OFF"]': {
        bg: REPORTS_COLORS.timeOff,
        borderRadius: 'pill',
      },
      '&[data-type="ABSENT"]': {
        bg: REPORTS_COLORS.absent,
        borderRadius: 'pill',
      },
      '&[data-type="CUSTOM_REQUEST"]': {
        borderColor: REPORTS_COLORS.customRequest,
        color: REPORTS_COLORS.customRequest,
      },
      '&[data-type="BUSINESS_TRIP"]': {
        borderColor: REPORTS_COLORS.businessTrip,
        color: REPORTS_COLORS.businessTrip,
      },
      '&[data-type="ERROR"]': {
        borderColor: REPORTS_COLORS.error,
        color: TEXTS.error,
      },
      '&[data-type="ANNOTATION"]': {
        bg: REPORTS_COLORS.annotation,
        borderColor: REPORTS_COLORS.annotation,
      },
    },
    fancyDataBox: {
      flexDirection: 'row',
      bg: 'alphas.darker2',
      borderRadius: 'sm',
      p: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 2,
      '&[data-bold="true"]': {
        fontWeight: 'bold',
      },
      '.value': {
        justifySelf: 'flex-end',
        bg: 'alphas.lighter7',
        py: 1,
        px: 2,
        borderRadius: 'xs',
      },
      '.arrow': {
        color: 'texts.lighter',
      },
      '.title': {
        pl: 1,
        py: 1,
      },
    },
    fancyList: {
      flexDirection: 'column',
      gap: '0.125rem',
      '> :first-of-type': {
        borderTopLeftRadius: 'sm',
        borderTopRightRadius: 'sm',
      },
      '> :last-of-type': {
        borderBottomLeftRadius: 'sm',
        borderBottomRightRadius: 'sm',
      },
    },
    fancyListItem: {
      bg: 'alphas.lighter7',
      px: 2,
      py: 2,
      minHeight: '2.5rem',
      alignItems: 'center',
      fontSize: 2,
      outline: '1px solid',
      outlineColor: 'transparent',
      gap: 2,
      // '&[data-dropdown="true"]': {},
      '&[data-interactive="true"]': {
        cursor: 'pointer',
        '&:hover': {
          outlineColor: 'alphas.bluish5',
          bg: 'list.rowBackground.hover',
          boxShadow: 'dropShadow.levelOne',
        },
      },
    },
    clockLogPhoto: {
      display: 'flex',
      flex: '0 0 auto',
      borderRadius: 'sm',
      cursor: 'pointer',
      transition: 'all 0.15s ease-in-out',
      '&:hover': {
        boxShadow: 'dropShadow.levelTwo',
        opacity: 0.7,
        transform: 'scale(1.05)',
      },
    },
  },
});
