import React from 'react';
import { Flex } from 'theme-ui';

import { Dot, DotProps } from 'components/Dot/Dot';

type WithDotProps = {
  showDot?: boolean;
  dotProps?: DotProps;
};

export function withDot<T>(
  Component: React.ComponentType<T & WithDotProps>,
): (props: T & WithDotProps) => React.ReactElement {
  const ComponentWithDot = ({ showDot, dotProps, ...props }: T & WithDotProps): React.ReactElement => {
    if (showDot) {
      return (
        <Flex sx={{ position: 'relative' }}>
          <Component {...(props as T)} />
          <Dot {...dotProps} />
        </Flex>
      );
    }
    return <Component {...(props as T)} />;
  };

  ComponentWithDot.defaultProps = {
    showDot: true,
    dotProps: undefined,
  };

  return ComponentWithDot;
}
