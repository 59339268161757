/** @jsxImportSource theme-ui */

import React, { useState, useRef, useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Modal } from 'components/Modal/Modal';
import { setPermissionsAction } from 'api/actions/employees/employeesActions';
import { delay } from 'utils/delay';
import { createEvent } from 'utils/createEvent';
import { rolesSelectOptionsSelector } from 'state/team';
import { AdvancedFeaturesFieldArray } from 'layouts/Team/forms/formsElements/AdvancedFeaturesFieldArray';
import { SetPermissionsActionProps } from 'api/actions/employees/employeesActions.types';
import { Switch } from 'components/ui/Switch';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useModal } from 'hooks/useModal/useModal';
import { FEATURES_DEFAULT_VALUE } from 'constants/defaults';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { getNumberField } from 'utils/getNumberField';
import { Feature, VisibilityLevels } from 'api/actions/organizationSession/organizationSessionActions.types';

const forceCorrectTypes = ({
  features,
  visibilityLevel,
  ...rest
}: Omit<SetPermissionsActionProps, 'peopleIds'>): Omit<SetPermissionsActionProps, 'peopleIds'> => ({
  ...rest,
  features: features.map((f: Feature) => ({ ...f, value: !!f.value })),
  visibilityLevel: getNumberField(visibilityLevel),
});

export const SetAdvancedDetails = (): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose, baseRoute } = useModal();

  const [loading, setLoading] = useState(false);

  const rolesSelectOptions = useRecoilValue(rolesSelectOptionsSelector);
  const { state } = useLocation<{ ids?: string[] }>();
  const { ids } = state || {};
  const formRef = useRef<HTMLFormElement | null>(null);

  const { control, register, handleSubmit, watch } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      hideOnList: false,
      features: FEATURES_DEFAULT_VALUE,
      visibilityLevel: VisibilityLevels.Year,
    },
  });

  const { mutate } = useMutation(setPermissionsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: Omit<SetPermissionsActionProps, 'peopleIds'>): Promise<boolean> => {
      if (!ids || !ids.length) return false;
      const { error: submitError } = await mutate({
        peopleIds: ids || [],
        ...forceCorrectTypes(body),
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_advanced_bulk.success', message: 'Successfully set!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, ids, addSnackbar],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    submitForm();
  };

  if (!rolesSelectOptions) return <Redirect to={baseRoute} />;

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_advanced_bulk">Set advanced details</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit, onSubmitError)} noValidate>
          <Switch
            size="sm"
            label={t({ id: 'team.set_advanced_bulk.hide', message: 'Hide on all lists' })}
            additionalInfo={t({
              id: 'team.set_advanced_bulk.hide.info',
              message: 'User will be visible only by Administrators',
            })}
            {...register('hideOnList')}
          />
          <AdvancedFeaturesFieldArray register={register} control={control} watch={watch} />
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
