import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Flex, Input, Text, Select as UiSelect, ThemeUIStyleObject } from 'theme-ui';
import _ from 'lodash';

import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { Select } from '../Select/Select';
import { useTheme } from 'styles/useTheme';
import { delay } from 'utils/delay';
import { silentSetValue } from 'utils/silentSetValue';
import { focusInput } from 'utils/focusInput';

import { AmOptions, InputNames, TimePickerProps } from './types';
import { useTimePickerLogic } from './useTimePickerLogic';

const defaultProps: Partial<TimePickerProps> = {
  onValidError: undefined,
  onClearError: undefined,
  quickSelectOptions: undefined,
  minTime: undefined,
  maxTime: undefined,
};

export const TimePicker = React.forwardRef<HTMLInputElement, TimePickerProps>(
  (
    {
      minTime,
      maxTime,
      quickSelectOptions,
      size = 'default',
      onValidError,
      onClearError,
      onChange,
      onBlur,
      ...props
    }: TimePickerProps,
    ref,
  ) => {
    const [afterFirstBlur, setAfterFirstBlur] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);

    const { theme } = useTheme();

    const isFocusRef = useRef<boolean>(false);
    const isBackspaceModeRef = useRef(false);

    const {
      timeFormat,
      timePickerError,
      timePickerErrorMessage,
      hiddenRef,
      hoursRef,
      minutesRef,
      amRef,
      dispatchBlurEvent,
      formatUserInput,
      getFirstUnfilledInput,
      getSelectOptions,
      isPickerEmpty,
      getNextInput,
      getPreviousInput,
      getInputRefByName,
      getUnixFromUserInput,
      setTimeInputs,
      updateHiddenInputValue,
      validate,
    } = useTimePickerLogic({
      minTime,
      maxTime,
      onValidError,
      onClearError,
    });

    const handleUserChange = useCallback(
      ({ target: { value, name: inputName, selectionStart: cursorPosition } }: React.ChangeEvent<HTMLInputElement>) => {
        const inputsUpdates: {
          inputRef: React.MutableRefObject<HTMLInputElement | null>;
          newValue?: string;
          newCursorPosition?: number;
        }[] = [];

        const currentInputRef = getInputRefByName(inputName);

        const hoursValueParser = (newValue: string) => {
          if (newValue.length === 2) {
            const firstNumber = Math.min(+newValue[0], +timeFormat[0]);

            let secondNumberMax = firstNumber === 2 ? 4 : 9;

            if (timeFormat === '12' && firstNumber === 1) secondNumberMax = 2;

            const secondNumber = Math.min(+newValue[1], secondNumberMax);

            return `${firstNumber}${secondNumber}`;
          }
          return newValue;
        };

        const minutesValueParser = (newValue: string) => {
          if (newValue.length === 2) {
            return `${Math.min(+newValue[0], 5)}${newValue[1]}`;
          }
          return newValue;
        };

        switch (value.length) {
          case 0:
            if (inputName === InputNames.minutes) {
              inputsUpdates.push({
                inputRef: hoursRef,
                newCursorPosition: 2,
              });
            }
            break;
          case 1:
            break;
          case 2:
            if (inputName === InputNames.hours && cursorPosition === 2) {
              inputsUpdates.push({
                inputRef: minutesRef,
                newCursorPosition: 0,
              });
            }
            if (inputName === InputNames.hours && +value > +timeFormat) {
              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue: timeFormat,
              });
            }
            if (inputName === InputNames.minutes && +value > 59) {
              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue: '59',
              });
            }
            break;
          default:
            if (cursorPosition && cursorPosition > 2) {
              if (inputName === InputNames.hours && isBackspaceModeRef.current) {
                const currentInputNewValue = value.substring(0, 2);
                const nextInputFirstChar = value[2];

                const minutesInputValue = minutesRef?.current?.value;

                const minutesInputNewValue = minutesInputValue
                  ? `${nextInputFirstChar}${minutesInputValue.substring(1)}`
                  : nextInputFirstChar;

                inputsUpdates.push({
                  inputRef: currentInputRef,
                  newValue: currentInputNewValue,
                });

                inputsUpdates.push({
                  inputRef: minutesRef,
                  newValue: minutesValueParser(minutesInputNewValue),
                  newCursorPosition: 1,
                });

                break;
              }

              const newValue = value.slice(-2);

              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue: inputName === InputNames.hours ? hoursValueParser(newValue) : minutesValueParser(newValue),
              });

              break;
            } else if (cursorPosition) {
              const filteredValue = [...value].filter((v, i) => i !== cursorPosition).join('');

              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue:
                  inputName === InputNames.hours ? hoursValueParser(filteredValue) : minutesValueParser(filteredValue),
                newCursorPosition: cursorPosition !== 2 ? cursorPosition : undefined,
              });

              if (cursorPosition === 2 && inputName === InputNames.hours) {
                inputsUpdates.push({
                  inputRef: minutesRef,
                  newCursorPosition: 0,
                });
              }

              break;
            }
        }

        inputsUpdates.forEach(({ inputRef, newValue, newCursorPosition }) => {
          if (!_.isNil(newValue)) {
            silentSetValue(inputRef, newValue);
          }

          if (!_.isNil(newCursorPosition)) {
            focusInput(inputRef, newCursorPosition, newCursorPosition);
          }
        });

        updateHiddenInputValue();

        isBackspaceModeRef.current = false;
      },
      [timeFormat, updateHiddenInputValue, getInputRefByName, hoursRef, minutesRef],
    );

    const handleUiSelectChange = useCallback(async () => {
      await delay(0);
      const newUnix = getUnixFromUserInput();
      setNativeValue(hiddenRef, newUnix || '');
    }, [getUnixFromUserInput, hiddenRef]);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (props.disabled) return;
        const { key, target, ctrlKey } = e;
        const { name: inputName, value, selectionStart: cursorPosition } = target as HTMLInputElement;

        const prevInput = getPreviousInput(inputName);
        const nextInput = getNextInput(inputName);
        const selectedText = window?.getSelection()?.toString() || '';

        if (
          !/[0-9]/.test(key) &&
          key !== 'Tab' &&
          key !== 'ArrowLeft' &&
          key !== 'Backspace' &&
          key !== 'Ctrl' &&
          key !== 'ArrowRight' &&
          key !== 'ArrowUp' &&
          key !== 'ArrowDown' &&
          !ctrlKey
        ) {
          e.preventDefault();
        }

        switch (key) {
          case 'ArrowRight':
            if (cursorPosition === value.length && nextInput) {
              e.preventDefault();
              nextInput.focus();
            }
            break;

          case 'ArrowLeft':
            if ((cursorPosition === 0 || inputName === InputNames.am) && prevInput) {
              e.preventDefault();
              prevInput.focus();
            }
            break;

          case 'Backspace':
            if (inputName === InputNames.minutes && cursorPosition === 0 && selectedText.length === 0) {
              e.preventDefault();
              focusInput(hoursRef, 2, 2);
            }
            break;

          default:
        }
      },
      [props.disabled, getNextInput, getPreviousInput, hoursRef],
    );

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
      switch (e.key) {
        case 'Backspace':
          isBackspaceModeRef.current = true;
          e.preventDefault();
          return;
        case 'ArrowRight':
          e.preventDefault();
          return;
        case 'ArrowLeft':
          e.preventDefault();
          break;
        default:
          break;
      }
    };

    const handleFocus = () => {
      setHasFocus(true);
      isFocusRef.current = true;
    };

    const handleHiddenInputBlur = useCallback(
      (e) => {
        isBackspaceModeRef.current = false;
        setAfterFirstBlur(true);
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur],
    );

    const handleBlur = useCallback(async () => {
      setHasFocus(false);
      isFocusRef.current = false;
      await delay(0);

      if (!isFocusRef.current) {
        isBackspaceModeRef.current = false;
        if (!isPickerEmpty()) {
          formatUserInput();
          const newHiddenInputValue = getUnixFromUserInput();
          if (`${newHiddenInputValue}` !== `${hiddenRef.current?.value}`) {
            setNativeValue(hiddenRef, newHiddenInputValue || '');
          }
        }
        setAfterFirstBlur(true);
        dispatchBlurEvent();
      }
    }, [formatUserInput, dispatchBlurEvent, getUnixFromUserInput, hiddenRef, isPickerEmpty]);

    const handleHiddenInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (onChange) {
          onChange(e);
        }

        if (value.length) {
          const unixFormUserInput = getUnixFromUserInput();
          if (value !== `${unixFormUserInput})`) {
            setTimeInputs(value);
          }
        }

        validate(value);
      },
      [onChange, setTimeInputs, getUnixFromUserInput, validate],
    );

    const updateView = useCallback(
      (value: string) => {
        setTimeInputs(value);
        const valid = validate(value);
        setAfterFirstBlur(!valid);
      },
      [setTimeInputs, validate],
    );

    const inputProps = useMemo(
      () => ({
        sx: theme.forms.timePicker.twoDigitInput[size],
        autoComplete: 'off',
        onKeyUp: handleKeyUp,
        onKeyDown: handleKeyDown,
        onChange: handleUserChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      }),
      [handleBlur, handleUserChange, handleKeyDown, size, theme.forms.timePicker.twoDigitInput],
    );
    const xsSpecificSx: ThemeUIStyleObject = useMemo(
      () => ({
        pr: timeFormat === '12' ? '0.3rem' : '0.15rem',
        pl: '0.15rem',
        py: '0.1rem',
      }),
      [timeFormat],
    );

    return (
      <Select
        {...props}
        ref={mergeRefs([ref, hiddenRef])}
        placeholder=""
        size={size}
        focusThiefElement={!hasFocus ? getFirstUnfilledInput() || undefined : undefined}
        alwaysHideOptions={!quickSelectOptions}
        ignoreOptions={!quickSelectOptions}
        error={props.error || (timePickerError && afterFirstBlur)}
        errorMessage={timePickerErrorMessage || props.errorMessage}
        options={getSelectOptions(quickSelectOptions)}
        onBlur={handleHiddenInputBlur}
        onChange={handleHiddenInputChange}
        onUpdateFieldView={updateView}
        customContent={
          <Flex
            sx={{
              alignItems: 'center',
              gap: 2,
              ...(size === 'xs' && xsSpecificSx),
            }}
          >
            <Flex>
              <Input {...inputProps} name={InputNames.hours} ref={hoursRef} className="focus" />
              <Text mx={1}>:</Text>
              <Input {...inputProps} name={InputNames.minutes} ref={minutesRef} />
            </Flex>
            {timeFormat === '12' && (
              <UiSelect
                name={InputNames.am}
                sx={{
                  ...theme.forms.timePicker.nativeSelect[size],
                  alignSelf: 'center',
                }}
                defaultValue={AmOptions.AM}
                ref={amRef}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleUiSelectChange}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
              >
                <option>{AmOptions.AM}</option>
                <option>{AmOptions.PM}</option>
              </UiSelect>
            )}
          </Flex>
        }
      />
    );
  },
);

TimePicker.defaultProps = defaultProps;
