import { UserSelectableColor } from 'constants/userSelectableColors';

export type SystemManagementPermission = {
  type: SystemManagementPermissionType;
  value: boolean;
};
export type ApplicationModulePermission = {
  type: ApplicationModulePermissionType;
  value: boolean;
};
export type RoleDetails = {
  name: string;
  description?: string;
  color: UserSelectableColor;
  systemManagement: SystemManagementPermission[];
  modules: ApplicationModulePermission[];
};

export type FetchRoleDetailsActionProps = {
  roleId: string;
};
export type FetchRoleDetailsResponse = RoleDetails;

export type EditRoleDetailsActionProps = {
  roleId: string;
  role: RoleDetails;
};

export type EditRoleDetailsResponse = {
  a: string;
};

export type AddRoleActionProps = RoleDetails;

export type AddRoleResponse = {
  a: string;
};

export type DeleteRolesActionProps = string[];

export type DeleteRolesResponse = {
  a: string;
};

export enum PermissionLevel {
  none = 0,
  read = 1,
  writeForTags = 2,
  write = 3,
}

export enum ApplicationModulePermissionType {
  Employees = 0,
  TimeTracking = 1,
  Schedules = 2,
  Requests = 3,
}

export enum SystemManagementPermissionType {
  companySettings = 0,
  permissions = 1,
  payments = 2,
  addMobileTimeClocks = 3,
  enterKioskMode = 4,
}
