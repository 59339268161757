import React from 'react';
import { ThemeUIStyleObject, Box } from 'theme-ui';

type Props = {
  placement?: 'TOP_RIGHT' | 'TOP_LEFT' | 'BOTTOM_RIGHT' | 'BOTTOM_LEFT';
  color?: string;
  size?: number;
  sx?: ThemeUIStyleObject;
};

export type DotProps = Props;

const defaultProps: Partial<Props> = {
  placement: 'TOP_RIGHT',
  color: 'red',
  size: 8,
  sx: undefined,
};

export const Dot = ({
  color = defaultProps.color,
  size = defaultProps.size,
  placement = 'TOP_RIGHT',
  sx,
}: Props): React.ReactElement => {
  const placementSpecificSx: ThemeUIStyleObject = (() => {
    switch (placement) {
      case 'TOP_RIGHT':
        return {
          top: 0,
          right: 0,
        };
      case 'TOP_LEFT':
        return {
          top: 0,
          left: 0,
        };
      case 'BOTTOM_RIGHT':
        return {
          bottom: 0,
          right: 0,
        };
      case 'BOTTOM_LEFT':
        return {
          bottom: 0,
          left: 0,
        };

      default:
        return {};
    }
  })();
  return (
    <Box
      as="span"
      sx={{
        position: 'absolute',
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: 'circle',
        bg: color,
        ...placementSpecificSx,
        ...(sx && sx),
      }}
    />
  );
};

Dot.defaultProps = defaultProps;
