import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { useRecoilValue } from 'recoil';

import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { Button, ButtonProps, LinkButton, LinkButtonProps } from 'components/ui/Buttons';
import { ButtonSharedProps } from 'components/ui/Buttons/types';
import { nightModeSelector } from 'Kiosk/state/kioskState';

const activeSx: ThemeUIStyleObject = {
  background: 'rgba(38, 134, 189, 0.35) !important',
  color: 'skyBlues5',
  '&:hover': {
    background: 'rgba(38, 134, 189, 0.45) !important',
  },
};

const warningSx: ThemeUIStyleObject = {
  background: 'rgba(255, 198, 28, 0.45) !important',
  '&:hover': {
    background: 'rgba(255, 198, 28, 0.55) !important',
  },
  '&::after': {
    content: '"!"',
    fontSize: 1,
    color: 'whites1',
    display: 'block',
    position: 'absolute',
    width: '17px',
    height: '17px',
    lineHeight: '17px',
    background: 'red',
    top: '-4px',
    right: '-4px',
    borderRadius: 'circle',
  },
};

type Props = Omit<ButtonSharedProps, 'prependWith' | 'bgOverwrite' | 'size' | 'shape'> & {
  icon: Icons;
  to?: LinkButtonProps['to'];
  onClick?: ButtonProps['onClick'];
  active?: boolean;
  warning?: boolean;
};

const defaultProps: Partial<Props> = {
  onClick: undefined,
  to: undefined,
  active: false,
  warning: false,
};

export const CameraViewButton = ({ to, onClick, icon, active, warning, sx, ...props }: Props): React.ReactElement => {
  const nightMode = useRecoilValue(nightModeSelector);

  const buttonProps: ButtonSharedProps = {
    ...props,
    prependWith: <Icon type={icon} />,
    bgOverwrite: {
      default: 'rgba(74,90,116, 0.2)',
      hover: 'rgba(125,144,171, 0.5)',
      tap: 'rgba(74,90,116, 0.35)',
      disabled: 'rgba(206,233,241, 0.1)',
    },
    sx: {
      position: 'relative',
      fontSize: '1.25rem',
      color: nightMode ? 'texts.default' : 'whites1',
      p: '6px',
      borderRadius: '14px',
      '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
        backdropFilter: 'blur(16px)',
      },
      ...(active && activeSx),
      ...(warning && warningSx),
      ...(sx && sx),
    },
  };
  if (to) {
    return <LinkButton to={to} {...buttonProps} />;
  }
  return <Button onClick={onClick} {...buttonProps} />;
};

CameraViewButton.defaultProps = defaultProps;
