import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { Flex, InputProps, ThemeUIStyleObject } from 'theme-ui';
import { FixedSizeList } from 'react-window';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';

import { Icon } from 'components/Icon/Icon';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { useForceUpdate } from 'hooks/useForceUpdate/useForceUpdate';
import { TextInput, TextInputProps } from '../TextInput';
import { Avatar } from 'components/Avatar/Avatar';
import { parsedEmployeesSelector } from 'state/employees';
import { getStringWithReducedWhiteSpaces } from 'utils/whiteSpaceReducer';
import { nameDisplayOrderSelector } from 'state/userSession';
import { NameDisplayOrder } from 'api/actions/userSession/userSessionActions.types';
import { withPopper } from '../PopperProvider/withPopper';
import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';

import { MemoizedOptionList } from './OptionList';
import { ITEM_SIZE_DEFAULT } from './constants';
import { EmployeeOption } from './types';

const FlexWithPopper = withPopper(Flex);

type Props = Omit<TextInputProps, 'type'> & {
  employeesIds: string[];
};

enum AvatarSizes {
  xs = 18,
  sm = 21,
  default = 24,
}

export const PersonSelect = React.forwardRef<HTMLInputElement, Props>(
  ({ onChange, onBlur, name, employeesIds, size = 'default', sx, error, errorMessage, ...props }: Props, ref) => {
    const forceUpdate = useForceUpdate();
    const [showOptions, setShowOptions] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [inputValue, setInputValue] = useState<EmployeeOption | null>(null);
    const [filteredOptionsArray, setFilteredOptionsArray] = useState<EmployeeOption[]>([]);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const hiddenRef = useRef<HTMLInputElement | null>(null);
    const optionsListRef = useRef<HTMLDivElement | null>(null);
    const fixedSizeListRef = useRef<FixedSizeList | null>(null);

    const hiddenInputValueRef = useRef<string>('');
    const employeeListRef = useRef<EmployeeOption[]>([]);

    const isFocusRef = useRef<boolean>(false);

    const selectedOptionRef = useRef<InputProps['value']>('');
    const focusedOptionIndexRef = useRef<number | null>(null);

    const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
    const { nameDisplayOrder } = useRecoilValue(nameDisplayOrderSelector);

    const onOutsideClick = useCallback(() => {
      setShowOptions(false);
    }, []);

    const resetFocusedOptionIndex = () => {
      focusedOptionIndexRef.current = null;
      optionsListRef.current?.childNodes.forEach((child) => {
        const option = child as HTMLLIElement;
        option.setAttribute('aria-selected', 'false');
      });
    };

    const dispatchBlurEvent = () => {
      const blurEvent = createEvent('focusout');
      if (hiddenRef.current) hiddenRef.current.dispatchEvent(blurEvent);
    };

    const setSelectedOption = useCallback((option?: EmployeeOption) => {
      if (!option) {
        selectedOptionRef.current = '';
        setNativeValue(hiddenRef, '');

        dispatchBlurEvent();
        return;
      }
      setSearchInputValue(option.label);
      selectedOptionRef.current = option.id;
      setNativeValue(hiddenRef, option.id);
    }, []);

    const onOptionClick = useCallback(
      (option: EmployeeOption) => {
        setSelectedOption(option);
        setShowOptions(false);
      },
      [setSelectedOption],
    );

    const onClearCallback = useCallback(() => {
      setInputValue(null);
      setSelectedOption();
      setShowOptions(true);
    }, [setSelectedOption]);

    const prepareSearchInputValue = useCallback(() => {
      if (isSearching) {
        return searchInputValue;
      }

      if (inputValue) {
        return inputValue.label;
      }

      return '';
    }, [inputValue, isSearching, searchInputValue]);

    const onClick = () => {
      if (!showOptions && !props.disabled) setShowOptions(true);
    };

    const onMouseDown = async () => {
      await delay(0);
      isFocusRef.current = true;
    };

    const filterOptions = useMemo(
      () =>
        _.debounce((filter: string) => {
          const newFilteredList = employeeListRef.current.filter((item) => {
            if (
              filter &&
              item.label.toLocaleLowerCase().includes(getStringWithReducedWhiteSpaces(filter.toLocaleLowerCase()))
            ) {
              return item;
            }

            if (filter === '' || filter === undefined) {
              return employeeListRef.current;
            }

            return null;
          });

          if (_.isEmpty(newFilteredList) || !_.isEqual(filteredOptionsArray, newFilteredList)) {
            setFilteredOptionsArray(newFilteredList);
          }
        }, 500),
      [filteredOptionsArray],
    );

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Enter':
        case 'ArrowUp':
        case 'ArrowDown':
          e.preventDefault();
          break;
        default:
          break;
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (props.disabled) return;

      const optionsNodes = optionsListRef.current?.childNodes;
      const maxIndex =
        fixedSizeListRef.current && fixedSizeListRef.current.props.itemCount
          ? fixedSizeListRef.current.props.itemCount - 1
          : 0;

      const handleFocusOption = () =>
        optionsNodes?.forEach((child) => {
          const option = child as HTMLLIElement;
          const optionTop: number = parseInt(option.style.top, 10);
          const focusedOptionTop = focusedOptionIndexRef.current
            ? focusedOptionIndexRef.current * ITEM_SIZE_DEFAULT
            : 0;

          if (optionTop === focusedOptionTop) {
            option.setAttribute('aria-selected', 'true');
          } else {
            option.setAttribute('aria-selected', 'false');
          }
        });

      const handleEnterOnOption = () => {
        if (optionsNodes && focusedOptionIndexRef.current !== null) {
          const clickEvent = createEvent('click');
          const focusedOptionTop = focusedOptionIndexRef.current
            ? focusedOptionIndexRef.current * ITEM_SIZE_DEFAULT
            : 0;

          optionsNodes?.forEach((child) => {
            const option = child as HTMLLIElement;
            const optionTop: number = parseInt(option.style.top, 10);

            if (optionTop === focusedOptionTop) {
              option.dispatchEvent(clickEvent);
              inputRef.current?.blur();
            }
          });
        }
      };

      switch (e.key) {
        case 'Enter':
          e.preventDefault();
          handleEnterOnOption();
          return;
        case 'ArrowUp':
          e.preventDefault();
          if (focusedOptionIndexRef.current === null) {
            focusedOptionIndexRef.current = maxIndex;
          } else if (focusedOptionIndexRef.current <= maxIndex && focusedOptionIndexRef.current > 0) {
            focusedOptionIndexRef.current -= 1;
          }
          fixedSizeListRef.current?.scrollToItem(focusedOptionIndexRef.current);
          handleFocusOption();
          return;
        case 'ArrowDown':
          e.preventDefault();
          if (focusedOptionIndexRef.current === null) {
            focusedOptionIndexRef.current = 0;
          } else if (focusedOptionIndexRef.current < maxIndex) {
            focusedOptionIndexRef.current += 1;
          }
          fixedSizeListRef.current?.scrollToItem(focusedOptionIndexRef.current);
          handleFocusOption();
          return;
        case 'Escape':
          e.preventDefault();
          if (showOptions) {
            setShowOptions(false);
            if (inputRef.current) inputRef.current.blur();
          }
          break;
        default:
          break;
      }
    };

    const handleBlur = async () => {
      isFocusRef.current = false;
      resetFocusedOptionIndex();

      await delay(0);
      if (!isFocusRef.current) {
        if (isSearching) {
          setIsSearching(false);
          filterOptions('');
        }
        dispatchBlurEvent();
      }
    };

    const handleFocus = () => {
      isFocusRef.current = true;
      if (!isSearching) {
        setIsSearching(true);
      }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      filterOptions(value);
      setSearchInputValue(value);
    };

    const initializeSelect = useCallback(
      async (initialValue: string) => {
        await delay(0);
        const initialOption: EmployeeOption | undefined = employeeListRef.current.find(
          (option) => option.id === initialValue,
        );

        if (initialOption && hiddenRef.current) {
          setInputValue(initialOption);
          setSelectedOption(initialOption);
        }
      },
      [setSelectedOption],
    );

    const handleHiddenInputChange = useCallback(
      (e?: React.ChangeEvent<HTMLInputElement>, passedValue?: string) => {
        const newValue = passedValue || e?.target.value || '';

        if (newValue !== hiddenInputValueRef.current || passedValue) {
          hiddenInputValueRef.current = newValue;
          const matchedOption = employeeListRef.current.find((op) => op.id === newValue);
          if (!matchedOption) {
            const shouldForceUpdate = !!selectedOptionRef.current;
            selectedOptionRef.current = '';
            setSearchInputValue('');
            setInputValue(inputValue);
            if (shouldForceUpdate) forceUpdate();
          } else {
            setSearchInputValue(matchedOption.label);
            setSelectedOption(matchedOption);
          }

          if (newValue) {
            initializeSelect(newValue);
          } else {
            initializeSelect(newValue);
            setInputValue(null);
          }
        }

        if (onChange && e && !passedValue) {
          onChange(e);
        }
      },
      [onChange, inputValue, forceUpdate, setSelectedOption, initializeSelect],
    );

    const handleHiddenInputFocus = useCallback(() => {
      if (!props.customContent && !props.focusThiefElement) {
        inputRef.current?.focus();
      }
      if (props.focusThiefElement) {
        props.focusThiefElement.focus();
      }
      setShowOptions(true);
    }, [props.customContent, props.focusThiefElement]);

    const handleHiddenInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (!isFocusRef.current) {
        if (selectedOptionRef.current) {
          const matchedOption = employeeListRef.current.find((op) => op.id === selectedOptionRef.current);
          if (matchedOption) {
            setSearchInputValue(matchedOption.label);
          }
        } else {
          setSearchInputValue('');
        }
      }

      if (onBlur) {
        onBlur(e);
      }
    };

    useEffect(() => {
      const generatedOptionList: EmployeeOption[] = [];

      if (parsedEmployees && employeesIds) {
        parsedEmployees.forEach((item) => {
          if (employeesIds.includes(item.id)) {
            return generatedOptionList.push({
              label:
                nameDisplayOrder === NameDisplayOrder.NameFirst
                  ? `${item.name.firstName} ${item.name.surname}`
                  : `${item.name.surname} ${item.name.firstName} `,
              name: item.name,
              id: item.id,
              role: item.role,
              tags: item.tags,
              avatar: item.avatarUrl,
            });
          }
          return null;
        });

        generatedOptionList.sort((a, b) => a.label.localeCompare(b.label));

        employeeListRef.current = generatedOptionList;
        setFilteredOptionsArray(generatedOptionList);
      }

      if (hiddenRef.current && generatedOptionList.length) {
        const initialValue = hiddenRef.current.value;
        if (initialValue) {
          hiddenRef.current.value = 'ONLY_TO_TRIGGER_ON_CHANGE';
          setNativeValue(hiddenRef, initialValue);
        }
      }
    }, [parsedEmployees, employeesIds, nameDisplayOrder]);

    const handleUpdateFieldView = useCallback(
      (newValue: string) => {
        handleHiddenInputChange(undefined, newValue);
      },
      [handleHiddenInputChange],
    );

    const onOwnValueChange = useCallback(
      (newValue: InputOwnValue) => {
        let parsedNewValue = newValue;
        if (_.isNil(newValue)) {
          parsedNewValue = '';
        }
        if (_.isArray(newValue)) {
          parsedNewValue = newValue.join(',');
        }
        handleUpdateFieldView(`${parsedNewValue}`);
      },
      [handleUpdateFieldView],
    );

    useOnOwnValueChange(hiddenRef, onOwnValueChange);

    const wrapperSx: ThemeUIStyleObject = useMemo(
      () => ({
        cursor: props.disabled ? 'auto' : 'pointer',
        width: props.customContent ? 'fit-content' : '100%',
        input: {
          cursor: 'inherit',
        },
      }),
      [props.customContent, props.disabled],
    );

    return (
      <>
        <input
          ref={mergeRefs([ref, hiddenRef])}
          name={name}
          onChange={handleHiddenInputChange}
          onFocus={handleHiddenInputFocus}
          onBlur={handleHiddenInputBlur}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
          readOnly
        />
        <FlexWithPopper
          onMouseDown={onMouseDown}
          onClick={onClick}
          sx={{ ...wrapperSx, ...(sx && sx) }}
          popperProps={{
            content: (
              <MemoizedOptionList
                filteredList={filteredOptionsArray}
                size={size}
                fixedSizeListRef={fixedSizeListRef}
                innerRef={optionsListRef}
                selectedOption={selectedOptionRef.current}
                onOptionClick={onOptionClick}
              />
            ),
            placement: 'bottom-start',
            trigger: 'manual',
            popperMargin: -0.3,
            widthLikeReferenceElement: true,
            onOutsideClick,
            visible: showOptions && !props.disabled,
          }}
        >
          <TextInput
            {...props}
            clearable
            usedAsDisplay
            controllerHasValue={!!hiddenRef.current?.value}
            size={size}
            ref={inputRef}
            type="text"
            autoComplete="off"
            error={error}
            errorMessage={errorMessage}
            onKeyUp={handleKeyUp}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onChange={handleOnChange}
            onClearCallback={onClearCallback}
            inputProps={{
              readOnly: false,
              ...(props.inputProps && props.inputProps),
              sx: {
                ...(props.inputProps?.sx && props.inputProps.sx),
              },
              value: prepareSearchInputValue(),
            }}
            prependWith={
              inputValue ? (
                <Avatar size={AvatarSizes[size]} circle name={{ ...inputValue.name }} image={inputValue.avatar} />
              ) : (
                <Avatar size={AvatarSizes[size]} circle />
              )
            }
            apendWith={<Icon type={showOptions ? 'chevronUp' : 'chevronDown'} wrapperSx={{ cursor: 'pointer' }} />}
          />
        </FlexWithPopper>
      </>
    );
  },
);
