import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Text, Flex } from 'theme-ui';
import { useMutation } from 'react-fetching-library';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { PATH } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { AvatarInfo, UserProfile } from '../forms/UserProfile';
import { UserEmploymentDetails } from '../forms/UserEmploymentDetails';
import { UserAdvancedDetails } from '../forms/UserAdvancedDetails';
import { ModalRoute } from 'routing/ModalRoute';
import { addEmployeeAction, fetchTagInheritedFeaturesAction } from 'api/actions/employees/employeesActions';
import { createEvent } from 'utils/createEvent';
import { EmployeeEmploymentDetailsFormState, ProfileFormState } from 'state/team';
import {
  AddEmployeeActionProps,
  AdvancedEmployeeInfo,
  FetchTagInheritedFeaturesResponse,
} from 'api/actions/employees/employeesActions.types';
import { Modal } from 'components/Modal/Modal';
import { useModal } from 'hooks/useModal/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { DefaultRole } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Switch } from 'components/ui/Switch';
import { useTheme } from 'styles/useTheme';
import { useIsMountedRef } from 'hooks/useIsMountedRef/useIsMountedRef';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { ManageTagsModal } from './ManageTags';
import { AddTagModal } from './AddTag';
import { ManageRolesModal } from './ManageRoles';
import { ManageWorkPositionsModal } from './ManageWorkPositions';

export const AddTeammateModal = (): React.ReactElement => {
  useLingui();
  const { theme } = useTheme();
  const [advancedMode, setAdvancedMode] = useState(false);
  const advancedModeRef = useRef(advancedMode);
  advancedModeRef.current = advancedMode;
  const { addSnackbar } = useSnackbar();

  const { handleClose } = useModal({
    ...(advancedMode && { wrapperSx: { ...theme.modal.lg, maxHeight: '90%' }, fullHeight: true }),
  });
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  const language = useRecoilValue(languageSelector);
  const [loading, setLoading] = useState(false);
  const isAddingTeammateRef = useRef(false);
  const isMountedRef = useIsMountedRef();

  const [userProfileKey, setUserProfileKey] = useState('userProfileKey');
  const [userEmploymentDetailsKey, setUserEmploymentDetailsKey] = useState('userEmploymentDetailsKey');
  const [userAdvancedDetailsKey, setUserAdvancedDetailsKey] = useState('userAdvancedDetailsKey');

  const [tagInheritedFeaturesResponse, setTagInheritedFeaturesResponse] =
    useState<FetchTagInheritedFeaturesResponse | null>(null);

  const noTagsTagInheritedFeaturesResponseRef = useRef<FetchTagInheritedFeaturesResponse | null>(null);
  const [profileFormState, setProfileFormState] = useState<ProfileFormState | null>(null);
  const [advancedDetailsFormState, setAdvancedDetailsFormState] = useState<AdvancedEmployeeInfo | null>(null);
  const [employmentDetailsFormState, setEmploymentDetailsFormState] =
    useState<Partial<EmployeeEmploymentDetailsFormState> | null>(null);

  const [fullEmployeeInfo, setFullEmployeeInfo] = useState<AddEmployeeActionProps | null>(null);

  const isFetchingTagInheritedFeaturesRef = useRef(false);

  const [advancedDetailsSavedFormState, setAdvancedDetailsSavedFormState] = useState<AdvancedEmployeeInfo | null>(null);
  const [employmentDetailsSavedFormState, setEmploymentDetailsSavedFormState] =
    useState<Partial<EmployeeEmploymentDetailsFormState> | null>(null);
  const [savedAvatarInfo, setSavedAvatarInfo] = useState<AvatarInfo | null>(null);

  const resetAfterSubmitRef = useRef(false);
  const selectedTagIdsRef = useRef<string[] | null>(null);

  const profileFormRef = useRef<HTMLFormElement | null>(null);
  const advancedDetailsFormRef = useRef<HTMLFormElement | null>(null);
  const employmentDetailsFormRef = useRef<HTMLFormElement | null>(null);

  const { mutate } = useMutation(addEmployeeAction);
  const { mutate: fetchTagInheritedFeatures } = useMutation(fetchTagInheritedFeaturesAction);

  const submitForm = (form: HTMLFormElement | null) => {
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const getTagInheritedFeatures = useCallback(
    async (tagsIds: ProfileFormState['tagsIds']) => {
      isFetchingTagInheritedFeaturesRef.current = true;
      setLoading(true);
      const { payload, error } = await fetchTagInheritedFeatures({ tagsIds });
      if (!error && payload) {
        setTagInheritedFeaturesResponse(payload);
        if (!tagsIds.length) {
          noTagsTagInheritedFeaturesResponseRef.current = payload;
        }
      }
      isFetchingTagInheritedFeaturesRef.current = false;
      setLoading(false);
    },
    [fetchTagInheritedFeatures],
  );

  const onTagsIdsBlur = useCallback(
    async (tagsIds: string[]) => {
      if (!isMountedRef.current) return;
      getTagInheritedFeatures(tagsIds);
    },
    [getTagInheritedFeatures, isMountedRef],
  );

  useEffect(() => {
    if (!tagInheritedFeaturesResponse) {
      getTagInheritedFeatures([]);
    }
  }, [getTagInheritedFeatures, tagInheritedFeaturesResponse]);
  useEffect(() => {
    const basicInfo = _.omit(profileFormState, [
      'postalCode',
      'city',
      'street',
      'phoneNumber',
      'email',
      'note',
      'firstName',
      'surname',
    ]);
    if (advancedModeRef.current && profileFormState && advancedDetailsFormState && employmentDetailsFormState) {
      setFullEmployeeInfo({
        ...basicInfo,
        employeeInfo: {
          ..._.pick(profileFormState, ['phoneNumber', 'email', 'note', 'firstName', 'surname']),
          address: _.pick(profileFormState, ['postalCode', 'city', 'street']),
        },
        advancedEmployeeInfo: {
          ...advancedDetailsFormState,
          customEmployeeId: employmentDetailsFormState.customEmployeeId,
        },
        rates: employmentDetailsFormState.rates || [],
        timeOffLimits: employmentDetailsFormState.timeOffLimits || [],
        workdayDurations: employmentDetailsFormState.workdayDurations || [],
        customRequestsLimits: employmentDetailsFormState.customRequestsLimits || [],
      });
    }

    if (!advancedModeRef.current && profileFormState) {
      setFullEmployeeInfo({
        ...basicInfo,
        employeeInfo: {
          ..._.pick(profileFormState, ['phoneNumber', 'email', 'firstName', 'surname']),
        },
      });
    }
  }, [profileFormState, advancedDetailsFormState, employmentDetailsFormState]);

  useEffect(() => {
    const addEmployee = async () => {
      isAddingTeammateRef.current = true;
      if (fullEmployeeInfo) {
        // console.log({
        //   ...fullEmployeeInfo,
        //   avatar: savedAvatarInfo?.avatarBlob || undefined,
        // });
        const { error: submitError } = await mutate({
          ...fullEmployeeInfo,
          avatar: savedAvatarInfo?.avatarBlob || undefined,
        });
        setProfileFormState(null);
        if (!submitError) {
          if (handleClose && !resetAfterSubmitRef.current) {
            handleClose();
          }
        }
        if (resetAfterSubmitRef.current) {
          resetAfterSubmitRef.current = false;
          selectedTagIdsRef.current = null;
          setTagInheritedFeaturesResponse(noTagsTagInheritedFeaturesResponseRef.current);
          setProfileFormState(null);
          setAdvancedDetailsFormState(null);
          setEmploymentDetailsFormState(null);
          setEmploymentDetailsSavedFormState(null);
          setAdvancedDetailsSavedFormState(null);
          setFullEmployeeInfo(null);
          setSavedAvatarInfo(null);
          setUserProfileKey(_.uniqueId());
          setUserEmploymentDetailsKey(_.uniqueId());
          setUserAdvancedDetailsKey(_.uniqueId());
          modalBodyRef.current?.scrollTo(0, 0);
        }
        setLoading(false);
        if (!submitError) {
          addSnackbar({
            message: t({ id: 'team.add_teammate.added', message: 'Employee successfully added!' }),
            variant: 'success',
          });
        }
      }
      isAddingTeammateRef.current = false;
    };

    if (fullEmployeeInfo && !isAddingTeammateRef.current) {
      addEmployee();
    }
  }, [fullEmployeeInfo, handleClose, mutate, savedAvatarInfo?.avatarBlob, addSnackbar]);

  const onProfileSubmit = useCallback(
    async (body: ProfileFormState, avatarInfo?: AvatarInfo): Promise<boolean> => {
      setProfileFormState(body);

      if (avatarInfo) {
        setSavedAvatarInfo(avatarInfo);
      }
      return true;
    },
    [setProfileFormState],
  );

  const onAdvancedDetailsSubmit = useCallback(
    async (body: AdvancedEmployeeInfo): Promise<boolean> => {
      setAdvancedDetailsFormState(body);
      return true;
    },
    [setAdvancedDetailsFormState],
  );

  const onEmploymentDetailsSubmit = useCallback(
    async (body: Partial<EmployeeEmploymentDetailsFormState>): Promise<boolean> => {
      setEmploymentDetailsFormState(body);
      return true;
    },
    [setEmploymentDetailsFormState],
  );

  const handleSave = () => {
    setEmploymentDetailsFormState(null);
    setAdvancedDetailsFormState(null);
    setProfileFormState(null);
    setLoading(true);
    submitForm(profileFormRef.current);
    submitForm(advancedDetailsFormRef.current);
    submitForm(employmentDetailsFormRef.current);
  };

  const handleSaveAndAdd = () => {
    resetAfterSubmitRef.current = true;
    handleSave();
  };

  const handleAdvancedModeChange = (checked: boolean) => {
    setAdvancedMode(checked);
    if (checked && !tagInheritedFeaturesResponse) {
      getTagInheritedFeatures([]);
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title sx={{ justifyContent: 'space-between' }}>
          <>
            <Text sx={{ whiteSpace: 'nowrap' }}>
              <Trans id="team.add_teammate">Add teammate</Trans>
            </Text>
            <Switch
              onChange={(e) => handleAdvancedModeChange(e.target.checked)}
              bold
              placement="right"
              size="sm"
              name="advanced"
              label={t({ id: 'team.add_teammate.advanced_mode', message: 'Advanced mode' })}
              wrapperSx={{ width: 'auto', whiteSpace: 'nowrap', gap: 2 }}
            />
          </>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modalBodyRef}>
        <UserProfile
          key={userProfileKey}
          hideHeader={!advancedMode}
          horizontal
          onTagsIdsBlur={onTagsIdsBlur}
          fullContactInfo={advancedMode}
          avatarHasChanged={!!(savedAvatarInfo && savedAvatarInfo.changed)}
          avatarUrl={!loading ? savedAvatarInfo?.avatarUrl : undefined}
          setLoading={setLoading}
          ref={profileFormRef}
          defaultValues={profileFormState || { roleId: `${DefaultRole.Employee}` }}
          onSubmit={onProfileSubmit}
        />
        {advancedMode && (
          <Flex sx={{ gap: 5, mt: 4 }}>
            <Flex sx={{ width: '50%' }}>
              <UserEmploymentDetails
                scrollParentRef={modalBodyRef}
                key={userEmploymentDetailsKey}
                onSubmit={onEmploymentDetailsSubmit}
                setLoading={setLoading}
                defaultValues={employmentDetailsSavedFormState || { rates: [], timeOffLimits: [] }}
                saveFormState={setEmploymentDetailsSavedFormState}
                ref={employmentDetailsFormRef}
              />
            </Flex>
            {tagInheritedFeaturesResponse && (
              <Flex sx={{ width: '50%' }}>
                <UserAdvancedDetails
                  key={userAdvancedDetailsKey}
                  shouldReactToTagInheritedFeaturesChanged
                  onSubmit={onAdvancedDetailsSubmit}
                  setLoading={setLoading}
                  tagInheritedFeatures={tagInheritedFeaturesResponse || undefined}
                  defaultValues={advancedDetailsSavedFormState || undefined}
                  saveFormState={setAdvancedDetailsSavedFormState}
                  ref={advancedDetailsFormRef}
                />
              </Flex>
            )}
          </Flex>
        )}
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            disabled: loading,
            onClick: handleSaveAndAdd,
            variant: 'minimal',
            children: t({ id: 'team.add_teammate.save_add', message: 'Save & add next' }),
          },
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />

      <ModalRoute size="sm" path={PATH.ADD_TAG_MODAL[language]}>
        <AddTagModal />
      </ModalRoute>
      <ModalRoute path={PATH.MANAGE_TAGS_MODAL[language]} fullHeight>
        <ManageTagsModal />
      </ModalRoute>
      <ModalRoute path={PATH.MANAGE_ROLES_MODAL[language]} fullHeight>
        <ManageRolesModal />
      </ModalRoute>
      <ModalRoute path={PATH.MANAGE_WORK_POSITIONS_MODAL[language]} fullHeight>
        <ManageWorkPositionsModal />
      </ModalRoute>
    </>
  );
};
