import { ThemeUIStyleObject } from 'theme-ui';

export const disconnectSx: ThemeUIStyleObject = {
  color: 'reds5',
  cursor: 'pointer',
  '&:hover': {
    color: 'reds4',
  },
};

export const expiredSx: ThemeUIStyleObject = {
  color: 'yellows5',
  cursor: 'pointer',
  '&:hover': {
    color: 'yellows4',
  },
};
