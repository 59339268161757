import React from 'react';
import { Flex } from 'theme-ui';

import { Button } from '../Buttons';

type Props = {
  children: React.ReactNode;
  variant: 'warning' | 'info' | 'danger';
  action?: { title: string; onClick: () => void };
};

const defaultProps: Partial<Props> = { action: undefined };

export const Alert = ({ variant, action, children }: Props): React.ReactElement => (
  <Flex variant={`alerts.${variant}`}>
    {children}
    {action && (
      <Button onClick={action.onClick} shape="rounded" variant="grey" sx={{ ml: 'auto' }}>
        {action.title}
      </Button>
    )}
  </Flex>
);
Alert.defaultProps = defaultProps;
