import React, { useContext, useEffect } from 'react';
import { ClientContext, useQuery } from 'react-fetching-library';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useHistory } from 'react-router';

import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { fetchOrganizationSessionAction } from 'api/actions/organizationSession/organizationSessionActions';
import { organizationSessionAtom } from 'state/organizationSession';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { setUnauthorized, signOut } from 'context/auth/authActionCreators/authActionCreators';
import { userSessionAtom } from 'state/userSession';
import { authStorage } from 'context/auth/authStorage/AuthStorage';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { signOutAction } from 'api/actions/auth/authActions';

import { OrganizationSessionInitializerProps } from './OrganizationSessionInitializer.types';
import { getParsedOrganizationSession } from './getParsedOrganizationSession';

export const OrganizationSessionInitializer = ({
  children,
}: OrganizationSessionInitializerProps): React.ReactElement => {
  const [, setOrganizationSession] = useRecoilState(organizationSessionAtom);
  const resetUserSessionState = useResetRecoilState(userSessionAtom);
  const resetOrganizationSessionState = useResetRecoilState(organizationSessionAtom);

  const dispatch = useAuthDispatch();
  const { query } = useContext(ClientContext);

  const { replace } = useHistory();

  const { loading, payload, error } = useQuery(fetchOrganizationSessionAction());

  useEffect(() => {
    if (!error && payload) {
      const parsedOrganizationSession = getParsedOrganizationSession(payload);

      setOrganizationSession(parsedOrganizationSession);
    }

    // !! FAILSAFE !!
    // SIGN-OUT FOR ABANDONED SIGN-UPS
    //
    if (error && payload?.innerCode === ErrorInnerCodes.NoPermissions) {
      authStorage.accessToken = null;
      resetUserSessionState();
      resetOrganizationSessionState();
      dispatch(signOut());
      dispatch(setUnauthorized());
      query(signOutAction());
      setTimeout(() => {
        localStorage.clear();
      }, 0);

      replace('/');
    }
  }, [
    dispatch,
    error,
    payload,
    query,
    replace,
    resetOrganizationSessionState,
    resetUserSessionState,
    setOrganizationSession,
  ]);

  if (loading || error) {
    return <LoadingSplash />;
  }

  return <>{children}</>;
};
