import React from 'react';
import { Text, Heading, Flex, Image } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { i18n } from '@lingui/core';

import { Button } from 'components/ui/Buttons/Button';
import { Tag } from 'components/Tag/Tag';
import { getSrcSet } from 'utils/getSrcSet';

type Props = {
  heading: string;
  description: string;
  isActive: boolean;
  image: string;
  connectButton: {
    text: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };
  disconnectButton: {
    text: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };
};

export const ExternalIntegration = ({
  heading,
  description,
  isActive,
  image,
  connectButton,
  disconnectButton,
}: Props): React.ReactElement => {
  useLingui();

  return (
    <Flex
      sx={{
        bg: 'whites0',
        flexWrap: 'wrap',
        flexShrink: 0,
        width: '300px',
        minHeight: '180px',
        overflow: 'hidden',
        borderRadius: 'default',
        p: 3,
        boxShadow: '0 2px 8px rgba(42, 65, 111, 0.04)',
        ...(isActive && {
          position: 'relative',
          boxSizing: 'border-box',
          border: '2px solid',
          borderColor: 'greens4',
        }),
      }}
    >
      {isActive && (
        <Tag
          title={i18n._(t({ id: 'enabled' }))}
          variant="solid"
          color="greens4"
          sx={{
            position: 'absolute',
            top: 3,
            right: 3,
            fontSize: 0,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'greens4',
            bg: 'greens4',
            color: 'rgba(14, 23, 55, 0.6)',
          }}
        >
          <Trans id="enabled" />
        </Tag>
      )}
      <Flex sx={{ flexDirection: 'column' }}>
        <Image sx={{ alignSelf: 'flex-start' }} src={image} srcSet={getSrcSet(image)} alt={heading} />

        <Heading variant="heading4" sx={{ mb: [0, 0, 0] }}>
          {heading}
        </Heading>

        <Text sx={{ fontSize: 2 }}>{description}</Text>
      </Flex>
      <Flex sx={{ mt: 'auto', flexBasis: '100%' }}>
        {connectButton && !isActive && (
          <Button variant="success" size="sm" shape="rounded" onClick={connectButton.onClick}>
            {connectButton.text}
          </Button>
        )}
        {disconnectButton && isActive && (
          <Button
            variant="naked"
            onClick={disconnectButton.onClick}
            size="sm"
            sx={{ color: 'reds5', cursor: 'pointer' }}
          >
            {disconnectButton.text}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
