import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';

import { PermissionState, permissionStateAtom } from 'Kiosk/state/permissionState';
import { languageSelector } from 'state/recoilState';
import { TO } from 'constants/routes';

type ReturnValue = {
  camera: PermissionState;
  location: PermissionState;
  showCameraRequiredSplash: () => void;
};

export const usePermissions = (): ReturnValue => {
  const { camera, location } = useRecoilValue(permissionStateAtom);
  const language = useRecoilValue(languageSelector);

  const { push } = useHistory();

  const showCameraRequiredSplash = () => {
    push(`${TO.KIOSK__CAMERA_PERMISSION[language]}`);
  };

  return {
    camera: camera || PermissionState.checking,
    location,
    showCameraRequiredSplash,
  };
};
