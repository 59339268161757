import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

type Props = {
  size: number;
  faceInPlace: boolean;
};

export const FaceBoundingBackground = React.forwardRef<HTMLDivElement, Props>(({ size, faceInPlace }: Props, ref) => {
  const sx: ThemeUIStyleObject = {
    position: 'absolute',
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    size: faceInPlace ? `${size}px` : `100%`,
    borderRadius: faceInPlace ? 'circle' : '0',
    zIndex: 2,
    boxShadow: `0 0 0 1000px black`,
    bg: 'transparent',
    transition: 'all linear 0.25s',
    transform: 'translate3d(0, 0, 0)',
  };

  return <Box ref={ref} sx={sx} />;
});
