import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { fetchUserInfoAction } from 'api/actions/settings/settingsActions';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { ProfileForm } from 'layouts/Settings/User/forms/ProfileForm';
import { resetFormButtonAtom, resetFormButtonCallbacksSelector } from 'state/settings';

export const Profile = (): React.ReactElement => {
  useLingui();
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);
  const { payload } = useQuery(fetchUserInfoAction());

  const handleResetButtonClick = useCallback(() => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  }, [formResetCallbacks, setResetButtonCallbacks]);

  return (
    <>
      <HeadingTip
        label={t({
          id: 'settings.user.profile.header',
          message: 'Your Profile',
        })}
        displayReset={!!formResetCallbacks}
        displayOnClick={() => handleResetButtonClick()}
      />
      {payload ? (
        <ProfileForm userInfo={payload} />
      ) : (
        <Flex
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            minHeight: '400px',
          }}
        >
          <LoadingSpinnerCss size={4} />
        </Flex>
      )}
    </>
  );
};
