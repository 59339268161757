import { Flex, Text } from 'theme-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation } from 'react-fetching-library';
import key from 'weak-key';
import dayjs from 'dayjs';
import { Trans } from '@lingui/macro';

import { MemoizedMessage } from 'chat/ChatWindow/Message';
import {
  sortedMessagesSelector,
  parsedMessagesWithPersonalDataSelector,
  removeExcessMessagesSelector,
} from 'state/chat';
import { fetchMessagesAction } from 'api/actions/chat/chatActions';
import { ChatUserForFetch, MessageForFetch } from 'api/actions/chat/chatActions.types';
import { MESSAGES_PART_SIZE } from '../constans';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { dateFormatSelector } from 'state/recoilState';
import { mergeRefs } from 'utils/mergeRefs';

type Props = {
  chatWindowId: ChatUserForFetch['sender'];
};

export const MessagesList = React.forwardRef<HTMLInputElement, Props>(
  ({ chatWindowId }: Props, ref): React.ReactElement | null => {
    const [partMessagesCounter, setPartMessagesCounter] = useState<number>(1);
    const messagesRef = useRef<HTMLDivElement | null>(null);

    const shouldFetchMessages = useRef<boolean>(true);

    const setFetchedMessages = useSetRecoilState(parsedMessagesWithPersonalDataSelector(chatWindowId));
    const removeExcessMessages = useSetRecoilState(removeExcessMessagesSelector);
    const messages = useRecoilValue(sortedMessagesSelector(chatWindowId));
    const dateFormat = useRecoilValue(dateFormatSelector);

    const { mutate: fetchMessagesMutate, loading } = useMutation(fetchMessagesAction);

    const fetchMessages = useCallback(async () => {
      if (shouldFetchMessages.current) {
        const { error, payload } = await fetchMessagesMutate({ id: chatWindowId, part: partMessagesCounter });
        if (!error && payload) {
          setFetchedMessages(payload);
          setPartMessagesCounter(partMessagesCounter + 1);
          if (payload.length < MESSAGES_PART_SIZE) {
            shouldFetchMessages.current = false;
          }
        }
      }
    }, [fetchMessagesMutate, partMessagesCounter, setFetchedMessages, chatWindowId]);

    useEffect(() => {
      if (!messages) {
        fetchMessages();
      }
      return () => setPartMessagesCounter((v) => v);
    }, [fetchMessages, messages]);

    useEffect(
      () => () => {
        removeExcessMessages(chatWindowId);
      },
      [chatWindowId, removeExcessMessages],
    );

    const fetchMessagesWithScroll = useCallback(async () => {
      if (messagesRef.current && !loading) {
        const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;

        if (scrollTop < 0 && (scrollTop - clientHeight) * -1 === scrollHeight - 1) {
          await fetchMessages().then(() => messagesRef?.current?.scrollTo(0, scrollTop));
        }
      }
    }, [fetchMessages, loading]);

    const prepareDate = useCallback(
      (date: MessageForFetch['createdUnix']) => {
        if (dayjs.unix(date).format(dateFormat) === dayjs().format(dateFormat)) {
          return <Trans id="calendar.today" />;
        }

        if (dayjs.unix(date).format(dateFormat) === dayjs().add(-1, 'day').format(dateFormat)) {
          return <Trans id="chat.messageList.divider.yesterday">Yesterday</Trans>;
        }

        if (dayjs.unix(date).year() === dayjs().year()) {
          return dayjs.unix(date).format('D MMMM');
        }

        return dayjs.unix(date).format(dateFormat);
      },
      [dateFormat],
    );

    const displayDivider = useCallback(
      (date: MessageForFetch['createdUnix'], nextDate: MessageForFetch['createdUnix']) => {
        if (!nextDate || dayjs.unix(date).format(dateFormat) === dayjs.unix(nextDate).format(dateFormat)) return <></>;

        return (
          <Flex as="time" variant="chat.messagesList.dayDivider.container">
            <Text variant="chat.messagesList.dayDivider.text">{prepareDate(date)}</Text>
          </Flex>
        );
      },
      [dateFormat, prepareDate],
    );

    return (
      <Flex
        ref={mergeRefs([ref, messagesRef])}
        variant="chat.messagesList.container"
        onScroll={fetchMessagesWithScroll}
      >
        {messages?.[0] && (
          <>
            {messages.map((m, i, a) => (
              <React.Fragment key={key(m)}>
                <MemoizedMessage {...m} chatWindowId={chatWindowId} />
                {displayDivider(m.createdUnix, a[i + 1]?.createdUnix)}
              </React.Fragment>
            ))}

            {!loading && (
              <Flex as="time" variant="chat.messagesList.dayDivider.container">
                <Text variant="chat.messagesList.dayDivider.text">
                  {prepareDate(messages[messages.length - 1].createdUnix)}
                </Text>
              </Flex>
            )}
          </>
        )}
        {loading && (
          <Flex variant="chat.window.spinnerContainer">
            <LoadingSpinnerCss />
          </Flex>
        )}
      </Flex>
    );
  },
);
