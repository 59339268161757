import React from 'react';
import { FlexProps, Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui';

import { Button } from 'components/ui/Buttons/Button';

type Props = FlexProps & {
  label: string;
  additionalInfo?: React.ReactNode;
  additionalInfoSx?: ThemeUIStyleObject;
  displayReset?: boolean;
  displayOnClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const defaultProps = {
  additionalInfo: undefined,
  additionalInfoSx: undefined,
  displayReset: false,
  displayOnClick: undefined,
};

export const HeadingTip = ({
  label,
  additionalInfo,
  variant = 'heading3',
  sx,
  additionalInfoSx,
  displayReset,
  displayOnClick,
  ...props
}: Props): React.ReactElement => (
  <Flex sx={{ ...(sx && sx), flexGrow: 1, gap: 2, alignItems: 'flex-start' }} {...props}>
    <Flex sx={{ flexGrow: 1, flexDirection: 'column' }}>
      <Heading variant={variant} sx={{ flexGrow: 1 }}>
        {label}
      </Heading>
      {additionalInfo && (
        <Text
          sx={{
            display: 'block',
            color: 'texts.lighter',
            ...(additionalInfoSx && additionalInfoSx),
          }}
        >
          {additionalInfo}
        </Text>
      )}
    </Flex>
    {displayReset && (
      <Button variant="grey" size="sm" shape="rounded" onClick={displayOnClick}>
        Reset
      </Button>
    )}
  </Flex>
);

HeadingTip.defaultProps = defaultProps;
