import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';

import { requestDownloadAction, requestPrintAction } from 'api/actions/requests/requestsActions';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Button, LinkButton } from 'components/ui/Buttons';
import { TO } from 'constants/routes';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { Main } from 'layouts/AuthorizedApp';
import { languageSelector } from 'state/recoilState';
import { selectedRequestsIdsSelector } from 'state/requests';
import { zusManualImportAction } from 'api/actions/zus/zusActions';

const ButtonWithDropdown = withDropdown(Button);

export const RequestsHeaderMenu = (): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const selectedRequestsIds = useRecoilValue(selectedRequestsIdsSelector);
  const language = useRecoilValue(languageSelector);
  const match = useCleanRouteMatch();
  const { mutate: mutatePrintRequests } = useMutation(requestPrintAction);
  const { mutate: mutateDownloadRequests } = useMutation(requestDownloadAction);
  const { mutate: mutateImportZus } = useMutation(zusManualImportAction);

  const checkIfRequestsSelected = useCallback(() => {
    if (!selectedRequestsIds || _.isEmpty(selectedRequestsIds)) {
      addSnackbar({
        message: t({ id: 'requests.selection_error', message: 'No requests selected' }),
        variant: 'default',
      });
      return false;
    }

    return true;
  }, [addSnackbar, selectedRequestsIds]);

  const handlePrintOnClick = useCallback(async () => {
    const requestsSelected = checkIfRequestsSelected();

    if (!requestsSelected) return;

    const { error } = await mutatePrintRequests(selectedRequestsIds);

    if (error) {
      addSnackbar({
        message: t({ id: 'error.something_went_wrong' }),
      });
    }
  }, [addSnackbar, checkIfRequestsSelected, mutatePrintRequests, selectedRequestsIds]);

  const handleDownloadOnClick = useCallback(async () => {
    const requestsSelected = checkIfRequestsSelected();

    if (!requestsSelected) return;

    const { error } = await mutateDownloadRequests(selectedRequestsIds);

    if (error) {
      addSnackbar({
        message: t({ id: 'error.something_went_wrong' }),
      });
    }
  }, [addSnackbar, checkIfRequestsSelected, mutateDownloadRequests, selectedRequestsIds]);

  const handleZusImport = useCallback(async () => {
    const { error } = await mutateImportZus({});

    if (error) {
      addSnackbar({
        message: t({ id: 'error.something_went_wrong' }),
      });
    }
  }, [addSnackbar, mutateImportZus]);

  return (
    <Main.Header.Menu>
      <Main.Header.Menu.ButtonsGroup>
        <LinkButton
          size="sm"
          variant="primary"
          shape="rounded"
          prependWith={<Icon type="plus" />}
          to={`${match}${TO.ADD_REQUEST[language]}`}
        >
          <Trans id="requests.add_request" />
        </LinkButton>
        <Button
          size="sm"
          variant="grey"
          shape="rounded"
          prependWith={<Icon type="download" />}
          onClick={handleDownloadOnClick}
        >
          <Trans id="global.download" />
        </Button>
        <Button
          size="sm"
          variant="grey"
          shape="rounded"
          prependWith={<Icon type="print" />}
          onClick={handlePrintOnClick}
        >
          <Trans id="global.print" />
        </Button>
      </Main.Header.Menu.ButtonsGroup>
      <ButtonWithDropdown
        dropdownProps={{
          links: [
            {
              label: t({ id: 'requests.import_ezla', message: 'Import from eZLA' }),
              icon: 'import',
              onClick: () => {
                handleZusImport();
              },
            },
            {
              prependWithDivider: true,
              label: t({ id: 'requests.request_overview' }),
              icon: 'info',
              to: {
                pathname: `${match}${TO.REQUEST_OVERVIEW_MODAL[language]}`,
              },
            },
          ],
        }}
        shape="rounded"
        size="sm"
        variant="grey"
      >
        <Icon type="more" />
      </ButtonWithDropdown>
    </Main.Header.Menu>
  );
};
