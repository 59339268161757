import React, { useReducer, useEffect } from 'react';

import { authStorage } from '../authStorage/AuthStorage';
import { AuthDispatchContext, AuthStateContext } from '../authContext/AuthContext';
import { authReducer } from '../authReducer/authReducer';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps): React.ReactElement => {
  const [state, dispatch] = useReducer(authReducer, {
    errorMessage: null,
    isAuthorized: false,
    isAuthorizing: false,
    isSignUpInProgress: false,
    user: undefined,
    accessToken: authStorage.accessToken,
  });

  useEffect(() => {
    authStorage.accessToken = state.accessToken;
  }, [state.accessToken, state]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
