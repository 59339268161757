/** @jsxImportSource theme-ui */

import { Label, Flex } from 'theme-ui';
import React, { InputHTMLAttributes } from 'react';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  size?: 'xs' | 'sm' | 'default';
};

const defaultProps: Partial<Props> = {
  size: 'default',
};

export const Radio = React.forwardRef<HTMLInputElement, Props>(({ size, ...props }: Props, ref) => (
  <Label
    sx={{
      cursor: props.disabled ? 'not-allowed' : 'pointer',
      userSelect: 'none',
    }}
  >
    <input ref={ref} type="radio" sx={{ left: '0', top: '0', opacity: '0', position: 'absolute' }} {...props} />
    <Flex variant={`forms.radio.${size}`} />
  </Label>
));

Radio.defaultProps = defaultProps;
