import { Flex } from 'theme-ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import weakKey from 'weak-key';

import { Checkbox } from 'components/ui/Checkbox';
import { selectAllSelectorFamily } from 'state/list';

import { ColumnHeader } from './ColumnHeader';
import { HeaderProps, ListColumn } from './types';
import { Cell } from './Cell';
import { ITEM_DEFAULT_WIDTH } from './constants';

export const Header = ({
  select,
  columns,
  listName,
  variant,
  type,
  sx: wrapperSx,
  ...props
}: HeaderProps): React.ReactElement => {
  const [globalSelect, setGlobalSelect] = useRecoilState(selectAllSelectorFamily(listName));

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalSelect(e.target.checked);
  };

  const columnRenderer = (listColumn: ListColumn, index: number): React.ReactNode => {
    const { title, sx, width, columnGrow, sortableValue, key, customHeaderRenderer } = listColumn;
    const uniqueKey = weakKey(listColumn);

    const getWidth = () => {
      let innerWidth = width;

      if (type === 'grid') {
        innerWidth = width || ITEM_DEFAULT_WIDTH;

        if (select === 'checkbox' && index === 0) {
          return parseInt(`${innerWidth}`, 10) - 42;
        }
      }

      return innerWidth;
    };

    return (
      <Cell
        key={uniqueKey}
        sx={{
          overflow: 'visible',
          ...sx,
        }}
        width={getWidth()}
        columnGrow={columnGrow}
      >
        <ColumnHeader
          columnKey={key}
          isSortable={!!sortableValue}
          listName={listName}
          hasCustomRenderer={!!customHeaderRenderer}
        >
          {customHeaderRenderer ? customHeaderRenderer(title) : title}
        </ColumnHeader>
      </Cell>
    );
  };

  return (
    <Flex variant={`stickyList.header.${variant}.${type}`} {...props} sx={wrapperSx}>
      {select && (
        <Cell
          width={type === 'list' ? '30px' : '42px'}
          sx={{
            ...(type === 'grid' && {
              pl: '0.75rem',
            }),
          }}
        >
          {select === 'checkbox' && (
            <Checkbox onChange={handleCheckboxChange} checked={globalSelect} name="rowCheckbox" size="sm" />
          )}
        </Cell>
      )}

      {columns.map(columnRenderer)}
    </Flex>
  );
};

export const MemorizedHeader = React.memo(Header);
