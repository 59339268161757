import { DayOfWeek } from '../organizationSession/organizationSessionActions.types';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { TimeZoneListItem } from '../data/dataActions.types';
import { DateFormat, NameDisplayOrder } from '../userSession/userSessionActions.types';

export type FetchScheduleSettingsResponse = {
  showLaborCodeAlerts: boolean;
  showVersionControl: boolean;
};

export type ScheduleSettingsActionProps = FetchScheduleSettingsResponse;

export type ScheduleSettingsResponse = null;

export type FetchNotificationSettingsResponse = {
  schedulesEmails: boolean;
  schedulesTextMessages: boolean;
  requestsEmails: boolean;
  requestsTextMessages: boolean;
  messagesEmails: boolean;
  promotionsEmails: boolean;
};

export type NotificationSettingsActionProps = FetchNotificationSettingsResponse;

export type NotificationSettingsResponse = null;

export type AdvancedSettingsCommonActionProps = {
  password: string;
};

export type AdvancedSettingsCommonResponse = null;

export type AdvancedSettingsDeletePhotosActionProps = AdvancedSettingsCommonActionProps;

export type AdvancedSettingsDeletePhotosResponse = AdvancedSettingsCommonResponse;

export type AdvancedSettingsDeleteFaceModelsActionProps = AdvancedSettingsCommonActionProps;

export type AdvancedSettingsDeleteFaceModelsResponse = AdvancedSettingsCommonResponse;

export type AdvancedSettingsDeleteOrganizationActionProps = {
  password: string;
  reason: string;
};

export type AdvancedSettingsDeleteOrganizationResponse = AdvancedSettingsCommonResponse;

export type FetchTimeTrackingSettingsResponse = {
  showPaidBreak: boolean;
  limitPaidBreak: boolean;
  paidBreakLimitSeconds?: number;
  showUnPaidBreak: boolean;
  enableFaceVerification: boolean;
  restrictLocations: boolean;
  enableOfflineMode: boolean;
  sendEventPhotos: boolean;
  allowTimeRounding: boolean;
  roundToMinutes: MagnetInterval;
  minOvertimeSeconds: number;
  autoBalanceOvertime: boolean;
  payrollPeriod: NormalPayrollPeriod;
  overtimePeriod: OvertimePayrollPeriod;
  typeOfWorktimeWithoutSchedule: WorktimeWithoutSchedule;
  countNightHours: boolean;
  nightHoursStartSeconds: number;
  nightHoursDurationSeconds: number;
};

export type TimeClocksSettingsActionProps = Pick<
  FetchTimeTrackingSettingsResponse,
  | 'showPaidBreak'
  | 'limitPaidBreak'
  | 'paidBreakLimitSeconds'
  | 'showUnPaidBreak'
  | 'enableFaceVerification'
  | 'restrictLocations'
  | 'enableOfflineMode'
  | 'sendEventPhotos'
  | 'allowTimeRounding'
  | 'roundToMinutes'
>;

export type OvertimeSettingsActionProps = Pick<
  FetchTimeTrackingSettingsResponse,
  'minOvertimeSeconds' | 'autoBalanceOvertime'
>;

export type PayPeriodSettingsActionProps = Pick<FetchTimeTrackingSettingsResponse, 'payrollPeriod' | 'overtimePeriod'>;

export type AdvancedSettingsActionProps = Pick<
  FetchTimeTrackingSettingsResponse,
  'typeOfWorktimeWithoutSchedule' | 'countNightHours' | 'nightHoursStartSeconds' | 'nightHoursDurationSeconds'
>;

export type TimeTrackingSettingsActionProps = FetchTimeTrackingSettingsResponse;

export type TimeTrackingSettingsResponse = null;

export type FetchOrganizationSettingsResponse = {
  name: string;
  address: AddressResponse;
  countryCode: string;
  vatId: string;
  timeZoneId: number;
  startingWeekDay: DayOfWeek;
  displayCurrency: string;
  holidays: YearHolidaysResponse;
  showHolidaysOnAllCalendars: boolean;
  autoImportHolidaysEachYear: boolean;
  currencies: Currencies;
};

export type BasicInformationSettingsActionProps = Pick<
  FetchOrganizationSettingsResponse,
  'name' | 'address' | 'countryCode' | 'vatId'
>;

export type PreferencesSettingsActionProps = Pick<
  FetchOrganizationSettingsResponse,
  'timeZoneId' | 'startingWeekDay' | 'displayCurrency'
>;

export type OrganizationHolidaysSettingsActionProps = Pick<
  FetchOrganizationSettingsResponse,
  'showHolidaysOnAllCalendars' | 'autoImportHolidaysEachYear'
>;

export type OrganizationSettingsActionProps = Omit<FetchOrganizationSettingsResponse, 'currencies' | 'holidays'>;

export type OrganizationSettingsResponse = null;

export type OrganizationSettingsAddChangeAvatarActionProps = {
  avatar: Blob;
};

export type OrganizationSettingsAddChangeAvatarResponse = null;

export type OrganizationSettingsDeleteAvatarResponse = null;

export type FetchRequestsSettingsResponse = {
  allowCarryOverUnUsedLimits: boolean;
  carriedOverLimitExpiresAfter: CarriedOverLimitExpiration;
  disableLocationRestrictionOnBusinessTrip: boolean;
  allowChangeRequestForLoggedClockInsAndOuts: boolean;
  allowRequestForBusinessTrips: boolean;
  timeOffApprovalSettings: ApprovalSettingsResponse;
  timeTrackingApprovalSettings: ApprovalSettingsResponse;
  businessTripsApprovalSettings: ApprovalSettingsResponse;
  customRequestsApprovalSettings: ApprovalSettingsResponse;
};

export type RequestsSettingsActionProps = FetchRequestsSettingsResponse;

export type TimeOffSettingsActionProps = Pick<
  FetchRequestsSettingsResponse,
  'timeOffApprovalSettings' | 'allowCarryOverUnUsedLimits' | 'carriedOverLimitExpiresAfter'
>;

export type RequestsTimeTrackingSettingsActionProps = Pick<
  FetchRequestsSettingsResponse,
  'allowChangeRequestForLoggedClockInsAndOuts' | 'timeTrackingApprovalSettings'
>;

export type BusinessTripSettingsActionProps = Pick<
  FetchRequestsSettingsResponse,
  'allowRequestForBusinessTrips' | 'disableLocationRestrictionOnBusinessTrip' | 'businessTripsApprovalSettings'
>;

export type CustomSettingsActionProps = Pick<FetchRequestsSettingsResponse, 'customRequestsApprovalSettings'>;

export type RequestsSettingsResponse = null;

export type AddressResponse = {
  postalCode?: string;
  city?: string;
  street?: string;
};

export type Holiday = {
  id: string;
  dateUnix: number;
  name: string;
};

type YearHolidaysResponse = [
  {
    year: number;
    holidays: HolidaysResponse;
  },
];

type HolidaysResponse = Holiday[];

export type Currencies = [
  {
    key: string;
    value: string;
  },
];

type ApprovalSettingsResponse = {
  twoStepApprovalFlow: boolean;
  firstStepRoleId?: string;
  secondStepRoleId?: string;
};

export enum MagnetInterval {
  Minutes5 = 5,
  Minutes10 = 10,
  Minutes15 = 15,
  Minutes30 = 30,
  Minutes60 = 60,
}

export enum NormalPayrollPeriod {
  Months1 = 1,
  Weeks1 = 7,
  Weeks2 = 8,
}

export enum OvertimePayrollPeriod {
  Default = 0,
  Months1 = 1,
  Months2 = 2,
  Months3 = 3,
  Months4 = 4,
  Months6 = 6,
  Weeks1 = 7,
  Weeks2 = 8,
  Months12 = 12,
}

export enum WorktimeWithoutSchedule {
  CountAsWorkTime = 0,
  CountAsOvertime = 1,
  CountSeparately = 2,
  DontCount = 3,
}

export enum CarriedOverLimitExpiration {
  Never = 0,
  Months1 = 1,
  Months2 = 2,
  Months3 = 3,
  Months4 = 4,
  Months5 = 5,
  Months6 = 6,
  Months7 = 7,
  Months8 = 8,
  Months9 = 9,
  Months10 = 10,
  Months11 = 11,
  Months12 = 12,
}

export type FetchUserNotificationsSettingsResponse = {
  schedulesEmails: boolean;
  schedulesTextMessages: boolean;
  requestsEmails: boolean;
  requestsTextMessages: boolean;
  messagesEmails: boolean;
  promotionsEmails: boolean;
};

export type UserNotificationsSettingsActionProps = FetchUserNotificationsSettingsResponse;

export type UserNotificationsSettingsResponse = null;

export type UserChangePasswordSettingsActionProps = {
  currentPassword: string;
  newPassword: string;
};

export type UserChangePasswordSettingsResponse = null;

type Address = {
  street?: string;
  city?: string;
  postalCode?: string;
};

export type UserProfileSettingsActionProps = {
  firstName: string;
  surname: string;
  email: string;
  phoneNumber: string;
  address?: Address;
};

export type UserProfileSettingsResponse = null;

export type UserPreferences = {
  language: SupportedLanguages;
  timeZoneId: TimeZoneListItem['id'];
  nameDisplayOrder: NameDisplayOrder;
  dateFormat: DateFormat;
  is24HourFormat: boolean;
};

export type UserPreferencesActionProps = {
  language: SupportedLanguages;
  timeZoneId: TimeZoneListItem['id'];
  nameDisplayOrder: NameDisplayOrder;
  dateFormat: DateFormat;
  is24HourFormat: boolean;
};

export type UserPreferencesResponse = null;
export type FetchUserInfoResponse = {
  firstName: string;
  surname: string;
  email: string;
  phoneNumber?: string;
  address?: Address;
};

export type UserInfoActionProps = FetchUserInfoResponse;

export type UserInfoResponse = null;

export type UserInfoChangeAvatarActionProps = {
  avatar: Blob;
};

export type UserInfoChangeAvatarResponse = null;

export type UserInfoDeleteAvatarResponse = null;
