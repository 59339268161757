import { SupportedLanguages } from 'constants/supportedLanguages';

export enum DateFormat {
  DayAndMonth,
  MonthAndDay,
  YearAndMonth,
  YearAndDay,
}

export const dateFormatAsString = {
  0: 'DD.MM.YYYY',
  1: 'MM.DD.YYYY',
  2: 'YYYY.MM.DD',
  3: 'YYYY.DD.MM',
};

export enum TimeFormat {
  _24h,
  _12h,
}

export const timeFormatAsString = {
  0: '24',
  1: '12',
};

export interface FetchUserSessionResponse {
  personId: string;
  timeZoneId?: string;
  preferredLanguage: SupportedLanguages;
  nameDisplayOrder: NameDisplayOrder;
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
  viewsSettings?: ViewsSetting;
}

export type ViewSettings = {
  hiddenColumns: string[];
};

type ViewsSetting = {
  team?: ViewSettings;
  clockLog?: ViewSettings;
  requests?: ViewSettings;
  timesheetsReport?: ViewSettings;
};
export type UpdateUserSessionResponse = Record<string, unknown>;
export type RefreshUserSessionResponse = FetchUserSessionResponse;

export type EditViewsSettingActionProps = ViewsSetting;
export type EditViewsSettingResponse = {
  a: string;
};

export enum NameDisplayOrder {
  NameFirst = 0,
  SurnameFirst = 1,
}
export enum Languages {
  'pl' = 0,
  'de',
  'en',
  'es',
  'fr',
}

export const languageAsString = {
  pl: 'Polski',
  en: 'English',
  de: 'Deustch',
  es: 'Español',
  fr: 'Français',
};
