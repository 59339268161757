import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { ClientContext } from 'react-fetching-library';
import { Flex, Link, Text } from 'theme-ui';

import { fetchFileAction } from 'api/actions/data/dataActions';
import { Icon } from 'components/Icon/Icon';
import { ButtonProps } from '../Buttons';

import { FileName, FileData, DeleteButton } from './fileUploadElements';
import { StyleVariant, ServerFileProps } from './types';
import { getExtensionFromType } from './helpers';

const defaultProps: Partial<ServerFileProps> = {
  isStatic: false,
};

export const ServerFile = ({
  serverFile,
  setServerFiles,
  setFilesIds,
  isStatic,
  dispatchBlurEvent,
}: ServerFileProps): React.ReactElement => {
  const [variant, setVariant] = useState<StyleVariant>('uploaded');

  const { id, name, url, size, type } = useMemo(() => serverFile, [serverFile]);

  const { query } = useContext(ClientContext);

  const handleOnDelete: ButtonProps['onClick'] = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setServerFiles((prevState) => prevState.filter((f) => f.id !== serverFile.id));
      setFilesIds((oldIds) => oldIds.filter((oldId) => oldId !== serverFile.id));
      dispatchBlurEvent();
    },
    [dispatchBlurEvent, serverFile.id, setFilesIds, setServerFiles],
  );

  const getFileDetails = useCallback(async () => {
    const { payload, error } = await query(fetchFileAction({ id }));

    if (error) {
      setVariant('notFound');
      return;
    }

    if (payload) {
      setServerFiles((prevState) => prevState.map((f) => (f.id === id ? payload : f)));
    }
  }, [id, query, setServerFiles]);

  useEffect(() => {
    if (!url) {
      getFileDetails();
    }
  }, [getFileDetails, url]);

  const renderContents = () => {
    if (variant === 'notFound') {
      return (
        <>
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <Text>
              <Trans id="fileUpload.file_not_found">Oops, file not found</Trans>
            </Text>
            <Text variant="fileUpload.smallText">
              <Trans id="fileUpload.file_not_found_hint">You can only delete this fancy placeholder</Trans>
            </Text>
          </Flex>
          {!isStatic && <DeleteButton onClick={handleOnDelete} />}
        </>
      );
    }

    if (url && name && size && type) {
      return (
        <>
          <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
            <FileName name={`${name}.${getExtensionFromType(type)}`} />
            <FileData size={size} />
          </Flex>
          <Icon size={22} className="icon" type="download" wrapperSx={{ variant: 'fileUpload.icon' }} />
          {!isStatic && <DeleteButton onClick={handleOnDelete} />}
        </>
      );
    }

    return (
      <Text>
        <Trans id="global.loading" />
      </Text>
    );
  };

  return (
    <>
      <Link href={url} download rel="noreferrer" data-state={variant} variant="fileUpload.file">
        {renderContents()}
      </Link>
    </>
  );
};

ServerFile.defaultProps = defaultProps;
