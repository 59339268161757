import { useContext, useEffect } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { ModalContext } from 'components/Modal/ModalContext';
import { ModalContextProps } from 'components/Modal/types';

type Options = {
  wrapperSx?: ThemeUIStyleObject;
  fullHeight?: boolean;
  closeOnBackdrop?: boolean;
  onHandleClose?: (closeModal: () => void) => void;
};

export const useModal = (options?: Options): Pick<ModalContextProps, 'handleClose' | 'baseRoute' | 'replaceMode'> => {
  const modalContext = useContext(ModalContext);

  if (modalContext === undefined) {
    throw new Error('useModal must be used within a Modal component');
  }
  const {
    handleClose,
    baseRoute,
    replaceMode,
    setWrapperSx,
    setIsFullHeight,
    setCustomHandleClose,
    setCloseOnBackdrop,
  } = modalContext;

  useEffect(() => {
    if (options) {
      const { closeOnBackdrop, fullHeight, onHandleClose, wrapperSx } = options;

      setCustomHandleClose(() => onHandleClose);
      setWrapperSx(wrapperSx);
      setIsFullHeight(fullHeight);
      setCloseOnBackdrop(closeOnBackdrop);
    }
  }, [setWrapperSx, setIsFullHeight, setCustomHandleClose, setCloseOnBackdrop, options]);

  return {
    handleClose,
    baseRoute,
    replaceMode,
  };
};
