import React, { useCallback, useContext, useEffect } from 'react';
import { ClientContext } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Trans } from '@lingui/macro';
import { useHistory } from 'react-router';
import { Flex, Text } from 'theme-ui';

import { languageSelector } from 'state/recoilState';
import { FormCard } from 'layouts/Authentication';
import { TO } from 'constants/routes';
import { AccountInfoCompany } from 'api/actions/user/userActions.types';
import { fetchTimeZoneListAction, fetchIndustryListAction } from 'api/actions/data/dataActions';
import { industryListAtom, signUpFormAtom, timeZoneListAtom } from 'state/signUp';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

import { EmployerStep2Form } from './EmployerStep2Form';

export const EmployerStep2 = React.forwardRef<HTMLFormElement>((props, ref) => {
  const { push } = useHistory();

  const language = useRecoilValue(languageSelector);
  const [timeZoneList, setTimeZoneList] = useRecoilState(timeZoneListAtom);
  const [industryList, setIndustryList] = useRecoilState(industryListAtom);
  const [signUpFormState, setSignUpFormState] = useRecoilState(signUpFormAtom);

  const { query } = useContext(ClientContext);

  useEffect(() => {
    const getTimeZoneList = async () => {
      const { payload, error: fetchError } = await query(fetchTimeZoneListAction());
      if (!fetchError && payload) {
        setTimeZoneList(payload);
      }
    };
    if (!timeZoneList) {
      getTimeZoneList();
    }
  }, [query, timeZoneList, setTimeZoneList]);

  useEffect(() => {
    const getIndustryList = async () => {
      const { payload, error: fetchError } = await query(fetchIndustryListAction());
      if (!fetchError && payload) {
        setIndustryList(
          payload.map((industry) => ({
            ...industry,
            id: `${industry.id}`,
            subcategories: industry.subcategories
              ? industry.subcategories.map((sub) => ({ ...sub, id: `${sub.id}` }))
              : [],
          })),
        );
      }
    };
    if (!industryList) {
      getIndustryList();
    }
  }, [query, industryList, setIndustryList]);

  const onSubmit = useCallback(
    async (body: AccountInfoCompany): Promise<boolean> => {
      setSignUpFormState({ ...signUpFormState, ...body });
      push(`${TO.SIGN_UP__EMPLOYER__STEP[language]}/3`, {
        signUp: {
          inProgress: true,
        },
      });
      return true;
    },
    [setSignUpFormState, signUpFormState, push, language],
  );

  return (
    <>
      <FormCard.Header>
        <FormCard.Title>
          <Trans id="sing_up.employer.step_3.lead.header">
            Nice to meet you{' '}
            <Text as="span" sx={{ textTransform: 'capitalize' }}>
              {signUpFormState?.firstName}
            </Text>
            ! 😊
          </Trans>
        </FormCard.Title>
        <FormCard.Lead>
          <Trans id="sing_up.employer.step_3.lead.text">Tell us about your company</Trans>
        </FormCard.Lead>
      </FormCard.Header>

      {timeZoneList && industryList ? (
        <EmployerStep2Form ref={ref} onSubmit={onSubmit} />
      ) : (
        <Flex sx={{ alignItems: 'center', justifyContent: 'center', flexGrow: 1, minHeight: '200px' }}>
          <LoadingSpinnerCss size={3} />
        </Flex>
      )}
    </>
  );
});
