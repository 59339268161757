import React, { CSSProperties, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FlexProps, Text, ThemeUIStyleObject } from 'theme-ui';

import { APP_NAME } from 'constants/common';

import { ReactComponent as LogoInewi } from './svg/inewi.svg';
import { ReactComponent as LogoTracktime24 } from './svg/tracktime24.svg';

type LogoWrapperProps = {
  children: React.ReactNode;
  clickable: boolean | undefined;
} & FlexProps;

const LogoWrapper = React.memo(
  ({ children, clickable, ...props }: LogoWrapperProps): React.ReactElement =>
    clickable ? (
      <Text as={Link} to="/" aria-label={APP_NAME} {...props}>
        {children}
      </Text>
    ) : (
      <Text {...props}>{children}</Text>
    ),
);

type Props = Partial<{
  fill?: CSSProperties['fill'];
  width?: number | string | (number | string | null)[];
  sx?: ThemeUIStyleObject;
  sxOverwrite?: ThemeUIStyleObject;
  clickable?: boolean;
}>;

export const Logo = React.memo(({ clickable = false, fill, width = 120, sx }: Props): React.ReactElement => {
  let { hostname } = window.location;

  if (process.env.NODE_ENV !== 'production') {
    hostname = process.env.HOSTNAME || 'inewi.pl';
  }

  const logoMap: { [index in 'inewi.pl' | 'tracktime24.com' | string]: React.ElementType } = useMemo(
    () => ({
      'inewi.pl': LogoInewi,
      'tracktime24.com': LogoTracktime24,
    }),
    [],
  );

  const LogoSvg = useMemo(() => logoMap[hostname] || LogoInewi, [hostname, logoMap]);

  return (
    <LogoWrapper
      clickable={clickable}
      sx={{
        fontSize: '0rem',
        svg: {
          transition: 'fill ease-in-out 0.2s',
          fill,
          width,
          ...(sx && sx),
        },
      }}
    >
      <LogoSvg preserveAspectRatio="xMidYMid meet" />
    </LogoWrapper>
  );
});
