import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { useAuthState } from '../../hooks/useAuthState/useAuthState';
import { SignInPayload } from 'api/actions/auth/authActions.types';
import { TextInput } from 'components/ui/TextInput';
import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { PasswordInput } from 'components/ui/PasswordInput';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { Checkbox } from 'components/ui/Checkbox';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

type Props = {
  onSubmit: (props: SignInPayload) => Promise<boolean>;
};

export const SignUpForm = ({ onSubmit }: Props): React.ReactElement => {
  const {
    register,
    setError,
    clearErrors,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const { isAuthorizing } = useAuthState();

  const handleSubmitCallback = useCallback(
    async (props) => {
      await onSubmit(props);
    },
    [onSubmit],
  );

  const setPasswordErrorCallback = useCallback(() => {
    setError('passwordInternalError' as 'password', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearPasswordErrorCallback = useCallback(() => {
    clearErrors('passwordInternalError' as 'password');
  }, [clearErrors]);

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} noValidate>
      <ElementGroup direction="column" marginValue={0}>
        <TextInput
          id="email"
          placeholder={i18n._('global.forms.inputs.email')}
          variant="roundedTop"
          type="email"
          autoComplete="email"
          error={!!errors.email}
          errorMessage={errors?.email?.message}
          icon="mail"
          clearable
          {...register('email', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
        />
        <PasswordInput
          id="password"
          {...register('password', validationFactory({ required: true }))}
          placeholder={i18n._('global.forms.inputs.password')}
          error={!!errors.password}
          errorMessage={errors?.password?.message}
          onValidError={setPasswordErrorCallback}
          onClearError={clearPasswordErrorCallback}
          variant="roundedBottom"
          valuesToExclude={watch().email ? [watch().email] : undefined}
          icon="lock"
        />
      </ElementGroup>

      <Button mt={4} isLoading={isAuthorizing} variant="primary" size="lg" fullWidth type="submit" shape="rounded">
        <Trans id="sign_up.form.submit_button">Create your account</Trans>
      </Button>

      <Checkbox
        sx={{ mt: 4 }}
        {...register('newsletterSubscription')}
        label={
          <Trans id="sign_up.join_newsletter">
            I want to receive newsletters and special offer notices.{' '}
            <LinkAnchor sx={{ textDecoration: 'underline' }} href={EXTERNAL_LINKS.NEWSLETTER_TERMS} target="_blank">
              Terms of newsletter
            </LinkAnchor>
            .
          </Trans>
        }
      />
    </form>
  );
};
