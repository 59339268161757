import React, { useCallback, useState } from 'react';

import { PopperProviderProps, PopperProvider } from './PopperProvider';

type WithAdditionalProps = {
  popperProps: Omit<PopperProviderProps, 'children'> & {
    popperGuardValue?: boolean;
  };
};

export function withPopper<T>(
  Component: React.ComponentType<T>,
): (props: T & WithAdditionalProps) => React.ReactElement | null {
  const ComponentWithPopper = ({ popperProps, ...props }: T & WithAdditionalProps): React.ReactElement => {
    const [isActivated, setIsActivated] = useState<boolean>(popperProps.popperGuardValue || false);

    const setActivated = useCallback(() => setIsActivated(true), []);

    return (
      <>
        {!isActivated ? (
          <Component {...(props as unknown as T)} onMouseOver={setActivated} />
        ) : (
          <PopperProvider {...popperProps}>
            <Component {...(props as unknown as T)} />
          </PopperProvider>
        )}
      </>
    );
  };

  return ComponentWithPopper;
}
