import React, { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import _ from 'lodash';
import { Flex } from 'theme-ui';

import { StickyList } from 'components/StickyList/StickyList';
import { StickyListProps } from 'components/StickyList/types';
import { PeopleLimit } from 'api/actions/requests/requestsActions.types';
import { FancyPersonBox } from 'components/ui/FancyPersonBox';

export type RemainingLimitsMap = Map<
  string,
  PeopleLimit & {
    id: PeopleLimit['personId'];
  }
>;

type Props = {
  remainingLimits: RemainingLimitsMap;
};

export const PersonLimitsList = ({ remainingLimits }: Props): React.ReactElement => {
  const prepareTimeLimit = useCallback((limit: { days: number; hours: number; minutes: number }) => {
    const { hours, minutes, days } = limit;

    const parsedDays = _.isNumber(days) ? (
      <>
        {`${days} `}
        <Trans id={days > 1 ? 'days' : 'day'} />
      </>
    ) : undefined;

    const parsedHours = hours ? <>{` ${hours}h`}</> : undefined;

    const parsedMinutes = minutes ? <>{` ${minutes}m`}</> : undefined;

    return _.filter([parsedDays, parsedHours, parsedMinutes], (s) => s !== undefined);
  }, []);

  const columns: StickyListProps<PeopleLimit>['columns'] = [
    {
      key: ['personId', 'duration'],
      title: 'personId',
      width: '100%',
      sortableValue: (personId) => personId,
      customCellRenderer: (items: [PeopleLimit['personId'], PeopleLimit['duration']]) => (
        <FancyPersonBox
          sx={{ bg: 'transparent', height: '46px', width: '100%', fontWeight: 'normal' }}
          employeeId={items[0]}
          showRoles
          appendWith={
            <Flex sx={{ ml: 'auto', flexShrink: 0 }}>
              {prepareTimeLimit({ days: items[1].days, hours: items[1].hours, minutes: items[1].minutes })?.map(
                (time) => (
                  <Flex
                    key={items[0]}
                    sx={{
                      ml: 1,
                      px: 1,
                      py: '0.125rem',
                      bg: 'fancyDateBox.background.time',
                      borderRadius: 'sm',
                    }}
                  >
                    {time}
                  </Flex>
                ),
              )}
            </Flex>
          }
        />
      ),
    },
  ];

  return (
    <StickyList
      name="TIME_LIMITS"
      list={remainingLimits}
      columns={columns}
      variant="inverted"
      emptyListMessage="No matches found. 😟"
    />
  );
};
