import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Flex, FlexProps } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useModal } from 'hooks/useModal/useModal';

enum actionTypes {
  RESEND_INVITATION = 'RESEND_INVITATION',
  SET_POSITION = 'SET_POSITION',
  SET_PAY_DETAILS = 'SET_PAY_DETAILS',
  SET_ROLE = 'SET_ROLE',
  SET_TAGS = 'SET_TAGS',
  SET_TIME_OFF_LIMITS = 'SET_TIME_OFF_LIMITS',
  SET_ADVANCED_DETAILS = 'SET_ADVANCED_DETAILS',
  CLEAR_POSITION = 'CLEAR_POSITION',
  CLEAR_TAGS = 'CLEAR_TAGS',
}

type ActionButtonProps = {
  isActive: boolean;
} & FlexProps;

const ActionButton = ({ isActive, sx, children, ...restProps }: ActionButtonProps): React.ReactElement => (
  <Flex
    {...restProps}
    sx={{
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',
      width: '100%',
      fontSize: 2,
      fontWeight: 'bold',
      py: '12px',
      px: 3,
      bg: isActive ? 'skyBlues5' : 'whites2',
      color: !isActive ? 'texts.default' : 'whites0',
      '&:hover': {
        ...(!isActive && {
          bg: 'whites3',
        }),
      },
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
);

export const Navigation = (): React.ReactElement => {
  useLingui();
  const { baseRoute } = useModal();
  const { push } = useHistory();
  const language = useRecoilValue(languageSelector);
  const [selectedAction, setSelectedAction] = useState<actionTypes | null>(null);
  const { state } = useLocation<{ ids?: string[] }>();
  const { ids } = state || {};

  const handleNext = () => {
    if (selectedAction) {
      switch (selectedAction) {
        case actionTypes.RESEND_INVITATION:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__RESEND_INVITATION[language]}`, { ids });
          break;
        case actionTypes.SET_POSITION:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__SET_POSITION[language]}`, { ids });
          break;
        case actionTypes.SET_PAY_DETAILS:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__SET_PAY_DETAILS[language]}`, { ids });
          break;
        case actionTypes.SET_ROLE:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__SET_ROLE[language]}`, { ids });
          break;
        case actionTypes.SET_TAGS:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__SET_TAGS[language]}`, { ids });
          break;
        case actionTypes.SET_TIME_OFF_LIMITS:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__SET_TIME_OFF_LIMITS[language]}`, { ids });
          break;
        case actionTypes.SET_ADVANCED_DETAILS:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__SET_ADVANCED_DETAILS[language]}`, { ids });
          break;
        case actionTypes.CLEAR_POSITION:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__CLEAR_POSITION[language]}`, { ids });
          break;
        case actionTypes.CLEAR_TAGS:
          push(`${baseRoute}${TO.BULK_ACTIONS_MODAL__CLEAR_TAGS[language]}`, { ids });
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.navigation_bulk.bulk_actions">Bulk actions</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ActionButton
          isActive={selectedAction === actionTypes.RESEND_INVITATION}
          sx={{ borderRadius: 'sm' }}
          onClick={() => setSelectedAction(actionTypes.RESEND_INVITATION)}
        >
          <Trans id="team.navigation_bulk.reinvite">Resend invitation</Trans>
        </ActionButton>
        <ElementGroup showAsList wrapperSx={{ mt: 2 }} marginValue="1.5px" direction="column">
          <ActionButton
            sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
            isActive={selectedAction === actionTypes.SET_POSITION}
            onClick={() => setSelectedAction(actionTypes.SET_POSITION)}
          >
            <Trans id="team.navigation_bulk.set_position">Set position</Trans>
          </ActionButton>
          <ActionButton
            isActive={selectedAction === actionTypes.SET_PAY_DETAILS}
            onClick={() => setSelectedAction(actionTypes.SET_PAY_DETAILS)}
          >
            <Trans id="team.navigation_bulk.set_pay_details">Set pay details</Trans>
          </ActionButton>
          <ActionButton
            isActive={selectedAction === actionTypes.SET_ROLE}
            onClick={() => setSelectedAction(actionTypes.SET_ROLE)}
          >
            <Trans id="team.navigation_bulk.set_role">Set role</Trans>
          </ActionButton>
          <ActionButton
            isActive={selectedAction === actionTypes.SET_TAGS}
            onClick={() => setSelectedAction(actionTypes.SET_TAGS)}
          >
            <Trans id="team.navigation_bulk.set_tags">Set tags</Trans>
          </ActionButton>
          <ActionButton
            isActive={selectedAction === actionTypes.SET_TIME_OFF_LIMITS}
            onClick={() => setSelectedAction(actionTypes.SET_TIME_OFF_LIMITS)}
          >
            <Trans id="team.navigation_bulk.set_time_off_limits">Set time off limits</Trans>
          </ActionButton>
          <ActionButton
            isActive={selectedAction === actionTypes.SET_ADVANCED_DETAILS}
            onClick={() => setSelectedAction(actionTypes.SET_ADVANCED_DETAILS)}
            sx={{ borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }}
          >
            <Trans id="team.navigation_bulk.set_advanced_details">Set advanced details</Trans>
          </ActionButton>
        </ElementGroup>
        <ElementGroup showAsList wrapperSx={{ mt: 2 }} marginValue="1.5px" direction="column">
          <ActionButton
            sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
            isActive={selectedAction === actionTypes.CLEAR_POSITION}
            onClick={() => setSelectedAction(actionTypes.CLEAR_POSITION)}
          >
            <Trans id="team.navigation_bulk.clear_position">Clear position</Trans>
          </ActionButton>
          <ActionButton
            isActive={selectedAction === actionTypes.CLEAR_TAGS}
            onClick={() => setSelectedAction(actionTypes.CLEAR_TAGS)}
            sx={{ borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }}
          >
            <Trans id="team.navigation_bulk.clear_tags">Clear tags</Trans>
          </ActionButton>
        </ElementGroup>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            disabled: !selectedAction,
            onClick: handleNext,
            variant: 'primary',
            children: t({ id: 'team.navigation_bulk.next', message: 'Next' }),
          },
        ]}
      />
    </>
  );
};
