import React from 'react';

import { AuthorizedAppRoutes } from 'routing/AuthorizedAppRoutes';
import { AuthorizedAppLayout } from 'layouts/AuthorizedApp';
import { BroadcastChannelObserver } from 'observers/BroadcastChannelObserver';
import { RefreshSessionObserver } from 'observers/RefreshSessionObserver';
import { NotificationProvider } from 'context/notifications/NotificationProvider';
import { SessionObserver } from 'observers/SessionObserver';

import { AuthorizedAppContext } from './AuthorizedAppContext';

export const AuthorizedApp = (): React.ReactElement => (
  <AuthorizedAppContext>
    <AuthorizedAppLayout>
      <RefreshSessionObserver />
      <SessionObserver />
      <NotificationProvider>
        <BroadcastChannelObserver />
      </NotificationProvider>
      <AuthorizedAppRoutes />
    </AuthorizedAppLayout>
  </AuthorizedAppContext>
);
