import React from 'react';

import { Icon, IconProps } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { AnchorButtonProps, LinkButton, LinkButtonProps } from 'components/ui/Buttons';
import { usePathnameMatch } from 'hooks/usePathnameMatch/usePathnameMatch';
import { useTheme } from 'styles/useTheme';

type Props = Omit<LinkButtonProps, 'to' | 'prependWith' | 'variant' | 'shape'> &
  AnchorButtonProps & {
    icon: Icons;
    withDot?: boolean;
    to: string;
    iconSize?: number;
    iconProps?: Partial<IconProps>;
  };

const defaultProps: Partial<Props> = {
  withDot: false,
  iconSize: 0,
  iconProps: undefined,
};

export const IconLink = ({ to, icon, withDot, iconProps, ...props }: Props): React.ReactElement => {
  const active = usePathnameMatch([to]);

  const { theme } = useTheme();

  return (
    <LinkButton
      to={to}
      {...props}
      sx={{
        ...(active && {
          color: 'navbar.iconButton.color.active',
          backgroundColor: `${theme.colors.navbar.iconButton.background.active} !important`,
        }),
        ...(props.sx && props.sx),
      }}
      bgOverwrite={{
        default: theme.colors.navbar.iconButton.background.default,
        hover: theme.colors.navbar.iconButton.background.hover,
        tap: theme.colors.navbar.iconButton.background.tap,
        disabled: theme.colors.navbar.iconButton.background.disabled,
      }}
      prependWith={<Icon type={icon} {...(iconProps && iconProps)} />}
    />
  );
};

IconLink.defaultProps = defaultProps;
