import type { ResponseInterceptor } from 'react-fetching-library';
import { t } from '@lingui/macro';

import { Action } from 'api/types';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { addSnackbar } from 'SnackbarHub/actions';

export const responseErrorInnerCodeInterceptor: () => ResponseInterceptor =
  () => () => async (action: Action<unknown>, response) => {
    if (response.status !== 400 && response.status !== 403) return response;

    const responseInnerCode = response.payload?.innerCode;

    if (!responseInnerCode) return response;

    switch (responseInnerCode as number) {
      case ErrorInnerCodes.BadRequestData:
        addSnackbar({
          message: t({ id: 'error.something_went_wrong', message: 'Something went wrong!' }),
          variant: 'danger',
        });
        break;
      case ErrorInnerCodes.InvalidSubscription:
        addSnackbar({
          message: `Error message for innerCode: ${ErrorInnerCodes.InvalidSubscription}`,
        });
        break;
      case ErrorInnerCodes.NoPermissions:
        addSnackbar({
          message: `Error message for innerCode: ${ErrorInnerCodes.NoPermissions}`,
        });
        break;
      default:
        break;
    }

    return response;
  };
