import React from 'react';
import { motion } from 'framer-motion';

import { Container } from 'components/ui/Container';

import { ModalProps } from './types';

const AnimatedContainer = motion(Container);

const modalAnimationVariants = {
  open: { scale: [0.4, 1], transition: { ease: [0.175, 0.885, 0.32, 1.275], duration: 0.2, delay: 0.045 } },
  close: { scale: [1, 0.1], transition: { ease: [0.6, -0.28, 0.735, 0.045], duration: 0.055 } },
};

type Props = {
  sx?: ModalProps['sx'];
  children: ModalProps['children'];
  size: NonNullable<ModalProps['size']>;
  fullHeight: boolean;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const ModalContainer = ({ children, sx, size, fullHeight, ...props }: Props): React.ReactElement => {
  const getMaxHeight = () => {
    switch (size) {
      case 'xs':
        return '48%';
      case 'sm':
        return '72%';
      case 'lg':
        return '92%';
      case 'xl':
        return '96%';
      case 'fullscreen':
        return '100%';
      case 'default':
      default:
        return '86%';
    }
  };

  return (
    <AnimatedContainer
      {...props}
      animate="open"
      exit="close"
      variants={modalAnimationVariants}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
      sx={{
        maxHeight: getMaxHeight(),
        maxWidth: '100%',
        bg: 'modal.background',
        borderRadius: 'lg',
        boxShadow: 'modal.normal',
        justifyContent: 'space-between',
        overflow: 'hidden',
        minHeight: '25%',
        ...(fullHeight && { height: '100%' }),
        '@media screen and (max-width: 767px)': {
          ...(size !== 'xs' && {
            alignSelf: 'flex-end',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: '97%',
            height: '100%',
            boxShadow: 'modal.reversed',
          }),
        },
        ...(sx && sx),
      }}
    >
      {children}
    </AnimatedContainer>
  );
};

ModalContainer.defaultProps = defaultProps;
