import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';

import {
  chatWindowIdsAtom,
  anyWindowIsVisibleSelector,
  crispIsVisibleAtom,
  anyChatWindowIsVisibleSelector,
} from 'state/chat';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick/useOnOutsideClick';

import { MemoizedMainWindow } from './MainWindow/MainWindow';
import { ChatWindow } from './ChatWindow/ChatWindow';

const AnimatedFlex = motion(Flex);

let screenChatRoot = document.getElementById('chat-root');

if (!screenChatRoot) {
  screenChatRoot = document.createElement('div');
  screenChatRoot.setAttribute('id', 'chat-root');
}

export const Chat = (): React.ReactElement | null => {
  const [isHovered, setIsHovered] = useState(false);
  const chatRef = useRef<HTMLDivElement | null>(null);

  const crispIsVisible = useRecoilValue(crispIsVisibleAtom);
  const chatWindowIds = useRecoilValue(chatWindowIdsAtom);
  const anyWindowIsVisible = useRecoilValue(anyWindowIsVisibleSelector);
  const anyChatWindowIsVisible = useRecoilValue(anyChatWindowIsVisibleSelector);

  const { isMobileBreakpoint } = useThemeBreakpoint();

  const isMobileChat = useMemo(() => isMobileBreakpoint, [isMobileBreakpoint]);

  const handleIsHovered = useCallback(
    (value: boolean) => {
      if (isHovered !== value) {
        setIsHovered(value);
      }
    },
    [isHovered],
  );

  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
    if (isMobileChat) {
      setIsHovered(true);
    }
  }, [isMobileChat]);

  useOnOutsideClick(chatRef, () => {
    if (isMobileChat && isHovered) setIsHovered(false);
  });

  useEffect(() => {
    if (!anyWindowIsVisible) setIsHovered(false);
  }, [anyWindowIsVisible]);

  const rootVariants = useMemo(
    () => ({
      initial: {
        x: 0,
        transition: {
          when: 'afterChildren',
          delayChildren: 0.4,
        },
      },
      visible: {
        x: 0,
        transition: {
          when: 'beforeChildren',
          staggerChildren: 0.04,
          ...(!isMobileChat && { delayChildren: anyWindowIsVisible ? 0 : 0.2 }),
        },
      },
    }),
    [anyWindowIsVisible, isMobileChat],
  );

  const renderAllChatWindows = useCallback(
    () => (
      <AnimatedFlex
        ref={chatRef}
        key="chat.root"
        variant="chat.root"
        variants={rootVariants}
        initial="initial"
        animate={isHovered || anyWindowIsVisible ? 'visible' : 'initial'}
        onHoverStart={() => handleIsHovered(true)}
        onHoverEnd={() => handleIsHovered(false)}
        onClick={handleOnClick}
        sx={{
          ...(crispIsVisible && { visibility: 'hidden' }),
          ...(isMobileChat &&
            !isHovered &&
            chatWindowIds.length > 0 && {
              pointerEvents: 'none',
              '*': {
                pointerEvents: 'none',
              },
            }),
        }}
      >
        <Flex
          variant="chat.chatWindowsContainer"
          {...(anyChatWindowIsVisible &&
            isMobileChat && {
              sx: {
                zIndex: 2,
              },
            })}
        >
          {chatWindowIds.length > 0 && chatWindowIds.map((id, index) => <ChatWindow id={id} index={index} key={id} />)}
        </Flex>

        <MemoizedMainWindow />
      </AnimatedFlex>
    ),
    [
      anyChatWindowIsVisible,
      anyWindowIsVisible,
      chatWindowIds,
      crispIsVisible,
      handleIsHovered,
      handleOnClick,
      isHovered,
      isMobileChat,
      rootVariants,
    ],
  );

  return screenChatRoot && createPortal(renderAllChatWindows(), screenChatRoot);
};
