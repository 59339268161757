import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';

import { withDot } from 'components/Dot/withDot';
import { Avatar } from 'components/Avatar/Avatar';
import { Dot } from 'components/Dot/Dot';
import { chatUserAtomFamily } from 'state/chat';
import { ChatWindowState } from 'chat/ChatWindow/types';
import { ChatUserForFetch } from 'api/actions/chat/chatActions.types';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';

const AvatarWithDot = withDot(Avatar);

export type ChatUserProps = {
  id: ChatUserForFetch['sender'];
  asSupport?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void | void;
  variant?: 'mainWindow' | 'chatWindow';
  chatWindowVisible?: ChatWindowState['isVisible'];
  chatWindowExpanded?: ChatWindowState['isExpanded'];
  sx?: ThemeUIStyleObject;
};

const defaultProps = {
  asSupport: false,
  style: undefined,
  onClick: undefined,
  variant: 'mainWindow',
  chatWindowVisible: false,
  chatWindowExpanded: false,
  sx: undefined,
};

export const ChatUser = ({
  style,
  onClick,
  asSupport,
  variant,
  chatWindowVisible,
  chatWindowExpanded,
  id,
  sx,
  ...props
}: ChatUserProps): React.ReactElement | null => {
  const chatUser = useRecoilValue(chatUserAtomFamily(id));
  const fullName = useNameDisplayOrder();

  const displayName = useMemo(
    () => fullName(chatUser?.firstName || '', chatUser?.surname || ''),
    [chatUser?.firstName, chatUser?.surname, fullName],
  );

  if (!chatUser) return null;

  const { hasUnreadMessages, isOnline, avatarUrl, firstName, surname } = chatUser;

  return (
    <Flex style={style} variant={`chat.user.${variant}`} onClick={onClick} sx={sx} {...props}>
      <AvatarWithDot
        className="chat-avatar"
        size={24}
        image={avatarUrl}
        {...(asSupport ? { iconType: 'support' } : { name: { firstName, surname } })}
        dotProps={{
          size: 10,
          color: 'greens4',
          placement: 'BOTTOM_RIGHT',
          sx: {
            border: '1px solid',
            borderColor: 'whites0',
            transform: 'translate(25%, 25%)',
          },
        }}
        showDot={isOnline}
        sx={{
          flexShrink: 0,
          ...(asSupport && {
            bg: 'softBlues2',
            color: 'whites6',
          }),
        }}
      />
      <Flex variant="chat.window.header.texts" className="chat-user">
        {asSupport ? (
          <>
            <Trans id="chatUser.support.name">Your support team</Trans>
            {isOnline && (
              <Text as="small">
                <Trans id="chatUser.support.online">We are online!</Trans>
              </Text>
            )}
          </>
        ) : (
          <Text>{displayName}</Text>
        )}
      </Flex>
      {hasUnreadMessages && _.isEqual(variant, 'mainWindow') && (
        <Dot
          color="reds4"
          sx={{
            ml: 'auto',
            position: 'relative',
          }}
        />
      )}
    </Flex>
  );
};

ChatUser.defaultProps = defaultProps;

export const MemoizedChatUser = React.memo(ChatUser);
