import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';
import key from 'weak-key';

import { fullTimeFormatSelector } from 'state/recoilState';
import { RequestHistoryStatusBadge } from 'components/recipes/RequestHistoryStatusBadge ';
import { RequestHistory } from 'api/actions/requests/requestsActions.types';
import { dateTime } from 'utils/dateTime';

type Props = {
  list: RequestHistory[];
};

const HistoryElement = ({ person, state, dateTimeUnix }: RequestHistory): React.ReactElement => {
  const timeFormat = useRecoilValue(fullTimeFormatSelector);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        fontSize: 1,
      }}
    >
      <Flex sx={{ width: '130px' }}>
        <RequestHistoryStatusBadge sx={{ height: '18px' }} requestState={state} />
      </Flex>
      <Flex>
        <Text sx={{ fontSize: '0.75rem' }}>
          <Text as="span" sx={{ fontWeight: 'bold' }}>
            {person}
          </Text>
          , {dateTime(dateTimeUnix).format(`ll ${timeFormat}`)}
        </Text>
      </Flex>
    </Flex>
  );
};

export const HistoryList = ({ list }: Props): React.ReactElement => (
  <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
    {list.map((element) => (
      <HistoryElement key={key(element)} {...element} />
    ))}
  </Flex>
);
