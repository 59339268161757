import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  PostEmployerAccountInfoResponse,
  PostEmployerAccountInfoPayload,
  PostEmployerAccountInfoErrors,
  PostEmployeeAccountRegisterPayload,
  PostEmployeeAccountRegisterResponse,
  GetEmployeeAccountRegisterPayload,
  GetEmployeeAccountRegisterResponse,
} from './userActions.types';

export function postEmployerAccountInfoAction(
  values: PostEmployerAccountInfoPayload,
): Action<PostEmployerAccountInfoResponse, unknown, PostEmployerAccountInfoErrors> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.accountInfo,
    body: {
      ...values,
    },
  };
}

export function postEmployeeAccountRegisterAction(
  values: PostEmployeeAccountRegisterPayload,
): Action<PostEmployeeAccountRegisterResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.signUpEmployee,
    body: {
      ...values,
    },
  };
}

export function fetchEmployeeAccountRegisterAction(
  values: GetEmployeeAccountRegisterPayload,
): Action<GetEmployeeAccountRegisterResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.signUpEmployee}?userId=${values.userId}&token=${values.token}`,
  };
}
