import React from 'react';

import { SnackbarHub } from 'SnackbarHub/SnackbarHub';

type Props = {
  children: React.ReactElement[] | React.ReactElement;
};

export const SnackbarProvider = ({ children }: Props): React.ReactElement => (
  <>
    <SnackbarHub />
    {children}
  </>
);
