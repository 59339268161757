import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { Flex } from 'theme-ui';

import { advancedSettingsDeleteOrganizationAction } from 'api/actions/settings/settingsActions';
import { AdvancedSettingsDeleteOrganizationActionProps } from 'api/actions/settings/settingsActions.types';
import { Divider } from 'components/Divider/Divider';
import { Button } from 'components/ui/Buttons';
import { InputOption, Select } from 'components/ui/Select/Select';
import { TextInput } from 'components/ui/TextInput';
import { HeadingTip } from 'layouts/Settings/HeadingTip';

export const AdvancedDeleteOrganization = (): React.ReactElement => {
  useLingui();
  const { mutate } = useMutation(advancedSettingsDeleteOrganizationAction);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const passwordValue = watch('password');

  const optionsList: InputOption[] = useMemo(
    () => [
      {
        label: t({
          id: 'advanced_settings.select_options.lack_of_functionality',
          message: 'Lack of functionality',
        }),
        id: 'Brak funkcjonalności',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.application_price',
          message: 'Application price too expensive',
        }),
        id: 'Zbyt wysoka cena aplikacji',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.competing_application',
          message: 'We took advantage of the offer of a competing application',
        }),
        id: 'Skorzystaliśmy z oferty konkurencyjnej aplikacji',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.no_integration',
          message: 'No integration',
        }),
        id: 'Brak integracji',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.no_decision',
          message: 'No decision yet',
        }),
        id: 'Nie podjęliśmy jeszcze decyzji',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.account_incorrectly',
          message: 'I am an employee, I set up my account incorrectly',
        }),
        id: 'Jestem pracownikiem, błędnie założyłem konto',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.no_company',
          message: "I don't have a company",
        }),
        id: 'Nie mam firmy',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.end_project',
          message: 'End of the project',
        }),
        id: 'Zakończenie projektu',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.seasonal_premises',
          message: 'Seasonal premises',
        }),
        id: 'Lokal sezonowy',
      },
    ],
    [],
  );

  const handleSubmitCallback = useCallback(
    async (data: AdvancedSettingsDeleteOrganizationActionProps) => {
      await mutate(data);
    },
    [mutate],
  );

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)}>
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <HeadingTip
          label={t({
            id: 'advanced_settings.heading.delete_organization',
            message: 'Delete whole organization',
          })}
          additionalInfo={t({
            id: 'advanced_settings.heading.additional_info',
          })}
          variant="heading4"
          additionalInfoSx={{
            fontSize: '0.875rem',
            lineHeight: '1.3125rem',
          }}
        />
        <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
          <Select
            {...register('reason', { required: true })}
            id="reason"
            placeholder={t({
              id: 'advanced_settings.delete_organization_reason.placeholder',
              message: 'Reason',
            })}
            error={!!errors.deleteOrganizationReason}
            errorMessage={errors.deleteOrganizationReason?.message}
            options={optionsList}
            searchable
            creatable
            size="sm"
            label={t({
              id: 'advanced_settings.delete_organization_reason.upper_text',
              message: 'Why do you want to delete your organization?',
            })}
            sx={{ maxWidth: '340px' }}
          />
          <TextInput
            {...register('password', {
              required: t({
                id: 'global.forms.required',
              }),
            })}
            id="deleteOrganizationPassword"
            placeholder={t({
              id: 'global.forms.inputs.password',
            })}
            size="sm"
            type="password"
            icon="lock"
            label={t({
              id: 'advanced_settings.type_your_password',
            })}
            error={!!errors.deleteOrganizationPassword}
            errorMessage={errors.deleteOrganizationPassword?.message}
            sx={{ maxWidth: '340px' }}
          />
          <Button
            variant="danger"
            shape="rounded"
            disabled={!passwordValue}
            type="submit"
            sx={{ alignSelf: 'flex-start' }}
          >
            <Trans id="advanced_settings.delete" />
          </Button>
          <Divider />
        </Flex>
      </Flex>
    </form>
  );
};
