import React, { useEffect, useMemo } from 'react';
import { useTimer } from 'use-timer';

import { stopwatchTimeFormat } from 'utils/stopwatchTimeFormat';

type Props = {
  initialTime: number;
  autostart?: boolean;
  active: boolean;
};

const defaultProps: Partial<Props> = {
  autostart: true,
};

export const Timer = ({ initialTime, autostart, active }: Props): React.ReactElement => {
  const { time, start, pause, advanceTime } = useTimer({
    autostart,
    interval: 1000,
    initialTime,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timeToUpdate = useMemo(() => initialTime - time, [initialTime]);

  useEffect(() => {
    if (active) start();
    if (!active) pause();
  }, [active, pause, start]);

  useEffect(() => {
    if (timeToUpdate) advanceTime(timeToUpdate);
  }, [advanceTime, timeToUpdate]);

  return <>{stopwatchTimeFormat(time)}</>;
};
Timer.defaultProps = defaultProps;
