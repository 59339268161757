import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'theme-ui';
import _ from 'lodash';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';

import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from 'constants/common';
import { dateTime } from 'utils/dateTime';
import { mergeRefs } from 'utils/mergeRefs';
import { Calendar, getSelectedDates, getSelectedTimes } from '../DatePicker/Calendar/Calendar';
import { TimeObject, TimePickers } from '../DatePicker/Calendar/TimePickers';
import { CalendarProps } from '../DatePicker/Calendar/types';
import { setNativeValue } from 'utils/setNativeValue';
import { getDatesWithTime, parsedHiddenInputValue } from '../DatePicker/helpers';
import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';
import { useFieldErrorDispatcher } from 'hooks/useFieldErrorDipatcher/useFieldErrorDispatcher';

import { SingleTimePicker } from './SingleTimePicker';

type ErrorDetails = [boolean | undefined, string | undefined];

type Props = Pick<CalendarProps, 'showStartTime' | 'showEndTime' | 'range' | 'minDate' | 'maxDate'> &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> & {
    maxRange?: number;
    showTime?: boolean;
    error?: boolean;
    errorMessage?: string;
    onValidError?: () => void;
    onClearError?: () => void;
    onLoseFocusCallback?: () => void;
  };

const defaultProps: Partial<Props> = {
  maxDate: DEFAULT_MAX_DATE,
  minDate: DEFAULT_MIN_DATE,
  range: false,
  showStartTime: false,
  showEndTime: false,
  maxRange: undefined,
  showTime: false,
  error: undefined,
  errorMessage: undefined,
};

const prepareMonthYear = (date: dayjs.Dayjs) => ({
  month: date.month(),
  year: date.year(),
});

export const DualCalendar = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      showStartTime,
      showEndTime,
      range,
      minDate = DEFAULT_MIN_DATE,
      maxDate = DEFAULT_MAX_DATE,
      maxRange,
      showTime,
      error,
      errorMessage,
      onValidError,
      onClearError,
      onLoseFocusCallback,
      ...props
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const TODAY_DATE = useMemo(() => dateTime().startOf('day'), []);

    const hiddenRef = useRef<HTMLInputElement | null>(null);
    const [selectedDates, setSelectedDates] = useState<number[] | undefined>(undefined);
    const [selectedTimes, setSelectedTimes] = useState<number[]>([NaN, NaN]);
    const [timePickerErrorMessage, setTimePickerErrorMessage] = useState<string | undefined>(undefined);
    const [selectedCurrentMonthYear, setSelectedCurrentMonthYear] = useState<{
      month: number;
      year: number;
    }>(prepareMonthYear(TODAY_DATE));

    const currentMinDate = useMemo(() => {
      if (!maxRange || !selectedDates) return minDate;

      const [startDate, endDate] = selectedDates;

      if (startDate && !endDate) {
        const subtractedDate = dateTime(startDate)
          .startOf('day')
          .subtract(maxRange - 1, 'day');

        if (minDate.isAfter(subtractedDate)) return minDate;

        return subtractedDate;
      }

      return minDate;
    }, [maxRange, minDate, selectedDates]);
    const bondedMinDate = useMemo(() => {
      const minimum = currentMinDate.add(1, 'month').startOf('month');

      if (!maxRange || !selectedDates) return minimum;

      const [startDate, endDate] = selectedDates;

      if (startDate && !endDate) return currentMinDate;

      return minimum;
    }, [currentMinDate, maxRange, selectedDates]);
    const currentMinDateUnix = useMemo(() => currentMinDate.unix(), [currentMinDate]);
    const bondedMinDateUnix = useMemo(() => bondedMinDate.unix(), [bondedMinDate]);
    const bondedMaxDate = useMemo(() => {
      if (!maxRange || !selectedDates) return maxDate;

      const [startDate, endDate] = selectedDates;

      if (startDate && !endDate) {
        const addedDate = dateTime(startDate)
          .endOf('day')
          .add(maxRange - 1, 'day');

        if (addedDate.isAfter(maxDate)) return maxDate;

        return addedDate;
      }

      return maxDate;
    }, [maxDate, maxRange, selectedDates]);
    const currentMaxDate = useMemo(() => {
      const max = bondedMaxDate.subtract(1, 'month').endOf('month');

      if (!maxRange || !selectedDates) return max;

      const [startDate, endDate] = selectedDates;

      if (startDate && !endDate) return bondedMaxDate;

      return max;
    }, [bondedMaxDate, maxRange, selectedDates]);
    const bondedMaxDateUnix = useMemo(() => bondedMaxDate.unix(), [bondedMaxDate]);
    const currentMaxDateUnix = useMemo(() => currentMaxDate.unix(), [currentMaxDate]);
    const currentMinDateControl = useMemo(() => minDate, [minDate]);
    const bondedMinDateControl = useMemo(
      () => currentMinDateControl.add(1, 'month').startOf('month'),
      [currentMinDateControl],
    );
    const bondedMaxDateControl = useMemo(() => maxDate, [maxDate]);
    const currentMaxDateControl = useMemo(
      () => bondedMaxDateControl.subtract(1, 'month').endOf('month'),
      [bondedMaxDateControl],
    );
    const bondedSelectedCurrentMonthYear = useMemo(() => {
      switch (selectedCurrentMonthYear.month) {
        case 11:
          return {
            month: 0,
            year: selectedCurrentMonthYear.year + 1,
          };
        default:
          return {
            month: selectedCurrentMonthYear.month + 1,
            year: selectedCurrentMonthYear.year,
          };
      }
    }, [selectedCurrentMonthYear]);

    const [computedError, computedErrorMessage] = useMemo(() => {
      const formError: ErrorDetails = [error, errorMessage];
      if (!timePickerErrorMessage) return formError;

      const timePickerError: ErrorDetails = [true, timePickerErrorMessage];

      return timePickerError;
    }, [error, errorMessage, timePickerErrorMessage]);

    const clearTimePickerError = useCallback(() => {
      setTimePickerErrorMessage(undefined);
    }, []);

    const setTimePickerErrorCallback = useCallback(({ id, message }: MessageDescriptor, appendWith?: string) => {
      setTimePickerErrorMessage(
        `${t({
          id,
          message,
        })}${appendWith || ''}`,
      );
    }, []);

    const validate = useCallback(
      (times: number[], sameDay?: boolean) => {
        const [startTime, endTime] = times;

        if ((showStartTime || showTime) && (_.isNil(startTime) || _.isNaN(startTime))) {
          setTimePickerErrorCallback({
            id: 'date_picker.time_required',
          });
          return false;
        }

        if (showEndTime && (_.isNil(endTime) || _.isNaN(endTime))) {
          setTimePickerErrorCallback({
            id: 'date_picker.time_required',
          });
          return false;
        }

        if (sameDay && startTime && endTime && endTime < startTime) {
          setTimePickerErrorCallback({
            id: 'global.forms.too_low',
            message: 'Too low',
          });
          return false;
        }

        clearTimePickerError();
        return true;
      },
      [clearTimePickerError, setTimePickerErrorCallback, showEndTime, showStartTime, showTime],
    );

    const setHiddenInputValue = useCallback((value: string | number[] | number) => {
      setNativeValue(hiddenRef, value);
    }, []);

    const onCalendarDateChange = useCallback((dates: number[]) => {
      setSelectedDates((prevDates) => (_.isEqual(dates, prevDates) ? prevDates : dates));
    }, []);

    const onTimeChangeCallback = useCallback(({ startTimeUnix, endTimeUnix }: TimeObject) => {
      setSelectedTimes([startTimeUnix || NaN, endTimeUnix || NaN]);
    }, []);

    const onSingleTimeChangeCallback = useCallback((timeUnix: number) => {
      setSelectedTimes([timeUnix, NaN]);
    }, []);

    const handleMonthChangeCallback = useCallback((month: number) => {
      setSelectedCurrentMonthYear((prevState) => ({
        ...prevState,
        month,
      }));
    }, []);

    const handleBondedMonthChangeCallback = useCallback((month: number) => {
      setSelectedCurrentMonthYear((prevState) => ({
        ...prevState,
        month: month === 0 ? 11 : month - 1,
        year: month === 0 ? prevState.year - 1 : prevState.year,
      }));
    }, []);

    const handleYearChangeCallback = useCallback((year: number) => {
      setSelectedCurrentMonthYear((prevState) => ({
        ...prevState,
        year,
      }));
    }, []);

    const onNextMonth = useCallback(() => {
      setSelectedCurrentMonthYear((prevState) => {
        const newMonth = prevState.month + 1;

        return {
          ...prevState,
          month: newMonth > 11 ? 0 : newMonth,
          year: newMonth > 11 ? prevState.year + 1 : prevState.year,
        };
      });
    }, []);

    const onPreviousMonth = useCallback(() => {
      setSelectedCurrentMonthYear((prevState) => {
        const newMonth = prevState.month - 1;

        return {
          ...prevState,
          month: newMonth < 0 ? 11 : newMonth,
          year: newMonth < 0 ? prevState.year - 1 : prevState.year,
        };
      });
    }, []);

    const onDayClickCallback = useCallback(() => {
      const [startTime, endTime] = selectedTimes;

      if (!_.isNaN(startTime) || !_.isNaN(endTime)) validate(selectedTimes);
    }, [selectedTimes, validate]);

    const onTimeBlurCallback = useCallback(
      ({ startTimeUnix, endTimeUnix }: TimeObject) => {
        const isSameDay = selectedDates && !_.isEmpty(selectedDates) && selectedDates[0] === selectedDates[1];

        validate([startTimeUnix, endTimeUnix], isSameDay);
      },
      [selectedDates, validate],
    );

    const onSingleTimeBlurCallback = useCallback(
      (timeUnix: number) => {
        validate([timeUnix, NaN], false);
      },
      [validate],
    );

    const updateCalendarView = useCallback(
      (value: string) => {
        if (!value) {
          setSelectedDates(undefined);
          setSelectedTimes([NaN, NaN]);
          setSelectedCurrentMonthYear(prepareMonthYear(TODAY_DATE));
          clearTimePickerError();
          return;
        }

        const unixDates = parsedHiddenInputValue(value);
        const startDate = dateTime(unixDates[0]);
        const newDates = getSelectedDates(unixDates);
        const newTimes = getSelectedTimes(unixDates).filter((time) => !_.isNaN(time));

        setSelectedDates(newDates);
        setSelectedTimes((prevTimes) => {
          if (newTimes.length === 2 || !prevTimes[1]) {
            return newTimes;
          }

          return [newTimes[0], prevTimes[1]];
        });
        setSelectedCurrentMonthYear(prepareMonthYear(startDate));
      },
      [TODAY_DATE, clearTimePickerError],
    );

    const onOwnValueChange = useCallback(
      (newValue: InputOwnValue) => {
        let parsedNewValue = newValue;
        if (_.isNil(newValue)) {
          parsedNewValue = '';
        }
        if (_.isArray(newValue)) {
          parsedNewValue = newValue.join(',');
        }
        updateCalendarView(`${parsedNewValue}`);
      },
      [updateCalendarView],
    );

    useOnOwnValueChange(hiddenRef, onOwnValueChange);

    useFieldErrorDispatcher(!!computedError, onValidError, onClearError);

    useEffect(() => {
      if (selectedDates && selectedTimes && showStartTime && showEndTime) {
        const [startTime, endTime] = selectedTimes;

        if (!_.isNaN(startTime) && !_.isNaN(endTime)) {
          setHiddenInputValue(getDatesWithTime(selectedDates, selectedTimes));
        }
      }
    }, [selectedDates, selectedTimes, setHiddenInputValue, showEndTime, showStartTime]);

    useEffect(() => {
      if (selectedDates && !showStartTime && !showEndTime && !showTime) {
        setHiddenInputValue(getDatesWithTime(selectedDates, selectedTimes));
      }
    }, [selectedDates, selectedTimes, setHiddenInputValue, showEndTime, showStartTime, showTime]);

    useEffect(() => {
      if (selectedDates && selectedTimes && !showStartTime && !showEndTime && showTime) {
        const [startTime] = selectedTimes;

        if (!(_.isNil(startTime) || _.isNaN(startTime))) {
          setHiddenInputValue(getDatesWithTime(selectedDates, selectedTimes));
        }
      }
    }, [selectedDates, selectedTimes, setHiddenInputValue, showEndTime, showStartTime, showTime]);

    return (
      <Flex sx={{ flexDirection: 'column', gap: 3 }}>
        <input
          {...props}
          ref={mergeRefs([ref, hiddenRef])}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
          readOnly
        />
        <Flex>
          <Calendar
            variant="dualCalendar"
            range={range}
            onChange={onCalendarDateChange}
            selectedDateTimes={selectedDates}
            selectedMonthYear={selectedCurrentMonthYear}
            controlProps={{
              onMonthChange: handleMonthChangeCallback,
              onYearChange: handleYearChangeCallback,
              onPrev: onPreviousMonth,
              minDate: currentMinDateControl,
              maxDate: currentMaxDateControl,
              hideArrow: 'right',
            }}
            daysGridProps={{
              minDateUnix: currentMinDateUnix,
              maxDateUnix: currentMaxDateUnix,
            }}
            recenterCalendarAfterDateSelect={false}
            onDayClickCallback={onDayClickCallback}
          />
          <Calendar
            variant="dualCalendar"
            range={range}
            onChange={onCalendarDateChange}
            selectedDateTimes={selectedDates}
            selectedMonthYear={bondedSelectedCurrentMonthYear}
            controlProps={{
              onMonthChange: handleBondedMonthChangeCallback,
              onYearChange: handleYearChangeCallback,
              onNext: onNextMonth,
              minDate: bondedMinDateControl,
              maxDate: bondedMaxDateControl,
              hideArrow: 'left',
            }}
            daysGridProps={{
              minDateUnix: bondedMinDateUnix,
              maxDateUnix: bondedMaxDateUnix,
            }}
            recenterCalendarAfterDateSelect={false}
            onDayClickCallback={onDayClickCallback}
          />
        </Flex>
        {range && (
          <TimePickers
            selectedTimes={selectedTimes}
            showStartTime={showStartTime || false}
            showEndTime={showEndTime || false}
            onTimeChange={onTimeChangeCallback}
            onTimeBlur={onTimeBlurCallback}
            error={error || computedError}
            errorMessage={computedErrorMessage}
          />
        )}
        {!range && showTime && (
          <SingleTimePicker
            selectedTime={selectedTimes[0]}
            onTimeChange={onSingleTimeChangeCallback}
            onTimeBlur={onSingleTimeBlurCallback}
            error={error || computedError}
            errorMessage={computedErrorMessage}
          />
        )}
      </Flex>
    );
  },
);

DualCalendar.defaultProps = defaultProps;
