import { HOSTNAME } from './common';

export const EXTERNAL_LINKS = {
  TERMS: HOSTNAME === 'inewi.pl' ? 'https://inewi.pl/Regulamin' : 'https://tracktime24.com/Terms-And-Conditions',
  PRIVACY_POLICY:
    HOSTNAME === 'inewi.pl' ? 'https://inewi.pl/Polityka-Prywatnosci' : 'https://tracktime24.com/Privacy-Policy',
  NEWSLETTER_TERMS:
    HOSTNAME === 'inewi.pl' ? 'https://inewi.pl/Regulamin-Newsletter' : 'https://tracktime24.com/Terms-Of-Newsletter',
  PERSONAL_DATA:
    HOSTNAME === 'inewi.pl'
      ? 'https://inewi.pl/Obowiazek-Informacyjny'
      : 'https://tracktime24.com/Personal-Data-From-Internet',
  ADD_TIME_CLOCK: HOSTNAME === 'inewi.pl' ? 'https://inewi.pl/Rcp' : 'https://tracktime24.com/Rcp',
};
