import React, { CSSProperties, UIEventHandler, useContext, useMemo } from 'react';

import { StickyListContext } from './context';

type OuterElementProps = {
  style: CSSProperties;
  className?: string;
  onScroll: UIEventHandler<HTMLDivElement>;
};

export const StickyListOuterElement = React.forwardRef<HTMLDivElement, OuterElementProps>(
  ({ style, ...props }, ref) => {
    const { disableNativeKeyboardScrolling } = useContext(StickyListContext);

    const innerStyle = useMemo(() => ({ ...style, outlineWidth: 0 }), [style]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (disableNativeKeyboardScrolling) {
        switch (e.key) {
          case 'ArrowUp':
            e.preventDefault();
            return;
          case 'ArrowDown':
            e.preventDefault();
            return;
          case 'ArrowRight':
            e.preventDefault();
            return;
          case 'ArrowLeft':
            e.preventDefault();
            break;
          default:
            break;
        }
      }
    };
    return <div ref={ref} aria-hidden tabIndex={-1} onKeyDown={handleKeyDown} {...props} style={innerStyle} />;
  },
);
