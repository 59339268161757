import { makeTheme } from 'styles/makeTheme';
import { hexToRgba } from 'utils/colors';

export const BASE_COLORS = {
  backgrounds: [
    '#FFFFFF', // 0
    '#F5F6F8',
    '#EFF2F4',
    '#EAEDF1',
    '#E5E9ED',
    '#E0E4EA',
    '#DAE0E6',
    '#D5DBE3',
    '#D0D7DF', // 8
  ],
  grays: [
    '#FAFBFC', // 0
    '#E1E2E4',
    '#C1C4C9',
    '#A1A6AD',
    '#818791',
    '#616975',
    '#414B59',
    '#232830',
    '#050607', // 8
  ],
  grayBlues: [
    '#F6F7F9', // 0
    '#EAECF1',
    '#CAD0DB',
    '#AAB3C5',
    '#8A97B0',
    '#6A7A9A',
    '#4A5E85',
    '#2A416F',
    '#04060B', // 8
  ],
  whiteBlues: [
    '#f4f8fd', // 0
    '#e5effa',
    '#d5e5f6',
    '#c6dbf3',
    '#b6d2f0',
    '#a7c8ed',
    '#97bfe9',
    '#88b5e6',
    '#769dc8', // 8
  ],
  whites: [
    '#FFFFFF', // 0
    '#F9FAFC',
    '#F4F6F8',
    '#EEF1F5',
    '#E8EDF2',
    '#E2E8EE',
    '#DDE3EB',
    '#D7DFE7',
    '#D1DAE4', // 8
  ],
  softBlues: [
    '#E0F7FE', // 0
    '#C2ECFE',
    '#A3DDFD',
    '#8CCDFB',
    '#66B5F9',
    '#4A8ED6',
    '#336AB3',
    '#204A90',
    '#133377', // 8
  ],
  skyBlues: [
    '#D6FCFD', // 0
    '#AEF4FB',
    '#84E2F4',
    '#63CBEA',
    '#34AADC',
    '#2686BD',
    '#1A659E',
    '#10487F',
    '#093369', // 8
  ],
  reds: [
    '#FFE4D9', // 0
    '#FFC3B3',
    '#FF9C8D',
    '#FF7771',
    '#FF424B',
    '#DB3047',
    '#B72143',
    '#93153C',
    '#7A0C38', // 8
  ],
  greens: [
    '#E2FCD8', // 0
    '#C0F9B3',
    '#93ED89',
    '#69DC68',
    '#3BC647',
    '#2BAA41',
    '#1D8E3B',
    '#127235',
    '#0B5F30',
  ],
  limes: [
    '#f4fcdb', // 0
    '#e8fab8',
    '#D2F192',
    '#bbe373',
    '#9AD148',
    '#7bb334',
    '#5F9624',
    '#457916',
    '#33640d', // 8
  ],
  yellows: [
    '#FFF8D1', // 0
    '#FFEFA4',
    '#FFE376',
    '#FFD854',
    '#FFC61C',
    '#DBA414',
    '#B7840E',
    '#936608',
    '#7A5005',
  ],
  oranges: [
    '#fff1d4', // 0
    '#ffe0aa',
    '#FFCA7F',
    '#ffb560',
    '#ff922b',
    '#db711f',
    '#B75315',
    '#933a0d',
    '#7a2808', // 8
  ],
  browns: [
    '#f9efdf', // 0
    '#f3dec0',
    '#DDBC97',
    '#bb9371',
    '#8E6243',
    '#7a4a30',
    '#663621',
    '#522415',
    '#44160c', // 8
  ],
  pinks: [
    '#FEE2E0', // 0
    '#FDC2C5',
    '#FAA3B0',
    '#F68BA5',
    '#F06595',
    '#CE4984',
    '#AC3275',
    '#8B2064',
    '#73135A',
  ],
  purples: [
    '#EFEAFF', // 0
    '#DFD5FF',
    '#CEC0FF',
    '#C0B1FF',
    '#AA97FF',
    '#806EDB',
    '#5B4CB7',
    '#3C3093',
    '#261C7A',
  ],
};

export const COLOR_ALPHAS = {
  // DARKER
  darker0: hexToRgba(BASE_COLORS.grayBlues[7], 0.0),
  darker1: hexToRgba(BASE_COLORS.grayBlues[7], 0.06),
  darker2: hexToRgba(BASE_COLORS.grayBlues[7], 0.08),
  darker3: hexToRgba(BASE_COLORS.grayBlues[7], 0.1),
  darker4: hexToRgba(BASE_COLORS.grayBlues[7], 0.12),
  darker5: hexToRgba(BASE_COLORS.grayBlues[7], 0.2),
  darker6: hexToRgba(BASE_COLORS.grayBlues[7], 0.28),
  darker7: hexToRgba(BASE_COLORS.grayBlues[7], 0.36),
  darker8: hexToRgba(BASE_COLORS.grayBlues[7], 0.42),
  // LIGHTER
  lighter0: hexToRgba(BASE_COLORS.whites[1], 0.0),
  lighter1: hexToRgba(BASE_COLORS.whites[1], 0.3),
  lighter2: hexToRgba(BASE_COLORS.whites[1], 0.5),
  lighter3: hexToRgba(BASE_COLORS.whites[1], 0.7),
  lighter4: hexToRgba(BASE_COLORS.whites[1], 0.75),
  lighter5: hexToRgba(BASE_COLORS.whites[1], 0.8),
  lighter6: hexToRgba(BASE_COLORS.whites[1], 0.85),
  lighter7: hexToRgba(BASE_COLORS.whites[1], 0.9),
  lighter8: hexToRgba(BASE_COLORS.whites[1], 0.95),
  // BLUISH
  bluish0: hexToRgba(BASE_COLORS.softBlues[5], 0.0),
  bluish1: hexToRgba(BASE_COLORS.softBlues[5], 0.08),
  bluish2: hexToRgba(BASE_COLORS.softBlues[5], 0.12),
  bluish3: hexToRgba(BASE_COLORS.softBlues[5], 0.16),
  bluish4: hexToRgba(BASE_COLORS.softBlues[5], 0.2),
  bluish5: hexToRgba(BASE_COLORS.softBlues[5], 0.36),
  bluish6: hexToRgba(BASE_COLORS.softBlues[5], 0.52),
  bluish7: hexToRgba(BASE_COLORS.softBlues[5], 0.68),
  bluish8: hexToRgba(BASE_COLORS.softBlues[5], 0.84),
  // REDISH
  redish0: hexToRgba(BASE_COLORS.reds[5], 0.0),
  redish1: hexToRgba(BASE_COLORS.reds[5], 0.08),
  redish2: hexToRgba(BASE_COLORS.reds[5], 0.12),
  redish3: hexToRgba(BASE_COLORS.reds[5], 0.16),
  redish4: hexToRgba(BASE_COLORS.reds[5], 0.2),
  redish5: hexToRgba(BASE_COLORS.reds[5], 0.36),
  redish6: hexToRgba(BASE_COLORS.reds[5], 0.52),
  redish7: hexToRgba(BASE_COLORS.reds[5], 0.68),
  redish8: hexToRgba(BASE_COLORS.reds[5], 0.84),
};

const REPORTS_COLORS = {
  present: BASE_COLORS.greens[5],
  incompleteShift: BASE_COLORS.yellows[4],
  overtime: BASE_COLORS.purples[4],
  absent: BASE_COLORS.reds[3],
  timeOff: BASE_COLORS.grays[3],
  customRequest: BASE_COLORS.grays[5],
  businessTrip: BASE_COLORS.greens[8],
  error: BASE_COLORS.reds[5],
  alphas: {
    timeOff: hexToRgba(BASE_COLORS.grays[8], 0.5),
  },
  cells: {
    customRequest: hexToRgba(BASE_COLORS.grays[8], 0.35),
    present: hexToRgba(BASE_COLORS.greens[5], 0.35),
    incompleteShift: hexToRgba(BASE_COLORS.yellows[4], 0.35),
    overtime: hexToRgba(BASE_COLORS.purples[4], 0.35),
    absent: hexToRgba(BASE_COLORS.reds[3], 0.35),
    timeOff: hexToRgba(BASE_COLORS.grays[3], 0.35),
    error: hexToRgba(BASE_COLORS.reds[5], 0.2),
  },
};

const colorAliases = {
  black: BASE_COLORS.grays[8],
  white: BASE_COLORS.whites[0],
  grayBlue: BASE_COLORS.grayBlues[4],
  skyBlue: BASE_COLORS.skyBlues[4],
  red: BASE_COLORS.reds[4],
  green: BASE_COLORS.greens[4],
  yellow: BASE_COLORS.yellows[4],
  pink: BASE_COLORS.pinks[4],
  purples: BASE_COLORS.purples[4],
};

const baseColorsParser = (): Record<string, unknown> => {
  let newObj = {};

  Object.entries(BASE_COLORS).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      Object.keys({ ...value }).forEach((oldKey: unknown) => {
        newObj = { ...newObj, ...{ [`${key}${oldKey}`]: { ...value }[oldKey as number] } };
      });
    } else {
      newObj = { ...newObj, ...{ [`${key}`]: value } };
    }
  });

  return newObj;
};

const baseColorsParsed = baseColorsParser();

const TEXT_COLORS = {
  darker: '#0E1737',
  default: '#253353',
  lighter: '#7D90AB',
  active: BASE_COLORS.skyBlues[6],
  reversed: BASE_COLORS.whites[1],
  error: BASE_COLORS.reds[5],
  positive: BASE_COLORS.greens[5],
};

const BACKGROUNDS = {
  levelFour: BASE_COLORS.backgrounds[0],
  levelThree: BASE_COLORS.backgrounds[1],
  levelTwo: BASE_COLORS.backgrounds[2],
  levelOne: BASE_COLORS.backgrounds[3],
  levelZero: BASE_COLORS.backgrounds[4],
  levelMinusOne: BASE_COLORS.backgrounds[5],
  levelMinusTwo: BASE_COLORS.backgrounds[6],
  levelMinusThree: BASE_COLORS.backgrounds[7],
  levelMinusFour: BASE_COLORS.backgrounds[8],
  //
  default: BASE_COLORS.whites[1],
  navbar: BASE_COLORS.whites[0],
  white: BASE_COLORS.whites[0],
  error: BASE_COLORS.reds[0],
  positive: BASE_COLORS.greens[0],
  //
  authorizedApp: BASE_COLORS.backgrounds[1],
};

const BADGE_COLORS = {
  default: BASE_COLORS.grays[1],
  info: BASE_COLORS.softBlues[4],
  success: BASE_COLORS.greens[4],
  danger: BASE_COLORS.reds[4],
  warning: BASE_COLORS.yellows[4],
};

export const colors = makeTheme({
  colors: {
    ...colorAliases,
    ...BASE_COLORS,
    ...baseColorsParsed,
    alphas: COLOR_ALPHAS,
    //
    texts: TEXT_COLORS,
    text: TEXT_COLORS.default,
    //
    backgrounds: BACKGROUNDS,
    background: BACKGROUNDS.default,
    primary: BASE_COLORS.skyBlues[5],
    //
    reports: REPORTS_COLORS,
    input: {
      text: {
        default: TEXT_COLORS.default,
        placeholder: COLOR_ALPHAS.darker7,
        disabled: TEXT_COLORS.lighter,
      },
      background: {
        default: BASE_COLORS.whites[0],
        hover: BASE_COLORS.whites[2],
        focus: BASE_COLORS.whites[0],
        disabled: BASE_COLORS.grays[1],
      },
    },
    select: {
      optionList: {
        background: {
          default: BASE_COLORS.whites[0],
        },
      },
      option: {
        text: {
          active: BASE_COLORS.whites[0],
        },
        background: {
          hover: COLOR_ALPHAS.darker2,
          active: BASE_COLORS.skyBlues[5],
        },
      },
      selectOption: {
        text: BASE_COLORS.whites[0],
        background: {
          default: BASE_COLORS.grays[8],
          hover: BASE_COLORS.grays[4],
        },
      },
    },
    twoDigitInput: {
      background: {
        default: COLOR_ALPHAS.darker3,
        hover: COLOR_ALPHAS.darker4,
        focus: COLOR_ALPHAS.darker2,
      },
    },
    checkbox: {
      background: {
        default: COLOR_ALPHAS.darker3,
        hover: COLOR_ALPHAS.darker5,
        checked: BASE_COLORS.skyBlues[5],
        disabled: BASE_COLORS.grays[2],
      },
      label: {
        default: TEXT_COLORS.default,
        disabled: BASE_COLORS.grays[3],
      },
      checkMark: {
        default: BASE_COLORS.whites[0],
        hover: BASE_COLORS.whites[1],
      },
    },
    radio: {
      background: {
        default: COLOR_ALPHAS.darker3,
        hover: COLOR_ALPHAS.darker5,
        checked: BASE_COLORS.skyBlues[5],
        disabled: COLOR_ALPHAS.darker1,
      },
    },
    switch: {
      background: {
        default: COLOR_ALPHAS.darker3,
        hover: COLOR_ALPHAS.darker5,
        checked: BASE_COLORS.skyBlues[5],
        disabled: COLOR_ALPHAS.darker1,
        checkedAndDisabled: hexToRgba(BASE_COLORS.skyBlues[5], 0.5),
      },
      indicator: {
        default: BASE_COLORS.whites[0],
        disabled: BASE_COLORS.whites[0],
      },
    },
    modal: {
      backdrop: COLOR_ALPHAS.darker6,
      background: BASE_COLORS.backgrounds[0],
    },
    navbar: {
      background: BASE_COLORS.backgrounds[0],
      menuButton: {
        background: {
          default: COLOR_ALPHAS.bluish0,
          hover: COLOR_ALPHAS.bluish2,
          tap: COLOR_ALPHAS.bluish4,
          disabled: COLOR_ALPHAS.bluish0,
        },
        color: {
          active: TEXT_COLORS.active,
        },
      },
      iconButton: {
        background: {
          default: COLOR_ALPHAS.darker2,
          hover: COLOR_ALPHAS.bluish3,
          tap: COLOR_ALPHAS.bluish4,
          active: COLOR_ALPHAS.bluish4,
          disabled: BASE_COLORS.grays[3],
        },
        color: {
          active: TEXT_COLORS.active,
          lighter: BASE_COLORS.grayBlues[3],
        },
      },
    },
    dropdown: {
      background: BASE_COLORS.whites[0],
      dropdownLink: {
        background: {
          default: BASE_COLORS.whites[0],
          hover: BASE_COLORS.whites[2],
          tap: BASE_COLORS.whites[3],
          disabled: BASE_COLORS.grays[0],
        },
        color: {
          active: BASE_COLORS.skyBlues[5],
        },
        additionalInfo: {
          default: TEXT_COLORS.lighter,
          active: BASE_COLORS.skyBlues[5],
        },
      },
      dividerColors: {
        primary: COLOR_ALPHAS.darker3,
        secondary: COLOR_ALPHAS.darker4,
      },
    },

    paymentTicker: {
      default: {
        background: BASE_COLORS.grayBlues[1],
        counter: COLOR_ALPHAS.darker4,
        text: TEXT_COLORS.lighter,
      },
      warning: {
        background: BASE_COLORS.yellows[0],
        counter: BASE_COLORS.yellows[2],
        text: BASE_COLORS.yellows[7],
      },
      danger: {
        background: BASE_COLORS.reds[0],
        counter: BASE_COLORS.reds[2],
        text: BASE_COLORS.reds[7],
      },
    },

    avatar: {
      background: COLOR_ALPHAS.darker3,
      text: COLOR_ALPHAS.darker7,
    },

    notification: {
      background: {
        hub: hexToRgba(BACKGROUNDS.white, 0.84),
        hover: BACKGROUNDS.levelFour,
        center: BACKGROUNDS.levelTwo,
        centerHover: BACKGROUNDS.levelZero,
      },
      closeButton: {
        fill: BASE_COLORS.grayBlues[3],
        background: {
          default: COLOR_ALPHAS.lighter7,
          hover: BASE_COLORS.whites[0],
          tap: BASE_COLORS.whites[1],
        },
      },
      text: {
        default: TEXT_COLORS.default,
        date: TEXT_COLORS.lighter,
      },
      type: {
        success: BASE_COLORS.greens[4],
        warning: BASE_COLORS.yellows[4],
        info: BASE_COLORS.softBlues[4],
        danger: BASE_COLORS.reds[4],
        love: BASE_COLORS.pinks[4],
      },
      icon: COLOR_ALPHAS.darker8,
    },

    snackbar: {
      default: {
        background: BASE_COLORS.grays[7],
        text: TEXT_COLORS.reversed,
      },
      success: {
        background: BASE_COLORS.greens[6],
        text: TEXT_COLORS.reversed,
      },
      warning: {
        background: BASE_COLORS.yellows[4],
        text: TEXT_COLORS.default,
      },
      danger: {
        background: BASE_COLORS.reds[5],
        text: TEXT_COLORS.reversed,
      },
    },
    drawer: {
      background: BASE_COLORS.backgrounds[0],
    },
    attendancePill: {
      counterText: hexToRgba(TEXT_COLORS.darker, 0.55),
      variants: {
        default: COLOR_ALPHAS.darker4,
        success: BASE_COLORS.greens[4],
        warning: BASE_COLORS.yellows[4],
        danger: BASE_COLORS.reds[4],
      },
    },

    list: {
      rowBackground: {
        default: BASE_COLORS.whites[0],
        inverted: BASE_COLORS.whites[2],
        hover: BASE_COLORS.whiteBlues[0],
        selected: BASE_COLORS.whiteBlues[1],
        selectedHover: BASE_COLORS.whiteBlues[2],
        nonEditable: BASE_COLORS.whites[5],
        nonEditableHover: BASE_COLORS.whites[4],
      },
    },

    tooltip: {
      background: BASE_COLORS.grays[7],
      text: TEXT_COLORS.reversed,
      textDecoration: BASE_COLORS.grayBlues[7],
    },
    alerts: {
      background: {
        info: BASE_COLORS.skyBlues[5],
        danger: BASE_COLORS.reds[5],
        warning: BASE_COLORS.yellows[4],
      },
      text: {
        info: BASE_COLORS.whites[0],
        danger: BASE_COLORS.whites[0],
      },
    },

    settingsNavLink: {
      background: {
        default: COLOR_ALPHAS.darker2,
        hover: COLOR_ALPHAS.darker1,
        active: BASE_COLORS.skyBlues[5],
      },
      text: {
        default: TEXT_COLORS.default,
        active: TEXT_COLORS.reversed,
        additionalLabel: TEXT_COLORS.lighter,
      },
    },

    badge: {
      ...BADGE_COLORS,
    },

    workTimer: {
      background: {
        container: {
          default: COLOR_ALPHAS.darker2,
          hover: BASE_COLORS.greens[0],
          activeHover: BASE_COLORS.reds[0],
        },
        button: {
          default: BASE_COLORS.greens[6],
          hover: BASE_COLORS.greens[8],
          tap: BASE_COLORS.greens[6],
          disabled: BASE_COLORS.greens[5],
        },
      },
      icon: {
        fill: {
          default: BASE_COLORS.greens[6],
          hover: BASE_COLORS.greens[8],
          active: BASE_COLORS.reds[6],
          activeHover: BASE_COLORS.reds[8],
          custom: BASE_COLORS.grayBlues[6],
        },
      },
      text: {
        default: TEXT_COLORS.lighter,
        hover: BASE_COLORS.greens[8],
        active: TEXT_COLORS.lighter,
        activeHover: BASE_COLORS.reds[8],
        custom: TEXT_COLORS.default,
      },
    },

    chat: {
      backgrounds: {
        divider: COLOR_ALPHAS.darker4,
      },
      texts: {
        support: TEXT_COLORS.lighter,
      },
      mainWindow: {
        backgrounds: {
          default: BASE_COLORS.backgrounds[0],
          hover: COLOR_ALPHAS.bluish1,
          hoverDarker: COLOR_ALPHAS.darker1,
        },
      },
    },

    workStatus: {
      background: {
        additional: BASE_COLORS.grays[1],
        main: {
          isStart: BASE_COLORS.greens[4],
          isEnd: BASE_COLORS.reds[4],
        },
      },
      text: {
        additional: TEXT_COLORS.darker,
        main: {
          isStart: TEXT_COLORS.positive,
          isEnd: TEXT_COLORS.error,
        },
      },
    },

    fancyPersonBox: {
      container: BACKGROUNDS.levelMinusOne,
    },
    fancyDateBox: {
      background: {
        container: BASE_COLORS.whites[2],
        time: BASE_COLORS.whites[4],
      },
    },
  },
});
