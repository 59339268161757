import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { languageSelector } from '../state/recoilState';
import { useAuthState } from '../hooks/useAuthState/useAuthState';
import { TO } from '../constants/routes';

export const AuthorizedRoute: React.ElementType = (props: RouteProps) => {
  const language = useRecoilValue(languageSelector);
  const { isAuthorized } = useAuthState();
  const from = props.location && props.location.pathname;

  if (isAuthorized) {
    return <Route {...props} />;
  }

  return <Redirect to={{ pathname: TO.SIGN_IN[language], state: { from } }} />;
};
