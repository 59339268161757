import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { deleteHolidayAction } from 'api/actions/holidays/holidaysActions';
import { DeleteHolidayActionProps } from 'api/actions/holidays/holidaysActions.types';
import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useModal } from 'hooks/useModal/useModal';
import { refreshSelectorFamily } from 'state/settings';
import { createEvent } from 'utils/createEvent';
import { DeleteHolidayForm } from '../forms/DeleteHolidayForm';
import { addSnackbar } from 'SnackbarHub/actions';

export const DeleteHolidayModal = (): React.ReactElement => {
  useLingui();
  const refreshHolidaysList = useRecoilValue(refreshSelectorFamily('HOLIDAYS_LIST'));
  const { id } = useParams<{ id: string }>();
  const { handleClose } = useModal();
  const { mutate } = useMutation(deleteHolidayAction);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: DeleteHolidayActionProps): Promise<void> => {
      const { error: submitError } = await mutate(data);

      if (!submitError) {
        if (handleClose) {
          handleClose();
          refreshHolidaysList();
        }
        addSnackbar({
          message: t({
            id: 'settings.holiday.delete_success',
            message: 'Deleted holiday',
          }),
          variant: 'success',
        });
      }

      if (submitError) {
        addSnackbar({
          message: t({
            id: 'settings.holiday.delete_danger',
            message: "Couldn't delete holiday",
          }),
          variant: 'danger',
        });
      }
      setLoading(false);
    },
    [handleClose, mutate, refreshHolidaysList],
  );

  const handleDelete = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.holidays.delete_holiday">Delete holiday</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DeleteHolidayForm
          ref={formRef}
          id={id}
          onSubmit={onSubmit}
          setLoading={setLoading}
          setDisabled={setDisabled}
        />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleDelete,
            variant: 'danger',
            children: t({ id: 'delete', message: 'Delete' }),
            disabled,
          },
        ]}
      />
    </>
  );
};
