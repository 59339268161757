import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-fetching-library';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { Flex, Text } from 'theme-ui';
import { i18n } from '@lingui/core';

import { SignUpErrorCodes, SignUpPayload } from 'api/actions/auth/authActions.types';
import { signUpAction } from 'api/actions/auth/authActions';
import {
  startAuthorizing,
  setToken,
  setUnauthorized,
  setIsSignUpInProgress,
} from '../../context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from '../../hooks/useAuthDispatch/useAuthDispatch';
import { languageSelector } from '../../state/recoilState';
import { TO } from '../../constants/routes';
import { Main, FormCard, Aside } from 'layouts/Authentication';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { APP_NAME } from 'constants/common';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { signUpFormAtom } from 'state/signUp';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { SignUpForm } from './SignUpForm';
import { PrivacyInformation } from './components/PrivacyInformation';

export const SignUp = (): React.ReactElement => {
  const { push } = useHistory();
  const language = useRecoilValue(languageSelector);
  const resetSignUpForm = useResetRecoilState(signUpFormAtom);

  const { mutate } = useMutation(signUpAction);
  const dispatch = useAuthDispatch();

  const { addSnackbar } = useSnackbar();

  useEffect(() => {
    resetSignUpForm();
  }, [resetSignUpForm]);

  const onSubmit = useCallback(
    async (body: SignUpPayload): Promise<boolean> => {
      dispatch(startAuthorizing());

      const { payload, error: submitError } = await mutate(body);

      dispatch(setUnauthorized());

      if (!submitError && payload) {
        const { accessToken } = payload;
        dispatch(setToken(accessToken));
        dispatch(setIsSignUpInProgress(true));
        push(TO.SIGN_UP__ACCOUNT_TYPE[language], { signUp: { inProgress: true } });
        return true;
      }

      dispatch(setIsSignUpInProgress(false));

      if (submitError && payload?.innerCode) {
        resetSignUpForm();

        const { innerCode } = payload;

        if (innerCode === SignUpErrorCodes.USER_INVITED) {
          push(TO.SIGN_UP__EMPLOYEE__INVITED[language], {
            signUp: { inProgress: true, invitedEmployee: payload.data },
          });
          return true;
        }

        if (innerCode === SignUpErrorCodes.USER_ALREADY_REGISTER) {
          addSnackbar({
            message: i18n._(
              t({
                id: 'form.email_taken_error',
                message: 'This e-mail address is already taken 😥',
              }),
            ),
            variant: 'danger',
          });

          return false;
        }
      }

      addSnackbar({
        message: i18n._(
          t({
            id: 'form.default_error',
            message: 'Something went wrong 😥',
          }),
        ),
        variant: 'danger',
      });

      return false;
    },
    [dispatch, mutate, addSnackbar, push, language, resetSignUpForm],
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'sing_up.page_title', message: `Sign up - ${APP_NAME}` }))}</title>
      </Helmet>

      <Aside>
        <Flex sx={{ gap: 2, flexDirection: 'column' }}>
          <Aside.Card
            title={<Trans id="sign_up.promo.quick_start_title">Get started quickly</Trans>}
            content={
              <Trans id="sign_up.promo.quick_start">
                With our built-in automations you'll jump start your account in the matter of minutes.
              </Trans>
            }
            iconType="rocket"
          />
          <Aside.Card
            title={<Trans id="sign_up.promo.focus_title">Focus on your business</Trans>}
            content={
              <Trans id="sign_up.promo.focus">
                Prepare ready for payroll timesheets and schedules with single click.
              </Trans>
            }
            iconType="heartOutline"
          />
          <Aside.Card
            title={<Trans id="sign_up.promo.ditch_title">Ditch paper, notes and spreadsheets</Trans>}
            content={
              <Trans id="sign_up.promo.ditch">
                Replace them with all-in-one tool, and your team will be more effective and efficient.
              </Trans>
            }
            iconType="cloud"
          />
          <Aside.Card
            title={<Trans id="sign_up.promo.timeclock_title">Mobile work time clock</Trans>}
            content={
              <Trans id="sign_up.promo.timeclock">
                Turn any smartphone or tablet into a professional tool with our mobile apps.
              </Trans>
            }
            iconType="timer"
          />
        </Flex>
      </Aside>

      <Main sx={{ flexGrow: 0 }}>
        <Flex
          sx={{
            width: '100%',
            maxWidth: '480px',
            flexGrow: 1,
            gap: 5,
            flexDirection: 'column',
            alignItems: 'stretch',
            alignSelf: 'center',
            textAlign: 'center',
          }}
        >
          <FormCard>
            <FormCard.Header>
              <FormCard.Title>
                <Trans id="sign_up.lead.header">Good choice! 👍</Trans>
              </FormCard.Title>
              <FormCard.Lead>
                <Trans id="sign_up.lead.text">
                  Join over <Text sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>100.000</Text> satisfied users.
                </Trans>
              </FormCard.Lead>
            </FormCard.Header>

            <SignUpForm onSubmit={onSubmit} />
          </FormCard>

          <Text as="p" sx={{ alignSelf: 'center', color: 'texts.default' }}>
            <LinkAnchor to={TO.SIGN_IN[language]} sx={{ fontWeight: '700' }}>
              <Trans id="sign_up.sign_in_button">Sign in instead</Trans>
            </LinkAnchor>
          </Text>

          <Flex sx={{ gap: 2, flexDirection: 'column' }}>
            <Text as="p" sx={{ alignSelf: 'center', color: 'texts.lighter' }}>
              <Trans id="sign_up.terms_acceptance">
                Creating an account you accept our{' '}
                <LinkAnchor sx={{ textDecoration: 'underline' }} href={EXTERNAL_LINKS.TERMS} target="_blank">
                  Terms & Conditions
                </LinkAnchor>
                .
              </Trans>
            </Text>

            <PrivacyInformation />
          </Flex>
        </Flex>
      </Main>
    </>
  );
};
