import { ThemeUICSSObject } from 'theme-ui';

export function hexToRgba(hex: ThemeUICSSObject['color'] | string, alpha: number): string {
  if (!hex) return '';

  const r = parseInt(`${hex}`.slice(1, 3), 16);
  const g = parseInt(`${hex}`.slice(3, 5), 16);
  const b = parseInt(`${hex}`.slice(5, 7), 16);

  if (alpha || alpha === 0) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgba(${r}, ${g}, ${b})`;
}
