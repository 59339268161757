/** @jsxImportSource theme-ui */

import React, { useMemo, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useLingui } from '@lingui/react';

import { parsedEmployeesSelector } from 'state/employees';
import { Modal } from 'components/Modal/Modal';
import { delay } from 'utils/delay';
import { inviteEmployeesAction } from 'api/actions/employees/employeesActions';
import { TextInput } from 'components/ui/TextInput';
import { useModal } from 'hooks/useModal/useModal';
import { useSelectedIdsModalLogic } from '../hooks/useSelectedIdsModalLogic';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

type Props = {
  isInvite?: boolean;
};
const defaultProps: Partial<Props> = {
  isInvite: undefined,
};

export const ReInviteTeammatesModal = ({ isInvite }: Props): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { baseRoute, handleClose } = useModal();
  const employees = useRecoilValue(parsedEmployeesSelector);
  const { selectedIds, selectedNames, isMulti, loading, setLoading } = useSelectedIdsModalLogic(employees, [
    'name.firstName',
    'name.surname',
  ]);

  const modalTitle = useMemo(() => {
    const titleFirstPart = isInvite
      ? t({ id: 'team.reinvite.invite', message: 'Invite' })
      : t({ id: 'team.reinvite', message: 'Re-invite' });
    return isMulti
      ? t({ id: 'team.reinvite.employees', message: `${titleFirstPart} employees?` })
      : `${titleFirstPart} - ${selectedNames[0]}?`;
  }, [isInvite, isMulti, selectedNames]);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { mutate } = useMutation(inviteEmployeesAction);

  const userEmail = useMemo(() => {
    if (!isMulti) {
      const employeeToReInvite = employees?.get(selectedIds[0]);
      if (employeeToReInvite) {
        return employeeToReInvite.email;
      }
    }
    return null;
  }, [employees, selectedIds, isMulti]);

  const {
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userEmail,
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const handleReInvite = async () => {
    setLoading(true);

    const inviteEmployeesActionProps = !isMulti
      ? [
          {
            employeeId: selectedIds[0],
            ...(userEmail !== watch('userEmail') && {
              userEmail: watch('userEmail'),
            }),
          },
        ]
      : selectedIds.map((employeeId) => ({ employeeId }));

    const { error: submitError } = await mutate(inviteEmployeesActionProps);

    if (!submitError) {
      if (handleClose) {
        handleClose();
      }
    }
    setLoading(false);
    if (!submitError) {
      await delay(100);
      addSnackbar({
        message: t({ id: 'team.reinvite.reinvited', message: 'Successfully re-invited!' }),
        variant: 'success',
      });
    }
  };

  if (!selectedIds.length) {
    return <Redirect to={baseRoute} />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isMulti && (
          <>
            <Text>
              <Trans id="team.reinvite.single.please">Please,</Trans>
              <strong>
                {isInvite ? (
                  <Trans id="team.reinvite.single.type_email"> type in the correct e-mail address.</Trans>
                ) : (
                  <Trans id="team.reinvite.single.check_email"> check if this is a correct e-mail address.</Trans>
                )}
              </strong>
            </Text>
            <form sx={{ width: '340px', mt: 3 }} ref={formRef} noValidate>
              <TextInput
                size="sm"
                id="userEmail"
                placeholder={t({
                  id: 'global.forms.inputs.email',
                  message: 'E-mail address',
                })}
                clearable
                variant="rounded"
                type="email"
                autoComplete="email"
                error={!!errors.userEmail}
                errorMessage={errors?.userEmail?.message}
                icon="mail"
                {...register('userEmail', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
              />
            </form>

            <Text sx={{ display: 'block' }} mt={3}>
              {isInvite ? (
                <Trans id="team.reinvite.single.then">Then</Trans>
              ) : (
                <Trans id="team.reinvite.single.if_so">If so,</Trans>
              )}
              <Trans id="team.reinvite.single.hit_send">
                <strong> hit the Send button </strong>
                and we will take care of the rest. 😊
              </Trans>
            </Text>
          </>
        )}
        {isMulti && (
          <Text>
            <Trans id="team.reinvite.multi">
              You are re-inviting users: <strong>{selectedNames.join(', ')}</strong>
              <br />
              <strong> Hit the Send button </strong>
              and we will take care of the rest. 😊
            </Trans>
          </Text>
        )}
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            disabled: !!errors.userEmail && selectedIds.length <= 1,
            isLoading: loading,
            onClick: handleReInvite,
            variant: 'success',
            children: t({ id: 'team.reinvite.send', message: 'Send' }),
          },
        ]}
      />
    </>
  );
};

ReInviteTeammatesModal.defaultProps = defaultProps;
