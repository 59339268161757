/** @jsxImportSource theme-ui */

import React, { useRef } from 'react';
import { motion } from 'framer-motion';

import { ModalBackdropProps } from './types';

export const ModalBackdrop = ({ handleClose, children, sx, ...props }: ModalBackdropProps): React.ReactElement => {
  const modalBackdropRef = useRef<HTMLDivElement | null>(null);
  const shouldCloseRef = useRef(true);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    shouldCloseRef.current = modalBackdropRef.current === e.target;
  };
  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    shouldCloseRef.current = modalBackdropRef.current === e.target && shouldCloseRef.current;
  };
  const handleClick = () => {
    if (!shouldCloseRef.current) {
      shouldCloseRef.current = true;
      return;
    }
    if (handleClose) handleClose();
  };

  return (
    <motion.div
      ref={modalBackdropRef}
      {...props}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.075, delay: 0.065 } }}
      exit={{ opacity: 0, transition: { duration: 0.075 } }}
      sx={{
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        bg: 'modal.backdrop',
        ...sx,
      }}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {children}
    </motion.div>
  );
};
