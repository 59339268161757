import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet';
import { t, Trans } from '@lingui/macro';
import { Flex } from 'theme-ui';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { useMutation } from 'react-fetching-library';
import { useLingui } from '@lingui/react';

import { APP_NAME } from 'constants/common';
import { filteredEmployeesSelector, ParsedEmployee } from 'state/employees';
import { hiddenColumnsTeamViewSelector } from 'state/team';
import { Avatar } from 'components/Avatar/Avatar';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { InvitationBadge } from 'components/recipes/InvitationBadge';
import { StickyListProps } from 'components/StickyList/types';
import { InvitationStates } from 'api/actions/organizationSession/organizationSessionActions.types';
import { useTheme } from 'styles/useTheme';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Tag } from 'components/Tag/Tag';
import { LazyComponentType } from 'utils/custom.types';
import { CopyToClipboardText } from 'components/ui/CopyToClipboardText';
import { Button } from 'components/ui/Buttons';
import { FullName } from 'components/utils/FullName';
import { Icon } from 'components/Icon/Icon';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { activateEmployeesAction } from 'api/actions/employees/employeesActions';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { Badge } from 'components/Badge/Badge';
import { DropdownProps } from 'components/Dropdown/Dropdown';
import { Icons } from 'components/Icon/Icon.types';
import { userSessionPersonIdSelector } from 'state/userSession';
import { Main } from 'layouts/AuthorizedApp';
import { HeaderMenu } from 'layouts/Team';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';

import { ModalRoutes } from './ModalRoutes';

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<ParsedEmployee>>;

const ButtonWithDropdown = withDropdown(Button);

export const Team = React.memo((): React.ReactElement => {
  const userId = useRecoilValue(userSessionPersonIdSelector);
  const language = useRecoilValue(languageSelector);
  const hiddenColumns = useRecoilValue(hiddenColumnsTeamViewSelector);
  const filteredEmployees = useRecoilValue(filteredEmployeesSelector);

  useLingui();
  const { addSnackbar } = useSnackbar();
  const getFullName = useNameDisplayOrder();
  const { push } = useHistory();
  const match = useCleanRouteMatch();
  const { theme } = useTheme();

  const { mutate: activateEmployee } = useMutation(activateEmployeesAction);

  const columns: StickyListProps<ParsedEmployee>['columns'] = [
    {
      key: ['avatarUrl', 'name'],
      customCellRenderer: (items: [ParsedEmployee['avatarUrl'], ParsedEmployee['name']]) => (
        <Avatar size={24} image={items[0]} name={items[1]} />
      ),
      width: '24px',
    },
    {
      title: t({ id: 'team.list.employee_name', message: `Name` }),
      key: 'name',
      sortableValue: ({ firstName, surname }: ParsedEmployee['name']) => getFullName(firstName, surname),
      customCellRenderer: ({ firstName, surname }: ParsedEmployee['name']) => (
        <TextEllipsis title={getFullName(firstName, surname)}>
          <FullName firstName={firstName} surname={surname} />
        </TextEllipsis>
      ),
      columnGrow: 2,
    },
    {
      title: t({ id: 'team.list.role_tags', message: `Role & Tags` }),
      key: ['role', 'tags'],
      sortableValue: (items: [ParsedEmployee['role'], ParsedEmployee['tags']]) =>
        _.map(items, (item) => {
          if (_.isArray(item)) {
            return _.flatMap(
              _.orderBy(item, (x) => x?.name),
              (tag) => tag && tag.name,
            );
          }
          return item.name;
        }).join(' '),

      customCellRenderer: (items: [ParsedEmployee['role'], ParsedEmployee['tags']]) =>
        _.map(items, (item) => {
          if (_.isArray(item)) {
            return _.flatMap(
              _.orderBy(item, ['name']),
              (tag) =>
                tag && (
                  <Tag key={tag.name} title={tag.name} color={UserSelectableColor[tag.color]}>
                    {tag.name}
                  </Tag>
                ),
            );
          }
          if (!item) return null;
          return (
            <Tag
              key={item.name}
              variant="outline"
              sx={{
                flex: '0 0 auto',
              }}
              color={UserSelectableColor[item.color]}
            >
              {t({
                id: item.name,
              })}
            </Tag>
          );
        }),
      columnGrow: 2,
    },
    {
      title: t({ id: 'team.list.position', message: `Position` }),
      key: 'workPosition',
      sortableValue: (item: ParsedEmployee['workPosition']) => `${item}`,
      customCellRenderer: (workPosition: ParsedEmployee['workPosition']) => (
        <TextEllipsis title={workPosition}>{workPosition}</TextEllipsis>
      ),
    },
    {
      title: t({ id: 'team.list.email', message: `Email` }),
      key: 'email',
      sortableValue: (item: ParsedEmployee['email']) => `${item}`,
      customCellRenderer: (email: ParsedEmployee['email']) => (
        <CopyToClipboardText ellipsis>{email}</CopyToClipboardText>
      ),
    },
    {
      title: t({ id: 'team.list.phone', message: `Phone` }),
      key: 'phoneNumber',
      sortableValue: (item: ParsedEmployee['phoneNumber']) => `${item}`,
      customCellRenderer: (phone: ParsedEmployee['phoneNumber']) => (
        <CopyToClipboardText ellipsis>{phone}</CopyToClipboardText>
      ),
    },
    {
      title: t({ id: 'team.list.employee_id', message: `Employee ID` }),
      key: 'customEmployeeId',
      sortableValue: (item: ParsedEmployee['customEmployeeId']) => `${item}`,
    },
    {
      title: t({ id: 'team.list.employee_app_state', message: `State` }),
      key: ['invitationState', 'isActive', 'isHidden'],
      sortableValue: (
        item: [ParsedEmployee['invitationState'], ParsedEmployee['isActive'], ParsedEmployee['isHidden']],
      ) => `${item}`,
      customCellRenderer: (
        item: [ParsedEmployee['invitationState'], ParsedEmployee['isActive'], ParsedEmployee['isHidden']],
        id: string,
      ) => {
        const handleOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
          e.stopPropagation();
          push(`${match}${TO.RE_INVITE_TEAMMATES_MODAL[language]}`, {
            ids: [id],
          });
        };
        if (!item) return null;
        return (
          <>
            {item[0] && (
              <InvitationBadge
                invitationState={item[0]}
                {...(item[0] === InvitationStates.EXPIRED && id !== userId && { onClick: handleOnClick })}
              />
            )}
            {!item[1] && (
              <Badge title={t({ id: 'team.badge.deactivated', message: 'Deactivated' })}>
                <Trans id="team.badge.deactivated">Deactivated</Trans>
              </Badge>
            )}
            {item[2] && (
              <Badge title={t({ id: 'team.badge.hidden', message: 'Hidden' })}>
                <Trans id="team.badge.hidden">Hidden</Trans>
              </Badge>
            )}
          </>
        );
      },
      columnGrow: 2,
    },
    {
      key: ['isActive', 'email', 'invitationState'],
      width: '40px',
      sx: {
        overflow: 'visible',
      },
      customCellRenderer: (
        [isActive, email, invitationState]: [
          ParsedEmployee['isActive'],
          ParsedEmployee['email'],
          ParsedEmployee['invitationState'],
        ],
        itemId,
      ) => {
        const shouldShowInviteButton = (() => {
          if (!isActive) return false;
          if (invitationState) return true;
          if (email) return false;
          return true;
        })();

        const restrictedLinks: DropdownProps['links'] = [
          {
            disabled: userId === itemId,
            prependWithDivider: true,
            label: t({ id: 'team.list.delete_employee', message: 'Delete' }),
            icon: 'delete',
            to: {
              pathname: `${match}${TO.DELETE_TEAMMATES_MODAL[language]}`,
              state: { ids: [itemId] },
            },
          },
          isActive
            ? {
                disabled: userId === itemId,
                label: t({ id: 'team.list.deactivate_employee', message: 'Deactivate' }),
                icon: 'lock',
                to: {
                  pathname: `${match}${TO.DEACTIVATE_TEAMMATES_MODAL[language]}`,
                  state: { ids: [itemId] },
                },
              }
            : {
                disabled: userId === itemId,
                label: t({ id: 'team.list.activate_employee', message: 'Activate' }),
                icon: 'unLock',
                onClick: async () => {
                  const { error: submitError } = await activateEmployee([itemId]);
                  if (!submitError) {
                    addSnackbar({
                      message: t({ id: 'team.list.activated_success', message: 'Successfully activated!' }),
                      variant: 'success',
                    });
                  }
                },
              },
          ...(!shouldShowInviteButton
            ? []
            : [
                {
                  disabled: userId === itemId,
                  prependWithDivider: true,
                  label: email
                    ? t({ id: 'team.list.reinvite', message: 'Re-invite' })
                    : t({ id: 'team.list.invite', message: 'Invite' }),
                  icon: 'send' as Icons,
                  to: {
                    pathname: `${match}${TO[email ? 'RE_INVITE_TEAMMATES_MODAL' : 'INVITE_TEAMMATES_MODAL'][language]}`,
                    state: { ids: [itemId] },
                  },
                },
              ]),
        ];
        return (
          <ButtonWithDropdown
            dropdownProps={{
              links: [
                {
                  label: t({ id: 'team.list.print_qr', message: 'Print QR card' }),
                  icon: 'print',
                  to: {
                    pathname: `${match}${TO.PRINT_QR_CARDS_MODAL[language]}`,
                    state: { ids: [itemId] },
                  },
                },
                ...(userId !== itemId ? restrictedLinks : []),
              ],
            }}
            shape="rounded"
            size="sm"
            variant="minimal"
            onClick={(e) => e.stopPropagation()}
          >
            <Icon type="more" />
          </ButtonWithDropdown>
        );
      },
    },
  ];

  const visibleColumns = columns.filter((column) => {
    if (_.includes(hiddenColumns, `${column.key}`)) {
      return false;
    }

    if (_.includes(hiddenColumns, 'roleAndTags') && _.isEqual(column.key, ['role', 'tags'])) {
      return false;
    }

    return true;
  });

  const fallbackSortableValueGetter = useCallback(
    ({ name: { firstName, surname } }: ParsedEmployee) => getFullName(firstName, surname),
    [getFullName],
  );

  const handleOnRowClick: StickyListProps['onRowClick'] = (id) => {
    push(`${match}${TO.EDIT_TEAMMATE_MODAL__ID[language]}/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>{t({ id: 'team.page_title', message: `Team - ${APP_NAME}` })}</title>
      </Helmet>

      <Main>
        <Main.Header>
          <Main.Header.Title>
            <Trans id="team.heading">Your team</Trans>
            <span> ({filteredEmployees.size})</span>
          </Main.Header.Title>
          <Main.Header.Deselect selectorFamilyID="TEAM" />

          <HeaderMenu selectorFamilyID="TEAM" />
        </Main.Header>

        <Flex sx={{ flexGrow: 1, mx: -4 }}>
          <React.Suspense
            fallback={
              <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <LoadingSpinnerCss size={4} color="#465F86" />
              </Flex>
            }
          >
            <LazyStickyList
              name="TEAM"
              select="checkbox"
              list={filteredEmployees}
              columns={visibleColumns}
              onRowClick={handleOnRowClick}
              emptyListMessage={t({ id: 'team.list.empty', message: 'No matches found. 😟' })}
              showHeader
              showContentPlaceholder
              fallbackSortableValueGetter={fallbackSortableValueGetter}
              style={{
                paddingLeft: theme.space[4],
                paddingRight: theme.space[4],
              }}
            />
          </React.Suspense>
        </Flex>
      </Main>
      <ModalRoutes />
    </>
  );
});
