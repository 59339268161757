import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';
import { i18n } from '@lingui/core';
import { useHistory } from 'react-router';

import { TextInput } from 'components/ui/TextInput';
import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Icon } from 'components/Icon/Icon';
import { FormCard } from 'layouts/Authentication';
import { AccountInfoCompany } from 'api/actions/user/userActions.types';
import { Select } from 'components/ui/Select/Select';
import { typedKeys } from 'utils/typedKeys';
import { industryListAtom, signUpFormAtom, timeZoneListAtom } from 'state/signUp';
import { REAL_LOCALE, REAL_TIMEZONE } from 'constants/common';
import { CountrySelect } from 'components/ui/CountrySelect/CountrySelect';
import { TimeZoneSelect } from 'components/ui/TimeZoneSelect/TimeZoneSelect';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

type Props = {
  onSubmit: (props: AccountInfoCompany) => Promise<boolean>;
};

export const EmployerStep2Form = React.forwardRef<HTMLFormElement, Props>(({ onSubmit }: Props, ref) => {
  const [signUpFormState, setSignUpFormState] = useRecoilState(signUpFormAtom);
  const timeZoneList = useRecoilValue(timeZoneListAtom);
  const fullIndustryList = useRecoilValue(industryListAtom);

  const [industryList] = useState<{ label: string; id: string }[]>(
    fullIndustryList ? fullIndustryList.map((ind) => ({ label: ind.label, id: ind.id })) : [],
  );
  const [industrySubcategoryList, setIndustrySubcategoryList] = useState<{ label: string; id: string }[]>(
    signUpFormState?.industrySubcategory && fullIndustryList
      ? fullIndustryList.find((l) => `${l.id}` === signUpFormState?.industry)?.subcategories || []
      : [],
  );

  const { go } = useHistory();

  const {
    getValues,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: signUpFormState?.companyName,
      ...(signUpFormState?.industry && { industry: signUpFormState?.industry }),
      industrySubcategory: signUpFormState?.industrySubcategory,
      timeZoneId: signUpFormState?.timeZoneId || timeZoneList?.find((tz) => tz.id === REAL_TIMEZONE)?.id,
      countryCode: signUpFormState?.countryCode || REAL_LOCALE,
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const watchIndustry = watch('industry');

  useEffect(() => {
    if (watchIndustry && fullIndustryList) {
      setIndustrySubcategoryList(
        fullIndustryList.find((l) => l.id === watchIndustry)
          ? fullIndustryList.find((l) => l.id === watchIndustry)?.subcategories || []
          : [],
      );
    }
  }, [fullIndustryList, watchIndustry]);

  const saveToRecoilCallback = useCallback(() => {
    let formValues = getValues();
    if (formValues.industry !== watchIndustry) {
      setValue('industrySubcategory', undefined);
      formValues = getValues();
    }
    let validFormValues = {};

    typedKeys(formValues).forEach((name) => {
      if (errors[name] && formValues[name] !== '') {
        return;
      }
      if (name === 'timeZoneId' && timeZoneList) {
        const timeZoneObject = timeZoneList.find((tz) => tz.id === formValues.timeZoneId);
        validFormValues = {
          ...validFormValues,
          timeZoneId: timeZoneObject ? timeZoneObject.id : formValues.timeZoneId,
        };
        return;
      }

      validFormValues = {
        ...validFormValues,
      };

      setSignUpFormState({
        ...signUpFormState,
        ...validFormValues,
      });
    });
  }, [getValues, watchIndustry, setValue, errors, timeZoneList, setSignUpFormState, signUpFormState]);

  const handleSubmitCallback = useCallback(
    async (formProps: AccountInfoCompany) => {
      await onSubmit(formProps);
    },
    [onSubmit],
  );

  const handleGoBack = () => {
    go(-1);
  };

  return (
    <form ref={ref} onChange={saveToRecoilCallback} onSubmit={handleSubmit(handleSubmitCallback)} noValidate>
      <ElementGroup marginValue="4" direction="column">
        <TextInput
          id="companyName"
          placeholder={i18n._(
            t({
              id: 'sign_up.employer.form.company_name',
              message: 'Name of your company',
            }),
          )}
          type="text"
          error={!!errors.companyName}
          errorMessage={errors?.companyName?.message}
          {...register('companyName', validationFactory({ ...VALIDATION_RULES.COMPANY_NAME, required: true }))}
        />

        <CountrySelect
          id="countryCode"
          label={i18n._(
            t({
              id: 'sign_up.employer.form.country',
              message: 'Country',
            }),
          )}
          placeholder={i18n._(
            t({
              id: 'sign_up.employer.form.country',
              message: 'Country',
            }),
          )}
          searchable
          error={!!errors.countryCode}
          errorMessage={errors?.countryCode?.message}
          {...register('countryCode', { required: i18n._('global.forms.required') })}
        />

        <TimeZoneSelect
          id="timeZoneId"
          label={i18n._(
            t({
              id: 'organization_settings.time_zone_id.label',
            }),
          )}
          placeholder={i18n._(
            t({
              id: 'organization_settings.time_zone_id.label',
            }),
          )}
          searchable
          error={!!errors.timeZoneId}
          errorMessage={errors?.timeZoneId?.message}
          {...register('timeZoneId', { required: i18n._('global.forms.required') })}
        />

        <Flex sx={{ flexDirection: 'column', gap: '2px' }}>
          <Select
            id="industry"
            label={i18n._(
              t({
                id: 'sign_up.employer.form.industry',
                message: 'Industry',
              }),
            )}
            placeholder={i18n._(
              t({
                id: 'global.forms.pick',
                message: 'Pick one',
              }),
            )}
            variant={industrySubcategoryList.length > 0 ? 'roundedTop' : 'rounded'}
            error={!!errors.industry}
            errorMessage={errors?.industry?.message}
            options={industryList}
            {...register('industry', { required: i18n._('global.forms.required') })}
          />

          {!!watchIndustry && industrySubcategoryList.length > 0 && (
            <Select
              id="industrySubcategory"
              placeholder={i18n._(
                t({
                  id: 'sign_up.employer.form.industry_sub',
                  message: 'Additional industry category (optional)',
                }),
              )}
              variant="roundedBottom"
              error={!!errors.industrySubcategory}
              errorMessage={errors?.industrySubcategory?.message}
              clearable
              options={industrySubcategoryList}
              {...register('industrySubcategory')}
            />
          )}
        </Flex>
      </ElementGroup>

      <FormCard.Footer>
        <Button variant="minimal" shape="rounded" size="lg" type="button" onClick={handleGoBack}>
          <Trans id="global.forms.buttons.back" />
        </Button>

        <Button variant="primary" apendWith={<Icon type="arrowRight" />} size="lg" type="submit" shape="rounded">
          <Trans id="global.forms.buttons.next" />
        </Button>
      </FormCard.Footer>
    </form>
  );
});
