import { atom } from 'recoil';

export type CameraState = {
  isCameraReady: boolean;
  isCameraStreaming: boolean;
  toggleVideoPlayback?: (state?: boolean) => void;
  source?: {
    width: number;
    height: number;
    video: HTMLVideoElement;
    stream: MediaStreamTrack;
  };
};

export const cameraStateAtom = atom<CameraState>({
  key: 'kiosk__cameraState',
  default: {
    isCameraReady: false,
    isCameraStreaming: false,
  },
});
