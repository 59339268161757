import { atom, selector } from 'recoil';

import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

type Props = {
  nightMode: boolean;
  debugMode: boolean;
};

export const stateAtom = atom<Props>({
  key: 'kiosk__stateAtom',
  default: {
    nightMode: false,
    debugMode: false,
  },
});

export const nightModeSelector = selector<boolean>({
  key: 'kiosk__nightMode',
  get: ({ get }) => {
    const { nightMode } = get(stateAtom);

    return nightMode;
  },
  set: ({ get, set }, newState) => {
    const { nightMode } = get(stateAtom);

    if (newState !== nightMode && !isRecoilDefaultValue(newState)) {
      set(stateAtom, (prevState) => ({ ...prevState, nightMode: newState }));
    }
  },
});

export const debugModeSelector = selector<boolean>({
  key: 'kiosk__debugMode',
  get: ({ get }) => {
    const { debugMode } = get(stateAtom);

    return debugMode;
  },
  set: ({ get, set }, newState) => {
    const { debugMode } = get(stateAtom);

    if (newState !== debugMode && !isRecoilDefaultValue(newState)) {
      set(stateAtom, (prevState) => ({ ...prevState, debugMode: newState }));
    }
  },
});
