import { Action } from 'react-fetching-library';

import { API_ENDPOINTS } from 'constants/api';

import {
  FetchRequestsResponse,
  GetTimeOffRemainingLimitsResponse,
  GetTimeOffRemainingLimitsActionProps,
  FetchRequestDetailsResponse,
  FetchRequestsOverviewForTimeRangeResponse,
  AcceptRequestManagementResponse,
  AcceptRequestManagementActionProps,
  DeleteRequestManagementActionProps,
  DeleteRequestManagementResponse,
  RejectRequestManagementActionProps,
  RejectRequestManagementResponse,
  AddRequestActionProps,
  AddRequestResponse,
  GetCustomRemainingLimitsActionProps,
  GetCustomRemainingLimitsResponse,
  FetchApprovalListsResponse,
  FetchApprovalListsProps,
  RequestGetDataToModifyResponse,
  RequestGetDataToModifyActionProps,
  RequestPrintActionProps,
  RequestPrintResponse,
} from './requestsActions.types';

export function fetchRequestsAction(): Action<FetchRequestsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.requests,
  };
}

export function acceptRequestManagementAction(
  ids: AcceptRequestManagementActionProps,
): Action<AcceptRequestManagementResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.acceptRequestManagement}/${ids[0]}`,
  };
}

export function rejectRequestAction({
  id,
  ...body
}: RejectRequestManagementActionProps): Action<RejectRequestManagementResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.rejectRequestManagement}/${id}`,
    body,
  };
}

export function deleteRequestsAction(ids: DeleteRequestManagementActionProps): Action<DeleteRequestManagementResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.deleteRequestManagement}/${ids[0]}`,
  };
}

export function addRequestAction(body: AddRequestActionProps): Action<AddRequestResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.addRequest,
    body,
    config: {
      skipResend: true,
    },
  };
}

export function fetchRequestForTimeRangeAction(year: string): Action<FetchRequestsOverviewForTimeRangeResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.requestOverview}/${year}`,
  };
}

export function fetchRequestDetailsAction(id: string): Action<FetchRequestDetailsResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.requests}/${id}`,
  };
}

export function requestTimeOffRemainingLimitsAction(
  body: GetTimeOffRemainingLimitsActionProps,
): Action<GetTimeOffRemainingLimitsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeOffRemainingLimits,
    body,
  };
}

export function requestCustomRemainingLimitsAction(
  body: GetCustomRemainingLimitsActionProps,
): Action<GetCustomRemainingLimitsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.customRemainingLimits,
    body,
  };
}

export function fetchApprovalListsAction({
  personId,
  requestType,
}: FetchApprovalListsProps): Action<FetchApprovalListsResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.getApprovalLists}/${personId}/${requestType}`,
  };
}

export function requestGetDataToModifyAction(
  body: RequestGetDataToModifyActionProps,
): Action<RequestGetDataToModifyResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestGetDataToModify,
    body,
  };
}

export function requestPrintAction(body: RequestPrintActionProps): Action<RequestPrintResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.printRequests,
    body,
  };
}

export function requestDownloadAction(body: RequestPrintActionProps): Action<RequestPrintResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.printRequests,
    body,
    headers: {
      'X-Download': 'true',
    },
  };
}
