import type { AuthStateContextProps } from '../authContext/AuthContext.types';

import type { AuthAction } from './authReducer.types';

export const SET_AUTHORIZED = 'auth/set-authorized';
export const SET_UNAUTHORIZED = 'auth/set-unauthorized';
export const SET_IS_SIGN_UP_IN_PROGRESS = 'auth/set-isSignUpInProgress';
export const SET_USER = 'auth/set-user';
export const SET_TOKEN = 'auth/set-token';
export const START_AUTHORIZING = 'auth/start-authorizing';
export const SIGN_OUT = 'auth/sing-out';

export const authReducer: (state: AuthStateContextProps, action: AuthAction) => AuthStateContextProps = (
  state,
  action,
) => {
  const behaviours: Record<string, (state: AuthStateContextProps, action: AuthAction) => AuthStateContextProps> = {
    [START_AUTHORIZING]: (oldState) => ({
      ...oldState,
      isAuthorizing: true,
    }),
    [SET_AUTHORIZED]: (oldState) => ({
      ...oldState,
      errorMessage: null,
      isAuthorizing: false,
      isAuthorized: true,
      isSignUpInProgress: false,
    }),
    [SET_USER]: (oldState, { user }) => ({
      ...oldState,
      user,
    }),
    [SET_IS_SIGN_UP_IN_PROGRESS]: (oldState, { isSignUpInProgress }) => ({
      ...oldState,
      isSignUpInProgress: !!isSignUpInProgress,
    }),
    [SET_UNAUTHORIZED]: (oldState) => ({
      ...oldState,
      user: undefined,
      isAuthorizing: false,
      isAuthorized: false,
    }),
    [SIGN_OUT]: (oldState) => ({
      ...oldState,
      errorMessage: action.errorMessage ? action.errorMessage : null,
      user: undefined,
      isAuthorizing: false,
      isAuthorized: false,
      accessToken: null,
    }),
    [SET_TOKEN]: (oldState) => {
      if (!action.accessToken) {
        throw new Error('Missing access token in authReducer');
      }

      return {
        ...oldState,
        accessToken: action.accessToken,
      };
    },
  };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};
