import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Flex } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';

import { Main, FormCard } from 'layouts/Authentication';
import { APP_NAME } from 'constants/common';
import { postResetPasswordAction } from 'api/actions/password/passwordActions';
import { PostResetPasswordPayload } from 'api/actions/password/passwordActions.types';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { QueryParams } from 'constants/queryParams';
import { useQuery as useParams } from 'hooks/useQuery/useQuery';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';

import { PassResetWizardForm } from './PassResetWizardForm';

export const PassResetWizard = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  const { replace } = useHistory();
  const { addSnackbar } = useSnackbar();
  const { mutate } = useMutation(postResetPasswordAction);

  const urlParams = useParams();
  const token = urlParams.get(QueryParams.Token);
  const userId = urlParams.get(QueryParams.UserId);

  useEffect(() => {
    if (!token || !userId) {
      addSnackbar({
        variant: 'danger',
        message: i18n._(
          t({
            id: 'password_reset_wizard.error.missing_query',
            message: 'Missing data, check the reset link.',
          }),
        ),
      });

      replace(TO.SIGN_IN[language]);
    }
  }, [addSnackbar, language, replace, token, userId]);

  const onSubmit = useCallback(
    async (body: Pick<PostResetPasswordPayload, 'password' | 'confirmPassword'>): Promise<boolean> => {
      if (!token || !userId) return false;

      const { payload, error } = await mutate({
        ...body,
        token,
        userId,
      });

      if (!error) {
        addSnackbar({
          variant: 'success',
          message: i18n._(
            t({
              id: 'password_reset_wizard.success',
              message: 'Password is changed successfully.',
            }),
          ),
        });

        replace(TO.SIGN_IN[language]);

        return true;
      }

      if (payload?.innerCode === ErrorInnerCodes.UserResetPasswordError) {
        addSnackbar({
          variant: 'danger',
          message: i18n._(
            t({
              id: 'password_reset_wizard.error.userResetPasswordError',
              message: 'Reset link is expired. Generate new link.',
            }),
          ),
        });

        replace(TO.SIGN_IN[language]);

        return false;
      }

      addSnackbar({
        variant: 'danger',
        message: i18n._(
          t({
            id: 'form.default_error',
          }),
        ),
      });

      return false;
    },
    [addSnackbar, language, mutate, replace, token, userId],
  );

  return (
    <>
      <Helmet>
        <title>
          {i18n._(t({ id: 'password_reset_wizard.page_title', message: `Create new password - ${APP_NAME}` }))}
        </title>
      </Helmet>

      <Main>
        <Flex
          sx={{
            width: '100%',
            maxWidth: '480px',
            flexGrow: 1,
            gap: 5,
            flexDirection: 'column',
            alignItems: 'stretch',
            alignSelf: 'center',
            textAlign: 'center',
          }}
        >
          <FormCard>
            <FormCard.Header>
              <FormCard.Title>
                <Trans id="password_reset_wizard.title">Create new password</Trans>
              </FormCard.Title>
              <FormCard.Lead>
                <Trans id="password_reset_wizard.lead">
                  Use 8 or more characters with a mix of letters, numbers & symbols.
                </Trans>
              </FormCard.Lead>
            </FormCard.Header>

            <PassResetWizardForm onSubmit={onSubmit} />
          </FormCard>
        </Flex>
      </Main>
    </>
  );
};
