import React from 'react';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Badge, BadgeProps } from 'components/Badge/Badge';
import { InvitationStates } from 'api/actions/organizationSession/organizationSessionActions.types';

type Props = {
  invitationState: InvitationStates;
} & Omit<BadgeProps, 'size' | 'variant' | 'icon' | 'title' | 'sx' | 'children'>;

export const InvitationBadge = ({ invitationState, ...props }: Props): React.ReactElement => {
  useLingui();
  const invitationStateMessage = (() => {
    switch (invitationState) {
      case InvitationStates.PENDING:
        return t({
          id: 'employee_state.pending',
          message: 'Invitation pending',
        });

      case InvitationStates.EXPIRED:
        return t({
          id: 'employee_state.expired',
          message: 'Invitation expired. Re-send?',
        });

      default:
        return '';
    }
  })();
  return (
    <Badge
      variant={invitationState === InvitationStates.EXPIRED ? 'warning' : 'default'}
      title={invitationStateMessage}
      {...props}
    >
      {invitationStateMessage}
    </Badge>
  );
};
