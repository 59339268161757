import { ThemeUICSSObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

import { THEME_NAVBAR_HEIGHT } from './base';

const NAVBAR_HEIGHT = parseInt(THEME_NAVBAR_HEIGHT, 10);

const workTimerContainerSx: ThemeUICSSObject = {
  width: '120px',
  height: '40px',
  borderRadius: 'pill',
  p: '0.125rem',
  pr: 2,
  flexShrink: 0,
  gap: 1,
  alignItems: 'center',
  bg: 'workTimer.background.container.default',
  userSelect: 'none',
  position: 'relative',
  transition: 'all .2s ease-in-out',
};

const workTimerContentSx: ThemeUICSSObject = {
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'center',
  color: 'workTimer.text.default',
};

const notificationContainerSx: ThemeUICSSObject = {
  p: 3,
  maxWidth: '100%',
  width: '320px',
  minWidth: '100%',
  borderRadius: 'default',
  transition: 'background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  color: 'notification.text.default',
};

const alertSx: ThemeUICSSObject = {
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: 'default',
  fontWeight: 'bold',
  px: 4,
  py: '0.75rem',
};

const badgeBaseSx: ThemeUICSSObject = {
  alignItems: 'center',
  gap: '0.125rem',
  borderRadius: 'pill',
  color: 'rgba(14, 23, 55, 0.5)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: 1,
  px: 1,
  py: '0.125rem',
  '> [data-text]': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    pt: '0.0625rem',
  },
};

const badge = {
  size: {
    sm: {
      fontSize: 0,
    },
    default: {
      fontSize: 1,
    },
    lg: {
      px: 2,
      py: 1,
      fontSize: 2,
    },
  },
  default: {
    ...badgeBaseSx,
    bg: 'badge.default',
  },
  info: {
    ...badgeBaseSx,
    bg: 'badge.info',
  },
  success: {
    ...badgeBaseSx,
    bg: 'badge.success',
  },
  danger: {
    ...badgeBaseSx,
    bg: 'badge.danger',
  },
  warning: {
    ...badgeBaseSx,
    bg: 'badge.warning',
  },
};

const workStatusBaseSx: ThemeUICSSObject = {
  alignItems: 'center',
  gap: 1,
  borderRadius: '3000px',
  borderTopLeftRadius: 'pill',
  borderBottomLeftRadius: 'pill',
  fontWeight: 'bold',
  lineHeight: '21px',
  padding: '0 6px 0 0',
  fontSize: 2,
  color: 'rgba(14, 23, 55, 0.6)',
  fill: 'rgba(14, 23, 55, 0.6)',
};

const workStatus = {
  size: {
    sm: {
      fontSize: 0,
      lineHeight: '15px',
    },
  },
  additional: {
    ...workStatusBaseSx,
    bg: 'workStatus.background.additional',
  },
  main: {
    isStart: {
      ...workStatusBaseSx,
      bg: 'workStatus.background.main.isStart',
    },
    isEnd: {
      ...workStatusBaseSx,
      bg: 'workStatus.background.main.isEnd',
    },
  },
};

const tagBaseSx: ThemeUICSSObject = {
  alignItems: 'center',
  borderRadius: 'pill',
  color: 'rgba(14, 23, 55, 0.5)',
  fontSize: 1,
  fontWeight: 'bold',
  lineHeight: 1,
  px: 1,
  py: '0.125rem',
  '> [data-text]': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    pt: '0.125rem',
  },
};

const tag = {
  solid: {
    ...tagBaseSx,
  },
  outline: {
    ...tagBaseSx,
    borderWidth: '0.0625rem',
    borderStyle: 'solid',
  },
};

export const customComponents = makeTheme({
  badge,
  tag,
  workStatus,
  notifications: {
    container: {
      notificationHub: {
        ...notificationContainerSx,
        backdropFilter: 'blur(8px)',
        bg: 'notification.background.hub',
        boxShadow: 'notificationHub',
        '&:hover, &:focus': {
          bg: 'notification.background.hover',
          boxShadow: 'dropShadowDarker.levelTwo',
        },
      },
      notificationCenter: {
        ...notificationContainerSx,
        bg: 'notification.background.center',
        width: '100%',
        '&:hover, &:focus': {
          bg: 'notification.background.centerHover',
          boxShadow: 'dropShadow.levelZero',
        },
      },
    },
    title: {
      fontSize: 2,
      color: 'notification.text.default',
      lineHeight: 'notification.title',
    },
    date: {
      minWidth: 'auto',
      fontSize: 0,
      pl: 2,
      textAlign: 'right',
      lineHeight: 'notification.date',
      whiteSpace: 'nowrap',
      color: 'notification.text.date',
    },
    message: {
      color: 'notification.text.default',
      fontSize: 2,
      lineHeight: 'notification.message',
    },
  },
  snackbars: {
    default: {
      bg: 'snackbar.default.background',
      color: 'snackbar.default.text',
    },
    success: {
      bg: 'snackbar.success.background',
      color: 'snackbar.success.text',
    },
    warning: {
      bg: 'snackbar.warning.background',
      color: 'snackbar.warning.text',
    },
    danger: {
      bg: 'snackbar.danger.background',
      color: 'snackbar.danger.text',
    },
  },
  modal: {
    xs: {
      width: '576px',
    },
    sm: {
      width: '576px',
    },
    default: {
      width: '768px',
    },
    lg: {
      width: '992px',
    },
    xl: {
      width: '1200px',
    },
    fullscreen: {
      width: '100%',
    },
  },
  dropdown: {
    sm: {
      '& .group-title': {
        fontSize: 0,
      },
      linkSx: {
        py: '0.3rem',
        px: '0.65rem',
        fontSize: 1,
        '& .additional-info': {
          fontSize: 0,
          lineHeight: '12px',
        },
      },
    },
    default: {
      '& .group-title': {
        fontSize: 1,
      },
      linkSx: {
        py: '0.5rem',
        px: '0.75rem',
        fontSize: 2,
        // lineHeight: 1,
        '& .additional-info': {
          fontSize: 1,
          lineHeight: '14px',
        },
      },
    },
  },
  popper: {
    container: {
      zIndex: 'popper',
      '&[data-popper-placement="bottom"]': {
        '& .arrow': {
          left: '0',
          top: '-4px',
        },
      },
      '&[data-popper-placement="top"]': {
        '& .arrow': {
          bottom: '-4px',
          left: '0',
        },
      },
      '&[data-popper-placement="right"]': {
        '& .arrow': {
          left: '-4px',
          top: '0',
        },
      },
      '&[data-popper-placement="left"]': {
        '& .arrow': {
          right: '-4px',
          top: '0',
        },
      },
      '&[data-popper-placement="bottom-start"]': {
        '& .arrow': {
          left: '-4px',
          top: '-2px',
        },
      },
      '&[data-popper-placement="bottom-end"]': {
        '& .arrow': {
          left: '2.5px',
          top: '-1px',
        },
      },
      '&[data-popper-placement="top-end"]': {
        '& .arrow': {
          left: '3.5px',
          bottom: '-2px',
        },
      },
      '&[data-popper-placement="top-start"]': {
        '& .arrow': {
          left: '-3.3px',
          bottom: '-1px',
        },
      },
    },
    arrow: {
      width: '8px',
      height: '8px',
      bg: 'tooltip.background',
      borderBottomRightRadius: '3px',
      borderTopLeftRadius: '3px',
    },
  },

  tooltip: {
    container: {
      bg: 'tooltip.background',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 'xs',
      boxShadow: 'tooltip',
      maxWidth: '192px',
      py: '0.125rem',
      px: 1,
      height: 'auto',
    },
    content: {
      color: 'tooltip.text',
      wordBreak: 'break-all',
      fontSize: 1,
      lineHeight: 'tooltip',
      textAlign: 'center',
      zIndex: 1,
    },
  },

  alerts: {
    warning: {
      ...alertSx,
      bg: 'alerts.background.warning',
    },
    info: {
      ...alertSx,
      bg: 'alerts.background.info',
      color: 'alerts.text.info',
    },
    danger: {
      ...alertSx,
      bg: 'alerts.background.danger',
      color: 'alerts.text.danger',
    },
  },

  drawer: {
    container: {
      flexDirection: 'column',
      position: 'absolute',
      top: [NAVBAR_HEIGHT, null, NAVBAR_HEIGHT + 16],
      right: [0, null, 16],
      boxShadow: [null, null, 'dropShadow.levelTwo'],
      borderRadius: [null, null, 'default'],
      width: ['var(--app-width)', null, '352px'],
      height: [
        `calc(var(--app-height) - ${NAVBAR_HEIGHT}px)`,
        null,
        `calc(var(--app-height) - ${NAVBAR_HEIGHT + 32}px)`,
      ],
      zIndex: 'drawer',
      py: 3,
      overflow: 'auto',
      bg: 'drawer.background',
      // TODO: Check in the future if we want the frosty glass effect
      // '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      //   bg: 'transparent',
      //   backgroundImage: `linear-gradient(130deg, ${hexToRgba(baseColors.backgrounds[4], 0.36)}, ${hexToRgba(
      //     baseColors.backgrounds[8],
      //     0.36,
      //   )} 100%)`,
      //   backdropFilter: 'blur(12px)',
      //   border: '1px solid rgba(0,0,0,.08)',
      // },
    },
  },
  notificationCenter: {
    message: {
      color: 'texts.lighter',
      fontSize: 2,
    },
  },
  fancyPersonBox: {
    container: {
      height: '33px',
      bg: 'fancyPersonBox.container',
      alignItems: 'center',
      px: 2,
      py: '0.375rem',
      borderRadius: 'sm',
    },
  },
  fancyDataBox: {
    container: {
      py: '0.125rem',
      pl: 1,
      pr: '0.125rem',
      bg: 'fancyDateBox.background.container',
      borderRadius: 'sm',
    },
    time: {
      ml: 2,
      px: 1,
      py: '0.125rem',
      bg: 'fancyDateBox.background.time',
      borderRadius: 'sm',
    },
  },
  navbarTimeEventTimer: {
    container: {
      work: {
        ...workTimerContainerSx,
        '&[data-hover="true"]': {
          bg: 'workTimer.background.container.hover',
          cursor: 'pointer',
          '&[data-active="true"]': {
            bg: 'workTimer.background.container.activeHover',
          },
        },
      },
      custom: {
        ...workTimerContainerSx,
        '&[data-hover="true"]': {
          cursor: 'pointer',
        },
      },
    },
    content: {
      work: {
        ...workTimerContentSx,
        '&[data-hover="true"]': {
          color: 'workTimer.text.hover',
        },
        '&[data-active="true"]': {
          color: 'workTimer.text.activeHover',
          fontWeight: 'bold',
        },
      },
      custom: {
        ...workTimerContentSx,
        color: 'workTimer.text.custom',
        fontWeight: 'bold',
      },
    },
    timer: {
      fontSize: 3,
      lineHeight: 'workTimer',
    },
  },
  timeEventTimer: {
    container: {
      p: '0.75rem',
      gap: 2,
      alignItems: 'center',
      justifyContent: 'space-between',
      bg: 'backgrounds.levelTwo',
      borderRadius: 'default',
      transition: 'background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      ':hover': {
        bg: 'backgrounds.levelZero',
        boxShadow: 'dropShadow.levelOne',
      },
    },
    content: {
      flexDirection: 'column',
      lineHeight: 1,
      '&[data-active="true"]': {
        fontWeight: 'bold',
      },
    },

    timer: {
      mt: '0.125rem',
      fontSize: '1.3125rem',
    },
    startTime: {
      fontWeight: 'body',
      color: 'texts.lighter',
    },
    maxDuration: {
      fontWeight: 'body',
      color: 'texts.lighter',
      fontSize: 2,
    },
  },
});
