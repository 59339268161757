import React, { useContext, useCallback, useEffect } from 'react';
import { ClientContext, useMutation } from 'react-fetching-library';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Text, Flex } from 'theme-ui';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import { SignInPayload } from 'api/actions/auth/authActions.types';
import { signInAction } from 'api/actions/auth/authActions';
import {
  startAuthorizing,
  setToken,
  setAuthorized,
  setUnauthorized,
} from '../../context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from '../../hooks/useAuthDispatch/useAuthDispatch';
import { useAuthState } from '../../hooks/useAuthState/useAuthState';
import { TO } from 'constants/routes';
import { languageSelector } from '../../state/recoilState';
import { Button } from 'components/ui/Buttons';
import { Icon } from 'components/Icon/Icon';
import { Main, LineDivider, FormCard } from 'layouts/Authentication';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { APPLE_CLIENT_ID, APPLE_LOGIN_URL, APP_NAME, GOOGLE_CLIENT_ID } from 'constants/common';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { useScript } from 'hooks/useScript/useScript';
import { AppleProps } from 'layouts/Settings/Integrations/AppleIntegration';
import { useGoogleSignIn } from 'hooks/useGoogleSignIn/useGoogleSignIn';
import { googleLoginAction } from 'api/actions/integrations/integrationActions';
import { fetchUserSessionAction } from 'api/actions/userSession/userSessionActions';
import { userSessionAtom } from 'state/userSession';
import { signUpFormAtom } from 'state/signUp';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { SignInForm } from './SignInForm';

declare const AppleID: AppleProps;

const appleLoginParams = {
  clientId: APPLE_CLIENT_ID,
  redirectURI: APPLE_LOGIN_URL,
  scope: 'openid',
};

export const SignIn = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const setUserSession = useSetRecoilState(userSessionAtom);
  const resetSignUpForm = useResetRecoilState(signUpFormAtom);

  const { state } = useLocation();

  const fromPath = state?.from || TO.START[language];

  const { query } = useContext(ClientContext);

  const { mutate } = useMutation(signInAction);
  const dispatch = useAuthDispatch();
  const { addSnackbar } = useSnackbar();

  const { mutate: mutateGoogleLogin } = useMutation(googleLoginAction);

  const { isAuthorized, errorMessage } = useAuthState();

  useEffect(() => {
    resetSignUpForm();
  }, [resetSignUpForm]);

  const appleScriptState = useScript(process.env.REACT_APP_APPLE_SCRIPT || '');

  const onSubmit = useCallback(
    async (body: SignInPayload): Promise<boolean> => {
      dispatch(startAuthorizing());
      const { payload, error: submitError } = await mutate(body);

      if (!submitError && payload) {
        localStorage.clear();
        const { accessToken } = payload;

        dispatch(setToken(accessToken));

        const { payload: userSession, error: fetchError } = await query(fetchUserSessionAction(accessToken));

        if (!fetchError && userSession) {
          setUserSession(userSession);
          dispatch(setAuthorized());
          return true;
        }
      }
      dispatch(setUnauthorized());

      addSnackbar({
        variant: 'danger',
        message: i18n._(
          t({
            id: 'sign_in.form.form_error',
            message: 'Invalid username and/or password 😥',
          }),
        ),
      });

      return false;
    },
    [addSnackbar, dispatch, mutate, query, setUserSession],
  );

  const handleGoogleOnSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('tokenId' in res) {
      const { payload, error } = await mutateGoogleLogin({ googleToken: res.tokenId });

      if (payload && !error) {
        const { accessToken } = payload;
        dispatch(setToken(accessToken));
        const { payload: userinfo, error: fetchError } = await query(fetchUserSessionAction(accessToken));
        if (!fetchError && userinfo) {
          setUserSession(userinfo);
          dispatch(setAuthorized());
        }
      }
    }
  };

  const useGoogleLogin = useGoogleSignIn(GOOGLE_CLIENT_ID, handleGoogleOnSuccess);

  useEffect(() => {
    if (appleScriptState.loaded && AppleID) {
      AppleID.auth.init(appleLoginParams);
    }
  }, [appleScriptState]);

  const handleAppleOnClick = () => {
    if (appleScriptState.loaded && AppleID) {
      AppleID.auth.signIn();
    }
  };

  if (isAuthorized) {
    return <Redirect to={fromPath} />;
  }

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'sing_in.page_title', message: `Sign in - ${APP_NAME}` }))}</title>
      </Helmet>

      <Main>
        <Flex
          sx={{
            width: '100%',
            maxWidth: '480px',
            flexGrow: 1,
            gap: 5,
            flexDirection: 'column',
            alignItems: 'stretch',
            alignSelf: 'center',
            textAlign: 'center',
          }}
        >
          <FormCard>
            <FormCard.Header>
              <FormCard.Title>
                <Trans id="sign_in.lead.header">Welcome back! 👋</Trans>
              </FormCard.Title>
              <FormCard.Lead>
                <Trans id="sign_in.lead.text">Type in your e-mail address and password.</Trans>
              </FormCard.Lead>
            </FormCard.Header>

            {errorMessage && (
              <Text sx={{ color: 'texts.error' }}>
                <Trans id="sign_in.redirect_error_title">Something went wrong 😥</Trans>
                <br />
                {errorMessage}
              </Text>
            )}

            <SignInForm onSubmit={onSubmit} />

            <LineDivider>
              <Trans id="sign_in.or_sign_in_with">or sign in with</Trans>
            </LineDivider>
            <Flex sx={{ flexDirection: 'row', gap: 2, justifyContent: 'center' }}>
              <Button outline variant="grey" shape="rounded" size="lg" onClick={useGoogleLogin}>
                <Icon type="google" />
              </Button>
              <Button outline variant="grey" shape="rounded" size="lg" onClick={handleAppleOnClick}>
                <Icon type="apple" />
              </Button>
            </Flex>
          </FormCard>

          <Text as="p" sx={{ alignSelf: 'center', color: 'texts.default' }}>
            <Trans id="sign_in.top_right">Don't have an account?</Trans>{' '}
            <LinkAnchor to={TO.SIGN_UP[language]} sx={{ fontWeight: '700' }}>
              <Trans id="sign_in.top_right.button_title">Create a FREE account</Trans>
            </LinkAnchor>
          </Text>

          <Text as="p" sx={{ alignSelf: 'center', color: 'texts.lighter' }}>
            <Trans id="sign_in.terms_acceptance">
              Signing in, you agree to the{' '}
              <LinkAnchor sx={{ textDecoration: 'underline' }} href={EXTERNAL_LINKS.TERMS} target="_blank">
                Terms and Conditions
              </LinkAnchor>{' '}
              as they stand.
            </Trans>
          </Text>
        </Flex>
      </Main>
    </>
  );
};
