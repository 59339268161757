import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { HolidayList } from 'layouts/Settings/Organization/formElements/HolidaysList';
import { MemoizedOrganizationChangeAvatarForm } from 'layouts/Settings/Organization/forms/OrganizationChangeAvatarForm';
import { OrganizationForms } from 'layouts/Settings/Organization/forms/OrganizationForms';
import { resetFormButtonAtom, resetFormButtonCallbacksSelector } from 'state/settings';

import { OrganizationModalRoutes } from './OrganizationModalRoutes';

export const Organization = (): React.ReactElement => {
  useLingui();
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);

  const handleResetButtonClick = () => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  };

  return (
    <>
      <HeadingTip
        label={t({
          id: 'organization_settings.heading.organization_settings',
          message: 'Organization settings',
        })}
        displayReset={!!formResetCallbacks}
        displayOnClick={() => handleResetButtonClick()}
      />

      <Flex sx={{ gap: '2.25rem', flexDirection: 'column' }}>
        <MemoizedOrganizationChangeAvatarForm />
        <OrganizationForms />
        <HolidayList />
        <OrganizationModalRoutes />
      </Flex>
    </>
  );
};
