import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Flex, Heading } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';

import { DayOfWeek } from 'api/actions/organizationSession/organizationSessionActions.types';
import { preferencesSettingsAction } from 'api/actions/settings/settingsActions';
import {
  FetchOrganizationSettingsResponse,
  PreferencesSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { InputOption, Select } from 'components/ui/Select/Select';
import { TimeZoneSelect } from 'components/ui/TimeZoneSelect/TimeZoneSelect';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { addSnackbar } from 'SnackbarHub/actions';
import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';

type Props = {
  payload: PreferencesSettingsActionProps;
  currencyOptions: InputOption[];
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  preferencesShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  preferencesFormPendingRef: React.MutableRefObject<boolean>;
  preferencesStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

type keys = keyof PreferencesSettingsActionProps;

export const Preferences = React.forwardRef(
  (
    {
      payload,
      currencyOptions,
      blockLocationPathnameRef,
      preferencesShouldBlockTransitionRef,
      preferencesFormPendingRef,
      preferencesStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchOrganizationSettingsResponse>(payloadSelectorFamily('ORGANIZATION'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const { mutate } = useMutation(preferencesSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<PreferencesSettingsActionProps>(payload);

    const {
      register,
      handleSubmit,
      reset: resetForm,
    } = useForm({
      defaultValues: payload,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const dispatchSubmitEvent = () => {
      const submitEvent = createEvent('submit');
      preferencesFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    };

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnBlur = useCallback(() => {
      preferencesShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, preferencesShouldBlockTransitionRef, reset, start]);

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      resetForm({ ...payload });

      preferencesShouldBlockTransitionRef.current = false;
      preferencesFormPendingRef.current = false;
    }, [payload, preferencesFormPendingRef, preferencesShouldBlockTransitionRef, resetForm]);

    const handleSubmitCallback = useCallback(
      async (data: PreferencesSettingsActionProps) => {
        const preferencesResetObject = { name: 'PREFERENCES_CALLBACK', callback: handleFormReset };
        reset();

        if (!_.isEqual(data, payload)) {
          const { error } = await mutate(data);

          if (!error) {
            setResetCallbacks((prevState) => {
              if (prevState) {
                const newState = _.reject(prevState, (item) => item.name === preferencesResetObject.name);
                return newState.length ? newState : null;
              }
              return prevState;
            });
            setPayload((prevPayload) => ({ ...prevPayload, ...data }));
            mutationDataRef.current = data;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [preferencesResetObject] : [...prevState, preferencesResetObject],
            );
            return;
          }
        }

        preferencesShouldBlockTransitionRef.current = false;
        preferencesFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        handleFormReset,
        mutate,
        payload,
        preferencesFormPendingRef,
        preferencesShouldBlockTransitionRef,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payload)) handleFormReset();
    }, [handleFormReset, payload]);

    useEffect(() => {
      preferencesStatusRef.current = status;
    }, [preferencesStatusRef, status]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    const dayOfWeekOptions: InputOption[] = useMemo(
      () => [
        {
          label: t({ id: 'global.day_of_week.monday', message: 'Monday' }),
          id: DayOfWeek.Monday.toString(),
        },
        {
          label: t({ id: 'global.day_of_week.sunday', message: 'Sunday' }),
          id: DayOfWeek.Sunday.toString(),
        },
      ],
      [],
    );

    return (
      <Flex sx={{ flexDirection: 'column', flexGrow: 1, gap: 2, maxWidth: '600px' }}>
        <Heading variant="heading4" onClick={() => handleFormReset()}>
          <Trans id="organization_settings.heading.preferences">Preferences</Trans>
        </Heading>

        <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
          <Flex sx={{ flexDirection: 'column', flexGrow: 1, gap: '0.75rem' }}>
            <OptionLabel
              withDivider
              label={t({
                id: 'organization_settings.time_zone_id.label',
                message: 'Timezone',
              })}
              apendWith={
                <TimeZoneSelect
                  {...registerOnBlur('timeZoneId')}
                  id="timeZone"
                  placeholder={t({
                    id: 'organization_settings.time_zone_id.label',
                  })}
                  size="sm"
                  searchable
                  sx={{ minWidth: '300px' }}
                />
              }
            />
            <OptionLabel
              withDivider
              label={t({
                id: 'organization_settings.starting_week_day.label',
                message: 'Start of the work week',
              })}
              apendWith={
                <Select
                  {...registerOnBlur('startingWeekDay', {
                    valueAsNumber: true,
                  })}
                  id="startingWeekDay"
                  placeholder={t({
                    id: 'organization_settings.starting_week_day.label',
                  })}
                  size="sm"
                  options={dayOfWeekOptions}
                  sx={{ minWidth: '300px' }}
                />
              }
            />
            <OptionLabel
              withDivider
              label={t({
                id: 'organization_settings.display_currency.label',
                message: 'Currency',
              })}
              apendWith={
                <Select
                  {...registerOnBlur('displayCurrency')}
                  id="displayCurrency"
                  placeholder={t({
                    id: 'organization_settings.display_currency.label',
                  })}
                  size="sm"
                  options={currencyOptions}
                  searchable
                  sx={{ minWidth: '300px' }}
                />
              }
            />
          </Flex>
        </form>
      </Flex>
    );
  },
);

export const MemoizedPreferences = React.memo(Preferences);
