import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { windowSizeAtom } from 'state/recoilState';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';

type ReturnType = {
  breakpoint: MEDIA_BREAKPOINTS;
  isMobileBreakpoint: boolean;
};

export const useThemeBreakpoint = (): ReturnType => {
  const sizes = useRecoilValue(windowSizeAtom);

  const isMobileBreakpoint = useMemo(
    () =>
      sizes.breakpoint === MEDIA_BREAKPOINTS.XS ||
      sizes.breakpoint === MEDIA_BREAKPOINTS.SM ||
      sizes.breakpoint === MEDIA_BREAKPOINTS.MD,
    [sizes.breakpoint],
  );

  return {
    breakpoint: sizes.breakpoint || MEDIA_BREAKPOINTS.XS,
    isMobileBreakpoint,
  };
};
