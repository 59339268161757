import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { t } from '@lingui/macro';
import { Flex } from 'theme-ui';
import { To } from 'history';
import { useLingui } from '@lingui/react';

import { rolesMapSelector } from 'state/employees';
import { PATH, TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { StickyListProps } from 'components/StickyList/types';
import { ModalRoute } from 'routing/ModalRoute';
import { DefaultRole, Role } from 'api/actions/organizationSession/organizationSessionActions.types';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { Icon } from 'components/Icon/Icon';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Button } from 'components/ui/Buttons';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { DropdownProps } from 'components/Dropdown/Dropdown';

import { EditRoleModal } from './EditRole';
import { AddRoleModal } from './AddRole';
import { DeleteRolesModal } from './DeleteRoles';
import { ManageModal } from './modalsElements/ManageModal';

const ButtonWithDropdown = withDropdown(Button);

const defaultRolesIds = Object.keys(DefaultRole).filter((role) => +role);

export const ManageRolesModal = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const roles = useRecoilValue(rolesMapSelector);

  const listPropsGenerator = useCallback(
    (
      match: string,
      replaceMode: boolean,
      preferredHistoryMethod: (to: To, state?: unknown) => void,
    ): StickyListProps<Role> => ({
      name: 'MANAGE_ROLES',
      select: 'checkbox',
      showHeader: true,
      showContentPlaceholder: true,
      list: roles || new Map(),
      columns: [
        {
          key: 'color',
          width: '40px',
          customCellRenderer: (color: Role['color']) => (
            <Flex
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '0.375rem',
                bg: UserSelectableColor[color],
              }}
            />
          ),
        },
        {
          title: t({ id: 'team.manage_roles.name', message: 'Name' }),
          key: 'name',
          sortableValue: (name: Role['name']) =>
            t({
              id: name,
            }),

          customCellRenderer: (name: Role['name']) => (
            <TextEllipsis
              title={t({
                id: name,
              })}
            >
              {t({
                id: name,
              })}
            </TextEllipsis>
          ),
        },
        {
          title: t({ id: 'team.manage_roles.description', message: 'Description' }),
          key: 'description',
          sortableValue: (description: Role['description']) =>
            description
              ? t({
                  id: description,
                })
              : '',
          customCellRenderer: (description: Role['description']) => (
            <TextEllipsis
              title={t({
                id: description,
              })}
            >
              {t({
                id: description,
              })}
            </TextEllipsis>
          ),
        },
        {
          key: 'id',
          width: '40px',
          sx: {
            overflow: 'visible',
          },
          customCellRenderer: (item, itemId, rowRef) => {
            const restrictedLinks: DropdownProps['links'] = [
              {
                disabled: !!DefaultRole[+item],
                replace: replaceMode,
                prependWithDivider: true,
                label: t({ id: 'team.manage_roles.delete', message: 'Delete' }),
                icon: 'delete',
                to: {
                  pathname: `${match}${TO.DELETE_ROLES_MODAL[language]}`,
                  state: { ids: [item] },
                },
              },
            ];
            return (
              <ButtonWithDropdown
                dropdownProps={{
                  links: [
                    {
                      replace: replaceMode,
                      label: t({ id: 'team.manage_roles.duplicate', message: 'Duplicate' }),
                      icon: 'copy',
                      to: {
                        pathname: `${match}${TO.ADD_ROLE_MODAL[language]}`,
                        state: { id: item },
                      },
                    },
                    ...(!DefaultRole[+item] ? restrictedLinks : []),
                  ],
                }}
                popperProps={{
                  onOutsideClick: () => {
                    rowRef?.current?.setAttribute('data-dropdown', 'false');
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  rowRef?.current?.setAttribute('data-dropdown', 'true');
                }}
                shape="rounded"
                size="sm"
                variant="minimal"
              >
                <Icon type="more" />
              </ButtonWithDropdown>
            );
          },
        },
      ],
      onRowClick: (listItemId) => {
        preferredHistoryMethod(
          `${match}${
            TO[DefaultRole[+listItemId] ? 'PREVIEW_ROLE_MODAL__ID' : 'EDIT_ROLE_MODAL__ID'][language]
          }/${listItemId}`,
        );
      },
      isRowUncheckableValidator: (listItemId) => !!DefaultRole[+listItemId],
    }),
    [roles, language],
  );

  const modalRoutesRenderer = useCallback(
    () => (
      <>
        <ModalRoute replaceMode size="sm" path={PATH.ADD_ROLE_MODAL[language]}>
          <AddRoleModal />
        </ModalRoute>
        <ModalRoute replaceMode size="sm" path={PATH.EDIT_ROLE_MODAL__ID[language]}>
          <EditRoleModal />
        </ModalRoute>
        <ModalRoute size="sm" path={PATH.PREVIEW_ROLE_MODAL__ID[language]}>
          <EditRoleModal isPreview />
        </ModalRoute>
        <ModalRoute replaceMode size="xs" path={PATH.DELETE_ROLES_MODAL[language]}>
          <DeleteRolesModal />
        </ModalRoute>
      </>
    ),
    [language],
  );

  const modalLinksPaths = useMemo(
    () => ({
      add: TO.ADD_ROLE_MODAL[language],
      delete: TO.DELETE_ROLES_MODAL[language],
    }),
    [language],
  );

  return (
    <ManageModal
      allResources={roles}
      restrictedIds={defaultRolesIds}
      title={t({ id: 'team.manage_roles', message: 'Manage roles' })}
      listPropsGenerator={listPropsGenerator}
      modalRoutesRenderer={modalRoutesRenderer}
      modalLinksPaths={modalLinksPaths}
    />
  );
};
