import { makeTheme } from 'styles/makeTheme';

const BACKGROUNDS = {
  aside: 'backgrounds.levelOne',
  main: '',
};

const SPACING = {
  padding: {
    x: 4,
    y: 3,
  },
};

export const authorizedStyles = makeTheme({
  layouts: {
    authorized: {
      aside: {
        filters: {
          flexDirection: 'column',
          bg: BACKGROUNDS.aside,
          py: 4,
          px: 3,
          gap: 4,
          alignItems: 'stretch',
          userSelect: 'none',
          overflowY: 'auto',
          maxWidth: '260px',
          width: '100%',
        },
        details: {
          container: {
            flexDirection: 'column',
            bg: BACKGROUNDS.aside,
            ml: 4,
            alignItems: 'stretch',
            overflowY: 'auto',
            maxWidth: '400px',
            width: '100%',
          },
          header: {
            bg: 'inherit',
            position: 'sticky',
            top: 0,
            alignItems: 'center',
            p: SPACING.padding.x,
            gap: 2,
          },
          headerMenu: {
            flexGrow: 1,
            justifyContent: 'flex-end',
            gap: 2,
            alignItems: 'center',
          },
          content: {
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            flexGrow: 1,
            gap: 5,
            px: SPACING.padding.x,
            pb: '3rem',
          },
          contentContainer: {
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            gap: 2,
          },
        },
      },
    },
  },
});
