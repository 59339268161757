import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';
import _ from 'lodash';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/macro';

import { requestDetailsAtom, requestDetailsDateDetailsSelector } from 'state/requests';

import { DateRangeDisplay } from './DateRangeDisplay';
import { Overtime } from './Overtime';
import { DateDisplay } from './DateDisplay';
import { Participants } from './Participants';

export const BusinessCustomTimeOffDetails = (): React.ReactElement => {
  useLingui();
  const requestDetails = useRecoilValue(requestDetailsAtom);
  const requestDetailsDateDetails = useRecoilValue(requestDetailsDateDetailsSelector);

  const requestDateRange = useMemo(() => {
    if (!requestDetailsDateDetails) return null;

    const { dateRange, isDateBound } = requestDetailsDateDetails;

    if (!dateRange) return null;

    return { dateRange, isDateBound };
  }, [requestDetailsDateDetails]);

  const requestDateUnix = useMemo(() => {
    if (!requestDetailsDateDetails) return null;

    const { dateUnix } = requestDetailsDateDetails;

    if (!dateUnix) return null;

    return dateUnix;
  }, [requestDetailsDateDetails]);

  const requestDuration = useMemo(() => {
    if (!requestDetailsDateDetails) return null;

    const { duration } = requestDetailsDateDetails;

    if (!duration) return null;

    return duration;
  }, [requestDetailsDateDetails]);

  const overtime = useMemo(() => {
    if (!requestDetails) return null;

    const { overtimeTaken } = requestDetails;

    if (_.isEmpty(overtimeTaken)) return null;

    return overtimeTaken;
  }, [requestDetails]);

  const requestPlace = useMemo(() => {
    if (!requestDetails) return null;

    const { place } = requestDetails;

    if (!place) return null;

    return place;
  }, [requestDetails]);

  const requestParticipants = useMemo(() => {
    if (!requestDetails) return null;

    const { participants } = requestDetails;

    if (_.isEmpty(participants)) return null;

    return participants;
  }, [requestDetails]);

  if (!requestDuration) return <></>;

  return (
    <Flex sx={{ flexDirection: 'column', gap: 4 }}>
      {requestDateRange ? (
        <DateRangeDisplay dateDetails={requestDateRange} duration={requestDuration} />
      ) : (
        <>{requestDateUnix && <DateDisplay dateUnix={requestDateUnix} duration={requestDuration} />}</>
      )}
      {overtime && <Overtime overtime={overtime} />}
      {requestPlace && (
        <Flex sx={{ flexDirection: 'column' }}>
          <Text
            sx={{
              fontWeight: 'bold',
              fontSize: 3,
            }}
          >
            <Trans id="request.details.section.place">Place</Trans>
          </Text>
          <Text sx={{ fontSize: 2 }}>{requestPlace}</Text>
        </Flex>
      )}
      {requestParticipants && <Participants participants={requestParticipants} />}
    </Flex>
  );
};
