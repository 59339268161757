/** @jsxImportSource theme-ui */

import React, { useCallback, useMemo, useRef, useState, FocusEvent } from 'react';
import { t, Trans } from '@lingui/macro';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { Box, Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useLingui } from '@lingui/react';

import { Button } from 'components/ui/Buttons';
import { ElementGroup } from 'components/ui/ElementGroup';
import { TextInput } from 'components/ui/TextInput';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { Select } from 'components/ui/Select/Select';
import { PhoneInput } from 'components/ui/PhoneInput';
import { Avatar } from 'components/Avatar/Avatar';
import {
  ProfileFormState,
  rolesSelectOptionsSelector,
  tagsSelectOptionsSelector,
  workPositionsSelectOptionsSelector,
} from 'state/team';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { setNativeValue } from 'utils/setNativeValue';
import { organizationSessionPropertySelectorFamily } from 'state/organizationSession';
import { FetchOrganizationSessionResponse } from 'api/actions/organizationSession/organizationSessionActions.types';
import { getTextField } from 'utils/getTextField';
import { Icon } from 'components/Icon/Icon';
import { useTheme } from 'styles/useTheme';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

const labelSx: ThemeUIStyleObject = {
  ml: 2,
  fontSize: 1,
  fontWeight: 'bold',
  mt: 3,
};

const forceCorrectTypes = ({ tagsIds, firstName, surname, roleId, ...values }: ProfileFormState): ProfileFormState => ({
  tagsIds: !_.isEmpty(tagsIds) && _.isString(tagsIds) ? tagsIds.split(',') : tagsIds || [],
  roleId,
  firstName,
  surname,
  ..._.mapValues(values, (value) => getTextField(value)),
});

export type AvatarInfo = {
  avatarUrl?: string;
  avatarBlob?: Blob | null;
  changed?: boolean;
};

type Props = {
  setLoading: (isLoading: boolean) => void;
  defaultValues: Partial<ProfileFormState>;
  avatarHasChanged: boolean;
  onSubmit: (props: ProfileFormState, avatarInfo?: AvatarInfo) => Promise<boolean>;
  onSubmitError?: () => void;
  onTagsIdsBlur?: (tagsIds: string[]) => void;
  avatarUrl?: string;
  fullContactInfo?: boolean;
  horizontal?: boolean;
  hideHeader?: boolean;
};

const defaultProps: Partial<Props> = {
  hideHeader: undefined,
  avatarUrl: undefined,
  onSubmitError: undefined,
  onTagsIdsBlur: undefined,
  fullContactInfo: undefined,
  horizontal: undefined,
};

export const UserProfile = React.forwardRef<HTMLFormElement, Props>(
  (
    {
      fullContactInfo,
      onSubmit,
      onSubmitError,
      defaultValues,
      avatarUrl,
      avatarHasChanged,
      setLoading,
      onTagsIdsBlur,
      horizontal,
      hideHeader,
    }: Props,
    ref,
  ) => {
    useLingui();

    const { theme } = useTheme();

    const roles = useRecoilValue(
      organizationSessionPropertySelectorFamily('roles') as RecoilValueReadOnly<
        FetchOrganizationSessionResponse['roles'] | null
      >,
    );
    const match = useCleanRouteMatch();
    const language = useRecoilValue(languageSelector);
    const tagsSelectOptions = useRecoilValue(tagsSelectOptionsSelector);
    const rolesSelectOptions = useRecoilValue(rolesSelectOptionsSelector);
    const workPositionsSelectOptions = useRecoilValue(workPositionsSelectOptionsSelector);
    const [showPrivateNote, setShowPrivateNote] = useState(!!defaultValues.note);
    const [showAddressDetails, setShowAddressDetails] = useState(
      !!defaultValues.postalCode || !!defaultValues.street || !!defaultValues.city,
    );

    const [currentAvatarUrl, setCurrentAvatarUrl] = useState<string | null>(avatarUrl || null);

    const avatarHasChangedRef = useRef<boolean>(avatarHasChanged);

    const avatarInputRef = useRef<HTMLInputElement | null>(null);

    const {
      watch,
      setError,
      clearErrors,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });
    const tagsIdsRef = useRef(defaultValues.tagsIds?.join());
    const selectedRoleId = watch('roleId');

    const selectedRoleDescription = roles?.find(({ id }) => id === selectedRoleId)?.description;

    const triggerAvatarInputClick = () => {
      const avatarInput = avatarInputRef.current;
      if (avatarInput) {
        avatarInput.click();
      }
    };
    const clearAvatarFile = () => {
      setCurrentAvatarUrl(null);
      setNativeValue(avatarInputRef, '');
      avatarHasChangedRef.current = true;
    };

    const handleSubmitCallback = useCallback(
      async (body) => {
        let avatarBlob: Blob | null = null;
        if (currentAvatarUrl && avatarHasChangedRef.current) {
          const avatarResp = await fetch(currentAvatarUrl);
          avatarBlob = await avatarResp.blob();
        }
        const avatarInfo = {
          avatarUrl: currentAvatarUrl || undefined,
          avatarBlob,
          changed: avatarHasChangedRef.current,
        };

        onSubmit(forceCorrectTypes(body), avatarInfo);
      },
      [onSubmit, currentAvatarUrl],
    );
    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
      if (onSubmitError) {
        onSubmitError();
      }
    }, [setLoading, onSubmitError]);

    const setPhoneErrorCallback = useCallback(() => {
      setError('phoneNumberInternalError' as 'phoneNumber', {
        message: 'only visible in code',
      });
    }, [setError]);
    const clearPhoneErrorCallback = useCallback(() => {
      clearErrors('phoneNumberInternalError' as 'phoneNumber');
    }, [clearErrors]);

    const handleAvatarChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files && files[0]) {
        avatarHasChangedRef.current = true;
        setCurrentAvatarUrl(URL.createObjectURL(files[0] || null));
      }
    }, []);

    const tagsIdsRegister = useMemo(() => {
      const { onBlur, ...restRegister } = register('tagsIds');

      return {
        ...restRegister,
        onBlur: (e: FocusEvent<HTMLInputElement>) => {
          onBlur(e);
          if (onTagsIdsBlur) {
            const newValue = e.target.value;

            if (!_.isEqual(newValue, tagsIdsRef.current)) {
              tagsIdsRef.current = newValue;
              const tagsIds = e.target.value.split(',').filter((id) => id !== '');
              onTagsIdsBlur(tagsIds);
            }
          }
        },
      };
    }, [onTagsIdsBlur, register]);

    const contactRenderer = useCallback(
      () => (
        <Flex sx={{ width: '100%', alignContent: 'space-between', mb: fullContactInfo ? 0 : 3 }}>
          <Box sx={{ width: '50%', flexDirection: 'column', flexGrow: 1, pr: '0.37rem' }}>
            <Text as="div" sx={{ ...labelSx, mt: 0 }}>
              <Trans id="team.user.email">E-mail</Trans>
            </Text>
            <TextInput
              size="sm"
              id="email"
              placeholder={t({
                id: 'global.forms.inputs.email_example',
                message: 'sam@example.com',
              })}
              type="email"
              autoComplete="email"
              error={!!errors.email}
              errorMessage={errors?.email?.message}
              {...register('email', validationFactory(VALIDATION_RULES.EMAIL))}
            />
          </Box>
          <Box sx={{ width: '50%', flexGrow: 1, pl: '0.37rem' }}>
            <Text as="div" sx={{ ...labelSx, mt: 0 }}>
              <Trans id="team.user.phone">Phone</Trans>
            </Text>
            <PhoneInput
              size="sm"
              id="phoneNumber"
              placeholder={t({
                id: 'global.forms.inputs.phone_number_example',
                message: '+1 (123) 555-0123',
              })}
              onValidError={setPhoneErrorCallback}
              onClearError={clearPhoneErrorCallback}
              error={!!errors.phoneNumber || !!errors['phoneNumberInternalError' as 'phoneNumber']}
              errorMessage={errors?.phoneNumber?.message}
              clearable
              {...register('phoneNumber')}
            />
          </Box>
        </Flex>
      ),

      [clearPhoneErrorCallback, register, errors, setPhoneErrorCallback, fullContactInfo],
    );

    if (!tagsSelectOptions || !rolesSelectOptions || !workPositionsSelectOptions) return <></>;

    return (
      <form ref={ref} onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)} noValidate>
        <Flex sx={{ ...(!horizontal ? { flexDirection: 'column' } : { gap: 5 }) }}>
          <Flex sx={{ flexDirection: 'column', ...(horizontal && { width: '50%' }), flexGrow: 1 }}>
            {!hideHeader && (
              <Heading variant="heading4.withMargins">
                <Trans id="team.user.basic_info">Basic information</Trans>
              </Heading>
            )}
            <Flex sx={{ flexDirection: 'column' }}>
              <Text sx={{ ...labelSx, mt: 2 }}>
                <Trans id="team.user.name">Name</Trans>
              </Text>
              <Flex sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <ElementGroup marginValue="1px" showAsList direction="column" wrapperSx={{ width: '100%' }}>
                  <TextInput
                    size="sm"
                    id="firstName"
                    placeholder={t({ id: 'team.user.first_name', message: 'First name' })}
                    variant="roundedTop"
                    error={!!errors.firstName}
                    errorMessage={errors?.firstName?.message}
                    {...register('firstName', validationFactory({ ...VALIDATION_RULES.FIRST_NAME, required: true }))}
                  />
                  <TextInput
                    size="sm"
                    id="surname"
                    placeholder={t({ id: 'team.user.last_name', message: 'Last name' })}
                    variant="roundedBottom"
                    error={!!errors.surname}
                    errorMessage={errors?.surname?.message}
                    {...register('surname', validationFactory({ ...VALIDATION_RULES.SURNAME, required: true }))}
                  />
                </ElementGroup>
                <Flex sx={{ position: 'relative', ml: 4, flexShrink: 0, alignItems: 'center' }}>
                  <input
                    sx={{ width: 0, opacity: 0, position: 'absolute' }}
                    type="file"
                    ref={avatarInputRef}
                    onChange={handleAvatarChange}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <Flex sx={{ position: 'relative' }}>
                    <Avatar size={74} image={currentAvatarUrl || undefined} />
                    {currentAvatarUrl ? (
                      <Flex
                        sx={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <Button
                          variant="minimal"
                          size="xs"
                          shape="circle"
                          aria-label="Delete"
                          onClick={clearAvatarFile}
                          bgOverwrite={{
                            default: theme.colors.notification.closeButton.background.default,
                            hover: theme.colors.notification.closeButton.background.hover,
                            tap: theme.colors.notification.closeButton.background.tap,
                            disabled: '',
                          }}
                          sx={{
                            border: `1px solid`,
                            borderColor: 'alphas.darker2',
                            backdropFilter: 'blur(2px)',
                            boxShadow: 'dropShadowDarker.levelOne',
                          }}
                        >
                          <Icon type="x" />
                        </Button>
                      </Flex>
                    ) : (
                      <Button
                        onClick={triggerAvatarInputClick}
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          borderBottomLeftRadius: 'sm',
                          borderBottomRightRadius: 'sm',
                        }}
                        size="xs"
                        shape="square"
                        variant="minimal"
                        bgOverwrite={{
                          default: theme.colors.alphas.lighter5,
                          hover: theme.colors.alphas.bluish5,
                          tap: theme.colors.alphas.bluish6,
                          disabled: '',
                        }}
                      >
                        <Trans id="add" />
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Flex>
              {!fullContactInfo && contactRenderer()}
              <Trans id="team.user.tags.add_manage">
                <Text sx={{ ...labelSx, mt: 0 }}>
                  Tags |{' '}
                  <LinkAnchor
                    standOut
                    sx={{ textDecoration: 'underline' }}
                    to={`${match}${TO.ADD_TAG_MODAL[language]}`}
                  >
                    Add
                  </LinkAnchor>{' '}
                  or{' '}
                  <LinkAnchor
                    standOut
                    sx={{ textDecoration: 'underline' }}
                    to={`${match}${TO.MANAGE_TAGS_MODAL[language]}`}
                  >
                    Manage
                  </LinkAnchor>
                </Text>
              </Trans>

              <Select
                size="sm"
                id="tagsIds"
                multi
                placeholder={t({ id: 'team.user.select_position', message: 'Select' })}
                error={!!errors.tagsIds}
                errorMessage={errors?.tagsIds?.message}
                searchable
                options={tagsSelectOptions}
                clearable
                {...tagsIdsRegister}
              />
              <Trans id="team.user.position.default_manage">
                <Text sx={labelSx}>
                  Default position |{' '}
                  <LinkAnchor
                    standOut
                    sx={{ textDecoration: 'underline' }}
                    to={`${match}${TO.MANAGE_WORK_POSITIONS_MODAL[language]}`}
                  >
                    Manage
                  </LinkAnchor>
                </Text>
              </Trans>

              <Select
                size="sm"
                id="defaultWorkPosition"
                // creatable TODO: 2021-12-06 - disabled for CORE version
                placeholder={t({ id: 'team.user.position.select_add', message: 'Select or add a new one' })}
                error={!!errors.defaultWorkPosition}
                errorMessage={errors?.defaultWorkPosition?.message}
                searchable
                options={workPositionsSelectOptions}
                clearable
                {...register(
                  'defaultWorkPosition',
                  // validationFactory(VALIDATION_RULES.WORK_POSITION_NAME) TODO: add when creatable
                )}
              />
              <Trans id="team.user.role.role_manage">
                <Text sx={labelSx}>
                  Role |{' '}
                  <LinkAnchor
                    standOut
                    sx={{ textDecoration: 'underline' }}
                    to={`${match}${TO.MANAGE_ROLES_MODAL[language]}`}
                  >
                    Manage
                  </LinkAnchor>
                </Text>
              </Trans>

              <Select
                size="sm"
                id="roleId"
                placeholder={t({ id: 'team.user.role', message: 'Role' })}
                error={!!errors.roleId}
                errorMessage={errors?.roleId?.message}
                searchable
                options={rolesSelectOptions}
                {...register('roleId', { required: t({ id: 'global.forms.required' }) })}
              />

              {selectedRoleDescription && (
                <Text sx={{ ml: 2, fontSize: 2, color: 'texts.lighter' }}>
                  {t({
                    id: selectedRoleDescription,
                  })}
                </Text>
              )}
            </Flex>
          </Flex>
          {fullContactInfo && (
            <Flex sx={{ ...(horizontal ? { width: '50%' } : { mt: 4 }) }}>
              <Flex sx={{ flexDirection: 'column', width: '100%' }}>
                <Heading variant="heading4" mb={3}>
                  <Trans id="team.user.contact_info">Contact information</Trans>
                </Heading>
                {contactRenderer()}

                <Flex mt={!showAddressDetails ? '1.5rem' : '1rem'}>
                  {!showAddressDetails ? (
                    <ShowFieldsButton
                      onClick={() => setShowAddressDetails(true)}
                      label={t({ id: 'team.user.address.add', message: 'Add address details' })}
                    />
                  ) : (
                    <Box sx={{ width: '100%' }}>
                      <Text sx={labelSx}>
                        <Trans id="team.user.address.details">Address details</Trans>
                      </Text>
                      <TextInput
                        size="sm"
                        id="street"
                        placeholder={t({ id: 'team.user.address.street', message: 'Street' })}
                        type="text"
                        error={!!errors.street}
                        errorMessage={errors?.street?.message}
                        clearable
                        {...register('street', validationFactory(VALIDATION_RULES.STREET))}
                      />
                      <Flex mt={1}>
                        <Box sx={{ width: '120px', mr: 1 }}>
                          <TextInput
                            size="sm"
                            id="postalCode"
                            placeholder={t({ id: 'team.user.address.zip', message: 'ZIP' })}
                            type="text"
                            error={!!errors.postalCode}
                            errorMessage={errors?.postalCode?.message}
                            clearable
                            {...register('postalCode', validationFactory(VALIDATION_RULES.POSTAL_CODE))}
                          />
                        </Box>

                        <TextInput
                          size="sm"
                          id="city"
                          placeholder={t({ id: 'team.user.address.city', message: 'City' })}
                          type="text"
                          error={!!errors.city}
                          errorMessage={errors?.city?.message}
                          clearable
                          {...register('city', validationFactory(VALIDATION_RULES.CITY))}
                        />
                      </Flex>
                    </Box>
                  )}
                </Flex>
                <Flex mt={!showPrivateNote ? '1.5rem' : '1rem'}>
                  {!showPrivateNote ? (
                    <ShowFieldsButton
                      onClick={() => setShowPrivateNote(true)}
                      label={t({ id: 'team.user.private.add', message: 'Add private note' })}
                    />
                  ) : (
                    <Box sx={{ width: '100%' }}>
                      <Text sx={labelSx}>
                        <Trans id="team.user.private.desc">Private note - visible to Managers & Administrators</Trans>
                      </Text>
                      <TextInput
                        size="sm"
                        id="note"
                        placeholder={t({
                          id: 'team.user.private.desc.more',
                          message: 'Private notes, logs and informations',
                        })}
                        type="text"
                        error={!!errors.note}
                        errorMessage={errors?.note?.message}
                        clearable
                        {...register('note', validationFactory(VALIDATION_RULES.NOTE))}
                      />
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </form>
    );
  },
);

UserProfile.defaultProps = defaultProps;
