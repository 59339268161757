import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  FetchAttendancePillResponse,
  FetchTimeTracker,
  PostTimeEvent,
  PostTimeEventResponse,
} from './drawerActions.types';

export function fetchAttendancePillAction(): Action<FetchAttendancePillResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.attendancePill,
  };
}

export function fetchTimeTrackerAction(): Action<FetchTimeTracker> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeTracker,
  };
}

export function postTimeEventAction(body: PostTimeEvent): Action<PostTimeEventResponse> {
  const formData = new FormData();

  formData.append('submitRequest', JSON.stringify(body));

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeEventSubmit,
    body: formData,
  };
}
