///
/// <reference types="react/next" />
/// <reference types="react-dom/next" />
///
/* eslint-disable no-console */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import './utils/wdyr';

import React from 'react';
import ReactDOMClient from 'react-dom/client';

import { Root } from 'components/Root';

// Disable console.logs on production build
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_API_URL === '') {
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const { worker } = require('./mocks/browser');

worker.start({
  onUnhandledRequest: 'bypass',
});

const container = document.getElementById('app') as HTMLDivElement;
const root = ReactDOMClient.createRoot(container);

root.render(<Root />);
