import React, { useCallback } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { useHotkeys } from 'react-hotkeys-hook';

import { languageSelector } from 'state/recoilState';
import { GetSettingsResponse } from 'api/actions/timeclock/timeclockActions.types';
import { TO } from 'constants/routes';
import { nightModeSelector, debugModeSelector } from '../state/kioskState';
import { CameraImageBufferProvider } from '../context/CameraImageBufferProvider';
import { QrScannerProvider } from '../context/QrScannerProvider';
import { FaceDetectionProvider } from '../context/FaceDetectionProvider';

import { Camera } from './Camera';
import { DebugFaceLandmarkModel } from './__debug/DebugFaceLandmarkModel';
import { OverlayController } from './OverlayController';
import { DebugCameraImageBuffer } from './__debug/DebugCameraImageBuffer';
import { DebugControlPanel } from './__debug/DebugControlPanel';
import { KioskLoadingSplash } from './KioskLoadingSplash/KioskLoadingSplash';

const kioskWrapperSx: ThemeUIStyleObject = {
  position: 'relative',
  width: '100%',
  height: 'var(--app-height)',
  overflow: 'hidden',
  color: 'white',
  '&[data-night-mode="true"]': {
    color: 'texts.default',
  },
};

type Props = {
  settings: GetSettingsResponse;
};

export const KioskApp = ({ settings }: Props): React.ReactElement => {
  const { push } = useHistory();

  const isNightMode = useRecoilValue(nightModeSelector);
  const language = useRecoilValue(languageSelector);
  const [debugMode, setDebugMode] = useRecoilState(debugModeSelector);

  const onUserMediaErrorCallback = useCallback(() => {
    push(`${TO.KIOSK__CAMERA_PERMISSION[language]}`);
  }, [language, push]);

  // __FOR_DEBUG_MODE
  useHotkeys('ctrl+shift+k,cmd+shift+k', () => {
    setDebugMode((prevState) => {
      // eslint-disable-next-line no-console
      console.log(`%c[DEBUG_MODE ${!prevState ? 'ON' : 'OFF'}]`, 'background: black; color: red');
      return !prevState;
    });
  });

  return (
    <>
      <KioskLoadingSplash />

      <Box sx={kioskWrapperSx} data-night-mode={isNightMode}>
        <OverlayController>
          <QrScannerProvider>
            <FaceDetectionProvider load={settings.isFaceRecognitionRequired}>
              <CameraImageBufferProvider>
                <Camera onUserMediaErrorCallback={onUserMediaErrorCallback} />
              </CameraImageBufferProvider>
            </FaceDetectionProvider>
          </QrScannerProvider>
        </OverlayController>
      </Box>

      {debugMode && <DebugFaceLandmarkModel prediction={[]} />}
      {debugMode && <DebugCameraImageBuffer />}
      {debugMode && <DebugControlPanel />}
    </>
  );
};
