/** @jsxImportSource theme-ui */
import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { Divider } from 'components/Divider/Divider';

type Props = {
  children: React.ReactNode;
  direction?: 'row' | 'column';
  marginAt?: 'start' | 'end';
  rowSpace?: string | number | Array<string | number>;
  marginValue?: string | number | (string | number | null)[];
  showAsList?: boolean;
  wrapperSx?: ThemeUIStyleObject;
  withDividers?: boolean;
};

export type ElementGroupProps = Props;

const defaultProps: Partial<Props> = {
  direction: 'row',
  marginValue: '0.125rem',
  marginAt: 'start',
  showAsList: false,
  wrapperSx: undefined,
  rowSpace: undefined,
  withDividers: undefined,
};

export const ElementGroup = ({
  children,
  marginValue,
  marginAt,
  direction,
  showAsList,
  wrapperSx,
  rowSpace,
  withDividers,
}: Props): React.ReactElement => {
  const prepareMargins = () => {
    if (direction === 'row') {
      return marginAt === 'start' ? { ml: marginValue } : { mr: marginValue };
    }
    return marginAt === 'start' ? { mt: marginValue } : { mb: marginValue };
  };

  return (
    <ConditionalWrapper
      condition={!!showAsList || !!rowSpace || !!wrapperSx}
      wrapper={({ children: ulChildren }) => {
        const type = showAsList ? 'ul' : 'div';
        const prepareSx = (): ThemeUIStyleObject | undefined => {
          if (rowSpace) {
            if (direction === 'row') {
              return {
                mt: `-${rowSpace}`,
                flexWrap: 'wrap',
                '& > *': {
                  mt: `${rowSpace} !important`,
                  flexGrow: 1,
                },
              };
            }

            return {
              ml: `-${rowSpace}`,
              flexWrap: 'wrap',
              '& > *': {
                ml: `${rowSpace} !important`,
              },
            };
          }

          return undefined;
        };

        return (
          <Flex as={type} sx={{ ...(wrapperSx && wrapperSx), ...(rowSpace && prepareSx()), flexDirection: direction }}>
            {ulChildren}
          </Flex>
        );
      }}
    >
      {React.Children.map(
        children,
        (child, i) =>
          React.isValidElement(child) && (
            <ConditionalWrapper
              condition={!!showAsList}
              wrapper={({ children: liChildren }) => (
                <li sx={{ ...((i > 0 || marginAt === 'end') && prepareMargins()) }}>{liChildren}</li>
              )}
            >
              <>
                {React.cloneElement(child as React.ReactElement, {
                  sx: { ...child.props.sx, ...((i > 0 || marginAt === 'end') && prepareMargins()) },
                })}
                {withDividers && <Divider sx={prepareMargins()} />}
              </>
            </ConditionalWrapper>
          ),
      )}
    </ConditionalWrapper>
  );
};

ElementGroup.defaultProps = defaultProps;
