import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, FlexProps, Text, ThemeUIStyleObject } from 'theme-ui';

import { RequestEventType } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { timeEventTypesDictionarySelector } from 'state/organizationSession';
import { fullTimeFormatSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';

const markNewDateSx: ThemeUIStyleObject = {
  color: 'greens5',
  fontWeight: 'bold',
};

type Props = {
  originalEvent?: RequestEventType;
  newEvent?: RequestEventType;
} & FlexProps;

const defaultProps: Partial<Props> = {
  originalEvent: undefined,
  newEvent: undefined,
};

export const EventsDisplay = ({ originalEvent, newEvent, ...props }: Props): React.ReactElement => {
  useLingui();
  const timeFormat = useRecoilValue(fullTimeFormatSelector);
  const timeEventsDictionary = useRecoilValue(timeEventTypesDictionarySelector);

  const originalEventName = useMemo(
    () => timeEventsDictionary && originalEvent && timeEventsDictionary[originalEvent.typeId].name,
    [originalEvent, timeEventsDictionary],
  );
  const newEventName = useMemo(
    () => timeEventsDictionary && newEvent && timeEventsDictionary[newEvent.typeId].name,
    [newEvent, timeEventsDictionary],
  );

  return (
    <Flex {...props} sx={{ gap: 4 }}>
      {originalEvent && originalEventName && (
        <Flex sx={{ flexDirection: 'column', width: '228px', gap: 2 }}>
          <Text
            sx={{
              fontWeight: 'bold',
              fontSize: 3,
            }}
          >
            <Trans id="request.details.original_event">Original event</Trans>
          </Text>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ fontWeight: 'bold' }}>
              <Trans id="type">Type</Trans>
            </Text>
            <Text>
              <Trans id={originalEventName} />
            </Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ fontWeight: 'bold' }}>
              <Trans id="requests.details.date_time">Date & time</Trans>
            </Text>
            <Text>{dateTime(originalEvent.dateUnix).format(`D MMM, ${timeFormat}`)}</Text>
          </Flex>
          {originalEvent.note && (
            <Flex sx={{ flexDirection: 'column' }}>
              <Text sx={{ fontWeight: 'bold' }}>
                <Trans id="note">Note</Trans>
              </Text>
              <Text>{originalEvent.note}</Text>
            </Flex>
          )}
        </Flex>
      )}
      {originalEvent && newEvent && <Icon type="arrowRight" fill="whites4" />}
      {newEvent && newEventName && (
        <Flex sx={{ flexDirection: 'column' }}>
          <Flex sx={{ flexDirection: 'column', width: '228px', gap: 2 }}>
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: 3,
              }}
            >
              {originalEvent ? (
                <Trans id="request.details.new_event">New Event</Trans>
              ) : (
                <Trans id="request.details.original_event" />
              )}
            </Text>

            <Flex sx={{ flexDirection: 'column' }}>
              <Text sx={{ fontWeight: 'bold' }}>
                <Trans id="type" />
              </Text>
              <Text
                sx={{
                  ...(originalEvent && !_.isEqual(newEventName, originalEventName) && markNewDateSx),
                }}
              >
                <Trans id={newEventName} />
              </Text>
            </Flex>
            <Flex sx={{ flexDirection: 'column' }}>
              <Text sx={{ fontWeight: 'bold' }}>
                <Trans id="requests.details.date_time" />
              </Text>
              <Text
                sx={{
                  ...(originalEvent && !_.isEqual(newEvent.dateUnix, originalEvent?.dateUnix) && markNewDateSx),
                }}
              >
                {dateTime(newEvent.dateUnix).format(`D MMM, ${timeFormat}`)}
              </Text>
            </Flex>
            {newEvent.note && (
              <Flex sx={{ flexDirection: 'column' }}>
                <Text sx={{ fontWeight: 'bold' }}>
                  <Trans id="note" />
                </Text>
                <Text
                  sx={{
                    ...(originalEvent && !_.isEqual(newEvent.note, originalEvent?.note) && markNewDateSx),
                  }}
                >
                  {newEvent.note}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

EventsDisplay.defaultProps = defaultProps;
