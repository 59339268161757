import { dateTime } from 'utils/dateTime';

import {
  DAY_PLACEHOLDER,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_STATIC_VALUE,
  MONTH_PLACEHOLDER,
  RANGE_DIVIDER,
  YEAR_PLACEHOLDER,
} from './Calendar/constants';

export const getMaskedValue = (unixDates: number[], isStatic: boolean, currentMaskedValue?: string): string => {
  const partialString = currentMaskedValue && currentMaskedValue.split(RANGE_DIVIDER);

  const stringFromUnix = unixDates
    .map((date) =>
      isStatic ? dateTime(date).format(DEFAULT_DATE_STATIC_VALUE) : dateTime(date).format(DEFAULT_DATE_FORMAT),
    )
    .join(RANGE_DIVIDER);

  const maskedString =
    unixDates.length < 2 && !isStatic && partialString && partialString[0].match(/[DMY]/g)?.length
      ? [stringFromUnix, partialString && partialString[1]].join(RANGE_DIVIDER)
      : stringFromUnix;

  return maskedString;
};

export const getDateStringsFromMaskedValue = (maskedInputValue: string): string[] =>
  maskedInputValue
    .split(RANGE_DIVIDER)
    .map((date) =>
      date.replaceAll(YEAR_PLACEHOLDER, '').replaceAll(MONTH_PLACEHOLDER, '').replaceAll(DAY_PLACEHOLDER, ''),
    )
    .filter((date) => date.length === DEFAULT_DATE_FORMAT.length);

export const getDatesWithTime = (dates: number[], times: number[]): number[] => {
  const datesStartOfDay = dates.map((date) => dateTime(date, { disableTimezone: true }).startOf('day').unix());
  return datesStartOfDay.map((date, index) => date + (times[index] || 0));
};

export const parsedHiddenInputValue = (value: string | undefined): number[] => {
  let initialValue;
  if (typeof value === 'string') {
    initialValue = value ? value.split(',').map((date) => parseInt(date, 10)) : [];
  } else if (typeof value === 'number') {
    initialValue = [value];
  }
  return initialValue || [];
};
