/** @jsxImportSource theme-ui */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Box, Input, InputProps, Label, LabelProps, ThemeUIStyleObject, Text } from 'theme-ui';
import { motion, Variants, MotionProps } from 'framer-motion';

import { Icons } from 'components/Icon/Icon.types';
import { Icon } from 'components/Icon/Icon';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { createEvent } from 'utils/createEvent';
import { useTheme } from 'styles/useTheme';

const errorMessageFontSizes = {
  default: 0,
  sm: '0.550rem',
  xs: '0.550rem',
};

const sxIcon: ThemeUIStyleObject = {
  outline: 'none',
  cursor: 'pointer',
  fill: 'texts.lighter',
  '&:hover > svg': {
    fill: 'texts.default',
  },
};

const labelSx: ThemeUIStyleObject = {
  fontSize: 1,
  fontWeight: 'bold',
  position: 'relative',
  left: 2,
  width: '0px',
  whiteSpace: 'nowrap',
};

const animationVariants: Variants = {
  default: { scale: 1 },
  error: { scale: [1, 1.025, 0.9875, 1], transition: { duration: 0.45 } },
};

const sxPrepend: ThemeUIStyleObject = {
  display: 'flex',
  flexWrap: 'wrap',
  '> * ~ *': {
    ml: '2px !important',
  },
};

type Props = Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> & {
  hideClearIcon?: boolean;
  usedAsDisplay?: boolean;
  controllerHasValue?: boolean;
  focusThiefElement?: HTMLInputElement;
  id: string;
  placeholder?: string;
  type?: string;
  variant?: TextInputVariant;
  size?: 'default' | 'sm' | 'xs';
  label?: string;
  labelProps?: Omit<LabelProps, 'ref'>;
  inputProps?: Omit<InputProps, 'ref' | 'onBlur' | 'onFocus'>;
  sxOverwrite?: ThemeUIStyleObject;
  autoComplete?: InputProps['autoComplete'];
  error?: boolean;
  errorMessage?: string;
  positive?: boolean;
  positiveMessage?: string;
  clearable?: boolean;
  disabled?: boolean;
  value?: InputProps['value'];
  icon?: Icons;
  apendWith?: React.ReactNode;
  prependWith?: React.ReactNode;
  customContent?: React.ReactNode;
  onClearCallback?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  hideClearIcon: false,
  usedAsDisplay: false,
  controllerHasValue: false,
  focusThiefElement: undefined,
  type: 'text',
  variant: 'rounded',
  size: 'default',
  label: undefined,
  placeholder: undefined,
  labelProps: undefined,
  inputProps: undefined,
  sxOverwrite: undefined,
  autoComplete: undefined,
  error: undefined,
  errorMessage: undefined,
  positive: undefined,
  positiveMessage: undefined,
  clearable: undefined,
  disabled: undefined,
  value: undefined,
  icon: undefined,
  apendWith: undefined,
  prependWith: undefined,
  customContent: undefined,
  onClearCallback: undefined,
  onChange: undefined,
  onKeyUp: undefined,
  onKeyDown: undefined,
  onBlur: undefined,
  onFocus: undefined,
  sx: undefined,
};

export type TextInputVariant = 'rounded' | 'square' | 'roundedTop' | 'roundedBottom' | 'naked';
export type TextInputProps = Props;

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      usedAsDisplay,
      controllerHasValue,
      hideClearIcon,
      focusThiefElement,
      id,
      placeholder,
      type = 'text',
      apendWith,
      autoComplete,
      clearable,
      disabled,
      error,
      errorMessage,
      icon,
      inputProps,
      label,
      labelProps,
      onChange,
      onBlur,
      onClearCallback,
      onFocus,
      onKeyDown,
      onKeyUp,
      positive,
      positiveMessage,
      prependWith,
      customContent,
      sxOverwrite,
      value,
      variant = 'rounded',
      size = 'default' as Required<Props>['size'],
      ...props
    }: Props,
    ref,
  ) => {
    const [hasValue, setValue] = useState<Props['value']>(value);
    const [hasError, setError] = useState<boolean | undefined>();
    const [hasErrorMessage, setErrorMessage] = useState<string | undefined>();
    const [hasPositive, setPositive] = useState<boolean | undefined>();
    const [hasPositiveMessage, setPositiveMessage] = useState<string | undefined>();
    const [isPasswordMasked, setIsPasswordMasked] = useState<boolean>(type === 'password');
    const [isFocus, setIsFocus] = useState<boolean>(false);

    const { theme } = useTheme();

    const localRef = useRef<HTMLInputElement | null>(null);

    const sxIsError = useMemo(
      () =>
        hasError && {
          color: 'texts.error',
          backgroundColor: 'backgrounds.error',
          '& [data-default-input="true"]': {
            '&::placeholder': {
              color: 'reds3',
            },
          },
        },
      [hasError],
    );

    const { fontSize, px, py, ...restOfSizeSpecificSx } = useMemo(
      () => theme.forms.input.sizes[size],
      [size, theme.forms.input.sizes],
    );

    const sxCustomContentContainer: ThemeUIStyleObject = useMemo(
      () => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        px,
        py: py > 2 ? '0.75rem' : py,
        ':hover + input:not(:disabled)': {
          bg: 'input.background.hover',
        },
        ':focus-within + input:not(:disabled)': {
          bg: 'input.background.focus',
        },
      }),
      [px, py],
    );

    const sxPrependContainer: ThemeUIStyleObject = useMemo(
      () => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: '100%',
        pr: 0,
        pl: px === 1 ? '0.5rem' : px,
        py: py > 2 ? '0.75rem' : py,
      }),
      [px, py],
    );

    const sxApendContainer: ThemeUIStyleObject = useMemo(
      () => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        pr: px === 1 ? '0.5rem' : px,
        '> * ~ *': {
          ml: '2px !important',
        },
      }),
      [px],
    );

    const setWhileTap = useCallback(() => {
      if (disabled) return 'default';
      if (!isFocus) return 'default';
      return 'default';
    }, [disabled, isFocus]);

    const setAnimations = useCallback(() => {
      if (hasError) return 'error';
      return 'default';
    }, [hasError]);

    const animationProps: MotionProps = useMemo(
      () => ({
        initial: 'default',
        variants: animationVariants,
        animate: setAnimations(),
        exit: 'default',
        whileTap: setWhileTap(),
      }),
      [setAnimations, setWhileTap],
    );

    const dispatchFocusEvent = () => {
      const focusEvent = createEvent('focus');
      if (localRef.current) localRef.current.dispatchEvent(focusEvent);
    };

    function clearValue(): void {
      setNativeValue(localRef, '');
      if (onClearCallback) onClearCallback();
    }

    const onClearIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      e.stopPropagation();
      if (localRef.current) clearValue();
      if (localRef.current) localRef.current.focus();
    };

    const onSetIsPasswordMaskedClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      e.stopPropagation();
      setIsPasswordMasked(!isPasswordMasked);
    };

    const getInitialType = useCallback(() => {
      if (type === 'password') return isPasswordMasked ? 'password' : 'text';
      return type;
    }, [type, isPasswordMasked]);

    const setIconColor = useCallback(() => {
      if (isFocus) return 'input.text.default';
      if (hasError && !hasValue) return 'reds5';
      if (hasError) return 'texts.error';
      if (hasPositive) return 'input.text.default';
      if (hasValue) return 'input.text.default';
      return 'input.text.placeholder';
    }, [hasError, isFocus, hasValue, hasPositive]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = e.target.value;
      if (hasValue !== newValue) {
        setValue(newValue);
      }
      if (onChange) {
        onChange(e);
      }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (props.readOnly) {
        return;
      }

      setIsFocus(true);
      if (onFocus) onFocus(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocus(false);
      if (onBlur) onBlur(e);
    };

    const handleWrapperClick = () => {
      if (props.readOnly) {
        return;
      }
      if (customContent) {
        if (focusThiefElement) {
          focusThiefElement.focus();
        }
        dispatchFocusEvent();
        return;
      }

      localRef.current?.focus();
    };

    useEffect(() => {
      if (typeof value === 'string' || value) {
        setNativeValue(localRef, value);
      }
      setError(error);
      setErrorMessage(errorMessage);

      setPositive(positive);
      setPositiveMessage(positiveMessage);
    }, [hasValue, error, errorMessage, value, positive, positiveMessage]);

    const renderInput = (inheritSx = false) => (
      <motion.div
        {...animationProps}
        // data-has-label={!!label}
        data-focus={isFocus}
        data-disabled={disabled}
        data-readonly={props.readOnly}
        onClick={handleWrapperClick}
        sx={{
          fontSize,
          m: '1px',
          ...theme.forms.input[variant],
          ...sxIsError,
          ...restOfSizeSpecificSx,
          ...sxOverwrite,
          ...(inheritSx && props.sx),
        }}
      >
        {(prependWith || !!icon) && !customContent && (
          <Box sx={sxPrependContainer}>
            {!!icon && <Icon type={icon} fill={setIconColor()} wrapperSx={{ pointerEvents: 'none' }} />}
            {prependWith && <Box sx={sxPrepend}>{prependWith}</Box>}
          </Box>
        )}
        {customContent && <Box sx={sxCustomContentContainer}>{customContent}</Box>}
        {!customContent && (
          <Input
            data-default-input
            name={id}
            placeholder={placeholder}
            type={getInitialType()}
            autoComplete={autoComplete}
            disabled={disabled}
            ref={mergeRefs([ref, localRef])}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            {...inputProps}
            {...props}
            sx={{
              fontSize,
              px: px === 1 ? '0.5rem' : px,
              py,
              border: 0,
              outline: 0,
              ...(customContent && { display: 'none' }),
              ...inputProps?.sx,
            }}
          />
        )}
        {(apendWith ||
          (clearable &&
            ((!usedAsDisplay && localRef.current?.value) || (usedAsDisplay && controllerHasValue)) &&
            !hideClearIcon &&
            !disabled) ||
          type === 'password') && (
          <Box sx={sxApendContainer}>
            {clearable &&
              ((!usedAsDisplay && localRef.current?.value) || (usedAsDisplay && controllerHasValue)) &&
              !hideClearIcon &&
              !disabled && (
                <Icon fill="texts.lighter" type="closeCircle" onClick={onClearIconClick} wrapperSx={sxIcon} />
              )}
            {type === 'password' && (
              <Icon
                fill="texts.lighter"
                type={isPasswordMasked ? 'eye' : 'eyeOff'}
                onClick={onSetIsPasswordMaskedClick}
                wrapperSx={sxIcon}
              />
            )}
            {apendWith && apendWith}
          </Box>
        )}
        {(hasError || hasPositive) && (
          <Text
            as="span"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              pr: 2,
              fontSize: errorMessageFontSizes[size],
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: hasError ? 'texts.error' : 'texts.positive',
              userSelect: 'none',
            }}
          >
            {hasError ? hasErrorMessage : hasPositiveMessage}
          </Text>
        )}
      </motion.div>
    );

    const renderLabel = () => (
      <Label htmlFor={id} {...labelProps} sx={{ ...labelSx, ...(!!labelProps?.sx && labelProps.sx) }}>
        {label}
      </Label>
    );

    return label ? (
      <Flex sx={{ flexDirection: 'column', flex: '1 0 0', ...props.sx }}>
        {renderLabel()}
        {renderInput()}
      </Flex>
    ) : (
      renderInput(true)
    );
  },
);

TextInput.defaultProps = defaultProps;
