import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import { timeOffSettingsAction } from 'api/actions/settings/settingsActions';
import {
  CarriedOverLimitExpiration,
  FetchRequestsSettingsResponse,
  TimeOffSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { Divider } from 'components/Divider/Divider';
import { InputOption, Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { payloadSelectorFamily, resetFormButtonAtom, rolesApproveRequestsSelectOptionsSelector } from 'state/settings';
import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { Paths } from '../../utils/createUnionOfTypeKeys';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';
import { addSnackbar } from 'SnackbarHub/actions';

type keys = Paths<TimeOffSettingsActionProps>;

type Props = {
  payload: TimeOffSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  timeOffShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  timeOffFormPendingRef: React.MutableRefObject<boolean>;
  timeOffStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TimeOff = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      timeOffShouldBlockTransitionRef,
      timeOffFormPendingRef,
      timeOffStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchRequestsSettingsResponse>(payloadSelectorFamily('REQUESTS'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const rolesSelectOptions = useRecoilValue(rolesApproveRequestsSelectOptionsSelector);
    const { mutate } = useMutation(timeOffSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<TimeOffSettingsActionProps>(payload);

    const { twoStepApprovalFlow, firstStepRoleId, secondStepRoleId } = payload.timeOffApprovalSettings;
    const payloadComparisonObject: TimeOffSettingsActionProps = useMemo(
      () => ({ ...payload, timeOffApprovalSettings: { twoStepApprovalFlow, firstStepRoleId, secondStepRoleId } }),
      [firstStepRoleId, payload, secondStepRoleId, twoStepApprovalFlow],
    );

    const {
      register,
      handleSubmit,
      setError,
      watch,
      reset: resetForm,
      formState: { errors },
    } = useForm({
      defaultValues: payload,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const twoStepApprovalWatch = watch('timeOffApprovalSettings.twoStepApprovalFlow');
    const allowCarryOverUnUsedLimitsWatch = watch('allowCarryOverUnUsedLimits');
    const firstStepRoleIdWatch = watch('timeOffApprovalSettings.firstStepRoleId');
    const secondStepRoleIdWatch = watch('timeOffApprovalSettings.secondStepRoleId');

    const dispatchSubmitEvent = useCallback(() => {
      const submitEvent = createEvent('submit');
      timeOffFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    }, [timeOffFormPendingRef]);

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnChange = useCallback(() => {
      timeOffShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, reset, start, timeOffShouldBlockTransitionRef]);

    const handleOnBlur = useCallback(() => {
      timeOffShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, reset, start, timeOffShouldBlockTransitionRef]);

    const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          handleOnChange();
        },
      };
    };

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      resetForm({
        ...payload,
      });

      timeOffShouldBlockTransitionRef.current = false;
      timeOffFormPendingRef.current = false;
    }, [payload, resetForm, timeOffFormPendingRef, timeOffShouldBlockTransitionRef]);

    const handleValidation = useCallback(
      (data: TimeOffSettingsActionProps) => {
        const dataFirstStepRoleId = data.timeOffApprovalSettings.firstStepRoleId;
        const dataSecondStepRoleId = data.timeOffApprovalSettings.secondStepRoleId;
        const dataTwoStepApprovalFlow = data.timeOffApprovalSettings.twoStepApprovalFlow;

        if (dataTwoStepApprovalFlow && (!dataFirstStepRoleId || !dataSecondStepRoleId)) {
          setError('timeOffApprovalSettings.firstStepRoleId', {
            type: 'validate',
          });
          setError('timeOffApprovalSettings.secondStepRoleId', {
            type: 'validate',
            message: t({ id: 'settings.forms.step_empty' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleSubmitCallback = useCallback(
      async (data: TimeOffSettingsActionProps) => {
        const validation = handleValidation(data);
        const timeOffResetObject = { name: 'TIME_OFF_CALLBACK', callback: handleFormReset };
        const dataComparisonObject: TimeOffSettingsActionProps = {
          ...data,
          timeOffApprovalSettings: {
            twoStepApprovalFlow: data.timeOffApprovalSettings.twoStepApprovalFlow,
            firstStepRoleId: data.timeOffApprovalSettings.firstStepRoleId?.length
              ? data.timeOffApprovalSettings.firstStepRoleId
              : undefined,
            secondStepRoleId: data.timeOffApprovalSettings.secondStepRoleId?.length
              ? data.timeOffApprovalSettings.secondStepRoleId
              : undefined,
          },
        };
        reset();

        if (validation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === timeOffResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(dataComparisonObject, payloadComparisonObject) && validation) {
          const { error } = await mutate(dataComparisonObject);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...dataComparisonObject }));
            mutationDataRef.current = dataComparisonObject;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) => (!prevState ? [timeOffResetObject] : [...prevState, timeOffResetObject]));
            return;
          }
        }

        if (!validation) {
          setResetCallbacks((prevState) => (!prevState ? [timeOffResetObject] : [...prevState, timeOffResetObject]));
          return;
        }

        timeOffShouldBlockTransitionRef.current = false;
        timeOffFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        handleFormReset,
        handleValidation,
        mutate,
        payloadComparisonObject,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
        timeOffFormPendingRef,
        timeOffShouldBlockTransitionRef,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payloadComparisonObject)) handleFormReset();
    }, [handleFormReset, payload, payloadComparisonObject]);

    useEffect(() => {
      timeOffStatusRef.current = status;
    }, [status, timeOffStatusRef]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    const carriedOverLimitOption: InputOption[] = useMemo(
      () => [
        {
          label: t({ id: 'settings.select.never', message: 'Never' }),
          id: CarriedOverLimitExpiration.Never.toString(),
        },
        {
          label: t({ id: 'settings.select.one_month', message: '1 Month' }),
          id: CarriedOverLimitExpiration.Months1.toString(),
        },
        {
          label: t({ id: 'settings.select.two_month', message: '2 Months' }),
          id: CarriedOverLimitExpiration.Months2.toString(),
        },
        {
          label: t({ id: 'settings.select.three_month', message: '3 Months' }),
          id: CarriedOverLimitExpiration.Months3.toString(),
        },
        {
          label: t({ id: 'settings.select.four_month', message: '4 Months' }),
          id: CarriedOverLimitExpiration.Months4.toString(),
        },
        {
          label: t({ id: 'settings.select.five_month', message: '5 Months' }),
          id: CarriedOverLimitExpiration.Months5.toString(),
        },
        {
          label: t({ id: 'settings.select.six_month', message: '6 Months' }),
          id: CarriedOverLimitExpiration.Months6.toString(),
        },
        {
          label: t({ id: 'settings.select.seven_month', message: '7 Months' }),
          id: CarriedOverLimitExpiration.Months7.toString(),
        },
        {
          label: t({ id: 'settings.select.eight_month', message: '8 Months' }),
          id: CarriedOverLimitExpiration.Months8.toString(),
        },
        {
          label: t({ id: 'settings.select.nine_month', message: '9 Months' }),
          id: CarriedOverLimitExpiration.Months9.toString(),
        },
        {
          label: t({ id: 'settings.select.ten_month', message: '10 Months' }),
          id: CarriedOverLimitExpiration.Months10.toString(),
        },
        {
          label: t({ id: 'settings.select.eleven_month', message: '11 Months' }),
          id: CarriedOverLimitExpiration.Months11.toString(),
        },
        {
          label: t({ id: 'settings.select.twelve_month', message: '12 Months' }),
          id: CarriedOverLimitExpiration.Months12.toString(),
        },
      ],
      [],
    );

    return (
      <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          {payload && (
            <>
              <HeadingError
                label={t({
                  id: 'requests_settings.heading.time_off',
                  message: 'Time Off',
                })}
                errorMessage={errors.timeOffApprovalSettings?.secondStepRoleId?.message}
              />
              <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
                <Switch
                  {...registerOnChange('timeOffApprovalSettings.twoStepApprovalFlow')}
                  label={t({
                    id: 'requests_settings.two_step_approval_flow.label',
                    message: 'Two-step approval flow',
                  })}
                  additionalInfo={
                    <Text>
                      <Trans id="requests_settings.two_step_approval_flow.additionalInfo">
                        <Text as="span" sx={{ textDecoration: 'underline' }}>
                          Changing this setting will affect all not accepted requests
                        </Text>
                        . All Administrators always can accept requests despite below settings.
                      </Trans>
                    </Text>
                  }
                  size="sm"
                />
                {twoStepApprovalWatch && (
                  <>
                    <OptionLabel
                      label={t({
                        id: 'requests_settings.first_step_role_id.label',
                        message: 'First step',
                      })}
                      apendWith={
                        <>
                          {rolesSelectOptions && (
                            <Select
                              {...registerOnBlur('timeOffApprovalSettings.firstStepRoleId')}
                              id="timeOffApprovalSettings.firstStepRoleId"
                              placeholder={t({
                                id: 'requests_settings.first_step_role_id.label',
                              })}
                              options={_.filter(rolesSelectOptions, (item) => item.id !== secondStepRoleIdWatch)}
                              size="sm"
                              error={!!errors.timeOffApprovalSettings?.firstStepRoleId}
                              sx={{ minWidth: '188px' }}
                            />
                          )}
                        </>
                      }
                    />
                    <OptionLabel
                      label={t({
                        id: 'requests_settings.second_step_role_id.label',
                        message: 'Second step',
                      })}
                      apendWith={
                        <>
                          {rolesSelectOptions && (
                            <Select
                              {...registerOnBlur('timeOffApprovalSettings.secondStepRoleId')}
                              id="timeOffApprovalSettings.secondStepRoleId"
                              placeholder={t({
                                id: 'requests_settings.second_step_role_id.label',
                              })}
                              options={_.filter(rolesSelectOptions, (item) => item.id !== firstStepRoleIdWatch)}
                              size="sm"
                              error={!!errors.timeOffApprovalSettings?.secondStepRoleId}
                              sx={{ minWidth: '188px' }}
                            />
                          )}
                        </>
                      }
                    />
                  </>
                )}
                <Divider />
                <Switch
                  {...registerOnChange('allowCarryOverUnUsedLimits')}
                  label={t({
                    id: 'requests_settings.allow_carry_over_unused_limits.label',
                    message: 'Allow carry-over of unused time off limits to next years limit',
                  })}
                  additionalInfo={t({
                    id: 'requests_settings.allow_carry_over_unused_limits.additionalInfo',
                    message: 'It is an individually defined option for each time off type on each employee.',
                  })}
                  size="sm"
                />
                {allowCarryOverUnUsedLimitsWatch && (
                  <OptionLabel
                    label={t({
                      id: 'requests_settings.carried_over_limit_expires_after.label',
                      message: 'Carried-over limit expires after',
                    })}
                    apendWith={
                      <Select
                        {...registerOnBlur('carriedOverLimitExpiresAfter', { valueAsNumber: true })}
                        id="carriedOverLimitExpiresAfter"
                        placeholder="select"
                        options={carriedOverLimitOption}
                        size="sm"
                        error={!!errors.carriedOverLimitExpiresAfter}
                        sx={{ maxWidth: '188px' }}
                      />
                    }
                  />
                )}
                <Divider />
              </Flex>
            </>
          )}
        </Flex>
      </form>
    );
  },
);

export const MemoizedTimeOff = React.memo(TimeOff);
