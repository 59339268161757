import { ThemeUICSSObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

const textDefault: ThemeUICSSObject = {
  fontSize: 3,
};

const fontSizesNumbers = [
  0.625, // 10px, 0
  0.75, // 12px, 1
  0.875, // 14px, 2
  1, // 16px, 3
  1.25, // 20px, 4
  1.5, // 24px, 5
  1.875, // 30px, 6
  2.375, // 38px, 7
];

const headings = {
  heading1: {
    fontSize: 6,
  },
  heading2: {
    fontSize: [3, 3, 5, 6],
  },
  heading3: {
    fontSize: [3, 3, 4, 5],
  },
  heading4: {
    fontSize: 3,
  },
  heading5: {
    fontSize: 2,
  },
  heading6: {
    fontSize: 1,
  },
};

export const text = makeTheme({
  fonts: {
    body: '"Proxima Nova Soft", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    heading: 'inherit',
    buttons: 'inherit',
    monospace: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  },

  fontWeights: {
    body: 'normal',
    heading: 'bold',
    bold: 'bold',
    display: 'normal',
  },

  fontSizes: fontSizesNumbers.map((n) => `${n}rem`),

  lineHeights: {
    body: 1.5,
    heading: 1.2,
    notification: {
      date: fontSizesNumbers[3],
      title: fontSizesNumbers[5] / fontSizesNumbers[4],
      message: fontSizesNumbers[5] / fontSizesNumbers[4],
    },
    tooltip: fontSizesNumbers[2] / fontSizesNumbers[1],
    workTimer: fontSizesNumbers[3],
  },

  text: {
    ellipsis: {
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    p: {
      ...textDefault,
    },
    pLead: {
      fontSize: 4,
      mb: 3,
    },
    pSmall: {
      fontSize: 3,
    },
    display: {
      fontWeight: 'display',
      lineHeight: 'heading',
    },
    heading1: {
      ...headings.heading1,
      withMargins: {
        ...headings.heading1,
        mb: '3',
      },
    },
    heading2: {
      ...headings.heading2,
      withMargins: {
        ...headings.heading2,
        mb: [2, 2, 3],
      },
    },
    heading3: {
      ...headings.heading3,
      withMargins: {
        ...headings.heading3,
        mb: [2, 2, 3],
      },
    },
    heading4: {
      ...headings.heading4,
      withMargins: {
        ...headings.heading4,
        mb: 2,
      },
    },
    heading5: {
      ...headings.heading5,
      withMargins: {
        ...headings.heading5,
        mb: 2,
      },
    },
    heading6: {
      ...headings.heading6,
      withMargins: {
        ...headings.heading6,
        mb: [2, 2, 3],
      },
    },
  },
});
