import {
  SET_AUTHORIZED,
  SET_USER,
  SET_TOKEN,
  SET_UNAUTHORIZED,
  START_AUTHORIZING,
  SIGN_OUT,
  SET_IS_SIGN_UP_IN_PROGRESS,
} from '../authReducer/authReducer';
import type { AuthAction, User } from '../authReducer/authReducer.types';

export const setAuthorized: () => AuthAction = () => ({
  type: SET_AUTHORIZED,
});

export const setIsSignUpInProgress: (isSignUpInProgress: boolean) => AuthAction = (isSignUpInProgress) => ({
  type: SET_IS_SIGN_UP_IN_PROGRESS,
  isSignUpInProgress,
});

export const setUser: (user: User) => AuthAction = (user) => ({
  type: SET_USER,
  user,
});

export const setUnauthorized: () => AuthAction = () => ({
  type: SET_UNAUTHORIZED,
});

export const setToken: (accessToken: string) => AuthAction = (accessToken) => ({
  type: SET_TOKEN,
  accessToken,
});

export const signOut: () => AuthAction = () => ({
  type: SIGN_OUT,
});

export const startAuthorizing: () => AuthAction = () => ({
  type: START_AUTHORIZING,
});
