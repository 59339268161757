import { ThemeUICSSObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

import { colors } from './colors';
import { calendarStyles } from './forms.calendar';

const textInput: ThemeUICSSObject = {
  position: 'relative',
  display: 'flex',
  width: 'auto',
  justifyContent: 'space-between',
  flex: '1 0 0px',
  cursor: 'text',
  backgroundColor: 'input.background.default',
  color: 'input.text.default',
  borderRadius: 'default',
  transition: 'background 0.15s ease-in-out',
  outline: '1px solid',
  outlineColor: 'alphas.darker5',
  '&[data-has-label="true"]': {
    mt: 3,
  },
  '& input': {
    fontFamily: 'inherit',
  },
  '& input[data-default-input="true"]': {
    display: 'flex !important',
    width: '100%',
    flex: '1 0 0px',
    background: 'transparent !important',
    '&::placeholder': {
      opacity: 1,
      color: 'input.text.placeholder',
    },
  },
  '&:hover:not([data-disabled="true"])': {
    backgroundColor: 'input.background.hover',
  },
  '&[data-focus="true"]:not([data-readonly="true"])': {
    color: 'input.text.default',
    backgroundColor: 'input.background.focus',
    outlineColor: 'alphas.bluish7',
    '&[data-default-input="true"]': {
      outline: 0,
      '&::placeholder': {
        color: 'input.text.placeholder',
      },
    },
  },
  '&[data-disabled="true"]': {
    backgroundColor: 'input.background.disabled',
    color: 'input.text.disabled',
    pointerEvents: 'none',
  },
};

const option: ThemeUICSSObject = {
  gap: 2,
  cursor: 'pointer',
  px: '0.75rem',
  py: 2,
  borderRadius: 'sm',
  transition: 'background 0.2s ease',
  alignItems: 'center',
  '&:hover, &:focus, &[aria-selected="true"]': {
    bg: 'select.option.background.hover',
  },
  '&[aria-selected="true"]': {
    color: 'texts.default',
  },
  span: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const selectedOption: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  px: 2,
  py: '0.125rem',
  my: '0.125rem',
  borderRadius: 'pill',
  transition: 'background 0.2s ease',
  bg: 'select.selectOption.background.default',
  color: 'select.selectOption.text',
  fontWeight: 'bold',
  '> span': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&:hover': {
    bg: 'select.selectOption.background.hover',
  },
};

const twoDigitInput: ThemeUICSSObject = {
  cursor: 'text !important',
  transition: 'all ease-in-out 0.05s',
  textAlign: 'center',
  p: 0,
  fontFamily: 'inherit',
  outline: 'none',
  border: 'none',
  borderRadius: 'sm',
  bg: 'alphas.darker4',
  '&:hover:not(:read-only)': {
    bg: 'alphas.darker2',
  },
  '&:focus:not(:read-only)': {
    bg: 'alphas.bluish4',
  },
  '&:read-only': {
    cursor: 'pointer !important',
  },
};

const nativeSelect: ThemeUICSSObject = {
  ...twoDigitInput,
  cursor: 'auto !important',
};

const checkbox: ThemeUICSSObject = {
  transition: 'all .1s ease-in-out',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '6px',
  overflow: 'hidden',
  flexShrink: 0,
  bg: 'checkbox.background.default',
  color: 'transparent',
  'input:checked ~ &': {
    bg: `${colors.colors.checkbox.background.checked} !important`,
    color: 'checkbox.checkMark.default',
  },
  'input:disabled ~ &, input[data-disabled-but-readable="true"] ~ &': {
    bg: `${colors.colors.checkbox.background.disabled} !important`,
  },
};

const radio: ThemeUICSSObject = {
  transition: 'all .1s ease-in-out',
  boxSizing: 'border-box',
  borderRadius: 'circle',
  borderColor: 'radio.background.default',
  borderWidth: '100%',
  borderStyle: 'solid',
  bg: 'radio.background.default',
  'input:checked ~ &': {
    bg: 'whites1',
    borderWidth: '5px',
    borderColor: `${colors.colors.radio.background.checked} !important`,
  },
  'input:hover:not(:disabled):not(:checked) ~ &': {
    bg: `whites1`,
    borderWidth: '5px',
    borderColor: `${colors.colors.radio.background.hover} !important`,
  },
  'input:disabled ~ &': {
    opacity: '0.4',
    pointerEvents: 'none',
  },
  'input:checked:disabled ~ &,input[data-disabled-but-readable="true"] ~ &': {
    bg: 'whites1',
    borderColor: `${colors.colors.radio.background.checked} !important`,
    opacity: '0.4',
  },
};

const switchSx: ThemeUICSSObject = {
  transition: 'background 0.1s ease-in-out',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  p: '2px',
  borderRadius: 'pill',
  overflow: 'hidden',
  flexShrink: 0,
  bg: colors.colors.switch.background.default,
  'input:checked ~ &': {
    bg: `${colors.colors.switch.background.checked} !important`,
    '& span': {
      transform: 'translateX(100%)',
    },
  },
  'input:disabled ~ &, input[data-disabled-but-readable="true"] ~ &': {
    bg: `${colors.colors.switch.background.disabled} !important`,
    '& span': {
      bg: colors.colors.switch.indicator.disabled,
    },
  },
  'input:checked:disabled ~ &, input:checked[data-disabled-but-readable="true"] ~ &': {
    bg: `${colors.colors.switch.background.checkedAndDisabled} !important`,
    '& span': {
      bg: colors.colors.switch.indicator.disabled,
    },
  },
};
const switchIndicator: ThemeUICSSObject = {
  transition: 'all 0.15s ease-in-out',
  bg: 'switch.indicator.default',
  borderRadius: 'circle',
};

export const forms = makeTheme({
  forms: {
    ...calendarStyles.forms,
    input: {
      sizes: {
        default: {
          py: 3,
          px: 3,
          fontSize: 3,
          '& input, & select': {
            borderRadius: 'sm',
          },
        },
        sm: {
          py: 2,
          px: 2,
          fontSize: 2,
          borderRadius: 'sm',
          '& input, & select': {
            borderRadius: 'xs',
          },
        },
        xs: {
          py: 1,
          px: 1,
          fontSize: 1,
          borderRadius: 'sm',
          '& input, & select': {
            borderRadius: 'xs',
          },
        },
      },
      rounded: {
        ...textInput,
      },
      square: {
        ...textInput,
        borderRadius: 0,
      },
      roundedTop: {
        ...textInput,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      roundedBottom: {
        ...textInput,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      naked: {
        ...textInput,
        bg: 'transparent !important',
      },
      inputLabel: {
        mt: 2,
        ml: 2,
        fontSize: 1,
        fontWeight: 'bold',
      },
    },

    select: {
      option: {
        ...option,
        active: {
          ...option,
          bg: 'select.option.background.active',
          color: 'white',
          fontWeight: 'bold',
          '&:hover': {
            bg: 'select.option.background.active',
          },
        },
      },
      selectedOption,
    },

    timePicker: {
      twoDigitInput: {
        default: {
          ...twoDigitInput,
          width: '40px',
          height: '32px',
          fontSize: 3,
        },
        sm: {
          ...twoDigitInput,
          width: '30px',
          height: '21px',
          fontSize: 2,
        },
        xs: {
          ...twoDigitInput,
          borderRadius: '6px',
          width: '28px',
          height: '20px',
          fontSize: 1,
        },
      },
      nativeSelect: {
        default: {
          ...nativeSelect,
          fontSize: 3,
          height: '32px',
          pl: 1,
          pr: '24px',
          '& + svg': {
            width: '32px',
          },
        },
        sm: {
          ...nativeSelect,
          fontSize: 2,
          height: '21px',
          pl: 1,
          pr: '24px',
          '& + svg': {
            width: '28px',
          },
        },
        xs: {
          ...nativeSelect,
          fontSize: 1,
          width: '52px',
          height: '20px',
          pr: '20px',
          '& + svg': {
            width: '24px',
          },
        },
      },
    },

    checkbox: {
      default: {
        ...checkbox,
        width: '20px',
        height: '20px',
        '& svg': {
          size: 16,
        },
      },
      sm: {
        ...checkbox,
        width: '18px',
        height: '18px',
        '& svg': {
          size: 14,
        },
      },
      xs: {
        ...checkbox,
        width: '16px',
        height: '16px',
        '& svg': {
          size: 12,
        },
      },
      labelFontSize: {
        default: 3,
        sm: 2,
        xs: 1,
      },
    },
    radio: {
      default: {
        ...radio,
        width: '20px',
        height: '20px',
      },
      sm: {
        ...radio,
        width: '18px',
        height: '18px',
      },
      xs: {
        ...radio,
        width: '16px',
        height: '16px',
      },
      labelFontSize: {
        default: 3,
        sm: 2,
        xs: 1,
      },
    },
    switch: {
      default: {
        ...switchSx,
        width: '36px',
        height: '20px',
        '& span': {
          ...switchIndicator,
          width: '16px',
          height: '16px',
        },
      },
      sm: {
        ...switchSx,
        width: '32px',
        height: '18px',
        '& span': {
          ...switchIndicator,
          width: '14px',
          height: '14px',
        },
      },
      xs: {
        ...switchSx,
        width: '24px',
        height: '14px',
        '& span': {
          ...switchIndicator,
          width: '10px',
          height: '10px',
        },
      },
    },
  },
});
