import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { editHolidayAction } from 'api/actions/holidays/holidaysActions';
import {
  AddHolidayActionProps,
  EditHolidayActionProps,
  Holiday,
  HolidaysYearResponse,
} from 'api/actions/holidays/holidaysActions.types';
import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useModal } from 'hooks/useModal/useModal';
import { createEvent } from 'utils/createEvent';
import { AddEditHolidayForm } from '../forms/AddEditHolidayForm';
import { payloadSelectorFamily, refreshSelectorFamily } from 'state/settings';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { addSnackbar } from 'SnackbarHub/actions';

export const EditHolidayModal = (): React.ReactElement => {
  useLingui();
  const { id } = useParams<{ id: string }>();
  const holidaysListPayload: HolidaysYearResponse = useRecoilValue(payloadSelectorFamily('HOLIDAYS_LIST'));
  const holiday: Holiday | undefined = _.find(holidaysListPayload, ['id', id]);
  const refreshHolidaysList = useRecoilValue(refreshSelectorFamily('HOLIDAYS_LIST'));
  const { handleClose } = useModal();
  const { mutate } = useMutation(editHolidayAction);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: AddHolidayActionProps | EditHolidayActionProps): Promise<void> => {
      if (_.isEqual(data, _.omit(holiday, ['id', 'isDefault']))) {
        addSnackbar({
          message: t({
            id: 'settings.holiday.edit_default',
            message: 'No changes to save!',
          }),
          variant: 'default',
        });
        return;
      }

      const { error: submitError } = await mutate({ ...data, id });

      if (!submitError) {
        if (handleClose) {
          handleClose();
          refreshHolidaysList();
        }

        addSnackbar({
          message: t({
            id: 'settings.holiday.edit_success',
            message: 'Edited holiday',
          }),
          variant: 'success',
        });
      }

      if (submitError) {
        addSnackbar({
          message: t({
            id: 'settings.holiday.edit_danger',
            message: "Couldn't edit holiday",
          }),
          variant: 'danger',
        });
      }
      setLoading(false);
    },
    [handleClose, holiday, id, mutate, refreshHolidaysList],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.holidays.edit">Edit Holiday</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {id && !holiday ? (
          <LoadingSpinnerCss size={4} />
        ) : (
          <AddEditHolidayForm
            ref={formRef}
            onSubmit={onSubmit}
            setLoading={setLoading}
            defaultValues={
              holiday && {
                dateUnix: holiday.dateUnix,
                name: holiday.name,
              }
            }
          />
        )}
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
