import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import { payloadSelectorFamily, resetFormButtonAtom } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { InputOption, Select } from 'components/ui/Select/Select';
import {
  FetchTimeTrackingSettingsResponse,
  PayPeriodSettingsActionProps,
  OvertimePayrollPeriod,
  NormalPayrollPeriod,
} from 'api/actions/settings/settingsActions.types';
import { payPeriodSettingsAction } from 'api/actions/settings/settingsActions';
import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';

type Props = {
  payload: PayPeriodSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  payPeriodShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  payPeriodFormPendingRef: React.MutableRefObject<boolean>;
  payPeriodStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

type keys = keyof PayPeriodSettingsActionProps;

export const PayPeriod = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      payPeriodShouldBlockTransitionRef,
      payPeriodFormPendingRef,
      payPeriodStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchTimeTrackingSettingsResponse>(payloadSelectorFamily('TIME_TRACKING'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const { mutate } = useMutation(payPeriodSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<PayPeriodSettingsActionProps>(payload);

    const {
      setError,
      register,
      handleSubmit,
      reset: resetForm,
      formState: { errors },
    } = useForm({ defaultValues: payload, mode: 'onTouched', reValidateMode: 'onChange' });

    const overtimePayrollPeriodOptions: InputOption[] = useMemo(
      () => [
        {
          label: t({ id: 'settings.select.same_as_above', message: 'Same as above' }),
          id: OvertimePayrollPeriod.Default.toString(),
        },
        {
          label: t({ id: 'settings.select.every_week', message: 'Every week' }),
          id: OvertimePayrollPeriod.Weeks1.toString(),
        },
        {
          label: t({ id: 'settings.select.every_two_week', message: 'Every 2 weeks' }),
          id: OvertimePayrollPeriod.Weeks2.toString(),
        },
        {
          label: t({ id: 'settings.select.every_month', message: 'Every month' }),
          id: OvertimePayrollPeriod.Months1.toString(),
        },
        {
          label: t({ id: 'settings.select.every_two_month', message: 'Every 2 months' }),
          id: OvertimePayrollPeriod.Months2.toString(),
        },
        {
          label: t({ id: 'settings.select.every_three_month', message: 'Every 3 months' }),
          id: OvertimePayrollPeriod.Months3.toString(),
        },
        {
          label: t({ id: 'settings.select.every_four_month', message: 'Every 4 months' }),
          id: OvertimePayrollPeriod.Months4.toString(),
        },
        {
          label: t({ id: 'settings.select.every_six_month', message: 'Every 6 months' }),
          id: OvertimePayrollPeriod.Months6.toString(),
        },
        {
          label: t({ id: 'settings.select.every_twelve_month', message: 'Every 12 months' }),
          id: OvertimePayrollPeriod.Months12.toString(),
        },
      ],
      [],
    );

    const payrollPeriodOptions: InputOption[] = useMemo(
      () => [
        {
          label: t({ id: 'settings.select.every_week' }),
          id: NormalPayrollPeriod.Weeks1.toString(),
        },
        {
          label: t({ id: 'settings.select.every_two_week' }),
          id: NormalPayrollPeriod.Weeks2.toString(),
        },
        {
          label: t({ id: 'settings.select.every_month' }),
          id: NormalPayrollPeriod.Months1.toString(),
        },
      ],
      [],
    );

    const dispatchSubmitEvent = () => {
      const submitEvent = createEvent('submit');
      payPeriodFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    };

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnBlur = useCallback(() => {
      payPeriodShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, payPeriodShouldBlockTransitionRef, reset, start]);

    const handlePeriodValidation = useCallback(
      (data: PayPeriodSettingsActionProps) => {
        const overtimePeriodValue = data.overtimePeriod;
        const payrollPeriodValue = data.payrollPeriod;
        // FIXME: dlaczego to jest na index a nie na enumie czy jakiejś tablicy pomocniczej? poleganie na kolejności tablicy opcji jest niebezpieczne, przy zmianie w opcjach nikt nie będzie się spodziewał ze to się wykrzaczy
        const freqIndex = _.findIndex(overtimePayrollPeriodOptions, ['id', `${payrollPeriodValue}`]);
        const overtimeIndex = _.findIndex(overtimePayrollPeriodOptions, ['id', `${overtimePeriodValue}`]);

        if (!(freqIndex <= overtimeIndex) && !(overtimeIndex === 0)) {
          setError('overtimePeriod', {
            type: 'validate',
          });
          setError('payrollPeriod', {
            type: 'validate',
            message: t({
              id: 'settings.forms.overtime_pay_period',
              message: 'Overtime pay period cannot be lower than frequency',
            }),
          });

          return false;
        }

        return true;
      },
      [overtimePayrollPeriodOptions, setError],
    );

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      resetForm({ ...payload });
      payPeriodShouldBlockTransitionRef.current = false;
      payPeriodFormPendingRef.current = false;
    }, [payPeriodFormPendingRef, payPeriodShouldBlockTransitionRef, payload, resetForm]);

    const handleSubmitCallback = useCallback(
      async (data: PayPeriodSettingsActionProps) => {
        const validation = handlePeriodValidation(data);
        const payPeriodResetObject = { name: 'PAY_PERIOD_CALLBACK', callback: handleFormReset };
        reset();

        if (validation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === payPeriodResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(data, payload) && validation) {
          const { error } = await mutate(data);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...data }));
            mutationDataRef.current = data;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [payPeriodResetObject] : [...prevState, payPeriodResetObject],
            );
            return;
          }
        }

        if (!validation) {
          setResetCallbacks((prevState) =>
            !prevState ? [payPeriodResetObject] : [...prevState, payPeriodResetObject],
          );
          return;
        }

        payPeriodShouldBlockTransitionRef.current = false;
        payPeriodFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        handleFormReset,
        handlePeriodValidation,
        mutate,
        payPeriodFormPendingRef,
        payPeriodShouldBlockTransitionRef,
        payload,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payload)) handleFormReset();
    }, [handleFormReset, payload]);

    useEffect(() => {
      payPeriodStatusRef.current = status;
    }, [payPeriodStatusRef, status]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    return (
      <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <HeadingError
            label={t({
              id: 'time_tracking_settings.heading.pay_period',
              message: 'Pay period',
            })}
            errorMessage={errors.payrollPeriod?.message}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <OptionLabel
              label={t({
                id: 'time_tracking_settings.payroll_period.label',
                message: 'Frequency',
              })}
              apendWith={
                <Select
                  {...registerOnBlur('payrollPeriod', {
                    required: t({
                      id: 'global.forms.required',
                    }),
                    valueAsNumber: true,
                  })}
                  id="payrollPeriod"
                  placeholder={t({
                    id: 'time_tracking_settings.payroll_period.label',
                  })}
                  options={_.slice(payrollPeriodOptions, 0, 3)}
                  size="sm"
                  error={!!errors.payrollPeriod}
                  sx={{ maxWidth: '188px' }}
                />
              }
              withDivider
            />
            <OptionLabel
              label={t({
                id: 'time_tracking_settings.overtime_period.label',
                message: 'Overtime pay period',
              })}
              additionalLabel={
                <>
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    <Trans id="time_tracking_settings.requires_schedules_and_requests" />
                  </Text>
                  .
                </>
              }
              apendWith={
                <Select
                  {...registerOnBlur('overtimePeriod', {
                    required: t({
                      id: 'global.forms.required',
                    }),
                    valueAsNumber: true,
                  })}
                  id="overtimePeriod"
                  placeholder={t({
                    id: 'time_tracking_settings.overtime_period.label',
                  })}
                  options={overtimePayrollPeriodOptions}
                  size="sm"
                  error={!!errors.overtimePeriod}
                  sx={{ maxWidth: '188px' }}
                />
              }
              withDivider
            />
          </Flex>
        </Flex>
      </form>
    );
  },
);

export const MemoizedPayPeriod = React.memo(PayPeriod);
