import { motion, useMotionValue } from 'framer-motion';
import React, { useEffect } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

type Props = { size: number; rotateX: number; rotateY?: never } | { size: number; rotateY: number; rotateX?: never };

export const Ellipsis = React.forwardRef<HTMLDivElement, Props>(({ size, rotateX, rotateY }: Props, ref) => {
  const faceBoundingCircleSx: ThemeUIStyleObject = {
    position: 'absolute',
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    size: `${size}px`,
    borderRadius: 'circle',
    zIndex: 1,
  };

  const opacity = useMotionValue(0);
  const curve = useMotionValue(0);

  useEffect(() => {
    const angle = rotateX || rotateY || 0;
    curve.set(angle);
  }, [curve, rotateX, rotateY]);

  return (
    <Box ref={ref} sx={faceBoundingCircleSx}>
      <motion.svg
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 0.8,
        }}
        transition={{
          delay: 0.65,
        }}
        width="180"
        height="180"
        viewBox="0 0 180 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: '108%',
          height: '108%',
          margin: '-4%',
          transformOrigin: 'center center',
          transform: rotateX ? `rotate(-90deg)` : '',
        }}
      >
        {[...Array(20)].map((val, index) => (
          <motion.path
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}`}
            style={{
              opacity,
              transition: `opacity ease-in ${0.05 * index + 0.05}s`,
            }}
            animate={{
              d: `M 90 0 C ${curve.get()} 36 ${curve.get()} 144 90 180 `,
            }}
            stroke={`rgba(192, 249, 179,${0.5 - 0.025 * index})`}
            strokeWidth={1}
            transition={{
              type: 'spring',
              damping: 40 + index * 2,
              stiffness: 200,
            }}
            {...(index === 0 && {
              onAnimationStart: () => {
                opacity.set(1);
              },
              onAnimationComplete: () => {
                opacity.set(0);
              },
            })}
          />
        ))}
      </motion.svg>
    </Box>
  );
});
