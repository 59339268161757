import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Box, ThemeUIStyleObject, Text, Flex } from 'theme-ui';
import { usePopper } from 'react-popper';
import { useRecoilValue } from 'recoil';
import ReactDOM from 'react-dom';
import { Placement, PositioningStrategy } from '@popperjs/core';
import { keyframes } from '@emotion/react';

import { useOnOudsideKeyDown } from 'hooks/useOnOudsideKeyDown/useOnOudsideKeyDown';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick/useOnOutsideClick';
import { useKeyboardEvent } from 'hooks/useKeybordEvent/useKeybordEvent';
import { useEvent } from 'hooks/useEvent/useEvent';
import { windowSizeAtom } from 'state/recoilState';
import { mergeRefs } from 'utils/mergeRefs';
import { delay } from 'utils/delay';

let popperRoot = document.getElementById('popper-root');

export const decorationSx: ThemeUIStyleObject = {
  textDecorationStyle: 'dotted',
  textDecorationLine: 'underline',
  textDecorationColor: 'tooltip.textDecoration',
};

type Props = {
  children: React.ReactElement[] | React.ReactElement | string;
  content: React.ReactNode;
  placement?: Placement;
  trigger?: 'hover' | 'click' | 'manual';
  popperMargin?: number;
  dismissOnEsc?: boolean;
  delayShow?: number;
  withArrow?: boolean;
  arrowSx?: ThemeUIStyleObject;
  popperContainerSx?: ThemeUIStyleObject;
  withPopperState?: boolean;
  positionStrategy?: PositioningStrategy;
  visible?: boolean;
  hideAfterPopperClick?: boolean;
  hideOnReferenceHidden?: boolean;
  withAnimation?: boolean;
  widthLikeReferenceElement?: boolean;
  withPortal?: boolean;
  onClickElement?: () => void;
  onClickPopper?: () => void;
  onOutsideClick?: () => void;
};

export type PopperState = {
  isVisible: boolean;
  setIsVisible: () => void;
};

export type PopperProviderProps = Props;

const defaultProps: Partial<Props> = {
  dismissOnEsc: false,
  trigger: 'hover',
  placement: 'auto',
  popperMargin: 0.5,
  delayShow: 750,
  withArrow: false,
  arrowSx: undefined,
  withPopperState: undefined,
  popperContainerSx: undefined,
  positionStrategy: 'fixed',
  onClickElement: undefined,
  onClickPopper: undefined,
  visible: false,
  onOutsideClick: undefined,
  hideAfterPopperClick: false,
  hideOnReferenceHidden: true,
  widthLikeReferenceElement: false,
  withAnimation: false,
  withPortal: false,
};

export const PopperProvider = ({
  placement,
  trigger,
  popperMargin,
  delayShow,
  children,
  dismissOnEsc,
  withArrow,
  content,
  arrowSx,
  withPopperState,
  positionStrategy,
  popperContainerSx,
  visible,
  hideAfterPopperClick,
  hideOnReferenceHidden,
  withAnimation,
  widthLikeReferenceElement,
  withPortal,
  onClickElement,
  onClickPopper,
  onOutsideClick,
}: Props): React.ReactElement => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const popperContainerRef = useRef<HTMLDivElement | null>(null);
  const referenceElementRef = useRef<HTMLDivElement | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const shouldUseOnOutsideClick = useRef<boolean>(false);

  const { isMobile } = useRecoilValue(windowSizeAtom);

  useEffect(() => {
    referenceElementRef?.current?.setAttribute('data-popper-visible', `${isVisible}`);
  }, [isVisible]);

  const { styles, attributes, state } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: positionStrategy,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: -4,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 6 + (popperMargin ? popperMargin * 16 : 0)],
        },
      },
    ],
  });

  if (!popperRoot) {
    popperRoot = document.createElement('div');
    popperRoot.setAttribute('id', 'popper-root');
  }

  const rotateArrow = useCallback((): string => {
    switch (state?.placement) {
      case 'right':
      case 'left':
      case 'right-start':
        return 'rotate(-45deg)';
      case 'top':
      case 'bottom':
        return 'rotate(45deg)';
      case 'bottom-start':
      case 'top-end':
        return 'rotate(75deg)';
      default:
        return '';
    }
  }, [state?.placement]);

  const showPopperWithTimeout = useCallback(() => {
    timeout.current = setTimeout(() => {
      setIsVisible(true);
    }, delayShow);
  }, [timeout, delayShow]);

  const hidePopper = useCallback(() => {
    setIsVisible(false);
    if (timeout.current) clearTimeout(timeout.current);
  }, [timeout]);

  useOnOutsideClick(popperContainerRef, async () => {
    shouldUseOnOutsideClick.current = true;
    await delay(0);
    shouldUseOnOutsideClick.current = false;
  });

  useOnOutsideClick(referenceElementRef, () => {
    if (shouldUseOnOutsideClick.current) {
      if ((isVisible && trigger === 'click') || isMobile) hidePopper();
      if (onOutsideClick) onOutsideClick();
    }

    shouldUseOnOutsideClick.current = false;
  });

  useOnOudsideKeyDown(popperContainerRef, 'Tab', () => {
    if (isVisible) {
      if (onOutsideClick) {
        onOutsideClick();
      } else {
        hidePopper();
      }
    }
  });

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (hideAfterPopperClick) hidePopper();
      if (onClickPopper) onClickPopper();
    },
    [hideAfterPopperClick, hidePopper, onClickPopper],
  );

  useKeyboardEvent('Escape', () => isVisible && trigger === 'click' && hidePopper(), !dismissOnEsc);

  useEvent(
    'click',
    () => {
      if (trigger === 'click') setIsVisible(!isVisible);
      if (onClickElement) onClickElement();
    },
    trigger !== 'hover' || isMobile ? referenceElement : null,
  );

  useEvent(
    'mouseover',
    () => !isVisible && showPopperWithTimeout(),
    trigger === 'hover' && !isMobile ? referenceElement : null,
  );
  useEvent('mouseout', () => hidePopper(), trigger === 'hover' && !isMobile ? referenceElement : null);

  useEffect(() => {
    if (trigger === 'manual' && typeof visible === 'boolean') setIsVisible(visible);
  }, [trigger, visible]);

  const renderPopperContainer = useCallback(
    () => (
      <Flex
        variant="popper.container"
        onClick={onClick}
        ref={mergeRefs([setPopperElement, popperContainerRef])}
        {...attributes.popper}
        style={styles.popper}
        sx={{
          ...(hideOnReferenceHidden && {
            '&[data-popper-reference-hidden="true"]': {
              visibility: 'hidden',
              pointerEvents: 'none',
            },
          }),
          ...(withAnimation && {
            opacity: 0,
            animation: `${keyframes({
              to: {
                opacity: 0.9,
              },
            })} .5s forwards`,
          }),
          ...(popperContainerSx && popperContainerSx),
          ...(widthLikeReferenceElement && { width: `${state?.rects?.reference?.width}px` }),
        }}
      >
        {content}
        {withArrow && (
          <Box
            variant="popper.arrow"
            className="arrow"
            ref={setArrowElement}
            sx={{
              ...(arrowSx && arrowSx),
              ...styles.arrow,
              transform: `${styles.arrow.transform} ${rotateArrow()} skew(15deg, 15deg)`,
            }}
          />
        )}
      </Flex>
    ),
    [
      arrowSx,
      attributes.popper,
      content,
      hideOnReferenceHidden,
      onClick,
      popperContainerSx,
      rotateArrow,
      state?.rects?.reference?.width,
      styles.arrow,
      styles.popper,
      widthLikeReferenceElement,
      withAnimation,
      withArrow,
    ],
  );
  return (
    <>
      {isVisible && withPortal && ReactDOM.createPortal(renderPopperContainer(), popperRoot)}

      {isVisible && !withPortal && renderPopperContainer()}

      {React.Children.map(typeof children === 'string' ? <Text>{children}</Text> : children, (child) =>
        React.cloneElement(child, {
          ref: mergeRefs([setReferenceElement, referenceElementRef]),
          ...(withPopperState && { popperState: { isVisible, setIsVisible } }),
        }),
      )}
    </>
  );
};

PopperProvider.defaultProps = defaultProps;
