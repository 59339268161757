import { t } from '@lingui/macro';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLingui } from '@lingui/react';

import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { RequestsForms } from 'layouts/Settings/Organization/forms/RequestsForms';
import { resetFormButtonAtom, resetFormButtonCallbacksSelector } from 'state/settings';

import { RequestModalRoutes } from './RequestModalRoutes';

// TODO: After CORE - too many conditional renders and the view jumps a lot.
export const Requests = (): React.ReactElement => {
  useLingui();
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);

  const handleResetButtonClick = () => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  };

  return (
    <>
      <HeadingTip
        label={t({
          id: 'requests_settings.heading.requests',
          message: 'Requests',
        })}
        displayReset={!!formResetCallbacks}
        displayOnClick={handleResetButtonClick}
      />
      <RequestsForms />
      <RequestModalRoutes />
    </>
  );
};
