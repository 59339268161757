import { useSetRecoilState } from 'recoil';
import { useClient } from 'react-fetching-library';
import { useCallback } from 'react';

import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { REFRESH_SESSION } from 'constants/customEventsNames';
import { organizationSessionAtom } from 'state/organizationSession';
import { userSessionAtom } from 'state/userSession';
import { fetchOrganizationSessionAction } from 'api/actions/organizationSession/organizationSessionActions';
import { refreshUserSessionAction } from 'api/actions/userSession/userSessionActions';
import { getParsedOrganizationSession } from 'context/session/organizationSessionInitializer/getParsedOrganizationSession';

export enum RefreshSessionCode {
  USER_AND_ORGANIZATION = 0,
  USER,
  ORGANIZATION,
}

export const RefreshSessionObserver: React.ElementType = () => {
  const setUserSession = useSetRecoilState(userSessionAtom);
  const setOrganizationSession = useSetRecoilState(organizationSessionAtom);
  const { query } = useClient();

  const getUserSession = useCallback(async () => {
    const { payload, error: submitError } = await query(refreshUserSessionAction());
    if (!submitError && payload) {
      setUserSession(payload);
    }
  }, [query, setUserSession]);

  const getOrganizationSession = useCallback(async () => {
    const { payload, error: submitError } = await query(fetchOrganizationSessionAction());
    if (!submitError && payload) {
      const parsedOrganizationSession = getParsedOrganizationSession(payload);

      setOrganizationSession(parsedOrganizationSession);
    }
  }, [query, setOrganizationSession]);

  useCustomEventListener(REFRESH_SESSION, (refreshSessionCode) => {
    switch (refreshSessionCode as number) {
      case RefreshSessionCode.USER:
        getUserSession();
        break;
      case RefreshSessionCode.ORGANIZATION:
        getOrganizationSession();
        break;
      case RefreshSessionCode.USER_AND_ORGANIZATION:
        getUserSession();
        getOrganizationSession();
        break;
      default:
        break;
    }
  });

  return null;
};
