import React from 'react';
import { Box, BoxProps } from 'theme-ui';

import { Icon, IconProps } from 'components/Icon/Icon';

type Props = BoxProps & {
  children: string;
  iconProps: Pick<IconProps, 'size'>;
  onClick: () => void;
};

export const SelectedMultiOption = ({ children, onClick, iconProps, ...props }: Props): React.ReactElement => {
  const internalOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (onClick) onClick();
  };
  return (
    <Box as="span" variant="forms.select.selectedOption" onClick={internalOnClick} {...props}>
      <span>{children}</span>
      <Icon type="x" {...iconProps} fill="white" wrapperSx={{ ml: 1 }} />
    </Box>
  );
};
