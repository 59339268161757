import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil';
import _ from 'lodash';

import { ChatUserForFetch, FetchChatUsersResponse, MessageForFetch } from 'api/actions/chat/chatActions.types';
import { ChatWindowState } from 'chat/ChatWindow/types';
import {
  MAIN_WINDOW_WIDTH,
  CHAT_WINDOW_WIDTH,
  CHAT_WINDOW_MOBILE_WIDTH,
  CHAT_WINDOW_IS_VISIBLE_WIDTH,
  CHAT_WINDOW_IS_EXPANDED_WIDTH,
  MARGIN,
  MAIN_WINDOW_MOBILE_WIDTH,
  CHAT_BOT,
  MESSAGES_PART_SIZE,
} from 'chat/constans';
import { Employee, PersonName } from 'api/actions/organizationSession/organizationSessionActions.types';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';

import { windowSizeAtom } from './recoilState';
import { organizationSessionAtom } from './organizationSession';
import { nameDisplayOrderSelector, userSessionAtom } from './userSession';

type ChatWidths = {
  default: number;
  isVisible: number;
  isExpanded: number;
  mainWindow: number;
};

const defaultChatWidthsSelector = selector<ChatWidths>({
  key: 'defaultChatWidths',
  get: ({ get }) => {
    const { width } = get(windowSizeAtom);

    if ((width || window.innerWidth) > MEDIA_BREAKPOINTS.SM)
      return {
        default: CHAT_WINDOW_WIDTH + MARGIN,
        isVisible: CHAT_WINDOW_IS_VISIBLE_WIDTH + MARGIN,
        isExpanded: CHAT_WINDOW_IS_EXPANDED_WIDTH + MARGIN,
        mainWindow: MAIN_WINDOW_WIDTH + MARGIN,
      };
    return {
      default: CHAT_WINDOW_MOBILE_WIDTH + MARGIN,
      isVisible: 0,
      isExpanded: 0,
      mainWindow: MAIN_WINDOW_MOBILE_WIDTH + MARGIN,
    };
  },
});

const mainWindowFilterInputAtom = atom<string>({
  key: 'mainWindowFilterInput',
  default: '',
});

export const mainWindowIsVisibleAtom = atom<boolean>({
  key: 'mainWindowIsVisible',
  default: false,
});

export const crispIsVisibleAtom = atom<boolean>({
  key: 'crispIsVisible',
  default: false,
});

export const parsedMainWindowFilterInputSelector = selector<string>({
  key: 'parsedMainWindowFilterInput',
  get: ({ get }) => {
    const query = get(mainWindowFilterInputAtom);
    return query.replace(/ /g, '');
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(mainWindowFilterInputAtom, newValue);
    }
  },
});

type ParsedChatUserType = Required<ChatUserForFetch> & Partial<Pick<Employee, 'avatarUrl'>> & PersonName;

const chatUsersAtom = atom<ParsedChatUserType[] | null>({
  key: 'chatUsers',
  default: null,
});

type ChatUser = Required<Omit<ChatUserForFetch, 'sender'>> & Partial<Pick<Employee, 'avatarUrl'>> & PersonName;

export const chatUserAtomFamily = atomFamily<ChatUser | null, string>({
  key: 'chatUser',
  default: null,
});

export const anyChatUserHasUnreadMessageSelector = selector<boolean>({
  key: 'anyChatUserHasUnreadMessage',
  get: ({ get }) => {
    const chatUsers = get(chatUsersAtom);

    if (!chatUsers) return false;

    const user = _.find(chatUsers, (u) => get(chatUserAtomFamily(u.sender))?.hasUnreadMessages);

    if (user) return true;

    return false;
  },
});
// TODO: chatbot as dummy
export const chatUsersListSelector = selector<FetchChatUsersResponse | null>({
  key: 'chatUsersList',
  get: ({ get }) => get(chatUsersAtom),
  set: ({ set, get }, newChatUsers) => {
    const organizationSession = get(organizationSessionAtom);

    if (!(newChatUsers instanceof DefaultValue) && newChatUsers && organizationSession) {
      const { employeesMap } = organizationSession;

      const parsedChatUsers: ParsedChatUserType[] = _.filter(
        _.map(newChatUsers, (u) => {
          const employee = employeesMap.get(u.sender);

          if (!employee) return null;

          const { name, avatarUrl } = employee;
          const parsedUser: ParsedChatUserType = {
            ...u,
            ...name,
            avatarUrl,
          };

          set(chatUserAtomFamily(u.sender), parsedUser);

          return parsedUser;
        }),
        (u): u is ParsedChatUserType => !!u,
      );

      set(chatUsersAtom, parsedChatUsers);
    }

    set(chatUserAtomFamily(CHAT_BOT), {
      hasUnreadMessages: false,
      isOnline: true,
      firstName: 'CHAT',
      surname: 'BOT',
    });
  },
});

type ParsedChatUser = ChatUserForFetch['sender'];

export const filteredAndSortedChatUsersIdsSelector = selector<ParsedChatUser[] | null>({
  key: 'filteredAndSortedChatUsersIdsData',
  get: ({ get }) => {
    const chatUsers = get(chatUsersAtom);
    const search = get(parsedMainWindowFilterInputSelector);
    const { sortBy } = get(nameDisplayOrderSelector);

    const searchingFor = (firstName: string, surname: string) => {
      const string = `${firstName}${surname}${surname}${firstName}`;
      return string.toLowerCase().includes(search.toLowerCase());
    };

    if (!chatUsers) return null;
    const filteredChatUsersWithPersonalData: ParsedChatUser[] = _.map(
      _(chatUsers)
        .filter((u) => searchingFor(u.firstName, u.surname))
        .orderBy(['hasUnreadMessages', sortBy], 'desc')
        .value(),
      (u) => u.sender,
    );
    return filteredChatUsersWithPersonalData;
  },
});

export const chatWindowIdsAtom = atom<ChatUserForFetch['sender'][]>({
  key: 'chatWindowIds',
  default: [],
});

export const changedChatWindowIdsAtom = atom<ChatUserForFetch['sender'][]>({
  key: 'changedChatWindowIds',
  default: [],
});

export const chatWindowAtomFamily = atomFamily<ChatWindowState, string>({
  key: 'chatWindow',
  default: {
    isVisible: false,
    isExpanded: false,
  },
});

export const messagesAtomFamily = atomFamily<ParsedMessage[] | null, string>({
  key: 'messages',
  default: null,
});
export const sortedMessagesSelector = selectorFamily<ParsedMessage[] | null, string>({
  key: 'sortedMessages',
  get:
    (id) =>
    ({ get }) => {
      const messages = get(messagesAtomFamily(id));

      if (!messages) return null;

      return _.orderBy(messages, ['createdUnix'], ['desc']);
    },
});
export const removeMessageSelector = selectorFamily<MessageForFetch['id'] | null, string>({
  key: 'removeMessage',
  get: () => () => null,
  set:
    (atomId) =>
    ({ set, get }, messageId) => {
      const messages = get(messagesAtomFamily(atomId));

      if (!(messageId instanceof DefaultValue) && messages) {
        set(
          messagesAtomFamily(atomId),
          _.filter(messages, (m) => !_.isEqual(m.id, messageId)),
        );
      }
    },
});

export enum MessageSendStatus {
  SENT = 0,
  UNSENT,
  SENDING,
}

type MessageStatus = {
  status: MessageSendStatus;
};

export type ParsedMessage = Pick<Employee, 'avatarUrl'> & MessageForFetch & PersonName & Partial<MessageStatus>;

export const parsedMessagesWithPersonalDataSelector = selectorFamily<MessageForFetch[] | null, string>({
  key: 'parsedMessagesWithPersonalData',
  get: () => () => null,
  set:
    (atomId) =>
    ({ get, set }, newMessages) => {
      const messages = get(messagesAtomFamily(atomId));
      const organizationSession = get(organizationSessionAtom);

      if (!organizationSession || !atomId || newMessages instanceof DefaultValue) return;

      const { employeesMap } = organizationSession;

      const parsedMessages: ParsedMessage[] = _.filter(
        _.map(newMessages, (m) => {
          const employee = employeesMap.get(m.sender);

          if (!employee) return {};

          const { name, avatarUrl } = employee;

          return {
            ...m,
            ...name,
            avatarUrl,
          };
        }),
        (m): m is ParsedMessage => !!m,
      );

      if (messages) set(messagesAtomFamily(atomId), [...messages, ...parsedMessages]);
      else set(messagesAtomFamily(atomId), parsedMessages);
    },
});

export const removeChatWindowSelector = selectorFamily<ChatWindowState | null, string>({
  key: 'removeChatWindow',
  get: () => () => null,
  set:
    (id) =>
    ({ set, get, reset }) => {
      const chatWindowIds = get(chatWindowIdsAtom);

      if (chatWindowIds && id) {
        set(
          chatWindowIdsAtom,
          _.filter(chatWindowIds, (w) => !_.isEqual(w, id)),
        );
        reset(chatWindowAtomFamily(id));
        reset(messagesAtomFamily(id));
      }
    },
});

const chatWindowWidthSelector = selectorFamily<number, string>({
  key: 'chatWindowWidth',
  get:
    (id) =>
    ({ get }) => {
      const chatWindow = get(chatWindowAtomFamily(id));
      const { isVisible, isExpanded, default: defaultWidth } = get(defaultChatWidthsSelector);

      if (!chatWindow) return 0;
      if (chatWindow.isExpanded) return isExpanded;
      if (chatWindow.isVisible) return isVisible;

      return defaultWidth;
    },
});

export const anyWindowIsVisibleSelector = selector<boolean>({
  key: 'anyWindowIsVisible',
  get: ({ get }) => {
    const chatWindowIds = get(chatWindowIdsAtom);
    const mainWindowIsVisible = get(mainWindowIsVisibleAtom);

    if (!chatWindowIds) return false;
    if (mainWindowIsVisible) return true;

    const isVisible = _.find(chatWindowIds, (w) => get(chatWindowAtomFamily(w))?.isVisible);

    return !!isVisible;
  },
});

export const anyChatWindowIsVisibleSelector = selector<boolean>({
  key: 'anyChatWindowIsVisible',
  get: ({ get }) => {
    const chatWindowIds = get(chatWindowIdsAtom);

    if (!chatWindowIds) return false;

    const isVisible = _.find(chatWindowIds, (w) => get(chatWindowAtomFamily(w))?.isVisible);

    return !!isVisible;
  },
});

export const chatWidthSelector = selector<number>({
  key: 'chatWidth',
  get: ({ get }) => {
    const chatWindowIds = get(chatWindowIdsAtom);
    const chatWindowsWidth = _.sum(_.map(chatWindowIds, (w) => get(chatWindowWidthSelector(w))));
    const { mainWindow } = get(defaultChatWidthsSelector);

    return chatWindowsWidth + mainWindow;
  },
});

type ChatWindowAction = {
  type: keyof ChatWindowState;
  value: ChatWindowState['isExpanded'] | ChatWindowState['isVisible'];
};

export const setStateChatWindowSelector = selectorFamily<ChatWindowAction | null, string>({
  key: 'setStateChatWindow',
  get: () => () => null,
  set:
    (id) =>
    ({ set, get }, action) => {
      const chatWindow = get(chatWindowAtomFamily(id));
      const chatWindowIds = get(chatWindowIdsAtom);
      if (chatWindowIds.length > 0 && chatWindow && action && !(action instanceof DefaultValue)) {
        const { width: windowWidth } = get(windowSizeAtom);
        const chatWidth = get(chatWidthSelector);
        const chatWindowCurrentWidth = get(chatWindowWidthSelector(id));
        const chatWidths = get(defaultChatWidthsSelector);
        if (
          action.value &&
          windowWidth &&
          chatWidth &&
          windowWidth - chatWidth < chatWidths[action.type] - chatWindowCurrentWidth
        ) {
          const windowToRemove = _.find(chatWindowIds, (w) => !_.isEqual(w, id));
          const windowToRemove2 = _.find(chatWindowIds, (w) => !_.isEqual(w, id) && !_.isEqual(w, windowToRemove));

          if (windowToRemove && windowToRemove2) {
            if (windowWidth - chatWidth + chatWidths.isVisible < chatWidths[action.type]) {
              set(removeChatWindowSelector(windowToRemove), null);
            } else {
              set(removeChatWindowSelector(windowToRemove), null);
              set(removeChatWindowSelector(windowToRemove2), null);
            }
          }
        }
        set(chatWindowAtomFamily(id), _.set(_.clone(chatWindow), action.type, action.value));
      }
    },
});

export const hasBeenUnreadMessagesSelector = selectorFamily<ChatUserForFetch['hasUnreadMessages'] | null, string>({
  key: 'hasBeenUnreadMessages',
  get:
    (id) =>
    ({ get }) => {
      const chatUser = get(chatUserAtomFamily(id));
      if (!chatUser) return null;
      return chatUser.hasUnreadMessages;
    },
  set:
    (id) =>
    ({ get, set }, newValue) => {
      const chatUsers = _.cloneDeep(get(chatUsersAtom));
      const index = _.findIndex(chatUsers, (u) => _.isEqual(u.sender, id));
      const chatUser = get(chatUserAtomFamily(id));

      if (!(newValue instanceof DefaultValue) && newValue !== null && id && index && chatUsers && chatUser) {
        chatUsers[index].hasUnreadMessages = newValue;
        set(chatUsersAtom, chatUsers);
        set(chatUserAtomFamily(id), {
          ...chatUser,
          hasUnreadMessages: newValue,
        });
      }
    },
});

export const addChatWindowIdsSelector = selector<string | null>({
  key: 'addChatWindowIds',
  get: () => null,
  set: ({ get, set }, id) => {
    const chatWindowIds = get(chatWindowIdsAtom);

    if (!(id instanceof DefaultValue) && id) {
      if (chatWindowIds.length === 0) {
        set(chatWindowIdsAtom, [id]);
        return;
      }

      if (_.findIndex(chatWindowIds, (w) => _.isEqual(w, id)) === -1) {
        const { width: windowWidth } = get(windowSizeAtom);
        const chatWidth = get(chatWidthSelector);
        const windowToRemove = _.find(chatWindowIds, (w) => !_.isEqual(w, id));
        const { isVisible } = get(defaultChatWidthsSelector);

        if (windowToRemove && windowWidth && chatWidth && windowWidth - chatWidth < isVisible) {
          set(removeChatWindowSelector(windowToRemove), null);
          set(chatWindowIdsAtom, [id, ..._.filter(chatWindowIds, (w) => !_.isEqual(w, windowToRemove))]);
        } else {
          set(chatWindowIdsAtom, [id, ...chatWindowIds]);
        }
      }
    }
  },
});

export type ChatUserStatus = {
  personId: ChatUserForFetch['sender'];
  isOnline: ChatUserForFetch['isOnline'];
};

export const setIsOnlineSelector = selector<ChatUserStatus | null>({
  key: 'setIsOnline',
  get: () => null,
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue || !newValue) return;

    const chatUser = get(chatUserAtomFamily(newValue.personId));

    if (chatUser) {
      set(chatUserAtomFamily(newValue.personId), {
        ...chatUser,
        isOnline: newValue.isOnline,
      });
    }
  },
});

export const addMessageSelector = selector<MessageForFetch | null>({
  key: 'addMessage',
  get: () => null,
  set: ({ set, get }, newMessage) => {
    if (!(newMessage instanceof DefaultValue) && newMessage) {
      const messages = get(messagesAtomFamily(newMessage.sender));
      const organizationSession = get(organizationSessionAtom);
      const userSession = get(userSessionAtom);

      if (!organizationSession) return;

      const { employeesMap } = organizationSession;

      const employee = employeesMap.get(newMessage.sender);
      const findMessage = _.find(messages, (m) => m.id === newMessage.id);

      if (!employee || findMessage) return;

      const { name, avatarUrl } = employee;

      if (findMessage) return;

      if (messages) {
        set(messagesAtomFamily(newMessage.sender), [
          {
            ...newMessage,
            ...name,
            avatarUrl,
          },
          ...messages,
        ]);
      }

      if (userSession && userSession.personId !== newMessage.sender) {
        const chatUser = get(chatUserAtomFamily(newMessage.sender));

        if (chatUser && !chatUser.hasUnreadMessages) {
          set(hasBeenUnreadMessagesSelector(newMessage.sender), true);
        }
      }
    }
  },
});

type OwnMessage = MessageForFetch & Partial<MessageStatus>;

export const addOwnMessageSelector = selector<OwnMessage | null>({
  key: 'addOwnMessage',
  get: () => null,
  set: ({ set, get }, newMessage) => {
    if (!(newMessage instanceof DefaultValue) && newMessage) {
      const messages = get(messagesAtomFamily(newMessage.receiver));
      const organizationSession = get(organizationSessionAtom);

      if (!organizationSession) return;

      const { employeesMap } = organizationSession;

      const employee = employeesMap.get(newMessage.sender);

      if (!employee) return;

      const { name, avatarUrl } = employee;

      if (messages) {
        set(messagesAtomFamily(newMessage.receiver), [
          {
            ...newMessage,
            ...name,
            avatarUrl,
          },
          ...messages,
        ]);
      }
    }
  },
});

export const updateOwnMessageSelector = selector<OwnMessage | null>({
  key: 'updateOwnMessage',
  get: () => null,
  set: ({ set, get }, newMessage) => {
    if (!(newMessage instanceof DefaultValue) && newMessage) {
      const messagesList = get(messagesAtomFamily(newMessage.receiver));

      const messageToUpdate = _.find(messagesList, (m) => newMessage.id === m.id);
      const messages = _.filter(messagesList, (m) => newMessage.id !== m.id);

      if (messages && messageToUpdate) {
        set(messagesAtomFamily(newMessage.receiver), [
          {
            ...messageToUpdate,
            status: newMessage.status,
          },
          ...messages,
        ]);
      }
    }
  },
});

export const removeExcessMessagesSelector = selector<ChatUserForFetch['sender'] | null>({
  key: 'removeExcessMessage',
  get: () => null,
  set: ({ set, get }, chatWindowId) => {
    if (!(chatWindowId instanceof DefaultValue) && chatWindowId) {
      const messages = get(messagesAtomFamily(chatWindowId));

      if (messages) set(messagesAtomFamily(chatWindowId), _.slice(messages, 0, MESSAGES_PART_SIZE));
    }
  },
});

export const changeAllVisibleWindowsVisibilitySelector = selector<boolean | null>({
  key: 'changeAllVisibleWindowsVisibility',
  get: () => null,
  set: ({ set, get }, value) => {
    const chatWindowIds = get(chatWindowIdsAtom);
    const changedChatWindowsIds = get(changedChatWindowIdsAtom);
    if (typeof value === 'boolean') {
      if (value) {
        changedChatWindowsIds.forEach((id) => {
          set(setStateChatWindowSelector(id), {
            type: 'isVisible',
            value,
          });
        });
      } else {
        const changedChatWindows = _.filter(chatWindowIds, (id) => {
          if (get(chatWindowAtomFamily(id)).isVisible) {
            set(setStateChatWindowSelector(id), {
              type: 'isVisible',
              value,
            });
            return true;
          }
          return false;
        });
        set(changedChatWindowIdsAtom, changedChatWindows);
      }

      set(mainWindowIsVisibleAtom, value);
    }
  },
});
