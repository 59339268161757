import React from 'react';
import { Flex, FlexProps, Text } from 'theme-ui';
import { useRecoilValue } from 'recoil';

import { filterGroupStateAtomFamily } from 'state/filters';
import { DateRangeFilters, FilterGroupNames } from 'layouts/AuthorizedApp/AsideFilters/types';
import { dateTime } from 'utils/dateTime';
import { useDateRangeFilter } from 'layouts/AuthorizedApp/AsideFilters/hooks/useDateRangeFilter';

type Props = Omit<FlexProps, 'variant'>;

export const DateRangeBadge = ({ sx, ...props }: Props): React.ReactElement | null => {
  const dateRangeType = useRecoilValue(filterGroupStateAtomFamily(FilterGroupNames.DATE_RANGE));
  const { dates } = useDateRangeFilter();

  if (!dateRangeType || !dates) return null;

  const { endDateUnix, startDateUnix } = dates;

  const generateTitle = () => {
    if (dateRangeType.includes(DateRangeFilters.MONTH)) {
      const date = dateTime(startDateUnix, { disableTimezone: true });
      return (
        <>
          <strong>{date.format('MMMM')} </strong>
          {date.format('YYYY')}
        </>
      );
    }

    const startDate = dateTime(startDateUnix, { disableTimezone: true });
    const year = startDate.format('YYYY');
    const formattedStartDate = startDate.format('ll').split(` ${year}`)[0].split(',')[0];

    return `${formattedStartDate} - ${dateTime(endDateUnix, {
      disableTimezone: true,
    }).format('ll')}`;
  };

  return (
    <Flex
      sx={{
        bg: 'alphas.bluish3',
        outline: '1px solid',
        outlineColor: 'alphas.bluish5',
        px: 2,
        borderRadius: 'xs',
        ...sx,
      }}
      {...props}
    >
      <Text sx={{ textTransform: 'capitalize', fontSize: 4, fontWeight: 'normal' }}>{generateTitle()}</Text>
    </Flex>
  );
};
