import { ThemeUIStyleObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

const BACKGROUNDS = {
  dropdown: 'dropdown.background',
  asideFilter: 'alphas.darker1',
};

const dropdownContainer: ThemeUIStyleObject = {
  bg: BACKGROUNDS.dropdown,
  borderRadius: 'sm',
  flexDirection: 'column',
  boxShadow: 'dropdown',
  py: '0.75rem',
  gap: 3,
  '> *': {
    px: '0.75rem',
  },
};

const dualCalendarContainer: ThemeUIStyleObject = {
  borderRadius: 'sm',
  flexDirection: 'column',
  gap: 3,
  '> *': {
    px: '0.75rem',
  },
};

const asideFilterContainer: ThemeUIStyleObject = {
  bg: BACKGROUNDS.asideFilter,
  borderRadius: 'xs',
  flexDirection: 'column',
  p: 1,
  gap: 1,
};

const controlsContainer: ThemeUIStyleObject = {
  alignItems: 'center',
  justifyContent: 'space-between',
  userSelect: 'none',
  gap: 2,
  '*': {
    userSelect: 'none',
  },
};

const arrowButton: ThemeUIStyleObject = {
  px: 1,
  '&[data-is-hidden="true"]': {
    pointerEvents: 'none',
    userSelect: 'none',
    opacity: 0,
  },
};

const controlsSelectInput: ThemeUIStyleObject = {
  bg: 'transparent',
  border: 'none',
  outline: 'none',
  'input[type="text"]': {
    fontWeight: 'bold',
    textAlign: 'center',
    '&::placeholder': {
      color: 'inherit !important',
    },
  },
};

const controlsSelectInputAside: ThemeUIStyleObject = {
  ...controlsSelectInput,
  borderRadius: 'xs',
  '> div': {
    display: 'none',
  },
  'input[type="text"]': {
    fontWeight: 'bold',
    textAlign: 'center',
    p: 1,

    '&::placeholder': {
      color: 'inherit !important',
    },
  },
};

const day: ThemeUIStyleObject = {
  height: '40px',
  width: '40px',
  my: '1px',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 2,
  userSelect: 'none',
  position: 'relative',
};

export const calendarStyles = makeTheme({
  forms: {
    calendar: {
      dropdown: {
        container: dropdownContainer,
        controls: {
          container: controlsContainer,
          arrowButton,
          select: controlsSelectInput,
        },
        day,
      },
      dualCalendar: {
        container: dualCalendarContainer,
        controls: {
          container: controlsContainer,
          arrowButton,
          select: controlsSelectInput,
        },
        day,
      },
      asideFilter: {
        container: asideFilterContainer,
        controls: {
          container: {
            ...controlsContainer,
            gap: 0,
          },
          arrowButton,
          select: controlsSelectInputAside,
        },
        day: {
          ...day,
          size: '29px',
          '> div': {
            size: '27px',
          },
        },
      },
    },
  },
});
