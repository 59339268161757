import { selector } from 'recoil';
import { t } from '@lingui/macro';

import { InputOption } from 'components/ui/Select/Select';
import { VisibilityLevels, LimitPeriods } from 'api/actions/organizationSession/organizationSessionActions.types';
import { ToleranceThresholds } from 'layouts/Locations/constants';

import { languageSelector } from './recoilState';
import { organizationSessionAtom } from './organizationSession';

export const visibilityLevelOptionsSelector = selector<InputOption[]>({
  key: 'visibilityLevelOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({ id: 'team.advanced.visibility.unlimited', message: 'Unlimited' }),
        id: `${VisibilityLevels.WithoutRestrictions}`,
      },
      { label: t({ id: 'team.advanced.visibility.1mo', message: '1 month' }), id: `${VisibilityLevels.OneMonth}` },
      { label: t({ id: 'team.advanced.visibility.2mo', message: '2 months' }), id: `${VisibilityLevels.TwoMonths}` },
      { label: t({ id: 'team.advanced.visibility.3mo', message: '3 months' }), id: `${VisibilityLevels.ThreeMonths}` },
      { label: t({ id: 'team.advanced.visibility.6mo', message: '6 months' }), id: `${VisibilityLevels.SixMonths}` },
      { label: t({ id: 'team.advanced.visibility.12mo', message: '12 months' }), id: `${VisibilityLevels.Year}` },
    ];
  },
});

export const periodSelectOptionsSelector = selector<InputOption[]>({
  key: 'periodSelectOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      { id: `${LimitPeriods.Weekly}`, label: t({ id: 'team.req_limits.week', message: 'Week' }) },
      { id: `${LimitPeriods.Monthly}`, label: t({ id: 'team.req_limits.month', message: 'Month' }) },
      { id: `${LimitPeriods.Annually}`, label: t({ id: 'team.req_limits.year', message: 'Year' }) },
    ];
  },
});

export const toleranceThresholdOptionsSelector = selector<InputOption[]>({
  key: 'toleranceThresholdOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    const values: number[] = Object.values(ToleranceThresholds)
      .map((key) => ToleranceThresholds[key])
      .filter((k) => !Number.isNaN(Number(k)));

    return values.map((threshold) => ({
      label: `${threshold} ${t({ id: 'locations.tolerance.meters', message: 'meters' })}`,
      id: `${threshold}`,
    }));
  },
});

export const timeEventTypesOptionsSelector = selector<InputOption[]>({
  key: 'timeEventTypesOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    const organizationSession = get(organizationSessionAtom);
    const timeEventTypesOptions = organizationSession?.timeEventTypes?.map((tET) => ({
      id: tET.id.toLocaleUpperCase(),
      label: tET.name,
    }));
    return timeEventTypesOptions;
  },
});
