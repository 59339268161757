import { t } from '@lingui/macro';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLingui } from '@lingui/react';

import { TimeTrackingForms } from 'layouts/Settings/Organization/forms/TimeTrackingForms';
import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { resetFormButtonAtom, resetFormButtonCallbacksSelector } from 'state/settings';

export const TimeTracking = (): React.ReactElement => {
  useLingui();
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);

  const handleResetButtonClick = () => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  };

  return (
    <>
      <HeadingTip
        label={t({
          id: 'time_tracking_settings.heading.time_tracking',
          message: 'Time tracking',
        })}
        displayReset={!!formResetCallbacks}
        displayOnClick={handleResetButtonClick}
      />
      <TimeTrackingForms />
    </>
  );
};
