import React from 'react';
import { useRecoilValue } from 'recoil';

import { PATH } from 'constants/routes';
import { ModalRoute } from 'routing/ModalRoute';
import { languageSelector } from 'state/recoilState';
import { AddHolidayModal } from 'layouts/Settings/Organization/modals/AddHolidayModal';
import { ImportHolidaysModal } from 'layouts/Settings/Organization/modals/ImportHolidaysModal';
import { DeleteHolidayModal } from 'layouts/Settings/Organization/modals/DeleteHolidayModal';
import { EditHolidayModal } from 'layouts/Settings/Organization/modals/EditHolidayModal';

export const OrganizationModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <ModalRoute size="sm" path={PATH.ADD_HOLIDAY_MODAL[language]}>
        <AddHolidayModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.EDIT_HOLIDAY_MODAL[language]}>
        <EditHolidayModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.IMPORT_HOLIDAYS_MODAL[language]}>
        <ImportHolidaysModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.DELETE_HOLIDAY_MODAL[language]}>
        <DeleteHolidayModal />
      </ModalRoute>
    </>
  );
};
