import { useRecoilValue } from 'recoil';

import { nameDisplayOrderSelector } from 'state/userSession';
import { NameDisplayOrder } from 'api/actions/userSession/userSessionActions.types';

type GetFullName = (firstName: string, surname: string) => string;

export const useNameDisplayOrder = (): GetFullName => {
  const { nameDisplayOrder } = useRecoilValue(nameDisplayOrderSelector);

  const getFullName: GetFullName = (firstName, surname) =>
    nameDisplayOrder === NameDisplayOrder.SurnameFirst ? `${surname} ${firstName}` : `${firstName} ${surname}`;

  return getFullName;
};
