import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { YearSelect } from 'components/recipes/YearSelect';
import { Button } from 'components/ui/Buttons';
import { ElementGroup } from 'components/ui/ElementGroup';
import { TextInput } from 'components/ui/TextInput';
import { FilterGroupNames, TeammatesFilters } from 'layouts/AuthorizedApp/AsideFilters/types';
import {
  requestOverviewIsActiveFilterSelectorFamily,
  requestOverviewLimitedRequestsFilterAtom,
  requestOverviewSearchFilterValueSelector,
  requestOverviewYearStateAtom,
} from 'state/requests';

const ButtonWithDropdown = withDropdown(Button);

type Props = { sx?: ThemeUIStyleObject };

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const RequestOverviewHeaderMenu = ({ sx }: Props): React.ReactElement => {
  useLingui();
  const [yearSelectState, setYearSelectState] = useRecoilState(requestOverviewYearStateAtom);
  const setSearchFilterValue = useSetRecoilState(requestOverviewSearchFilterValueSelector);

  const [isActiveHideNotLimited, setIsActiveHideNotLimited] = useRecoilState(requestOverviewLimitedRequestsFilterAtom);

  const [isActiveTeammatesHidden, setIsActiveTeammatesHidden] = useRecoilState(
    requestOverviewIsActiveFilterSelectorFamily({
      groupName: FilterGroupNames.TEAMMATES,
      filterId: TeammatesFilters.HIDDEN,
    }),
  );
  const [isActiveTeammatesDeactivated, setIsActiveTeammatesDeactivated] = useRecoilState(
    requestOverviewIsActiveFilterSelectorFamily({
      groupName: FilterGroupNames.TEAMMATES,
      filterId: TeammatesFilters.DEACTIVATED,
    }),
  );
  const [isActiveHideTimeOff, setIsActiveHideTimeOff] = useRecoilState(
    requestOverviewIsActiveFilterSelectorFamily({
      groupName: FilterGroupNames.REQUEST_TYPES,
      filterId: `${RequestFormType.TimeOff}`,
    }),
  );
  const [isActiveHideCustom, setIsHideActiveCustom] = useRecoilState(
    requestOverviewIsActiveFilterSelectorFamily({
      groupName: FilterGroupNames.REQUEST_TYPES,
      filterId: `${RequestFormType.Custom}`,
    }),
  );
  const [isActiveHideTimeTracking, setIsActiveHideTimeTracking] = useRecoilState(
    requestOverviewIsActiveFilterSelectorFamily({
      groupName: FilterGroupNames.REQUEST_TYPES,
      filterId: `${RequestFormType.TimeTracking}`,
    }),
  );
  const [isActiveHideBusinessTrip, setIsActiveHideBusinessTrip] = useRecoilState(
    requestOverviewIsActiveFilterSelectorFamily({
      groupName: FilterGroupNames.REQUEST_TYPES,
      filterId: `${RequestFormType.BusinessTrip}`,
    }),
  );

  const saveInputValueToRecoil = _.debounce((value: string) => {
    setSearchFilterValue(value);
  }, 400);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    saveInputValueToRecoil(value);
  };

  const handleYearSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setYearSelectState(value);
  };

  return (
    <Flex sx={{ alignItems: 'center', height: '30px', ...(sx && sx) }}>
      <YearSelect
        sx={{ height: '30px' }}
        defaultValue={yearSelectState}
        size="sm"
        id="year"
        onChange={handleYearSelectChange}
      />

      <ElementGroup showAsList marginValue={1} wrapperSx={{ ml: 'auto' }}>
        <ButtonWithDropdown
          dropdownProps={{
            links: [
              {
                label: isActiveTeammatesHidden
                  ? t({ id: 'requests.hide_teammates', message: 'Hide hidden teammates' })
                  : t({ id: 'requests.show_teammates', message: 'Show hidden teammates' }),
                onClick: () => setIsActiveTeammatesHidden(!isActiveTeammatesHidden),
              },
              {
                label: isActiveTeammatesDeactivated
                  ? t({ id: 'requests.hide_deactivated_teammates', message: 'Hide deactivated teammates' })
                  : t({ id: 'requests.show_deactivated_teammates', message: 'Show deactivated teammates' }),
                onClick: () => setIsActiveTeammatesDeactivated(!isActiveTeammatesDeactivated),
              },
              {
                label: isActiveHideTimeOff
                  ? t({ id: 'requests.show_time_offs', message: 'Show time offs' })
                  : t({ id: 'requests.hide_time_offs', message: 'Hide time offs' }),
                prependWithDivider: true,
                onClick: () => setIsActiveHideTimeOff(!isActiveHideTimeOff),
              },
              {
                label: isActiveHideCustom
                  ? t({ id: 'requests.show_customs', message: 'Show customs' })
                  : t({ id: 'requests.hide_customs', message: 'Hide customs' }),
                onClick: () => setIsHideActiveCustom(!isActiveHideCustom),
              },
              {
                label: isActiveHideTimeTracking
                  ? t({ id: 'requests.show_time_tracking', message: 'Show time tracking' })
                  : t({ id: 'requests.hide_time_tracking', message: 'Hide time tracking' }),
                onClick: () => setIsActiveHideTimeTracking(!isActiveHideTimeTracking),
              },
              {
                label: isActiveHideBusinessTrip
                  ? t({ id: 'requests.show_business_trip', message: 'Show business trip' })
                  : t({ id: 'requests.hide_business_trip', message: 'Hide business trip' }),
                onClick: () => setIsActiveHideBusinessTrip(!isActiveHideBusinessTrip),
              },
              {
                label: isActiveHideNotLimited
                  ? t({ id: 'requests.show_not_limited', message: 'Show all not limited' })
                  : t({ id: 'requests.hide_not_limited', message: 'Hide all not limited' }),
                prependWithDivider: true,
                onClick: () => setIsActiveHideNotLimited(!isActiveHideNotLimited),
              },
            ],
          }}
          shape="rounded"
          size="sm"
          variant="grey"
          prependWith={<Icon type="filter" />}
        >
          <Trans id="filters" />
        </ButtonWithDropdown>
        <TextInput
          onChange={handleSearchInputChange}
          size="xs"
          id="searchInput"
          placeholder={t({ id: 'aside_filters.search' })}
          icon="search"
          clearable
          sx={{ height: '30px' }}
        />
      </ElementGroup>
    </Flex>
  );
};

RequestOverviewHeaderMenu.defaultProps = defaultProps;
