import { Trans, t } from '@lingui/macro';
import React, { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useClient } from 'react-fetching-library';
import key from 'weak-key';
import { i18n } from '@lingui/core';
import { Flex } from 'theme-ui';

import { Button } from 'components/ui/Buttons';
import { parsedTimeTrackerSelector, timeTrackerAtom } from 'state/drawer';
import { fetchTimeTrackerAction } from 'api/actions/drawer/drawerActions';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { Drawer } from '../Drawer';

import { TimeEventTimer } from './TimeEventTimer';

export const TimeTracker = (): React.ReactElement => {
  const shouldFetchTimeTracker = useRef<boolean>(true);

  const setTimeTracker = useSetRecoilState(timeTrackerAtom);
  const parsedTimeTracker = useRecoilValue(parsedTimeTrackerSelector);

  const { query } = useClient();

  useEffect(() => {
    const fetchTimeTracker = async () => {
      const { error, payload } = await query(fetchTimeTrackerAction());
      if (!error && payload) {
        setTimeTracker(payload);
      }
    };
    if (shouldFetchTimeTracker.current) {
      shouldFetchTimeTracker.current = false;
      fetchTimeTracker();
    }
  }, [query, setTimeTracker]);

  if (!parsedTimeTracker) {
    return (
      <LoadingSpinner
        size={3}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      />
    );
  }

  const { workTime, recentTimeEventsList, availableTimeEvents, postTimeEvent } = parsedTimeTracker;

  return (
    <>
      <Drawer.Header>
        <Drawer.Title>
          <Trans id="drawer.header.time_tracker">Select what you want to do</Trans>
        </Drawer.Title>
      </Drawer.Header>

      <Drawer.Container sx={{ gap: 4 }}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <TimeEventTimer withTimer {...workTime} />

          {recentTimeEventsList.map((timeEvent) => (
            <TimeEventTimer key={key(timeEvent)} {...timeEvent} />
          ))}
        </Flex>

        {availableTimeEvents.length > 0 && (
          <>
            <Flex sx={{ flexDirection: 'column', gap: 2 }}>
              <Drawer.SubTitle>
                <Trans id="drawer.header.time_tracker.other_status">Start other status</Trans>
              </Drawer.SubTitle>

              <Flex sx={{ flexDirection: 'column', gap: '0.125rem' }}>
                {availableTimeEvents.map((tE, i, a) => (
                  <Button
                    key={tE.id}
                    sx={{
                      minHeight: '2.625rem',
                      ...(i === 0 && {
                        borderTopLeftRadius: 'default',
                        borderTopRightRadius: 'default',
                      }),
                      ...(i === a.length - 1 && {
                        borderBottomLeftRadius: 'default',
                        borderBottomRightRadius: 'default',
                      }),
                    }}
                    variant="lightGrey"
                    shape="square"
                    fullWidth
                    onClick={() =>
                      postTimeEvent({
                        timeEventTypeId: tE.id,
                        isEnd: false,
                      })
                    }
                  >
                    {i18n._(
                      t({
                        id: tE.name,
                      }),
                    )}
                  </Button>
                ))}
              </Flex>
            </Flex>
          </>
        )}
      </Drawer.Container>
    </>
  );
};
