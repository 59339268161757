import React, { useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { Action, useMutation } from 'react-fetching-library';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Checkbox } from 'components/ui/Checkbox';
import { Modal } from 'components/Modal/Modal';
import { delay } from 'utils/delay';
import { useModal } from 'hooks/useModal/useModal';
import { BasicModalFooter, BasicModalFooterProps } from 'components/recipes/BasicModalFooter';
import { useSelectedIdsModalLogic } from 'layouts/Team/hooks/useSelectedIdsModalLogic';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

type UnknownObject = {
  [key: string]: unknown;
};

type ActionProps = string[];

type Props = {
  list: { id: string }[] | Map<string, unknown> | null;
  action: (body: ActionProps) => Action<UnknownObject>;
  titleRenderer: (idsCount: number, name: string) => string | React.ReactElement;
  contentRenderer: (
    idsCount: number,
    selectedNames: string[],
    selectedIds: string[],
    baseRoute: string,
  ) => React.ReactElement;
  withConfirmation?: boolean;
  variant?: 'DELETE' | 'DEACTIVATE' | 'INVITE';
  onResponseCallback?: (error: boolean) => void;
  namePath?: string | [string, string];
  nameParser?: (id: string, index: number) => string;
};

const defaultProps: Omit<Partial<Props>, 'variant'> & Required<Pick<Props, 'variant'>> = {
  variant: 'DELETE',
  withConfirmation: undefined,
  onResponseCallback: undefined,
  namePath: undefined,
  nameParser: undefined,
};

export const DeleteDeactivateModal = ({
  list,
  action,
  titleRenderer,
  contentRenderer,
  withConfirmation,
  variant = defaultProps.variant,
  namePath,
  nameParser,
  onResponseCallback,
}: Props): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { baseRoute, handleClose } = useModal();
  const { selectedIds, selectedNames, idsCount, loading, setLoading, confirmed, setConfirmed } =
    useSelectedIdsModalLogic(list, namePath, nameParser);

  const { mutate } = useMutation(action);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
  };

  const handleClick = useCallback(async () => {
    setLoading(true);

    const { error: submitError } = await mutate(selectedIds);

    if (onResponseCallback) onResponseCallback(submitError);

    if (!submitError) {
      if (handleClose) {
        handleClose();
      }
    }
    setLoading(false);
    if (!submitError) {
      await delay(100);
      addSnackbar({
        message: (() => {
          switch (variant) {
            case 'DELETE':
              return t({ id: 'team.delete_deactivate.deleted', message: 'Successfully deleted!' });
            case 'DEACTIVATE':
              return t({ id: 'team.delete_deactivate.deactivated', message: 'Successfully deactivated!' });
            default:
              return '';
          }
        })(),
        variant: 'success',
      });
    }
  }, [setLoading, mutate, selectedIds, onResponseCallback, handleClose, addSnackbar, variant]);

  const buttons: BasicModalFooterProps['buttons'] = useMemo(
    () => [
      {
        ...(variant === 'DELETE' ? { disabled: !confirmed } : {}),
        isLoading: loading,
        onClick: handleClick,
        variant: (() => {
          switch (variant) {
            case 'DELETE':
              return 'danger';
            case 'DEACTIVATE':
              return 'warning';
            default:
              return 'primary';
          }
        })(),
        children: (() => {
          switch (variant) {
            case 'DELETE':
              return t({ id: 'team.delete_deactivate.delete', message: 'Delete' });
            case 'DEACTIVATE':
              return t({ id: 'team.delete_deactivate.deactivate', message: 'Deactivate' });
            default:
              return '';
          }
        })(),
      },
    ],
    [loading, variant, confirmed, handleClick],
  );

  if (!selectedIds.length) {
    return <Redirect to={baseRoute} />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{titleRenderer(idsCount, selectedNames[0])}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        sx={{
          '>span': {
            display: 'block',
            '+span': {
              mt: 4,
            },
          },
        }}
      >
        {contentRenderer(idsCount, selectedNames, selectedIds, baseRoute)}

        {!!withConfirmation && (
          <Checkbox
            onChange={handleCheckboxChange}
            checked={confirmed}
            name="confirm"
            label={t({ id: 'team.delete_deactivate.confirm', message: "I understand, let's proceed." })}
            sx={{ mt: 4 }}
          />
        )}
      </Modal.Body>
      <BasicModalFooter buttons={buttons} />
    </>
  );
};

DeleteDeactivateModal.defaultProps = defaultProps;
