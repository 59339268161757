import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { useHistory } from 'react-router';

import { InvitationJoinRequestPayload } from 'api/actions/auth/authActions.types';
import { TextInput } from 'components/ui/TextInput';
import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { FormCard } from 'layouts/Authentication';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

type Props = {
  onSubmit: (props: InvitationJoinRequestPayload) => Promise<boolean>;
};

const NOT_INVITED_FORM_ID = 'employee-not-invited-form-id';

export const EmployeeNotInvitedForm = ({ onSubmit }: Props): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange' });

  const { go } = useHistory();

  const handleSubmitCallback = useCallback(
    async (props) => {
      await onSubmit(props);
    },
    [onSubmit],
  );

  const handleBackOnClick = () => {
    go(-1);
  };

  return (
    <>
      <form id={NOT_INVITED_FORM_ID} onSubmit={handleSubmit(handleSubmitCallback)} noValidate>
        <ElementGroup marginValue="4" direction="column">
          <TextInput
            id="email"
            autoComplete="off"
            label={i18n._(
              t({
                id: 'sing_up.employee.not_invited.form.email',
                message: "Administrator's email address",
              }),
            )}
            placeholder={i18n._(
              t({
                id: 'global.forms.inputs.email_example',
              }),
            )}
            type="email"
            error={!!errors.email}
            errorMessage={errors?.email?.message}
            clearable
            {...register('email', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
          />

          <TextInput
            id="userName"
            autoComplete="name"
            label={i18n._(
              t({
                id: 'sing_up.employee.not_invited.form.user_name',
                message: 'Your full name',
              }),
            )}
            placeholder={i18n._(
              t({
                id: 'global.forms.inputs.name_example',
                message: 'John Late',
              }),
            )}
            type="text"
            error={!!errors.userName}
            errorMessage={errors?.userName?.message}
            clearable
            {...register('userName', validationFactory({ required: true }))}
          />

          <TextInput
            id="note"
            label={i18n._('sing_up.employee.form.note')}
            type="text"
            error={!!errors.note}
            errorMessage={errors?.note?.message}
            clearable
            {...register('note')}
          />
        </ElementGroup>
      </form>

      <FormCard.Footer>
        <Button variant="minimal" shape="rounded" size="lg" type="button" onClick={handleBackOnClick}>
          <Trans id="global.forms.buttons.back" />
        </Button>

        <Button variant="primary" shape="rounded" size="lg" type="submit" form={NOT_INVITED_FORM_ID}>
          <Trans id="sing_up.employee.not_invited.form.submit_button">Send an invite request</Trans>
        </Button>
      </FormCard.Footer>
    </>
  );
};
