import React from 'react';
import { Route, Switch } from 'react-router';

import { DRAWER_ROUTES } from 'constants/memoryRoutes';

import { TimeTracker } from './TimeTracker/TimeTracker';
import { NotificationCenter } from './NotificationCenter/NotificationCenter';
import { AttendanceOverview } from './AttendanceOverview/AttendanceOverview';

export const DrawerRoutes = (): React.ReactElement => (
  <Switch>
    <Route path={DRAWER_ROUTES.DRAWER__ATTENDANCE_OVERVIEW} component={AttendanceOverview} />
    <Route path={DRAWER_ROUTES.DRAWER__TIME_TRACKER} component={TimeTracker} />
    <Route path={DRAWER_ROUTES.DRAWER__NOTIFICATION_CENTER} component={NotificationCenter} />
  </Switch>
);
