import { ThemeUIStyleObject } from 'theme-ui';

import { GRID_DEFAULT_HEIGHT, GUTTER_DEFAULT_SIZE, ITEM_DEFAULT_HEIGHT } from 'components/StickyList/constants';
import { makeTheme } from '../makeTheme';
import { hexToRgba } from 'utils/colors';

import { BASE_COLORS } from './colors';

const BACKGROUNDS = {
  header: {
    default: {
      list: `linear-gradient(180deg, ${BASE_COLORS.backgrounds[1]} 0%, ${BASE_COLORS.backgrounds[1]} 85%, ${hexToRgba(
        BASE_COLORS.backgrounds[1],
        0,
      )} 100%)`,
      grid: 'backgrounds.levelThree',
    },
    inverted: {
      list: `linear-gradient(180deg, ${BASE_COLORS.backgrounds[0]} 0%, ${BASE_COLORS.backgrounds[0]} 85%, ${hexToRgba(
        BASE_COLORS.backgrounds[0],
        0,
      )} 100%)`,
      grid: 'backgrounds.levelFour',
    },
  },
};

const headerSx: ThemeUIStyleObject = {
  position: 'sticky',
  top: 0,
  left: 0,
  maxHeight: ITEM_DEFAULT_HEIGHT,
  flexDirection: 'row',
  alignItems: 'stretch',
  flex: '1 0',
  gap: 2,
  px: '0.75rem',
  fontSize: 0,
  textTransform: 'uppercase',
  userSelect: 'none',
  zIndex: 40,
  willChange: 'transform',
  color: 'texts.default',
  background: BACKGROUNDS.header.default.list,
};

const gridHeaderSx: ThemeUIStyleObject = {
  ...headerSx,
  background: {},
  bg: BACKGROUNDS.header.default.grid,
  gap: 0,
  px: 0,
  pt: GRID_DEFAULT_HEIGHT - ITEM_DEFAULT_HEIGHT,
};

const rowSx: ThemeUIStyleObject = {
  willChange: 'transform',
  flex: '1 0',
  gap: 2,
  alignItems: 'center',
  width: '100%',
  px: '0.75rem',
  bg: 'list.rowBackground.default',
  color: 'texts.default',
  fontSize: 2,
  zIndex: 10,
  outline: '1px solid',
  outlineColor: 'transparent',
  '&:hover': {
    outlineColor: 'alphas.bluish5',
    bg: 'list.rowBackground.hover',
    boxShadow: 'dropShadow.levelOne',
    zIndex: 20,
  },
  '&[data-dropdown="true"]': {
    zIndex: 30,
  },
  '&[data-first="true"]': {
    borderTopLeftRadius: 'sm',
    borderTopRightRadius: 'sm',
  },
  '&[data-last="true"]': {
    borderBottomLeftRadius: 'sm',
    borderBottomRightRadius: 'sm',
  },
  '&[data-selected="true"]': {
    bg: 'list.rowBackground.selected',
    outlineColor: 'alphas.bluish5',
    '&:hover': {
      bg: 'list.rowBackground.selectedHover',
    },
  },
  '&[data-non-editable="true"]': {
    bg: 'list.rowBackground.nonEditable',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  '&[data-non-checkable="true"]': {
    bg: 'list.rowBackground.nonEditable',
    '&:hover': {
      outlineColor: 'alphas.darker5',
      bg: 'list.rowBackground.nonEditableHover',
    },
  },
  '> .checkbox-wrapper': {
    flexShrink: 0,
    mr: '1.3rem',
  },
};

const gridCellSx: ThemeUIStyleObject = {
  willChange: 'transform',
  flex: '1 0',
  alignItems: 'center',
  width: '100%',
  px: '0.75rem',
  bg: 'list.rowBackground.default',
  color: 'texts.default',
  fontSize: 2,
  zIndex: 10,
  outline: '1px solid',
  outlineColor: 'transparent',
  '&:hover': {
    outlineColor: 'alphas.bluish5',
    bg: 'list.rowBackground.hover',
    boxShadow: 'dropShadow.levelOne',
    zIndex: 20,
    // TODO: Add canvas to create the highlight for rows/column hover effect
  },
  '&[data-selected="true"]': {
    outlineColor: 'alphas.bluish5',
    bg: 'list.rowBackground.selected',
    '&:hover': {
      bg: 'list.rowBackground.selectedHover',
    },
  },
  '&[data-dropdown="true"]': {
    zIndex: 30,
  },
  // This doesn't work well
  //
  // '&[data-br*="tl"]': {
  //   borderTopLeftRadius: 'sm',
  // },
  // '&[data-br*="tr"]': {
  //   borderTopRightRadius: 'sm',
  // },
  // '&[data-br*="br"]': {
  //   borderBottomRightRadius: 'sm',
  // },
  // '&[data-br*="bl"]': {
  //   borderBottomLeftRadius: 'sm',
  // },
};

const gridCellAfterSx: ThemeUIStyleObject = {
  content: '""',
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -2,
  outline: `${GUTTER_DEFAULT_SIZE / 2}px solid`,
  borderRadius: 'inherit',
};

export const stickyListStyles = makeTheme({
  stickyList: {
    header: {
      default: {
        list: {
          ...headerSx,
          boxShadow: `4px -2px 0 -2px ${BASE_COLORS.backgrounds[1]}, -4px -2px 0 -2px ${BASE_COLORS.backgrounds[1]}`,
        },
        grid: {
          ...gridHeaderSx,
        },
      },
      inverted: {
        list: {
          ...headerSx,
          background: BACKGROUNDS.header.inverted.list,
          boxShadow: `4px -2px 0 -2px ${BASE_COLORS.backgrounds[0]}, -4px -2px 0 -2px ${BASE_COLORS.backgrounds[0]}`,
        },
        grid: {
          ...gridHeaderSx,
          bg: BACKGROUNDS.header.inverted.grid,
        },
      },
    },
    row: {
      default: {
        ...rowSx,
      },
      inverted: {
        ...rowSx,
        bg: 'list.rowBackground.inverted',
      },
    },
    gridCell: {
      default: {
        ...gridCellSx,
        '&::after': {
          ...gridCellAfterSx,
          outlineColor: 'backgrounds.authorizedApp',
        },
      },
      inverted: {
        ...gridCellSx,
        bg: 'list.rowBackground.inverted',
        '&::after': {
          ...gridCellAfterSx,
          outlineColor: 'backgrounds.levelZero',
        },
      },
    },
  },
});
