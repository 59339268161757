import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';

export const useGoogleSignIn = (
  id: string | undefined,
  success?: (res: GoogleLoginResponse | GoogleLoginResponseOffline) => void,
  failure?: (res: unknown) => void,
): (() => void) => {
  const clientId = id || '';

  const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (success) {
      success(res);
    }
  };

  const onFailure = (res: unknown) => {
    if (failure) {
      failure(res);
    }
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
  });

  return signIn;
};
