import { ThemeUIStyleObject } from 'theme-ui';

import { ThemeDefault } from 'styles/types';

import { ButtonSharedProps } from './types';

interface SetOutlineSxGenerator {
  ({ radii, textColor }: { textColor: string; radii: number | string }): ThemeUIStyleObject;
}

export const setOutlineSx: SetOutlineSxGenerator = ({ textColor, radii }) => ({
  position: 'relative',
  color: textColor,
  '> span': {
    zIndex: 1,
  },
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: '2px',
    bg: 'background',
    borderRadius: radii,
    transition: 'opacity 0.2s ease-in-out',
  },
  '&:hover&::after': {
    opacity: 0.93,
  },
  '&:disabled&::after, &[disabled]&::after': {
    opacity: 0.5,
  },
});

interface SetShapeGenerator {
  ({
    shape,
    size,
    theme,
  }: {
    shape: NonNullable<ButtonSharedProps['shape']>;
    size: NonNullable<ButtonSharedProps['size']>;
    theme: ThemeDefault;
  }): ThemeUIStyleObject;
}

export const setShape: SetShapeGenerator = ({ shape, size, theme }) => {
  if (shape === ('circle' || 'square')) {
    return {
      ...theme.buttons.shapes[shape],
      ...theme.buttons.shapeSizes[size],
    };
  }

  if (shape === 'rounded') {
    return {
      ...theme.buttons.shapeRoundedSizes[size],
    };
  }

  return theme.buttons.shapes[shape];
};

interface SetBgOverwriteSxGenerator {
  ({ bgOverwrite }: { bgOverwrite: NonNullable<ButtonSharedProps['bgOverwrite']> }): {
    [K: string]:
      | string
      | {
          background: string;
        };
  };
}

export const setBgOverwriteSx: SetBgOverwriteSxGenerator = ({ bgOverwrite }) => ({
  background: bgOverwrite.default,
  '&:hover, &:focus': {
    background: bgOverwrite.hover,
  },
  '&:active': {
    background: bgOverwrite.tap,
  },
  '&:disabled, &[disabled]': {
    background: bgOverwrite.disabled,
  },
});

interface SetAnimationsGenerator {
  ({
    bgOverwrite,
    variant,
    theme,
  }: {
    bgOverwrite: ButtonSharedProps['bgOverwrite'];
    variant: NonNullable<ButtonSharedProps['variant']>;
    theme: ThemeDefault;
  }): {
    [key in 'initial' | 'tap' | 'hover']: {
      [keey in 'backgroundColor' | 'backgroundImage']?: string;
    };
  };
}

export const setAnimations: SetAnimationsGenerator = ({ bgOverwrite, variant, theme }) => {
  const isBgOverwriteSx = bgOverwrite && setBgOverwriteSx({ bgOverwrite });

  const bg = bgOverwrite ? isBgOverwriteSx?.background : theme.buttons[variant].background;
  const bgColors = bgOverwrite ? (isBgOverwriteSx as typeof theme.buttons.default) : theme.buttons[variant];

  if (String(bg).includes('linear-gradient')) {
    return {
      initial: { backgroundImage: bgColors.background },
      tap: { backgroundImage: bgColors['&:active'].background },
      hover: { backgroundImage: bgColors['&:hover, &:focus'].background },
    };
  }

  return {
    initial: { backgroundColor: bgColors.background },
    tap: { backgroundColor: bgColors['&:active'].background },
    hover: { backgroundColor: bgColors['&:hover, &:focus'].background },
  };
};
