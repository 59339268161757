import { ThemeUIStyleObject } from 'theme-ui';

import { hexToRgba } from 'utils/colors';
import { makeTheme } from '../makeTheme';

import { BASE_COLORS, colors as fullBaseColors } from './colors';

const { texts } = fullBaseColors.colors;

const commonButtonStyles: ThemeUIStyleObject = {
  display: 'inline-flex',
  minWidth: 'auto',
  appearance: 'none',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'nowrap',
  textAlign: 'center',
  fontFamily: 'inherit',
  lineHeight: 'inherit',
  textDecoration: 'none',
  fontSize: 'inherit',
  border: 0,
  outline: 'none',
  fontWeight: 'bold',
  transition: 'background 0.2s ease-in-out',
  '&:disabled, &[disabled]': {
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
};

const commonButtonTextStyles = {
  default: texts.default,
  disabled: texts.default,
  reversed: texts.reversed,
};

const reversedButtonTextStyles = {
  default: texts.reversed,
  disabled: texts.reversed,
  reversed: texts.default,
};

const commonButtonDisabledStyles: ThemeUIStyleObject = {
  pointerEvents: 'none',
  opacity: 0.2,
  cursor: 'not-allowed',
};

const buttonColors = {
  primary: {
    background: {
      default: `linear-gradient(270deg, ${BASE_COLORS.skyBlues[4]} 0%, ${BASE_COLORS.skyBlues[5]} 100%)`,
      hover: `linear-gradient(270deg, ${BASE_COLORS.skyBlues[3]} 0%, ${BASE_COLORS.skyBlues[4]} 100%)`,
      pressed: `linear-gradient(270deg, ${BASE_COLORS.skyBlues[5]} 0%, ${BASE_COLORS.skyBlues[6]} 100%)`,
    },
    text: {
      ...reversedButtonTextStyles,
    },
  },
  success: {
    background: {
      default: `linear-gradient(270deg, ${BASE_COLORS.greens[4]} 0%, ${BASE_COLORS.greens[5]} 100%)`,
      hover: `linear-gradient(270deg, ${BASE_COLORS.greens[3]} 0%, ${BASE_COLORS.greens[4]} 100%)`,
      pressed: `linear-gradient(270deg, ${BASE_COLORS.greens[5]} 0%, ${BASE_COLORS.greens[6]} 100%)`,
    },
    text: {
      ...reversedButtonTextStyles,
    },
  },
  warning: {
    background: {
      default: `linear-gradient(270deg, ${BASE_COLORS.yellows[4]} 0%, ${BASE_COLORS.yellows[5]} 100%)`,
      hover: `linear-gradient(270deg, ${BASE_COLORS.yellows[3]} 0%, ${BASE_COLORS.yellows[4]} 100%)`,
      pressed: `linear-gradient(270deg, ${BASE_COLORS.yellows[5]} 0%, ${BASE_COLORS.yellows[6]} 100%)`,
    },
    text: {
      ...reversedButtonTextStyles,
    },
  },
  danger: {
    background: {
      default: `linear-gradient(270deg, ${BASE_COLORS.reds[4]} 0%, ${BASE_COLORS.reds[5]} 100%)`,
      hover: `linear-gradient(270deg, ${BASE_COLORS.reds[3]} 0%, ${BASE_COLORS.reds[4]} 100%)`,
      pressed: `linear-gradient(270deg, ${BASE_COLORS.reds[5]} 0%, ${BASE_COLORS.reds[6]} 100%)`,
    },
    text: {
      ...reversedButtonTextStyles,
    },
  },
  default: {
    background: {
      default: BASE_COLORS.grayBlues[2],
      hover: BASE_COLORS.grayBlues[1],
      pressed: BASE_COLORS.grayBlues[3],
    },
    text: {
      ...commonButtonTextStyles,
      reversed: texts.default,
    },
  },
  minimal: {
    background: {
      default: hexToRgba(BASE_COLORS.whites[0], 0),
      hover: BASE_COLORS.whites[4],
      pressed: BASE_COLORS.grayBlues[2],
    },
    text: {
      ...commonButtonTextStyles,
      reversed: texts.default,
    },
  },
  lightGrey: {
    background: {
      default: BASE_COLORS.backgrounds[2],
      hover: BASE_COLORS.whites[5],
      pressed: BASE_COLORS.whites[6],
    },
    text: {
      ...commonButtonTextStyles,
      reversed: texts.default,
    },
  },
  grey: {
    background: {
      default: BASE_COLORS.whites[6],
      hover: BASE_COLORS.whites[5],
      pressed: BASE_COLORS.whites[7],
    },
    text: {
      ...commonButtonTextStyles,
      reversed: texts.default,
    },
  },
  darkGrey: {
    background: {
      default: BASE_COLORS.grayBlues[4],
      hover: BASE_COLORS.grayBlues[3],
      pressed: BASE_COLORS.grayBlues[5],
    },
    text: {
      ...reversedButtonTextStyles,
    },
  },
  naked: {
    text: {
      ...commonButtonTextStyles,
      reversed: texts.default,
    },
  },
};

const radioButtonColors = {
  default: {
    background: {
      default: BASE_COLORS.whites[6],
      hover: BASE_COLORS.whites[5],
    },
    text: {
      ...commonButtonTextStyles,
    },
  },
  active: {
    background: {
      default: BASE_COLORS.skyBlues[5],
    },
    text: {
      ...reversedButtonTextStyles,
    },
  },
};

export const buttons = makeTheme({
  colors: {
    buttons: {
      ...buttonColors,
    },
  },
  buttons: {
    sizes: {
      lg: {
        py: 3,
        px: 4,
        fontSize: 3,
      },
      default: {
        py: 2,
        px: 3,
        fontSize: 2,
      },
      sm: {
        py: 1,
        px: 2,
        fontSize: 2,
      },
      xs: {
        py: 1,
        px: 1,
        fontSize: 1,
      },
    },
    shapes: {
      flat: {
        borderRadius: 0,
      },
      rounded: {
        borderRadius: 'default',
      },
      roundedTop: {
        borderRadius: 'default',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      roundedBottom: {
        borderRadius: 'default',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      pill: {
        borderRadius: 'pill',
      },
      square: {
        borderRadius: 0,
      },
      circle: {
        borderRadius: '50%',
      },
    },
    shapeSizes: {
      default: {
        width: '40px',
        height: '40px',
      },
      lg: {
        width: '56px',
        height: '56px',
      },
      sm: {
        width: '32px',
        height: '32px',
      },
      xs: {
        width: '26px',
        height: '26px',
      },
    },
    shapeRoundedSizes: {
      default: {
        borderRadius: 'sm',
      },
      lg: {
        borderRadius: 'default',
      },
      sm: {
        borderRadius: 'xs',
      },
      xs: {
        borderRadius: 'xs',
      },
    },
    primary: {
      ...commonButtonStyles,
      color: 'buttons.primary.text.default',
      background: buttonColors.primary.background.default,
      '&:hover, &:focus': {
        background: buttonColors.primary.background.hover,
      },
      '&:active': {
        background: buttonColors.primary.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.primary.background.default} !important`,
      },
    },
    default: {
      ...commonButtonStyles,
      color: 'buttons.default.text.default',
      background: buttonColors.default.background.default,
      '&:hover, &:focus': {
        background: buttonColors.default.background.hover,
      },
      '&:active': {
        background: buttonColors.default.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.default.background.default} !important`,
      },
    },
    minimal: {
      ...commonButtonStyles,
      color: 'buttons.minimal.text.default',
      background: buttonColors.minimal.background.default,
      '&:hover, &:focus': {
        background: buttonColors.minimal.background.hover,
      },
      '&:active': {
        background: buttonColors.minimal.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.minimal.background.default} !important`,
      },
    },
    naked: {
      ...commonButtonStyles,
      color: 'buttons.naked.text.default',
      background: 'rgba(0,0,0,0)',
      '&:hover, &:focus': {
        background: 'rgba(0,0,0,0)',
      },
      '&:active': {
        background: 'rgba(0,0,0,0)',
      },
      '&:disabled, &[disabled]': {
        opacity: 0.2,
      },
    },
    lightGrey: {
      ...commonButtonStyles,
      color: 'buttons.lightGrey.text.default',
      background: buttonColors.lightGrey.background.default,
      '&:hover, &:focus': {
        background: buttonColors.lightGrey.background.hover,
      },
      '&:active': {
        background: buttonColors.lightGrey.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.lightGrey.background.default} !important`,
      },
    },
    grey: {
      ...commonButtonStyles,
      color: 'buttons.grey.text.default',
      background: buttonColors.grey.background.default,
      '&:hover, &:focus': {
        background: buttonColors.grey.background.hover,
      },
      '&:active': {
        background: buttonColors.grey.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.grey.background.default} !important`,
      },
    },
    darkGrey: {
      ...commonButtonStyles,
      color: 'buttons.darkGrey.text.default',
      background: buttonColors.darkGrey.background.default,
      '&:hover, &:focus': {
        background: buttonColors.darkGrey.background.hover,
      },
      '&:active': {
        background: buttonColors.darkGrey.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.darkGrey.background.default} !important`,
      },
    },
    success: {
      ...commonButtonStyles,
      color: 'buttons.primary.text.default',
      background: buttonColors.success.background.default,
      '&:hover, &:focus': {
        background: buttonColors.success.background.hover,
      },
      '&:active': {
        background: buttonColors.success.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.success.background.default} !important`,
      },
    },
    warning: {
      ...commonButtonStyles,
      color: 'buttons.primary.text.default',
      background: buttonColors.warning.background.default,
      '&:hover, &:focus': {
        background: buttonColors.warning.background.hover,
      },
      '&:active': {
        background: buttonColors.warning.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.warning.background.default} !important`,
      },
    },
    danger: {
      ...commonButtonStyles,
      color: 'buttons.primary.text.default',
      background: buttonColors.danger.background.default,
      '&:hover, &:focus': {
        background: buttonColors.danger.background.hover,
      },
      '&:active': {
        background: buttonColors.danger.background.pressed,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${buttonColors.danger.background.default} !important`,
      },
    },
    radioDefault: {
      ...commonButtonStyles,
      color: radioButtonColors.default.text.default,
      background: radioButtonColors.default.background.default,
      textAlign: 'unset',
      alignItems: 'unset',
      justifyContent: 'unset',
      '&:hover, &:focus': {
        background: radioButtonColors.default.background.hover,
      },
      '&:disabled, &[disabled]': {
        ...commonButtonDisabledStyles,
        background: `${radioButtonColors.default.background.default} !important`,
      },
      'input:checked + &': {
        color: radioButtonColors.active.text.default,
        background: radioButtonColors.active.background.default,
      },
    },
  },
});
