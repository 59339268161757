import { useEffect } from 'react';

export const useKeyboardEvent = (key: string, callback: () => void, hold?: true | false | undefined): void => {
  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (event.key === key) {
        callback();
      }
    };
    if (hold !== true) {
      window.addEventListener('keydown', handler);
    }
    return () => {
      if (hold !== true) {
        window.removeEventListener('keydown', handler);
      }
    };
  }, [key, callback, hold]);
};
