import React, { useMemo, useRef } from 'react';
import { Control, useFieldArray, UseFormRegister, useFormState, useWatch } from 'react-hook-form';
import { Flex, FlexProps, Heading } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useRecoilValue } from 'recoil';
import { v1 as uuidv1 } from 'uuid';

import { EmployeeEmploymentDetailsFieldNames, EmployeeEmploymentDetailsFormState } from 'state/team';
import { ButtonPair } from 'components/recipes/ButtonPair';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { DatePicker } from 'components/ui/DatePicker/DatePicker';
import { MaskedTextInput } from 'components/ui/MaskedTextInput';
import { dateTime } from 'utils/dateTime';
import { delay } from 'utils/delay';
import { scrollIfOutOfParentRect } from 'utils/scrollIfOutOfParentRect';
import { organizationDetailsSelector, organizationDisplayCurrencySelector } from 'state/organizationSession';

type Props = {
  control: Control<EmployeeEmploymentDetailsFormState>;
  register: UseFormRegister<EmployeeEmploymentDetailsFormState>;
  scrollParentRef: React.MutableRefObject<HTMLDivElement | null>;
} & FlexProps;

export const Rates = ({ control, register, sx, scrollParentRef, ...flexProps }: Props): React.ReactElement => {
  useLingui();
  const displayCurrency = useRecoilValue(organizationDisplayCurrencySelector);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const organizationDetails = useRecoilValue(organizationDetailsSelector);

  const { fields, remove, append } = useFieldArray<
    EmployeeEmploymentDetailsFormState,
    EmployeeEmploymentDetailsFieldNames.Rates,
    'RHF_ID'
  >({
    name: EmployeeEmploymentDetailsFieldNames.Rates,
    control,
    keyName: 'RHF_ID',
  });
  const { errors } = useFormState({ control });

  const watchRates = useWatch({ control, name: EmployeeEmploymentDetailsFieldNames.Rates });

  const watchRatesRef = useRef(watchRates);
  watchRatesRef.current = watchRates;

  const controlledFields = useMemo(
    () =>
      fields
        .map((field) => {
          const currentRate = watchRates?.find(({ id }) => id === field.id);
          return {
            ...field,
            ...(currentRate && currentRate),
          };
        })
        .map(({ startDateUnix, ...rest }) => ({ startDateUnix: +startDateUnix, ...rest })),
    [fields, watchRates],
  );

  const defaultRates = useMemo(() => {
    const alreadySelectedDates = controlledFields.map(({ startDateUnix }) => +startDateUnix);
    const lastSelectedDate = Math.max(...alreadySelectedDates);
    const oneDayInSeconds = 86400;

    const today = dateTime().unix();

    const nextDate = alreadySelectedDates.length ? lastSelectedDate + oneDayInSeconds : today;
    return {
      startDateUnix: nextDate,
      normal: 0,
      overtime: 0,
      id: uuidv1(),
    };
  }, [controlledFields]);

  const defaultRatesRef = useRef(defaultRates);

  defaultRatesRef.current = defaultRates;

  const handleAppend = async () => {
    append(defaultRatesRef.current, { shouldFocus: false });
    await delay(0);
    const scrollParent = scrollParentRef.current;
    const fieldWrapper = wrapperRef.current;
    scrollIfOutOfParentRect(scrollParent, fieldWrapper);
  };
  return (
    <Flex sx={{ flexDirection: 'column', ...(sx && sx) }} {...flexProps} ref={wrapperRef}>
      {!fields?.length ? (
        <ShowFieldsButton
          onClick={handleAppend}
          label={t({ id: 'team.pay_details.add', message: 'Add pay details' })}
        />
      ) : (
        <>
          <Heading variant="heading4" mb={1}>
            <Trans id="team.pay_details">Pay details</Trans>
          </Heading>

          <Flex sx={{ flexDirection: 'column' }}>
            {controlledFields.map((field, index, allFields) => {
              const alreadySelectedDates = allFields.map(({ startDateUnix }) => +startDateUnix);
              const datesToExclude = alreadySelectedDates.filter((date) => date !== +field.startDateUnix);
              return (
                <Flex key={`rates-${field.RHF_ID}`} sx={{ flexDirection: 'column', ...(index !== 0 && { mt: 4 }) }}>
                  <Flex>
                    <Flex sx={{ width: '136px' }}>
                      <DatePicker
                        isStatic
                        placeholder={t({ id: 'team.pay_details.pick_date', message: 'Pick a date' })}
                        label="From"
                        size="sm"
                        id={`rates.${index}.startDateUnix`}
                        error={!!errors.rates?.[index]?.startDateUnix}
                        errorMessage={errors.rates?.[index]?.startDateUnix?.message}
                        minDate={dateTime(organizationDetails?.createDateUnix)}
                        excludedDates={datesToExclude}
                        {...register(`rates.${index}.startDateUnix` as const)}
                      />
                    </Flex>

                    <ButtonPair
                      sx={{ ml: 3, mt: 3, alignItems: 'center' }}
                      leftButtonProps={
                        index === fields.length - 1 && fields.length < 5
                          ? {
                              onClick: handleAppend,
                            }
                          : undefined
                      }
                      rightButtonProps={{ onClick: () => remove(index) }}
                    />
                  </Flex>
                  {/* figma '264px' */}
                  <Flex sx={{ mt: 3, justifyContent: 'space-between', width: '296px' }}>
                    <MaskedTextInput
                      mask={Number}
                      radix="."
                      mapToRadix={['.', ',']}
                      showMask
                      label={t({ id: 'team.pay_details.regular', message: 'Regular' })}
                      size="sm"
                      id={`rates.${index}.normal`}
                      placeholder=""
                      variant="rounded"
                      error={!!errors.rates?.[index]?.normal}
                      errorMessage={errors.rates?.[index]?.normal?.message}
                      defaultValue={field.normal}
                      apendWith={`${displayCurrency || ''}/${t({ id: 'global.forms.pay_rate.hour', message: 'hour' })}`}
                      type="number"
                      max={999}
                      min={0}
                      {...register(`rates.${index}.normal`)}
                    />
                    <MaskedTextInput
                      sx={{ ml: 1 }}
                      labelProps={{ sx: { left: '.75rem' } }}
                      mask={Number}
                      radix="."
                      mapToRadix={['.', ',']}
                      showMask
                      label={t({ id: 'team.pay_details.overtime', message: 'Overtime' })}
                      size="sm"
                      id={`rates.${index}.overtime`}
                      placeholder=""
                      variant="rounded"
                      error={!!errors.rates?.[index]?.overtime}
                      errorMessage={errors.rates?.[index]?.overtime?.message}
                      defaultValue={field.overtime}
                      apendWith={`${displayCurrency || ''}/${t({ id: 'global.forms.pay_rate.hour', message: 'hour' })}`}
                      type="number"
                      max={999}
                      min={0}
                      {...register(`rates.${index}.overtime`)}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export const RatesFieldArray = React.memo(Rates);
