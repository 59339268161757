import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Button } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { BackButton } from 'layouts/Authentication/Main/BackButton';
import { PostForgotPasswordPayload } from 'api/actions/password/passwordActions.types';
import { FormCard } from 'layouts/Authentication';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

const RESET_FORM_ID = 'reset-form-id';

type Props = {
  onSubmit: (props: PostForgotPasswordPayload) => Promise<boolean>;
};

export const PassResetForm = ({ onSubmit }: Props): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange' });

  const handleSubmitCallback = useCallback(
    async (props) => {
      setIsLoading(true);
      const formState = await onSubmit(props);
      setIsLoading(formState);
    },
    [onSubmit],
  );

  return (
    <>
      <form id={RESET_FORM_ID} onSubmit={handleSubmit(handleSubmitCallback)} noValidate>
        <TextInput
          id="email"
          placeholder={i18n._('global.forms.inputs.email')}
          type="email"
          autoComplete="email"
          error={!!errors.email}
          errorMessage={errors?.email?.message}
          icon="mail"
          clearable
          {...register('email', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
        />
      </form>

      <FormCard.Footer>
        <BackButton />

        <Button isLoading={isLoading} variant="primary" size="lg" type="submit" shape="rounded" form={RESET_FORM_ID}>
          <Trans id="global.forms.buttons.next" />
        </Button>
      </FormCard.Footer>
    </>
  );
};
