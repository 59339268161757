import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { InvitationJoinRequestPayload } from 'api/actions/auth/authActions.types';
import { invitationJoinRequestAction } from 'api/actions/auth/authActions';
import { languageSelector } from 'state/recoilState';
import { TO } from 'constants/routes';
import { Main, FormCard } from 'layouts/Authentication';
import { APP_NAME } from 'constants/common';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { LinkButton } from 'components/ui/Buttons';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';

import { EmployeeNotInvitedForm } from './EmployeeNotInvitedForm';

const ReminderSent = (): React.ReactElement => (
  <>
    <FormCard.Header>
      <FormCard.Title>
        <Trans id="sing_up.employee.reminder_sent.lead.header">The reminder has been sent! 👍</Trans>
      </FormCard.Title>
    </FormCard.Header>

    <FormCard.Lead>
      <Trans id="sing_up.employee.reminder_sent.lead.text">
        You should soon receive an invitation from your company's <span>{APP_NAME}</span> administrator.
      </Trans>
    </FormCard.Lead>

    <FormCard.Lead>
      <Trans id="sing_up.employee.reminder_sent.lead.text.not_invited">
        As long as the provided administrator e-mail address has an account in <span>{APP_NAME}</span>.
      </Trans>
    </FormCard.Lead>
  </>
);

const NotInvitedHeader = (): React.ReactElement => (
  <FormCard.Header>
    <FormCard.Title>
      <Trans id="sing_up.employee.not_invited.lead.header">
        This e-mail address hasn't been invited to any company account yet. 😔
      </Trans>
    </FormCard.Title>

    <FormCard.Lead>
      <Trans id="sing_up.employee.not_invited.lead.text">
        If your company already uses {APP_NAME}, send a reminder to the administrator asking them for the access to your
        account.
      </Trans>
    </FormCard.Lead>
  </FormCard.Header>
);

export const EmployeeNotInvited = (): React.ReactElement => {
  const { state } = useLocation();

  const [isSent, setIsSent] = useState(false);

  const language = useRecoilValue(languageSelector);
  const { mutate } = useMutation(invitationJoinRequestAction);

  const { addSnackbar } = useSnackbar();

  const onJoinRequestSubmit = useCallback(
    async (body: InvitationJoinRequestPayload): Promise<boolean> => {
      const { payload, error } = await mutate(body);

      if (!error) {
        setIsSent(true);
        return true;
      }

      if (error && payload?.innerCode === ErrorInnerCodes.InvitationInvalidAdminEmail) {
        addSnackbar({
          message: i18n._(
            t({
              id: 'form.error.invitation_invalid_admin_email',
              message: 'There is no organization with this e-mail address.',
            }),
          ),
          variant: 'danger',
        });

        return false;
      }

      addSnackbar({
        message: i18n._(
          t({
            id: 'form.default_error',
          }),
        ),
        variant: 'danger',
      });

      return false;
    },
    [mutate, addSnackbar],
  );

  if (!state?.signUp?.inProgress) {
    return <Redirect to={TO.SIGN_UP[language]} />;
  }

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'sing_up.page_title', message: `Sign up - ${APP_NAME}` }))}</title>
      </Helmet>

      <Main>
        <FormCard size="md">
          {isSent ? (
            <>
              <ReminderSent />
              <FormCard.Footer sx={{ justifyContent: 'center' }}>
                <LinkButton to={TO.SIGN_IN[language]} variant="grey" shape="rounded" size="lg">
                  <Trans id="password_recovery.form.back_button" />
                </LinkButton>
              </FormCard.Footer>
            </>
          ) : (
            <>
              <NotInvitedHeader />
              <EmployeeNotInvitedForm onSubmit={onJoinRequestSubmit} />
            </>
          )}
        </FormCard>
      </Main>
    </>
  );
};
