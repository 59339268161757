import React, { useEffect } from 'react';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useSetRecoilState } from 'recoil';

import { ACCESS_TOKEN_KEY } from 'constants/common';
import { addMessageSelector, ChatUserStatus, setIsOnlineSelector } from 'state/chat';
import { MessageForFetch } from 'api/actions/chat/chatActions.types';
import { API_ENDPOINTS } from 'constants/api';

type Props = {
  children: React.ReactElement[] | React.ReactElement;
};
// FIXME: remove LogLevel on prod
// TODO: connect watch
export const SignalRProvider = ({ children }: Props): React.ReactElement => {
  const addMessage = useSetRecoilState(addMessageSelector);
  const setIsOnline = useSetRecoilState(setIsOnlineSelector);

  useEffect(() => {
    const connect = async () => {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (accessToken) {
        try {
          const connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL + API_ENDPOINTS.signalR, {
              accessTokenFactory: () => accessToken,
              headers: { Language: 'pl-PL' },
              transport: HttpTransportType.WebSockets,
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect([1000, 3000, 5000, 10000])
            .build();

          connection.on('ReceiveMessage', (m: MessageForFetch) => {
            addMessage(m);
          });

          connection.on('UserStatus', (u: ChatUserStatus) => {
            setIsOnline(u);
          });

          await connection.start();
          // eslint-disable-next-line no-empty
        } catch {}
      }
    };
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMessage, setIsOnline]);

  return <>{children}</>;
};
