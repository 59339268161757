import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { Flex } from 'theme-ui';

import { advancedSettingsDeleteFaceModelsAction } from 'api/actions/settings/settingsActions';
import { AdvancedSettingsCommonActionProps } from 'api/actions/settings/settingsActions.types';
import { Divider } from 'components/Divider/Divider';
import { Button } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { addSnackbar } from 'SnackbarHub/actions';

export const AdvancedDeleteFaceModels = (): React.ReactElement => {
  useLingui();
  const { mutate } = useMutation(advancedSettingsDeleteFaceModelsAction);

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const passwordValue = watch('password');

  const handleSubmitCallback = useCallback(
    async (data: AdvancedSettingsCommonActionProps) => {
      const { error } = await mutate(data);

      if (!error) {
        addSnackbar({
          message: t({
            id: 'settings.organization.advanced.delete_face_models_success',
            message: 'Deleted all face recognition models',
          }),
          variant: 'success',
        });
        reset();
      }

      if (error) {
        addSnackbar({
          message: t({
            id: 'settings.organization.advanced.delete_face_models_danger',
            message: "Couldn't delete face recognition models",
          }),
          variant: 'danger',
        });
      }
    },
    [mutate, reset],
  );

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)}>
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <HeadingTip
          label={t({
            id: 'advanced_settings.heading.delete_all_ai',
            message: 'Delete all AI face recognition models',
          })}
          additionalInfo={t({
            id: 'advanced_settings.heading.additional_info',
          })}
          variant="heading4"
          additionalInfoSx={{
            fontSize: '0.875rem',
            lineHeight: '1.3125rem',
          }}
        />
        <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
          <TextInput
            {...register('password', {
              required: t({
                id: 'global.forms.required',
              }),
            })}
            id="deleteFaceModels"
            placeholder={t({
              id: 'global.forms.inputs.password',
            })}
            size="sm"
            type="password"
            icon="lock"
            label={t({
              id: 'advanced_settings.type_your_password',
            })}
            error={!!errors.deleteFaceModels}
            errorMessage={errors.deleteFaceModels?.message}
            sx={{ maxWidth: '340px' }}
          />

          <Button
            variant="danger"
            shape="rounded"
            disabled={!passwordValue}
            type="submit"
            sx={{ alignSelf: 'flex-start' }}
          >
            <Trans id="advanced_settings.delete" />
          </Button>

          <Divider />
        </Flex>
      </Flex>
    </form>
  );
};
