import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import {
  CustomRequestTypes,
  Employee,
  PersonName,
  TimeEventType,
  TimeOffTypes,
} from '../organizationSession/organizationSessionActions.types';

export enum RequestFormType {
  TimeOff = 0,
  TimeTracking = 1,
  BusinessTrip = 2,
  Custom = 3,
}

export enum RequestState {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
  Deleted = 3,
}

export enum RequestHistoryState {
  Created = 0,
  Accepted = 1,
  Rejected = 2,
  FirstOutOfTwoAcceptance = 3,
  Deleted = 4,
}

export enum RequestActionType {
  Create = 0,
  Edit = 1,
  Remove = 2,
}

export type DateTimeDetails = {
  dateUnix?: number;
  dateRange?: {
    startUnix: number;
    endUnix: number;
  };
  duration?: {
    days: number;
    hours: number;
    minutes: number;
  };
  isDateBound: boolean;
};

export type Request = {
  id: string;
  personId: Employee['id'];
  number: string;
  type: RequestFormType;
  typeId?: string;
  state: RequestState;
  isDeleted: boolean;
  dateTimeDetails: DateTimeDetails;
  typeDescription: string;
  actionType: RequestActionType;
  modificationAllowed: boolean;
  deleteAllowed: boolean;
};

export type FetchRequestsResponse = Request[];

export type CommonRequestManagementActionProps = Request['id'][];

export type AcceptRequestManagementActionProps = CommonRequestManagementActionProps;

export type AcceptRequestManagementResponse = {
  a: string;
};

export type RejectRequestManagementActionProps = {
  id: string;
  reason: string;
};

export type RejectRequestManagementResponse = {
  a: string;
};

export type DeleteRequestManagementActionProps = CommonRequestManagementActionProps;

export type DeleteRequestManagementResponse = {
  a: string;
};

export type RequestOverviewElementRequest = {
  type: RequestFormType;
  typeId: string;
  usageTimeDuration: {
    days: 0;
    hours: 0;
    minutes: 0;
  };
  totalTimeDuration?: {
    days: 0;
    hours: 0;
    minutes: 0;
  };
};

export type RequestOverviewElement = {
  personId: Employee['id'];
  businessTravelsCount: number;
  timeEventsCount?: number;
  requests: RequestOverviewElementRequest[];
};

export type FetchRequestsOverviewForTimeRangeResponse = RequestOverviewElement[];

export type RequestHistory = {
  person: string;
  dateTimeUnix: number;
  reason: string;
  state: RequestHistoryState;
};

export type RequestEventType = {
  typeId: TimeEventType['id'];
  dateUnix: number;
  note: string;
};

export type OvertimeTaken = {
  dateUnix: number;
  hours: number;
  minutes: number;
};

export type Participant = {
  person: PersonName;
  avatarUrl: string;
};

export type FetchRequestDetailsResponse = Request & {
  note?: string;
  place?: string;
  overtimeTaken?: OvertimeTaken[];
  history: RequestHistory[];
  attachments?: string[];
  originalEvent?: RequestEventType;
  newEvent?: RequestEventType;
  participants?: Participant[];
};

export type PeopleLimit = {
  personId: Employee['id'];
  duration: {
    days: number;
    hours: number;
    minutes: number;
  };
};

export type RemainingLimit = {
  maxLength: {
    days: number;
    hours: number;
    minutes: number;
  };
  datesUnix: number[];
  type: RequestFormType;
  typeId: string;
  peopleLimits: PeopleLimit[];
};

export type GetTimeOffRemainingLimitsActionProps = {
  peopleIds: Employee['id'][];
  typeId: string;
  datesUnix?: number[];
};

export type GetTimeOffRemainingLimitsResponse = RemainingLimit;

export type GetCustomRemainingLimitsActionProps = {
  peopleIds: Employee['id'][];
  typeId: string;
  datesUnix?: number[];
};

export type GetCustomRemainingLimitsResponse = RemainingLimit;

export type AddRequest = {
  code: ErrorInnerCodes;
  person: string;
  message: string;
  success: boolean;
};

export type AddRequestActionProps = {
  peopleIds: Employee['id'][];
  type: RequestFormType;
  actionType: RequestActionType;
  note?: string;
  files?: string[];
  processedById?: Employee['id'];
  optionalStepProcessedById?: Employee['id'];
  requestId?: string;
  timeOffData?: {
    typeId: TimeOffTypes;
    isDateBound: boolean;
    datesUnix?: number[];
    durationUnix?: number;
    correlatedDateUnix?: number;
    ignoreHolidays: boolean;
    ignoreWeekends: boolean;
  };
  customRequestData?: {
    typeId: CustomRequestTypes;
    isDateBound: boolean;
    datesUnix?: number[];
    durationUnix?: 0;
    ignoreHolidays: boolean;
    ignoreWeekends: boolean;
  };
  businessTripData?: {
    name: string;
    datesUnix: number[];
    place: string;
  };
  timeTrackingData?: {
    isEnd: boolean;
    datesUnix: number[];
    note: string;
    eventId: string;
  };
};

export type AddRequestResponse = AddRequest[];

export type TimeEventHistoryElement = {
  id: string;
  typeId: TimeEventType['id'];
  personId: Employee['id'];
  dateUnix: number;
  isEndStatus: boolean;
};

export type FetchApprovalListsProps = {
  personId: string;
  requestType: RequestFormType;
};

export type FetchApprovalListsResponse = {
  firstStepPeopleIds: string[];
  secondStepPeopleIds: string[];
};

type Duration = {
  days: number;
  hours: number;
  minutes: number;
};

export type CommonEditRemoveRequestData = {
  id: string;
  dateTimeDetails: {
    dateUnix?: number;
    dateRange?: {
      startUnix: number;
      endUnix: number;
    };
    duration?: Duration;
    isDateBound: boolean;
  };
  note?: string;
  fileIds?: string[];
  processedById: string;
  optionalStepProcessedById?: string;
};

export type EventsData = CommonEditRemoveRequestData & {
  typeId: string;
  isEndStatus: boolean;
};

export type TimeOffsData = CommonEditRemoveRequestData & {
  correlatedDateUnix: number;
  typeId: string;
  ignoreHolidays: boolean;
  ignoreWeekends: boolean;
};

export type CustomRequestsData = CommonEditRemoveRequestData & {
  correlatedDateUnix: number;
  typeId: string;
  ignoreHolidays: boolean;
  ignoreWeekends: boolean;
};

export type BusinessTravelsData = CommonEditRemoveRequestData & {
  name: string;
  place: string;
};

export type RequestGetDataToModifyActionProps = {
  personId: string;
  skip: number;
  type: number;
};

export type RequestGetDataToModifyResponse = {
  eventsData?: EventsData[];
  timeOffsData?: TimeOffsData[];
  customRequestsData?: CustomRequestsData[];
  businessTravelsData?: BusinessTravelsData[];
};

export type RequestPrintActionProps = string[];

export type RequestPrintResponse = {
  url: string;
};
