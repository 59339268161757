import React from 'react';
import { useRecoilValue } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { ElementGroup } from 'components/ui/ElementGroup';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';

import { MenuButton } from './MenuButton';

const MenuButtonWithDropdown = withDropdown(MenuButton);

export const Menu = React.memo((): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  useLingui();

  const timeTrackingLinks = [
    {
      label: t({
        id: 'navbar.menu.clock_log',
        message: 'Clock Log',
      }),
      to: TO.TIME_TRACKING__CLOCK_LOG[language],
    },
    {
      label: t({
        id: 'navbar.menu.work_statuses',
        message: 'Work Statuses',
      }),
      to: TO.TIME_TRACKING__WORK_STATUSES[language],
    },
    {
      label: t({
        id: 'navbar.menu.locations',
        message: 'Locations',
      }),
      to: TO.TIME_TRACKING__LOCATIONS[language],
    },
    {
      label: t({
        id: 'navbar.menu.time_clocks',
        message: 'Time Clocks',
      }),
      to: TO.TIME_TRACKING__TIME_CLOCKS[language],
    },
    {
      label: t({
        id: 'navbar.menu.kiosk',
        message: 'Kiosk Mode',
      }),

      prependWithDivider: true,
      to: TO.KIOSK[language],
      apendWith: (
        <Icon
          type="blankOpen"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.open(TO.KIOSK[language], '_blank');
            return false;
          }}
          wrapperSx={{
            margin: '-0.25rem',
            padding: '0.25rem',
            borderRadius: 'sm',
            color: 'texts.lighter',
            transition: 'all 0.125s ease-in-out',
            '&:hover': {
              color: 'texts.default',
              backgroundColor: 'whites4',
            },
          }}
          tooltip={t({
            id: 'global.open_in_new_window',
            message: 'Open in new window',
          })}
        />
      ),
    },
  ];

  const reportsLinks = [
    {
      label: t({
        id: 'navbar.menu.attendance_list',
        message: 'Attendance List',
      }),
      to: TO.REPORTS__ATTENDANCE_LIST[language],
    },
    {
      label: t({
        id: 'navbar.menu.timesheets',
        message: 'Timesheets',
      }),
      to: TO.REPORTS__TIMESHEETS[language],
    },
  ];

  return (
    <ElementGroup
      marginValue={[0, null, null, '2px']}
      showAsList
      wrapperSx={{
        alignItems: 'center',
      }}
    >
      <MenuButton icon="home" to={TO.START[language]} sx={{ display: ['none', null, null, 'inline-flex'] }} />
      <MenuButton icon="team" to={TO.TEAM[language]}>
        <Trans id="navbar.menu.team">Team</Trans>
      </MenuButton>
      <MenuButton icon="calendar" to="/p2">
        <Trans id="navbar.menu.calendar">Calendar</Trans>
      </MenuButton>
      <MenuButton icon="requests" to={TO.REQUESTS[language]}>
        <Trans id="navbar.menu.requests">Requests</Trans>
      </MenuButton>
      <MenuButtonWithDropdown
        popperProps={{
          withPopperState: true,
          hideOnReferenceHidden: false,
        }}
        dropdownProps={{
          links: timeTrackingLinks,
        }}
        icon="timeClock"
        dropdownLinks={timeTrackingLinks}
      >
        <Trans id="navbar.menu.time_tracking">Time Tracking</Trans>
      </MenuButtonWithDropdown>
      <MenuButtonWithDropdown
        popperProps={{
          withPopperState: true,
          hideOnReferenceHidden: false,
        }}
        dropdownProps={{
          links: reportsLinks,
        }}
        dropdownLinks={reportsLinks}
        icon="reports"
      >
        <Trans id="navbar.menu.reports">Reports</Trans>
      </MenuButtonWithDropdown>
    </ElementGroup>
  );
});
