import React, { useRef } from 'react';
import { Control, useFieldArray, UseFormRegister, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';

import { MergedProperties } from 'utils/custom.types';
import { AdvancedEmployeeInfo } from 'api/actions/employees/employeesActions.types';
import { Switch } from 'components/ui/Switch';
import { Checkbox, CheckboxProps } from 'components/ui/Checkbox';
import { ElementGroup } from 'components/ui/ElementGroup';
import { TagDetails } from 'api/actions/tags/tagsActions.types';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { Select, SelectProps } from 'components/ui/Select/Select';
import { setNativeValue } from 'utils/setNativeValue';
import { mergeRefs } from 'utils/mergeRefs';
import {
  Feature,
  FeaturesTypes,
  VisibilityLevels,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import {
  APP_STORE_DOWNLOAD_APP_LINK,
  APP_STORE_DOWNLOAD_RCP_APP_LINK,
  GOOGLE_PLAY_DOWNLOAD_APP_LINK,
  GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK,
} from 'constants/common';
import { visibilityLevelOptionsSelector } from 'state/selectOptions';

const checkboxWrapperSx: ThemeUIStyleObject = {
  fontWeight: '400',
  flexBasis: '88px',
  minWidth: '88px',
};

type Props = {
  control: MergedProperties<UseFormRegister<Control<AdvancedEmployeeInfo>>, Control<Partial<TagDetails>>>;
  register: MergedProperties<UseFormRegister<Partial<AdvancedEmployeeInfo>>, UseFormRegister<Partial<TagDetails>>>;
  watch?: UseFormWatch<Partial<AdvancedEmployeeInfo>>;
  setValue?: MergedProperties<UseFormSetValue<Control<AdvancedEmployeeInfo>>, UseFormSetValue<Partial<TagDetails>>>;
  usedInEmployeeForm?: boolean;
};

const defaultProps = {
  usedInEmployeeForm: false,
  watch: undefined,
  setValue: undefined,
};

export const AdvancedFeaturesFieldArray = ({
  control,
  register,
  watch, // FIXME: this should be provided by useWatch hook
  setValue,
  usedInEmployeeForm,
}: Props): React.ReactElement => {
  const { fields } = useFieldArray({
    control,
    name: 'features',
  });

  const watchFieldArray = watch ? (watch('features') as unknown as Feature[]) : (fields as unknown as Feature[]);
  const controlledFields = (fields as unknown as Feature[]).map((field) => {
    const watchedField = _.find(watchFieldArray, { type: field.type });
    return {
      ...field,
      ...(watchedField && watchedField),
    };
  });

  const visibilityLevelOptions = useRecoilValue(visibilityLevelOptionsSelector);

  const visibilityLevelRef = useRef<HTMLInputElement | null>(null);

  useLingui();

  const handleCheckboxWrapperClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation();

  return (
    <ElementGroup wrapperSx={{ mt: '0.75rem' }} showAsList marginValue="0.75rem" direction="column" withDividers>
      {controlledFields.map((field: Feature, index) => {
        const { label, additionalInfo } = (() => {
          switch (field.type) {
            case FeaturesTypes.showFullTeammatesData:
              return {
                label: t({ id: 'team.advanced.full_data', message: 'Show full data about teammates' }),
                additionalInfo: t({
                  id: 'team.advanced.full_data.desc',
                  message: 'For ex. exact types of time offs and schedules',
                }),
              };
            case FeaturesTypes.clockInsWebApp:
              return {
                label: t({ id: 'team.advanced.web_app_clockins', message: 'Allow Clock-Ins from Web App' }),
              };
            case FeaturesTypes.turnOffLocationLimits:
              return {
                label: t({
                  id: 'team.advanced.location_limits',
                  message: 'Turn off all location limits for time tracking',
                }),
                additionalInfo: t({
                  id: 'team.advanced.location_limits.desc',
                  message: 'User will be able to Clock-In & Out from any location',
                }),
              };
            case FeaturesTypes.limitDataVisibility:
              return {
                label: t({
                  id: 'team.advanced.data_visibility',
                  message: 'Limit data visibility',
                }),
                additionalInfo: t({
                  id: 'team.advanced.data_visibility.desc',
                  message: 'Defines how long into the past user will be able to see their data',
                }),
              };
            case FeaturesTypes.clockInsMobileApp:
              return {
                label: t({
                  id: 'team.advanced.mobile_clockins',
                  message: 'Allow Clock-Ins from Mobile App',
                }),
                additionalInfo: (
                  <Trans id="team.advanced.mobile_clockins.desc">
                    Download app from &nbsp;
                    <LinkAnchor
                      sx={{ pointerEvents: 'auto', mx: '5px' }}
                      href={GOOGLE_PLAY_DOWNLOAD_APP_LINK}
                      target="_blank"
                    >
                      Google Play
                    </LinkAnchor>
                    &nbsp; or &nbsp;
                    <LinkAnchor sx={{ pointerEvents: 'auto' }} href={APP_STORE_DOWNLOAD_APP_LINK} target="_blank">
                      App Store
                    </LinkAnchor>
                  </Trans>
                ),
              };
            case FeaturesTypes.clockInsKioskMode:
              return {
                label: t({
                  id: 'team.advanced.kiosk_clockins',
                  message: 'Allow Clock-Ins from Kiosk Mode',
                }),
              };
            case FeaturesTypes.clockInsMobileTimeClock:
              return {
                label: t({
                  id: 'team.advanced.kiosk_clockins.allow',
                  message: 'Allow Clock-Ins from Mobile Time Clock',
                }),
                additionalInfo: (
                  <Trans id="team.advanced.kiosk_clockins.desc">
                    Download app from&nbsp;
                    <LinkAnchor
                      sx={{ pointerEvents: 'auto', mx: '5px' }}
                      href={GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK}
                      target="_blank"
                    >
                      Google Play
                    </LinkAnchor>
                    &nbsp;or&nbsp;
                    <LinkAnchor sx={{ pointerEvents: 'auto' }} href={APP_STORE_DOWNLOAD_RCP_APP_LINK} target="_blank">
                      App Store
                    </LinkAnchor>
                  </Trans>
                ),
              };
            default:
              return {
                label: '',
                additionalInfo: '',
              };
          }
        })();

        const getInheritFromTagRegisterProps = (): Pick<CheckboxProps, 'onChange' | 'name' | 'onBlur'> | null => {
          if (usedInEmployeeForm) {
            return register(`features.${index}.inheritFromTag`);
          }
          return null;
        };

        const getVisibilityLevelRegisterProps = ():
          | (Pick<SelectProps, 'onChange' | 'name' | 'onBlur'> & {
              ref: React.RefCallback<HTMLInputElement | null>;
            })
          | null => {
          if (field.type === FeaturesTypes.limitDataVisibility) {
            const { ref, ...registerProps } = register('visibilityLevel');
            return {
              ref: mergeRefs([ref, visibilityLevelRef]),
              ...registerProps,
            };
          }
          return null;
        };

        const inheritFromTagRegisterProps = getInheritFromTagRegisterProps();
        const visibilityLevelRegisterProps = getVisibilityLevelRegisterProps();

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.checked && setValue) {
            setValue(`features.${index}.value`, field.inheritedValue);
            if (watch) {
              const visibilityLevelInheritedFromTag = watch('visibilityLevelInheritedFromTag');
              setNativeValue(visibilityLevelRef, visibilityLevelInheritedFromTag);
            }
          }
          if (inheritFromTagRegisterProps?.onChange) {
            inheritFromTagRegisterProps.onChange(e);
          }
        };

        return (
          <ConditionalWrapper
            key={`advanced-feature--${field.type}`}
            condition={field.type === FeaturesTypes.limitDataVisibility}
            wrapper={({ children, sx }) => (
              <Flex sx={{ flexDirection: 'column', ...(sx && sx) }}>
                {children}
                {watch && watch(`features.${index}.value`) && (
                  <Flex
                    sx={{
                      pl: 3,
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 4,
                      mt: '0.75rem',
                    }}
                  >
                    <Text sx={{ fontWeight: 'bold', fontSize: 2 }}>
                      <Trans id="team.advanced.only_show_last">Only show last</Trans>
                    </Text>
                    <Select
                      sx={{ width: '160px' }}
                      defaultValue={VisibilityLevels.Year}
                      disabled={watch(`features.${index}.inheritFromTag`)}
                      size="sm"
                      id="visibilityLevel"
                      placeholder=""
                      options={visibilityLevelOptions}
                      {...(visibilityLevelRegisterProps && visibilityLevelRegisterProps)}
                    />
                  </Flex>
                )}
              </Flex>
            )}
          >
            <Switch
              defaultChecked={field.value}
              disabledButReadable={watch ? watch(`features.${index}.inheritFromTag`) : false}
              placement="right"
              size="sm"
              label={label}
              additionalInfo={additionalInfo}
              prependSwitchWith={
                usedInEmployeeForm && (
                  <Flex onClick={handleCheckboxWrapperClick} sx={checkboxWrapperSx}>
                    <Checkbox
                      defaultChecked={field.inheritFromTag}
                      label={t({ id: 'team.advanced.inherit_from_tags', message: 'Inherit from tags' })}
                      size="sm"
                      {..._.omit(inheritFromTagRegisterProps, ['onChange'])}
                      onChange={handleChange}
                      sx={{ gap: 2 }}
                    />
                  </Flex>
                )
              }
              {...register(`features.${index}.value`)}
            />
          </ConditionalWrapper>
        );
      })}
    </ElementGroup>
  );
};

AdvancedFeaturesFieldArray.defaultProps = defaultProps;
