import React from 'react';
import { useRecoilValue } from 'recoil';

import { PATH } from 'constants/routes';
import { ModalRoute } from 'routing/ModalRoute';
import { languageSelector } from 'state/recoilState';
import { DeleteRequestsModal } from 'layouts/Requests/modals/DeleteRequests';
import { RejectRequestModal } from 'layouts/Requests/modals/RejectRequestModal';
import { RequestOverviewModal } from 'layouts/Requests/requestsOverview/RequestOverview';
import { AddRequestModal } from 'layouts/Requests/modals/addRequest/AddRequest';
import { RequestDetailsModal } from 'layouts/Requests/modals/requestDetails/RequestDetailsModal';

export const RequestsModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  return (
    <>
      <ModalRoute size="sm" path={PATH.DELETE_REQUESTS_MODAL[language]}>
        <DeleteRequestsModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.REJECT_REQUESTS_MODAL[language]}>
        <RejectRequestModal />
      </ModalRoute>
      <ModalRoute fullHeight size="fullscreen" path={PATH.REQUEST_OVERVIEW_MODAL[language]}>
        <RequestOverviewModal />
      </ModalRoute>
      <ModalRoute fullHeight size="sm" path={PATH.REQUEST_DETAILS__ID[language]}>
        <RequestDetailsModal />
      </ModalRoute>
      <ModalRoute fullHeight size="sm" path={PATH.ADD_REQUEST[language]}>
        <AddRequestModal />
      </ModalRoute>
    </>
  );
};
