import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Trans } from '@lingui/macro';
import { useHistory } from 'react-router';

import { FormCard } from 'layouts/Authentication';
import { AccountInfoAbout } from 'api/actions/user/userActions.types';
import { signUpFormAtom } from 'state/signUp';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

import { EmployerStep1Form } from './EmployerStep1Form';

export const EmployerStep1 = React.forwardRef<HTMLFormElement>((props, ref) => {
  const language = useRecoilValue(languageSelector);
  const [signUpFormState, setSignUpFormState] = useRecoilState(signUpFormAtom);

  const { push } = useHistory();

  const onSubmit = useCallback(
    async (body: AccountInfoAbout): Promise<boolean> => {
      setSignUpFormState({ ...signUpFormState, ...body });
      push(`${TO.SIGN_UP__EMPLOYER__STEP[language]}/2`, {
        signUp: {
          inProgress: true,
        },
      });
      return true;
    },
    [language, push, setSignUpFormState, signUpFormState],
  );

  return (
    <>
      <FormCard.Header>
        <FormCard.Title>
          <Trans id="sing_up.employer.step_2.lead.header">Let's get to know each other! 🤝</Trans>
        </FormCard.Title>
        <FormCard.Lead>
          <Trans id="sing_up.employer.step_2.lead.text">
            So we'll be able to guarantee you the best experience possible.
          </Trans>
        </FormCard.Lead>
      </FormCard.Header>

      <EmployerStep1Form ref={ref} onSubmit={onSubmit} />
    </>
  );
});
