import { atom, selector, DefaultValue } from 'recoil';
import _ from 'lodash';

import { NotificationOwnProps, NotificationVariants } from 'components/Notification/types';

export const notificationCenterAtom = atom<NotificationOwnProps[]>({
  key: 'notificationsForCenter',
  default: [],
});

export const addNotificationCenterSelector = selector<NotificationOwnProps | null>({
  key: 'addNotificationForCenter',
  get: () => null,

  set: ({ get, set }, newNotification) => {
    if (newNotification && !(newNotification instanceof DefaultValue)) {
      const notifications = get(notificationCenterAtom);
      const index = _.findIndex(notifications, (n) => _.isEqual(n.id, newNotification.id));
      if (index === -1) {
        const variant: NotificationVariants = 'notificationCenter';
        set(notificationCenterAtom, [{ ...newNotification, variant }, ...notifications]);
      }
    }
  },
});

export const removeFromNotificationCenterSelector = selector<string | null>({
  key: 'removeNotificationFromCenter',
  get: () => null,

  set: ({ get, set }, id) => {
    const notifications = get(notificationCenterAtom);

    if (id && notifications) {
      const notificationsAfterRemove = _.filter(notifications, (n) => !_.isEqual(n.id, id));
      set(notificationCenterAtom, notificationsAfterRemove);
    }
  },
});
