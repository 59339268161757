/** @jsxImportSource theme-ui */

import React, { useCallback, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { Heading, Text, ThemeUIStyleObject } from 'theme-ui';
import { useForm } from 'react-hook-form';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';

import { TextInput } from 'components/ui/TextInput';
import { ColorPicker } from 'components/ui/ColorPicker';
import { TagDetails } from 'api/actions/tags/tagsActions.types';
import { FEATURES_DEFAULT_VALUE } from 'constants/defaults';
import { getTextField } from 'utils/getTextField';
import { getNumberField } from 'utils/getNumberField';
import {
  FetchOrganizationSessionResponse,
  VisibilityLevels,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { organizationSessionPropertySelectorFamily } from 'state/organizationSession';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

import { AdvancedFeaturesFieldArray } from './formsElements/AdvancedFeaturesFieldArray';

const labelSx: ThemeUIStyleObject = {
  ml: 2,
  fontSize: 1,
  fontWeight: 'bold',
  mt: 3,
};

const forceCorrectTypes = ({ additionalInfo, color, visibilityLevel, ...rest }: TagDetails): TagDetails => ({
  ...rest,
  additionalInfo: getTextField(additionalInfo),
  color: +color,
  visibilityLevel: +(getNumberField(visibilityLevel) || VisibilityLevels.Year),
});

type Props = {
  defaultValues?: Partial<TagDetails>;
  onSubmit: (props: TagDetails) => Promise<boolean>;
  setLoading: (loading: boolean) => void;
};

const defaultProps: Partial<Props> = {
  defaultValues: {
    features: FEATURES_DEFAULT_VALUE,
    visibilityLevel: VisibilityLevels.Year,
  },
};

export const AddEditTag = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, defaultValues = defaultProps.defaultValues, setLoading }: Props, ref) => {
    useLingui();
    const {
      watch,
      control,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: 'onTouched',
      reValidateMode: 'onChange',
      defaultValues,
    });
    const tags = useRecoilValue(
      organizationSessionPropertySelectorFamily('tags') as RecoilValueReadOnly<
        FetchOrganizationSessionResponse['tags'] | null
      >,
    );

    const restrictedTagNames = useMemo(() => {
      if (!tags) return [];
      return tags
        .map(({ name }) => name.trim())
        .filter((name) => {
          if (!defaultValues) return true;
          return name !== defaultValues.name;
        });
    }, [tags, defaultValues]);

    const handleSubmitCallback = useCallback(
      (body: TagDetails) => {
        onSubmit(forceCorrectTypes(body));
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    return (
      <form ref={ref} onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)} noValidate>
        <Heading variant="heading4.withMargins">
          <Trans id="team.add_edit_tag.basic_details">Basic details</Trans>
        </Heading>
        <Text sx={labelSx}>
          <Trans id="team.add_edit_tag.name">Name</Trans>
        </Text>

        <TextInput
          clearable
          size="sm"
          id="name"
          placeholder={t({ id: 'team.add_edit_tag.name', message: 'Name' })}
          variant="rounded"
          error={!!errors.name}
          errorMessage={errors?.name?.message}
          {...register(
            'name',
            validationFactory({
              ...VALIDATION_RULES.TAG_NAME,
              required: true,
              restrictedValues: restrictedTagNames,
            }),
          )}
        />

        <Text as="div" sx={labelSx}>
          <Trans id="team.add_edit_tag.additional_info">Additional information - not visible to Employees</Trans>
        </Text>
        <TextInput
          size="sm"
          id="additionalInfo"
          placeholder={t({
            id: 'team.add_edit_tag.additional_info.description',
            message: 'Private notes, logs and informations',
          })}
          variant="rounded"
          error={!!errors.additionalInfo}
          errorMessage={errors?.additionalInfo?.message}
          {...register('additionalInfo', validationFactory(VALIDATION_RULES.TAG_ADDITIONAL_INFO))}
        />

        <Heading mt={4} variant="heading4.withMargins">
          <Trans id="team.add_edit_tag.color">Color</Trans>
        </Heading>
        <ColorPicker {...register('color')} />
        <Heading mt={4} variant="heading4.withMargins">
          <Trans id="team.add_edit_tag.advanced_details">Advanced details</Trans>
        </Heading>
        <AdvancedFeaturesFieldArray register={register} control={control} watch={watch} />
      </form>
    );
  },
);

AddEditTag.defaultProps = defaultProps;
