import { useEffect, useRef, useState } from 'react';

const cachedScripts: unknown[] = [];

export const useScript = (src: string): { loaded: boolean; error: boolean } => {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  const mountedRef = useRef<boolean>(true);

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    }

    cachedScripts.push(src);

    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const onScriptLoad = () => {
      if (mountedRef.current) {
        setState({
          loaded: true,
          error: false,
        });
      }
    };

    const onScriptError = () => {
      const index = cachedScripts.indexOf(src);

      if (index >= 0) {
        cachedScripts.splice(index, 1);
      }

      script.remove();

      setState({
        loaded: true,
        error: true,
      });
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', onScriptError);
      script.removeEventListener('error', onScriptError);
      document.body.removeChild(script);
    };
  }, [src]);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    [],
  );

  return { loaded: state.loaded, error: state.error };
};
