import React, { useCallback } from 'react';
import { Box, Flex, Text, BoxOwnProps } from 'theme-ui';
import { motion, MotionProps } from 'framer-motion';
import _ from 'lodash';

import { useTheme } from 'styles/useTheme';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

import { ButtonSharedProps } from './types';
import { setAnimations, setBgOverwriteSx, setOutlineSx, setShape } from './internals';

const AnimatedBox = motion(Box as React.FC<unknown>) as React.FC<AnimatedBoxButtonProps>;

type AnimatedBoxButtonProps =
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | MotionProps
  | BoxOwnProps
  | { ref?: React.ForwardedRef<HTMLButtonElement> };

interface Props extends ButtonSharedProps, Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> {}

export type ButtonProps = Props;

const defaultProps: Partial<Props> = {
  variant: 'default',
  size: 'default',
  shape: 'pill',
  type: 'button',
  outline: false,
  isLoading: false,
  fullWidth: false,
  prependWith: undefined,
  apendWith: undefined,
  bgOverwrite: undefined,
};

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      variant = 'default',
      size = 'default',
      shape = 'pill',
      outline,
      isLoading,
      fullWidth,
      prependWith,
      apendWith,
      onClick,
      sx,
      bgOverwrite,
      type,
      disabled,
      ...props
    }: Props,
    ref,
  ) => {
    const { theme } = useTheme();

    const motionAnimations = setAnimations({ bgOverwrite, variant, theme });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const internalOnClick = useCallback(
      _.debounce(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          if (isLoading) {
            event.preventDefault();
            return;
          }

          if (onClick) onClick(event);
        },
        150,
        {
          leading: true,
          trailing: false,
        },
      ),
      [onClick, isLoading],
    );

    return (
      <AnimatedBox
        as="button"
        initial={motionAnimations.initial}
        whileHover={!isLoading ? { ...motionAnimations.hover } : undefined}
        whileTap={!isLoading ? { scale: !fullWidth ? 0.975 : 0.985, ...motionAnimations.tap } : undefined}
        transition={{ duration: 0.05 }}
        variant={`buttons.${variant}`}
        ref={ref}
        sx={{
          cursor: !isLoading ? 'pointer' : 'progress',
          width: fullWidth ? '100%' : 'auto',
          gap: theme.buttons.sizes[size].py,
          ...(bgOverwrite && setBgOverwriteSx({ bgOverwrite })),
          ...(outline &&
            setOutlineSx({
              textColor: theme.colors.buttons[variant].text.reversed,
              radii:
                shape !== 'rounded'
                  ? theme.buttons.shapes[shape].borderRadius
                  : theme.buttons.shapeRoundedSizes[size].borderRadius,
            })),
          ...theme.buttons.sizes[size],
          ...setShape({ shape, size, theme }),
          ...(sx && sx),
          '&[data-popper-visible="true"]': {
            background: `${
              motionAnimations.hover.backgroundColor || motionAnimations.hover.backgroundImage
            } !important`,
          },
        }}
        onClick={internalOnClick}
        type={type}
        disabled={disabled}
        {...props}
      >
        <ConditionalWrapper
          condition={!!isLoading}
          wrapper={({ children: wrapperChildren }) => <Flex sx={{ opacity: 0 }}>{wrapperChildren}</Flex>}
        >
          <>
            {prependWith && prependWith}
            {children && (
              <Text
                as="span"
                sx={{
                  display: 'inline-flex',
                  position: 'relative',
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                }}
              >
                {children}
              </Text>
            )}
            {apendWith && apendWith}
          </>
        </ConditionalWrapper>

        {isLoading && (
          <LoadingSpinnerCss
            sx={{ position: 'absolute' }}
            size={theme.buttons.sizes[size].fontSize || 2}
            color={theme.buttons[variant].color}
          />
        )}
      </AnimatedBox>
    );
  },
);

Button.defaultProps = defaultProps;
