import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  FetchChatUsersResponse,
  FetchMessagesParams,
  FetchMessagesResponse,
  PostHasBeenRead,
  PostHasBeenReadResponse,
  PostMessage,
  PostMessageResponse,
  RemoveMessage,
  RemoveMessageResponse,
} from './chatActions.types';

export function fetchMessagesAction(params: FetchMessagesParams): Action<FetchMessagesResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.chat.messages}${params.id}&part=${params.part}`,
  };
}

export function fetchChatUsersAction(): Action<FetchChatUsersResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.chat.chatUsers,
  };
}

export function postMessageAction(message: PostMessage): Action<PostMessageResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.chat.message,
    body: {
      ...message,
    },
  };
}

export function removeMessageAction(id: RemoveMessage): Action<RemoveMessageResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.chat.deleteMessage + id,
  };
}

export function postHasBeenReadAction(id: PostHasBeenRead): Action<PostHasBeenReadResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.chat.hasBeenRead + id,
  };
}
