/* eslint-disable no-underscore-dangle */

import { ACCESS_TOKEN_KEY } from '../../../constants/common';

class AuthStorage {
  private _accessToken: string | null = null;

  constructor() {
    try {
      this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    } catch (error) {
      this.accessToken = null;
    }
  }

  get accessToken(): string | null {
    return this._accessToken;
  }

  set accessToken(value: string | null) {
    this._accessToken = value;

    try {
      if (typeof value === 'string') {
        localStorage.setItem(ACCESS_TOKEN_KEY, value);
      } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
      }
    } catch (error) {
      // throw some error
    }
  }
}

export const authStorage = new AuthStorage();
