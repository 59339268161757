import React, { useEffect } from 'react';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useScript } from 'hooks/useScript/useScript';
import { APPLE_CLIENT_ID, APPLE_LINK_URL } from 'constants/common';

import { ExternalIntegration } from './ExternalIntegration';

type initProps = {
  clientId: string;
  scope: string;
  redirectURI: string;
  state?: string;
  nonce?: string;
  usePopup?: boolean;
};

export type AppleProps = {
  auth: {
    init: (props: initProps) => void;
    renderButton: () => void;
    signIn: () => void;
  };
};

declare const AppleID: AppleProps;

const params = {
  clientId: APPLE_CLIENT_ID,
  redirectURI: APPLE_LINK_URL,
  scope: 'openid',
};

type Props = {
  isActive: boolean;
  image: string;
};

export const AppleIntegration = ({ isActive, image }: Props): React.ReactElement => {
  useLingui();
  const scriptState = useScript(process.env.REACT_APP_APPLE_SCRIPT || '');

  useEffect(() => {
    if (scriptState.loaded) {
      AppleID.auth.init(params);
    }
  }, [scriptState]);

  const onClick = () => {
    if (!scriptState.error) {
      AppleID.auth.signIn();
    }
  };

  return (
    <ExternalIntegration
      heading="Apple"
      image={image}
      description={t({
        id: 'settings.integrations.apple',
        message: 'Use you Apple ID to quickly sign into our platform.',
      })}
      isActive={isActive}
      connectButton={{ text: t({ id: 'connect' }), onClick }}
      disconnectButton={{ text: t({ id: 'disconnect' }) }}
    />
  );
};
