import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { withPopper } from '../PopperProvider/withPopper';
import { decorationSx, PopperProviderProps } from '../PopperProvider/PopperProvider';

import { Tooltip, TooltipProps } from './Tooltip';

type WithAdditionalProps = {
  tooltipProps?: TooltipProps;
  popperProps?: Omit<PopperProviderProps, 'children' | 'content'>;
  decorateChildren?: boolean;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<WithAdditionalProps> = {
  popperProps: undefined,
  tooltipProps: undefined,
  decorateChildren: false,
  sx: undefined,
};
export function withTooltip<T>(
  Component: React.ComponentType<T & WithAdditionalProps>,
): (props: T & WithAdditionalProps) => React.ReactElement {
  const ComponentWithPopper = withPopper(Component);

  const ComponentWithTooltip = ({
    tooltipProps,
    popperProps,
    decorateChildren,
    sx,
    ...props
  }: T & WithAdditionalProps): React.ReactElement => (
    <ComponentWithPopper
      sx={{
        ...(sx && sx),
        ...(decorateChildren && decorationSx),
      }}
      {...(props as unknown as T)}
      popperProps={
        {
          placement: 'auto',
          withArrow: true,
          withAnimation: true,
          withPortal: true,
          ...popperProps,
          content: <Tooltip {...tooltipProps} />,
        } as PopperProviderProps
      }
    />
  );
  ComponentWithTooltip.defaultProps = defaultProps;

  return ComponentWithTooltip;
}
