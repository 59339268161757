import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';
import { To } from 'history';
import _ from 'lodash';
import { useMutation } from 'react-fetching-library';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { workPositionsMapSelector } from 'state/employees';
import { PATH, TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { LinkButton } from 'components/ui/Buttons';
import { Icon } from 'components/Icon/Icon';
import { StickyListProps } from 'components/StickyList/types';
import { ModalRoute } from 'routing/ModalRoute';
import { WorkPosition } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Switch } from 'components/ui/Switch';
import { WorkPositionStateObject } from 'api/actions/workPositions/workPositionsActions.types';
import { setWorkPositionsStatesAction } from 'api/actions/workPositions/workPositionsActions';
import { TextEllipsis } from 'components/utils/TextEllipsis';

import { DeleteWorkPositionsModal } from './DeleteWorkPositions';
import { AddWorkPositionModal } from './AddWorkPosition';
import { EditWorkPositionModal } from './EditWorkPosition';
import { ManageModal } from './modalsElements/ManageModal';

const MANAGE_WORK_POSITIONS = 'MANAGE_WORK_POSITIONS';

export const ManageWorkPositionsModal = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const workPositions = useRecoilValue(workPositionsMapSelector);

  const [workPositionsWithChangedStatuses, setWorkPositionsWithChangedStatuses] = useState<WorkPositionStateObject[]>(
    [],
  );
  const workPositionsWithChangedStatusesRef = useRef<WorkPositionStateObject[]>([]);

  const { mutate } = useMutation(setWorkPositionsStatesAction);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setWorkPositionsStates = useCallback(
    _.debounce(() => {
      const workPositionsStates = workPositionsWithChangedStatusesRef.current;
      if (workPositionsStates.length) {
        workPositionsWithChangedStatusesRef.current = [];
        mutate({ workPositionsStates });
      }
    }, 500),
    [mutate],
  );

  useEffect(() => {
    setWorkPositionsStates();
  }, [workPositionsWithChangedStatuses, setWorkPositionsStates]);

  const handleSwitchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const workPositionState = {
      state: e.target.checked,
      id,
    };
    const filteredWorkPositionsWithChangedStatuses = workPositionsWithChangedStatusesRef.current.filter(
      ({ id: currentId }) => currentId !== id,
    );
    const newWorkPositionsWithChangedStatuses = [...filteredWorkPositionsWithChangedStatuses, workPositionState];
    workPositionsWithChangedStatusesRef.current = newWorkPositionsWithChangedStatuses;

    setWorkPositionsWithChangedStatuses(newWorkPositionsWithChangedStatuses);
  }, []);

  const listPropsGenerator = useCallback(
    (
      match: string,
      replaceMode: boolean,
      preferredHistoryMethod: (to: To, state?: unknown) => void,
    ): StickyListProps<WorkPosition> => ({
      name: MANAGE_WORK_POSITIONS,
      select: 'checkbox',
      showHeader: true,
      showContentPlaceholder: true,
      list: workPositions || new Map(),
      columns: [
        {
          title: t({ id: 'team.manage_work_pos.active', message: 'Active' }),
          key: 'isActive',
          width: '60px',
          customCellRenderer: (isActive: WorkPosition['isActive'], itemId) => (
            <Flex onClick={(e) => e.stopPropagation()}>
              <Switch
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSwitchChange(e, itemId);
                }}
                wrapperSx={{ display: 'block' }}
                size="sm"
                name="isActive"
                defaultChecked={isActive}
              />
            </Flex>
          ),
        },
        {
          title: t({ id: 'team.manage_work_pos.name', message: 'Name' }),
          key: 'name',
          sortableValue: (name: WorkPosition['name']) => `${name}`,
          customCellRenderer: (name: WorkPosition['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
        },
        {
          key: 'id',
          width: '34px',
          customCellRenderer: (item) => (
            <Flex onClick={(e) => e.stopPropagation()}>
              <LinkButton
                replace={replaceMode}
                to={{
                  pathname: `${match}${TO.DELETE_WORK_POSITIONS_MODAL[language]}`,
                  state: { ids: [item] },
                }}
                variant="minimal"
                size="sm"
                shape="rounded"
              >
                <Icon size={18} type="delete" />
              </LinkButton>
            </Flex>
          ),
        },
      ],
      onRowClick: (listItemId) => {
        preferredHistoryMethod(`${match}${TO.EDIT_WORK_POSITION_MODAL__ID[language]}/${listItemId}`);
      },
    }),
    [language, workPositions, handleSwitchChange],
  );

  const modalRoutesRenderer = useCallback(
    () => (
      <>
        <ModalRoute replaceMode size="sm" path={PATH.ADD_WORK_POSITION_MODAL[language]}>
          <AddWorkPositionModal />
        </ModalRoute>
        <ModalRoute replaceMode size="sm" path={PATH.EDIT_WORK_POSITION_MODAL__ID[language]}>
          <EditWorkPositionModal />
        </ModalRoute>
        <ModalRoute replaceMode size="xs" path={PATH.DELETE_WORK_POSITIONS_MODAL[language]}>
          <DeleteWorkPositionsModal />
        </ModalRoute>
      </>
    ),
    [language],
  );

  const modalLinksPaths = useMemo(
    () => ({
      add: TO.ADD_WORK_POSITION_MODAL[language],
      delete: TO.DELETE_WORK_POSITIONS_MODAL[language],
    }),
    [language],
  );

  return (
    <ManageModal
      allResources={workPositions}
      title={t({ id: 'team.manage_work_pos', message: 'Manage positions' })}
      listPropsGenerator={listPropsGenerator}
      modalRoutesRenderer={modalRoutesRenderer}
      modalLinksPaths={modalLinksPaths}
    />
  );
};
