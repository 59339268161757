import { Flex } from 'theme-ui';
import React, { Suspense, useCallback, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Checkbox } from 'components/ui/Checkbox';
import { rowSelectSelectorFamily, selectedRowsAtomFamily } from 'state/list';
import { Radio } from 'components/ui/Radio';

import { RowProps } from './types';
import { Cell } from './Cell';

const LazyRowContent = React.lazy(() =>
  import('./RowContent').then(({ RowContent }) => ({
    default: RowContent,
  })),
);

export const Row = ({
  onRowClick,
  select,
  columns,
  id,
  listName,
  data,
  variant,
  uncheckable,
  ...props
}: RowProps): React.ReactElement => {
  const [selected, setSelected] = useRecoilState(rowSelectSelectorFamily({ listName, itemId: id }));
  const selectSingleRow = useSetRecoilState(selectedRowsAtomFamily(listName));
  const rowRef = useRef<HTMLDivElement | null>(null);

  const selectSingleRowCallback = useCallback(() => {
    const newMap = new Map();
    if (!selected) {
      newMap.set(id, !selected);
    }
    selectSingleRow({ value: newMap });
  }, [id, selectSingleRow, selected]);

  const preventParentOnClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.checked);
  };

  const onRowClickCallback = useCallback(() => {
    if (onRowClick) {
      onRowClick(id);
    } else if (select === 'checkbox') {
      setSelected((prev) => !prev);
    } else if (select === 'radio') {
      selectSingleRowCallback();
    }
  }, [id, onRowClick, select, selectSingleRowCallback, setSelected]);

  return (
    <Flex
      ref={rowRef}
      onClick={onRowClickCallback}
      data-selected={uncheckable ? false : selected}
      data-non-editable={data.isNonEditable}
      data-non-checkable={uncheckable}
      variant={`stickyList.row.${variant}`}
      sx={{
        cursor: onRowClick || (!onRowClick && select) ? 'pointer' : 'default',
        ...(uncheckable && { bg: 'backgrounds.bg.levelMinusOne' }),
      }}
      {...props}
    >
      {select && (
        <Cell width="30px" onClick={preventParentOnClick}>
          {select === 'checkbox' ? (
            <Checkbox
              onChange={handleCheckboxChange}
              checked={uncheckable ? false : selected}
              name="rowCheckbox"
              size="sm"
              disabled={uncheckable}
            />
          ) : (
            <Radio
              onChange={selectSingleRowCallback}
              checked={uncheckable ? false : selected}
              name="rowRadio"
              size="sm"
              disabled={uncheckable}
            />
          )}
        </Cell>
      )}
      <Suspense fallback={<></>}>
        <LazyRowContent columns={columns} data={data} id={id} rowRef={rowRef} listName={listName} />
      </Suspense>
    </Flex>
  );
};

export const MemorizedRow = React.memo(Row);
