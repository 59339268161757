import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex, Heading } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';

import { Avatar } from 'components/Avatar/Avatar';
import { Button } from 'components/ui/Buttons/Button';
import { setNativeValue } from 'utils/setNativeValue';
import { createEvent } from 'utils/createEvent';
import { settingsPageDataSelector } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';
import {
  organizationSettingsAvatarAction,
  organizationSettingsDeleteAvatarAction,
} from 'api/actions/settings/settingsActions';
import { organizationDetailsSelector } from 'state/organizationSession';

export const OrganizationChangeAvatarForm = (): React.ReactElement => {
  useLingui();
  const { mutate: mutateAvatar } = useMutation(organizationSettingsAvatarAction);
  const { mutate: mutateDeleteAvatar } = useMutation(organizationSettingsDeleteAvatarAction);
  const settingsPageData = useRecoilValue(settingsPageDataSelector);
  const organizationDetails = useRecoilValue(organizationDetailsSelector);

  const [currentAvatarUrl, setCurrentAvatarUrl] = useState<string | null>(null);

  const formRef = useRef<HTMLFormElement | null>(null);
  const avatarInputRef = useRef<HTMLInputElement | null>(null);
  const avatarHasChangedRef = useRef<boolean>(false);
  const firstRenderRef = useRef<boolean>(true);

  const { handleSubmit } = useForm();

  const handleFormSubmit = () => {
    const submitEvent = createEvent('submit');
    formRef.current?.dispatchEvent(submitEvent);
  };

  const triggerAvatarInputClick = () => {
    const avatarInput = avatarInputRef.current;

    if (avatarInput) {
      avatarInput.click();
    }
  };

  const clearAvatarFile = async () => {
    const { error } = await mutateDeleteAvatar({});

    if (!error) {
      setCurrentAvatarUrl(null);
      setNativeValue(avatarInputRef, '');
      avatarHasChangedRef.current = true;
      addSnackbar({
        message: t({
          id: 'settings.avatar.delete_success',
        }),
        variant: 'success',
      });
    }

    if (error) {
      addSnackbar({
        message: t({
          id: 'settings.avatar.delete_danger',
        }),
        variant: 'danger',
      });
    }
  };

  const handleAvatarChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files && files[0]) {
      avatarHasChangedRef.current = true;
      setCurrentAvatarUrl(URL.createObjectURL(files[0] || null));
    }
  }, []);

  const handleSubmitCallback = useCallback(async () => {
    let avatarBlob: Blob | null = null;

    if (currentAvatarUrl && avatarHasChangedRef.current) {
      const avatarResp = await fetch(currentAvatarUrl);
      avatarBlob = await avatarResp.blob();
    }

    if (avatarBlob) {
      const { error } = await mutateAvatar({ avatar: avatarBlob });

      if (!error) {
        addSnackbar({
          message: t({
            id: 'settings.avatar.add_success',
          }),
          variant: 'success',
        });
      }

      if (error) {
        addSnackbar({
          message: t({
            id: 'settings.avatar.add_danger',
          }),
          variant: 'danger',
        });
      }
    }
  }, [currentAvatarUrl, mutateAvatar]);

  useEffect(() => {
    if (currentAvatarUrl && !firstRenderRef.current) {
      handleFormSubmit();
    }

    firstRenderRef.current = false;
  }, [currentAvatarUrl]);

  useEffect(() => {
    if (organizationDetails && organizationDetails.avatarUrl) {
      setCurrentAvatarUrl(organizationDetails.avatarUrl);
    }
  }, [organizationDetails]);

  return (
    <Flex sx={{ gap: 2, flexDirection: 'column', flexGrow: 1 }}>
      <Heading variant="heading4.withMargins">
        <Trans id="logo">Logo</Trans>
      </Heading>
      <form ref={formRef} onSubmit={handleSubmit(handleSubmitCallback)}>
        <input
          ref={avatarInputRef}
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleAvatarChange}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
        />
        <Flex sx={{ flexGrow: 1, gap: 3 }}>
          <Avatar
            size={124}
            image={currentAvatarUrl || undefined}
            name={
              settingsPageData
                ? { firstName: settingsPageData.organization.name[0], surname: settingsPageData.organization.name[1] }
                : undefined
            }
          />
          <Flex sx={{ gap: 1, flexDirection: 'column' }}>
            <Button
              variant="grey"
              size="sm"
              shape="rounded"
              onClick={triggerAvatarInputClick}
              sx={{ display: 'flex', width: '124px' }}
            >
              {currentAvatarUrl ? <Trans id="change">Change</Trans> : <Trans id="add">Add</Trans>}
            </Button>
            {currentAvatarUrl && (
              <Button
                variant="minimal"
                size="sm"
                shape="rounded"
                onClick={clearAvatarFile}
                sx={{ display: 'flex', width: '124px' }}
              >
                <Trans id="remove">Remove</Trans>
              </Button>
            )}
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
};

export const MemoizedOrganizationChangeAvatarForm = React.memo(OrganizationChangeAvatarForm);
