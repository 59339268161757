import React, { useState, useRef, useMemo } from 'react';
import { Label, Text } from 'theme-ui';
import { Trans } from '@lingui/macro';

import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { Icon } from 'components/Icon/Icon';

import { validateBlobFiles } from './helpers';
import { UploaderProps, StyleVariant } from './types';

// TODO: fn shared with Modal.tsx, can be abstracted to helper fn
const getInstanceNumber = (() => {
  let counter = 0;
  return () => {
    counter += 1;
    return counter;
  };
})();

export const Uploader = ({
  blobFiles,
  serverFiles,
  setBlobFiles,
  maxSize,
  minSize,
  acceptExt,
  maxFiles,
}: UploaderProps): React.ReactElement => {
  const [variant, setVariant] = useState<StyleVariant>('default');

  // remove bug: component flickers on dragover
  const dragOnLabelRef = useRef<EventTarget | null>(null);

  const { addSnackbar } = useSnackbar();

  const instanceNumber = useMemo(getInstanceNumber, []);

  const uploadFlow = (pickedBlobFilesFileList: FileList) => {
    const pickedBlobFiles = Array.from(pickedBlobFilesFileList);
    const validatedBlobFiles = validateBlobFiles({
      pickedFiles: pickedBlobFiles,
      existingFiles: blobFiles,
      serverFiles,
      addSnackbar,
      maxSize,
      minSize,
      acceptExt,
      maxFiles,
    });
    if (!validatedBlobFiles?.length) return;
    setBlobFiles((oldBlobFiles) => (oldBlobFiles ? [...oldBlobFiles, ...validatedBlobFiles] : validatedBlobFiles));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setVariant('default');
    const { target } = e;
    if (target.files) uploadFlow(target.files);
    // fix: onChange doesn't fire on same file re-upload attempt
    e.target.value = '';
  };

  const handleDragEnter = (e: React.DragEvent) => {
    dragOnLabelRef.current = e.target;
  };

  const handleFilesDragover = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setVariant('droppable');
  };

  const handleFilesDrop = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer === null) return;
    setVariant('default');
    uploadFlow(e.dataTransfer.files);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    if (dragOnLabelRef.current !== e.target) return;
    setVariant('default');
  };

  const iconType = variant === 'default' ? 'plus' : 'import';

  return (
    <Label
      htmlFor={`uploader-${instanceNumber}`}
      variant="fileUpload.uploader"
      data-state={variant}
      onDragEnter={handleDragEnter}
      onDragOver={handleFilesDragover}
      onDrop={handleFilesDrop}
      onDragLeave={handleDragLeave}
    >
      <input
        type="file"
        multiple
        onChange={handleInputChange}
        id={`uploader-${instanceNumber}`}
        style={{ width: 0, opacity: 0, position: 'absolute' }}
      />

      <Icon size={22} type={iconType} className="icon" wrapperSx={{ variant: 'fileUpload.icon' }} />
      <Text className="heading">
        <Trans id="file_upload.heading">
          <Text>Choose a file</Text>
          <Text> or </Text>
          <Text>drop it here</Text>
        </Trans>
      </Text>
    </Label>
  );
};
