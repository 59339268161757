import React from 'react';
import { Flex } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { useLingui } from '@lingui/react';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { ElementGroup } from 'components/ui/ElementGroup';
import { userDetailsSelector } from 'state/userSession';
import { DrawerMemoryRouter } from 'layouts/AuthorizedApp/Drawer/DrawerMemoryRouter';
import { withDot } from 'components/Dot/withDot';
import { DRAWER_ROUTES } from 'constants/memoryRoutes';
import { notificationCenterAtom } from 'state/notification';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { settingsPageDataSelector } from 'state/settings';
import { dateTime } from 'utils/dateTime';

import { PaymentTicker } from './PaymentTicker';
import { AvatarButton } from './AvatarButton';
import { IconLink } from './IconLink';
import { NavbarTimeEventTimer } from './NavbarTimeEventTimer';

const AvatarButtonWithDropdown = withDropdown(AvatarButton);
const IconLinkWithDot = withDot(IconLink);

export const SideMenu = React.memo((): React.ReactElement => {
  useLingui();
  const userDetails = useRecoilValue(userDetailsSelector);
  const settingsPageData = useRecoilValue(settingsPageDataSelector);
  const language = useRecoilValue(languageSelector);
  const notifications = useRecoilValue(notificationCenterAtom);

  const nextPaymentDate = dateTime(undefined).format('D MMM YYYY');

  return (
    <Flex
      sx={{
        px: [2, null, null, 3],
        alignItems: 'center',
        flexDirection: ['row-reverse', null, null, 'row'],
        justifyContent: ['space-between', null, null, 'initial'],
        flexGrow: [1, null, null, 0],
      }}
    >
      <Flex
        sx={{
          flexGrow: [1, null, null, 0],
          justifyContent: ['space-between', null, null, 'initial'],
          mr: [null, null, null, 2],
        }}
      >
        <PaymentTicker daysTillEnd={12} sx={{ mr: [null, null, null, 2] }} />
        <DrawerMemoryRouter>
          <NavbarTimeEventTimer to={DRAWER_ROUTES.DRAWER__TIME_TRACKER} sx={{ mr: 2 }} />
          <ElementGroup direction="row" showAsList>
            <IconLink
              to={DRAWER_ROUTES.DRAWER__ATTENDANCE_OVERVIEW}
              icon="attendance"
              variant="grey"
              shape="circle"
              iconProps={{ size: 26 }}
            />
            <IconLinkWithDot
              dotProps={{ color: 'red', placement: 'TOP_RIGHT' }}
              to={DRAWER_ROUTES.DRAWER__NOTIFICATION_CENTER}
              icon="notifications"
              variant="grey"
              shape="circle"
              withDot
              showDot={notifications.length > 0}
              iconProps={{ size: 26 }}
            />
          </ElementGroup>
        </DrawerMemoryRouter>
      </Flex>

      <AvatarButtonWithDropdown
        image={userDetails?.avatarUrl}
        name={userDetails?.name}
        popperProps={{
          withPopperState: true,
          hideOnReferenceHidden: false,
        }}
        dropdownProps={{
          links: [
            {
              label: i18n._(
                t({
                  id: 'navbar.side_menu.user.edit.label',
                  message: 'Profile',
                }),
              ),
              icon: 'account',
              to: TO.SETTINGS__USER[language],
            },
            {
              label: i18n._(
                t({
                  id: 'navbar.side_menu.user.preferences.label',
                  message: 'Preferences',
                }),
              ),
              icon: 'preferences',
              to: TO.SETTINGS__USER__PREFERENCES[language],
            },
            {
              label: i18n._(
                t({
                  id: 'navbar.side_menu.integrations',
                  message: 'Integrations',
                }),
              ),
              icon: 'integrations',
              to: TO.SETTINGS__INTEGRATIONS[language],
            },
            {
              prependWithDivider: true,
              label: i18n._(
                t({
                  id: 'navbar.side_menu.organization.settings.label',
                  message: 'Settings',
                }),
              ),
              additionalInfo: settingsPageData?.organization.name,
              icon: 'settings',
              to: TO.SETTINGS__ORGANIZATION[language],
            },
            {
              label: i18n._(
                t({
                  id: 'navbar.side_menu.organization.billing.label',
                  message: 'Plan and billing',
                }),
              ),
              additionalInfo: i18n._(
                t({
                  id: 'navbar.side_menu.organization.billing.additional_info',
                  message: `Next payment on ${nextPaymentDate}`,
                }),
              ),
              icon: 'card',
              to: '/p14',
            },
            {
              prependWithDivider: true,
              label: i18n._(
                t({
                  id: 'navbar.side_menu.user.sign_out',
                  message: 'Sign out',
                }),
              ),
              icon: 'signOut',
              to: TO.SIGN_OUT[language],
            },
          ],
        }}
      />
    </Flex>
  );
});
