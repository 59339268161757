import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { t, plural, Trans, Plural } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { rolesMapSelector } from 'state/employees';
import { deleteRolesAction } from 'api/actions/roles/rolesActions';

import { DeleteDeactivateModal } from './modalsElements/DeleteDeactivateModal';

export const DeleteRolesModal = (): React.ReactElement => {
  useLingui();
  const roles = useRecoilValue(rolesMapSelector);
  const titleRenderer = useCallback(
    (count: number, name: string) =>
      t({
        id: 'team.delete_roles',
        message: plural(count, { one: `Delete role - ${name}?`, other: 'Delete roles?' }),
      }),
    [],
  );

  const contentRenderer = useCallback(
    (count: number, selectedNames: string[]) => (
      <Trans id="team.delete_roles.desc">
        <Text>
          You're about to permanently delete{' '}
          <Plural
            value={count}
            one="this role and its settings."
            other={
              <Trans>
                roles: <strong>{selectedNames.join(', ')}.</strong>
              </Trans>
            }
          />{' '}
          <strong>This action cannot be reversed.</strong>
        </Text>

        <Text>
          All users that were included in <Plural value={count} one="this role" other="these roles" /> will be switched
          to basic role - Employee.
        </Text>
      </Trans>
    ),
    [],
  );

  return (
    <DeleteDeactivateModal
      list={roles}
      namePath="name"
      action={deleteRolesAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
    />
  );
};
