import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { useHistory, useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { Flex } from 'theme-ui';

import { ReactComponent as EmployerIcon } from 'layouts/Authentication/svg/employer.svg';
import { ReactComponent as EmployeeIcon } from 'layouts/Authentication/svg/employee.svg';
import { languageSelector } from 'state/recoilState';
import { TO } from 'constants/routes';
import { Icon } from 'components/Icon/Icon';
import { Main, AccountTypeCard, FormCard } from 'layouts/Authentication';
import { Button } from 'components/ui/Buttons/Button';
import { APP_NAME } from 'constants/common';

export const AccountType = (): React.ReactElement => {
  const { state } = useLocation();

  const [accountType, setAccountType] = useState<null | 'employer' | 'employee'>(null);

  const language = useRecoilValue(languageSelector);

  const history = useHistory();

  const handleClick = () => {
    switch (accountType) {
      case 'employer':
        history.push(`${TO.SIGN_UP__EMPLOYER[language]}`, { signUp: { inProgress: true } });
        return;
      case 'employee':
        history.push(TO.SIGN_UP__EMPLOYEE__NOT_INVITED[language], { signUp: { inProgress: true } });
        break;
      default:
    }
  };

  if (!state?.signUp?.inProgress) {
    return <Redirect to={TO.SIGN_UP[language]} />;
  }

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'sing_up.page_title', message: `Sign up - ${APP_NAME}` }))}</title>
      </Helmet>

      <Main>
        <FormCard size="md">
          <FormCard.Header>
            <FormCard.Title>
              <Trans id="sign_up.account_type.lead.header">What type of account do you want to create?</Trans>
            </FormCard.Title>
          </FormCard.Header>

          <Flex sx={{ flexDirection: ['column', null, 'row'], gap: 3 }}>
            <AccountTypeCard
              active={accountType === 'employer'}
              appendWith={<EmployerIcon />}
              onClick={() => {
                setAccountType('employer');
              }}
              title={i18n._(
                t({
                  id: 'sign_up.account_type.card.employer.title',
                  message: 'Are you an employer?',
                }),
              )}
              message={i18n._(
                t({
                  id: 'sign_up.account_type.card.employer.message',
                  message: 'I want to create a new company account.',
                }),
              )}
            />
            <AccountTypeCard
              active={accountType === 'employee'}
              appendWith={<EmployeeIcon />}
              onClick={() => {
                setAccountType('employee');
              }}
              title={i18n._(
                t({
                  id: 'sign_up.account_type.card.employee.title',
                  message: 'Are you an employee?',
                }),
              )}
              message={i18n._(
                t({
                  id: 'sign_up.account_type.card.employee.message',
                  message: 'I want to join a company account.',
                }),
              )}
            />
          </Flex>

          <FormCard.Footer sx={{ justifyContent: 'flex-end' }}>
            <Button
              disabled={!accountType}
              variant="primary"
              apendWith={<Icon type="arrowRight" />}
              size="lg"
              shape="rounded"
              type="submit"
              onClick={() => {
                handleClick();
              }}
            >
              <Trans id="global.forms.buttons.next">Next</Trans>
            </Button>
          </FormCard.Footer>
        </FormCard>
      </Main>
    </>
  );
};
