import { t, Trans } from '@lingui/macro';
import React from 'react';
import { useMutation } from 'react-fetching-library';
import { Heading } from 'theme-ui';
import { useLingui } from '@lingui/react';

import { userChangePasswordAction } from 'api/actions/settings/settingsActions';
import { ChangePasswordForm } from 'layouts/Settings/User/forms/ChangePasswordForm';
import { UserChangePasswordSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

export const ChangePassword = (): React.ReactElement => {
  useLingui();
  const { mutate } = useMutation(userChangePasswordAction);

  const { addSnackbar } = useSnackbar();

  const onUserChangePasswordSubmit = async (body: UserChangePasswordSettingsActionProps) => {
    const { error: submitError } = await mutate(body);

    if (!submitError) {
      addSnackbar({
        message: t({
          id: 'settings.forms.submit_success',
        }),
        variant: 'success',
      });
      return true;
    }

    addSnackbar({
      message: t({
        id: 'settings.forms.submit_fail',
      }),
      variant: 'danger',
    });
    return false;
  };

  return (
    <>
      <Heading variant="heading3">
        <Trans id="settings.user.change_password.header">Change password</Trans>
      </Heading>
      <ChangePasswordForm onSubmit={onUserChangePasswordSubmit} />
    </>
  );
};
