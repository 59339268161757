import React, { useMemo } from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

type Props = Partial<{
  borderColor: 'primary' | 'secondary';
  sx: ThemeUIStyleObject;
  axis: 'vertical' | 'horizontal';
}>;

export const Divider = React.memo(({ borderColor = 'primary', sx, axis = 'horizontal' }: Props): React.ReactElement => {
  const prepareDividerStyle = useMemo(
    () =>
      ((): ThemeUIStyleObject => {
        switch (axis) {
          case 'vertical':
            return {
              height: '100%',
              borderLeft: '1px solid',
              borderLeftColor: `dropdown.dividerColors.${borderColor}`,
            };

          default:
            return {
              width: '100%',
              borderTop: '1px solid',
              borderTopColor: `dropdown.dividerColors.${borderColor}`,
            };
        }
      })(),
    [axis, borderColor],
  );

  return (
    <Flex
      sx={{
        ...prepareDividerStyle,
        ...(sx && sx),
      }}
    />
  );
});
