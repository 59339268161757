import { useContext } from 'react';

import { AuthDispatchContextProps } from '../../context/auth/authContext/AuthContext.types';
import { AuthDispatchContext } from '../../context/auth/authContext/AuthContext';

export const useAuthDispatch: () => AuthDispatchContextProps = () => {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within an AuthContextController');
  }

  return context;
};
