import React from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { Badge, BadgeProps } from 'components/Badge/Badge';
import { RequestHistoryState } from 'api/actions/requests/requestsActions.types';

type Props = {
  requestState: RequestHistoryState;
} & Omit<BadgeProps, 'variant' | 'icon' | 'title' | 'children'>;

export const RequestHistoryStatusBadge = ({ requestState, ...props }: Props): React.ReactElement => {
  const variant = ((): BadgeProps['variant'] => {
    switch (requestState) {
      case RequestHistoryState.Accepted:
        return 'success';

      case RequestHistoryState.Rejected:
        return 'danger';

      case RequestHistoryState.Deleted:
        return 'warning';

      case RequestHistoryState.FirstOutOfTwoAcceptance:
      case RequestHistoryState.Created:
      default:
        return 'default';
    }
  })();

  const stateMessage = (() => {
    switch (requestState) {
      case RequestHistoryState.Accepted:
        return i18n._(
          t({
            id: 'request.history_state.accepted',
            message: 'Accepted',
          }),
        );

      case RequestHistoryState.Rejected:
        return i18n._(
          t({
            id: 'request.history_state.rejected',
            message: 'Rejected',
          }),
        );

      case RequestHistoryState.Deleted:
        return i18n._(
          t({
            id: 'request.history_state.deleted',
            message: 'Deleted',
          }),
        );
      case RequestHistoryState.FirstOutOfTwoAcceptance:
        return i18n._(
          t({
            id: 'request.history_state.firstOutOfTwoAcceptance',
            message: '1/2 APPROVED',
          }),
        );
      case RequestHistoryState.Created:
        return i18n._(
          t({
            id: 'request.history_state.created',
            message: 'Created',
          }),
        );
      default:
        return '';
    }
  })();

  return (
    <Badge variant={variant} title={stateMessage} {...props}>
      {stateMessage}
    </Badge>
  );
};
