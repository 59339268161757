export const focusInput = (
  refToFocus: React.MutableRefObject<HTMLInputElement | null>,
  selectionStart?: number,
  selectionEnd?: number,
): void => {
  if (selectionStart !== undefined && selectionEnd !== undefined) {
    refToFocus?.current?.setSelectionRange(selectionStart, selectionEnd);
  }
  refToFocus?.current?.focus();
};
