import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  AdvancedSettingsActionProps,
  AdvancedSettingsDeleteFaceModelsActionProps,
  AdvancedSettingsDeleteFaceModelsResponse,
  AdvancedSettingsDeleteOrganizationActionProps,
  AdvancedSettingsDeleteOrganizationResponse,
  AdvancedSettingsDeletePhotosActionProps,
  AdvancedSettingsDeletePhotosResponse,
  BasicInformationSettingsActionProps,
  BusinessTripSettingsActionProps,
  CustomSettingsActionProps,
  FetchNotificationSettingsResponse,
  FetchOrganizationSettingsResponse,
  FetchRequestsSettingsResponse,
  FetchScheduleSettingsResponse,
  FetchTimeTrackingSettingsResponse,
  FetchUserInfoResponse,
  FetchUserNotificationsSettingsResponse,
  NotificationSettingsActionProps,
  NotificationSettingsResponse,
  OrganizationHolidaysSettingsActionProps,
  OrganizationSettingsActionProps,
  OrganizationSettingsAddChangeAvatarActionProps,
  OrganizationSettingsAddChangeAvatarResponse,
  OrganizationSettingsDeleteAvatarResponse,
  OrganizationSettingsResponse,
  OvertimeSettingsActionProps,
  PayPeriodSettingsActionProps,
  PreferencesSettingsActionProps,
  RequestsSettingsActionProps,
  RequestsSettingsResponse,
  RequestsTimeTrackingSettingsActionProps,
  ScheduleSettingsActionProps,
  ScheduleSettingsResponse,
  TimeClocksSettingsActionProps,
  TimeOffSettingsActionProps,
  TimeTrackingSettingsActionProps,
  TimeTrackingSettingsResponse,
  UserChangePasswordSettingsActionProps,
  UserChangePasswordSettingsResponse,
  UserInfoActionProps,
  UserInfoChangeAvatarActionProps,
  UserInfoChangeAvatarResponse,
  UserInfoDeleteAvatarResponse,
  UserInfoResponse,
  UserNotificationsSettingsActionProps,
  UserNotificationsSettingsResponse,
  UserPreferencesActionProps,
  UserPreferencesResponse,
} from './settingsActions.types';

export function fetchSchedulesAction(): Action<FetchScheduleSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.schedulesSettings,
  };
}

export function scheduleSettingsAction(body: ScheduleSettingsActionProps): Action<ScheduleSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.schedulesSettings,
    body,
  };
}

export function userNotificationsSettingsAction(
  body: UserNotificationsSettingsActionProps,
): Action<UserNotificationsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userNotifications,
    body,
  };
}

export function fetchNotificationSettingsAction(): Action<FetchNotificationSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.notificationSettings,
  };
}

export function notificationSettingsAction(
  body: NotificationSettingsActionProps,
): Action<NotificationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.notificationSettings,
    body,
  };
}

export function fetchUserNotificationsSettingsAction(): Action<FetchUserNotificationsSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.userNotifications,
  };
}

export function userChangePasswordAction(
  body: UserChangePasswordSettingsActionProps,
): Action<UserChangePasswordSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userChangePassword,
    body,
  };
}

export function advancedSettingsDeletePhotosAction(
  body: AdvancedSettingsDeletePhotosActionProps,
): Action<AdvancedSettingsDeletePhotosResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.advancedOrganizationSettingsDeletePhotos,
    body,
  };
}

export function advancedSettingsDeleteFaceModelsAction(
  body: AdvancedSettingsDeleteFaceModelsActionProps,
): Action<AdvancedSettingsDeleteFaceModelsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.advancedOrganizationSettingsDeleteFaceModels,
    body,
  };
}

export function advancedSettingsDeleteOrganizationAction(
  body: AdvancedSettingsDeleteOrganizationActionProps,
): Action<AdvancedSettingsDeleteOrganizationResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.advancedOrganizationSettingsDeleteOrganization,
    body,
  };
}

export function fetchTimeTrackingSettingsAction(): Action<FetchTimeTrackingSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.timeTrackingSettings,
  };
}

export function timeTrackingSettingsAction(
  body: TimeTrackingSettingsActionProps,
): Action<TimeTrackingSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeTrackingSettings,
    body,
  };
}

export function timeClocksSettingsAction(body: TimeClocksSettingsActionProps): Action<TimeTrackingSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeTrackingSettings,
    body,
  };
}

export function overtimeSettingsAction(body: OvertimeSettingsActionProps): Action<TimeTrackingSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeTrackingSettings,
    body,
  };
}

export function payPeriodSettingsAction(body: PayPeriodSettingsActionProps): Action<TimeTrackingSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeTrackingSettings,
    body,
  };
}

export function advancedSettingsAction(body: AdvancedSettingsActionProps): Action<TimeTrackingSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeTrackingSettings,
    body,
  };
}

export function userPreferencesAction(body: UserPreferencesActionProps): Action<UserPreferencesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userPreferences,
    body,
  };
}

export function fetchOrganizationSettingsAction(): Action<FetchOrganizationSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.organizationSettings,
  };
}

export function organizationSettingsAction(
  body: OrganizationSettingsActionProps,
): Action<OrganizationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettings,
    body,
  };
}

export function basicInformationSettingsAction(
  body: BasicInformationSettingsActionProps,
): Action<OrganizationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettings,
    body,
  };
}

export function preferencesSettingsAction(body: PreferencesSettingsActionProps): Action<OrganizationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettings,
    body,
  };
}

export function organizationHolidaysSettingsAction(
  body: OrganizationHolidaysSettingsActionProps,
): Action<OrganizationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettings,
    body,
  };
}

export function organizationSettingsAvatarAction({
  avatar,
}: OrganizationSettingsAddChangeAvatarActionProps): Action<OrganizationSettingsAddChangeAvatarResponse> {
  const formData = new FormData();

  formData.append('avatar', avatar);

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettingsChangeAvatar,
    body: formData,
  };
}

export function organizationSettingsDeleteAvatarAction(): Action<OrganizationSettingsDeleteAvatarResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettingsDeleteAvatar,
  };
}

export function fetchRequestsSettingsAction(): Action<FetchRequestsSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.requestSettings,
  };
}

export function requestsSettingsAction(body: RequestsSettingsActionProps): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
  };
}

export function timeOffSettingsAction(body: TimeOffSettingsActionProps): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
  };
}

export function requestsTimeTrackingSettingsAction(
  body: RequestsTimeTrackingSettingsActionProps,
): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
  };
}

export function businessTripSettingsAction(body: BusinessTripSettingsActionProps): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
  };
}

export function customSettingsAction(body: CustomSettingsActionProps): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
  };
}

export function fetchUserInfoAction(): Action<FetchUserInfoResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.userInfo,
  };
}

export function userInfoAction(body: UserInfoActionProps): Action<UserInfoResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userInfo,
    body,
  };
}

export function userInfoChangeAvatarAction({
  avatar,
}: UserInfoChangeAvatarActionProps): Action<UserInfoChangeAvatarResponse> {
  const formData = new FormData();

  formData.append('avatar', avatar);

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userInfoChangeAvatar,
    body: formData,
  };
}

export function userInfoDeleteAvatarAction(): Action<UserInfoDeleteAvatarResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userInfoDeleteAvatar,
  };
}
