/* eslint-disable no-restricted-syntax */

import { MutableSnapshot, RecoilRootProps, RecoilState } from 'recoil';

import { SupportedLanguages } from 'constants/supportedLanguages';
import { FetchUserSessionResponse } from 'api/actions/userSession/userSessionActions.types';
import { NotificationOwnProps } from 'components/Notification/types';
import { AccountInfoAbout, AccountInfoCompany } from 'api/actions/user/userActions.types';
import { settingsAtom as kioskSettingsAtom } from 'Kiosk/state/settingsState';

import { chatWindowIdsAtom } from './chat';
import { isLoggedInAtom, languageSelector } from './recoilState';
import { signUpFormAtom } from './signUp';
import { userSessionAtom } from './userSession';
import { notificationCenterAtom } from './notification';
import { sortingStateAtomFamily } from './list';
import { dateRangeFilterAtom } from './filters';

type LookUpAtomWithKey =
  | RecoilState<FetchUserSessionResponse | null>
  | RecoilState<boolean>
  | RecoilState<keyof typeof SupportedLanguages>
  | RecoilState<NotificationOwnProps[]>
  | RecoilState<Partial<AccountInfoAbout & AccountInfoCompany> | null>
  | RecoilState<string[]>
  | typeof kioskSettingsAtom
  | typeof dateRangeFilterAtom
  | undefined;

function lookupAtomWithKey(keyProp: RecoilState<unknown>['key']): LookUpAtomWithKey {
  const atomsList = [
    userSessionAtom,
    isLoggedInAtom,
    signUpFormAtom,
    languageSelector,
    notificationCenterAtom,
    chatWindowIdsAtom,
    dateRangeFilterAtom,
    kioskSettingsAtom,
  ];

  for (const atom of atomsList) {
    if (atom.key === keyProp) {
      return atom;
    }
  }

  return undefined;
}

function lookupAtomFamilyWithKey(keyProp: string) {
  const atomFamilyList = [{ atomFamily: sortingStateAtomFamily, prefix: 'sortingState__' }];

  for (const atomFamilyMeta of atomFamilyList) {
    if (keyProp.includes(atomFamilyMeta.prefix)) {
      return atomFamilyMeta.atomFamily;
    }
  }

  return undefined;
}

export const stateInitializer: RecoilRootProps['initializeState'] = ({ set }: MutableSnapshot) => {
  for (const [key, value] of Object.entries(localStorage)) {
    const currentAtom = lookupAtomWithKey(key);
    if (currentAtom) {
      set(currentAtom as RecoilState<unknown>, JSON.parse(value).value);
    }

    const currentAtomFamily = lookupAtomFamilyWithKey(key);
    if (currentAtomFamily) {
      const splittedKey = key.split('"');
      const atomName = splittedKey[splittedKey.length - 2];
      set(currentAtomFamily(atomName) as RecoilState<unknown>, JSON.parse(value));
    }
  }
};
