/** @jsxImportSource theme-ui */

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Webcam, { WebcamProps } from 'react-webcam';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ThemeUIStyleObject } from 'theme-ui';

import { windowSizeAtom } from 'state/recoilState';
import { mergeRefs } from 'utils/mergeRefs';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { nightModeSelector } from 'Kiosk/state/kioskState';
import {
  initialFaceScanInPlaceAtom,
  initialFaceScanStepAtom,
  InitialFaceScanSteps,
} from 'Kiosk/state/initialFaceScanState';
import { usePermissions } from 'Kiosk/hooks/usePermissions';

interface VideoConstraints {
  width: number;
  height: number;
  facingMode: string;
  ratio?: number;
  frameRate?: {
    ideal: number;
    min: number;
  };
}

type Props = {
  onUserMediaCallback?: WebcamProps['onUserMedia'];
  onUserMediaErrorCallback?: WebcamProps['onUserMediaError'];
};

const defaultProps = {
  onUserMediaCallback: undefined,
  onUserMediaErrorCallback: undefined,
};

export const Camera = React.forwardRef<Webcam, Props>(
  ({ onUserMediaCallback, onUserMediaErrorCallback }: Props, ref): React.ReactElement => {
    const cameraRef = useRef<Webcam>(null);

    const { type: overlay } = useRecoilValue(overlayStateAtom);
    const step = useRecoilValue(initialFaceScanStepAtom);
    const isFaceInPlace = useRecoilValue(initialFaceScanInPlaceAtom);
    const isNightMode = useRecoilValue(nightModeSelector);
    const { isLandscape, aspectRatio, isMobile } = useRecoilValue(windowSizeAtom);

    const [cameraState, setCameraState] = useRecoilState(cameraStateAtom);
    const { isCameraReady, isCameraStreaming } = cameraState;

    const { showCameraRequiredSplash } = usePermissions();

    const constraints: VideoConstraints = useMemo(() => {
      let frameRate;
      if (navigator.mediaDevices && typeof navigator.mediaDevices.getSupportedConstraints === 'function') {
        const supported = navigator.mediaDevices.getSupportedConstraints();
        if (supported.frameRate) {
          frameRate = {
            ideal: 25,
            min: 10,
          };
        }
      }

      if (isMobile) {
        return {
          width: 640,
          height: 480,
          facingMode: 'user',
          frameRate,
        };
      }

      return {
        width: isLandscape ? 640 : 480,
        height: isLandscape ? 480 : 640,
        ratio: aspectRatio,
        facingMode: 'user',
        frameRate,
      };
    }, [aspectRatio, isLandscape, isMobile]);

    //
    // STYLES
    //

    const generateSize = useCallback(
      (percentage: number) => ({
        ...(!isLandscape
          ? {
              width: 'auto',
              height: `${(percentage * 9) / 16}vw`,
              minWidth: `${(percentage * 16) / 9}vh`,
              minHeight: `${percentage}%`,
            }
          : {
              width: `${(percentage * 16) / 9}vh`,
              height: 'auto',
              minWidth: `${percentage}%`,
              minHeight: `${(percentage * 9) / 16}vw`,
            }),
      }),
      [isLandscape],
    );

    // see: https://stackoverflow.com/questions/10797632/simulate-background-sizecover-on-video-or-img
    const webcamSx: ThemeUIStyleObject = useMemo(
      () => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) scaleX(-1) translateZ(0)',
        ...generateSize(100),
        zIndex: -3,
      }),
      [generateSize],
    );

    const webcamCanvasWrapperSx: ThemeUIStyleObject = useMemo(
      () => ({
        '--animation-time': '.25s',
        '--shadow-color': 'rgba(14, 23, 55, .2)',
        // ...generateSize(100),
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        overflow: 'hidden',
        boxShadow: '0 4px 4px var(--shadow-color), 0 8px 24px var(--shadow-color), 0 1px 2px var(--shadow-color)',
        borderRadius: 0,
        transition: 'all var(--animation-time) ease-in-out',
        transform: 'scale(1)',
        zIndex: -3,
        '& > video': {
          zIndex: -3,
        },
        '&::after': {
          content: '""',
          display: 'block',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: 'fixed',
          zIndex: -1000,
          background: 'black',
          transition: 'background var(--animation-time) ease-in-out',
          transform: 'translateZ(0)',
        },
        '&[data-initial-face-scan="true"]': {
          overflow: 'visible',
          transform: 'scale(0.8)',
          transition: 'all .35s ease-in-out',
          marginTop: 'auto',
        },
        '&[data-night-mode="true"]:not([data-initial-face-scan="true"])': {
          transform: 'scale(0.7)',
          borderRadius: 'xl',
          '@media (min-height: 400px)': {
            top: '10%',
          },
          '&::after': {
            backgroundColor: 'white',
            borderRadius: 'xl',
          },
        },
      }),
      [],
    );

    //
    // EVENTS
    //

    const toggleVideoPlayback = (track: MediaStreamTrack, video?: HTMLVideoElement | null, state?: boolean) => {
      const isStreaming = state || !track.enabled;
      track.enabled = isStreaming;
      setCameraState((prevState) => ({ ...prevState, isCameraStreaming: isStreaming }));

      if (video) {
        if (isStreaming) {
          video.play();
        } else {
          video.pause();
        }
      }
    };

    const onUserMedia: WebcamProps['onUserMedia'] = (stream) => {
      if (cameraRef.current && cameraRef.current.video) {
        setCameraState({
          isCameraReady: true,
          isCameraStreaming: false,
          toggleVideoPlayback: (state) =>
            toggleVideoPlayback(stream.getVideoTracks()[0], cameraRef?.current?.video, state),
          source: {
            video: cameraRef.current.video,
            stream: stream.getVideoTracks()[0],
            height: cameraRef.current.video.videoHeight,
            width: cameraRef.current.video.videoWidth,
          },
        });
      }

      if (onUserMediaCallback) onUserMediaCallback(stream);
    };

    const onUserMediaError: WebcamProps['onUserMediaError'] = (error) => {
      if (isCameraReady || isCameraStreaming) {
        setCameraState({
          isCameraReady: false,
          isCameraStreaming: false,
        });
      }

      if (onUserMediaErrorCallback) onUserMediaErrorCallback(error);

      showCameraRequiredSplash();
    };

    //
    //
    //

    useEffect(() => {
      const camera = cameraRef.current;

      function handleLoadmetadata() {
        if (camera && camera.video) {
          const { video } = camera;
          const { videoHeight, videoWidth } = video;

          setCameraState((prevState) => ({
            ...prevState,
            isCameraStreaming: true,
            ...(prevState.source && {
              source: {
                ...prevState.source,
                video,
                width: videoWidth,
                height: videoHeight,
              },
            }),
          }));
        }
      }

      camera?.video?.addEventListener('loadedmetadata', () => handleLoadmetadata());
      //   camera?.video?.addEventListener('play', () => console.log('play'));
      //   camera?.video?.addEventListener('playing', () => console.log('playing'));

      return () => camera?.video?.removeEventListener('loadedmetadata', () => handleLoadmetadata());
    }, [setCameraState]);

    return (
      <div
        data-initial-face-scan={
          overlay === KioskOverlay.initialFaceScan && step === InitialFaceScanSteps.SCAN && isFaceInPlace
        }
        data-night-mode={isNightMode && overlay === KioskOverlay.start}
        sx={webcamCanvasWrapperSx}
      >
        <Webcam
          onUserMedia={onUserMedia}
          onUserMediaError={onUserMediaError}
          ref={mergeRefs([ref, cameraRef])}
          sx={webcamSx}
          audio={false}
          videoConstraints={constraints}
          forceScreenshotSourceSize={false}
          imageSmoothing
          mirrored={false}
          screenshotFormat="image/webp"
          screenshotQuality={0.92}
        />
      </div>
    );
  },
);

Camera.defaultProps = defaultProps;
