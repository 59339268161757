import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { DateRangeBadge } from './DateRangeBadge';
import { DeselectMenu } from './DeselectMenu';
import { FetchingIndicator } from './FetchingIndicator';
import { Menu } from './Menu';
import { Title } from './Title';

type HeaderComponent = React.FC<HeaderProps> & {
  Title: typeof Title;
  Menu: typeof Menu;
  Deselect: typeof DeselectMenu;
  DateRangeBadge: typeof DateRangeBadge;
  FetchingIndicator: typeof FetchingIndicator;
};

type HeaderProps = FlexProps;

export const Header: HeaderComponent = ({ children, sx, ...props }: HeaderProps): React.ReactElement => (
  <Flex {...props} as="header" sx={{ alignItems: 'center', pt: 4, gap: 2, ...(sx && sx) }}>
    {children}
  </Flex>
);

Header.Title = Title;
Header.Menu = Menu;
Header.Deselect = DeselectMenu;
Header.DateRangeBadge = DateRangeBadge;
Header.FetchingIndicator = FetchingIndicator;
