import { Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { addRequestAction } from 'api/actions/requests/requestsActions';
import { AddRequestActionProps, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useModal } from 'hooks/useModal/useModal';
import { getRequestsAtom, selectedEmployeesAddRequestsIdsSelector } from 'state/requests';
import { createEvent } from 'utils/createEvent';
import { selectAllAtomFamily, selectedRowsAtomFamily } from 'state/list';
import { ADD_REQUEST_PICK_TEAMMATES_LIST } from 'layouts/Requests/constans';

import { AddRequestStep1 } from './AddRequestStep1';
import { AddRequestStep2 } from './AddRequestStep2';
import { AddRequestStep3 } from './AddRequestStep3/AddRequestStep3';

enum Step {
  SelectTeammates = 1,
  SelectType = 2,
  Forms = 3,
}

export const AddRequestModal = (): React.ReactElement => {
  const { handleClose } = useModal();
  const selectedUsers = useRecoilValue(selectedEmployeesAddRequestsIdsSelector);
  const getRequests = useRecoilValue(getRequestsAtom);
  const resetSelectedEmployees = useResetRecoilState(selectedRowsAtomFamily(ADD_REQUEST_PICK_TEAMMATES_LIST));
  const resetAllSelectedEmployees = useResetRecoilState(selectAllAtomFamily(ADD_REQUEST_PICK_TEAMMATES_LIST));
  const { mutate: mutateAddRequest } = useMutation(addRequestAction);

  const formRef = useRef<HTMLFormElement | null>(null);

  const [requestType, setRequestType] = useState<RequestFormType>(RequestFormType.TimeOff);
  const [step, setStep] = useState<Step>(Step.SelectTeammates);
  const [loading, setLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const nextStep = useCallback(() => setStep((prev) => prev + 1), []);
  const prevStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => setStep((prev) => prev - 1), []);

  const submitForm = useCallback(() => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  }, []);

  const onSubmit = useCallback(
    async (data: AddRequestActionProps): Promise<void> => {
      const optionalStepProcessedById = data.optionalStepProcessedById?.length
        ? data.optionalStepProcessedById
        : undefined;
      const processedById = data.processedById?.length ? data.processedById : undefined;

      if (_.isNumber(requestType) && !_.isEmpty(selectedUsers)) {
        const dataObject = {
          ...data,
          type: requestType,
          peopleIds: selectedUsers,
          processedById,
          optionalStepProcessedById,
        };
        const { error: submitError } = await mutateAddRequest(dataObject);

        if (!submitError) {
          if (getRequests) await getRequests();
          handleClose();
        }
        setLoading(false);
      }
    },
    [getRequests, handleClose, mutateAddRequest, requestType, selectedUsers],
  );

  const handleSave = useCallback(() => {
    setLoading(true);
    submitForm();
  }, [submitForm]);

  useEffect(() => {
    if (step === Step.Forms) setIsSaveDisabled(true);
  }, [step]);

  useEffect(
    () => () => {
      resetSelectedEmployees();
      resetAllSelectedEmployees();
    },
    [resetAllSelectedEmployees, resetSelectedEmployees],
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="requests.add_request">Add request</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ pt: 0, gap: 2 }}>
        {step === Step.SelectTeammates && <AddRequestStep1 />}
        {step === Step.SelectType && <AddRequestStep2 requestType={requestType} setRequestType={setRequestType} />}
        {step === Step.Forms && _.isNumber(requestType) && (
          <AddRequestStep3
            ref={formRef}
            requestType={requestType}
            onSubmit={onSubmit}
            setLoading={setLoading}
            setIsSaveDisabled={setIsSaveDisabled}
          />
        )}
      </Modal.Body>

      <BasicModalFooter
        prependWith={
          <Text sx={{ fontSize: 2 }}>
            <Trans id="add_request.step">Step {step} of 3</Trans>
          </Text>
        }
        backButtonOnClick={step === Step.SelectType || step === Step.Forms ? prevStep : undefined}
        buttons={[
          {
            isLoading: loading,
            disabled:
              !selectedUsers[0] ||
              (step === Step.SelectType && requestType === undefined) ||
              (isSaveDisabled && step === Step.Forms),
            variant: step === Step.Forms ? 'success' : 'primary',
            onClick: step === Step.Forms ? handleSave : nextStep,
            sx: step === Step.Forms ? {} : { pr: 2 },
            children: (
              <>
                {step === Step.Forms ? (
                  <Trans id="save">Save</Trans>
                ) : (
                  <>
                    <Trans id="global.forms.buttons.next" />
                    <Icon wrapperSx={{ ml: 3 }} type="arrowRight" />
                  </>
                )}
              </>
            ),
          },
        ]}
      />
    </>
  );
};
