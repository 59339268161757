import { useCallback, useRef, useState } from 'react';
import _ from 'lodash';

import { createEvent } from 'utils/createEvent';
import { setNativeValue } from 'utils/setNativeValue';
import { silentSetValue } from 'utils/silentSetValue';
import { useFieldErrorDispatcher } from 'hooks/useFieldErrorDipatcher/useFieldErrorDispatcher';

import { DurationPickerOwnProps, InputNames } from './types';
import { getInputsValuesFromSeconds, getSecondsFromHours, getSecondsFromMinutes } from './utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDurationPickerLogic = ({
  minDuration,
  maxDuration,
  hours,
  minutes,
  seconds,
  onValidError,
  onClearError,
}: DurationPickerOwnProps) => {
  const [durationPickerError, setDurationPickerError] = useState(false);
  const [durationPickerErrorMessage, setDurationPickerErrorMessage] = useState<string | undefined>(undefined);

  const hiddenRef = useRef<HTMLInputElement | null>(null);

  const hoursRef = useRef<HTMLInputElement | null>(null);
  const minutesRef = useRef<HTMLInputElement | null>(null);
  const secondsRef = useRef<HTMLInputElement | null>(null);

  const dispatchBlurEvent = () => {
    const blurEvent = createEvent('focusout');
    if (hiddenRef.current) hiddenRef.current.dispatchEvent(blurEvent);
  };

  const isHoursFilled = useCallback(
    (fully?: boolean) => {
      if (!hours) {
        return true;
      }
      if (hoursRef.current && hoursRef.current.value && hoursRef.current.value.length) {
        return fully ? hoursRef.current.value.length > 1 : true;
      }
      return false;
    },
    [hours],
  );

  const isMinutesFilled = useCallback(
    (fully?: boolean) => {
      if (!minutes) {
        return true;
      }
      if (minutesRef.current && minutesRef.current.value && minutesRef.current.value.length) {
        return fully ? minutesRef.current.value.length > 1 : true;
      }
      return false;
    },
    [minutes],
  );

  const isSecondsFilled = useCallback(
    (fully?: boolean) => {
      if (!seconds) {
        return true;
      }
      if (secondsRef.current && secondsRef.current.value && secondsRef.current.value.length) {
        return fully ? secondsRef.current.value.length > 1 : true;
      }
      return false;
    },
    [seconds],
  );

  const isPickerFilled = useCallback(
    (fully?: boolean) => isHoursFilled(fully) && isMinutesFilled(fully) && isSecondsFilled(fully),
    [isHoursFilled, isMinutesFilled, isSecondsFilled],
  );

  const isPickerEmpty = useCallback(() => {
    let isEmpty = true;
    if (hours && isHoursFilled()) {
      isEmpty = false;
    }
    if (minutes && isMinutesFilled()) {
      isEmpty = false;
    }
    if (seconds && isSecondsFilled()) {
      isEmpty = false;
    }
    return isEmpty;
  }, [hours, isHoursFilled, isMinutesFilled, isSecondsFilled, minutes, seconds]);

  const getFirstUnfilledInput = () => {
    if (!isHoursFilled() && hours) {
      return hoursRef.current;
    }
    if (!isMinutesFilled() && minutes) {
      return minutesRef.current;
    }
    if (!isSecondsFilled() && seconds) {
      return secondsRef.current;
    }

    if (seconds) {
      return secondsRef.current;
    }
    if (minutes) {
      return minutesRef.current;
    }
    if (hours) {
      return hoursRef.current;
    }

    return null;
  };

  const getSecondsFromUserInput = useCallback(() => {
    let hoursInSeconds = 0;
    let minutesInSeconds = 0;
    let s = 0;

    if (hours && hoursRef.current && hoursRef.current.value) {
      hoursInSeconds = getSecondsFromHours(+hoursRef.current.value);
    }
    if (minutes && minutesRef.current && minutesRef.current.value) {
      minutesInSeconds = getSecondsFromMinutes(+minutesRef.current.value);
    }
    if (seconds && secondsRef.current && secondsRef.current.value) {
      s = +secondsRef.current.value;
    }

    const totalSeconds = hoursInSeconds + minutesInSeconds + s;

    return totalSeconds;
  }, [hours, minutes, seconds]);

  const getNextInput = useCallback(
    (currentInputName: string) => {
      switch (currentInputName) {
        case InputNames.hours:
          if (minutes) {
            return minutesRef;
          }
          return null;
        case InputNames.minutes:
          if (seconds) {
            return secondsRef;
          }
          return null;
        default:
          return null;
      }
    },
    [minutes, seconds],
  );

  const getPreviousInput = useCallback(
    (currentInputName: string) => {
      switch (currentInputName) {
        case InputNames.minutes:
          if (hours) {
            return hoursRef;
          }
          return null;
        case InputNames.seconds:
          if (minutes) {
            return minutesRef;
          }
          return null;

        default:
          return null;
      }
    },
    [hours, minutes],
  );

  const getInputRefByName = useCallback((inputName: string) => {
    switch (inputName) {
      case InputNames.hours:
        return hoursRef;
      case InputNames.minutes:
        return minutesRef;
      default:
        return secondsRef;
    }
  }, []);

  const prepareDurationForErrorMessage = useCallback(
    (duration: number): string => {
      const timeObject = getInputsValuesFromSeconds(duration);

      let value = '';
      if (hours) value = timeObject.hours;
      if (minutes) value += `${hours ? ':' : ''}${timeObject.minutes}`;
      if (seconds) value += `${minutes ? ':' : ''}${timeObject.seconds}`;

      return value;
    },
    [hours, minutes, seconds],
  );

  const setInputsValues = useCallback((newValue: string) => {
    const isEmpty = !newValue || _.isNaN(+newValue);
    const newValues = isEmpty ? null : getInputsValuesFromSeconds(+newValue);

    silentSetValue(hoursRef, newValues?.hours || '');
    silentSetValue(minutesRef, newValues?.minutes || '');
    silentSetValue(secondsRef, newValues?.seconds || '');
  }, []);

  const formatUserInput = useCallback(() => {
    if (isPickerEmpty()) {
      return;
    }

    const hoursInput = hoursRef.current;
    const minutesInput = minutesRef.current;
    const secondsInput = secondsRef.current;

    if ((hours && !hoursInput) || (minutes && !minutesInput) || (seconds && !secondsInput)) {
      return;
    }
    if (hours && hoursInput && !isHoursFilled()) {
      silentSetValue(hoursRef, '00');
    }
    if (minutes && minutesInput && !isMinutesFilled()) {
      silentSetValue(minutesRef, '00');
    }
    if (seconds && secondsInput && !isSecondsFilled()) {
      silentSetValue(secondsRef, '00');
    }
    if (hours && hoursInput && hoursInput.value.length === 1) {
      silentSetValue(hoursRef, `0${hoursInput.value}`);
    }
    if (minutes && minutesInput && minutesInput.value.length === 1) {
      silentSetValue(minutesRef, `0${minutesInput.value}`);
    }
    if (seconds && secondsInput && secondsInput.value.length === 1) {
      silentSetValue(secondsRef, `0${secondsInput.value}`);
    }
  }, [hours, isHoursFilled, isMinutesFilled, isPickerEmpty, isSecondsFilled, minutes, seconds]);

  const clearDurationPickerError = useCallback(() => {
    setDurationPickerError(false);
    setDurationPickerErrorMessage(undefined);
  }, []);

  const validate = useCallback(
    (value: string) => {
      if (!value || _.isNaN(+value)) {
        clearDurationPickerError();
        return true;
      }

      if (maxDuration && +value > maxDuration) {
        setDurationPickerError(true);
        setDurationPickerErrorMessage(`Max: ${prepareDurationForErrorMessage(maxDuration)}`);
        return false;
      }

      if (minDuration && +value < minDuration) {
        setDurationPickerError(true);
        setDurationPickerErrorMessage(`Min: ${prepareDurationForErrorMessage(minDuration)}`);
        return false;
      }

      clearDurationPickerError();
      return true;
    },
    [maxDuration, minDuration, prepareDurationForErrorMessage, clearDurationPickerError],
  );

  const updateHiddenInputValue = useCallback(
    _.debounce(() => {
      if (!isPickerFilled(true)) {
        setNativeValue(hiddenRef, '');
        return;
      }
      const duration = getSecondsFromUserInput();
      setNativeValue(hiddenRef, duration);
    }, 300),
    [getSecondsFromUserInput, isPickerFilled],
  );

  useFieldErrorDispatcher(durationPickerError, onValidError, onClearError);

  return {
    durationPickerError,
    durationPickerErrorMessage,
    hiddenRef,
    hoursRef,
    minutesRef,
    secondsRef,
    dispatchBlurEvent,
    isPickerEmpty,
    getFirstUnfilledInput,
    getSecondsFromUserInput,
    getNextInput,
    getPreviousInput,
    getInputRefByName,
    setInputsValues,
    formatUserInput,
    validate,
    updateHiddenInputValue,
  };
};
