import React from 'react';
import { useRecoilValue } from 'recoil';

import { requestDetailsAtom } from 'state/requests';

import { EventsDisplay } from './EventsDisplay';

export const TimeEventDetails = (): React.ReactElement => {
  const requestDetails = useRecoilValue(requestDetailsAtom);

  if (!requestDetails) return <></>;

  return <EventsDisplay {...requestDetails} />;
};
