import { UUID } from 'utils/UUID';

import { AddNotificationProps, NotificationInsertTypes } from './types';
import { notificationPublisher } from './publisher';

export const addNotification = (notification: AddNotificationProps): void => {
  const defaultNotificationProps = {
    id: UUID(),
    insert: 'top' as NotificationInsertTypes,
    duration: 7000,
    isStatic: false,
  };

  notificationPublisher.addNotification({
    ...defaultNotificationProps,
    ...notification,
  });
};

export const removeNotification = (id: string): void => {
  if (notificationPublisher.removeNotification) {
    notificationPublisher.removeNotification(id);
  }
};

export const removeAllNotifications = (): void => {
  if (notificationPublisher.removeAllNotifications) {
    notificationPublisher.removeAllNotifications();
  } else {
    throw new Error('removeAllNotifications must be used within an NotificationProvider');
  }
};
