import React, { useLayoutEffect } from 'react';
import { InputProps } from 'theme-ui';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

export type InputOwnValue = InputProps['value'] | InputProps['value'][];

type Props = {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  onChange: (value: InputOwnValue, input: HTMLInputElement) => void;
};

/**
 * This hook defines a property 'value' on the input element provided.
 * The 'onChange' callback passed to this hook will not fire when setting the value with 'setNativeValue' projects util because 'setNativeValue' uses the property 'value' from 'HTMLInputElement.prototype' and omits the 'value' property defined in this hook directly on the provided input element.
 * Main use for this hook is to catch value changes set programmatically by react-form-hook, 'onChange' callback will fire when method like 'reset' or 'setValue' are called.
 */
export const useOnOwnValueChange = (inputRef: Props['inputRef'], onChange: Props['onChange']): void => {
  const onChangeRef = useCallbackRef(onChange);

  useLayoutEffect(() => {
    const input = inputRef.current;
    if (input) {
      Object.defineProperty(input, 'value', {
        set: (value) => {
          const nativeInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');

          if (nativeInputValue) {
            const nativeInputValueSetter = nativeInputValue.set;

            if (nativeInputValueSetter) {
              nativeInputValueSetter.call(input, value);
            }
          }

          onChangeRef.current(value, input);
        },
      });
    }
  }, [inputRef, onChangeRef]);
};
