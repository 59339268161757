import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';
import { useRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { i18n } from '@lingui/core';
import { useHistory } from 'react-router';

import { TextInput } from 'components/ui/TextInput';
import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Icon } from 'components/Icon/Icon';
import { AccountInfoAbout } from 'api/actions/user/userActions.types';
import { PhoneInput } from 'components/ui/PhoneInput';
import { typedKeys } from 'utils/typedKeys';
import { delay } from 'utils/delay';
import { signUpFormAtom } from 'state/signUp';
import { FormCard } from 'layouts/Authentication';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

type Props = {
  onSubmit: (props: AccountInfoAbout) => Promise<boolean>;
};

export const EmployerStep1Form = React.forwardRef<HTMLFormElement, Props>(({ onSubmit }: Props, ref) => {
  const [signUpFormState, setSignUpFormState] = useRecoilState(signUpFormAtom);

  const {
    setError,
    clearErrors,
    getValues,
    register,
    handleSubmit,
    formState: { isSubmitted, errors },
  } = useForm({
    mode: signUpFormState?.firstName && signUpFormState?.lastName ? 'onChange' : 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: signUpFormState?.firstName,
      lastName: signUpFormState?.lastName,
      phoneNumber: signUpFormState?.phoneNumber,
    },
  });

  const { go } = useHistory();

  const setPhoneErrorCallback = useCallback(() => {
    setError('phoneNumberInternalError' as 'phoneNumber', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearPhoneErrorCallback = useCallback(() => {
    clearErrors('phoneNumberInternalError' as 'phoneNumber');
  }, [clearErrors]);

  const handleSubmitCallback = useCallback(
    async (props) => {
      await onSubmit(props);
    },
    [onSubmit],
  );

  const handleGoBack = () => {
    go(-1);
  };

  const saveToRecoilCallback = useCallback(async () => {
    await delay(0);
    const formValues = getValues();

    let validFormValues = {};

    typedKeys(formValues).forEach((name) => {
      if (errors[name] && formValues[name] !== '') {
        return;
      }
      if (name === 'phoneNumber' && errors['phoneNumberInternalError' as 'phoneNumber']) {
        return;
      }
      validFormValues = {
        ...validFormValues,
        [name]: formValues[name],
      };
    });

    setSignUpFormState({
      ...signUpFormState,
      ...validFormValues,
    });
  }, [getValues, errors, signUpFormState, setSignUpFormState]);

  return (
    <form ref={ref} onChange={saveToRecoilCallback} onSubmit={handleSubmit(handleSubmitCallback)} noValidate>
      <ElementGroup marginValue="4" direction="column">
        <ElementGroup direction="column">
          <TextInput
            variant="roundedTop"
            id="firstName"
            placeholder={i18n._(
              t({
                id: 'sign_up.employer.form.first_name',
                message: 'Your first name',
              }),
            )}
            type="text"
            error={!!errors.firstName}
            errorMessage={errors?.firstName?.message}
            {...register('firstName', validationFactory({ ...VALIDATION_RULES.FIRST_NAME, required: true }))}
          />
          <TextInput
            variant="roundedBottom"
            id="lastName"
            placeholder={i18n._(
              t({
                id: 'sign_up.employer.form.last_name',
                message: 'Your last name',
              }),
            )}
            type="text"
            error={!!errors.lastName}
            errorMessage={errors?.lastName?.message}
            {...register('lastName', validationFactory({ ...VALIDATION_RULES.SURNAME, required: true }))}
          />
        </ElementGroup>
        <Flex>
          <PhoneInput
            id="phoneNumber"
            autoComplete="tel"
            label={i18n._(
              t({
                id: 'global.forms.inputs.phone_number',
                message: 'Phone number',
              }),
            )}
            placeholder={i18n._(
              t({
                id: 'global.forms.inputs.phone_number_example',
              }),
            )}
            onValidError={setPhoneErrorCallback}
            onClearError={clearPhoneErrorCallback}
            error={!!errors.phoneNumber || (!!errors['phoneNumberInternalError' as 'phoneNumber'] && isSubmitted)}
            errorMessage={errors?.phoneNumber?.message}
            clearable
            {...register('phoneNumber')}
          />
        </Flex>
      </ElementGroup>

      <FormCard.Footer>
        <Button variant="minimal" shape="rounded" size="lg" type="button" onClick={handleGoBack}>
          <Trans id="global.forms.buttons.back" />
        </Button>

        <Button variant="primary" apendWith={<Icon type="arrowRight" />} size="lg" type="submit" shape="rounded">
          <Trans id="global.forms.buttons.next" />
        </Button>
      </FormCard.Footer>
    </form>
  );
});
