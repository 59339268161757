import { t } from '@lingui/macro';
import React, { useMemo } from 'react';
import { Flex, FlexProps, Text, ThemeUICSSObject } from 'theme-ui';
import { useLingui } from '@lingui/react';

import { TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Icon } from 'components/Icon/Icon';
import { ENTRY_TIME_EVENT_ID, EXIT_TIME_EVENT_ID } from 'Kiosk/constants/constants';

type Props = {
  workStatus: TimeEventType;
  isEnd: boolean;
} & FlexProps;

const statusSx: ThemeUICSSObject = {
  alignItems: 'center',
  gap: '0.375rem',
  borderTopLeftRadius: 'pill',
  borderBottomLeftRadius: 'pill',
  borderTopRightRadius: 'xs',
  borderBottomRightRadius: 'xs',
  color: 'rgba(14, 23, 55, 0.6)',
  fontWeight: 'bold',
  lineHeight: 1,
  pl: '0.1875rem',
  pr: '0.375rem',
  py: '0.1875rem',
  '> [data-text]': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    pt: '0.0625rem',
  },
};

export const WorkStatusBadge = ({ workStatus, isEnd, ...props }: Props): React.ReactElement => {
  useLingui();
  const { id, name } = workStatus;

  const statusColor = useMemo((): string => {
    switch (id) {
      case ENTRY_TIME_EVENT_ID:
        return 'greens4';
      case EXIT_TIME_EVENT_ID:
        return 'reds4';
      default:
        return 'whites3';
    }
  }, [id]);

  return (
    <Flex
      title={t({
        id: name,
      })}
      sx={{
        bg: statusColor,
        ...statusSx,
        ...(props.sx && props.sx),
      }}
      {...props}
    >
      {<Icon type={isEnd ? 'playCircle' : 'stopCircle'} lineHeight={1.2} />}

      <Text as="span" data-text>
        {t({
          id: name,
        })}
      </Text>
    </Flex>
  );
};
