import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { requestGetDataToModifyAction } from 'api/actions/requests/requestsActions';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { StickyList } from 'components/StickyList/StickyList';
import { StickyListProps } from 'components/StickyList/types';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { TIME_EVENTS_HISTORY_LIST } from 'layouts/Requests/constans';
import { selectedRowsAtomFamily } from 'state/list';
import { fullTimeFormatSelector } from 'state/recoilState';
import {
  ParsedTimeEventHistoryElement,
  parsedTimeEventsHistorySelector,
  requestHistoryAtom,
  selectedEmployeesAddRequestsIdsSelector,
} from 'state/requests';
import { dateTime } from 'utils/dateTime';
import { TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';

type Props = {
  label: string;
};

export const TimeEventsHistoryList = ({ label }: Props): React.ReactElement => {
  useLingui();
  const { query, loading } = useParameterizedQuery(requestGetDataToModifyAction);
  const setRequestHistory = useSetRecoilState(requestHistoryAtom);
  const parsedTimeEventsHistory = useRecoilValue(parsedTimeEventsHistorySelector);
  const selectedUsers = useRecoilValue(selectedEmployeesAddRequestsIdsSelector);
  const resetListSelection = useResetRecoilState(selectedRowsAtomFamily(TIME_EVENTS_HISTORY_LIST));

  const timeFormat = useRecoilValue(fullTimeFormatSelector);

  const getHistory = useCallback(async () => {
    const personId = selectedUsers[0];

    const { error, payload } = await query({ personId, skip: 0, type: RequestFormType.TimeTracking });

    if (!error && payload) {
      setRequestHistory(payload);
    }
  }, [query, selectedUsers, setRequestHistory]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  useEffect(() => () => resetListSelection(), [resetListSelection]);

  const columns: StickyListProps<ParsedTimeEventHistoryElement>['columns'] = [
    {
      key: 'dateTimeDetails',
      title: 'Date',
      sortableValue: (dateTimeDetails: ParsedTimeEventHistoryElement['dateTimeDetails']) =>
        `${dateTimeDetails.dateUnix}`,
      customCellRenderer: (dateTimeDetails: ParsedTimeEventHistoryElement['dateTimeDetails']) => {
        if (!dateTimeDetails.dateUnix) return <></>;

        const { dateUnix } = dateTimeDetails;
        const displayDate = dateTime(dateUnix).format(`ll ${timeFormat}`);

        return <TextEllipsis title={displayDate}>{displayDate}</TextEllipsis>;
      },
      columnGrow: 2,
    },
    {
      key: ['requestType', 'isEndStatus'],
      customCellRenderer: (
        items: [ParsedTimeEventHistoryElement['requestType'], ParsedTimeEventHistoryElement['isEndStatus']],
      ) => <WorkStatusBadge workStatus={items[0] as TimeEventType} isEnd={items[1]} />,
      columnGrow: 1,
    },
  ];

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ fontWeight: 'bold', mb: 2 }}>{label}</Text>
      <Flex sx={{ minHeight: '230px', width: '100%' }}>
        <StickyList
          name={TIME_EVENTS_HISTORY_LIST}
          list={parsedTimeEventsHistory || []}
          columns={columns}
          variant="inverted"
          select="radio"
          emptyListMessage={loading && t({ id: 'global.loading' })}
        />
      </Flex>
    </Flex>
  );
};
