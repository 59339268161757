import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { EmployeeNotInvited } from 'pages/SignUp/Employee/EmployeeNotInvited';
import { SignIn } from 'pages/SignIn/SignIn';
import { PATH } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { ServiceUnavailable } from 'pages/errors/ServiceUnavailable';
import { PassReset } from 'pages/PassReset/PassReset';
import { PassResetWizard } from 'pages/PassReset/PassResetWizard';
import { SignUp } from 'pages/SignUp/SignUp';
import { Employer } from 'pages/SignUp/Employer/Employer';
import { EmployeeInvited } from 'pages/SignUp/Employee/EmployeeInvited';
import { AccountType } from 'pages/SignUp/AccountType/AccountType';
import { AuthorizedApp } from 'components/AuthorizedApp';
import { PageNotFound } from 'pages/errors/NotFound';
import { Employee } from 'pages/SignUp/Employee/Employee';
import { Kiosk } from 'Kiosk/Kiosk';

import { AuthorizedRoute } from './AuthorizedRoute';
import { LocalizedRouteGate } from './LocalizedRouteGate';
import { SignUpRoute } from './SignUpRoute';
import { AuthenticationSwitch } from './AuthenticationSwitch';

export const AppRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const { pathname } = useLocation();

  return (
    <LocalizedRouteGate language={language}>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path={PATH.SERVICE_UNAVAILABLE[language]} component={ServiceUnavailable} />
        <Route path={PATH.PAGE_NOT_FOUND[language]} component={PageNotFound} />

        {/* AUTHENTICATION */}
        <Route path={[PATH.SIGN_IN[language], PATH.SIGN_UP[language], PATH.PASS_RESET[language]]}>
          <AuthenticationSwitch>
            <Route path={PATH.SIGN_UP__EMPLOYEE__INVITED[language]} component={EmployeeInvited} />
            <SignUpRoute path={PATH.SIGN_UP__EMPLOYEE__NOT_INVITED[language]} component={EmployeeNotInvited} />
            <Route exact path={PATH.SIGN_UP__EMPLOYEE[language]} component={Employee} />
            <SignUpRoute exact path={PATH.SIGN_UP__ACCOUNT_TYPE[language]} component={AccountType} />
            <Route exact path={PATH.SIGN_UP[language]} component={SignUp} />
            <Route exact path={PATH.PASS_RESET__CONFIRMED[language]} component={PassResetWizard} />
            <Route exact path={PATH.PASS_RESET[language]} component={PassReset} />
            <Route exact path={PATH.SIGN_IN[language]} component={SignIn} />
            <SignUpRoute path={PATH.SIGN_UP__EMPLOYER[language]} component={Employer} />
          </AuthenticationSwitch>
        </Route>

        {/* AUTHORIZED APP */}
        <AuthorizedRoute path={PATH.KIOSK[language]} component={Kiosk} />
        <AuthorizedRoute path={PATH.START[language]} component={AuthorizedApp} />
      </Switch>
    </LocalizedRouteGate>
  );
};
