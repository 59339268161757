import { useEffect } from 'react';

export const useEvent = (
  option: string,
  callback: EventListenerOrEventListenerObject,
  target: null | HTMLElement | Window = window,
): void => {
  useEffect(() => {
    if (target !== null && callback) {
      target.addEventListener(option, callback);
    }
    return () => {
      if (target !== null && callback) {
        target.removeEventListener(option, callback);
      }
    };
  }, [target, callback, option]);
};
