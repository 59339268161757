import React, { useCallback, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui';
import { useForm, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';

import { TextInput } from 'components/ui/TextInput';
import { ColorPicker } from 'components/ui/ColorPicker';
import {
  ApplicationModulePermission,
  ApplicationModulePermissionType,
  RoleDetails,
  SystemManagementPermission,
  SystemManagementPermissionType,
} from 'api/actions/roles/rolesActions.types';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Switch } from 'components/ui/Switch';
import { getTextField } from 'utils/getTextField';
import { FetchOrganizationSessionResponse } from 'api/actions/organizationSession/organizationSessionActions.types';
import { organizationSessionPropertySelectorFamily } from 'state/organizationSession';
import { DEFAULT_ROLES_NAMES } from 'constants/common';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

const labelSx: ThemeUIStyleObject = {
  ml: 2,
  fontSize: 1,
  fontWeight: 'bold',
  mt: 3,
};

const forceCorrectTypes = ({ color, description, ...rest }: RoleDetails): RoleDetails => ({
  ...rest,
  description: getTextField(description),
  color: +color,
});

type Props = {
  isDuplicate?: boolean;
  disable?: boolean;
  defaultValues?: Partial<RoleDetails>;
  onSubmit: (props: RoleDetails) => Promise<boolean>;
  setLoading: (loading: boolean) => void;
};

const defaultSystemManagement = _.times(5).map((f, i) => ({
  type: i,
  value: true,
}));

const defaultModules = [
  { type: ApplicationModulePermissionType.Employees, value: true },
  { type: ApplicationModulePermissionType.TimeTracking, value: false },
  { type: ApplicationModulePermissionType.Schedules, value: true },
  { type: ApplicationModulePermissionType.Requests, value: true },
];

const defaultProps: Partial<Props> = {
  isDuplicate: false,
  disable: false,
  defaultValues: {
    systemManagement: defaultSystemManagement,
    modules: defaultModules,
  },
};

export const AddEditRole = React.forwardRef<HTMLFormElement, Props>(
  ({ isDuplicate, disable, onSubmit, defaultValues = defaultProps.defaultValues, setLoading }: Props, ref) => {
    useLingui();
    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: 'onTouched',
      reValidateMode: 'onChange',
      defaultValues,
    });
    const roles = useRecoilValue(
      organizationSessionPropertySelectorFamily('roles') as RecoilValueReadOnly<
        FetchOrganizationSessionResponse['roles'] | null
      >,
    );

    const restrictedRoleNames = useMemo(() => {
      if (!roles) return [];
      return [
        ...roles.map(({ name }) => name.trim()),
        ...DEFAULT_ROLES_NAMES.map((name) => name.toLowerCase()),
        ...DEFAULT_ROLES_NAMES,
      ].filter((name) => {
        if (!defaultValues || isDuplicate) return true;

        return name !== defaultValues.name;
      });
    }, [roles, defaultValues, isDuplicate]);

    const { fields: systemManagementFields } = useFieldArray({
      control,
      name: 'systemManagement',
    });
    const { fields: modulesFields } = useFieldArray({
      control,
      name: 'modules',
    });

    const handleSubmitCallback = useCallback(
      (body: RoleDetails) => {
        onSubmit(forceCorrectTypes(body));
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    return (
      <form ref={ref} onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)} noValidate>
        <Heading variant="heading4.withMargins">
          <Trans id="team.add_edit_role.basic_details">Basic details</Trans>
        </Heading>
        <Text sx={labelSx}>
          <Trans id="team.add_edit_role.name">Name</Trans>
        </Text>

        <TextInput
          disabled={disable}
          clearable
          size="sm"
          id="name"
          placeholder={t({ id: 'team.add_edit_role.name', message: 'Name' })}
          variant="rounded"
          error={!!errors.name}
          errorMessage={errors?.name?.message}
          {...register(
            'name',
            validationFactory({ ...VALIDATION_RULES.ROLE_NAME, required: true, restrictedValues: restrictedRoleNames }),
          )}
        />

        <Text as="div" sx={labelSx}>
          <Trans id="team.add_edit_role.description">Description</Trans>
        </Text>
        <TextInput
          disabled={disable}
          size="sm"
          id="additionalInfo"
          placeholder={t({
            id: 'team.add_edit_role.description_placeholder',
            message: 'Description of the role (optional)',
          })}
          variant="rounded"
          error={!!errors.description}
          errorMessage={errors?.description?.message}
          {...register('description', validationFactory(VALIDATION_RULES.ROLE_DESCRIPTION))}
        />

        <Heading mt={4} variant="heading4.withMargins">
          <Trans id="team.add_edit_role.color">Color</Trans>
        </Heading>
        <ColorPicker disabled={disable} {...register('color')} />
        <Heading mt={4} variant="heading4.withMargins">
          <Trans id="team.add_edit_role.advanced_details">Advanced details</Trans>
        </Heading>

        <ElementGroup showAsList wrapperSx={{ mt: '0.75rem' }} marginValue="0.75rem" direction="column" withDividers>
          {[
            ...systemManagementFields.map((systemManagementField: SystemManagementPermission, index: number) => {
              const { label, additionalInfo } = (() => {
                switch (systemManagementField.type) {
                  case SystemManagementPermissionType.companySettings:
                    return {
                      label: t({
                        id: 'team.add_edit_role.access.settings',
                        message: 'Access to organization settings',
                      }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.settings.info',
                        message: 'Can manage team-wide settings',
                      }),
                    };
                  case SystemManagementPermissionType.permissions:
                    return {
                      label: t({ id: 'team.add_edit_role.access.roles', message: 'Access to roles management' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.roles.info',
                        message: 'Add, edit and delete',
                      }),
                    };
                  case SystemManagementPermissionType.payments:
                    return {
                      label: t({ id: 'team.add_edit_role.access.billing', message: 'Access to plan & billing' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.billing.info',
                        message: 'Can make changes to organization current billing, invoices etc.',
                      }),
                    };
                  case SystemManagementPermissionType.addMobileTimeClocks:
                    return {
                      label: t({ id: 'team.add_edit_role.access.timeclocks', message: 'Access to Mobile TimeClocks' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.timeclocks.info',
                        message: 'Can sign into and create new Mobile TimeClocks via our apps',
                      }),
                    };
                  case SystemManagementPermissionType.enterKioskMode:
                    return {
                      label: t({ id: 'team.add_edit_role.access.kiosk', message: 'Access to web KIOSK mode' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.kiosk.info',
                        message: 'Can create TimeClocks via web KIOSK',
                      }),
                    };
                  default:
                    return {
                      label: '',
                      additionalInfo: '',
                    };
                }
              })();
              return (
                <Flex key={`${label}-${systemManagementField.type}`} id={`${label}-${systemManagementField.type}`}>
                  <Switch
                    disabled={disable}
                    defaultChecked={systemManagementField.value}
                    placement="right"
                    size="sm"
                    label={label}
                    additionalInfo={additionalInfo}
                    {...register(`systemManagement.${index}.value`)}
                  />
                </Flex>
              );
            }),
            ...modulesFields.map((moduleField: ApplicationModulePermission, index: number) => {
              const { label, additionalInfo } = (() => {
                switch (moduleField.type) {
                  case ApplicationModulePermissionType.Employees:
                    return {
                      label: t({ id: 'team.add_edit_role.manage.teammates', message: 'Manage teammates' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.manage.teammates.info',
                        message: 'Can add, edit and delete other teammates (only in tags the user belongs to)',
                      }),
                    };
                  case ApplicationModulePermissionType.TimeTracking:
                    return {
                      label: t({ id: 'team.add_edit_role.manage.time_tracking', message: 'Manage time tracking' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.manage.time_tracking.info',
                        message: 'Can add, edit & delete time tracking data (only in tags the user belongs to)',
                      }),
                    };
                  case ApplicationModulePermissionType.Schedules:
                    return {
                      label: t({ id: 'team.add_edit_role.manage.schedules', message: 'Manage schedules' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.manage.schedules.info',
                        message: 'Can add, edit, delete & publish schedules (only in tags the user belongs to)',
                      }),
                    };
                  case ApplicationModulePermissionType.Requests:
                    return {
                      label: t({ id: 'team.add_edit_role.manage.requests', message: 'Manage requests' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.manage.requests.info',
                        message:
                          'Can add, edit, delete & approve/reject others requests (only in tags the user belongs to)',
                      }),
                    };
                  default:
                    return {
                      label: '',
                      additionalInfo: '',
                    };
                }
              })();
              return (
                <Flex key={`${label}-${moduleField.type}`} id={`${label}-${moduleField.type}`}>
                  <Switch
                    disabled={disable}
                    defaultChecked={moduleField.value}
                    placement="right"
                    size="sm"
                    label={label}
                    additionalInfo={additionalInfo}
                    {...register(`modules.${index}.value`)}
                  />
                </Flex>
              );
            }),
          ]}
        </ElementGroup>
      </form>
    );
  },
);

AddEditRole.defaultProps = defaultProps;
