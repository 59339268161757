import { atom, selector } from 'recoil';

import { FetchUserSessionResponse, NameDisplayOrder } from 'api/actions/userSession/userSessionActions.types';
import { Employee, Role, Tag } from 'api/actions/organizationSession/organizationSessionActions.types';

import { organizationSessionAtom } from './organizationSession';

type UserDetails = Employee & { role: Role; tags?: Tag[] };

export const userSessionAtom = atom<FetchUserSessionResponse | null>({
  key: 'userSession',
  default: null,
});

export const userSessionPersonIdSelector = selector<FetchUserSessionResponse['personId'] | null>({
  key: 'userSessionPersonId',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    if (!userSession) return null;
    const { personId } = userSession;
    return personId;
  },
});

export const userDetailsSelector = selector<UserDetails | null>({
  key: 'userDetails',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession || !userSession) return null;
    const { personId } = userSession;
    const { employeesMap, rolesMap, tagsMap } = organizationSession;
    const userDetails = employeesMap.get(personId);
    if (!userDetails) return null;
    const { roleId, tagsIds } = userDetails;
    const role = rolesMap.get(roleId);
    const tags = tagsIds.length ? (tagsIds.map((tagId) => tagsMap.get(tagId)) as Tag[]) : undefined;
    if (!userDetails || !role) return null;
    return {
      ...userDetails,
      role,
      tags,
    };
  },
});

type NameOrderConfig = {
  sortBy: string;
  nameDisplayOrder: NameDisplayOrder;
};

export const nameDisplayOrderSelector = selector<NameOrderConfig>({
  key: 'nameDisplayOrder',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    const nameDisplayOrder = userSession?.nameDisplayOrder || NameDisplayOrder.NameFirst;

    const sortBy = (() => {
      switch (nameDisplayOrder) {
        case NameDisplayOrder.SurnameFirst:
          return 'surname';
        case NameDisplayOrder.NameFirst:
        default:
          return 'firstName';
      }
    })();

    return { nameDisplayOrder, sortBy };
  },
});
