import { ConfigType } from 'dayjs';

import { SelectProps } from '../Select/Select';

export type TimePickerOwnProps = {
  onValidError?: () => void;
  onClearError?: () => void;
  quickSelectOptions?: (string | number)[];
  minTime?: ConfigType;
  maxTime?: ConfigType;
};

export type TimePickerProps = Omit<SelectProps, 'placeholder' | 'options'> & TimePickerOwnProps;

export enum AmOptions {
  AM = 'AM',
  PM = 'PM',
}

export enum InputNames {
  hours = 'hours',
  minutes = 'minutes',
  am = 'am',
}
