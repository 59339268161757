import React from 'react';
import { ThemeUIStyleObject, Text } from 'theme-ui';

import { usePathnameMatch } from 'hooks/usePathnameMatch/usePathnameMatch';
import { LinkButton, LinkButtonProps } from 'components/ui/Buttons';
import { Icon } from 'components/Icon/Icon';
import { useTheme } from 'styles/useTheme';

import { DropdownLinkObject } from './types';

const linkButtonSx: ThemeUIStyleObject = {
  justifyContent: 'flex-start',
  textAlign: 'left',
  flexGrow: 1,
};

const additionalInfoSx: ThemeUIStyleObject = {
  display: 'flex',
  fontWeight: 'normal',
};

type Props = Omit<LinkButtonProps, 'to' | 'prependWith' | 'variant' | 'shape'> & DropdownLinkObject;

export type DropdownLinkProps = Props;

const defaultProps: Partial<Props> = {
  additionalInfo: undefined,
  icon: undefined,
  prependWith: undefined,
};

export const DropdownLink = ({ to, label, additionalInfo, icon, prependWith, ...props }: Props): React.ReactElement => {
  const active = usePathnameMatch([to]);
  const { theme } = useTheme();

  const getPrependWithComponent = () => {
    if (prependWith) {
      return prependWith;
    }
    if (icon) {
      return <Icon type={icon} />;
    }
    return undefined;
  };

  return (
    <LinkButton
      fullWidth
      shape="rounded"
      variant="minimal"
      prependWith={icon || prependWith ? getPrependWithComponent() : undefined}
      bgOverwrite={{
        default: theme.colors.dropdown.dropdownLink.background.default,
        hover: theme.colors.dropdown.dropdownLink.background.hover,
        tap: theme.colors.dropdown.dropdownLink.background.tap,
        disabled: theme.colors.dropdown.dropdownLink.background.disabled,
      }}
      to={to}
      {...props}
      sx={{
        ...linkButtonSx,
        ...(!props.disabled && { cursor: 'pointer' }),
        ...(active && { color: 'dropdown.dropdownLink.color.active' }),
        ...(props.sx && props.sx),
      }}
    >
      <Text as="span" sx={{ display: 'flex', flexDirection: 'column' }}>
        {label}
        {additionalInfo && (
          <Text
            as="span"
            className="additional-info"
            sx={{
              ...additionalInfoSx,
              color: active
                ? 'dropdown.dropdownLink.additionalInfo.active'
                : 'dropdown.dropdownLink.additionalInfo.default',
            }}
          >
            {additionalInfo}
          </Text>
        )}
      </Text>
    </LinkButton>
  );
};

DropdownLink.defaultProps = defaultProps;
