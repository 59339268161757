import React from 'react';
import { Text, Flex, ThemeUIStyleObject } from 'theme-ui';
import { Trans } from '@lingui/macro';

import { Icon } from 'components/Icon/Icon';
import { SummaryButton } from '../components/ui/SummaryButton';
import { durationFromSeconds } from 'utils/dateTime';

const wrapperSx: ThemeUIStyleObject = {
  width: '100%',
  bg: 'rgba(0,0,0, 0.2)',
  borderRadius: 'lg',
  alignItems: 'center',
  py: '1rem',
  pr: '0.75rem',
  pl: '1rem',
};
const contentSx: ThemeUIStyleObject = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexGrow: 1,
};

const timerSx: ThemeUIStyleObject = {
  p: 0,
  m: 0,
  color: 'inherit',
  fontSize: [5, 6],
};

type Props = {
  onClick: () => void;
  duration: number;
  timeEventName: string;
  allowedDuration?: number;
  isWorkTimeEnd?: boolean;
  workStartTime?: string;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  workStartTime: undefined,
  allowedDuration: undefined,
  isWorkTimeEnd: false,
  sx: undefined,
};

export const TimeEventHistoryCard = ({
  workStartTime,
  isWorkTimeEnd,
  duration,
  allowedDuration,
  timeEventName,
  onClick,
  sx,
  ...props
}: Props): React.ReactElement => (
  <Flex {...props} sx={{ ...wrapperSx, ...(sx && sx) }}>
    <Flex sx={contentSx}>
      <Text
        sx={{
          fontSize: [3, 4],
          fontWeight: 'bold',
        }}
      >
        {timeEventName}
        {workStartTime && (
          <Text as="span" sx={{ fontWeight: 'normal' }}>
            {' '}
            (<Trans id="kiosk.change_status.history_card.from">from </Trans>
            {workStartTime})
          </Text>
        )}
      </Text>
      <Text sx={{ ...timerSx, fontWeight: isWorkTimeEnd ? 'bold' : 'normal' }}>
        {durationFromSeconds(duration).format('HH:mm:ss')}
        {allowedDuration && (
          <Text as="span" sx={{ fontSize: [2, 3] }}>
            {' '}
            / {durationFromSeconds(allowedDuration).format('HH:mm:ss')}
          </Text>
        )}
      </Text>
    </Flex>

    <SummaryButton
      onClick={onClick}
      shape="rounded"
      variant={isWorkTimeEnd ? 'stop' : 'default'}
      sx={{ width: ['110px', '140px'], px: 1 }}
      prependWith={<Icon fill="whites1" type={isWorkTimeEnd ? 'stop' : 'play'} />}
    >
      {isWorkTimeEnd ? 'STOP' : 'START'}
    </SummaryButton>
  </Flex>
);

TimeEventHistoryCard.defaultProps = defaultProps;
