import React, { useMemo } from 'react';
import { createClient, ClientContextProvider, RequestInterceptor, ResponseInterceptor } from 'react-fetching-library';

import { buildCustomFetch } from 'api/buildCustomFetch';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { requestAuthInterceptor } from 'api/interceptors/requestAuthInterceptor';
import { requestHostInterceptor } from 'api/interceptors/requestHostInterceptor';
import { responseRefreshAccessTokenInterceptor } from 'api/interceptors/responseRefreshAccessTokenInterceptor';
import { responseTimeoutExceededInterceptor } from 'api/interceptors/responseTimeoutExceededInterceptor';
import { requestTimezoneInterceptor } from 'api/interceptors/requestTimezoneInterceptor';
import { responseRefreshSessionInterceptor } from 'api/interceptors/responseRefreshSessionInterceptor';
import { responseErrorInnerCodeInterceptor } from 'api/interceptors/responseErrorInnerCodeInterceptor';
import { responseFileUrlInterceptor } from 'api/interceptors/responseFileUrlInterceptor';

import { ClientProviderProps } from './ClientContextController.types';

const requestInterceptors: RequestInterceptor[] = [];
const responseInterceptors: ResponseInterceptor[] = [];

export const ClientContextController: React.ElementType = ({ children }: ClientProviderProps) => {
  const { accessToken } = useAuthState();
  const dispatch = useAuthDispatch();

  const client = useMemo(
    () =>
      createClient({
        requestInterceptors: [
          ...requestInterceptors,
          requestHostInterceptor(String(process.env.REACT_APP_API_URL)),
          requestAuthInterceptor(accessToken),
          requestTimezoneInterceptor(),
        ],
        responseInterceptors: [
          ...responseInterceptors,
          responseRefreshAccessTokenInterceptor(dispatch),
          responseRefreshSessionInterceptor(),
          responseErrorInnerCodeInterceptor(),
          responseTimeoutExceededInterceptor(),
          responseFileUrlInterceptor(),
        ],
        fetch: buildCustomFetch(),
      }),
    [accessToken, dispatch],
  );

  return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};
