import { useRecoilValue } from 'recoil';
import React, { useCallback } from 'react';
import { Text } from 'theme-ui';
import { t, plural, Trans, Plural } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { tagsMapSelector } from 'state/employees';
import { deleteTagsAction } from 'api/actions/tags/tagsActions';

import { DeleteDeactivateModal } from './modalsElements/DeleteDeactivateModal';

export const DeleteTagsModal = (): React.ReactElement => {
  useLingui();
  const tags = useRecoilValue(tagsMapSelector);

  const titleRenderer = useCallback(
    (count: number, name: string) =>
      t({
        id: 'team.delete_tags',
        message: plural(count, { one: `Delete tag - ${name}?`, other: 'Delete tags?' }),
      }),
    [],
  );

  const contentRenderer = useCallback(
    (count: number, selectedNames: string[]) => (
      <Trans id="team.delete_tags.desc">
        <Text>
          You're about to permanently delete{' '}
          <Plural
            value={count}
            one="this tag and its settings."
            other={
              <Trans>
                tags: <strong>{selectedNames.join(', ')}.</strong>
              </Trans>
            }
          />
          <strong> This action cannot be reversed.</strong>
        </Text>

        <Text>
          All users that were included in <Plural value={count} one="this tag" other="these tags" /> will NOT be
          affected other than loose <Plural value={count} one="it" other="them" /> from their tag list.
        </Text>
      </Trans>
    ),
    [],
  );

  return (
    <DeleteDeactivateModal
      list={tags}
      namePath="name"
      action={deleteTagsAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
    />
  );
};
