import React from 'react';
import { Heading } from 'theme-ui';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/macro';

import { IntegrationsList } from 'layouts/Settings/Integrations/IntegrationsList';

import { ModalRoutes } from './ModalRoutes';

// TODO: After CORE integration components should work similar to selects and show that they are loading... Now entire view jumps during loading.
export const Integrations = (): React.ReactElement => {
  useLingui();

  return (
    <>
      <Heading variant="heading3">
        <Trans id="navbar.side_menu.integrations" />
      </Heading>
      <IntegrationsList />
      <ModalRoutes />
    </>
  );
};
