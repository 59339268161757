import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import { Flex, Text } from 'theme-ui';
import { Trans } from '@lingui/macro';
import { useQuery } from 'react-fetching-library';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { motion } from 'framer-motion';

import { withDot } from 'components/Dot/withDot';
import { Icon } from 'components/Icon/Icon';
import { fetchChatUsersAction } from 'api/actions/chat/chatActions';
import {
  anyChatUserHasUnreadMessageSelector,
  chatUsersListSelector,
  filteredAndSortedChatUsersIdsSelector,
  mainWindowIsVisibleAtom,
} from 'state/chat';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { userDetailsSelector } from 'state/userSession';
import { DefaultRole } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Button } from 'components/ui/Buttons';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';

const IconWithDot = withDot(Icon);
const AnimatedFlex = motion(Flex);
const LazyMainWindowContent = React.lazy(() =>
  import('./MainWindowContent').then(({ MainWindowContent }) => ({
    default: MainWindowContent,
  })),
);

const MainWindow = (): React.ReactElement => {
  const [isVisible, setIsVisible] = useRecoilState(mainWindowIsVisibleAtom);
  const hasUnreadMessages = useRecoilValue(anyChatUserHasUnreadMessageSelector);
  const setChatUsers = useSetRecoilState(chatUsersListSelector);
  const filteredChatUsersIds = useRecoilValue(filteredAndSortedChatUsersIdsSelector);
  const userDetails = useRecoilValue(userDetailsSelector);

  const hasPermission = useMemo(() => {
    switch (userDetails?.roleId) {
      case `${DefaultRole.Admin}`:
      case `${DefaultRole.Manager}`:
        return true;
      default:
        return true;
    }
  }, [userDetails?.roleId]);

  const { isMobileBreakpoint } = useThemeBreakpoint();

  const { payload, error, loading } = useQuery(fetchChatUsersAction());

  const variants = useMemo(
    () => ({
      collapsed: {
        height: '0vh',
        y: 'calc(0)',
      },
      open: {
        height: isMobileBreakpoint ? '100vh' : '70vh',
        y: 'calc(0)',
      },
    }),
    [isMobileBreakpoint],
  );

  const handleIsVisible = useCallback(() => {
    if (filteredChatUsersIds) {
      setIsVisible(!isVisible);
    }
  }, [filteredChatUsersIds, isVisible, setIsVisible]);

  const renderLoader = () => (
    <Flex variant="chat.window.spinnerContainer">
      <LoadingSpinnerCss size={3} />
    </Flex>
  );

  useEffect(() => {
    if (!error && payload) {
      setChatUsers(payload);
    }
  }, [error, payload, setChatUsers]);

  return (
    <AnimatedFlex
      key="chat.mainWindow"
      variant="chat.window.mainContainer"
      data-is-visible={isVisible}
      variants={variants}
      initial="collapsed"
      animate={isVisible ? 'open' : 'collapsed'}
      transition={{
        type: 'spring',
        duration: isMobileBreakpoint ? 0 : 0.25,
      }}
    >
      <Flex as="header" variant="chat.window.header" onClick={handleIsVisible}>
        <IconWithDot
          type="chat"
          dotProps={{
            placement: 'TOP_RIGHT',
            sx: {
              transform: 'translate(50%, -50%)',
            },
          }}
          showDot={hasUnreadMessages}
        />

        <Flex variant="chat.window.header.texts">
          <Text>
            <Trans id="mainWindows.title">Messages</Trans>
          </Text>
        </Flex>

        <Flex variant="chat.window.header.buttons">
          <Button size="sm" variant="minimal" shape="rounded" sx={{ px: 1 }}>
            <Icon type={isVisible ? 'chevronDown' : 'chevronUp'} />
          </Button>
        </Flex>
      </Flex>

      <Flex variant="chat.window.list">
        {isVisible &&
          filteredChatUsersIds &&
          (!loading ? (
            <Suspense fallback={renderLoader()}>
              <LazyMainWindowContent canUseSupport={hasPermission} filteredChatUsersIds={filteredChatUsersIds} />
            </Suspense>
          ) : (
            renderLoader()
          ))}
      </Flex>
    </AnimatedFlex>
  );
};

export const MemoizedMainWindow = React.memo(MainWindow);
