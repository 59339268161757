import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { useMutation } from 'react-fetching-library';
import { Flex } from 'theme-ui';

import { languageSelector } from 'state/recoilState';
import { TO } from 'constants/routes';
import { FormCard } from 'layouts/Authentication';
import { postEmployerAccountInfoAction } from 'api/actions/user/userActions';
import { PostEmployerAccountInfoErrors, PostEmployerAccountInfoPayload } from 'api/actions/user/userActions.types';
import { signUpFormAtom } from 'state/signUp';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { setAuthorized, setIsSignUpInProgress } from 'context/auth/authActionCreators/authActionCreators';

export const EmployerStep3 = (): React.ReactElement => {
  const { push } = useHistory();
  const language = useRecoilValue(languageSelector);

  const signUpFormState = useRecoilValue(signUpFormAtom);
  const resetSignUpForm = useResetRecoilState(signUpFormAtom);

  const { mutate } = useMutation(postEmployerAccountInfoAction);

  const dispatch = useAuthDispatch();

  const { addSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async () => {
    if (
      signUpFormState &&
      signUpFormState.firstName &&
      signUpFormState.lastName &&
      signUpFormState.companyName &&
      signUpFormState.countryCode &&
      signUpFormState.industry &&
      signUpFormState.timeZoneId
    ) {
      const postData: PostEmployerAccountInfoPayload = {
        ...signUpFormState,
        industrySubcategory: signUpFormState.industrySubcategory
          ? parseInt(signUpFormState.industrySubcategory, 10)
          : undefined,
        industry: parseInt(signUpFormState.industry, 10),
        countryCode: signUpFormState.countryCode,
        timeZoneId: signUpFormState.timeZoneId,
        firstName: signUpFormState.firstName,
        lastName: signUpFormState.lastName,
        companyName: signUpFormState.companyName,
      };

      const { payload, error } = await mutate(postData);

      if (!error) {
        resetSignUpForm();
        dispatch(setIsSignUpInProgress(false));
        dispatch(setAuthorized());

        return true;
      }

      if (payload?.innerCode === PostEmployerAccountInfoErrors.InvalidTimeZoneId) {
        addSnackbar({
          variant: 'danger',
          message: i18n._(
            t({
              id: 'sign_up.employer.form.errors.time_zone',
              message: 'Incorrect time zone, please choose a correct one from the Select.',
            }),
          ),
        });

        push(`${TO.SIGN_UP__EMPLOYER__STEP[language]}/2`, {
          signUp: {
            inProgress: true,
          },
        });
      }
    }

    return false;
  }, [addSnackbar, dispatch, language, mutate, push, resetSignUpForm, signUpFormState]);

  useEffect(() => {
    handleSubmit().then(() => {
      push(TO.START[language]);
    });
  }, [handleSubmit, language, push]);

  return (
    <>
      <Flex
        sx={{
          flexGrow: 1,
          minHeight: '400px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <LoadingSpinnerCss size={5} />
        <Trans id="sign_up.employer.preparing_app">
          <FormCard.Lead>We're preparing your app.</FormCard.Lead>
          <FormCard.Lead>Please bear with us for a while.</FormCard.Lead>
        </Trans>
      </Flex>
    </>
  );
};
