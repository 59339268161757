import React, { useEffect, useRef, useState } from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { mergeRefs } from 'utils/mergeRefs';
import { Icon } from 'components/Icon/Icon';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { typedKeys } from 'utils/typedKeys';
import { setNativeValue } from 'utils/setNativeValue';

import { ElementGroup } from './ElementGroup';

export type InputOption = { label: string; id: string; isCreatable?: boolean };

type Props = Pick<FlexProps, 'sx'> &
  React.HTMLAttributes<HTMLInputElement> & {
    disabled?: boolean;
  };

const defaultProps: Partial<Props> = {
  disabled: false,
};

export const ColorPicker = React.forwardRef<HTMLInputElement, Props>(({ sx, disabled, ...props }: Props, ref) => {
  const [selectedColor, setSelectedColor] = useState<string>(UserSelectableColor[0]);

  const hiddenRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (hiddenRef.current) {
      const initialValue = hiddenRef.current.value;

      if (initialValue && UserSelectableColor[+initialValue]) {
        setSelectedColor(UserSelectableColor[+initialValue]);
      }
    }
  }, []);

  const handleColorChange = (color: string) => {
    setSelectedColor(color);
    setNativeValue(hiddenRef, UserSelectableColor[color as unknown as UserSelectableColor]);
  };

  return (
    <Flex sx={{ flexWrap: 'wrap', ...(sx && sx), ...(disabled && { pointerEvents: 'none' }) }}>
      <input
        {...props}
        ref={mergeRefs([ref, hiddenRef])}
        style={{ width: 0, opacity: 0, position: 'absolute' }}
        readOnly
      />
      <ElementGroup marginAt="end" marginValue="2px" direction="row">
        {typedKeys(UserSelectableColor)
          // eslint-disable-next-line no-restricted-globals
          .filter((key) => !isNaN(Number(UserSelectableColor[key])))
          .map((color) => (
            <Flex
              key={color}
              sx={{
                mt: '2px',
                alignItems: 'center',
                justifyContent: 'center',
                width: '24px',
                height: '24px',
                borderRadius: '6px',
                cursor: 'pointer',
                bg: color,
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={() => handleColorChange(color)}
            >
              {selectedColor === color && (
                <Flex
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 0,
                    width: '16px',
                    height: '16px',
                    bg: 'rgba(252,252,252,.7)',
                    borderRadius: 'circle',
                  }}
                >
                  <Icon type="selected" />
                </Flex>
              )}
            </Flex>
          ))}
      </ElementGroup>
    </Flex>
  );
});

ColorPicker.defaultProps = defaultProps;
