import { PublisherAddNotificationProps } from './types';

interface PublisherStore {
  addNotification: (notification: PublisherAddNotificationProps) => string | null;
  removeNotification: ((id: string) => void) | undefined;
  register: (param: RegisterParams) => void;
}

interface RegisterParams {
  addNotification: (notification: PublisherAddNotificationProps) => string;
  removeNotification: (id: string) => void;
  removeAllNotifications: () => void;
}

class Publisher implements PublisherStore {
  constructor() {
    this.add = null;
  }

  private add: ((notification: PublisherAddNotificationProps) => string) | null;

  public addNotification(notification: PublisherAddNotificationProps): string | null {
    if (!this.add) return null;
    return this.add(notification);
  }

  public removeNotification: ((id: string) => void) | undefined;

  public removeAllNotifications: (() => void) | undefined;

  public register(parameters: RegisterParams): void {
    const { addNotification, removeNotification, removeAllNotifications } = parameters;

    this.add = addNotification;
    this.removeNotification = removeNotification;
    this.removeAllNotifications = removeAllNotifications;
  }
}

export const notificationPublisher = new Publisher();
