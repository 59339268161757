import React from 'react';
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui';
import { Trans } from '@lingui/macro';

import { PieProgressBar } from 'components/PieProgressBar/PieProgressBar';
import { useTheme } from 'styles/useTheme';

type Props = {
  daysTillEnd: number;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const PaymentTicker = ({ daysTillEnd, sx }: Props): React.ReactElement => {
  const type = (() => {
    if (daysTillEnd <= 3) {
      return 'danger';
    }
    if (daysTillEnd <= 7) {
      return 'warning';
    }
    return 'default';
  })();

  const { theme } = useTheme();

  return (
    <Flex
      sx={{
        bg: theme.colors.paymentTicker[type].background,
        userSelect: 'none',
        borderRadius: 'pill',
        p: '2px',
        pr: 2,
        alignContent: 'space-between',
        justifyContent: 'center',
        flexShrink: 0,
        gap: 1,
        ...sx,
      }}
    >
      <PieProgressBar
        size="36px"
        borderWidth={3}
        background={theme.colors.paymentTicker[type].counter}
        fill={theme.colors.paymentTicker[type].background}
        value={daysTillEnd}
        valueMax={14}
        reverse
      >
        <Text sx={{ fontSize: 3, fontWeight: 'bold', color: theme.colors.paymentTicker[type].text }}>
          {daysTillEnd}
        </Text>
      </PieProgressBar>
      <Flex sx={{ alignItems: 'center', ml: '3px' }}>
        <Text
          sx={{ fontSize: 0, color: theme.colors.paymentTicker[type].text, lineHeight: '10px', whiteSpace: 'nowrap' }}
        >
          <Trans id="navbar.payment_ticker.label">
            days until
            <Text as="span" sx={{ fontSize: 'inherit', display: 'block' }}>
              trial ends
            </Text>
          </Trans>
        </Text>
      </Flex>
    </Flex>
  );
};

PaymentTicker.defaultProps = defaultProps;
