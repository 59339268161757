import React, { useRef } from 'react';
import { MemoryRouter, Route } from 'react-router';
import { Flex } from 'theme-ui';

import { DRAWER_ROUTES } from 'constants/memoryRoutes';
import { WorkTimeTrackerProvider } from 'context/workTimeTracker/WorkTimeTrackerProvider';

import { Drawer } from './Drawer';
import { LocationPermissionRoutes } from './TimeTracker/LocationPermissionRoutes';
import { DrawerRoutes } from './DrawerRoutes';

type Props = {
  children: React.ReactNode;
};

export const DrawerMemoryRouter = ({ children }: Props): React.ReactElement => {
  const drawerRef = useRef<HTMLDivElement | null>(null);

  return (
    <MemoryRouter>
      <WorkTimeTrackerProvider>
        <Flex ref={drawerRef}>
          <LocationPermissionRoutes />
          <Route
            path={DRAWER_ROUTES.DRAWER}
            render={() => (
              <Drawer drawerRef={drawerRef}>
                <DrawerRoutes />
              </Drawer>
            )}
          />
          {children}
        </Flex>
      </WorkTimeTrackerProvider>
    </MemoryRouter>
  );
};
