import React from 'react';

import { OrganizationSessionInitializer } from 'context/session/organizationSessionInitializer/OrganizationSessionInitializer';
import { ChatProvider } from 'context/chat/ChatProvider';
import { SignalRProvider } from 'context/signalR/SignalRProvider';

type Props = {
  children: React.ReactElement;
};

export const AuthorizedAppContext = ({ children }: Props): React.ReactElement => (
  <OrganizationSessionInitializer>
    <SignalRProvider>
      <ChatProvider>{children}</ChatProvider>
    </SignalRProvider>
  </OrganizationSessionInitializer>
);
