import React, { useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-fetching-library';
import { Resolver, useForm } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';

import { languageSelector } from 'state/recoilState';
import { Modal } from 'components/Modal/Modal';
import { setPayDetailsAction } from 'api/actions/employees/employeesActions';
import { delay } from 'utils/delay';
import { createEvent } from 'utils/createEvent';
import { PATH } from 'constants/routes';
import { ModalRoute } from 'routing/ModalRoute';
import { ManageRolesModal } from '../ManageRoles';
import { RatesFieldArray } from 'layouts/Team/forms/formsElements/RatesFieldArray';
import { PayRate, SetPayDetailsActionProps } from 'api/actions/employees/employeesActions.types';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useModal } from 'hooks/useModal/useModal';
import { dateTime } from 'utils/dateTime';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { getNumberField } from 'utils/getNumberField';

const forceCorrectTypes = ({
  rates,
}: Omit<SetPayDetailsActionProps, 'employeesIds'>): Omit<SetPayDetailsActionProps, 'employeesIds'> => ({
  rates: rates.map(({ normal, overtime, startDateUnix, ...rate }) => ({
    ...rate,
    startDateUnix: +startDateUnix,
    normal: getNumberField(normal),
    overtime: getNumberField(overtime),
  })),
});

type RatesFormState = {
  rates: PayRate[];
};

export const SetPayDetails = (): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const language = useRecoilValue(languageSelector);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation<{ ids?: string[] }>();
  const { ids } = state || {};
  const formRef = useRef<HTMLFormElement | null>(null);
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  const { control, register, handleSubmit } = useForm<RatesFormState>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      rates: [
        {
          startDateUnix: dateTime().unix(),
          normal: 0,
          overtime: 0,
          id: uuidv1(),
        },
      ],
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resolver,
  });

  const { mutate } = useMutation(setPayDetailsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: Omit<SetPayDetailsActionProps, 'employeesIds'>): Promise<boolean> => {
      if (!ids || !ids.length) return false;
      const { error: submitError } = await mutate({
        employeesIds: (ids as string[]) || [],
        ...forceCorrectTypes(body),
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_pay_bulk.set', message: 'Successfully set!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, ids, addSnackbar],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_pay_bulk.set_pay_details">Set pay details</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        sx={{
          mt: 3,
        }}
        ref={modalBodyRef}
      >
        <form ref={formRef} onSubmit={handleSubmit(onSubmit, onSubmitError)} noValidate>
          <RatesFieldArray register={register} control={control} scrollParentRef={modalBodyRef} />
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'team.set_pay_bulk.next', message: 'Next' }),
          },
        ]}
      />

      <ModalRoute size="sm" path={PATH.MANAGE_ROLES_MODAL[language]} fullHeight>
        <ManageRolesModal />
      </ModalRoute>
    </>
  );
};

const resolver: Resolver<RatesFormState> = async (values) => {
  const required = t({
    id: 'global.forms.required',
  });

  const { rates } = values;

  const isValid = (v?: string | number) => !_.isNil(v) && v !== '';
  // FIXME: same code as in UserEmploymentDetails.tsx, find a way to avoid copy/paste
  const ratesErrors = rates?.reduce(
    (acc, { normal, overtime }, index) => {
      const newErrors =
        !isValid(normal) || !isValid(overtime)
          ? {
              [index]: {
                ...(!isValid(normal) && {
                  normal: {
                    message: required,
                  },
                }),
                ...(!isValid(overtime) && {
                  overtime: {
                    message: required,
                  },
                }),
              },
            }
          : null;
      return {
        ...acc,
        ...(newErrors && newErrors),
      };
    },

    {},
  );

  return {
    errors: {
      ...(!_.isEmpty(ratesErrors) && { rates: ratesErrors }),
    },
    values,
  };
};
