import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTimer } from 'react-timer-hook';
import _ from 'lodash';

import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { SleepModeSplash } from 'Kiosk/pages/SleepModeSplash';
import { getCustomEventTargetElement } from 'utils/customEvents';
import { RESET_IS_IDLE_COUNTDOWN } from 'constants/customEventsNames';
import { idleStateAtom } from 'Kiosk/state/idleState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';

const minToMs = (min: number) => min * 60 * 1000;

const DEFAULT_EVENTS = ['mousemove', 'mousedown', 'keydown', 'touchstart'];

type Props = {
  load: boolean;
  timerInMinutes: number;
};

export const IdleObserver = ({ timerInMinutes, load }: Props): React.ReactElement => {
  const [showSplash, setShowSplash] = useState(false);

  const prevLoad = usePrevious(load);

  const generateTimestamp = useCallback(() => {
    const time = new Date();
    const ms = time.getTime() + minToMs(timerInMinutes);
    time.setTime(ms);

    return time;
  }, [timerInMinutes]);

  const { toggleVideoPlayback } = useRecoilValue(cameraStateAtom);
  const setIdleState = useSetRecoilState(idleStateAtom);

  const onTimeOver = () => {
    if (toggleVideoPlayback) toggleVideoPlayback(false);
    setShowSplash(true);
  };

  const { isRunning, start, restart, pause } = useTimer({
    autoStart: load,
    onExpire: onTimeOver,
    expiryTimestamp: generateTimestamp(),
  });

  const reset = useCallback(() => {
    restart(generateTimestamp(), true);
    setShowSplash(false);
    if (toggleVideoPlayback) toggleVideoPlayback(true);
  }, [generateTimestamp, restart, toggleVideoPlayback]);

  useEffect(() => {
    setIdleState({
      status: isRunning,
      pause,
      reset,
      start,
      stop: pause,
    });
  }, [isRunning, pause, reset, setIdleState, start, timerInMinutes]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const eventResetTimer = useCallback(
    _.throttle(() => {
      restart(generateTimestamp(), load);
    }, minToMs(1)),
    [],
  );

  const resumeTimer = useCallback(() => {
    if (!isRunning) {
      restart(generateTimestamp(), true);
    }
  }, [generateTimestamp, isRunning, restart]);

  useEffect(() => {
    if (load !== prevLoad) {
      if (load) {
        reset();
        return;
      }

      pause();
    }
  }, [load, pause, prevLoad, reset]);

  useEffect(() => {
    const customEventTargetElement = getCustomEventTargetElement();

    if (isRunning) {
      _.forEach(DEFAULT_EVENTS, (event) => {
        window.addEventListener(event, eventResetTimer);
      });

      customEventTargetElement.addEventListener(RESET_IS_IDLE_COUNTDOWN, resumeTimer);
    } else {
      _.forEach(DEFAULT_EVENTS, (event) => {
        window.removeEventListener(event, eventResetTimer);
      });
      customEventTargetElement.removeEventListener(RESET_IS_IDLE_COUNTDOWN, resumeTimer);
    }

    return () => {
      _.forEach(DEFAULT_EVENTS, (event) => {
        window.removeEventListener(event, eventResetTimer);
      });
      customEventTargetElement.removeEventListener(RESET_IS_IDLE_COUNTDOWN, resumeTimer);
    };
  }, [reset, start, isRunning, restart, resumeTimer, eventResetTimer, load]);

  return showSplash ? <SleepModeSplash /> : <></>;
};
