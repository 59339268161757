/* eslint-disable no-console */
import { useRecoilState, useRecoilValue } from 'recoil';
import { useCallback, useEffect } from 'react';

import { QrScannerState, qrScannerStateAtom, qrScannerWorkerAtom } from 'Kiosk/state/qrScannerState';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';

type Props = {
  onDecode?: (qr: string) => void;
};

type ReturnProps = QrScannerState;

export const useQrScanner = ({ onDecode }: Props): ReturnProps => {
  const webWorker = useRecoilValue(qrScannerWorkerAtom);
  const [state, setState] = useRecoilState(qrScannerStateAtom);
  const { isQrScannerRunning } = state;

  const { isCameraReady, isCameraStreaming } = useRecoilValue(cameraStateAtom);
  const prevCameraState = usePrevious({ isCameraReady, isCameraStreaming });

  const onDecodeCallback = useCallback(
    (event: MessageEvent) => {
      if (event.data === 'PONG') {
        console.log('Pong caught on event, so isQrScannerWorking = true');
        return;
      }

      const qr = event.data;

      if (onDecode) onDecode(qr);
    },
    [onDecode],
  );

  const pingWorker = useCallback(() => {
    if (webWorker) webWorker.postMessage('PING');
  }, [webWorker]);

  const decode = useCallback(
    (frameData: ImageData) => {
      if (webWorker) webWorker.postMessage(frameData);
    },
    [webWorker],
  );

  const startQrScanner = useCallback(() => {
    console.log('useQrScanner', '[ACTION - STARTED]');
    setState((prevState) => ({ ...prevState, isQrScannerRunning: true }));
  }, [setState]);

  const stopQrScanner = useCallback(() => {
    console.log('useQrScanner', '[ACTION - STOPPED]');
    setState((prevState) => ({ ...prevState, isQrScannerRunning: false }));
  }, [setState]);

  const toggleQrScanner = useCallback(() => {
    setState((prevState) => {
      console.log('useQrScanner', '[ACTION - TOGGLED]', !prevState.isQrScannerRunning);
      return { ...prevState, isQrScannerRunning: !prevState.isQrScannerRunning };
    });
  }, [setState]);

  useEffect(() => {
    if (webWorker) {
      setState({
        isQrScannerRunning: true,
        startQrScanner,
        stopQrScanner,
        toggleQrScanner,
        decode,
        pingWorker,
      });
    }
  }, [decode, pingWorker, setState, startQrScanner, stopQrScanner, toggleQrScanner, webWorker]);

  useEffect(() => {
    if (
      (prevCameraState && prevCameraState.isCameraReady !== isCameraReady) ||
      (prevCameraState && prevCameraState.isCameraStreaming !== isCameraStreaming)
    ) {
      if (!isCameraReady || !isCameraStreaming) {
        stopQrScanner();
      }
      if (!isQrScannerRunning) {
        startQrScanner();
      }
    }
  }, [isCameraReady, isCameraStreaming, isQrScannerRunning, prevCameraState, startQrScanner, stopQrScanner]);

  useEffect(() => {
    if (webWorker) {
      if (isQrScannerRunning) {
        webWorker.addEventListener('message', onDecodeCallback);
      }

      if (!isQrScannerRunning) {
        webWorker.removeEventListener('message', onDecodeCallback);
      }
    }

    return () => {
      if (webWorker) {
        webWorker.removeEventListener('message', onDecodeCallback);
      }
    };
  }, [onDecodeCallback, isQrScannerRunning, webWorker]);

  return state;
};
