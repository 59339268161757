import React, { useCallback, useEffect } from 'react';
import { Flex } from 'theme-ui';
import { useForm } from 'react-hook-form';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TextInput } from 'components/ui/TextInput';
import { Switch } from 'components/ui/Switch';
import { AddTimeOffTypeActionProps, UpdateTimeOffTypeActionProps } from 'api/actions/timeoff/timeOffActions.types';

type Props = {
  onSubmit: (data: AddTimeOffTypeActionProps | UpdateTimeOffTypeActionProps) => Promise<void>;
  setLoading: (loading: boolean) => void;
  defaultValues?: UpdateTimeOffTypeActionProps;
};

const defaultProps: Partial<Props> = {
  defaultValues: undefined,
};

export const AddEditTimeOffTypeForm = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, setLoading, defaultValues }: Props, ref): React.ReactElement => {
    useLingui();
    const {
      register,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useForm({ defaultValues });

    const handleSubmitCallback = useCallback(
      (data: AddTimeOffTypeActionProps | UpdateTimeOffTypeActionProps) => {
        onSubmit(data);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    useEffect(() => {
      if (!defaultValues) {
        setValue('isActive', true);
      }
    }, [defaultValues, setValue]);

    return (
      <form ref={ref} onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)}>
        <Flex sx={{ gap: 2 }}>
          <TextInput
            {...register('name', {
              required: t({
                id: 'global.forms.required',
              }),
            })}
            id="name"
            label={t({ id: 'global.forms.name' })}
            placeholder={t({ id: 'global.forms.name' })}
            size="sm"
            error={!!errors.name}
            errorMessage={errors.name?.message}
            sx={{ flexGrow: 1 }}
          />
          <TextInput
            {...register('abbreviation', {
              required: t({
                id: 'global.forms.required',
              }),
            })}
            id="abbreviation"
            label={t({ id: 'settings.abbrevation' })}
            placeholder={t({ id: 'settings.abbrevation' })}
            size="sm"
            error={!!errors.abbreviation}
            errorMessage={errors.abbreviation?.message}
          />
        </Flex>
        <Switch
          {...register('isPaid')}
          label={t({ id: 'settings.time_off_type.paid', message: 'Is this a paid time off type?' })}
          additionalInfo={t({
            id: 'settings.time_off_type.paid_description',
            message: 'Only works if your employee has a defined rate.',
          })}
          size="sm"
          wrapperSx={{ mt: 4 }}
        />
        <Switch
          {...register('isLimitable')}
          label={t({ id: 'settings.is_limitable' })}
          additionalInfo={t({
            id: 'settings.is_limitable_description',
          })}
          size="sm"
          wrapperSx={{ mt: 2 }}
        />
      </form>
    );
  },
);

AddEditTimeOffTypeForm.defaultProps = defaultProps;
