import React from 'react';
import { Flex } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import { Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Helmet } from 'react-helmet';

import { NavLink } from 'layouts/Settings/NavLink';
import { PATH } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { Divider } from 'components/Divider/Divider';
import { ElementGroup } from 'components/ui/ElementGroup';
import { APP_NAME } from 'constants/common';
import { settingsPageDataSelector, settingsProfileFormDefaultValuesSelector } from 'state/settings';
import { Container } from 'components/ui/Container';

import { Profile } from './User/Profile';
import { ChangePassword } from './User/ChangePassword';
import { Notifications } from './User/Notifications';
import { Preferences } from './User/Preferences';
import { Organization } from './Organization/Organization';
import { TimeTracking } from './Organization/TimeTracking';
import { Schedules } from './Organization/Schedules';
import { Notifications as OrganizationNotifications } from './Organization/Notifications';
import { Advanced } from './Organization/Advanced';
import { Requests } from './Organization/Requests';
import { Integrations } from './Integrations/Integrations';

const marginTop = '16px';

export const SettingsPage = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const settingsPageData = useRecoilValue(settingsPageDataSelector);
  const employeeData = useRecoilValue(settingsProfileFormDefaultValuesSelector);

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'settings.page_title', message: `Settings - ${APP_NAME}` }))}</title>
      </Helmet>

      <Flex sx={{ flexDirection: 'row', flexGrow: 1, overflow: 'hidden' }}>
        <Flex
          as="aside"
          sx={{
            p: 4,
            flexDirection: 'column',
            maxWidth: '360px',
            width: '100%',
            overflow: 'auto',
            bg: 'backgrounds.levelOne',
          }}
        >
          <NavLink
            to={PATH.SETTINGS__USER[language]}
            label={settingsPageData?.user.parsedName}
            additionalLabel={i18n._(
              t({
                id: 'settings.user.additional_label',
                message: 'Your profile, photo, mail & phone',
              }),
            )}
            avatarProps={{ name: settingsPageData?.user.name, image: employeeData?.avatarUrl }}
            shape="rounded"
          />

          <ElementGroup marginValue="1px" direction="column" showAsList wrapperSx={{ marginTop, width: '100%' }}>
            <NavLink
              to={PATH.SETTINGS__USER__PREFERENCES[language]}
              label={i18n._(
                t({
                  id: 'settings.user.preferences',
                  message: 'Preferences',
                }),
              )}
              sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
            />
            <NavLink
              to={PATH.SETTINGS__USER__NOTIFICATIONS[language]}
              label={i18n._(
                t({
                  id: 'settings.user.notifications',
                  message: 'Notifications',
                }),
              )}
            />
            <NavLink
              to={PATH.SETTINGS__USER__CHANGE_PASSWORD[language]}
              label={i18n._(
                t({
                  id: 'settings.user.change_password',
                  message: 'Change password',
                }),
              )}
              sx={{ borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }}
            />
          </ElementGroup>

          <NavLink
            to={PATH.SETTINGS__INTEGRATIONS[language]}
            label={i18n._(
              t({
                id: 'settings.integrations',
                message: 'Integrations',
              }),
            )}
            shape="rounded"
            sx={{ marginTop }}
          />

          <Divider sx={{ marginTop }} borderColor="secondary" />

          <NavLink
            to={PATH.SETTINGS__ORGANIZATION[language]}
            label={settingsPageData?.organization.name}
            additionalLabel={i18n._(
              t({
                id: 'settings.organization.additional_label',
                message: 'Your organization',
              }),
            )}
            avatarProps={{
              image: settingsPageData?.organization.avatarUrl,
              name: settingsPageData
                ? { firstName: settingsPageData.organization.name[0], surname: settingsPageData.organization.name[1] }
                : undefined,
            }}
            shape="rounded"
            sx={{ marginTop }}
          />

          <ElementGroup marginValue="1px" direction="column" showAsList wrapperSx={{ marginTop, width: '100%' }}>
            <NavLink
              to={PATH.SETTINGS__ORGANIZATION__TIME_TRACKING[language]}
              label={i18n._(
                t({
                  id: 'settings.organization.time_tracking',
                  message: 'Time tracking',
                }),
              )}
              sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
            />
            <NavLink
              to={PATH.SETTINGS__ORGANIZATION__SCHEDULES[language]}
              label={i18n._(
                t({
                  id: 'settings.organization.schedules',
                  message: 'Schedules',
                }),
              )}
            />
            <NavLink
              to={PATH.SETTINGS__ORGANIZATION__REQUESTS[language]}
              label={i18n._(
                t({
                  id: 'settings.organization.requests',
                  message: 'Requests',
                }),
              )}
              sx={{ borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }}
            />
          </ElementGroup>

          <ElementGroup marginValue="1px" direction="column" showAsList wrapperSx={{ marginTop, width: '100%' }}>
            <NavLink
              to={PATH.SETTINGS__ORGANIZATION__NOTIFICATIONS[language]}
              label={i18n._(
                t({
                  id: 'settings.user.notifications',
                }),
              )}
              sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
            />
            <NavLink
              to={PATH.SETTINGS__ORGANIZATION__ADVANCED[language]}
              label={i18n._(
                t({
                  id: 'settings.organization.advanced',
                  message: 'Advanced',
                }),
              )}
              sx={{ borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }}
            />
          </ElementGroup>
        </Flex>

        <Flex as="main" sx={{ flexGrow: 1, p: 4, flexDirection: 'column', gap: 4, overflow: 'auto' }}>
          <Container
            sx={{
              ml: 0,
              gap: 'inherit',
              // bg: 'backgrounds.levelFour',
              // p: 3,
              // borderRadius: 'default',
            }}
          >
            <Switch>
              <Route path={PATH.SETTINGS__USER__PREFERENCES[language]} component={Preferences} />
              <Route path={PATH.SETTINGS__USER__NOTIFICATIONS[language]} component={Notifications} />
              <Route path={PATH.SETTINGS__USER__CHANGE_PASSWORD[language]} component={ChangePassword} />
              <Route path={PATH.SETTINGS__USER[language]} component={Profile} />
              <Route path={PATH.SETTINGS__INTEGRATIONS[language]}>
                <Integrations />
              </Route>
              <Route path={PATH.SETTINGS__ORGANIZATION__TIME_TRACKING[language]}>
                <TimeTracking />
              </Route>
              <Route path={PATH.SETTINGS__ORGANIZATION__SCHEDULES[language]}>
                <Schedules />
              </Route>
              <Route path={PATH.SETTINGS__ORGANIZATION__REQUESTS[language]}>
                <Requests />
              </Route>
              <Route path={PATH.SETTINGS__ORGANIZATION__NOTIFICATIONS[language]}>
                <OrganizationNotifications />
              </Route>
              <Route path={PATH.SETTINGS__ORGANIZATION__ADVANCED[language]}>
                <Advanced />
              </Route>
              <Route path={PATH.SETTINGS__ORGANIZATION[language]}>
                <Organization />
              </Route>
            </Switch>
          </Container>
        </Flex>
      </Flex>
    </>
  );
};
