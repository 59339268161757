import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ImportHolidaysForm } from '../forms/ImportHolidaysForm';
import { useModal } from 'hooks/useModal/useModal';
import { createEvent } from 'utils/createEvent';
import { importHolidaysAction, importPreviewHolidaysAction } from 'api/actions/holidays/holidaysActions';
import { ImportHolidaysProps } from 'api/actions/holidays/holidaysActions.types';
import { refreshSelectorFamily } from 'state/settings';
import { addSnackbar } from 'SnackbarHub/actions';
import { selectedRowsIdsSelectorFamily } from 'state/list';

export const ImportHolidaysModal = (): React.ReactElement => {
  useLingui();
  const refreshHolidaysList = useRecoilValue(refreshSelectorFamily('HOLIDAYS_LIST'));
  const selectedHolidays = useRecoilValue(selectedRowsIdsSelectorFamily('IMPORT_HOLIDAYS'));
  const { handleClose } = useModal();
  const { mutate: mutateHolidayImportPreview } = useMutation(importPreviewHolidaysAction);
  const { mutate: mutateHolidayImport } = useMutation(importHolidaysAction);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: ImportHolidaysProps): Promise<void> => {
      const { error: submitError } = await mutateHolidayImport(data);

      if (!submitError) {
        if (handleClose) {
          handleClose();
          refreshHolidaysList();
        }
        addSnackbar({
          message: t({
            id: 'settings.holiday.import_success',
            message: 'Imported holidays',
          }),
          variant: 'success',
        });
      }

      if (submitError) {
        addSnackbar({
          message: t({
            id: 'settings.holiday.import_danger',
            message: "Couldn't import holidays",
          }),
          variant: 'danger',
        });
      }
      setLoading(false);
    },
    [handleClose, mutateHolidayImport, refreshHolidaysList],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.holidays.import_google">Import holidays from Google</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImportHolidaysForm
          ref={formRef}
          onSubmit={onSubmit}
          mutate={mutateHolidayImportPreview}
          setLoading={setLoading}
          selectedHolidays={selectedHolidays}
        />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'team.import' }),
            disabled: !selectedHolidays.length,
          },
        ]}
      />
    </>
  );
};
