import React from 'react';

const LazyTimeClocks = React.lazy(() =>
  import('./TimeClocks').then(({ TimeClocks }) => ({
    default: TimeClocks,
  })),
);

export const TimeClocksRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<></>}>
      <LazyTimeClocks />
    </React.Suspense>
  ),
);
