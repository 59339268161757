/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { atomFamily, selectorFamily } from 'recoil';
import _ from 'lodash';

import { guardRecoilDefaultValue } from 'utils/guardRecoilDefaultValue';
import {
  ListColumn,
  ListItem,
  StickyListProps,
  SortingOrder,
  IsRowUncheckableValidator,
} from 'components/StickyList/types';

type ListMap = Map<string, ListItem>;
type ListName = StickyListProps['name'];
type SelectedRows = Map<ListItem['id'], boolean>;

type CommonAtomFamilyConfig = {
  listName: ListName;
  itemId: ListItem['id'];
};
type RowSelectSelectorFamilyConfig = CommonAtomFamilyConfig;

//
// LIST
//

export const sortedListMapAtomFamily = atomFamily<ListMap | null, ListName>({
  key: 'sortedListMap',
  default: null,
});

export const sortedListMapSelectorFamily = selectorFamily<ListMap | null, ListName>({
  key: 'sortedListMapSelector',
  get:
    (listName) =>
    ({ get }) =>
      get(sortedListMapAtomFamily(listName)),
  set:
    (listName) =>
    ({ get, set }, newValue) => {
      if (guardRecoilDefaultValue(newValue)) return;

      const globalSelectAtom = selectAllAtomFamily(listName);
      const sortedListMapAtom = sortedListMapAtomFamily(listName);
      const sortedListMap = get(sortedListMapAtom);

      if (!newValue) {
        set(sortedListMapAtom, null);
        return;
      }

      set(sortedListMapAtom, newValue);

      if (get(globalSelectAtom) && sortedListMap && newValue.size !== sortedListMap.size) {
        set(globalSelectAtom, false);
      }
    },
});

//
// STICKY LIST ACTIONS
//
type ScrollFuncType = (columnIndex: number, row: number) => void;
type StickyListActionsType<K = Map<string, any>> = {
  scrollTo: ScrollFuncType;
  list: K | null;
  columns: string[];
};
export const stickyListActionsAtomFamily = atomFamily<StickyListActionsType | null, ListName>({
  key: 'stickyListActionsAtomFamily',
  default: null,
});

//
// SELECT ROW & SELECT ALL
//

export const selectAllAtomFamily = atomFamily<boolean, ListName>({
  key: 'selectAll',
  default: false,
});

export const selectedRowsAtomFamily = atomFamily<{ value: SelectedRows } | null, ListName>({
  key: 'selectedRows',
  default: null,
});

export const selectedRowsIdsSelectorFamily = selectorFamily<ListItem['id'][], ListName>({
  key: 'selectedRowsIdsSelector',
  get:
    (listName) =>
    ({ get }) => {
      const selectedRows = get(selectedRowsAtomFamily(listName));

      return selectedRows ? [...selectedRows.value.keys()] : [];
    },
  set:
    (listName) =>
    ({ set }, newValue) => {
      if (guardRecoilDefaultValue(newValue)) return;
      const selectedRowsAtom = selectedRowsAtomFamily(listName);
      if (!newValue.length) {
        set(selectedRowsAtom, { value: new Map() });
        return;
      }

      const idsMap = new Map();
      newValue.forEach((id) => idsMap.set(id, true));

      set(selectedRowsAtom, { value: idsMap });
    },
});

export const rowSelectSelectorFamily = selectorFamily<boolean, RowSelectSelectorFamilyConfig>({
  key: 'rowSelectSelector',
  get:
    ({ itemId, listName }) =>
    ({ get }) =>
      get(selectedRowsAtomFamily(listName))?.value.get(itemId) || false,
  set:
    ({ itemId, listName }) =>
    ({ set, get }, newValue) => {
      const idsMap = get(selectedRowsAtomFamily(listName))?.value || new Map();

      if (newValue) {
        idsMap.set(itemId, newValue);

        set(selectedRowsAtomFamily(listName), { value: idsMap });
      } else {
        idsMap.delete(itemId);
        set(selectedRowsAtomFamily(listName), { value: idsMap });
      }

      set(selectAllAtomFamily(listName), false);
    },
});

export const isRowUncheckableValidatorAtomFamily = atomFamily<{ value: IsRowUncheckableValidator } | null, ListName>({
  key: 'isRowUncheckableValidator',
  default: null,
});

export const selectAllSelectorFamily = selectorFamily<boolean, ListName>({
  key: 'selectAllSelector',
  get:
    (listName) =>
    ({ get }) =>
      get(selectAllAtomFamily(listName)),
  set:
    (listName) =>
    ({ set, get }, newValue) => {
      const selectAllAtom = selectAllAtomFamily(listName);
      const sortedListMapAtom = sortedListMapAtomFamily(listName);
      const sortedListMap = get(sortedListMapAtom);
      const selectedRowsAtom = selectedRowsAtomFamily(listName);
      const isRowUncheckableValidatorAtom = isRowUncheckableValidatorAtomFamily(listName);
      const isRowUncheckableValidator = get(isRowUncheckableValidatorAtom);

      if (!sortedListMap) return;

      if (newValue) {
        const idsList = [...sortedListMap.keys()];
        const filteredIdsList = isRowUncheckableValidator
          ? idsList.filter((id) => !isRowUncheckableValidator.value(id))
          : null;
        const newMap = new Map(_.map(filteredIdsList || idsList, (id) => [id, true]));
        set(selectedRowsAtom, { value: newMap });
        set(selectAllAtom, true);
      } else {
        set(selectedRowsAtom, null);
        set(selectAllAtom, false);
      }
    },
});

//
// SORT BY
//

type SortingState = {
  columnKey: ListColumn['key'];
  order: SortingOrder;
} | null;
type SortingOrderSelectorFamilyConfig = {
  listName: ListName;
  columnKey: ListColumn['key'];
};

export const sortingStateAtomFamily = atomFamily<SortingState, ListName>({
  key: 'sortingState',
  default: null,
});

export const sortingOrderSelectorFamily = selectorFamily<SortingOrder | null, SortingOrderSelectorFamilyConfig>({
  key: 'sortingOrder',
  get:
    ({ listName, columnKey }) =>
    ({ get }) => {
      const sortingState = get(sortingStateAtomFamily(listName));

      if (!sortingState) return null;

      const { columnKey: name, order } = sortingState;

      return _.isEqual(name, columnKey) ? order : null;
    },
  set:
    ({ listName, columnKey }) =>
    ({ set, get }, newValue) => {
      if (!newValue || guardRecoilDefaultValue(newValue)) return;

      const sortingStateAtom = sortingStateAtomFamily(listName);
      const sortingState = get(sortingStateAtom);

      if (!_.isEqual(sortingState, newValue)) {
        set(sortingStateAtom, {
          columnKey,
          order: newValue,
        });
      }
    },
});
