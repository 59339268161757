import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import _ from 'lodash';

import { Modal } from 'components/Modal/Modal';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { ModalProps } from 'components/Modal/types';

interface Props extends RouteProps {
  children: React.ReactElement;
  size?: ModalProps['size'];
  unClosable?: ModalProps['unClosable'];
  sx?: ModalProps['sx'];
  replaceMode?: ModalProps['replaceMode'];
  fullHeight?: ModalProps['fullHeight'];
}

const defaultProps: Partial<Props> = {
  size: 'default',
  unClosable: false,
  sx: undefined,
  replaceMode: undefined,
  fullHeight: undefined,
};

export const ModalRoute = ({
  fullHeight,
  unClosable,
  sx,
  replaceMode,
  children,
  path,
  size,
  ...props
}: Props): React.ReactElement => {
  const match = useCleanRouteMatch();

  const getPaths = (): RouteProps['path'] => {
    if (_.isArray(path)) {
      return _.map(path, (routePath) => `${match}${routePath}`);
    }

    return `${match}${path}`;
  };

  return (
    <Route
      path={getPaths()}
      render={(routeProps) => (
        <Modal
          onCloseRoute={match}
          fullHeight={fullHeight}
          unClosable={unClosable}
          sx={sx}
          size={size}
          replaceMode={replaceMode}
          {...routeProps}
        >
          {children}
        </Modal>
      )}
      {...props}
    />
  );
};

ModalRoute.defaultProps = defaultProps;
