import React, { useEffect, useRef } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { Select, SelectProps } from '../Select/Select';
import { mergeRefs } from 'utils/mergeRefs';
import { emitUpdateFieldView } from 'utils/emitUpdateFieldView';
import { delay } from 'utils/delay';
import { timeZoneListAtom } from 'state/signUp';
import { fetchTimeZoneListAction } from 'api/actions/data/dataActions';

type Props = Omit<SelectProps, 'options'>;

export const TimeZoneSelect = React.forwardRef<HTMLInputElement, Props>(
  ({ ...props }: Props, ref): React.ReactElement => {
    const [timeZoneList, setTimeZoneList] = useRecoilState(timeZoneListAtom);

    const selectRef = useRef<HTMLInputElement | null>(null);

    const { query } = useClient();

    useEffect(() => {
      const getTimeZoneList = async () => {
        const { error, payload } = await query(fetchTimeZoneListAction());

        if (!error && payload) {
          setTimeZoneList(payload);

          const updateSelectView = async () => {
            await delay(0);
            emitUpdateFieldView(selectRef);
          };

          updateSelectView();
        }
      };

      if (!timeZoneList) getTimeZoneList();
    }, [query, setTimeZoneList, timeZoneList]);

    return <Select ref={mergeRefs([ref, selectRef])} {...props} options={timeZoneList || []} />;
  },
);
