import React, { useCallback, useMemo } from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { dateTime } from 'utils/dateTime';

interface Option {
  id: [number, number];
  label: string;
}

type Props = {
  onClickCallback: (datesUnix: number[]) => void;
  minDateUnix: number;
  maxDateUnix: number;
  todayUnix: number;
};

export const QuickSelect = ({ onClickCallback, maxDateUnix, minDateUnix, todayUnix }: Props): React.ReactElement => {
  const date = useMemo(() => dateTime(todayUnix, { disableTimezone: true }), [todayUnix]);

  const QUICKSELECT_DEFAULT_OPTIONS: Option[] = useMemo(
    () => [
      {
        id: [date.startOf('day').unix(), date.endOf('day').startOf('day').unix()],
        label: i18n._(
          t({
            id: 'calendar.today',
            message: 'Today',
          }),
        ),
      },
      {
        id: [date.startOf('week').unix(), date.endOf('week').startOf('day').unix()],
        label: i18n._(
          t({
            id: 'calendar.this_week',
            message: 'This week',
          }),
        ),
      },
      {
        id: [date.startOf('month').unix(), date.endOf('month').startOf('day').unix()],
        label: i18n._(
          t({
            id: 'calendar.this_month',
            message: 'This month',
          }),
        ),
      },
      {
        id: [date.add(-1, 'week').startOf('week').unix(), date.add(-1, 'week').endOf('week').startOf('day').unix()],
        label: i18n._(
          t({
            id: 'calendar.last_week',
            message: 'Last week',
          }),
        ),
      },
      {
        id: [date.add(-1, 'month').startOf('month').unix(), date.add(-1, 'month').endOf('month').startOf('day').unix()],
        label: i18n._(
          t({
            id: 'calendar.last_month',
            message: 'Last month',
          }),
        ),
      },
      {
        id: [date.add(-1, 'week').startOf('week').unix(), date.endOf('week').startOf('day').unix()],
        label: i18n._(
          t({
            id: 'calendar.this_and_last_week',
            message: 'This & last week',
          }),
        ),
      },
      {
        id: [date.add(-1, 'month').startOf('month').unix(), date.endOf('month').startOf('day').unix()],
        label: i18n._(
          t({
            id: 'calendar.this_and_last_month',
            message: 'This & last month',
          }),
        ),
      },
    ],
    [date],
  );

  const onClick = useCallback(
    (selectedOption: Option['id']) => {
      onClickCallback(selectedOption);
    },
    [onClickCallback],
  );

  const renderOptions = useCallback(
    () =>
      QUICKSELECT_DEFAULT_OPTIONS.flatMap((option) => {
        const [startDateUnix, endDateUnix] = option.id;

        if (startDateUnix >= minDateUnix && endDateUnix <= maxDateUnix) {
          return (
            <Button
              variant="lightGrey"
              type="button"
              key={option.label}
              size="xs"
              shape="rounded"
              onClick={() => {
                onClick(option.id);
              }}
            >
              {option.label}
            </Button>
          );
        }

        return [];
      }),
    [QUICKSELECT_DEFAULT_OPTIONS, maxDateUnix, minDateUnix, onClick],
  );

  return (
    <ElementGroup direction="row" marginAt="end" marginValue="0.25rem" rowSpace="0.25rem" wrapperSx={{}}>
      {renderOptions()}
    </ElementGroup>
  );
};
