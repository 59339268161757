/** @jsxImportSource theme-ui */

import React, { useCallback, useEffect } from 'react';
import { FlexProps, Heading } from 'theme-ui';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Switch } from 'components/ui/Switch';
import { EmployeeAdvancedDetailsFormState } from 'state/team';
import { FetchTagInheritedFeaturesResponse } from 'api/actions/employees/employeesActions.types';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { getNumberField } from 'utils/getNumberField';
import { Feature, FeaturesTypes } from 'api/actions/organizationSession/organizationSessionActions.types';

import { AdvancedFeaturesFieldArray } from './formsElements/AdvancedFeaturesFieldArray';

const forceCorrectTypes = ({
  features,
  visibilityLevel,
  ...rest
}: Omit<EmployeeAdvancedDetailsFormState, 'customEmployeeId'>): Omit<
  EmployeeAdvancedDetailsFormState,
  'customEmployeeId'
> => ({
  ...rest,
  features: features.map((f: Feature) => ({ ...f, value: !!f.value })),
  visibilityLevel: getNumberField(visibilityLevel),
});

type Props = {
  tagInheritedFeatures?: FetchTagInheritedFeaturesResponse;
  shouldReactToTagInheritedFeaturesChanged?: boolean;
  defaultValues?: Partial<Omit<EmployeeAdvancedDetailsFormState, 'customEmployeeId'>>;
  onSubmit: (props: Omit<EmployeeAdvancedDetailsFormState, 'customEmployeeId'>) => Promise<boolean>;
  onSubmitError?: () => void;
  setLoading: (isLoading: boolean) => void;
  saveFormState?: (formState: Omit<EmployeeAdvancedDetailsFormState, 'customEmployeeId'>) => void;
  wait?: boolean;
} & Pick<FlexProps, 'sx'>;

const defaultProps = {
  defaultValues: undefined,
  tagInheritedFeatures: undefined,
  shouldReactToTagInheritedFeaturesChanged: undefined,
  saveFormState: undefined,
  onSubmitError: undefined,
  wait: false,
};

export const UserAdvancedDetails = React.forwardRef<HTMLFormElement, Props>(
  (
    {
      onSubmit,
      onSubmitError,
      defaultValues,
      tagInheritedFeatures,
      shouldReactToTagInheritedFeaturesChanged,
      saveFormState,
      setLoading,
      sx,
      wait,
      ...restProps
    }: Props,
    ref,
  ) => {
    useLingui();
    const getDefaultValues = useCallback(() => {
      if (!defaultValues) {
        if (!tagInheritedFeatures) return undefined;
        return (
          {
            ...tagInheritedFeatures,
            hideOnList: false,
            features: tagInheritedFeatures.features || [],
          } || undefined
        );
      }
      if (!tagInheritedFeatures) {
        return (
          {
            ...defaultValues,
            hideOnList: !!defaultValues.hideOnList,
            features: defaultValues.features || [],
          } || undefined
        );
      }
      const { features: newFeatures, visibilityLevelInheritedFromTag: newVisibilityLevelInheritedFromTag } =
        tagInheritedFeatures;

      const { features, visibilityLevelInheritedFromTag, visibilityLevel, hideOnList, ...restDefaultValues } =
        defaultValues;

      const limitDataVisibilityFeature = _.find(features, { type: FeaturesTypes.limitDataVisibility });
      return {
        ...restDefaultValues,
        hideOnList: !!hideOnList,
        ...(!_.isNil(newVisibilityLevelInheritedFromTag) && {
          visibilityLevelInheritedFromTag: newVisibilityLevelInheritedFromTag,
        }),
        visibilityLevel: limitDataVisibilityFeature?.inheritFromTag
          ? newVisibilityLevelInheritedFromTag
          : visibilityLevel,
        features:
          features?.map(({ value, inheritFromTag, type, inheritedValue }) => {
            const tagInheritedFeature = _.find(newFeatures, { type });

            return {
              inheritFromTag,
              type,
              value: inheritFromTag ? !!tagInheritedFeature?.inheritedValue : value,
              inheritedValue: tagInheritedFeature?.inheritedValue || inheritedValue,
            };
          }) || [],
      };
    }, [defaultValues, tagInheritedFeatures]);
    const { control, getValues, register, handleSubmit, watch, setValue } = useForm({
      defaultValues: getDefaultValues(),
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    useEffect(() => {
      if (shouldReactToTagInheritedFeaturesChanged && tagInheritedFeatures) {
        const { features: newFeatures, visibilityLevelInheritedFromTag: newVisibilityLevelInheritedFromTag } =
          tagInheritedFeatures;

        const { features, visibilityLevelInheritedFromTag } = getValues();

        setValue(
          'visibilityLevelInheritedFromTag',
          !_.isNil(newVisibilityLevelInheritedFromTag)
            ? newVisibilityLevelInheritedFromTag
            : visibilityLevelInheritedFromTag,
          { shouldDirty: true },
        );
        features?.forEach(({ inheritFromTag, type, inheritedValue }, index) => {
          const tagInheritedFeature = _.find(newFeatures, { type });
          if (+type === FeaturesTypes.limitDataVisibility && inheritFromTag) {
            setValue('visibilityLevel', newVisibilityLevelInheritedFromTag, {
              shouldDirty: true,
            });
          }
          if (inheritFromTag) {
            setValue(`features.${index}.value`, !!tagInheritedFeature?.inheritedValue, {
              shouldDirty: true,
            });
          }

          setValue(
            `features.${index}.inheritedValue`,
            !_.isNil(tagInheritedFeature?.inheritedValue) ? !!tagInheritedFeature?.inheritedValue : !!inheritedValue,
            {
              shouldDirty: true,
            },
          );
        });
      }
    }, [tagInheritedFeatures, shouldReactToTagInheritedFeaturesChanged, setValue, getValues]);

    const handleSubmitCallback = useCallback(
      (props) => {
        onSubmit(forceCorrectTypes(props));
      },
      [onSubmit],
    );
    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
      if (onSubmitError) {
        onSubmitError();
      }
    }, [setLoading, onSubmitError]);

    useEffect(
      () => () => {
        if (saveFormState) {
          saveFormState(getValues());
        }
      },
      [saveFormState, getValues],
    );

    if (wait) return <CenteredLoadingSpinner />;

    return (
      <form
        {...restProps}
        sx={{ flexGrow: 2, ...(sx && sx) }}
        ref={ref}
        onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)}
        noValidate
      >
        <Heading variant="heading4.withMargins">
          <Trans id="team.user.advanced_details">Advanced details</Trans>
        </Heading>
        <Switch
          wrapperSx={{ mt: 2 }}
          size="sm"
          label={t({ id: 'team.user.hide_on_all', message: 'Hide on all lists' })}
          additionalInfo={t({
            id: 'team.user.hide_on_all.info',
            message: 'User will be visible only by Administrators',
          })}
          withDivider
          {...register('hideOnList')}
        />

        <AdvancedFeaturesFieldArray
          usedInEmployeeForm
          register={register}
          control={control}
          watch={watch}
          setValue={setValue}
        />
      </form>
    );
  },
);

UserAdvancedDetails.defaultProps = defaultProps;
