import React from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { Flex, Text } from 'theme-ui';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

export const PageNotFound = (): React.ReactElement => {
  const history = useHistory();
  const language = useRecoilValue(languageSelector);
  return (
    <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1 0' }}>
      <Text variant="heading1.withMargins" sx={{ textAlign: 'center' }} as="h1">
        404
        <br />
        Page Not Found
      </Text>

      <button
        type="button"
        onClick={() => {
          history.push(TO.START[language]);
        }}
      >
        Return to start
      </button>
    </Flex>
  );
};
