import { Trans } from '@lingui/macro';
import React from 'react';
import { BoxOwnProps, BoxProps, Flex, Text } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { dateTime } from 'utils/dateTime';

import { TotalOf } from './TotalOf';

type Props = BoxOwnProps &
  BoxProps & {
    dateUnix: number;
    duration: {
      days: number;
      hours: number;
      minutes: number;
    };
  };

export const DateDisplay = ({ dateUnix, duration, ...props }: Props): React.ReactElement => (
  <Flex {...props} sx={{ gap: 4, ...(props.sx && props.sx) }}>
    <Flex sx={{ flexDirection: 'column', gap: 2 }}>
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: 3,
        }}
      >
        <Trans id="request.details.time_frame.on">On</Trans>
      </Text>
      <Text sx={{ fontSize: 2 }}>{dateTime(dateUnix).format('dd, ll')}</Text>
    </Flex>
    <Divider sx={{ height: 'auto' }} axis="vertical" />
    <TotalOf duration={duration} />
  </Flex>
);
