import {
  DateFormat,
  dateFormatAsString,
  languageAsString,
  Languages,
} from 'api/actions/userSession/userSessionActions.types';
import { InputOption } from 'components/ui/Select/Select';

// TODO: additional languages

export const languageListOptions: InputOption[] = [
  {
    label: languageAsString.en,
    id: `${Languages.en}`,
  },
  {
    label: languageAsString.pl,
    id: `${Languages.pl}`,
  },
  // {
  //   label: languageString.De,
  //   id: `${Language.De}`,
  // },
  // {
  //   label: languageString.Es,
  //   id: `${Language.Es}`,
  // },
  // {
  //   label: languageString.Fr,
  //   id: `${Language.Fr}`,
  // },
];

export const dateFormatOptions: InputOption[] = [
  {
    label: dateFormatAsString[DateFormat.DayAndMonth],
    id: `${DateFormat.DayAndMonth}`,
  },
  {
    label: dateFormatAsString[DateFormat.MonthAndDay],
    id: `${DateFormat.MonthAndDay}`,
  },
  {
    label: dateFormatAsString[DateFormat.YearAndMonth],
    id: `${DateFormat.YearAndMonth}`,
  },
  {
    label: dateFormatAsString[DateFormat.YearAndDay],
    id: `${DateFormat.YearAndDay}`,
  },
];
