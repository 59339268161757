import React, { useCallback, useEffect, useRef } from 'react';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Text, Flex } from 'theme-ui';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useQuery } from 'react-fetching-library';
import _ from 'lodash';

import { attendancePillAtom, drawerSearchInputAtom, parsedAndFilteredAttendancePillSelector } from 'state/drawer';
import { fetchAttendancePillAction } from 'api/actions/drawer/drawerActions';
import { TextInput } from 'components/ui/TextInput';
import { Drawer } from '../Drawer';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

import { AttendancePill } from './AttendancePill';

export const AttendanceOverview = (): React.ReactElement => {
  const filteredAttendancePill = useRecoilValue(parsedAndFilteredAttendancePillSelector);
  const [attendancePill, setAttendancePill] = useRecoilState(attendancePillAtom);
  const setInputSearch = useSetRecoilState(drawerSearchInputAtom);
  const resetInputSearch = useResetRecoilState(drawerSearchInputAtom);

  const { payload, error, loading } = useQuery(fetchAttendancePillAction());

  const containerRef = useRef<HTMLDivElement | null>(null);

  const setQuery = _.debounce((value: string) => {
    setInputSearch(value);
  }, 600);

  const handleInputChange = useCallback((e) => setQuery(e.target.value), [setQuery]);

  useEffect(() => {
    if (!error && payload && !_.isEqual(attendancePill, payload)) {
      setAttendancePill(payload);
    }
  }, [error, payload, setAttendancePill, attendancePill]);

  useEffect(() => resetInputSearch(), [resetInputSearch]);

  const overviewRenderer = useCallback(() => {
    if (filteredAttendancePill && filteredAttendancePill.working) {
      const { working, locations, late, absent, absenceExcused } = filteredAttendancePill;
      return (
        <>
          <Flex ref={containerRef} sx={{ flexDirection: 'column', gap: 4 }}>
            <TextInput
              id="filter"
              size="sm"
              icon="search"
              type="text"
              onChange={handleInputChange}
              placeholder={i18n._(
                t({
                  id: 'drawer.input.filter.placeholder',
                  message: 'Filter',
                }),
              )}
            />

            <Flex sx={{ flexDirection: 'column', gap: 2 }}>
              <AttendancePill
                outerRef={containerRef}
                variant="success"
                persons={working}
                title={i18n._(
                  t({
                    id: 'attendance_pill.working',
                    message: 'Working',
                  }),
                )}
              />
              <AttendancePill
                outerRef={containerRef}
                variant="warning"
                persons={late}
                title={i18n._(
                  t({
                    id: 'attendance_pill.late',
                    message: 'Late',
                  }),
                )}
              />
              <AttendancePill
                outerRef={containerRef}
                variant="danger"
                persons={absent}
                title={i18n._(
                  t({
                    id: 'attendance_pill.absent',
                    message: 'Absent',
                  }),
                )}
              />
              <AttendancePill
                outerRef={containerRef}
                persons={absenceExcused}
                title={i18n._(
                  t({
                    id: 'attendance_pill.absence_excused',
                    message: 'Absence excused',
                  }),
                )}
              />
            </Flex>

            <Flex sx={{ flexDirection: 'column', gap: 1 }}>
              <Drawer.SubTitle>
                <Trans id="attendance_pill.locations">In locations</Trans>
              </Drawer.SubTitle>

              {locations &&
                locations.map((l) => (
                  <AttendancePill
                    outerRef={containerRef}
                    key={l.locationName}
                    title={l.locationName}
                    persons={l.people}
                  />
                ))}
            </Flex>
          </Flex>
        </>
      );
    }

    return (
      <Text>
        <Trans id="attendance_overview.empty.message">Currently there is nothing to show here.</Trans>
      </Text>
    );
  }, [filteredAttendancePill, handleInputChange]);

  return (
    <>
      <Drawer.Header>
        <Drawer.Title>
          <Trans id="drawer.header.attendance_overview">Attendance Overview</Trans>
        </Drawer.Title>
      </Drawer.Header>

      <Drawer.Container>
        {loading || (!filteredAttendancePill && !error) ? (
          <Flex sx={{ flex: '1 0 ', alignItems: 'center', justifyContent: 'center' }}>
            <LoadingSpinnerCss size={3} />
          </Flex>
        ) : (
          overviewRenderer()
        )}
      </Drawer.Container>
    </>
  );
};
