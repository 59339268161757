import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';
import { To } from 'history';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { tagsMapSelector } from 'state/employees';
import { PATH, TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { StickyListProps } from 'components/StickyList/types';
import { ModalRoute } from 'routing/ModalRoute';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { LinkButton } from 'components/ui/Buttons';
import { Icon } from 'components/Icon/Icon';
import { Tag } from 'api/actions/organizationSession/organizationSessionActions.types';
import { TextEllipsis } from 'components/utils/TextEllipsis';

import { AddTagModal } from './AddTag';
import { EditTagModal } from './EditTag';
import { DeleteTagsModal } from './DeleteTags';
import { ManageModal } from './modalsElements/ManageModal';

export const ManageTagsModal = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const tags = useRecoilValue(tagsMapSelector);

  const listPropsGenerator = useCallback(
    (
      match: string,
      replaceMode: boolean,
      preferredHistoryMethod: (to: To, state?: unknown) => void,
    ): StickyListProps<Tag> => ({
      name: 'MANAGE_TAGS',
      select: 'checkbox',
      showHeader: true,
      showContentPlaceholder: true,
      variant: 'inverted',
      list: tags || new Map(),
      columns: [
        {
          key: 'color',
          width: '40px',
          customCellRenderer: (color: Tag['color']) => (
            <Flex
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '6px',
                bg: UserSelectableColor[color],
              }}
            />
          ),
        },
        {
          title: t({ id: 'team.manage_tags.name', message: 'Name' }),
          key: 'name',
          sortableValue: (name: Tag['name']) => `${name}`,
          customCellRenderer: (name: Tag['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
        },
        {
          title: t({ id: 'team.manage_tags.additional_info', message: 'Additional information' }),
          key: 'additionalInfo',
          sortableValue: (additionalInfo: Tag['additionalInfo']) => `${additionalInfo}`,
          customCellRenderer: (additionalInfo: Tag['additionalInfo']) => (
            <TextEllipsis title={additionalInfo}>{additionalInfo}</TextEllipsis>
          ),
        },
        {
          key: 'id',
          width: '34px',
          customCellRenderer: (item) => (
            <Flex onClick={(e) => e.stopPropagation()}>
              <LinkButton
                replace={replaceMode}
                to={{
                  pathname: `${match}${TO.DELETE_TAGS_MODAL[language]}`,
                  state: { ids: [item] },
                }}
                variant="minimal"
                size="sm"
                shape="rounded"
              >
                <Icon size={18} type="delete" />
              </LinkButton>
            </Flex>
          ),
        },
      ],
      onRowClick: (listItemId) => {
        preferredHistoryMethod(`${match}${TO.EDIT_TAG_MODAL__ID[language]}/${listItemId}`);
      },
    }),
    [language, tags],
  );

  const modalRoutesRenderer = useCallback(
    () => (
      <>
        <ModalRoute replaceMode size="sm" path={PATH.ADD_TAG_MODAL[language]}>
          <AddTagModal />
        </ModalRoute>
        <ModalRoute replaceMode size="sm" path={PATH.EDIT_TAG_MODAL__ID[language]}>
          <EditTagModal />
        </ModalRoute>
        <ModalRoute replaceMode size="xs" path={PATH.DELETE_TAGS_MODAL[language]}>
          <DeleteTagsModal />
        </ModalRoute>
      </>
    ),
    [language],
  );

  const modalLinksPaths = useMemo(
    () => ({
      add: TO.ADD_TAG_MODAL[language],
      delete: TO.DELETE_TAGS_MODAL[language],
    }),
    [language],
  );

  return (
    <ManageModal
      allResources={tags}
      title={t({ id: 'team.manage_tags', message: 'Manage tags' })}
      listPropsGenerator={listPropsGenerator}
      modalRoutesRenderer={modalRoutesRenderer}
      modalLinksPaths={modalLinksPaths}
    />
  );
};
