import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { Flex, Text } from 'theme-ui';
import { useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';
import { useClient } from 'react-fetching-library';
import _ from 'lodash';

import {
  AddRequestActionProps,
  FetchApprovalListsResponse,
  RequestActionType,
  RequestFormType,
} from 'api/actions/requests/requestsActions.types';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { TextInput } from 'components/ui/TextInput';
import { PersonSelect } from 'components/ui/PersonSelect/PersonSelect';
import { commonEditRequestDataSelector, selectedEmployeesAddRequestsIdsSelector } from 'state/requests';
import { Select } from 'components/ui/Select/Select';
import { FileUpload } from 'components/ui/FileUpload/FileUpload';
import { fetchApprovalListsAction } from 'api/actions/requests/requestsActions';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { ADD_REQUEST_PICK_TEAMMATES_LIST } from 'layouts/Requests/constans';

import { TimeTrackingForm } from './TimeTrackingForm';
import { TimeOffForm } from './TimeOffForm';
import { BusinessTripForm } from './BusinessTripForm';
import { CustomForm } from './CustomForm';

type Props = {
  requestType: RequestFormType;
  onSubmit: (data: AddRequestActionProps) => Promise<void>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

function shouldShowForm(selectedUsers: string[], timeTrackerAction: RequestActionType): boolean {
  return (
    (!selectedUsers[1] && timeTrackerAction !== RequestActionType.Create) ||
    timeTrackerAction === RequestActionType.Create
  );
}

const DEFAULT_ACTION_TYPE = RequestActionType.Create;

export const AddRequestStep3 = React.forwardRef<HTMLFormElement, Props>(
  ({ requestType, onSubmit, setLoading, setIsSaveDisabled }: Props, ref): React.ReactElement => {
    useLingui();
    const { query } = useClient();
    const personIds = useRecoilValue(selectedRowsIdsSelectorFamily(ADD_REQUEST_PICK_TEAMMATES_LIST));
    const commonEditRequestData = useRecoilValue(commonEditRequestDataSelector);
    const selectedUsers = useRecoilValue(selectedEmployeesAddRequestsIdsSelector);

    const [showCommonForm, setShowCommonForm] = useState<boolean>(false);
    const [showAddNote, setShowAddNote] = useState<boolean>(false);
    const [showAddAttachments, setShowAddAttachments] = useState<boolean>(false);
    const [requestAcceptationSelect, setRequestAcceptationSelect] = useState<FetchApprovalListsResponse>({
      firstStepPeopleIds: [],
      secondStepPeopleIds: [],
    });

    const methods = useForm<AddRequestActionProps>({
      defaultValues: {
        actionType: DEFAULT_ACTION_TYPE,
        processedById: '',
        optionalStepProcessedById: '',
      },
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const actionTypeWatch = methods.watch('actionType');

    const showNote = useCallback(() => setShowAddNote(true), []);
    const showAttachments = useCallback(() => setShowAddAttachments(true), []);

    const handleSubmitCallback = useCallback(
      (data: AddRequestActionProps) => {
        onSubmit(data);
      },
      [onSubmit],
    );

    const handleFormErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    useEffect(() => {
      const getApprovalList = async () => {
        if (personIds.length === 1) {
          const { error, payload } = await query(fetchApprovalListsAction({ personId: personIds[0], requestType }));

          if (!error && payload) {
            const { firstStepPeopleIds } = payload;
            const { secondStepPeopleIds } = payload;

            setRequestAcceptationSelect({
              firstStepPeopleIds,
              secondStepPeopleIds,
            });
          }
        }
      };

      getApprovalList();
    }, [personIds, query, requestType]);

    useEffect(() => {
      if (commonEditRequestData) {
        const { processedById, note, fileIds } = commonEditRequestData;
        methods.setValue('processedById', processedById);

        if (note) {
          showNote();
          methods.setValue('note', note);
        } else {
          setShowAddNote(false);
        }

        if (fileIds && !_.isEmpty(fileIds)) {
          showAttachments();
          methods.setValue('files', fileIds.join(','));
        } else {
          setShowAddAttachments(false);
          methods.unregister('files');
        }
      } else {
        setShowAddNote(false);
        setShowAddAttachments(false);
        methods.unregister('files');
      }
    }, [commonEditRequestData, methods, showAttachments, showNote]);

    const renderForm = useMemo((): React.ReactElement => {
      switch (requestType) {
        case RequestFormType.TimeTracking:
          return (
            <TimeTrackingForm
              action={actionTypeWatch}
              setIsSaveDisabled={setIsSaveDisabled}
              setShowCommonForm={setShowCommonForm}
            />
          );

        case RequestFormType.BusinessTrip:
          return (
            <BusinessTripForm
              action={actionTypeWatch}
              setIsSaveDisabled={setIsSaveDisabled}
              setShowCommonForm={setShowCommonForm}
            />
          );

        case RequestFormType.Custom:
          return (
            <CustomForm
              action={actionTypeWatch}
              setIsSaveDisabled={setIsSaveDisabled}
              setShowCommonForm={setShowCommonForm}
            />
          );

        case RequestFormType.TimeOff:
        default:
          return (
            <TimeOffForm
              action={actionTypeWatch}
              setIsSaveDisabled={setIsSaveDisabled}
              setShowCommonForm={setShowCommonForm}
            />
          );
      }
    }, [actionTypeWatch, requestType, setIsSaveDisabled]);

    const typeOptions = useMemo(
      () => [
        {
          id: `${RequestActionType.Create}`,
          label: t({
            id: 'requests.add_request.time_tracker_action.create',
            message: 'Create',
          }),
        },
        {
          id: `${RequestActionType.Edit}`,
          label: t({
            id: 'requests.add_request.time_tracker_action.edit',
            message: 'Edit',
          }),
        },
        {
          id: `${RequestActionType.Remove}`,
          label: t({
            id: 'requests.add_request.time_tracker_action.remove',
            message: 'Remove',
          }),
        },
      ],
      [],
    );

    return (
      <FormProvider {...methods}>
        <form ref={ref} onSubmit={methods.handleSubmit(handleSubmitCallback, handleFormErrorCallback)}>
          <Flex sx={{ fontSize: 2, flexDirection: 'column', gap: 4 }}>
            <Flex sx={{ flexDirection: 'column' }}>
              <Text sx={{ mb: 2, fontWeight: 'bold' }}>
                <Trans id="requests.add_request.select_type">Select type</Trans>
              </Text>
              <Select
                {...methods.register('actionType', { valueAsNumber: true })}
                id="actionType"
                options={typeOptions}
                size="sm"
              />
            </Flex>
            {shouldShowForm(selectedUsers, actionTypeWatch) ? (
              <>
                {renderForm}

                {((actionTypeWatch === RequestActionType.Create && showCommonForm) || commonEditRequestData) && (
                  <>
                    {selectedUsers.length === 1 && (
                      <>
                        <Flex sx={{ flexDirection: 'column' }}>
                          <Text sx={{ mb: 2, fontWeight: 'bold' }}>
                            <Trans id="add_request.send_to">Send request to</Trans>
                          </Text>
                          <Flex sx={{ gap: 4 }}>
                            <PersonSelect
                              {...methods.register('processedById')}
                              employeesIds={requestAcceptationSelect.firstStepPeopleIds}
                              id="processedById"
                              placeholder={t({ id: 'requests.all_supervisors', message: 'All supervisors' })}
                              size="sm"
                              errorMessage={methods.formState.errors.processedById?.message}
                              error={!!methods.formState.errors.processedById}
                              clearable
                            />
                            {!_.isEmpty(requestAcceptationSelect.secondStepPeopleIds) && (
                              <PersonSelect
                                {...methods.register('optionalStepProcessedById')}
                                employeesIds={requestAcceptationSelect.secondStepPeopleIds}
                                id="optionalStepProcessedById"
                                placeholder={t({ id: 'requests.all_supervisors' })}
                                size="sm"
                                errorMessage={methods.formState.errors.optionalStepProcessedById?.message}
                                error={!!methods.formState.errors.optionalStepProcessedById}
                                clearable
                              />
                            )}
                          </Flex>
                        </Flex>
                      </>
                    )}

                    {showAddNote ? (
                      <TextInput
                        id="note"
                        type="text"
                        {...methods.register('note')}
                        placeholder={t({
                          id: 'add_request.note.placeholder',
                          message: 'A reason why are you making this request',
                        })}
                        label={t({
                          id: 'add_request.note',
                          message: 'Note',
                        })}
                        variant="rounded"
                        size="sm"
                        labelProps={{
                          sx: {
                            mb: 2,
                            fontSize: 2,
                          },
                        }}
                      />
                    ) : (
                      <ShowFieldsButton
                        onClick={showNote}
                        label={t({
                          id: 'add_request.add_note',
                          message: 'Add note (optional)',
                        })}
                      />
                    )}

                    {showAddAttachments ? (
                      <Flex sx={{ flexDirection: 'column' }}>
                        <Text
                          sx={{
                            fontSize: 2,
                            fontWeight: 'bold',
                            ml: 2,
                            mb: 2,
                          }}
                        >
                          <Trans id="add_request.add_attachments" />
                        </Text>
                        <FileUpload {...methods.register('files', { setValueAs: (v: string) => v.split(',') })} />
                      </Flex>
                    ) : (
                      <ShowFieldsButton
                        onClick={showAttachments}
                        label={t({
                          id: 'add_request.add_attachments',
                          message: 'Add attachments (optional)',
                        })}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <Text sx={{ fontWeight: 'bold', color: 'reds5', mt: -3 }}>
                <Trans
                  id={`requests.add_request.${actionTypeWatch === RequestActionType.Edit ? 'edit' : 'delete'}_error`}
                />
              </Text>
            )}
          </Flex>
        </form>
      </FormProvider>
    );
  },
);
