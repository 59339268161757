import React from 'react';
import { Flex, Text, ThemeUIStyleObject, FlexOwnProps } from 'theme-ui';

type Props = Omit<FlexOwnProps, 'children' | 'color'> & {
  title: string | React.ReactElement;
  content: React.ReactElement;
  additionalText?: string | React.ReactElement;
  showDivider?: boolean;
};

const defaultProps = {
  additionalText: undefined,
  showDivider: false,
};

const settingWrapperSx: ThemeUIStyleObject = {
  justifyContent: 'space-between',
  alignItems: ['flex-start', 'center'],
  gap: 3,
};

export const SettingsItem = ({ content, title, additionalText, showDivider, ...props }: Props): React.ReactElement => (
  <Flex
    {...props}
    sx={{
      ...settingWrapperSx,
      ...(showDivider && { borderBottom: '1px solid', borderColor: 'whites3', pb: 3 }),
      ...props.sx,
    }}
  >
    <Flex sx={{ flexDirection: 'column', flex: '1 0' }}>
      <Text variant="heading4" as="h4" sx={{ m: 0 }}>
        {title}
      </Text>
      {additionalText && (
        <Text variant="p" color="texts.lighter" sx={{ fontSize: 2, lineHeight: 'heading' }}>
          {additionalText}
        </Text>
      )}
    </Flex>

    <Flex sx={{ flex: '1 0', maxWidth: '160px', justifyContent: 'flex-end' }}>{content}</Flex>
  </Flex>
);

SettingsItem.defaultProps = defaultProps;
