import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { Flex } from 'theme-ui';

import { advancedSettingsDeletePhotosAction } from 'api/actions/settings/settingsActions';
import { AdvancedSettingsCommonActionProps } from 'api/actions/settings/settingsActions.types';
import { Divider } from 'components/Divider/Divider';
import { Button } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { HeadingTip } from 'layouts/Settings/HeadingTip';
import { addSnackbar } from 'SnackbarHub/actions';

export const AdvancedDeletePhotosForm = (): React.ReactElement => {
  useLingui();
  const { mutate } = useMutation(advancedSettingsDeletePhotosAction);

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const passwordValue = watch('password');

  const handleSubmitCallback = useCallback(
    async (data: AdvancedSettingsCommonActionProps) => {
      const { error } = await mutate(data);

      if (!error) {
        addSnackbar({
          message: t({
            id: 'settings.organization.advanced.delete_photos_success',
            message: 'Deleted all photos from clog log',
          }),
          variant: 'success',
        });
        reset();
      }

      if (error) {
        addSnackbar({
          message: t({
            id: 'settings.organization.advanced.delete_photos_danger',
            message: "Couldn't delete photos",
          }),
          variant: 'danger',
        });
      }
    },
    [mutate, reset],
  );

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)}>
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <HeadingTip
          label={t({
            id: 'advanced_settings.heading.delete_all_photos',
            message: 'Delete all photos from clock log',
          })}
          additionalInfo={`${t({
            id: 'advanced_settings.heading.additional_info',
            message: 'This operation cannot be reversed!',
          })} ${t({
            id: 'advanced_settings.heading.can_take_up',
            message: 'It can take up to few minutes to complete this request.',
          })}`}
          variant="heading4"
          additionalInfoSx={{
            fontSize: '0.875rem',
            lineHeight: '1.3125rem',
          }}
        />
        <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
          <TextInput
            {...register('password', {
              required: t({
                id: 'global.forms.required',
              }),
            })}
            id="deletePhotosPassword"
            placeholder={t({
              id: 'global.forms.inputs.password',
            })}
            size="sm"
            type="password"
            icon="lock"
            label={t({
              id: 'advanced_settings.type_your_password',
              message: 'Type your Password',
            })}
            error={!!errors.deletePhotosPassword}
            errorMessage={errors.deletePhotosPassword?.message}
            sx={{ maxWidth: '340px' }}
          />

          <Button
            variant="danger"
            shape="rounded"
            disabled={!passwordValue}
            type="submit"
            sx={{ alignSelf: 'flex-start' }}
          >
            <Trans id="advanced_settings.delete">Delete</Trans>
          </Button>

          <Divider />
        </Flex>
      </Flex>
    </form>
  );
};
