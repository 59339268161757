import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import { useMutation, useQuery } from 'react-fetching-library';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { PATH, TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Icon } from 'components/Icon/Icon';
import { AvatarInfo } from '../forms/UserProfile';
import { UserAdvancedDetails } from '../forms/UserAdvancedDetails';
import {
  editEmployeeDetailsAction,
  fetchEmployeeDetailsAction,
  fetchTagInheritedFeaturesAction,
} from 'api/actions/employees/employeesActions';
import {
  editedEmployeeDetailsAtom,
  EmployeeAdvancedDetailsFormState,
  employeeAdvancedDetailsFormStateSelector,
  EmployeeEmploymentDetailsFormState,
  employeeEmploymentDetailsFormStateSelector,
  ProfileFormState,
  profileFormStateSelector,
} from 'state/team';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { Modal } from 'components/Modal/Modal';
import {
  FetchEmployeeDetailsResponse,
  FetchTagInheritedFeaturesResponse,
} from 'api/actions/employees/employeesActions.types';
import { ModalRoute } from 'routing/ModalRoute';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useModal } from 'hooks/useModal/useModal';
import { employeeSelectorFamily } from 'state/employees';
import { InvitationBadge } from 'components/recipes/InvitationBadge';
import { InvitationStates } from 'api/actions/organizationSession/organizationSessionActions.types';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useIsMountedRef } from 'hooks/useIsMountedRef/useIsMountedRef';
import { addSnackbar } from 'SnackbarHub/actions';

import { AddTagModal } from './AddTag';
import { ManageTagsModal } from './ManageTags';
import { ManageRolesModal } from './ManageRoles';
import { ManageWorkPositionsModal } from './ManageWorkPositions';
import { ReInviteTeammatesModal } from './ReInviteTeammates';

const LazyUserProfile = React.lazy(() =>
  import('../forms/UserProfile').then(({ UserProfile }) => ({
    default: UserProfile,
  })),
);
const LazyUserEmploymentDetails = React.lazy(() =>
  import('../forms/UserEmploymentDetails').then(({ UserEmploymentDetails }) => ({
    default: UserEmploymentDetails,
  })),
);

const tabButtonSx: ThemeUIStyleObject = {
  bg: 'alphas.darker3',
  p: '.75rem',
  pr: 2,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: 'texts.lighter',
  cursor: 'pointer',
  '&:hover': {
    bg: 'alphas.darker1',
  },
  '& span:first-of-type': {
    color: 'texts.default',
  },
};

const tabButtonActiveSx: ThemeUIStyleObject = {
  cursor: 'default',
  color: 'alphas.lighter6',
  bg: 'skyBlues5',
  '&:hover': {},
  '& span:first-of-type': {
    color: 'texts.reversed',
  },
};
type TabNames = 'PROFILE' | 'EMPLOYMENT' | 'ADVANCED';

export const EditTeammateModal = (): React.ReactElement => {
  useLingui();
  const { push, location } = useHistory();

  const isMountedRef = useIsMountedRef();
  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const [loading, setLoading] = useState(false);

  const [isFetchingTagInheritedFeatures, setIsFetchingTagInheritedFeatures] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabNames>('PROFILE');
  const [editedEmployeeDetails, setEditedEmployeeDetails] = useRecoilState(editedEmployeeDetailsAtom);
  const resetEditedEmployeeDetails = useResetRecoilState(editedEmployeeDetailsAtom);

  const currentTabRef = useRef<TabNames>(currentTab);

  const [tagInheritedFeaturesResponse, setTagInheritedFeaturesResponse] =
    useState<FetchTagInheritedFeaturesResponse | null>(null);

  const [oldEmployeeDetails, setOldEditedEmployeeDetails] = useState<FetchEmployeeDetailsResponse | null>(null);

  const [savedAvatarInfo, setSavedAvatarInfo] = useState<AvatarInfo | null>(null);

  const [profileFormState, setProfileFormState] = useRecoilState(profileFormStateSelector);
  const [advancedDetailsFormState, setAdvancedDetailsFormState] = useRecoilState(
    employeeAdvancedDetailsFormStateSelector,
  );
  const [employmentDetailsFormState, setEmploymentDetailsFormState] = useRecoilState(
    employeeEmploymentDetailsFormStateSelector,
  );

  const language = useRecoilValue(languageSelector);

  const isFormValidRef = useRef(false);
  const shouldSubmitRef = useRef(false);

  const { id } = useParams<{ id: string }>();

  const selectedEmployee = useRecoilValue(employeeSelectorFamily({ id }));

  const { payload, error } = useQuery(fetchEmployeeDetailsAction({ employeeId: id }));
  const { mutate } = useMutation(editEmployeeDetailsAction);
  const { mutate: fetchTagInheritedFeatures } = useMutation(fetchTagInheritedFeaturesAction);

  const submitCurrentForm = useCallback(() => {
    const form = formRef.current;

    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  }, []);

  const { handleClose, baseRoute } = useModal({
    closeOnBackdrop: false,
  });

  useEffect(() => {
    if (!error && payload) {
      setEditedEmployeeDetails({
        ..._.omit(payload, 'defaultWorkPositionId'),
        defaultWorkPosition: payload.defaultWorkPositionId,
      });
      setOldEditedEmployeeDetails(_.clone(payload));
    }
  }, [error, payload, setEditedEmployeeDetails]);

  const onProfileSubmit = useCallback(
    async (body: ProfileFormState, avatarInfo?: AvatarInfo): Promise<boolean> => {
      isFormValidRef.current = true;
      setProfileFormState(body);
      if (avatarInfo) {
        const alreadyChanged = !!savedAvatarInfo?.changed;
        setSavedAvatarInfo({ ...avatarInfo, changed: alreadyChanged || avatarInfo.changed });
      }
      return true;
    },
    [setProfileFormState, savedAvatarInfo?.changed],
  );
  const onAdvancedDetailsSubmit = useCallback(
    async (body: EmployeeAdvancedDetailsFormState): Promise<boolean> => {
      isFormValidRef.current = true;
      setAdvancedDetailsFormState(body);
      return true;
    },
    [setAdvancedDetailsFormState],
  );
  const onEmploymentDetailsSubmit = useCallback(
    async (body: EmployeeEmploymentDetailsFormState): Promise<boolean> => {
      isFormValidRef.current = true;
      setEmploymentDetailsFormState(body);
      return true;
    },
    [setEmploymentDetailsFormState],
  );

  const handleTabChange = useCallback(
    async (tabName: TabNames) => {
      if (currentTabRef.current === tabName) return;

      isFormValidRef.current = false;
      submitCurrentForm();

      await delay(currentTabRef.current === 'PROFILE' ? 200 : 0);

      if (isFormValidRef.current) {
        setCurrentTab(tabName);
        currentTabRef.current = tabName;
        modalContentRef.current?.scrollTo(0, 0);
      }
    },
    [submitCurrentForm],
  );
  useEffect(() => {
    const submitEmployeeDetails = async (employeeDetails: FetchEmployeeDetailsResponse) => {
      shouldSubmitRef.current = false;
      // console.log({
      //   employeeId: id,
      //   employee: { ...employeeDetails, avatarChanged: !!savedAvatarInfo?.changed },
      //   avatar: savedAvatarInfo?.avatarBlob || undefined,
      // });
      const { error: submitError } = await mutate({
        employeeId: id,
        employee: { ...employeeDetails, avatarChanged: !!savedAvatarInfo?.changed },
        avatar: savedAvatarInfo?.avatarBlob || undefined,
      });

      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      } else {
        shouldSubmitRef.current = false;
      }
      setLoading(false);

      if (!submitError) {
        addSnackbar({
          message: t({ id: 'team.edit_teammate.edited', message: 'Employee successfully edited!' }),
          variant: 'success',
        });
      }
    };
    if (shouldSubmitRef.current && isFormValidRef.current) {
      const getParsedPhoneNumber = (phoneNumber: string) => +phoneNumber.replace('+', '').split(' ').join('');

      if (
        oldEmployeeDetails &&
        _.isEqual(
          _.omit(editedEmployeeDetails, ['employeeInfo.phoneNumber']),
          _.omit(oldEmployeeDetails, ['employeeInfo.phoneNumber']),
        ) &&
        _.isEqual(
          getParsedPhoneNumber(_.get(editedEmployeeDetails, 'employeeInfo.phoneNumber')),
          getParsedPhoneNumber(_.get(oldEmployeeDetails, 'employeeInfo.phoneNumber')),
        ) &&
        !savedAvatarInfo?.changed
      ) {
        setLoading(false);
        // addSnackbar({
        //   message: 'No changes to save!',
        //   variant: 'default',
        // });
        shouldSubmitRef.current = false;
      } else if (editedEmployeeDetails) {
        submitEmployeeDetails(editedEmployeeDetails);
      }
    }
  }, [editedEmployeeDetails, handleClose, mutate, id, oldEmployeeDetails, savedAvatarInfo]);

  useEffect(
    () => () => {
      resetEditedEmployeeDetails();
    },
    [resetEditedEmployeeDetails],
  );
  const handleSave = () => {
    setLoading(true);
    isFormValidRef.current = false;
    shouldSubmitRef.current = true;
    submitCurrentForm();
  };

  const onSubmitError = useCallback(() => {
    shouldSubmitRef.current = false;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTagInheritedFeatures = useCallback(
    _.debounce(async (tagsIds: ProfileFormState['tagsIds']) => {
      if (!isMountedRef.current) return;
      const { payload: fetchTagInheritedFeaturesPayload, error: fetchTagInheritedFeaturesError } =
        await fetchTagInheritedFeatures({ tagsIds });
      if (!isMountedRef.current) return;
      if (!fetchTagInheritedFeaturesError && fetchTagInheritedFeaturesPayload) {
        setTagInheritedFeaturesResponse(fetchTagInheritedFeaturesPayload);
      }
      setIsFetchingTagInheritedFeatures(false);
    }, 500),
    [fetchTagInheritedFeatures, isMountedRef],
  );

  const onTagsIdsBlur = useCallback(
    async (tagsIds: string[]) => {
      if (!isMountedRef.current) return;
      setIsFetchingTagInheritedFeatures(true);
      getTagInheritedFeatures(tagsIds);
    },
    [getTagInheritedFeatures, isMountedRef],
  );

  const handleInvitationBadgeClick = () => {
    push(`${location.pathname}${TO.RE_INVITE_TEAMMATES_MODAL[language]}`, {
      ids: [id],
    });
  };

  if (!selectedEmployee) return <Redirect to={baseRoute} />;

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.edit_teammate">Edit teammate</Trans>
        </Modal.Title>
      </Modal.Header>
      {!profileFormState ? (
        <CenteredLoadingSpinner />
      ) : (
        <>
          <Modal.Body sx={{ py: 0, overflow: 'hidden' }}>
            <Flex sx={{ flex: '1', alignItems: 'stretch', overflow: 'hidden', mr: -4 }}>
              <Flex
                sx={{
                  flexDirection: 'column',
                  width: '224px',
                  borderRadius: 'default',
                  overflow: 'hidden',
                }}
              >
                <ElementGroup direction="column" marginValue="1px">
                  {[
                    <Flex
                      key="PROFILE"
                      onClick={() => handleTabChange('PROFILE')}
                      sx={{
                        ...tabButtonSx,
                        borderTopRightRadius: 'default',
                        borderTopLeftRadius: 'default',
                        ...(currentTab === 'PROFILE' && tabButtonActiveSx),
                      }}
                    >
                      <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>
                        <Trans id="team.edit_teammate.profile">Profile</Trans>
                      </Text>
                      <Icon type="chevronRight" />
                    </Flex>,
                    <Flex
                      key="EMPLOYMENT"
                      onClick={() => handleTabChange('EMPLOYMENT')}
                      sx={{
                        ...tabButtonSx,
                        ...(currentTab === 'EMPLOYMENT' && tabButtonActiveSx),
                      }}
                    >
                      <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>
                        <Trans id="team.edit_teammate.employment_det">Employment details</Trans>
                      </Text>
                      <Icon type="chevronRight" />
                    </Flex>,
                    <Flex
                      key="ADVANCED"
                      onClick={() => handleTabChange('ADVANCED')}
                      sx={{
                        ...tabButtonSx,
                        ...(currentTab === 'ADVANCED' && tabButtonActiveSx),
                      }}
                    >
                      <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>
                        {' '}
                        <Trans id="team.edit_teammate.advanced">Advanced</Trans>
                      </Text>
                      <Icon type="chevronRight" />
                    </Flex>,
                    <Flex
                      key="BADGE"
                      sx={{
                        bg: 'alphas.darker3',
                        flex: '1',
                        p: 3,
                        width: '100%',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                      }}
                    >
                      {selectedEmployee.invitationState && (
                        <InvitationBadge
                          invitationState={selectedEmployee.invitationState}
                          {...(selectedEmployee.invitationState === InvitationStates.EXPIRED && {
                            onClick: handleInvitationBadgeClick,
                          })}
                        />
                      )}
                    </Flex>,
                  ]}
                </ElementGroup>
              </Flex>

              <Flex sx={{ flex: '1', pb: 3, overflow: 'auto', px: 4, flexDirection: 'column' }} ref={modalContentRef}>
                {currentTab === 'PROFILE' && (
                  <React.Suspense fallback={<></>}>
                    <LazyUserProfile
                      fullContactInfo
                      avatarHasChanged={!!(savedAvatarInfo && savedAvatarInfo.changed)}
                      avatarUrl={savedAvatarInfo ? savedAvatarInfo.avatarUrl : selectedEmployee?.avatarUrl}
                      setLoading={setLoading}
                      defaultValues={profileFormState}
                      onSubmit={onProfileSubmit}
                      onSubmitError={onSubmitError}
                      onTagsIdsBlur={onTagsIdsBlur}
                      ref={formRef}
                    />
                  </React.Suspense>
                )}

                {currentTab === 'EMPLOYMENT' && employmentDetailsFormState && (
                  <React.Suspense fallback={<></>}>
                    <LazyUserEmploymentDetails
                      scrollParentRef={modalContentRef}
                      setLoading={setLoading}
                      onSubmit={onEmploymentDetailsSubmit}
                      onSubmitError={onSubmitError}
                      defaultValues={employmentDetailsFormState}
                      ref={formRef}
                    />
                  </React.Suspense>
                )}
                {currentTab === 'ADVANCED' && advancedDetailsFormState && (
                  <UserAdvancedDetails
                    wait={isFetchingTagInheritedFeatures}
                    setLoading={setLoading}
                    onSubmit={onAdvancedDetailsSubmit}
                    onSubmitError={onSubmitError}
                    tagInheritedFeatures={tagInheritedFeaturesResponse || undefined}
                    defaultValues={advancedDetailsFormState}
                    ref={formRef}
                  />
                )}
              </Flex>
            </Flex>
          </Modal.Body>

          <BasicModalFooter
            buttons={[
              {
                isLoading: loading,
                onClick: handleSave,
                variant: 'primary',
                children: t({ id: 'save', message: 'Save' }),
              },
            ]}
          />
        </>
      )}

      <ModalRoute size="sm" path={PATH.ADD_TAG_MODAL[language]}>
        <AddTagModal />
      </ModalRoute>
      <ModalRoute path={PATH.MANAGE_TAGS_MODAL[language]} fullHeight>
        <ManageTagsModal />
      </ModalRoute>
      <ModalRoute path={PATH.MANAGE_ROLES_MODAL[language]} fullHeight>
        <ManageRolesModal />
      </ModalRoute>
      <ModalRoute path={PATH.MANAGE_WORK_POSITIONS_MODAL[language]} fullHeight>
        <ManageWorkPositionsModal />
      </ModalRoute>
      <ModalRoute size="xs" path={PATH.RE_INVITE_TEAMMATES_MODAL[language]}>
        <ReInviteTeammatesModal />
      </ModalRoute>
    </>
  );
};
