export const UPDATE_FIELD_VIEW = 'UPDATE_FIELD_VIEW';

/**
 * This will a custom 'UPDATE_FIELD_VIEW' event dispatched from the provided input element.
 * See: useOnUpdateFieldView.ts
 */
export const emitUpdateFieldView = (inputRef: React.MutableRefObject<HTMLInputElement | null>): void => {
  if (!inputRef) return;

  const element = inputRef.current;
  if (!element) return;

  const event = new CustomEvent(UPDATE_FIELD_VIEW, { detail: element.value });
  element.dispatchEvent(event);
};
