/** @jsxImportSource theme-ui */
import { t } from '@lingui/macro';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from 'theme-ui';
import { useLingui } from '@lingui/react';

import { AddWebhookActionProps, Webhook, WebhookType } from 'api/actions/webhook/webhooksActions.types';
import { InputOption, Select } from 'components/ui/Select/Select';
import { TextInput } from 'components/ui/TextInput';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

type Props = {
  onSubmit: (data: AddWebhookActionProps) => void;
  setLoading: (loading: boolean) => void;
  defaultValues?: Omit<Webhook, 'id'>;
};

const defaultProps: Partial<Props> = {
  defaultValues: undefined,
};

export const AddEditWebhookForm = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, setLoading, defaultValues }: Props, ref): React.ReactElement => {
    useLingui();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues,
    });

    const handleSubmitCallback = useCallback(
      (data: AddWebhookActionProps | Webhook) => {
        onSubmit(data);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    const webhookTypesSelectOptions: InputOption[] = useMemo(
      () => [
        {
          id: WebhookType.TimeOffAdd.toString(),
          label: t({ id: 'settings.webhook.time_off_add', message: 'New time off request' }),
        },
        {
          id: WebhookType.TimeOffEdit.toString(),
          label: t({ id: 'settings.webhook.time_off_edit', message: 'Accepted time off request modified' }),
        },
        {
          id: WebhookType.TimeOffDelete.toString(),
          label: t({ id: 'settings.webhook.time_off_delete', message: 'Accepted time off request deleted' }),
        },
      ],
      [],
    );

    return (
      <form
        ref={ref}
        onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: '340px', minHeight: '370px' }}
      >
        <Box>
          <Select
            {...register('type', {
              ...validationFactory<WebhookType>({ required: true }),
              valueAsNumber: true,
            })}
            id="type"
            size="sm"
            placeholder={t({ id: 'settings.webhook.type', message: 'Type' })}
            options={webhookTypesSelectOptions}
            label={t({ id: 'settings.webhook.type' })}
            error={!!errors.type}
            errorMessage={errors.type?.message}
          />
        </Box>
        <Box>
          <TextInput
            {...register('url', validationFactory({ ...VALIDATION_RULES.ROLE_NAME, required: true }))}
            id="url"
            size="sm"
            placeholder="https://domain.com/endpoint"
            label={t({ id: 'settings.webhook.url', message: 'Url' })}
            error={!!errors.url}
            errorMessage={errors.url?.message}
          />
        </Box>
        <Box>
          <TextInput
            {...register('token')}
            id="token"
            size="sm"
            placeholder={t({ id: 'settings.webhook.token', message: 'Token' })}
            label={t({ id: 'settings.webhook.token_optional', message: 'Token (optional)' })}
          />
        </Box>
      </form>
    );
  },
);

AddEditWebhookForm.defaultProps = defaultProps;
