import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useLingui } from '@lingui/react';
import { t, Trans } from '@lingui/macro';

import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ConnectZusForm } from '../forms/ConnectZusForm';
import { useModal } from 'hooks/useModal/useModal';
import { zusConncectAction, zusCredentialsAction } from 'api/actions/zus/zusActions';
import { createEvent } from 'utils/createEvent';
import { ZusConnectActionProps } from 'api/actions/zus/zusActions.types';
import { refreshSelectorFamily } from 'state/settings';

export const ZusModal = (): React.ReactElement => {
  useLingui();
  const { handleClose } = useModal();
  const { mutate: mutateConnect } = useMutation(zusConncectAction);
  const { mutate: mutateUpdate } = useMutation(zusCredentialsAction);
  const { state } = useLocation();
  const refreshIntegrations = useRecoilValue(refreshSelectorFamily('INTEGRATIONS'));

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: ZusConnectActionProps): Promise<void> => {
      const { reconnect } = state;
      const { error: submitError } = reconnect ? await mutateUpdate(data) : await mutateConnect(data);

      if (!submitError) {
        if (handleClose) {
          refreshIntegrations();
          handleClose();
        }
      }
      setLoading(false);
    },
    [handleClose, mutateConnect, mutateUpdate, refreshIntegrations, state],
  );

  const handleConnect = () => {
    setLoading(true);
    submitForm();
  };

  useEffect(() => {
    if (!state && handleClose) handleClose();
  }, [handleClose, state]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.integrations.zus_connect">Connect Zus e-ZLA</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ConnectZusForm ref={formRef} onSubmit={onSubmit} setLoading={setLoading} />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleConnect,
            variant: 'success',
            children: t({ id: 'connect' }),
          },
        ]}
      />
    </>
  );
};
