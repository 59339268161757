import { Flex } from 'theme-ui';
import React from 'react';
import _ from 'lodash';

import { ListRendererProps } from '../types';
import { GUTTER_DEFAULT_SIZE } from '../constants';
import { MemorizedRow } from '../Row';

export const rowRenderer = ({ data, style, index, ...props }: ListRendererProps): JSX.Element | null => {
  const { showHeader, columns, name, select, list, itemCount, onRowClick, variant, isRowUncheckableValidator } = data;
  const rowData = _.isArray(list) ? list[index] : list.get(`${index}`);

  if (!rowData) return null;

  let innerStyle = style;

  if (showHeader) {
    innerStyle = {
      ...innerStyle,
      top: parseInt(`${innerStyle.top}`, 10) + parseInt(`${innerStyle.height}`, 10),
    };
  }

  innerStyle = {
    ...innerStyle,
    height: parseInt(`${innerStyle.height}`, 10) - GUTTER_DEFAULT_SIZE,
    marginBottom: GUTTER_DEFAULT_SIZE,
  };

  const isFirst = index + 1 === 1;
  const isLast = index + 1 === itemCount;

  return (
    <Flex style={innerStyle}>
      <MemorizedRow
        {...props}
        id={rowData.id}
        data={rowData}
        listName={name}
        columns={columns}
        select={select}
        data-first={isFirst}
        data-last={isLast}
        onRowClick={onRowClick}
        variant={variant}
        {...(isRowUncheckableValidator && { uncheckable: isRowUncheckableValidator(rowData.id) })}
      />
    </Flex>
  );
};
