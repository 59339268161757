/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { userInfoChangeAvatarAction, userInfoDeleteAvatarAction } from 'api/actions/settings/settingsActions';
import { Avatar } from 'components/Avatar/Avatar';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { settingsProfileFormDefaultValuesSelector } from 'state/settings';
import { setNativeValue } from 'utils/setNativeValue';
import { addSnackbar } from 'SnackbarHub/actions';
import { useTheme } from 'styles/useTheme';

export const AvatarForm = (): React.ReactElement => {
  useLingui();

  const { theme } = useTheme();

  const profileValues = useRecoilValue(settingsProfileFormDefaultValuesSelector);
  const { mutate: mutateAvatar } = useMutation(userInfoChangeAvatarAction);
  const { mutate: mutateDeleteAvatar } = useMutation(userInfoDeleteAvatarAction);

  const [currentAvatarUrl, setCurrentAvatarUrl] = useState<string | null>(profileValues?.avatarUrl || null);

  const avatarInputRef = useRef<HTMLInputElement | null>(null);
  const avatarHasChangedRef = useRef<boolean>(false);
  const firstRenderRef = useRef<boolean>(true);

  const triggerAvatarInputClick = () => {
    const avatarInput = avatarInputRef.current;

    if (avatarInput) {
      avatarInput.click();
    }
  };

  const clearAvatarFile = async () => {
    const { error } = await mutateDeleteAvatar({});

    if (!error) {
      setCurrentAvatarUrl(null);
      setNativeValue(avatarInputRef, '');
      avatarHasChangedRef.current = true;
      addSnackbar({
        message: t({
          id: 'settings.avatar.delete_success',
          message: 'Deleted avatar',
        }),
        variant: 'success',
      });
    }

    if (error) {
      addSnackbar({
        message: t({
          id: 'settings.avatar.delete_danger',
          message: "Couldn't delete avatar",
        }),
        variant: 'danger',
      });
    }
  };

  const handleAvatarChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files && files[0]) {
      avatarHasChangedRef.current = true;
      setCurrentAvatarUrl(URL.createObjectURL(files[0] || null));
    }
  }, []);

  const handleSubmitCallback = useCallback(async () => {
    let avatarBlob: Blob | null = null;

    if (currentAvatarUrl && avatarHasChangedRef.current) {
      const avatarResp = await fetch(currentAvatarUrl);
      avatarBlob = await avatarResp.blob();
    }

    if (avatarBlob) {
      const { error } = await mutateAvatar({ avatar: avatarBlob });

      if (!error) {
        addSnackbar({
          message: t({
            id: 'settings.avatar.add_success',
            message: 'Added avatar',
          }),
          variant: 'success',
        });
      }

      if (error) {
        addSnackbar({
          message: t({
            id: 'settings.avatar.add_danger',
            message: "Couldn't add avatar",
          }),
          variant: 'danger',
        });
      }
    }
  }, [currentAvatarUrl, mutateAvatar]);

  useEffect(() => {
    if (currentAvatarUrl && !firstRenderRef.current) {
      handleSubmitCallback();
    }

    firstRenderRef.current = false;
  }, [currentAvatarUrl, handleSubmitCallback]);

  return (
    <Flex sx={{ position: 'relative', ml: 4, flexShrink: 0, alignItems: 'center' }}>
      <input
        sx={{ width: 0, opacity: 0, position: 'absolute' }}
        type="file"
        ref={avatarInputRef}
        onChange={handleAvatarChange}
        accept="image/png, image/gif, image/jpeg"
      />
      <Flex sx={{ position: 'relative' }}>
        <Avatar size={74} image={currentAvatarUrl || undefined} />
        {currentAvatarUrl ? (
          <Flex
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Button
              variant="minimal"
              size="xs"
              shape="circle"
              aria-label="Delete"
              onClick={clearAvatarFile}
              bgOverwrite={{
                default: theme.colors.notification.closeButton.background.default,
                hover: theme.colors.notification.closeButton.background.hover,
                tap: theme.colors.notification.closeButton.background.tap,
                disabled: '',
              }}
              sx={{
                border: `1px solid`,
                borderColor: 'alphas.darker2',
                backdropFilter: 'blur(2px)',
                boxShadow: 'dropShadowDarker.levelOne',
              }}
            >
              <Icon type="x" />
            </Button>
          </Flex>
        ) : (
          <Button
            onClick={triggerAvatarInputClick}
            sx={{
              position: 'absolute',
              width: '100%',
              bottom: 0,
              left: 0,
              right: 0,
              borderBottomLeftRadius: 'sm',
              borderBottomRightRadius: 'sm',
            }}
            size="xs"
            shape="square"
            variant="minimal"
            bgOverwrite={{
              default: theme.colors.alphas.lighter5,
              hover: theme.colors.alphas.bluish5,
              tap: theme.colors.alphas.bluish6,
              disabled: '',
            }}
          >
            <Trans id="add" />
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
