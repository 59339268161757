import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { getRoutesRegExp, TO } from 'constants/routes';
import { useScrollToTop } from 'hooks/useScrollToTop/useScrollToTop';
import { SupportedLanguages } from 'constants/supportedLanguages';

type Props = {
  children: React.ReactElement[] | React.ReactElement;
  language: keyof typeof SupportedLanguages;
};

export const LocalizedRouteGate = ({ children, language }: Props): React.ReactElement => {
  const { push } = useHistory();
  const location = useLocation();
  const currentLanguage = useRef<keyof typeof SupportedLanguages>();

  useScrollToTop();

  useEffect(() => {
    const { pathname } = location;

    const redirectFromLanguage = (fromLang: keyof typeof SupportedLanguages) => {
      let mergedKeys: number[] = [];

      const urlArray = _.split(pathname, /[/](?!:)/).map((urlPart) => `/${urlPart}`);
      const keyArray = _.map(urlArray, (url, index, urls) => {
        const arrayKey = _.findKey(TO, (to) => to[fromLang] === url);

        if (!arrayKey) {
          const urlWithPrev = _.takeRightWhile(_.takeRight(urls, index), (item, i) => {
            const key = _.findKey(TO, (to) => to[fromLang] !== item);
            if (key) {
              const mergedIndex = index - i - 1;

              if (mergedIndex > 0) {
                mergedKeys.push(index - i - 1);
              }
              return true;
            }
            return false;
          }).join('');

          const arrayKeyWithPrev = _.findKey(TO, (to) => to[fromLang] === urlWithPrev);

          if (arrayKeyWithPrev) {
            return arrayKeyWithPrev;
          }
        }

        return arrayKey;
      });

      mergedKeys = _.uniq(mergedKeys);

      const keyArrayAfterMerge = _.pullAll(keyArray, _.pullAt(keyArray, mergedKeys));

      if (keyArrayAfterMerge.length > 0) {
        // const filteredKeyArray = _.filter(keyArray, (key, index, keys) => {
        //   console.log(
        //     key,
        //     keys,
        //     _.takeRight(keys, keys.length - (index + 1)),
        //     _.some(_.takeRight(keys, index + 1), (i) => i?.includes(`${key}__`)),
        //   );
        //   return _.some(keys, (i) => i?.includes(`${key}`));
        // });
        // console.log(filteredKeyArray);

        const translatedUrl = _.map(keyArrayAfterMerge, (key: keyof typeof TO, index) => {
          if (key) {
            return TO[key][language];
          }

          return urlArray[index];
        })
          .join('')
          .replace('//', '/');

        return push(translatedUrl);
        // return <Redirect to={translatedUrl} />;
      }

      return null;
    };

    if (currentLanguage.current !== language) {
      // Redirect if language changed during session
      if (currentLanguage.current) {
        redirectFromLanguage(currentLanguage.current);
      }

      // Redirects on initial URL
      if (!currentLanguage.current) {
        const otherLanguages = _.filter(
          SupportedLanguages,
          (lang) => typeof lang === 'string' && lang !== language && lang,
        );
        const routeRegExOtherLanguages = _.map(otherLanguages, (lang) =>
          getRoutesRegExp(lang as unknown as keyof typeof SupportedLanguages),
        );
        const urlLang = _.map(
          routeRegExOtherLanguages,
          (regex, index) => regex.test(pathname) && otherLanguages[index],
        )[0];

        if (urlLang && String(urlLang) !== String(language)) {
          redirectFromLanguage(urlLang as unknown as keyof typeof SupportedLanguages);
        }
      }

      currentLanguage.current = language;
    }
  }, [location, language, push]);

  // REDIRECT 404
  useEffect(() => {
    const { pathname } = location;

    if (currentLanguage.current) {
      const routeRegEx = getRoutesRegExp();

      if (!routeRegEx.test(pathname)) {
        push(TO.PAGE_NOT_FOUND[currentLanguage.current]);
      }
    }
  }, [location, push]);

  return <>{children}</>;
};
