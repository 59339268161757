import { useRecoilTransactionObserver_UNSTABLE } from 'recoil';

import { chatWindowIdsAtom } from 'state/chat';
import { notificationCenterAtom } from 'state/notification';
import { userSessionAtom } from 'state/userSession';
import { languageSelector } from '../state/recoilState';
import { sortingStateAtomFamily } from 'state/list';
import { settingsAtom as kioskSettingsAtom } from 'Kiosk/state/settingsState';
import { dateRangeFilterAtom } from 'state/filters';

export const PersistenceObserver: React.ElementType = () => {
  useRecoilTransactionObserver_UNSTABLE(async ({ snapshot }) => {
    const userSession = await snapshot.getPromise(userSessionAtom);
    const language = await snapshot.getPromise(languageSelector);
    const chatWindowIds = await snapshot.getPromise(chatWindowIdsAtom);
    const notificationCenter = await snapshot.getPromise(notificationCenterAtom);
    const dateRangeFilter = await snapshot.getPromise(dateRangeFilterAtom);

    const stateNodes = snapshot.getNodes_UNSTABLE();
    const sortingStateNodes: string[] = [];
    [...stateNodes].forEach((node) => {
      if (node.key.includes('sortingState__') && node.key !== 'sortingState__') sortingStateNodes.push(node.key);
    });
    sortingStateNodes.forEach(async (node) => {
      const splittedKey = node.split('"');
      const atomName = splittedKey[splittedKey.length - 2];
      const listState = await snapshot.getPromise(sortingStateAtomFamily(atomName));

      localStorage.setItem(node, JSON.stringify(listState));
    });

    localStorage.setItem(userSessionAtom.key, JSON.stringify({ value: userSession }));
    localStorage.setItem(languageSelector.key, JSON.stringify({ value: language }));
    localStorage.setItem(chatWindowIdsAtom.key, JSON.stringify({ value: chatWindowIds }));
    localStorage.setItem(notificationCenterAtom.key, JSON.stringify({ value: notificationCenter }));
    localStorage.setItem(dateRangeFilterAtom.key, JSON.stringify({ value: dateRangeFilter }));

    // FOR KIOSK MODE
    const kioskSettings = await snapshot.getPromise(kioskSettingsAtom);
    localStorage.setItem(kioskSettingsAtom.key, JSON.stringify({ value: kioskSettings }));
  });

  return null;
};
