/* eslint-disable no-console */
/** @jsxImportSource theme-ui */

import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Box, Text, Flex } from 'theme-ui';

import { settingsAtom } from 'Kiosk/state/settingsState';
import { windowSizeAtom } from 'state/recoilState';
import { Button } from 'components/ui/Buttons';
import { debugModeSelector } from 'Kiosk/state/kioskState';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { useCameraImageBuffer } from 'Kiosk/hooks/useCameraImageBuffer';
import { useQrScanner } from 'Kiosk/hooks/useQrScanner';
import { useFaceDetection } from 'Kiosk/hooks/useFaceLandmarkModel';
import { useGeoLocation } from 'Kiosk/hooks/useGeoLocation';
import { usePermissions } from 'Kiosk/hooks/usePermissions';
import { useIdle } from 'hooks/useIdle/useIdle';

export const DebugControlPanel = (): React.ReactElement => {
  const settings = useRecoilValue(settingsAtom);
  const { isLandscape, width, height, aspectRatio, isMobile } = useRecoilValue(windowSizeAtom);

  const cameraState = useRecoilValue(cameraStateAtom);
  const { isCameraReady, isCameraStreaming, toggleVideoPlayback } = cameraState;

  const [, setDebugMode] = useRecoilState(debugModeSelector);

  const { getBlob, getImageData } = useCameraImageBuffer();
  const { isQrScannerRunning, toggleQrScanner, pingWorker, decode } = useQrScanner({
    onDecode: (qr) => console.log(`Your Qr code - ${qr}`),
  });

  const { isModelLoaded, faceBounding, getPrediction, pointsToScan } = useFaceDetection();
  const { getGeoLocation, location } = useGeoLocation();
  const { camera: cameraPermission, location: locationPermission } = usePermissions();

  const { status, stop, start } = useIdle();

  if (!settings) {
    return <>Loading...</>;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        maxWidth: '300px',
        width: '100%',
        hight: '100%',
        overflow: 'auto',
        zIndex: 9999,
        right: 0,
        top: 0,
        bottom: 0,
        p: 3,
        bg: 'rgba(0,0,0,.35)',
      }}
    >
      <Text color="white" as="p">
        isMobile = {`${isMobile}`}
        <br />
        isLandscape = {`${isLandscape}`}
        <br />
        aspectRatio = {`${aspectRatio}`}
        <br />
        size = {`${width} x ${height}`}
      </Text>

      <hr sx={{ width: '100%' }} />
      <Text color="white" as="p" sx={{ fontWeight: 'bold' }}>
        Kiosk Settings
      </Text>
      <Text color="white" as="p">
        isFaceRecognitionRequired = {`${settings.isFaceRecognitionRequired}`}
        <br />
        isLocationRequired = {`${settings.isLocationRequired}`}
        <br />
        isSleepModeEnabled = {`${settings.isSleepModeEnabled}`}
        <br />
        sleepModeTimeOutMinutes = {`${settings.sleepModeTimeOutMinutes}`} min
      </Text>

      <hr sx={{ width: '100%' }} />
      <Text color="white" as="p" sx={{ fontWeight: 'bold' }}>
        Permissions
      </Text>
      <Text color="white" as="p">
        camera = {`${cameraPermission}`}
        <br />
        location = {`${locationPermission}`}
      </Text>

      <Flex sx={{ flexDirection: 'column' }}>
        <hr sx={{ width: '100%' }} />
        <Text color="white" as="p" sx={{ fontWeight: 'bold' }}>
          IdleObserver (SleepMode)
        </Text>
        <Text color="white" as="p">
          status = {status ? 'RUNNING' : 'STOPPED'}
        </Text>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            if (start) start();
          }}
        >
          Start
        </Button>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            if (stop) stop();
          }}
        >
          Stop
        </Button>
      </Flex>

      <Flex sx={{ flexDirection: 'column' }}>
        <hr sx={{ width: '100%' }} />
        <Text color="white" as="p" sx={{ fontWeight: 'bold' }}>
          Camera
        </Text>
        <Text color="white" as="p">
          isCameraReady = {`${isCameraReady}`}
          <br />
          isCameraStreaming = {`${isCameraStreaming}`}
        </Text>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            if (toggleVideoPlayback) toggleVideoPlayback();
          }}
        >
          Toggle Video Playback
        </Button>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            console.log(cameraState);
          }}
        >
          Log Camera State
        </Button>

        <hr sx={{ width: '100%' }} />
        <Text color="white" as="p" sx={{ fontWeight: 'bold' }}>
          QrScanner
        </Text>
        <Text color="white" as="p">
          isQrScannerRunning = {`${isQrScannerRunning}`}
        </Text>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            if (toggleQrScanner) toggleQrScanner();
          }}
        >
          Toggle QR Scanner
        </Button>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            if (pingWorker) pingWorker();
          }}
        >
          Ping Worker
        </Button>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={async () => {
            const testFrame = getImageData && (await getImageData());
            if (testFrame && decode) decode(testFrame);
          }}
        >
          Scan for QrCode
        </Button>

        <hr sx={{ width: '100%' }} />
        <Text color="white" as="p" sx={{ fontWeight: 'bold' }}>
          FaceScanner
        </Text>
        <Text color="white" as="p">
          isModelLoaded = {`${isModelLoaded}`}
        </Text>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={async () => {
            const testFrame = getImageData && (await getImageData());
            if (getPrediction && testFrame) {
              const test = await getPrediction(testFrame);
              console.log(test);
            }
          }}
        >
          getPredictions
        </Button>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            console.log({
              faceBounding,
              pointsToScan,
            });
          }}
        >
          Get Additional State
        </Button>

        <hr sx={{ width: '100%' }} />
        <Text color="white" as="p" sx={{ fontWeight: 'bold' }}>
          GeoLocation
        </Text>
        <Text color="white" as="p">
          location = {`${location?.coords.latitude} ${location?.coords.longitude}`}
        </Text>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            console.log(
              getGeoLocation &&
                getGeoLocation(
                  (position) => console.log(position),
                  () => console.log('error'),
                ),
            );
          }}
        >
          GetGeoLocation
        </Button>

        <hr sx={{ width: '100%' }} />
        <Text color="white" as="p">
          useCameraImageBuffer
        </Text>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            console.log(getImageData && getImageData());
          }}
        >
          getImageData
        </Button>
        <Button
          variant="danger"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            console.log(getBlob && getBlob());
          }}
        >
          Log Blob (ref)
        </Button>

        <hr sx={{ width: '100%' }} />
        <Text color="white" as="p">
          Other
        </Text>
        <Button
          variant="primary"
          shape="rounded"
          size="sm"
          sx={{ mt: 1 }}
          onClick={() => {
            // setIsInitialFaceScan(!isInitialFaceScan);
          }}
        >
          Toggle InitialFaceScan
        </Button>

        <hr sx={{ width: '100%' }} />
        <Button shape="rounded" size="sm" sx={{ mt: 1 }} onClick={() => setDebugMode(false)}>
          Exit DebugMode
        </Button>
      </Flex>
    </Box>
  );
};
