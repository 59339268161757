import { Trans } from '@lingui/macro';
import React from 'react';
import { Box, BoxOwnProps } from 'theme-ui';

type Props = Omit<BoxOwnProps, 'color'> &
  React.ComponentPropsWithoutRef<'div'> & {
    label: string;
    onClick: () => void;
    active?: boolean;
  };

const defaultProps: Partial<Props> = {
  active: false,
};

export const Option = ({ label, onClick, active, ...props }: Props): React.ReactElement => (
  <Box
    as="li"
    role="option"
    variant={!active ? 'forms.select.option' : 'forms.select.option.active'}
    onClick={onClick}
    {...props}
  >
    {label}
  </Box>
);

Option.defaultProps = defaultProps;

const EmptyList = () => (
  <Box as="li" sx={{ px: 3, py: 4, textAlign: 'center', cursor: 'not-allowed' }}>
    <Trans id="select.empty_list">No more results</Trans>
  </Box>
);

type OptionListProps = BoxOwnProps &
  React.ComponentPropsWithRef<'div'> & {
    children: React.ReactNode[] | React.ReactNode;
  };

export const OptionList = React.forwardRef<HTMLDivElement, OptionListProps>(
  ({ children, ...props }: OptionListProps, ref): React.ReactElement => {
    const renderChildren = () => {
      if (children === null || (Array.isArray(children) && children.every((child) => child === null))) {
        return <EmptyList />;
      }

      return children;
    };

    return (
      <Box
        as="ul"
        ref={ref}
        role="listbox"
        sx={{
          textAlign: 'left',
          overflowX: 'hidden',
          overflowY: 'auto',
          width: '100%',
          maxHeight: '300px',
          bg: 'dropdown.background',
          boxShadow: 'dropdown',
          borderRadius: 'sm',
          p: 1,
          '> li + li': {
            mt: '0.125rem',
          },
        }}
        {...props}
      >
        {renderChildren()}
      </Box>
    );
  },
);
