import React, { useCallback, useEffect, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { getSettings } from 'api/actions/timeclock/timeclockActions';
import { settingsAtom } from 'Kiosk/state/settingsState';
import { LoadingSplash } from 'components/Loading/LoadingSplash';

type Props = {
  children: React.ReactElement;
};

export const KioskSettingsInitializer = ({ children }: Props): React.ReactElement => {
  const [isInitialized, setIsInitialized] = useState(false);

  const [kioskSettings, setKioskSettings] = useRecoilState(settingsAtom);

  const { query } = useClient();

  const fetchKioskSettings = useCallback(async () => {
    const { payload, error } = await query(getSettings());

    if (!error && payload) {
      let mutableSettings;
      if (kioskSettings) {
        const { sleepModeTimeOutMinutes, summaryTimeSeconds, isSleepModeEnabled } = kioskSettings;
        mutableSettings = { sleepModeTimeOutMinutes, summaryTimeSeconds, isSleepModeEnabled };
      }

      setKioskSettings({ ...payload, ...mutableSettings });
    }

    setIsInitialized(true);
  }, [kioskSettings, query, setKioskSettings]);

  useEffect(() => {
    if (!isInitialized) {
      fetchKioskSettings();
    }

    if (kioskSettings && !isInitialized) {
      setIsInitialized(true);
    }
  }, [fetchKioskSettings, isInitialized, kioskSettings]);

  if (!isInitialized) {
    return <LoadingSplash sx={{ zIndex: 9999 }} />;
  }

  return <>{children}</>;
};
