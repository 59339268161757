import React from 'react';
import { Flex, Text } from 'theme-ui';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { OvertimeTaken } from 'api/actions/requests/requestsActions.types';
import { dateTime } from 'utils/dateTime';
import { FancyDataBox } from 'components/ui/FancyDataBox';
import { UUID } from 'utils/UUID';

const prepareDuration = (hours: number, minutes: number): string => {
  let s = '';

  if (hours) s = `${hours}h`;
  if (minutes) s += ` ${minutes}m`;

  return s;
};

type Props = {
  overtime: OvertimeTaken[];
};

export const Overtime = ({ overtime }: Props): React.ReactElement => {
  useLingui();

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: 3,
        }}
      >
        <Trans id="request.details.section.overtime">Overtime from</Trans>
      </Text>
      <Flex sx={{ flexWrap: 'wrap', gap: 1 }}>
        {overtime.map((element) => {
          const { dateUnix, minutes, hours } = element;

          return (
            <FancyDataBox key={UUID()} appendElements={[prepareDuration(hours, minutes)]}>
              {dateTime(dateUnix).format(`D MMM`)}
            </FancyDataBox>
          );
        })}
      </Flex>
    </Flex>
  );
};
