import React from 'react';
import { BoxOwnProps, BoxProps, Flex, Heading, Text } from 'theme-ui';

type Props = BoxOwnProps &
  BoxProps & {
    label: string;
    variant?: string;
    errorMessage?: string;
    errorFontSize?: number;
  };

const defaultProps: Partial<Props> = {
  variant: 'heading4',
  errorMessage: undefined,
  errorFontSize: 0,
};

export const HeadingError = ({ label, variant, errorMessage, errorFontSize, ...props }: Props): React.ReactElement => (
  <Flex {...props}>
    <Heading variant={variant}>{label}</Heading>
    <Text as="p" sx={{ fontSize: errorFontSize, fontWeight: 'bold', color: 'reds4', ml: 'auto', alignSelf: 'center' }}>
      {errorMessage}
    </Text>
  </Flex>
);

HeadingError.defaultProps = defaultProps;
