import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FixedSizeList, VariableSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Text, Flex } from 'theme-ui';
import { useRecoilState, useSetRecoilState } from 'recoil';
import _ from 'lodash';

import {
  isRowUncheckableValidatorAtomFamily,
  stickyListActionsAtomFamily,
  sortedListMapSelectorFamily,
  sortingStateAtomFamily,
} from 'state/list';

import { ListColumn, StickyListProps, SortingOrder } from './types';
import { GRID_DEFAULT_HEIGHT, ITEM_DEFAULT_HEIGHT, ITEM_DEFAULT_WIDTH } from './constants';
import { gridRenderer } from './renderers/gridRenderer';
import { rowRenderer } from './renderers/rowRenderer';
import { StickyListInnerElement } from './StickyListInnerElement';
import { StickyListContext } from './context';
import { StickyListOuterElement } from './StickyListOuterElement';

// TODO:
// 1. Add StickyListActions for type = list

export const StickyList = ({
  showHeader,
  columns,
  list,
  name,
  emptyListMessage,
  select,
  style,
  showContentPlaceholder,
  onRowClick,
  variant = 'default',
  type = 'list',
  frozenColumns,
  disableNativeKeyboardScrolling,
  isRowUncheckableValidator,
  fallbackSortableValueGetter,
}: StickyListProps): React.ReactElement => {
  const [sortingState, setSortingState] = useRecoilState(sortingStateAtomFamily(name));
  const [sortedList, setSortedList] = useRecoilState(sortedListMapSelectorFamily(name));
  const setIsRowUncheckableValidator = useSetRecoilState(isRowUncheckableValidatorAtomFamily(name));
  const setStickyListActions = useSetRecoilState(stickyListActionsAtomFamily(name));

  const gridRef = useRef<VariableSizeGrid | null>(null);

  const listCallback = useCallback((columnIndex: number, rowIndex: number) => {
    gridRef?.current?.scrollToItem({
      align: 'auto',
      columnIndex,
      rowIndex,
    });
  }, []);

  useEffect(() => {
    setStickyListActions({ scrollTo: listCallback, list: sortedList, columns: columns.map((c) => c.title || '') });
  }, [columns, listCallback, setStickyListActions, sortedList]);

  const isListEmpty = useMemo(() => (_.isArray(list) ? !list.length : !list.size), [list]);

  useEffect(() => {
    if (isRowUncheckableValidator) {
      setIsRowUncheckableValidator({ value: isRowUncheckableValidator });
    }
  }, [isRowUncheckableValidator, setIsRowUncheckableValidator]);

  useEffect(() => {
    if (!sortingState) {
      const firstSortableColumn = _.find(columns, (column) => column.sortableValue) as ListColumn;

      if (firstSortableColumn.key) {
        setSortingState({
          columnKey: firstSortableColumn.key,
          order: SortingOrder.ASC,
        });
      }
    }
  }, [columns, setSortingState, sortingState]);

  useEffect(() => {
    let sortedMap = new Map();

    if (sortingState) {
      const sortingColumn = _.find(columns, (column) => _.isEqual(column.key, sortingState.columnKey));
      const newMap = new Map();

      if (sortingColumn && sortingColumn.sortableValue) {
        const { sortableValue, key } = sortingColumn;

        list.forEach((item) => {
          const content = _.isArray(key) ? _.map(key, (k) => item[k]) : item[key];
          const sortableVal = sortableValue(content);

          newMap.set(item, _.isString(sortableVal) ? _.trim(`${sortableValue(content)}`) : sortableVal);
        });
      }

      const listOrderBy = _.orderBy(
        [...newMap],
        [
          (item) => item[1],
          ...(fallbackSortableValueGetter
            ? [
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (item: [any, any]) => {
                  const fallbackSortableValue = fallbackSortableValueGetter(item[0]);
                  return _.isString(fallbackSortableValue) ? _.trim(fallbackSortableValue) : fallbackSortableValue;
                },
              ]
            : []),
        ],
        [sortingState.order, ...(fallbackSortableValueGetter ? [SortingOrder.ASC] : [])],
      );

      sortedMap = new Map(_.map(listOrderBy, (item) => [item[0].id, item[0]]));
    }

    setSortedList(sortedMap);
  }, [columns, list, setSortedList, sortingState, fallbackSortableValueGetter]);

  const rowCount = React.useMemo(() => (_.isArray(list) ? list.length : list.size), [list]);
  const columnCount = React.useMemo(() => columns.length, [columns]);
  const createItemData = React.useMemo(
    () => ({
      name,
      columns,
      select,
      showHeader,
      disableNativeKeyboardScrolling,
      list: sortedList ? new Map(_.toPairs([...sortedList.values()])) : new Map(),
      itemCount: rowCount,
      frozenColumns,
      onRowClick,
      variant,
      isRowUncheckableValidator,
    }),
    [
      name,
      columns,
      select,
      showHeader,
      disableNativeKeyboardScrolling,
      sortedList,
      rowCount,
      frozenColumns,
      onRowClick,
      variant,
      isRowUncheckableValidator,
    ],
  );
  const createContextData = useMemo(
    () => ({
      ...createItemData,
      listStyle: style,
      type,
      showContentPlaceholder,
    }),
    [createItemData, showContentPlaceholder, style, type],
  );

  const columnWidths = useMemo(
    () => (type === 'grid' ? columns.map((column) => parseInt(`${column.width}`, 10) || ITEM_DEFAULT_WIDTH) : []),
    [columns, type],
  );

  return (
    <StickyListContext.Provider value={createContextData}>
      {sortedList && sortedList.size > 0 ? (
        <AutoSizer>
          {({ height, width }) =>
            type === 'list' ? (
              <FixedSizeList
                itemData={createItemData}
                innerElementType={StickyListInnerElement}
                outerElementType={StickyListOuterElement}
                itemCount={rowCount}
                itemSize={ITEM_DEFAULT_HEIGHT}
                overscanCount={5}
                width={width}
                height={height}
                style={{
                  paddingBottom: '0.75rem',
                  paddingLeft: '1px',
                  paddingRight: '1px',
                  ...style,
                }}
              >
                {rowRenderer}
              </FixedSizeList>
            ) : (
              <VariableSizeGrid
                ref={gridRef}
                itemData={createItemData}
                innerElementType={StickyListInnerElement}
                outerElementType={StickyListOuterElement}
                columnCount={columnCount}
                columnWidth={(index) => columnWidths[index]}
                rowCount={rowCount}
                rowHeight={() => GRID_DEFAULT_HEIGHT}
                overscanColumnCount={5}
                overscanRowCount={5}
                width={width}
                height={height}
                style={{
                  paddingBottom: '0.75rem',
                  ...style,
                }}
              >
                {gridRenderer}
              </VariableSizeGrid>
            )
          }
        </AutoSizer>
      ) : (
        isListEmpty && (
          <Flex sx={{ flexDirection: 'column', width: '100%', p: 4 }}>
            <Flex sx={{ width: '100%', p: 4, justifyContent: 'center' }}>
              <Text as="span" sx={{ display: 'inline-flex', fontWeight: '600', color: 'texts.lighter' }}>
                {emptyListMessage || 'This list is empty.'}
              </Text>
            </Flex>
          </Flex>
        )
      )}
    </StickyListContext.Provider>
  );
};
