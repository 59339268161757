import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Location } from 'history';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Flex, Heading } from 'theme-ui';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';

import { userInfoAction } from 'api/actions/settings/settingsActions';
import { FetchUserInfoResponse, UserInfoActionProps } from 'api/actions/settings/settingsActions.types';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { ElementGroup } from 'components/ui/ElementGroup';
import { PhoneInput } from 'components/ui/PhoneInput';
import { TextInput } from 'components/ui/TextInput';
import { useBlockRouteTransition } from 'hooks/useBlockTransition/useBlockRouteTransition';
import { TIMER_END_TIME, TIMER_INTERVAL } from 'layouts/Settings/Organization/forms/constants';
import { Paths } from 'layouts/Settings/Organization/utils/createUnionOfTypeKeys';
import { addSnackbar } from 'SnackbarHub/actions';
import { resetFormButtonAtom } from 'state/settings';
import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';

import { AvatarForm } from './AvatarForm';

type Props = {
  userInfo: FetchUserInfoResponse;
};

type keys = Paths<UserInfoActionProps>;

export const ProfileForm = ({ userInfo }: Props): React.ReactElement => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const history = useHistory();
  const { mutate } = useMutation(userInfoAction);

  const [showAddressDetails, setShowAddressDetails] = useState(!!userInfo.address);
  const [profilePayload, setProfilePayload] = useState<FetchUserInfoResponse>(userInfo);

  const phoneNumberRef = useRef<HTMLInputElement | null>(null);
  const blockLocationPathnameRef = useRef<string | null>(null);
  const shouldBlockRef = useRef<boolean>(false);
  const formPending = useRef<boolean>(false);
  const statusRef = useRef<Status>('STOPPED');

  const {
    setError,
    clearErrors,
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: profilePayload,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  const dispatchSubmitEvent = useCallback(() => {
    const submitEvent = createEvent('submit');
    formPending.current = true;
    formRef.current?.dispatchEvent(submitEvent);
  }, []);

  const { start, reset, status } = useTimer({
    endTime: TIMER_END_TIME,
    interval: TIMER_INTERVAL,
    onTimeOver: () => dispatchSubmitEvent(),
  });

  const handleOnBlur = useCallback(() => {
    shouldBlockRef.current = true;
    blockLocationPathnameRef.current = null;
    reset();
    start();
  }, [reset, start]);

  const registerOnBlur = useCallback(
    (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onBlur, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          onBlur(e);
          handleOnBlur();
        },
      };
    },
    [handleOnBlur, register],
  );

  const setPhoneErrorCallback = useCallback(() => {
    setError('phoneNumberInternalError' as 'phoneNumber', {
      message: 'only visible in code',
    });
  }, [setError]);

  const clearPhoneErrorCallback = useCallback(() => {
    clearErrors('phoneNumberInternalError' as 'phoneNumber');
  }, [clearErrors]);

  const phoneNumberRegister = useMemo(() => registerOnBlur('phoneNumber'), [registerOnBlur]);

  const handleFormReset = useCallback(() => {
    resetForm({ ...profilePayload, phoneNumber: profilePayload.phoneNumber || '' });
    shouldBlockRef.current = false;
    formPending.current = false;
  }, [profilePayload, resetForm]);

  const userProfileResetObject = useMemo(
    () => ({ name: 'USER_PROFILE_CALLBACK', callback: handleFormReset }),
    [handleFormReset],
  );

  const handleSubmitCallback = useCallback(
    async (data: UserInfoActionProps) => {
      const dataObject: UserInfoActionProps = {
        ...data,
        address:
          data.address && (data.address.postalCode !== '' || data.address.city !== '' || data.address.street !== '')
            ? {
                postalCode:
                  !data.address.postalCode || data.address.postalCode === '' ? undefined : data.address.postalCode,
                city: !data.address.city || data.address.city === '' ? undefined : data.address.city,
                street: !data.address.street || data.address.street === '' ? undefined : data.address.street,
              }
            : undefined,
        // TODO: add a demasking util method exported from PhoneInput
        phoneNumber:
          !data.phoneNumber || data.phoneNumber.replaceAll(' ', '').replace('+', '') === ''
            ? undefined
            : data.phoneNumber.replaceAll(' ', '').replace('+', ''),
      };

      const payloadComparisonObject: UserInfoActionProps = {
        ...profilePayload,
        address: profilePayload.address
          ? {
              postalCode:
                !profilePayload.address.postalCode || profilePayload.address.postalCode === ''
                  ? undefined
                  : profilePayload.address.postalCode,
              city:
                !profilePayload.address.city || profilePayload.address.city === ''
                  ? undefined
                  : profilePayload.address.city,
              street:
                !profilePayload.address.street || profilePayload.address.street === ''
                  ? undefined
                  : profilePayload.address.street,
            }
          : undefined,
        phoneNumber: !profilePayload.phoneNumber ? undefined : profilePayload.phoneNumber,
      };

      if (!_.isEqual(dataObject, payloadComparisonObject)) {
        const { error } = await mutate(dataObject);

        if (!error) {
          setProfilePayload(dataObject);
          setResetCallbacks(null);
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_success',
              message: 'Changes saved',
            }),
            variant: 'success',
          });
        }

        if (error) {
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_fail',
              message: "Couldn't save changes",
            }),
            variant: 'danger',
          });
          setResetCallbacks([userProfileResetObject]);
          return;
        }
      }

      formPending.current = false;
      shouldBlockRef.current = false;
      if (blockLocationPathnameRef.current) {
        history.push(blockLocationPathnameRef.current);
      }
    },
    [history, mutate, profilePayload, setResetCallbacks, userProfileResetObject],
  );

  useEffect(() => {
    if (
      errors.firstName ||
      errors.phoneNumber ||
      errors.surname ||
      errors.email ||
      errors['phoneNumberInternalError' as 'phoneNumber']
    ) {
      setResetCallbacks([userProfileResetObject]);
    } else {
      setResetCallbacks(null);
    }
  }, [
    errors,
    errors.firstName,
    errors.phoneNumber,
    errors.surname,
    errors.email,
    setResetCallbacks,
    userProfileResetObject,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    errors['phoneNumberInternalError' as 'phoneNumber'],
  ]);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

  const handlePageTransitionCallback = useCallback(
    (location: Location) => {
      blockLocationPathnameRef.current = location.pathname;

      if (statusRef.current === 'RUNNING') {
        reset();
        dispatchSubmitEvent();
      }

      if (shouldBlockRef.current || formPending.current) return false;

      return true;
    },
    [dispatchSubmitEvent, reset],
  );

  useBlockRouteTransition(handlePageTransitionCallback);

  return (
    <form ref={formRef} onSubmit={handleSubmit(handleSubmitCallback)}>
      <Flex sx={{ maxWidth: '600px', flexDirection: 'column', gap: '2.25rem' }}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Heading variant="heading4.withMargins">
            <Trans id="settings.user.profile.basic_information">Basic information</Trans>
          </Heading>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <ElementGroup marginValue="1px" showAsList direction="column" wrapperSx={{ flexGrow: 1 }}>
              <TextInput
                size="sm"
                id="firstName"
                placeholder={t({
                  id: 'settings.user.profile.first_name',
                  message: 'First name',
                })}
                label={t({
                  id: 'settings.user.profile.name',
                  message: 'Name',
                })}
                variant="roundedTop"
                error={!!errors.firstName}
                errorMessage={errors?.firstName?.message}
                {...registerOnBlur('firstName', validationFactory({ ...VALIDATION_RULES.FIRST_NAME, required: true }))}
              />
              <TextInput
                size="sm"
                id="surname"
                placeholder={t({
                  id: 'settings.user.profile.surname',
                  message: 'Surname',
                })}
                variant="roundedBottom"
                error={!!errors.surname}
                errorMessage={errors?.surname?.message}
                {...registerOnBlur('surname', validationFactory({ ...VALIDATION_RULES.SURNAME, required: true }))}
              />
            </ElementGroup>
            <Flex sx={{ alignSelf: 'end', flexGrow: 0 }}>
              <AvatarForm />
            </Flex>
          </Flex>
        </Flex>

        <Flex sx={{ flexDirection: 'column' }}>
          <Heading variant="heading4.withMargins">
            <Trans id="settings.user.profile.contact_information">Contact information</Trans>
          </Heading>
          <ElementGroup marginValue={3} wrapperSx={{ width: '100%' }}>
            <TextInput
              size="sm"
              id="email"
              placeholder={t({
                id: 'global.forms.inputs.email',
                message: 'E-mail address',
              })}
              label="E-mail"
              variant="rounded"
              type="email"
              autoComplete="email"
              error={!!errors.email}
              errorMessage={errors?.email?.message}
              {...registerOnBlur('email', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
            />

            <PhoneInput
              {...phoneNumberRegister}
              ref={mergeRefs([phoneNumberRegister.ref, phoneNumberRef])}
              size="sm"
              id="phoneNumber"
              placeholder={t({
                id: 'global.forms.inputs.phone_number',
                message: 'Phone number',
              })}
              label={t({
                id: 'global.forms.inputs.phone',
                message: 'Phone',
              })}
              variant="rounded"
              onValidError={setPhoneErrorCallback}
              onClearError={clearPhoneErrorCallback}
              error={!!errors.phoneNumber || !!errors['phoneNumberInternalError' as 'phoneNumber']}
              errorMessage={errors?.phoneNumber?.message}
              clearable
            />
          </ElementGroup>
          <Flex mt={2}>
            {!showAddressDetails ? (
              <ShowFieldsButton
                onClick={() => setShowAddressDetails(true)}
                label={t({ id: 'team.user.address.add' })}
                sx={{ py: 1 }}
              />
            ) : (
              <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
                <TextInput
                  size="sm"
                  id="street"
                  placeholder={t({
                    id: 'global.forms.inputs.street',
                    message: 'Street',
                  })}
                  type="text"
                  error={!!errors.address?.street}
                  errorMessage={errors?.address?.street?.message}
                  clearable
                  {...registerOnBlur('address.street', validationFactory(VALIDATION_RULES.STREET))}
                  label={t({
                    id: 'settings.user.profile.address_details',
                    message: 'Address details',
                  })}
                />
                <Flex mt={1}>
                  <TextInput
                    size="sm"
                    id="postalCode"
                    placeholder={t({
                      id: 'global.forms.inputs.zip',
                      message: 'ZIP',
                    })}
                    type="text"
                    error={!!errors.address?.postalCode}
                    errorMessage={errors?.address?.postalCode?.message}
                    clearable
                    {...registerOnBlur('address.postalCode', validationFactory(VALIDATION_RULES.POSTAL_CODE))}
                    sx={{ maxWidth: '120px', mr: 1 }}
                  />
                  <TextInput
                    size="sm"
                    id="city"
                    placeholder={t({
                      id: 'global.forms.inputs.city',
                      message: 'City',
                    })}
                    type="text"
                    error={!!errors.address?.city}
                    errorMessage={errors?.address?.city?.message}
                    clearable
                    {...registerOnBlur('address.city', validationFactory(VALIDATION_RULES.CITY))}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};
