import React from 'react';
import { Global } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import { Route } from 'react-router';

import { Navbar } from 'layouts/AuthorizedApp/Navbar';
import { THEME_NAVBAR_HEIGHT } from 'styles/theme/base';
import { PATH } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

import { AsideFiltersRenderer } from './AsideFilters';

type Props = {
  children: React.ReactElement[] | React.ReactElement;
};

export const AuthorizedAppLayout = ({ children }: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <Global
        styles={(theme) => ({
          body: {
            background: theme.colors.backgrounds.authorizedApp,
            paddingTop: THEME_NAVBAR_HEIGHT,
            '@media screen and (max-width: 991px)': {
              paddingBottom: THEME_NAVBAR_HEIGHT,
            },
          },
        })}
      />
      <Navbar />
      <Route
        path={[
          PATH.TEAM[language],
          PATH.TIME_TRACKING__CLOCK_LOG[language],
          PATH.TIME_TRACKING__LOCATIONS[language],
          PATH.TIME_TRACKING__WORK_STATUSES[language],
          PATH.TIME_TRACKING__TIME_CLOCKS[language],
          PATH.REPORTS__ATTENDANCE_LIST[language],
          PATH.REPORTS__TIMESHEETS[language],
          PATH.REQUESTS[language],
        ]}
      >
        <AsideFiltersRenderer />
      </Route>
      {children}
    </>
  );
};

export * from './Main/Main';
