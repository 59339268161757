import React from 'react';
import { InputProps } from 'theme-ui';

import { createEvent } from './createEvent';

export function setNativeValue(
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
  value: InputProps['value'] | InputProps['value'][],
): void {
  const input = inputRef?.current;

  if (input) {
    const nativeInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');
    if (nativeInputValue) {
      const nativeInputValueSetter = nativeInputValue.set;

      if (nativeInputValueSetter) {
        nativeInputValueSetter.call(input, value);

        const event = createEvent('input');
        input.dispatchEvent(event);
      }
    }
  }
}
