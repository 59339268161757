import React from 'react';
import { Box, Text } from 'theme-ui';
import { Trans } from '@lingui/macro';

import { Icon } from 'components/Icon/Icon';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { Button } from '../Buttons';
import { useTheme } from 'styles/useTheme';

import {
  FileNameProps,
  ProgressBarProps,
  LoadingStatusProps,
  UploadingButtonsProps,
  FileDataProps,
  DeleteButtonProps,
} from './types';
import { bytesToMB, shortenFileName } from './helpers';
import { DEFAULT_MAX_NAME_DISPLAY_LENGTH } from './constants';

const TextWithTooltip = withTooltip(Text);

export const ProgressBar = ({ hasError, uploadPercent }: ProgressBarProps): React.ReactElement => (
  <Box variant="fileUpload.progressBar" data-has-error={hasError} style={{ width: `${uploadPercent}%` }}>
    {/* <Box  /> */}
  </Box>
);

export const LoadingStatus = ({ hasError }: LoadingStatusProps): React.ReactElement => (
  <Text
    sx={{
      opacity: 0.7,
      ...(hasError && {
        color: 'reds7',
      }),
    }}
  >
    {!hasError ? (
      <Trans id="file_upload.uploading">Uploading...</Trans>
    ) : (
      <Trans id="file_upload.failure">Upload failed!</Trans>
    )}
  </Text>
);

export const UploadingButtons = ({ onCancel, onRetry, showRetry }: UploadingButtonsProps): React.ReactElement => {
  const { theme } = useTheme();

  const handelOnClick = () => {
    if (showRetry) {
      onRetry();
    } else if (onCancel) onCancel();
  };

  return (
    <Button
      onClick={handelOnClick}
      variant="minimal"
      size="sm"
      shape="rounded"
      bgOverwrite={{
        default: theme.colors.alphas.darker0,
        hover: theme.colors.alphas.darker2,
        tap: theme.colors.alphas.darker3,
        disabled: '',
      }}
      sx={{
        ...(showRetry && {
          color: 'reds6',
        }),
      }}
    >
      {showRetry ? <Trans id="file_upload.retry">Retry</Trans> : <Icon type="x" />}
    </Button>
  );
};

export const FileName = ({ name }: FileNameProps): React.ReactElement => (
  <ConditionalWrapper
    condition={name.length > DEFAULT_MAX_NAME_DISPLAY_LENGTH}
    wrapper={({ children }) => (
      <TextWithTooltip tooltipProps={{ content: name }}>
        <>{children}</>
      </TextWithTooltip>
    )}
  >
    <Text>{shortenFileName(name)}</Text>
  </ConditionalWrapper>
);

export const FileData = ({ size }: FileDataProps): React.ReactElement => (
  <Text variant="fileUpload.smallText">{`${bytesToMB(size)} MB`}</Text>
);

export const DeleteButton = ({ onClick }: DeleteButtonProps): React.ReactElement | null => {
  const { theme } = useTheme();

  return (
    <Button
      variant="minimal"
      size="xs"
      shape="circle"
      aria-label="Delete"
      onClick={onClick}
      bgOverwrite={{
        default: theme.colors.notification.closeButton.background.default,
        hover: theme.colors.notification.closeButton.background.hover,
        tap: theme.colors.notification.closeButton.background.tap,
        disabled: '',
      }}
      sx={{
        position: 'absolute',
        top: -8,
        right: -8,
        border: `1px solid`,
        borderColor: 'alphas.darker2',
        backdropFilter: 'blur(2px)',
        boxShadow: 'dropShadowDarker.levelOne',
      }}
    >
      <Icon type="x" fill={theme.colors.notification.closeButton.fill} />
    </Button>
  );
};
