import React, { useMemo } from 'react';
import { Text } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ParsedRequest } from 'state/requests';
import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { RequestType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { isOfType } from 'utils/isOfType';

type Props = Pick<ParsedRequest, 'type' | 'typeDescription' | 'requestType'> &
  Partial<Pick<ParsedRequest, 'actionType'>>;

export const RequestTypePresenter = ({ requestType, type, typeDescription, actionType }: Props): React.ReactElement => {
  useLingui();

  const actionDescription = useMemo((): string => {
    switch (actionType) {
      case RequestActionType.Create:
        return t({
          id: 'request.action.create',
          message: 'Create',
        });

      case RequestActionType.Edit:
        return t({
          id: 'request.action.edit',
          message: 'Edit',
        });

      case RequestActionType.Remove:
        return t({
          id: 'request.action.remove',
          message: 'Remove',
        });

      default:
        return '';
    }
  }, [actionType]);

  const displayType = useMemo((): string => {
    switch (type) {
      case RequestFormType.BusinessTrip:
        return t({ id: 'requests_settings.heading.business_trip' });
      case RequestFormType.Custom:
        return t({ id: 'request.type.custom' });
      case RequestFormType.TimeOff:
        return t({ id: 'request.type.time_off' });
      case RequestFormType.TimeTracking:
        return t({ id: 'request.type.time_tracking' });
      default:
        return typeDescription;
    }
  }, [type, typeDescription]);

  return (
    <Text variant="ellipsis">
      <Text as="span" sx={{ color: 'texts.lighter' }}>
        {displayType} / {actionDescription} /{' '}
      </Text>
      {requestType ? (
        <>
          {isOfType<RequestType>(requestType, 'abbreviation') && (
            <>
              <Trans id={requestType.abbreviation} /> -{' '}
            </>
          )}
          <Trans id={requestType.name} />
        </>
      ) : (
        `${typeDescription}`
      )}
    </Text>
  );
};
