import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { ElementGroup } from 'components/ui/ElementGroup';
import { Divider } from '../Divider/Divider';
import { useTheme } from 'styles/useTheme';

import { DropdownLink } from './DropdownLink';
import { DropdownLinks } from './types';
import { GroupTitle } from './GroupTitle';

const dropdownSx: ThemeUIStyleObject = {
  bg: 'dropdown.background',
  p: 1,
  borderRadius: 'sm',
  flexDirection: 'column',
  boxShadow: 'dropdown',
};

type Props = {
  links: DropdownLinks;
  onClick?: () => void;
  sx?: ThemeUIStyleObject;
  size?: 'sm' | 'default';
};

export type DropdownProps = Props;

const defaultProps: Partial<Props> = {
  sx: undefined,
  onClick: undefined,
  size: 'default',
};

export const Dropdown = ({ links, sx, onClick, size }: Props): React.ReactElement => {
  const { theme } = useTheme();

  if (!size) {
    return <></>;
  }

  return (
    <ElementGroup
      showAsList
      wrapperSx={{
        ...theme.dropdown[size],
        ...dropdownSx,
        ...(sx && sx),
      }}
      direction="column"
    >
      {links.map(({ prependWithTitle, prependWithDivider, sx: linkSx, ...link }) => (
        <React.Fragment key={link.label}>
          {prependWithDivider && <Divider sx={{ my: 1 }} />}
          {prependWithTitle && <GroupTitle title={prependWithTitle} />}
          <DropdownLink
            {...link}
            sx={{ ...linkSx, ...theme.dropdown[size].linkSx }}
            to={link.to}
            onClick={() => {
              if (link.onClick) {
                link.onClick();
              }

              if (onClick) {
                onClick();
              }
            }}
          >
            {link.label}
          </DropdownLink>
        </React.Fragment>
      ))}
    </ElementGroup>
  );
};

Dropdown.defaultProps = defaultProps;
