import React from 'react';
import { useRecoilValue } from 'recoil';

import { PATH } from 'constants/routes';
import { AddCustomRequestModal } from 'layouts/Settings/Organization/modals/AddCustomRequestModal';
import { AddTimeOffTypeModal } from 'layouts/Settings/Organization/modals/AddTimeOffTypeModal';
import { ModalRoute } from 'routing/ModalRoute';
import { languageSelector } from 'state/recoilState';
import { EditTimeOffTypeModal } from 'layouts/Settings/Organization/modals/EditTimeOffTypeModal';
import { DeleteTimeOffTypeModal } from 'layouts/Settings/Organization/modals/DeleteTimeOffTypeModal';
import { EditCustomRequestModal } from 'layouts/Settings/Organization/modals/EditCustomRequestModal';
import { DeleteCustomRequestModal } from 'layouts/Settings/Organization/modals/DeleteCustomRequestModal';

export const RequestModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <ModalRoute size="sm" path={PATH.ADD_TIME_OFF_TYPE_MODAL[language]}>
        <AddTimeOffTypeModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.EDIT_TIME_OFF_TYPE_MODAL[language]}>
        <EditTimeOffTypeModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.DELETE_TIME_OFF_TYPE_MODAL[language]}>
        <DeleteTimeOffTypeModal />
      </ModalRoute>

      <ModalRoute size="sm" path={PATH.ADD_CUSTOM_REQUEST_TYPE_MODAL[language]}>
        <AddCustomRequestModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.EDIT_CUSTOM_REQUEST_TYPE_MODAL[language]}>
        <EditCustomRequestModal />
      </ModalRoute>
      <ModalRoute size="sm" path={PATH.DELETE_CUSTOM_REQUEST_TYPE_MODAL[language]}>
        <DeleteCustomRequestModal />
      </ModalRoute>
    </>
  );
};
