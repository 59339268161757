import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from 'theme-ui';
import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from 'constants/common';
import { dateTime, timeTz } from 'utils/dateTime';
import { getDatesWithTime } from '../helpers';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

import { QuickSelect } from './QuickSelect';
import { Controls } from './Controls';
import { Weekdays } from './Weekdays';
import { DaysGrid } from './DaysGrid';
import { TimeObject, TimePickers } from './TimePickers';
import { CalendarProps } from './types';

type State = {
  currentMonth: number;
  currentYear: number;
  selectedTimes: number[];
  selectedDates?: number[];
  selectedDatesWithTimes?: number[];
};

type Props = CalendarProps;

const defaultProps: Partial<Props> = {
  onTimeChange: undefined,
  excludedDates: undefined,
  onChange: undefined,
  maxDate: DEFAULT_MAX_DATE,
  minDate: DEFAULT_MIN_DATE,
  range: false,
  selectedDateTimes: [],
  initialSelectedTimes: undefined,
  showQuickSelect: false,
  showStartTime: false,
  showEndTime: false,
  variant: 'dropdown',
  selectedMonthYear: undefined,
  controlProps: undefined,
  daysGridProps: undefined,
  recenterCalendarAfterDateSelect: true,
  onDayClickCallback: undefined,
};

const prepareCurrentView = (date: dayjs.Dayjs) => ({
  currentMonth: date.month(),
  currentYear: date.year(),
});

export const getSelectedTimes = (dateTimes: number[]): number[] => dateTimes.map((date) => timeTz(date).unix());

export const getSelectedDates = (dateTimes?: number[]): number[] | undefined => {
  if (!dateTimes) {
    return undefined;
  }

  return dateTimes.map((date) => dateTime(date, { disableTimezone: true }).startOf('day').unix());
};

export const Calendar = ({
  onTimeChange,
  onChange,
  initialSelectedTimes,
  maxDate = DEFAULT_MAX_DATE,
  minDate = DEFAULT_MIN_DATE,
  range = false,
  selectedDateTimes = [],
  showQuickSelect = false,
  showStartTime = false,
  showEndTime = false,
  excludedDates,
  variant = 'dropdown',
  selectedMonthYear,
  controlProps,
  daysGridProps,
  recenterCalendarAfterDateSelect,
  onDayClickCallback,
}: Props): React.ReactElement => {
  const TODAY_DATE = useMemo(() => dateTime().startOf('day'), []);
  const minDateUnix = useMemo(() => minDate.unix(), [minDate]);
  const maxDateUnix = useMemo(() => maxDate.unix(), [maxDate]);

  const onChangeRef = useCallbackRef(onChange);
  const onTimeChangeRef = useCallbackRef(onTimeChange);

  const [state, setState] = useState<State>({
    ...prepareCurrentView(selectedDateTimes ? dateTime(selectedDateTimes[0]) : TODAY_DATE),
    selectedTimes: getSelectedTimes([
      initialSelectedTimes && !_.isNil(initialSelectedTimes?.[0]) ? initialSelectedTimes[0] : NaN,
      initialSelectedTimes && !_.isNil(initialSelectedTimes?.[1]) ? initialSelectedTimes[1] : NaN,
    ]),
    selectedDates: getSelectedDates(selectedDateTimes),
  });

  const {
    currentMonth,
    currentYear,
    selectedDates: selectedDatesToMemoize,
    selectedTimes: selectedTimesToMemoize,
  } = state;

  const selectedDates = useMemoCompare(selectedDatesToMemoize);
  const selectedTimes = useMemoCompare(selectedTimesToMemoize);

  const memoizedExcludedDates = useMemoCompare(excludedDates);

  const excludedDatesStartDay = useMemo(() => getSelectedDates(memoizedExcludedDates), [memoizedExcludedDates]);

  const onPreviousMonth = useCallback(() => {
    const prevMonth = currentMonth - 1;

    if (prevMonth < 0) {
      setState((prev) => ({
        ...prev,
        currentMonth: 11,
        currentYear: prev.currentYear - 1,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        currentMonth: prevMonth,
      }));
    }
  }, [currentMonth]);

  const onNextMonth = useCallback(() => {
    const nextMonth = currentMonth + 1;

    if (nextMonth > 11) {
      setState((prev) => ({
        ...prev,
        currentMonth: 0,
        currentYear: prev.currentYear + 1,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        currentMonth: nextMonth,
      }));
    }
  }, [currentMonth]);

  const onTimeChangeCallback = useCallback(
    ({ startTimeUnix, endTimeUnix }: TimeObject) => {
      const times = [
        !_.isNaN(startTimeUnix) ? startTimeUnix : selectedTimes[0],
        !_.isNaN(startTimeUnix) ? endTimeUnix : selectedTimes[1],
      ];

      setState((prev) => ({
        ...prev,
        selectedTimes: times,
      }));
    },
    [selectedTimes],
  );

  const onDateChangeCallback = useCallback((dates: number[]) => {
    setState((prev) => ({ ...prev, selectedDates: dates }));
  }, []);

  const onDayClick = (dateUnix: number) => {
    if (selectedDates && selectedDates.length) {
      if (!range) {
        setState({ ...state, selectedDates: [dateUnix] });
        return;
      }

      const [startDateUnix, endDateUnix] = selectedDates;

      if (endDateUnix) {
        setState({ ...state, selectedDates: [dateUnix] });
      } else if (startDateUnix && dateUnix < startDateUnix) {
        setState({ ...state, selectedDates: [dateUnix, startDateUnix] });
      } else {
        setState({ ...state, selectedDates: [startDateUnix, dateUnix] });
      }
    } else {
      setState({ ...state, selectedDates: [dateUnix] });
    }

    if (onDayClickCallback) onDayClickCallback();
  };

  const handleMonthChangeCallback = useCallback((month: number) => {
    setState((prev) => ({
      ...prev,
      currentMonth: month,
    }));
  }, []);

  const handleYearChangeCallback = useCallback(
    (year: number) => {
      const minDateYear = minDate.year();
      const isFirstYear = minDateYear === year;

      const firstYearsMinMonth = minDate.month();

      const maxDateYear = maxDate.year();
      const isLastYear = maxDateYear === year;

      const lastYearsMaxMonth = maxDate.month();

      setState((prev) => {
        if (!isFirstYear && !isLastYear) {
          return {
            ...prev,
            currentYear: year,
          };
        }

        let newMonth: null | number = null;
        if (isFirstYear && prev.currentMonth < firstYearsMinMonth) {
          newMonth = firstYearsMinMonth;
        }
        if (isLastYear && prev.currentMonth > lastYearsMaxMonth) {
          newMonth = lastYearsMaxMonth;
        }

        return {
          ...prev,
          ...(!_.isNull(newMonth) && { currentMonth: newMonth }),
          currentYear: year,
        };
      });
    },
    [minDate, maxDate],
  );

  useEffect(() => {
    setState((prev) => {
      const currentView = prepareCurrentView(dateTime(selectedDateTimes[0]));

      return {
        ...(recenterCalendarAfterDateSelect ? currentView : prev),
        selectedTimes: prev.selectedTimes,
        selectedDates: getSelectedDates(selectedDateTimes),
      };
    });
  }, [TODAY_DATE, recenterCalendarAfterDateSelect, selectedDateTimes]);

  useEffect(() => {
    if (onTimeChangeRef.current) {
      onTimeChangeRef.current(selectedTimes);
    }
  }, [onTimeChangeRef, selectedTimes]);

  useEffect(() => {
    if (selectedDates && onChangeRef.current) {
      onChangeRef.current(getDatesWithTime(selectedDates, selectedTimes));
    }
  }, [onChangeRef, selectedDates, selectedTimes]);

  useEffect(() => {
    if (selectedMonthYear) {
      setState((prevState) => ({
        ...prevState,
        currentMonth: selectedMonthYear.month,
        currentYear: selectedMonthYear.year,
      }));
    }
  }, [selectedMonthYear]);

  return (
    <Flex variant={`forms.calendar.${variant}.container`}>
      {showQuickSelect && range && (
        <QuickSelect
          todayUnix={TODAY_DATE.utc(true).unix()}
          minDateUnix={minDateUnix}
          maxDateUnix={maxDateUnix}
          onClickCallback={onDateChangeCallback}
        />
      )}
      <Flex sx={{ flexDirection: 'column' }}>
        <Controls
          variant={variant}
          maxDate={maxDate}
          minDate={minDate}
          currentMonth={currentMonth}
          currentYear={currentYear}
          onMonthChange={handleMonthChangeCallback}
          onYearChange={handleYearChangeCallback}
          onNext={onNextMonth}
          onPrev={onPreviousMonth}
          {...(controlProps && controlProps)}
        />
        <Weekdays variant={variant} />
        <DaysGrid
          variant={variant}
          selectedDates={selectedDates}
          currentMonth={currentMonth}
          currentYear={currentYear}
          minDateUnix={minDateUnix}
          maxDateUnix={maxDateUnix}
          onDayClick={onDayClick}
          range={range}
          todayUnix={TODAY_DATE.utc(true).unix()}
          excludedDates={excludedDatesStartDay}
          {...(daysGridProps && daysGridProps)}
        />
      </Flex>
      {(showStartTime || (showEndTime && range)) && (
        <TimePickers
          selectedTimes={selectedTimes}
          showStartTime={showStartTime}
          showEndTime={showEndTime && range}
          onTimeChange={onTimeChangeCallback}
        />
      )}
    </Flex>
  );
};

Calendar.defaultProps = defaultProps;
