/** @jsxImportSource theme-ui */

import React, { useState, useRef, useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { Redirect, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useLingui } from '@lingui/react';

import { languageSelector } from 'state/recoilState';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { Checkbox } from 'components/ui/Checkbox';
import { setWorkPositionsAction } from 'api/actions/employees/employeesActions';
import { delay } from 'utils/delay';
import { createEvent } from 'utils/createEvent';
import { Select } from 'components/ui/Select/Select';
import { workPositionsSelectOptionsSelector } from 'state/team';
import { PATH, TO } from 'constants/routes';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { ModalRoute } from 'routing/ModalRoute';
import { ManageWorkPositionsModal } from '../ManageWorkPositions';
import { SetWorkPositionsActionProps } from 'api/actions/employees/employeesActions.types';
import { useModal } from 'hooks/useModal/useModal';
import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

export const SetPosition = (): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose, baseRoute } = useModal();
  const language = useRecoilValue(languageSelector);
  const [loading, setLoading] = useState(false);
  const match = useCleanRouteMatch();
  const workPositionsSelectOptions = useRecoilValue(workPositionsSelectOptionsSelector);
  const { state } = useLocation<{ ids?: string[] }>();
  const { ids } = state || {};
  const formRef = useRef<HTMLFormElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workPositionId: undefined,
      overwrite: false,
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const { mutate } = useMutation(setWorkPositionsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: Omit<SetWorkPositionsActionProps, 'employeesIds'>): Promise<boolean> => {
      if (!ids || !ids.length) return false;
      const { error: submitError } = await mutate({
        employeesIds: ids || [],
        ...body,
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_position_bulk.set', message: 'Successfully set!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, ids, addSnackbar],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    submitForm();
  };

  if (!workPositionsSelectOptions) return <Redirect to={baseRoute} />;

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_position_bulk">Set position</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          sx={{ width: '340px', maxWidth: '100%' }}
          ref={formRef}
          onSubmit={handleSubmit(onSubmit, onSubmitError)}
          noValidate
        >
          <Text sx={{ ml: 2, mt: 2, fontSize: 1, fontWeight: 'bold' }}>
            <Trans id="team.set_position_bulk.default">Default position</Trans> |{' '}
            <LinkAnchor
              replace
              standOut
              sx={{ textDecoration: 'underline' }}
              to={`${match}${TO.MANAGE_WORK_POSITIONS_MODAL[language]}`}
            >
              <Trans id="team.set_position_bulk.manage">Manage</Trans>
            </LinkAnchor>
          </Text>

          <Select
            size="sm"
            id="workPositionId"
            creatable
            placeholder={t({ id: 'team.set_position_bulk.select_add', message: 'Select or add a new one' })}
            error={!!errors.workPositionId}
            errorMessage={errors?.workPositionId?.message}
            searchable
            options={workPositionsSelectOptions}
            clearable
            {...register('workPositionId', {
              required: t({ id: 'global.forms.required' }),
            })}
          />
          <br />
          <Checkbox
            labelWrapperSx={{ ml: -2 }}
            label={t({ id: 'team.set_position_bulk.overwrite', message: 'Overwrite existing position settings' })}
            size="sm"
            {...register('overwrite')}
          />
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'team.set_position_bulk.next', message: 'Next' }),
          },
        ]}
      />

      <ModalRoute replaceMode path={PATH.MANAGE_WORK_POSITIONS_MODAL[language]} fullHeight>
        <ManageWorkPositionsModal />
      </ModalRoute>
    </>
  );
};
