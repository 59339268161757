import React, { useCallback, useState } from 'react';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import { FixedSizeList } from 'react-window';

import { Icon } from 'components/Icon/Icon';
import { ScrollController } from '../../../../components/utils/ScrollController';
import { PILL_PERSON_HEIGHT } from 'styles/theme/attendancePill';

import { Person } from './AttendancePillPersonContent';
import { MemoizedAttendancePillPerson } from './AttendancePillPerson';

type Props = {
  title: string;
  variant?: 'default' | 'success' | 'warning' | 'danger';
  persons: Person[];
  outerRef?: React.RefObject<HTMLDivElement | null>;
  sx?: ThemeUIStyleObject;
};
const defaultProps: Partial<Props> = {
  variant: 'default',
  outerRef: undefined,
  sx: undefined,
};

export const AttendancePill = ({ title, variant, persons, outerRef, sx }: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenList = useCallback(() => {
    if (persons && persons.length > 0) {
      setIsOpen(!isOpen);
    }
  }, [persons, setIsOpen, isOpen]);

  const returnScrollElement = useCallback(() => {
    if (outerRef && outerRef.current !== null) {
      return outerRef.current;
    }
    return undefined;
  }, [outerRef]);

  return (
    <Flex
      variant="attendancePill.container"
      data-is-empty={persons.length === 0 && true}
      data-is-open={isOpen}
      sx={{
        ...(sx && sx),
      }}
    >
      <Flex onClick={handleOpenList} variant="attendancePill.containerHeader">
        <Flex
          variant="attendancePill.containerCounter"
          sx={{
            bg: `attendancePill.variants.${variant}`,
          }}
        >
          {persons ? persons.length : 0}
        </Flex>

        <Text variant="attendancePill.containerTitle">{title}</Text>

        {persons.length > 0 && <Icon type={isOpen ? 'chevronUp' : 'chevronDown'} />}
      </Flex>

      {isOpen && persons.length > 0 && (
        <ScrollController element={returnScrollElement()}>
          {({ ref, outerRef: injectedOuterRef, style: injectedStyle, onScroll }) => (
            <FixedSizeList
              ref={ref}
              height={outerRef?.current?.clientHeight || 0}
              width="100%"
              itemCount={persons.length}
              itemSize={PILL_PERSON_HEIGHT}
              outerRef={injectedOuterRef}
              overscanCount={5}
              style={{
                ...injectedStyle,
                borderRadius: '12px',
              }}
              onScroll={onScroll}
            >
              {({ index, style }) => <MemoizedAttendancePillPerson person={persons[index]} key={index} style={style} />}
            </FixedSizeList>
          )}
        </ScrollController>
      )}
    </Flex>
  );
};

AttendancePill.defaultProps = defaultProps;
