/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext } from 'react';
import { t, Trans } from '@lingui/macro';
import { Link, Route, Switch, useHistory, useRouteMatch, Redirect, useParams } from 'react-router-dom';
import { Action, ClientContext, useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { Box, Flex, Heading, Text } from 'theme-ui';
import { useRecoilState, useRecoilValue } from 'recoil';
import dayjs from 'dayjs';

import { DatePicker } from 'components/ui/DatePicker/DatePicker';
import { PATH, TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { addNotification } from 'NotificationHub/actions';
import { ModalRoute } from 'routing/ModalRoute';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { PaymentTicker } from 'layouts/AuthorizedApp/Navbar/PaymentTicker';
import { Avatar } from 'components/Avatar/Avatar';
import { USER_SESSION_DEFAULT_VALUE } from 'constants/defaults';
import {
  addRefreshPageNotification,
  changeLanguage,
  refreshUserSession,
} from 'broadcastChannel/channelActionCreators/channelActionCreators';
import { broadcastChannel } from 'observers/BroadcastChannelObserver';
import { PieChartSvg } from 'components/PieProgressBar/PieChartSvg';
import { withDot } from 'components/Dot/withDot';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Button } from 'components/ui/Buttons';
import { ButtonShape } from 'components/ui/Buttons/types';
import { colors as fullColors } from 'styles/theme/colors';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { TimePicker } from 'components/ui/TimePicker/TimePicker';
import { Notification } from 'components/Notification/Notification';
import { Alert } from 'components/ui/Alert/Alert';
import { FileUpload } from 'components/ui/FileUpload/FileUpload';
import { Attachment } from 'components/ui/FileUpload/Attachment';
import { PersonSelect } from 'components/ui/PersonSelect/PersonSelect';
import { Container } from 'components/ui/Container';
import { printQrCardsAction } from 'api/actions/employees/employeesActions';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { CameraViewButton, ProgressBar, SummaryButton } from 'Kiosk/Layout';
import { PrintQrCardsActionProps, PrintQrCardsResponse } from 'api/actions/employees/employeesActions.types';
import { API_ENDPOINTS } from 'constants/api';
import { userSessionPersonIdSelector } from 'state/userSession';
import { filteredEmployeesSelector } from 'state/employees';
import { MaskedTextInput } from 'components/ui/MaskedTextInput';
import { WorkStatus } from 'components/WorkStatus/WorkStatus';
import { dateTime, durationFromSeconds } from 'utils/dateTime';

const { colors } = fullColors;

const AvatarWithDot = withDot(Avatar);

const TextWithTooltip = withTooltip(Text);

const SmallLoader = (): React.ReactElement => (
  <Text>
    <LoadingSpinner size={2} color="white" />
  </Text>
);

const FirstStep = (): React.ReactElement => (
  <>
    <Heading as="h2" variant="heading.1">
      <Trans>camera-view/help-center/1__header</Trans>
    </Heading>
    <Text as="p" variant="p.lead" mt={3}>
      <Trans>camera-view/help-center/1__message</Trans>
    </Text>
  </>
);
const SecondStep = (): React.ReactElement => (
  <>
    <Heading as="h2" variant="heading.1">
      <Trans>camera-view/help-center/2__header</Trans>
    </Heading>
    <Text as="p" variant="p.lead" mt={3}>
      <Trans>camera-view/help-center/2__message-1</Trans>
    </Text>
    <Text as="p" variant="p.lead" mt={3}>
      <Trans>camera-view/help-center/2__message-2</Trans>
    </Text>
  </>
);
const ThirdStep = (): React.ReactElement => (
  <>
    <Heading as="h2" variant="heading.1">
      <Trans>camera-view/help-center/3__header</Trans>
    </Heading>
    <Text as="p" variant="p.lead" mt={3}>
      <Trans>camera-view/help-center/3__message-1</Trans>
    </Text>
    <Text as="p" variant="p.lead" mt={3}>
      <Trans>camera-view/help-center/3__message-2</Trans>
    </Text>
    <Text as="p" variant="p.lead" mt={3}>
      <Trans>camera-view/help-center/3__message-3</Trans>
    </Text>
  </>
);
const FourthStep = (): React.ReactElement => (
  <>
    <Heading as="h2" variant="heading.1">
      <Trans>camera-view/help-center/4__header</Trans>
    </Heading>
    <Text as="p" variant="p.lead" mt={3}>
      <Trans>camera-view/help-center/4__message</Trans>
    </Text>
  </>
);
const FifthStep = (): React.ReactElement => (
  <>
    <Heading as="h2" variant="heading.1">
      <Trans>camera-view/help-center/5__header</Trans>
    </Heading>
    <Text as="p" variant="p.lead" mt={3}>
      <Trans>camera-view/help-center/5__message</Trans>
    </Text>
  </>
);
const ModalStepLink = ({ to, isActive }: { to: string; isActive: boolean }): React.ReactElement => (
  <Link to={to}>
    <Box
      sx={{
        p: 3,
        '&:hover div': {
          bg: 'primary',
          opacity: '0.4',
        },
      }}
    >
      <Box
        sx={{
          transition: 'all ease-in-out 0.16s',
          width: '8px',
          height: '8px',
          borderRadius: 'circle',
          border: '1px solid',
          borderColor: 'primary',
          bg: isActive ? 'primary' : 'transparent',
          opacity: isActive ? '1' : '0.6',
        }}
      />
    </Box>
  </Link>
);

const EditModal = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const params = useParams<{ step: string }>();
  const step = (() => {
    switch (params.step) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
        return +params.step;
      default:
        return 1;
    }
  })();

  const getCurrentStep = () => {
    switch (params.step) {
      case '1':
        return <FirstStep />;
      case '2':
        return <SecondStep />;
      case '3':
        return <ThirdStep />;
      case '4':
        return <FourthStep />;
      case '5':
        return <FifthStep />;
      default:
        return <Redirect to={`${TO.EDIT_MODAL__STEP[language]}/1`} />;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '700px',
        maxWidth: '100%',
        height: '500px',
        maxHeight: '100%',
      }}
    >
      <Box mt="70px">{getCurrentStep()}</Box>

      <Box>
        <Flex sx={{ width: '100%', justifyContent: 'center' }}>
          <Flex sx={{ justifyContent: 'space-between' }}>
            {[...new Array(5)].map((linkStep, index) => (
              <ModalStepLink to={`${TO.EDIT_MODAL__STEP[language]}/${index + 1}`} isActive={index + 1 === step} />
              // <Link to={`${ROUTES.START__EDIT_MODAL[language]}/${index + 1}`}>{index + 1}</Link>
            ))}
          </Flex>
        </Flex>

        <Flex sx={{ width: '100%', justifyContent: 'space-between', mt: 4 }}>
          {step === 1 && (
            <Link to={TO.START[language]}>
              <Button variant="minimal" size="lg" type="submit" title="submit">
                <Trans>camera-view/help-center__button--end</Trans>
              </Button>
            </Link>
          )}
          {step > 1 && (
            <Link to={`${TO.EDIT_MODAL__STEP[language]}/${step - 1}`}>
              <Button variant="minimal" size="lg" type="submit" title="submit">
                <Trans>camera-view/help-center__button--back</Trans>
              </Button>
            </Link>
          )}

          {step < 5 && (
            <Link to={`${TO.EDIT_MODAL__STEP[language]}/${step + 1}`}>
              <Button variant="primary" size="lg" type="submit" title="submit">
                <Trans>camera-view/help-center__button--next</Trans>
              </Button>
            </Link>
          )}
          {step === 5 && (
            <Link to={TO.START[language]}>
              <Button variant="primary" size="lg" type="submit" title="submit">
                <Trans>camera-view/help-center__button--end</Trans>
              </Button>
            </Link>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export const HomePage = (): React.ReactElement => {
  const filteredEmployees = useRecoilValue(filteredEmployeesSelector);

  const idArray10 = [...filteredEmployees.keys()];
  const idArray = idArray10;

  const { addSnackbar } = useSnackbar();
  const history = useHistory();

  const { query } = useContext(ClientContext);
  const [language, setLanguage] = useRecoilState(languageSelector);

  const handleRemoveTokenClicked = (): void => {
    localStorage.removeItem('accessToken');
    addNotification({ title: 'Token removed!' });
  };

  const handleChangeLanguageClicked = (): void => {
    if (language === 'en') {
      setLanguage('pl');

      return;
    }
    if (language === 'pl') {
      setLanguage('en');
    }
    addNotification({ title: 'You have changed the language and this is a very looooong text.' });
  };

  const defaultValues = {
    personSelect: '2',
    personSelectErrorTest: '11',
    datePicker2: [1638130230, 1667712000],
    datePicker: 1667712000,
    datePicker3: 1667766000,
    datePicker4: 1667766000,
    durationPicker: 333,
    // durationPicker: 0,
    timePicker: 42400,
    // timePicker: 32400,
    masked: 233,
  };

  const {
    register: registerPersonSelect,
    handleSubmit: handleSubmitPersonSelect,
    reset: resetForm,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const setDurationPickerErrorCallback = useCallback(() => {
    setError('durationPickerInternalError' as 'durationPicker', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearDurationPickerErrorCallback = useCallback(() => {
    clearErrors('durationPickerInternalError' as 'durationPicker');
  }, [clearErrors]);
  const setDatePickerError = useCallback(() => {
    setError('datePickerInternalError' as 'datePicker', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearDatePickerError = useCallback(() => {
    clearErrors('datePickerInternalError' as 'datePicker');
  }, [clearErrors]);
  const setTimePickerPickerError = useCallback(() => {
    setError('timePickerInternalError' as 'timePicker', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearTimePickerError = useCallback(() => {
    clearErrors('timePickerInternalError' as 'timePicker');
  }, [clearErrors]);

  const setDatePickerError2 = useCallback(() => {
    setError('datePickerInternalError2' as 'datePicker2', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearDatePickerError2 = useCallback(() => {
    clearErrors('datePickerInternalError2' as 'datePicker2');
  }, [clearErrors]);

  const setDatePickerError3 = useCallback(() => {
    setError('datePickerInternalError3' as 'datePicker3', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearDatePickerError3 = useCallback(() => {
    clearErrors('datePickerInternalError3' as 'datePicker3');
  }, [clearErrors]);

  const setDatePickerError4 = useCallback(() => {
    setError('datePickerInternalError4' as 'datePicker4', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearDatePickerError4 = useCallback(() => {
    clearErrors('datePickerInternalError4' as 'datePicker4');
  }, [clearErrors]);

  const { mutate } = useMutation(printQrCardsAction);
  const { mutate: download } = useMutation(
    (body: PrintQrCardsActionProps): Action<PrintQrCardsResponse> => ({
      method: 'POST',
      endpoint: API_ENDPOINTS.printQr,
      headers: {
        'X-Download': 'true',
      },
      body,
      config: {
        customFetchTimeout: 5000,
      },
    }),
  );
  const personId = useRecoilValue(userSessionPersonIdSelector);

  const handlePrintFile = async () => {
    if (!personId) return;
    const [selectedIds, showFirstName, showLastName, showTags, showPhoto] = [[personId], true, false, false, true];
    await mutate({
      employeesIds: selectedIds,
      showFirstName,
      showLastName,
      showTags,
      showPhoto,
    });
  };
  const handleDownloadFile = async () => {
    if (!personId) return;
    const [selectedIds, showFirstName, showLastName, showTags, showPhoto] = [[personId], true, false, false, true];
    await download({
      employeesIds: selectedIds,
      showFirstName,
      showLastName,
      showTags,
      showPhoto,
    });
  };

  const {
    handleSubmit: handleSubmitFileUpload,
    formState: { errors: fileUploadFormErrors },
    register: registerFileUpload,
    setError: setErrorFileUpload,
    clearErrors: clearErrorsFileUpload,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      // fileUpload: ['a09def36-8eda-46a5-92ff-1c8015fc15df'],
    },
  });

  const { register: registerAttachment } = useForm({
    defaultValues: {
      // attachment: ['a09def36-8eda-46a5-92ff-1c8015fc15df'],
    },
  });

  const setFileUploadErrorCallback = useCallback(() => {
    setErrorFileUpload('fileUploadInternalError' as 'fileUpload', {
      message: 'only visible in code',
    });
  }, [setErrorFileUpload]);
  const clearFileUploadErrorCallback = useCallback(() => {
    clearErrorsFileUpload('fileUploadInternalError' as 'fileUpload');
  }, [clearErrorsFileUpload]);

  const memoizedFn = useCallback(() => {}, []);

  const dateUnix = 3600;
  const timeSec = 3600;

  const date = dateTime(dateUnix);
  const duration = durationFromSeconds(timeSec);
  const afterSet = date.set({
    hour: duration.get('hour'),
  });
  const doubleDateTime = dateTime(afterSet);

  const unix = dateTime(dateUnix, { disableTimezone: true });
  const unixSet = dateTime(unix).set({
    date: unix.get('date'),
    month: unix.get('month'),
    year: unix.get('year'),
    hour: duration.get('hour'),
    minute: duration.get('minute'),
    seconds: duration.get('seconds'),
  });

  const utcOffset = unixSet.utc().tz().utcOffset() * 60;

  const dateMath = dateUnix - utcOffset + timeSec;

  const parsedDateUnix = dateTime(dateUnix, { disableTimezone: true }).startOf('day').unix();
  const dateMath2 = parsedDateUnix - utcOffset + timeSec;

  return (
    <Box>
      <Box>
        <Text>
          date - {date.format()} {dateTime(0, { disableTimezone: true }).format()}
        </Text>
        <br />
        <Text>
          duration - {duration.format()} {duration.format()}
        </Text>
        <br />
        <Text>afterSet - {afterSet.format()}</Text>
        <br />
        <Text>afterSetWithTz - {afterSet.tz().format()}</Text>
        <br />
        <Text>doubleDateTime - {doubleDateTime.format()}</Text>

        <br />
        <br />
        <Text>unixTest - {unix.format()}</Text>
        <br />
        <Text>
          afterSetWithTZ - {unixSet.format()} {unixSet.utc().format()} {unixSet.unix()} {unixSet.utc().tz().format()}{' '}
          {utcOffset}
        </Text>
        <br />

        <Text>
          math - {dateMath} {dateTime(dateMath).format()}
        </Text>
        <br />
        <br />
        <Text>
          math2 - {dateMath2} {dateTime(dateMath2).format()}
        </Text>
      </Box>
      <Container sx={{ my: 3 }}>
        <form onSubmit={handleSubmitFileUpload((data) => console.log(data))}>
          <button type="submit">Send form</button>
          <FileUpload
            {...registerFileUpload('fileUpload', {
              // required: 'File required.',
            })}
            onValidError={setFileUploadErrorCallback}
            onClearError={clearFileUploadErrorCallback}
            error={!!fileUploadFormErrors.fileUpload}
            errorMessage={fileUploadFormErrors?.fileUpload?.message}
            // maxFiles={1} // UNCOMMENT TO CHECK SINGLE_FILE USAGE
            // minFiles={5}
          />
        </form>
      </Container>
      {/* <Container sx={{ my: 3 }}>
        <Attachment {...registerAttachment('attachment')} />
      </Container> */}
      <Container sx={{ mt: '20px' }}>
        <Icon tooltip="dsadsadsadsa" type="apple" size={50} />

        <TextWithTooltip
          decorateChildren
          tooltipProps={{
            content: 'ssssssssssdasdsadsasssssss',
          }}
        >
          dsadsadsadsaaaaaaaaaaaaaaa
        </TextWithTooltip>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <br />

        <form
          style={{ display: 'flex', flexDirection: 'column' }}
          onSubmit={handleSubmitPersonSelect((data) => console.log(data))}
        >
          {' '}
          <DatePicker
            id="dsar"
            isStatic
            error={!!errors.datePicker4}
            errorMessage={errors?.datePicker4?.message}
            excludedDates={[1638230400, 1637712000, 1637366400]}
            onValidError={setDatePickerError4}
            onClearError={clearDatePickerError4}
            {...registerPersonSelect('datePicker4')}
          />
          <DatePicker
            id="dsayyy"
            error={!!errors.datePicker3}
            errorMessage={errors?.datePicker3?.message}
            excludedDates={[1638230400, 1637712000, 1637366400]}
            onValidError={setDatePickerError3}
            onClearError={clearDatePickerError3}
            {...registerPersonSelect('datePicker3')}
          />
          <DatePicker
            id="dsay"
            showStartTime
            showQuickSelect
            onValidError={setDatePickerError}
            onClearError={clearDatePickerError}
            error={!!errors.datePicker}
            errorMessage={errors?.datePicker?.message}
            excludedDates={[1638230400, 1637712000, 1637366400]}
            {...registerPersonSelect('datePicker')}
          />
          <TimePicker
            quickSelectOptions={[33702, 32400, 32355]}
            maxTime={32400}
            onValidError={setTimePickerPickerError}
            onClearError={clearTimePickerError}
            id="time1"
            {...registerPersonSelect('timePicker')}
          />
          {/* <TimePicker
            maxTime={32400}
            onValidError={setTimePickerPickerError}
            onClearError={clearTimePickerError}
            id="time2"
            size="sm"
            {...registerPersonSelect('timePicker2')}
          />
          <TimePicker
            maxTime={32400}
            onValidError={setTimePickerPickerError}
            onClearError={clearTimePickerError}
            size="xs"
            id="time3"
            {...registerPersonSelect('timePicker3')}
          /> */}
          <DurationPicker
            {...registerPersonSelect('durationPicker', {
              required: t({ id: 'global.forms.required' }),
            })}
            onValidError={setDurationPickerErrorCallback}
            onClearError={clearDurationPickerErrorCallback}
            error={!!errors.durationPicker}
            errorMessage={errors?.durationPicker?.message}
            seconds={false}
            minDuration={12}
            maxDuration={2222}
            quickSelectOptions={[300, 2344, 57457, 4411]}
            id="duration1"
          />
          <MaskedTextInput
            mask={/^(?:36[0-6]|3[0-5][0-9]|[12][0-9][0-9]|[1-9][0-9]|[0-9])$/}
            size="sm"
            id="masked"
            placeholder=""
            variant="rounded"
            apendWith={t({ id: 'team.time_off_limits.append_days', message: 'days' })}
            {...registerPersonSelect(`masked`)}
          />
          {/* <DurationPicker
            {...registerPersonSelect('durationPicker2')}
            onValidError={setDurationPickerErrorCallback}
            onClearError={clearDurationPickerErrorCallback}
            seconds={false}
            minDuration={12}
            maxDuration={2222}
            size="sm"
            id="duration2"
          />
          <DurationPicker
            {...registerPersonSelect('durationPicker3')}
            onValidError={setDurationPickerErrorCallback}
            onClearError={clearDurationPickerErrorCallback}
            seconds={false}
            minDuration={12}
            maxDuration={2222}
            size="xs"
            id="duration3"
          /> */}
          <DatePicker
            id="dssssa"
            excludedDates={[1644707400]}
            range
            showStartTime
            showEndTime
            showQuickSelect
            onValidError={setDatePickerError2}
            onClearError={clearDatePickerError2}
            {...registerPersonSelect('datePicker2', { required: true })}
            error={!!errors.datePicker2}
            errorMessage={errors?.datePicker2 && 'required'}
            // defaultValue={defaultValues.datePicker2}
          />
          <PersonSelect
            {...registerPersonSelect('personSelect', { required: true })}
            employeesIds={idArray10}
            id="Random"
            placeholder="placeholder"
          />
          <Container size="sm" sx={{ width: '300px' }}>
            <PersonSelect
              {...registerPersonSelect('personSelectErrorTest', { required: true })}
              employeesIds={idArray}
              id="personSelectErrorTest"
              placeholder="placeholder"
              error={!!errors.personSelectErrorTest}
              errorMessage={errors?.personSelectErrorTest?.type}
              size="sm"
            />
          </Container>
          <button type="button" onClick={() => resetForm(defaultValues)}>
            Reset
          </button>
          <button type="button" onClick={() => setValue('durationPicker', 444)}>
            {/* <button type="button" onClick={() => setValue('durationPicker', 333)}> */}
            set duration value
          </button>
          <input type="submit" />
        </form>
      </Container>
      <br />
      <button
        style={{
          background: 'lightblue',
          padding: '10px 20px',
          fontWeight: 'bold',
          color: 'rebeccapurple',
          cursor: 'zoom-in',
          margin: '20px',
        }}
        type="button"
        onClick={handlePrintFile}
      >
        Print file
      </button>
      <button
        style={{
          border: '2px solid orange',
          padding: '10px 20px',
          fontWeight: 'bold',
          color: 'orange',
          margin: '20px',
        }}
        type="button"
        onClick={handleDownloadFile}
      >
        Download file
      </button>
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message:
              'Some kind of text to describe this snackbar. Some kind of text to describe this snackbar. Some kind of text to describe this snackbar. Some kind of text to describe this snackbar. Some kind of text to describe this snackbar. Some kind of text to describe this snackbar. Some kind of text to describe this snackbar.',
          });
        }}
      >
        Add default snackbar
      </button>
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message: 'success snackbar with loader',
            variant: 'success',
            prependWith: <SmallLoader />,
          });
        }}
      >
        Add success snackbar with loader
      </button>
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message: 'danger snackbar with button',
            variant: 'danger',
            action: {
              title: 'Button',
              onClickCallback: memoizedFn,
            },
            isUnreplacable: true,
          });
        }}
      >
        Add unreplacable danger snackbar with button
      </button>
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message: 'warning snackbar with icon and button',
            variant: 'warning',
            prependWith: <Icon type="plus" />,
            action: {
              title: 'Button',
              onClickCallback: () => {},
            },
          });
        }}
      >
        Add warning snackbar with icon and button
      </button>
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message: 'warning STATIC snackbar with button',
            variant: 'warning',
            isStatic: true,
            action: {
              title: 'Button',
              onClickCallback: memoizedFn,
            },
          });
        }}
      >
        Add warning STATIC snackbar with button
      </button>
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message: 'default snackbar with bottom placement',
            placement: 'bottom',
          });
        }}
      >
        Add default snackbar with bottom placement
      </button>
      <br />
      <br />
      Login, remove accessToken from localStorage and refresh the page, it should refresh the token and land you on the
      last route you've visited
      <br />
      <button type="button" onClick={handleRemoveTokenClicked}>
        Remove Token
      </button>
      <br />
      <Alert variant="info">
        We could not process your order, your Bank has returned an error - insufficient funds.
      </Alert>
      <Alert variant="warning" action={{ title: 'Retry warning', onClick: () => console.log('warning') }}>
        We could not process your order, your Bank has returned an error - insufficient funds.
      </Alert>
      <Alert variant="danger" action={{ title: 'Retry danger', onClick: () => console.log('danger') }}>
        We could not process your order, your Bank has returned an error - insufficient funds.
      </Alert>
      <br />
      <br />
      Login, click &quot;Get resource&quot; and check in the console if request header includes Bearer token
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <button type="button" onClick={handleChangeLanguageClicked}>
        Change Language
      </button>
      <br />
      <br />
      Current Language: {language}
      <br />
      <br />
      <LoadingSpinnerCss />
      <Flex sx={{ alignItems: 'center' }}>
        <PaymentTicker daysTillEnd={13} />
        <PaymentTicker daysTillEnd={6} />
        <PaymentTicker daysTillEnd={3} />
        <Flex sx={{ height: '100px', width: '100px' }}>
          <PieChartSvg fill="blue" value={6} valueMax={14} reverse />
        </Flex>
        <Avatar size={124} circle image="https://avatarfiles.alphacoders.com/120/thumb-120208.jpg" />
        <Avatar image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStRnGbj7SsPzB3lMbZ72mK1IYgTVM_oqB9oA&usqp=CAU" />
        <Avatar />
        <Avatar size={124} />
        <AvatarWithDot
          showDot
          dotProps={{ color: 'green', placement: 'BOTTOM_RIGHT' }}
          name={{ firstName: 'J', surname: 'P' }}
          size={24}
        />
      </Flex>
      <br />
      <Flex>
        <Notification
          id="dss"
          variant="notificationCenter"
          redirectTo="https://localhost:3000/app/?redirect=1"
          title="Aktualizacja aplikacji"
          content="Jakaś tam aktualizacja!"
        />

        <Notification
          id="dssdsadsa"
          title="Aktualizacja aplikacji"
          createdAt={1634127606}
          content="Jakaś tam aktualizacja!"
          type="info"
        />
      </Flex>
      <button
        type="button"
        onClick={() => {
          addNotification({
            title: 'Jakaś tam aktualizacja!',
            type: 'love',
            isStatic: true,
          });
        }}
      >
        Love notification
      </button>
      <button
        type="button"
        onClick={() => {
          addNotification({
            title: 'Twój wniosek urlopowy został zaakceptowany!',
            content:
              'Twój wniosek o Urlop wypoczynkowy (Uw) na okres od 20.12.2019 do 31.12.2019 został zaakceptowany przez Jacek Pytlik.',
            type: 'success',
            variant: 'notificationCenter',
          });
        }}
      >
        Success notification
      </button>
      <button
        type="button"
        onClick={() => {
          addNotification({
            title: 'Twój wniosek urlopowy został odrzucony.',
            content:
              'Twój wniosek o Urlop wypoczynkowy (Uw) na okres od 20.12.2019 do 31.12.2019 został odrzucony przez Jacek Pytlik.',
            type: 'danger',
          });
        }}
      >
        Danger notification
      </button>
      <button
        type="button"
        onClick={() => {
          addNotification({
            title: 'Nie dodałeś jeszcze grafiku na ten miesiąc.',
            content: 'Jakiś strasznie ale to strasznie nawet przestrasznie długi nmessage.',
            createdAt: 1634127606,
            type: 'warning',
            isStatic: true,
          });
        }}
      >
        Warning notification
      </button>
      <button
        type="button"
        onClick={() => {
          addNotification({
            title: 'Opublikowano nowy grafik pracy!',
            content: 'W miesiącu Grudzień 2020.',
            type: 'info',
            insert: 'bottom',
            createdAt: 1634127606,
          });
        }}
      >
        Info notification
      </button>
      <button
        type="button"
        onClick={() => {
          addNotification({
            title: 'Opublikowano nowy grafik pracy!',
            content: 'W miesiącu Grudzień 2020.',
            type: 'default',
            isStatic: true,
          });
        }}
      >
        Default notification
      </button>
      <button
        type="button"
        onClick={() => {
          addNotification({
            title: 'Nie dodałeś jeszcze grafiku na ten miesiąc.',
            content: 'Grudzień 2020',
            type: 'warning',
            actions: [
              {
                title: 'Odrzuć',
                onClick: () => {
                  history.push(`${TO.EDIT_MODAL__STEP[language]}/2`);
                },
              },
              {
                title: 'Dodaj',
                onClick: () => {
                  history.push(`${TO.EDIT_MODAL__STEP[language]}/2`);
                },
              },
            ],
          });
        }}
      >
        Notification with additional actions
      </button>
      <button
        type="button"
        onClick={() => {
          addNotification({
            title: 'Twój wniosek urlopowy został odrzucony.',
            content:
              'Twój wniosek o Urlop wypoczynkowy (Uw) na okres od 20.12.2019 do 31.12.2019 został odrzucony przez Jacek Pytlik.',
            type: 'danger',
            actions: [
              {
                title: 'Usuń',
                onClick: () => {
                  history.push(`${TO.EDIT_MODAL__STEP[language]}/2`);
                },
              },
            ],
          });
        }}
      >
        Notification with additional action
      </button>
      <br />
      <br />
      <Box sx={{ width: '328px', bg: 'black' }}>
        <SummaryButton shape="rounded">KONIEC PRACY</SummaryButton>
        <CameraViewButton icon="info" active />
      </Box>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Link to={`${TO.EDIT_MODAL__STEP[language]}/1`}>Test multi step modal</Link>
      <br />
      <br />
      <br />
      <br />
      <ModalRoute path={PATH.EDIT_MODAL__STEP[language]}>
        <EditModal />
      </ModalRoute>
      <br />
      <br />
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message: 'Downloading',
            prependWith: <SmallLoader />,
            isStatic: true,
            action: {
              title: 'Cancel',

              onClickCallback: () => {},
            },
          });
        }}
      >
        Add snackbar with button
      </button>
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message: 'I hereby inform you of this at most important message!',
            // prependWith: <SmallLoader />,
            prependWith: <Icon type="plus" />,
          });
        }}
      >
        Add snackbar with spinner
      </button>
      <button
        type="button"
        onClick={() => {
          addSnackbar({
            message: 'Snackbar 2 with a very long text, in fact it is waaaay to long... or is it?',
            prependWith: <Icon type="approve" />,
          });
        }}
      >
        Add snackbar with icon
      </button>
      <br />
      <br />
      <button
        type="button"
        onClick={() => {
          const dispatcher = window || document;
          const event = new CustomEvent('setRecoilState', {
            detail: {
              payload: USER_SESSION_DEFAULT_VALUE,
              recoilKey: 'userSession',
            },
          });
          dispatcher.dispatchEvent(event);
        }}
      >
        Dispatch custom setRecoilState event for userSession
      </button>
      <br />
      <br />
      <button
        type="button"
        onClick={() => {
          const dispatcher = window || document;
          const event = new CustomEvent('setRecoilState', {
            detail: {
              payload: 'en',
              recoilKey: 'language',
            },
          });
          dispatcher.dispatchEvent(event);
        }}
      >
        Dispatch custom setRecoilState event for language
      </button>
      <br />
      <br />
      <button
        type="button"
        onClick={() => {
          broadcastChannel?.postMessage(refreshUserSession(USER_SESSION_DEFAULT_VALUE));
        }}
      >
        dispatch channel refreshUserSession
      </button>
      <br />
      <br />
      <button
        type="button"
        onClick={() => {
          handleChangeLanguageClicked();
          broadcastChannel?.postMessage(changeLanguage(language === 'pl' ? 'en' : 'pl'));
        }}
      >
        dispatch channel changeLanguage
      </button>
      <br />
      <br />
      <button
        type="button"
        onClick={() => {
          broadcastChannel?.postMessage(addRefreshPageNotification());
        }}
      >
        dispatch channel addRefreshPageNotification
      </button>
      <br />
      <br />
      {/* <button
        type="button"
        onClick={() => {
         setRecoilState()
        }}
      >
        set recoil state
      </button>
      <br />
      <br /> */}
      <Trans id="home">home</Trans>
      <br />
      <br />
      <Box sx={{ height: '3000px' }} />
    </Box>
  );
};
