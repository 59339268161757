import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { useHistory, useParams } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import {
  acceptRequestManagementAction,
  fetchRequestDetailsAction,
  requestDownloadAction,
  requestPrintAction,
} from 'api/actions/requests/requestsActions';
import { RequestFormType, RequestState } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/Modal';
import { RequestStatusBadge } from 'components/recipes/RequestStatusBadge';
import { Button } from 'components/ui/Buttons';
import { ElementGroup } from 'components/ui/ElementGroup';
import { PATH, TO } from 'constants/routes';
import { useCleanRouteMatch } from 'hooks/useCleanRouteMatch/useCleanRouteMatch';
import { useModal } from 'hooks/useModal/useModal';
import { ModalRoute } from 'routing/ModalRoute';
import { addSnackbar } from 'SnackbarHub/actions';
import { languageSelector } from 'state/recoilState';
import { requestDetailsAtom } from 'state/requests';
import { RejectRequestModal } from '../RejectRequestModal';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

import { BusinessCustomTimeOffDetails } from './elements/BusinessCustomTimeOffDetails';
import { RequestDetailsMain } from './elements/RequestDetailsMain';
import { TimeEventDetails } from './elements/TimeEventDetails';

export const RequestDetailsModal = (): React.ReactElement => {
  useLingui();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const match = useCleanRouteMatch();
  const { handleClose } = useModal();
  const { query, loading } = useParameterizedQuery(fetchRequestDetailsAction);
  const { mutate: approveRequest, loading: approveLoading } = useMutation(acceptRequestManagementAction);
  const { mutate: mutatePrintRequests } = useMutation(requestPrintAction);
  const { mutate: mutateDownloadRequests } = useMutation(requestDownloadAction);
  const [requestDetails, setRequestDetails] = useRecoilState(requestDetailsAtom);
  const language = useRecoilValue(languageSelector);

  const getRequestDetails = useCallback(async () => {
    const { error, payload } = await query(id);

    if (error) {
      handleClose();
    }

    if (!error && payload) {
      setRequestDetails(payload);
    }
  }, [handleClose, id, query, setRequestDetails]);

  const handleApprove = useCallback(async () => {
    const { error } = await approveRequest([id]);
    if (!error) {
      addSnackbar({
        message: t({
          id: 'request.snackbar_approved',
          message: 'Request approved!',
        }),
        variant: 'success',
      });
      getRequestDetails();
    }
  }, [approveRequest, getRequestDetails, id]);

  const handleReject = useCallback(() => {
    push(`${match}${TO.REJECT_REQUESTS_MODAL[language]}`, { id });
  }, [id, language, match, push]);

  const onSubmitRejectAction = useCallback(async () => {
    await getRequestDetails();
  }, [getRequestDetails]);

  const handlePrintOnClick = useCallback(async () => {
    const { error } = await mutatePrintRequests([id]);

    if (error) {
      addSnackbar({
        message: t({ id: 'error.something_went_wrong' }),
      });
    }
  }, [id, mutatePrintRequests]);

  const handleDownloadOnClick = useCallback(async () => {
    const { error } = await mutateDownloadRequests([id]);

    if (error) {
      addSnackbar({
        message: t({ id: 'error.something_went_wrong' }),
      });
    }
  }, [id, mutateDownloadRequests]);

  useEffect(() => {
    getRequestDetails();
  }, [getRequestDetails]);

  const renderTypeDetails = useMemo(() => {
    if (requestDetails) {
      const { type } = requestDetails;

      switch (type) {
        case RequestFormType.TimeOff:
        case RequestFormType.Custom:
        case RequestFormType.BusinessTrip:
          return <BusinessCustomTimeOffDetails />;
        case RequestFormType.TimeTracking:
          return <TimeEventDetails />;
        default:
          <></>;
      }
    }

    return <></>;
  }, [requestDetails]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Flex sx={{ alignItems: 'center' }}>
            <Text>
              <Trans id="requests.request">Request no.</Trans> {requestDetails?.number || '-'}
            </Text>
            {requestDetails && (
              <RequestStatusBadge sx={{ ml: '0.625rem', height: '18px' }} requestState={requestDetails.state} />
            )}
          </Flex>
        </Modal.Title>
        <ElementGroup wrapperSx={{ mr: 2 }} marginValue={2}>
          <Button
            variant="minimal"
            shape="circle"
            sx={{
              mr: '-0.75rem',
            }}
            onClick={handleDownloadOnClick}
          >
            <Icon size={24} type="download" />
          </Button>
          <Button
            variant="minimal"
            shape="circle"
            sx={{
              mr: '-0.75rem',
            }}
            onClick={handlePrintOnClick}
          >
            <Icon size={24} type="print" />
          </Button>
          <Button
            variant="minimal"
            shape="circle"
            sx={{
              mr: '-0.75rem',
            }}
            onClick={() => {
              push(`${TO.REQUESTS[language]}${TO.DELETE_REQUESTS_MODAL[language]}`, { ids: [id] });
            }}
          >
            <Icon size={24} type="delete" />
          </Button>
        </ElementGroup>
      </Modal.Header>
      <Modal.Body>
        {!loading ? (
          <RequestDetailsMain>{renderTypeDetails}</RequestDetailsMain>
        ) : (
          <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
            <LoadingSpinnerCss size={4} />
          </Flex>
        )}
      </Modal.Body>
      {requestDetails?.state === RequestState.Pending && (
        <Modal.Footer>
          <Button
            isLoading={approveLoading}
            variant="danger"
            shape="rounded"
            prependWith={<Icon size={18} type="deny" />}
            onClick={handleReject}
          >
            <Trans id="requests.button.reject">Reject</Trans>
          </Button>
          <Button
            isLoading={approveLoading}
            variant="success"
            shape="rounded"
            prependWith={<Icon size={18} type="approve" />}
            onClick={handleApprove}
          >
            <Trans id="requests.button.approve">Approve</Trans>
          </Button>
        </Modal.Footer>
      )}
      <ModalRoute size="sm" path={PATH.REJECT_REQUESTS_MODAL[language]}>
        <RejectRequestModal onSubmitCallback={onSubmitRejectAction} />
      </ModalRoute>
    </>
  );
};
