import type { ResponseInterceptor } from 'react-fetching-library';

export const responseFileUrlInterceptor: () => ResponseInterceptor = () => () => async (action, response) => {
  const xFileHeader = response.headers?.get('X-File');

  if (!xFileHeader) {
    return response;
  }

  const { payload } = response;
  const fileUrl: string | undefined = payload.url;

  if (!fileUrl) {
    return response;
  }

  const { headers } = action;
  const isDownload = headers ? !!headers['X-Download'] : false;

  if (isDownload) {
    const link = document.createElement('a');
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response;
  }

  window.open(fileUrl);

  return response;
};
