/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { useInitialFaceScan } from 'Kiosk/hooks/useInitialFaceScan';
import {
  drawBoundingBox,
  drawBoxForCheckIfFaceIsInPlace,
  drawPointsForAxisXY,
  drawPointsToScan,
  drawMesh,
  drawScannedPoints,
} from 'Kiosk/helpers';
import { windowSizeAtom } from 'state/recoilState';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { AnnotatedPrediction } from 'Kiosk/models/face-landmarks-detection/mediapipe-facemesh';

type Props = Required<Pick<ReturnType<typeof useInitialFaceScan>, 'scannedPoints'>> & {
  prediction: AnnotatedPrediction[] | undefined;
};

export const DebugInitialFaceScan = ({ prediction, scannedPoints }: Props): React.ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { isLandscape } = useRecoilValue(windowSizeAtom);
  const { source } = useRecoilValue(cameraStateAtom);

  if (canvasRef.current && source) {
    canvasRef.current.width = source.width;
    canvasRef.current.height = source.height;
  }

  useEffect(() => {
    let requestId: number;

    const ctx = canvasRef?.current?.getContext('2d');

    if (ctx && prediction && source) {
      const renderDraw = () => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawBoxForCheckIfFaceIsInPlace(ctx, source.video, true);
        drawBoundingBox(prediction, ctx);
        drawPointsForAxisXY(prediction, ctx);
        drawPointsToScan(ctx, source.video, true);
        drawScannedPoints(scannedPoints, ctx, source.video);
        requestId = requestAnimationFrame(renderDraw);
      };

      renderDraw();
    }

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [prediction, scannedPoints, source]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: isLandscape ? '100%' : 'auto',
        width: isLandscape ? 'auto' : '100%',
        zIndex: 4,
      }}
    />
  );
};
