import { Flex } from 'theme-ui';
import React, { Suspense, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { Checkbox } from 'components/ui/Checkbox';
import { rowSelectSelectorFamily } from 'state/list';
import { Radio } from 'components/ui/Radio';

import { GridCellProps } from './types';
import { Cell } from './Cell';

const LazyGridCellContent = React.lazy(() =>
  import('./GridCellContent').then(({ GridCellContent }) => ({
    default: GridCellContent,
  })),
);

export const GridCell = ({
  select,
  id,
  listName,
  data,
  columnData,
  variant,
  uncheckable,
  ...props
}: GridCellProps): React.ReactElement => {
  const [selected, setSelected] = useRecoilState(rowSelectSelectorFamily({ listName, itemId: id }));

  const cellRef = useRef<HTMLDivElement | null>(null);

  const preventParentOnClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.checked);
  };

  return (
    <Flex
      ref={cellRef}
      data-uncheckable={uncheckable}
      data-selected={uncheckable ? false : selected}
      variant={`stickyList.gridCell.${variant}`}
      {...props}
    >
      {select === 'checkbox' && !uncheckable && (
        <Cell width="30px" onClick={preventParentOnClick} sx={{ bg: 'transaprent' }}>
          <Checkbox onChange={handleCheckboxChange} checked={selected} name="gridCheckbox" size="sm" />
        </Cell>
      )}
      {select === 'radio' && !uncheckable && (
        <Cell width="30px" onClick={preventParentOnClick} sx={{ bg: 'transaprent' }}>
          <Radio onChange={handleCheckboxChange} checked={selected} name="gridCheckbox" size="sm" />
        </Cell>
      )}
      <Suspense fallback={<></>}>
        <LazyGridCellContent cellRef={cellRef} columnData={columnData} data={data} id={id} listName={listName} />
      </Suspense>
    </Flex>
  );
};

export const MemorizedGridCell = React.memo(GridCell);
