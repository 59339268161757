import React from 'react';
import { Flex, Text, FlexProps, Heading, HeadingProps, TextProps, ThemeUIStyleObject } from 'theme-ui';

type FormCardSizes = 'auto' | 'default' | 'md';

type Props = FlexProps & {
  size?: FormCardSizes;
};
type LeadProps = TextProps;
type TitleProps = HeadingProps;

type FormCardComponent = React.FC<Props> & {
  Header: typeof Header;
  Footer: typeof Footer;
  Title: typeof Title;
  Lead: typeof Lead;
  ProgressBar: typeof ProgressBar;
};

const defaultProps = {
  size: 'auto' as FormCardSizes,
};

const formCardSx: ThemeUIStyleObject = {
  position: 'relative',
  overflow: 'hidden',
  textAlign: 'center',
  px: [null, null, '2.5rem'],
  pt: [null, null, 6],
  pb: [null, null, 5],
  flexDirection: 'column',
  alignItems: 'stretch',
  boxShadow: [null, null, 'dropShadow.levelTwo'],
  borderRadius: [null, null, 'default'],
  bg: [null, null, 'white'],
};

export const FormCard: FormCardComponent = ({ children, sx, size = 'auto', ...props }: Props): React.ReactElement => (
  <Flex
    {...props}
    sx={{
      ...formCardSx,
      ...(size !== 'auto' && {
        maxWidth: (size === 'default' && '480px') || (size === 'md' && '748px'),
        width: '100%',
      }),
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
);

FormCard.defaultProps = defaultProps;

const Footer = ({ children, sx, ...props }: FlexProps): React.ReactElement => (
  <Flex
    as="footer"
    {...props}
    sx={{
      pt: 6,
      gap: 2,
      justifyContent: [null, null, 'space-between'],
      flexDirection: ['column-reverse', null, 'row'],
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
);

const Header = ({ children, sx, ...props }: FlexProps): React.ReactElement => (
  <Flex {...props} as="header" sx={{ mb: 5, flex: '1 0', flexDirection: 'column', ...(sx && sx) }}>
    {children}
  </Flex>
);

const Title = ({ children, sx, ...props }: TitleProps): React.ReactElement => (
  <Heading {...props} as="h1" variant="heading1" sx={{ mb: 2, ...(sx && sx) }}>
    {children}
  </Heading>
);

const Lead = ({ children, sx, ...props }: LeadProps): React.ReactElement => (
  <Text {...props} as="p" variant="pLead" sx={{ mb: 0, ...(sx && sx) }}>
    {children}
  </Text>
);

type ProgressBarProps = Omit<FlexProps, 'children'> & {
  steps: number;
  completed: number;
};

const ProgressBar = ({ completed, steps, sx, ...props }: ProgressBarProps): React.ReactElement => {
  const progressWidth = `${(completed * 100) / steps}%`;

  return (
    <Flex
      {...props}
      sx={{
        position: ['fixed', null, 'absolute'],
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        overflow: 'hidden',
        ...(sx && sx),
      }}
    >
      <Flex
        style={{
          width: progressWidth,
        }}
        sx={(theme) => ({
          transition: 'width 0.25s ease-in-out',
          height: '100%',
          width: 0,
          bg: 'skyBlues5',
          boxShadow: `2px 0 4px ${theme?.colors?.skyBlues8}`,
        })}
      />
    </Flex>
  );
};

FormCard.ProgressBar = ProgressBar;
FormCard.Footer = Footer;
FormCard.Header = Header;
FormCard.Title = Title;
FormCard.Lead = Lead;
