// See https://imask.js.org/guide.html
// See https://github.com/uNmAnNeR/imaskjs/blob/master/packages/react-imask/src/mixin.js

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IMaskMixin, IMaskInputProps, AnyMask } from 'react-imask';
import { Flex } from 'theme-ui';

import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';
import { createEvent } from 'utils/createEvent';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { useOnUpdateFieldView } from 'hooks/useOnUpdateFieldView/useOnUpdateFieldView';

import { TextInput, TextInputProps } from './TextInput';

type Props = Omit<IMaskInputProps, 'inputRef' | 'lazy' | 'value' | 'onChange'> &
  TextInputProps & {
    showMask?: boolean;
    returnMaskedValue?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };

export type MaskedTextInputProps = Props;

const defaultProps: Partial<Props> = {
  showMask: false,
  returnMaskedValue: false,
  onChange: undefined,
};

const Masked = IMaskMixin(({ inputRef, ...props }: IMaskInputProps & TextInputProps) => (
  <TextInput {...props} ref={inputRef} />
));

// TODO: there is a new hook available in react-imask, check if can be useful
export const MaskedTextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ showMask = false, returnMaskedValue = false, defaultValue, onChange, onBlur, id, ...props }: Props, ref) => {
    const hiddenRef = useRef<HTMLInputElement | null>(null);
    const maskedRef = useRef<HTMLInputElement | null>(null);
    const [hasValue, setHasValue] = useState<string>('');
    const [hasUnmaskedValue, setHasUnmaskedValue] = useState<string>('');

    const dispatchBlurEvent = () => {
      const blurEvent = createEvent('focusout');
      if (hiddenRef.current) hiddenRef.current.dispatchEvent(blurEvent);
    };

    const handleHiddenInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
    };

    const handleBlur = () => {
      dispatchBlurEvent();
    };

    const onClearCallback = () => {
      setNativeValue(hiddenRef, '');
    };

    const handleHiddenInputFocus = useCallback(() => {
      maskedRef.current?.focus();
    }, []);

    const handleHiddenInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setHasValue(newValue);
      if (onChange) {
        onChange(e);
      }
    };

    const handleOnAccept = (value: string, mask: AnyMask) => {
      const returnValue = returnMaskedValue ? mask.value : mask.unmaskedValue;
      if (hiddenRef.current?.value !== returnValue) {
        setNativeValue(hiddenRef, returnValue);
      }
      setHasUnmaskedValue(mask.unmaskedValue);

      if (props.onAccept) {
        props.onAccept(value, mask);
      }
    };

    const initializeMaskedTextInput = useCallback((initialValue: string) => {
      if (initialValue.length) {
        setHasValue(initialValue);
      }
    }, []);

    useEffect(() => {
      const hiddenInputValue = hiddenRef?.current?.value;
      if (hiddenInputValue) {
        initializeMaskedTextInput(hiddenInputValue);
      }
    }, [initializeMaskedTextInput]);

    const onOwnValueChange = useCallback(
      (newValue: InputOwnValue) => {
        if (`${newValue}` === hasValue) {
          // to support reset when used in DatePicker
          setHasValue(`${newValue} `);
        }
        setHasValue(`${newValue}`);
      },
      [hasValue],
    );

    useOnOwnValueChange(hiddenRef, onOwnValueChange);

    useOnUpdateFieldView(hiddenRef, onOwnValueChange);

    const getMaskedInputColor = useCallback(() => {
      if (!hasUnmaskedValue) {
        if (props.error) return 'reds3';
        return 'input.text.placeholder';
      }

      if (props.error) return 'inherit';

      return 'input.text.default';
    }, [hasUnmaskedValue, props.error]);

    const mockPlaceholderSx = useMemo(
      () => ({
        ...(!props.disabled &&
          props.mask && {
            '& [data-default-input="true"]': {
              color: getMaskedInputColor(),
            },
          }),
      }),
      [getMaskedInputColor, props.disabled, props.mask],
    );

    return (
      // <Flex sx={{ flex: '1 0 auto' }}>
      <Flex>
        <input
          defaultValue={defaultValue}
          name={id}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
          ref={mergeRefs([ref, hiddenRef])}
          onChange={handleHiddenInputChange}
          onFocus={handleHiddenInputFocus}
          onBlur={handleHiddenInputBlur}
          readOnly
        />
        <Masked
          {...props}
          id={id}
          value={hasValue}
          {...(props.mask && { hideClearIcon: !hasUnmaskedValue })}
          sxOverwrite={mockPlaceholderSx}
          lazy={!showMask}
          onAccept={handleOnAccept}
          onBlur={handleBlur}
          onClearCallback={onClearCallback}
          inputRef={(e: HTMLInputElement | null) => {
            maskedRef.current = e;
          }}
        />
      </Flex>
    );
  },
);

MaskedTextInput.defaultProps = defaultProps;
