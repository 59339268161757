import { EstimateFacesConfig } from '@tensorflow-models/face-landmarks-detection/dist/mediapipe-facemesh';
import { atom } from 'recoil';

import { AnnotatedPrediction } from 'Kiosk/models/face-landmarks-detection/mediapipe-facemesh';

export type FaceDetectionModel = {
  isModelLoaded: boolean;
  getPrediction?: (source: EstimateFacesConfig['input']) => Promise<AnnotatedPrediction[]>;
};

export const faceDetectionModelAtom = atom<FaceDetectionModel>({
  key: 'kiosk__faceDetectionModel',
  default: {
    isModelLoaded: false,
  },
});
