import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Flex } from 'theme-ui';

import { Button } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { PasswordInput } from 'components/ui/PasswordInput';
import { mergeRefs } from 'utils/mergeRefs';
import { FormCard } from 'layouts/Authentication';
import { PostEmployeeAccountRegisterPayload } from 'api/actions/user/userActions.types';
import { Icon } from 'components/Icon/Icon';
import { Checkbox } from 'components/ui/Checkbox';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { EXTERNAL_LINKS } from 'constants/externalLinks';

const EMPLOYEE_REGISTER_FORM_ID = 'employee-register-form-id';

type Props = {
  onSubmit: (props: Omit<PostEmployeeAccountRegisterPayload, 'token' | 'userId'>) => Promise<boolean>;
};

export const EmployeeForm = ({ onSubmit }: Props): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange' });

  const handleSubmitCallback = useCallback(
    async (props) => {
      setIsLoading(true);
      const response = await onSubmit(props);

      if (!response) {
        setIsLoading(false);
      }
    },
    [onSubmit],
  );

  const passwordRegister = useMemo(
    () => register('password', { required: i18n._('global.forms.required') }),
    [register],
  );
  const confirmPasswordRegister = useMemo(
    () =>
      register('confirmPassword', {
        required: i18n._('global.forms.required'),
        validate: (value) =>
          value === passwordRef.current?.value ||
          i18n._(
            t({
              id: 'settings.user.change_password.not_match',
            }),
          ),
      }),
    [register],
  );

  const setPasswordErrorCallback = useCallback(() => {
    setError('passwordInternalError' as 'password', {
      message: 'only visible in code',
    });
  }, [setError]);

  const clearPasswordErrorCallback = useCallback(() => {
    clearErrors('passwordInternalError' as 'password');
  }, [clearErrors]);

  return (
    <>
      <form id={EMPLOYEE_REGISTER_FORM_ID} onSubmit={handleSubmit(handleSubmitCallback)} noValidate>
        <Flex sx={{ gap: 4, flexDirection: 'column' }}>
          <PasswordInput
            id="password"
            autoComplete="newPassword"
            {...passwordRegister}
            ref={mergeRefs([passwordRegister.ref, passwordRef])}
            label={i18n._(
              t({
                id: 'settings.user.change_password.new_password',
                message: 'New password',
              }),
            )}
            placeholder="••••••"
            icon="lock"
            error={!!errors.password}
            errorMessage={errors?.password?.message}
            onValidError={setPasswordErrorCallback}
            onClearError={clearPasswordErrorCallback}
          />

          <TextInput
            id="confirmPassword"
            type="password"
            {...confirmPasswordRegister}
            label={i18n._(
              t({
                id: 'settings.user.change_password.confirm_password',
                message: 'Confirm password',
              }),
            )}
            placeholder="••••••"
            icon="lock"
            error={!!errors.confirmPassword}
            errorMessage={errors?.confirmPassword?.message}
          />
        </Flex>

        <FormCard.Footer sx={{ flexDirection: 'column', gap: 1 }}>
          <Button
            isLoading={isLoading}
            variant="primary"
            size="lg"
            fullWidth
            type="submit"
            shape="rounded"
            form={EMPLOYEE_REGISTER_FORM_ID}
            apendWith={<Icon type="arrowRight" />}
          >
            <Trans id="global.forms.buttons.next" />
          </Button>

          <Checkbox
            sx={{ mt: 4 }}
            {...register('newsletterSubscription')}
            label={
              <Trans id="sign_up.join_newsletter">
                I want to receive newsletters and special offer notices.{' '}
                <LinkAnchor sx={{ textDecoration: 'underline' }} href={EXTERNAL_LINKS.NEWSLETTER_TERMS} target="_blank">
                  Terms of newsletter
                </LinkAnchor>
                .
              </Trans>
            }
          />
        </FormCard.Footer>
      </form>
    </>
  );
};
