export enum UserSelectableColor {
  grays2 = 0,
  grays3 = 6,
  grays4 = 22,
  skyBlues2 = 16,
  skyBlues3 = 17,
  skyBlues4 = 23,
  reds2 = 1,
  reds4 = 24,
  reds6 = 5,
  softBlues3 = 25,
  softBlues4 = 26,
  softBlues5 = 18,
  greens2 = 10,
  greens3 = 11,
  greens5 = 9,
  yellows2 = 27,
  yellows4 = 12,
  yellows5 = 13,
  purples2 = 28,
  purples3 = 29,
  purples4 = 19,
  pinks2 = 30,
  pinks3 = 20,
  pinks4 = 21,
  oranges2 = 14,
  oranges3 = 15,
  oranges5 = 31,
  browns1 = 2,
  browns2 = 3,
  browns3 = 4,
  limes2 = 32,
  limes3 = 8,
  limes5 = 7,
}
