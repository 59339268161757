import React, { useMemo, useRef } from 'react';
import { Control, useFieldArray, UseFormRegister, useFormState, useWatch } from 'react-hook-form';
import { Flex, FlexProps, Heading } from 'theme-ui';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import _ from 'lodash';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ElementGroup } from 'components/ui/ElementGroup';
import { EmployeeEmploymentDetailsFieldNames, EmployeeEmploymentDetailsFormState } from 'state/team';
import { ButtonPair } from 'components/recipes/ButtonPair';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { YearSelect } from 'components/recipes/YearSelect';
import { organizationDetailsSelector, organizationSessionPropertySelectorFamily } from 'state/organizationSession';
import { FetchOrganizationSessionResponse } from 'api/actions/organizationSession/organizationSessionActions.types';
import { dateTime } from 'utils/dateTime';
import { delay } from 'utils/delay';
import { scrollIfOutOfParentRect } from 'utils/scrollIfOutOfParentRect';

import { NestedTimeOffLimitsFieldArray } from './NestedTimeOffLimitsFieldArray';
import { CURRENT_YEAR, DEFAULT_LAST_YEAR } from './constants';

type Props = {
  control: Control<EmployeeEmploymentDetailsFormState>;
  register: UseFormRegister<EmployeeEmploymentDetailsFormState>;
  scrollParentRef: React.MutableRefObject<HTMLDivElement | null>;
} & FlexProps;

export const TimeOffLimits = ({ control, register, sx, scrollParentRef, ...flexProps }: Props): React.ReactElement => {
  useLingui();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { fields, remove, append } = useFieldArray<
    EmployeeEmploymentDetailsFormState,
    EmployeeEmploymentDetailsFieldNames.TimeOffLimits
  >({
    control,
    name: EmployeeEmploymentDetailsFieldNames.TimeOffLimits,
  });
  const { errors } = useFormState({ control });
  const watchTimeOffLimits = useWatch({ control, name: EmployeeEmploymentDetailsFieldNames.TimeOffLimits });

  const timeOffDefaultLimits = useRecoilValue(
    organizationSessionPropertySelectorFamily('timeOffDefaultLimits') as RecoilValueReadOnly<
      FetchOrganizationSessionResponse['timeOffDefaultLimits'] | null
    >,
  );
  const organizationDetails = useRecoilValue(organizationDetailsSelector);

  const createDateUnix = useMemo(() => organizationDetails?.createDateUnix || 0, [organizationDetails]);

  const controlledFields = fields
    .map((field) => {
      const currentLimit = watchTimeOffLimits?.find(({ id }: { id: string }) => id === field.id);
      return {
        ...field,
        ...(currentLimit && currentLimit),
      };
    })
    .map(({ fromYear, ...rest }) => ({ fromYear: +fromYear, ...rest }));

  const defaultTimeOffLimitsGroup = useMemo(() => {
    const alreadySelectedYears = controlledFields.map(({ fromYear }) => +fromYear);
    const yearRange = _.range(dateTime(+createDateUnix).year(), DEFAULT_LAST_YEAR + 1);
    const unselectedYears = yearRange.filter((y) => !alreadySelectedYears.includes(y));
    const firstUnselectedYear = unselectedYears[0];

    const nextYear = firstUnselectedYear || CURRENT_YEAR;

    return {
      fromYear: nextYear,
      limits: timeOffDefaultLimits?.map((limit) => ({ days: undefined, ...limit })) || [],
      id: _.uniqueId(),
    };
  }, [timeOffDefaultLimits, controlledFields, createDateUnix]);

  const defaultTimeOffLimitsGroupRef = useRef(defaultTimeOffLimitsGroup);

  defaultTimeOffLimitsGroupRef.current = defaultTimeOffLimitsGroup;

  const handleAppend = async () => {
    append(defaultTimeOffLimitsGroupRef.current, { shouldFocus: false });
    await delay(0);
    const scrollParent = scrollParentRef.current;
    const fieldWrapper = wrapperRef.current;
    scrollIfOutOfParentRect(scrollParent, fieldWrapper);
  };

  return (
    <Flex sx={{ flexDirection: 'column', ...(sx && sx) }} {...flexProps} ref={wrapperRef}>
      {!fields?.length ? (
        <ShowFieldsButton
          onClick={handleAppend}
          label={t({ id: 'team.time_off_limits.bulk.add', message: 'Add time offs limits' })}
        />
      ) : (
        <>
          <Heading variant="heading4" mb={1}>
            <Trans id="team.time_off_limits.bulk">Time offs limits</Trans>
          </Heading>
          <ElementGroup
            marginValue={4}
            direction="column"
            showAsList
            wrapperSx={{ width: '100%', flexDirection: 'column' }}
          >
            {controlledFields.map((field, index, allFields) => {
              const allSelectedYears = allFields.map(({ fromYear }) => +fromYear);
              const lastYear = field.fromYear > DEFAULT_LAST_YEAR ? field.fromYear : DEFAULT_LAST_YEAR;
              const yearSelectRange: [number, number] = [dateTime(+createDateUnix).year(), lastYear];
              const yearsToExclude = allSelectedYears.filter((year) => year !== +field.fromYear);
              return (
                <Flex key={`time-off-${field.id}`} sx={{ flexDirection: 'column' }}>
                  <Flex mb={2}>
                    <YearSelect
                      range={yearSelectRange}
                      excludedYears={yearsToExclude}
                      label={t({ id: 'team.time_off_limits.from_year', message: 'From' })}
                      id={`timeOffLimits.${index}.fromYear`}
                      defaultValue={`${field.fromYear}`}
                      {...register(`timeOffLimits.${index}.fromYear` as const)}
                      error={!!errors?.timeOffLimits?.[index]?.fromYear}
                      errorMessage={errors?.timeOffLimits?.[index]?.fromYear?.message}
                    />
                    <ButtonPair
                      sx={{ ml: 3, mt: 3, alignItems: 'center' }}
                      leftButtonProps={
                        index === allFields.length - 1 && allFields.length < 5
                          ? {
                              onClick: handleAppend,
                            }
                          : undefined
                      }
                      rightButtonProps={{
                        onClick: () => remove(index),
                      }}
                    />
                  </Flex>
                  <NestedTimeOffLimitsFieldArray
                    register={register}
                    fields={field.limits}
                    nestIndex={index}
                    control={control}
                  />
                </Flex>
              );
            })}
          </ElementGroup>
        </>
      )}
    </Flex>
  );
};

export const TimeOffLimitsGroupFieldArray = React.memo(TimeOffLimits);
