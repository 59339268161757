import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Switch, useHistory } from 'react-router';
import { t, Trans } from '@lingui/macro';
import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';

import { KioskApp } from 'Kiosk/components/KioskApp';
import { AuthorizedRoute } from 'routing/AuthorizedRoute';
import { PATH, TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { APP_NAME } from 'constants/common';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { QueryParams } from 'constants/queryParams';
import { useQuery } from 'hooks/useQuery/useQuery';

import { KioskSettingsInitializer } from './components/KioskSettingsInitializer';
import { settingsAtom } from './state/settingsState';
import { CameraRequiredErrorPage } from './pages/errors/CameraRequired/CameraRequiredErrorPage';
import { LocationRequiredErrorPage } from './pages/errors/LocationRequired/LocationRequiredErrorPage';
import { PermissionObserver } from './observers/PermissionObserver';
import { GeoLocationProvider } from './context/GeoLocationProvider';
import { IdleObserver } from './observers/IdleObserver';

export const Kiosk = (): React.ReactElement => {
  const settings = useRecoilValue(settingsAtom);
  const language = useRecoilValue(languageSelector);

  const { addSnackbar } = useSnackbar();
  const { replace } = useHistory();

  const urlParams = useQuery();
  const alert = urlParams.get(QueryParams.Alert);

  useEffect(() => {
    if (alert === 'alert_kiosk_session_refresh') {
      addSnackbar({
        message: i18n._(
          t({
            id: 'kiosk.camera_view.kiosk_refresh',
            message: "Your organization's settings have changed, we needed to refresh your session.",
          }),
        ),
        variant: 'warning',
        duration: 8000,
      });

      replace(TO.KIOSK[language]);
    }
  }, [addSnackbar, alert, language, replace, urlParams]);

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'kiosk.page_title', message: `Kiosk - ${APP_NAME}` }))}</title>
      </Helmet>

      <KioskSettingsInitializer>
        <GeoLocationProvider>
          {!settings ? (
            <LoadingSplash message={<Trans id="global.loading" />} />
          ) : (
            <>
              <Switch>
                <AuthorizedRoute path={PATH.KIOSK__CAMERA_PERMISSION[language]} component={CameraRequiredErrorPage} />
                <AuthorizedRoute
                  path={PATH.KIOSK__LOCATION_PERMISSION[language]}
                  component={LocationRequiredErrorPage}
                />
                <AuthorizedRoute path={PATH.KIOSK[language]}>
                  <KioskApp settings={settings} />
                </AuthorizedRoute>
              </Switch>

              <PermissionObserver requireLocation={settings.isLocationRequired} />
              <IdleObserver load={settings.isSleepModeEnabled} timerInMinutes={settings.sleepModeTimeOutMinutes} />
            </>
          )}
        </GeoLocationProvider>
      </KioskSettingsInitializer>
    </>
  );
};
