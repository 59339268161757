import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { QueryResponse } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';

import {
  ImportHoliday,
  ImportHolidayPreview,
  ImportHolidaysProps,
  ImportPreviewHolidaysActionProps,
  ImportPreviewHolidaysResponse,
} from 'api/actions/holidays/holidaysActions.types';
import { FetchOrganizationSettingsResponse, Holiday } from 'api/actions/settings/settingsActions.types';
import { Languages } from 'api/actions/userSession/userSessionActions.types';
import { StickyList } from 'components/StickyList/StickyList';
import { StickyListProps } from 'components/StickyList/types';
import { CountrySelect } from 'components/ui/CountrySelect/CountrySelect';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { selectAllAtomFamily, selectedRowsAtomFamily } from 'state/list';
import { languageSelector } from 'state/recoilState';
import { payloadSelectorFamily } from 'state/settings';
import { dateTime } from 'utils/dateTime';

type Props = {
  onSubmit: (data: ImportHolidaysProps) => Promise<void>;
  mutate: (action: ImportPreviewHolidaysActionProps) => Promise<QueryResponse<ImportPreviewHolidaysResponse>>;
  setLoading: (loading: boolean) => void;
  selectedHolidays: string[];
};

export const ImportHolidaysForm = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, mutate, setLoading, selectedHolidays }: Props, ref): React.ReactElement => {
    useLingui();
    const { year } = useParams<{ year: string }>();
    const { handleSubmit } = useForm();
    const language = useRecoilValue(languageSelector);
    const organizationPayload = useRecoilValue<FetchOrganizationSettingsResponse>(
      payloadSelectorFamily('ORGANIZATION'),
    );
    const resetSelectedHolidays = useResetRecoilState(selectedRowsAtomFamily('HOLIDAYS_FOR'));
    const resetAllSelectedHolidays = useResetRecoilState(selectAllAtomFamily('HOLIDAYS_FOR'));

    const [holidayList, setHolidayList] = useState<StickyListProps<ImportHoliday>['list'] | null>(null);
    const [importData, setImportData] = useState<ImportPreviewHolidaysActionProps | null>(null);
    const [pickedLocation, setPickedLocation] = useState<string>(organizationPayload.countryCode);

    const languageRef = useRef<Languages>(Languages[language]);
    const selectedLocationRef = useRef<HTMLInputElement | null>(null);

    const columns: StickyListProps<Holiday>['columns'] = [
      {
        key: 'dateUnix',
        title: t({ id: 'global.date' }),
        sortableValue: (date: Holiday['dateUnix']) => `${date}`,
        customCellRenderer: (dateUnix: Holiday['dateUnix']) => {
          const displayDate = dateTime(dateUnix).format('MMM DD');
          const displayDateUpperCase = displayDate.replace(/^./, (str) => str.toUpperCase());

          return <TextEllipsis title={displayDateUpperCase}>{displayDateUpperCase}</TextEllipsis>;
        },
        columnGrow: 1,
      },
      {
        key: 'name',
        title: t({ id: 'global.forms.name' }),
        sortableValue: (name: Holiday['name']) => `${name}`,
        customCellRenderer: (name: Holiday['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
        columnGrow: 2,
      },
    ];

    const handleSubmitCallback = useCallback(() => {
      const submitData: ImportHoliday[] = [];

      if (holidayList) {
        holidayList.forEach((item) => {
          const holiday = item as ImportHolidayPreview;
          if (_.includes(selectedHolidays, holiday.id)) {
            submitData.push({
              holidayId: holiday.id,
              ..._.omit(holiday, ['id']),
            });
          }
        });
      }

      onSubmit(submitData);
    }, [holidayList, onSubmit, selectedHolidays]);

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    const handleOnBlur = () => {
      if (selectedLocationRef.current && selectedLocationRef.current.value) {
        setPickedLocation(selectedLocationRef.current.value);
        resetSelectedHolidays();
        resetAllSelectedHolidays();
      }
    };

    const handleDataImport = useCallback(async () => {
      if (importData) {
        const { payload } = await mutate(importData);

        if (payload) setHolidayList(payload);
      }
    }, [importData, mutate]);

    useEffect(() => {
      handleDataImport();
    }, [handleDataImport]);

    useEffect(() => {
      resetSelectedHolidays();
      resetAllSelectedHolidays();
    }, [resetAllSelectedHolidays, resetSelectedHolidays]);

    useEffect(() => {
      setImportData({
        year: parseInt(year, 10),
        language: languageRef.current,
        calendarCountryCode: pickedLocation,
      });
    }, [pickedLocation, year]);

    return (
      <form ref={ref} onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)}>
        <CountrySelect
          ref={selectedLocationRef}
          id="holidays"
          label={t({ id: 'settings.holidays.holidays_for', message: 'Holidays for' })}
          placeholder={t({ id: 'settings.holidays.holidays_for' })}
          size="sm"
          defaultValue={pickedLocation}
          onBlur={handleOnBlur}
          searchable
        />
        <Flex sx={{ minHeight: '300px', mt: 2 }}>
          <StickyList
            name="IMPORT_HOLIDAYS"
            emptyListMessage={!holidayList && t({ id: 'global.loading' })}
            columns={columns}
            list={holidayList || []}
            select="checkbox"
            showHeader
            variant="inverted"
          />
        </Flex>
      </form>
    );
  },
);
