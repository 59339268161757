export const ADD_REQUEST_PICK_TEAMMATES_LIST = 'ADD_REQUEST_PICK_TEAMMATES_LIST';
export const REQUESTS_LIST = 'REQUESTS_LIST';
export const REQUEST_OVERVIEW_LIST = 'REQUEST_OVERVIEW_LIST';
export const TIME_EVENTS_HISTORY_LIST = 'TIME_EVENTS_HISTORY_LIST';
export const CUSTOM_TIME_OFF_HISTORY_LIST = 'CUSTOM_TIME_OFF_HISTORY_LIST';
export const BUSINESS_HISTORY_LIST = 'BUSINESS_HISTORY_LIST';
export const IS_END_STATUS = '_IS_END_STATUS';
export const OVER_TIME_PAYMENT_ID = '496c593e-4bb7-48a1-a010-b1268e5e68a1';
export const INPUT_DEBOUNCE_TIME = 200;
export const VALIDATION_MAX_TIME_IN_SECONDS = 24 * 60 * 60 - 60;
