export interface AccountInfoModules {
  [key: string]: boolean;
}

export interface AccountInfoCompany {
  companyName: string;
  industry: string;
  industrySubcategory?: string;
  timeZoneId: string;
  countryCode: string;
}

export interface AccountInfoAbout {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}

export type PostEmployerAccountInfoPayload = AccountInfoAbout &
  Omit<AccountInfoCompany, 'industry' | 'industrySubcategory'> & {
    industry: number;
    industrySubcategory?: number;
  };

export enum PostEmployerAccountInfoErrors {
  InvalidTimeZoneId = 2004,
}

export type PostEmployerAccountInfoResponse = Record<string, unknown>;

export type PostEmployeeAccountRegisterPayload = {
  token: string;
  userId: string;
  password: string;
  newsletterSubscription: boolean;
};

export type PostEmployeeAccountRegisterResponse = Record<string, unknown>;

export type GetEmployeeAccountRegisterPayload = Pick<PostEmployeeAccountRegisterPayload, 'token' | 'userId'>;

export type GetEmployeeAccountRegisterResponse = {
  fullName: string;
};
