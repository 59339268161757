import React, { useCallback, useRef, useMemo, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { RegisterOptions, useForm } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useMutation } from 'react-fetching-library';
import { useTimer } from 'use-timer';
import { Status } from 'use-timer/lib/types';
import { useLingui } from '@lingui/react';

import {
  BusinessTripSettingsActionProps,
  FetchRequestsSettingsResponse,
} from 'api/actions/settings/settingsActions.types';
import { payloadSelectorFamily, resetFormButtonAtom, rolesApproveRequestsSelectOptionsSelector } from 'state/settings';
import { Paths } from '../../utils/createUnionOfTypeKeys';
import { createEvent } from 'utils/createEvent';
import { Switch } from 'components/ui/Switch';
import { Divider } from 'components/Divider/Divider';
import { OptionLabel } from 'layouts/Settings/OptionLabel';
import { Select } from 'components/ui/Select/Select';
import { HeadingError } from 'layouts/Settings/HeadingError';
import { businessTripSettingsAction } from 'api/actions/settings/settingsActions';
import { mergeRefs } from 'utils/mergeRefs';
import { TIMER_END_TIME, TIMER_INTERVAL } from '../constants';
import { addSnackbar } from 'SnackbarHub/actions';

type keys = Paths<BusinessTripSettingsActionProps>;

type Props = {
  payload: BusinessTripSettingsActionProps;
  blockLocationPathnameRef: React.MutableRefObject<string | null>;
  businessTripShouldBlockTransitionRef: React.MutableRefObject<boolean>;
  businessTripFormPendingRef: React.MutableRefObject<boolean>;
  businessTripStatusRef: React.MutableRefObject<Status>;
  setTransitionPage: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BusinessTrip = React.forwardRef(
  (
    {
      payload,
      blockLocationPathnameRef,
      businessTripShouldBlockTransitionRef,
      businessTripFormPendingRef,
      businessTripStatusRef,
      setTransitionPage,
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const setPayload = useSetRecoilState<FetchRequestsSettingsResponse>(payloadSelectorFamily('REQUESTS'));
    const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
    const rolesSelectOptions = useRecoilValue(rolesApproveRequestsSelectOptionsSelector);
    const { mutate } = useMutation(businessTripSettingsAction);

    const formRef = useRef<HTMLFormElement | null>(null);
    const mutationDataRef = useRef<BusinessTripSettingsActionProps>(payload);

    const { twoStepApprovalFlow, firstStepRoleId, secondStepRoleId } = payload.businessTripsApprovalSettings;
    const payloadComparisonObject: BusinessTripSettingsActionProps = useMemo(
      () => ({ ...payload, businessTripsApprovalSettings: { twoStepApprovalFlow, firstStepRoleId, secondStepRoleId } }),
      [firstStepRoleId, payload, secondStepRoleId, twoStepApprovalFlow],
    );

    const {
      register,
      handleSubmit,
      setError,
      watch,
      reset: resetForm,
      formState: { errors },
    } = useForm({
      defaultValues: payload,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const twoStepApprovalWatch = watch('businessTripsApprovalSettings.twoStepApprovalFlow');
    const firstStepRoleIdWatch = watch('businessTripsApprovalSettings.firstStepRoleId');
    const secondStepRoleIdWatch = watch('businessTripsApprovalSettings.secondStepRoleId');

    const dispatchSubmitEvent = useCallback(() => {
      const submitEvent = createEvent('submit');
      businessTripFormPendingRef.current = true;
      formRef.current?.dispatchEvent(submitEvent);
    }, [businessTripFormPendingRef]);

    const { start, reset, status } = useTimer({
      endTime: TIMER_END_TIME,
      interval: TIMER_INTERVAL,
      onTimeOver: () => dispatchSubmitEvent(),
    });

    const handleOnChange = useCallback(() => {
      businessTripShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, businessTripShouldBlockTransitionRef, reset, start]);

    const handleOnBlur = useCallback(() => {
      businessTripShouldBlockTransitionRef.current = true;
      blockLocationPathnameRef.current = null;
      reset();
      start();
    }, [blockLocationPathnameRef, businessTripShouldBlockTransitionRef, reset, start]);

    const registerOnChange = (registerName: keys, registerOptions?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          handleOnChange();
        },
      };
    };

    const registerOnBlur = useCallback(
      (registerName: keys, registerOptions?: RegisterOptions) => {
        const { onBlur, ...restRegister } = register(registerName, registerOptions);

        return {
          ...restRegister,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            handleOnBlur();
          },
        };
      },
      [handleOnBlur, register],
    );

    const handleFormReset = useCallback(() => {
      resetForm({
        ...payload,
      });

      businessTripShouldBlockTransitionRef.current = false;
      businessTripFormPendingRef.current = false;
    }, [businessTripFormPendingRef, businessTripShouldBlockTransitionRef, payload, resetForm]);

    const handleValidation = useCallback(
      (data: BusinessTripSettingsActionProps) => {
        const dataFirstStepRoleId = data.businessTripsApprovalSettings.firstStepRoleId;
        const dataSecondStepRoleId = data.businessTripsApprovalSettings.secondStepRoleId;
        const dataTwoStepApprovalFlow = data.businessTripsApprovalSettings.twoStepApprovalFlow;

        if (dataTwoStepApprovalFlow && (!dataFirstStepRoleId || !dataSecondStepRoleId)) {
          setError('businessTripsApprovalSettings.firstStepRoleId', {
            type: 'validate',
          });
          setError('businessTripsApprovalSettings.secondStepRoleId', {
            type: 'validate',
            message: t({ id: 'settings.forms.step_empty', message: 'Either step cannot be empty' }),
          });

          return false;
        }

        return true;
      },
      [setError],
    );

    const handleSubmitCallback = useCallback(
      async (data: BusinessTripSettingsActionProps) => {
        const validation = handleValidation(data);
        const businessTripResetObject = { name: 'BUSINESS_TRIP_CALLBACK', callback: handleFormReset };
        const dataComparisonObject: BusinessTripSettingsActionProps = {
          ...data,
          businessTripsApprovalSettings: {
            twoStepApprovalFlow: data.businessTripsApprovalSettings.twoStepApprovalFlow,
            firstStepRoleId: data.businessTripsApprovalSettings.firstStepRoleId?.length
              ? data.businessTripsApprovalSettings.firstStepRoleId
              : undefined,
            secondStepRoleId: data.businessTripsApprovalSettings.secondStepRoleId?.length
              ? data.businessTripsApprovalSettings.secondStepRoleId
              : undefined,
          },
        };
        reset();

        if (validation) {
          setResetCallbacks((prevState) => {
            if (prevState) {
              const newState = _.reject(prevState, (item) => item.name === businessTripResetObject.name);
              return newState.length ? newState : null;
            }
            return prevState;
          });
        }

        if (!_.isEqual(dataComparisonObject, payloadComparisonObject) && validation) {
          const { error } = await mutate(dataComparisonObject);

          if (!error) {
            setPayload((prevPayload) => ({ ...prevPayload, ...dataComparisonObject }));
            mutationDataRef.current = dataComparisonObject;
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            });
          }

          if (error) {
            addSnackbar({
              message: t({
                id: 'settings.forms.submit_fail',
              }),
              variant: 'danger',
            });
            setResetCallbacks((prevState) =>
              !prevState ? [businessTripResetObject] : [...prevState, businessTripResetObject],
            );
            return;
          }
        }

        if (!validation) {
          setResetCallbacks((prevState) =>
            !prevState ? [businessTripResetObject] : [...prevState, businessTripResetObject],
          );
          return;
        }

        businessTripShouldBlockTransitionRef.current = false;
        businessTripFormPendingRef.current = false;
        setTransitionPage(true);
      },
      [
        businessTripFormPendingRef,
        businessTripShouldBlockTransitionRef,
        handleFormReset,
        handleValidation,
        mutate,
        payloadComparisonObject,
        reset,
        setPayload,
        setResetCallbacks,
        setTransitionPage,
      ],
    );

    useEffect(() => {
      if (!_.isEqual(mutationDataRef.current, payloadComparisonObject)) handleFormReset();
    }, [handleFormReset, payload, payloadComparisonObject]);

    useEffect(() => {
      businessTripStatusRef.current = status;
    }, [status, businessTripStatusRef]);

    useEffect(() => () => setResetCallbacks(null), [setResetCallbacks]);

    return (
      <form ref={mergeRefs([formRef, ref])} onSubmit={handleSubmit(handleSubmitCallback)}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <HeadingError
            label={t({
              id: 'requests_settings.heading.business_trip',
              message: 'Business Trip',
            })}
            errorMessage={errors.businessTripsApprovalSettings?.secondStepRoleId?.message}
          />
          <Flex sx={{ flexDirection: 'column', gap: '0.75rem' }}>
            <Switch
              {...registerOnChange('allowRequestForBusinessTrips')}
              label={t({
                id: 'requests_settings.allow_request_for_business_trips.label',
                message: 'Allow request for business trips',
              })}
              size="sm"
              bold
              withDivider
            />
            <Switch
              {...registerOnChange('disableLocationRestrictionOnBusinessTrip')}
              label={t({
                id: 'requests_settings.disable_location_restriction_on_business_trip.label',
                message: 'Turn off location limits during business trips',
              })}
              size="sm"
              bold
              withDivider
            />
            <Switch
              {...registerOnChange('businessTripsApprovalSettings.twoStepApprovalFlow')}
              label={t({
                id: 'requests_settings.two_step_approval_flow.label',
              })}
              additionalInfo={
                <Trans id="requests_settings.two_step_approval_flow.additionalInfo">
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    Changing this setting will affect all not accepted requests
                  </Text>
                  .
                </Trans>
              }
              size="sm"
            />
            {twoStepApprovalWatch && (
              <>
                <OptionLabel
                  label={t({
                    id: 'requests_settings.first_step_role_id.label',
                  })}
                  apendWith={
                    <>
                      {rolesSelectOptions && (
                        <Select
                          {...registerOnBlur('businessTripsApprovalSettings.firstStepRoleId')}
                          id="businessTripsApprovalSettings.firstStepRoleId"
                          placeholder={t({
                            id: 'requests_settings.first_step_role_id.label',
                          })}
                          options={_.filter(rolesSelectOptions, (item) => item.id !== secondStepRoleIdWatch)}
                          size="sm"
                          error={!!errors.businessTripsApprovalSettings?.firstStepRoleId}
                          sx={{ maxWidth: '188px' }}
                        />
                      )}
                    </>
                  }
                />
                <OptionLabel
                  label={t({
                    id: 'requests_settings.second_step_role_id.label',
                  })}
                  apendWith={
                    <>
                      {rolesSelectOptions && (
                        <Select
                          {...registerOnBlur('businessTripsApprovalSettings.secondStepRoleId')}
                          id="businessTripsApprovalSettings.secondStepRoleId"
                          placeholder={t({
                            id: 'requests_settings.second_step_role_id.label',
                          })}
                          options={_.filter(rolesSelectOptions, (item) => item.id !== firstStepRoleIdWatch)}
                          error={!!errors.businessTripsApprovalSettings?.secondStepRoleId}
                          size="sm"
                          sx={{ maxWidth: '188px' }}
                        />
                      )}
                    </>
                  }
                />
              </>
            )}
            <Divider />
          </Flex>
        </Flex>
      </form>
    );
  },
);

export const MemoizedBusinessTrip = React.memo(BusinessTrip);
