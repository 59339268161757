export type FetchWebhooksResponse = Webhook[];

export type FetchWebhookActionProps = {
  id: string;
};
export type FetchWebhookResponse = Webhook;

export type FetchAvailableWebhooksResponse = WebhookType[];

export type AddWebhookActionProps = Omit<Webhook, 'id'>;

export type AddWebhookResponse = {
  a: string;
};

export type EditWebhookActionProps = Webhook;

export type EditWebhookResponse = {
  a: string;
};

// TODO: Check after backend changes for key name.
export type DeleteWebhookActionProps = string[];

export type Webhook = {
  id: string;
  type: WebhookType;
  url: string;
  token: string;
};

export enum WebhookType {
  TimeOffAdd = 0,
  TimeOffEdit = 1,
  TimeOffDelete = 2,
}
