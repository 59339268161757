import React from 'react';
import { Text } from 'theme-ui';

type Props = {
  title: string;
};

export const GroupTitle = ({ title }: Props): React.ReactElement => (
  <Text
    className="group-title"
    as="span"
    sx={{ pl: 3, textAlign: 'left', fontWeight: 'bold', width: '100%', display: 'block' }}
  >
    {title}
  </Text>
);
