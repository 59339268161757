import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

type Props = {
  duration: number;
  durationProgress: number;
  barSx?: ThemeUIStyleObject;
  wrapperSx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  barSx: undefined,
  wrapperSx: undefined,
};

const ProgressBar = ({ duration, durationProgress, barSx, wrapperSx }: Props): React.ReactElement => {
  const getCurrentFill = () => `${Math.floor((durationProgress * 100) / duration)}%`;

  return (
    <Box
      sx={{
        width: '90%',
        mx: '5%',
        bg: 'rgba(255,255,255, 0.2)',
        borderRadius: 'pill',
        ...(wrapperSx && wrapperSx),
      }}
    >
      <Box
        sx={{
          height: '5px',
          bg: 'whites1',
          borderRadius: 'pill',
          width: getCurrentFill(),
          transition: 'width linear 1s',
          ...(barSx && barSx),
        }}
      />
    </Box>
  );
};

ProgressBar.defaultProps = defaultProps;

export const MemorizedProgressBar = React.memo(ProgressBar);
