import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Flex } from 'theme-ui';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { AddHolidayActionProps, EditHolidayActionProps } from 'api/actions/holidays/holidaysActions.types';
import { DatePicker } from 'components/ui/DatePicker/DatePicker';
import { TextInput } from 'components/ui/TextInput';

type Props = {
  onSubmit: (data: AddHolidayActionProps) => Promise<void>;
  setLoading: (loading: boolean) => void;
  defaultValues?: Omit<AddHolidayActionProps, 'isDateBound'>;
};

const defaultProps = {
  defaultValues: undefined,
};

export const AddEditHolidayForm = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, setLoading, defaultValues }: Props, ref): React.ReactElement => {
    useLingui();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const handleSubmitCallback = useCallback(
      (data: AddHolidayActionProps | EditHolidayActionProps) => {
        onSubmit(data);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    return (
      <form ref={ref} onSubmit={handleSubmit(handleSubmitCallback, handleSubmitErrorCallback)}>
        <Flex sx={{ gap: 2 }}>
          <DatePicker
            {...register('dateUnix', { valueAsNumber: true, required: t({ id: 'global.forms.required' }) })}
            id="dateUnix"
            label={t({ id: 'global.date' })}
            placeholder={t({ id: 'settings.holidays.pick_a_date', message: 'Pick a date' })}
            error={!!errors.dateUnix}
            errorMessage={errors.dateUnix?.message}
            size="sm"
            isStatic
          />
          <TextInput
            {...register('name', { required: t({ id: 'global.forms.required' }) })}
            id="name"
            label={t({ id: 'global.forms.name' })}
            placeholder={t({ id: 'global.forms.name' })}
            error={!!errors.name}
            errorMessage={errors.name?.message}
            size="sm"
          />
        </Flex>
      </form>
    );
  },
);

AddEditHolidayForm.defaultProps = defaultProps;
