/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-plusplus */
import { AnnotatedPrediction } from '@tensorflow-models/face-landmarks-detection/dist/mediapipe-facemesh';

import {
  FACE_BOUNDING_THRESHOLD_AXIS_X,
  FACE_BOUNDING_THRESHOLD_AXIS_Y,
  INITIAL_FACE_SCAN_POINTS_NUMBER,
  TRIANGULATION,
} from './constants/constants';

export const getBoundingBoxForIsFaceInPlace = (
  video: HTMLVideoElement,
): {
  boundingBox: {
    topLeft: number[];
    bottomRight: number[];
  };
  boundingBoxInvert: {
    topLeft: number[];
    bottomRight: number[];
  };
  x: number;
  y: number;
  width: number;
  height: number;
} => {
  const { videoWidth } = video;
  const { videoHeight } = video;
  const isLandscape = video.videoHeight <= video.videoWidth;

  const column = videoWidth / 12;
  const row = videoHeight / 12;

  const width = isLandscape ? column * 4 : column * 8;
  const height = isLandscape ? row * 6 : row * 7;
  const x = isLandscape ? column * 4 : column * 2;
  const y = isLandscape ? row * 3 : row * 2.5;

  const topLeftX = x;
  const topLeftY = y;
  const bottomRightX = x + width;
  const bottomRightY = y + height;

  return {
    boundingBox: {
      topLeft: [topLeftX, topLeftY],
      bottomRight: [bottomRightX, bottomRightY],
    },
    boundingBoxInvert: {
      topLeft: [bottomRightX, topLeftY],
      bottomRight: [topLeftX, bottomRightY],
    },
    x,
    y,
    width,
    height,
  };
};

const generateMinMax = (number: number, threshold: number) => [number - threshold, number + threshold];

export const isFaceInPlace = (predictions: AnnotatedPrediction[], source: HTMLVideoElement): boolean => {
  // FIXME: if model is flippedHorizonal the boundingBox X and Y are also flipped, left is right etc.
  let cornerCheck = [false];

  function checkIfTrue(value: boolean) {
    return value === true;
  }

  if (predictions.length > 0) {
    predictions.forEach((prediction) => {
      cornerCheck = [];
      const { boundingBox } = prediction;
      const boundingBoxForFace = getBoundingBoxForIsFaceInPlace(source);

      Object.entries(boundingBox).forEach((corner) => {
        const cornerName = corner[0] as 'topLeft' | 'bottomRight';
        const cornerCords = corner[1];

        for (let i = 0; i < cornerCords.length; i++) {
          const minMax = generateMinMax(
            boundingBoxForFace.boundingBoxInvert[cornerName][i],
            i === 0 ? FACE_BOUNDING_THRESHOLD_AXIS_X : FACE_BOUNDING_THRESHOLD_AXIS_Y,
          );

          if (corner[1][i] >= minMax[0] && corner[1][i] <= minMax[1]) {
            cornerCheck.push(true);
          } else {
            cornerCheck.push(false);
          }
        }
      });
    });
  }

  return cornerCheck.every(checkIfTrue);
};

type getPointsToScanProps = {
  source: HTMLVideoElement;
  pointsQuantity?: number;
  pointsDistance?: number;
};

const getPointsToScan = ({
  source,
  pointsDistance = 0.18,
  pointsQuantity = INITIAL_FACE_SCAN_POINTS_NUMBER,
}: getPointsToScanProps) => {
  const boundingBoxForFace = getBoundingBoxForIsFaceInPlace(source);
  const { x, y, width, height } = boundingBoxForFace;

  const centerX = x + width / 2;
  const centerY = y + height / 2;

  const pointsAngle = ((360 / pointsQuantity) * Math.PI) / 180;
  const isLandscape = height <= width;
  const radius = !isLandscape ? height / 2 : width / 2;

  const pointsToScan = [...Array(pointsQuantity).keys()].map((value, index) => {
    const realX = centerX + radius * Math.sin(pointsAngle * index) * pointsDistance;
    const realY = centerY + radius * Math.cos(pointsAngle * index) * pointsDistance;

    const drawX = centerX + radius * Math.sin(pointsAngle * index);
    const drawY = centerY + radius * Math.cos(pointsAngle * index);

    return { key: index, point: [realX, realY], drawPoint: [drawX, drawY] };
  });

  return {
    pointsToScan,
    centerX,
    centerY,
  };
};

export const getAxisPointsToScan = (
  source: HTMLVideoElement,
): {
  points: {
    point: number[];
    drawPoint: number[];
    axis: number[];
    axisBoundary: {
      min: number[];
      max: number[];
    };
    errorBoundary: number;
  }[];
  centerX: number;
  centerY: number;
} => {
  const { centerX, centerY, pointsToScan } = getPointsToScan({ source });

  const points = pointsToScan.map(({ key, point, drawPoint }) => {
    const errorBoundary = 10;
    const axisX = centerY - point[1];
    const axisY = point[0] - centerX;

    return {
      key,
      point,
      drawPoint,
      axis: [axisX, axisY],
      axisBoundary: {
        min: [axisX - errorBoundary, axisY - errorBoundary],
        max: [axisX + errorBoundary, axisY + errorBoundary],
      },
      errorBoundary,
    };
  });

  return { points, centerX, centerY };
};

export const getAxisXY = (predictions: AnnotatedPrediction[]): { degreeX: number; degreeY: number; tilt: number } => {
  let degreeX = 0;
  let degreeY = 0;
  let tilt = 0;

  if (predictions.length > 0) {
    predictions.forEach((prediction) => {
      const { annotations } = prediction;

      const [topX, topY] = annotations.noseTip[0];
      const [rightX, rightY] = annotations.rightCheek[0];
      const [leftX, leftY] = annotations.leftCheek[0];

      const bottomX = (rightX + leftX) / 2;
      const bottomY = (rightY + leftY) / 2;

      tilt = Math.atan((leftY - rightY) / (leftX - rightX)) * (180 / Math.PI);
      degreeY = topX - bottomX;
      degreeX = bottomY - topY;

      if (degreeX < 0) {
        // As down rotation is less noticibale,
        // we are multiplying it by [number] to keep the degree rotation scale the same for all axises
        degreeX *= 2;
      }
    });
  }

  return {
    degreeX,
    degreeY,
    tilt,
  };
};

//
//
//
// DRAW FUNCTIONS
// __FOR DEBUGGING
//
//
//

export const drawPointsToScan = (
  ctx: CanvasRenderingContext2D,
  source: HTMLVideoElement,
  drawScanningAreas = false,
): void => {
  const { pointsToScan } = getPointsToScan({ source });

  // console.log(pointsToScan);

  pointsToScan.forEach(({ drawPoint }) => {
    ctx.beginPath();
    ctx.fillStyle = 'black';
    ctx.arc(drawPoint[0], drawPoint[1], 5, 0, 2 * Math.PI);
    ctx.fill();
  });

  if (drawScanningAreas) {
    const { points } = getAxisPointsToScan(source);

    points.forEach((value, index, array) => {
      const { point, errorBoundary } = value;
      const x = point[0] - errorBoundary;
      const y = point[1] - errorBoundary;
      const width = errorBoundary * 2;
      const height = width;

      ctx.beginPath();
      ctx.rect(x, y, width, height);
      ctx.strokeStyle = 'black';
      ctx.stroke();
    });
  }
};

export const drawScannedPoint = (ctx: CanvasRenderingContext2D, x: number, y: number): void => {
  ctx.beginPath();
  ctx.fillStyle = 'green';
  ctx.arc(x, y, 5, 0, 2 * Math.PI);
  ctx.fill();
};

export const drawBoxForCheckIfFaceIsInPlace = (
  ctx: CanvasRenderingContext2D,
  source: HTMLVideoElement,
  drawMinMaxBoundingBox = false,
): void => {
  const { x, y, width, height, boundingBox } = getBoundingBoxForIsFaceInPlace(source);

  ctx.beginPath();
  ctx.rect(x, y, width, height);
  ctx.strokeStyle = 'red';
  ctx.stroke();

  ctx.fillStyle = 'red';
  ctx.beginPath();
  ctx.arc(boundingBox.topLeft[0], boundingBox.topLeft[1], 5, 0, 2 * Math.PI); // Start point
  ctx.fill();
  ctx.beginPath();
  ctx.arc(boundingBox.bottomRight[0], boundingBox.bottomRight[1], 5, 0, 2 * Math.PI); // Start point
  ctx.fill();

  if (drawMinMaxBoundingBox) {
    const minMaxTopLeft = {
      x: generateMinMax(boundingBox.topLeft[0], FACE_BOUNDING_THRESHOLD_AXIS_X),
      y: generateMinMax(boundingBox.topLeft[1], FACE_BOUNDING_THRESHOLD_AXIS_Y),
    };
    const minMaxBottomRight = {
      x: generateMinMax(boundingBox.bottomRight[0], FACE_BOUNDING_THRESHOLD_AXIS_X),
      y: generateMinMax(boundingBox.bottomRight[1], FACE_BOUNDING_THRESHOLD_AXIS_Y),
    };

    ctx.beginPath();
    ctx.rect(
      minMaxTopLeft.x[1],
      minMaxTopLeft.y[1],
      minMaxBottomRight.x[0] - minMaxTopLeft.x[1],
      minMaxBottomRight.y[0] - minMaxTopLeft.y[1],
    );
    ctx.rect(
      minMaxTopLeft.x[0],
      minMaxTopLeft.y[0],
      minMaxBottomRight.x[1] - minMaxTopLeft.x[0],
      minMaxBottomRight.y[1] - minMaxTopLeft.y[0],
    );
    ctx.strokeStyle = 'pink';
    ctx.stroke();
  }
};

// Triangle drawing method
const drawPath = (ctx: CanvasRenderingContext2D, points: number[][], closePath: boolean) => {
  const region = new Path2D();
  region.moveTo(points[0][0], points[0][1]);
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    region.lineTo(point[0], point[1]);
  }

  if (closePath) {
    region.closePath();
  }
  ctx.strokeStyle = 'aqua';
  ctx.stroke(region);
};

// Drawing Mesh
export const drawMesh = (predictions: AnnotatedPrediction[], ctx: CanvasRenderingContext2D): void => {
  if (predictions.length > 0) {
    predictions.forEach((prediction) => {
      const keypoints = prediction.scaledMesh as number[][];

      //  Draw Triangles
      for (let i = 0; i < TRIANGULATION.length / 3; i++) {
        // Get sets of three keypoints for the triangle
        const points = [TRIANGULATION[i * 3], TRIANGULATION[i * 3 + 1], TRIANGULATION[i * 3 + 2]].map(
          (index) => keypoints[index],
        );
        //  Draw triangle
        drawPath(ctx, points, true);
      }

      // Draw Dots
      for (let i = 0; i < keypoints.length; i++) {
        const x = keypoints[i][0];
        const y = keypoints[i][1];

        ctx.beginPath();
        ctx.arc(x, y, 1, 0, 3 * Math.PI);
        ctx.fillStyle = 'aqua';
        ctx.fill();
      }
    });
  }
};

export const drawBoundingBox = (predictions: AnnotatedPrediction[], ctx: CanvasRenderingContext2D): void => {
  if (predictions.length > 0) {
    predictions.forEach((prediction) => {
      const { boundingBox } = prediction;
      const { topLeft, bottomRight } = boundingBox as { [key: string]: number[] };

      const width = topLeft[0] - bottomRight[0];
      const height = bottomRight[1] - topLeft[1];

      ctx.beginPath();
      ctx.rect(bottomRight[0], topLeft[1], width, height);
      ctx.strokeStyle = 'aqua';
      ctx.stroke();

      ctx.fillStyle = 'blue';
      ctx.beginPath();
      ctx.arc(topLeft[0], topLeft[1], 5, 0, 2 * Math.PI);
      ctx.fill();
      ctx.beginPath();
      ctx.arc(bottomRight[0], bottomRight[1], 5, 0, 2 * Math.PI);
      ctx.fill();
    });
  }
};

export const drawPointsForAxisXY = (predictions: AnnotatedPrediction[], ctx: CanvasRenderingContext2D): void => {
  if (predictions.length > 0) {
    predictions.forEach((prediction) => {
      const { annotations } = prediction;

      const [topX, topY] = annotations.noseTip[0];
      const [rightX, rightY] = annotations.rightCheek[0];
      const [leftX, leftY] = annotations.leftCheek[0];
      const bottomX = (rightX + leftX) / 2;
      const bottomY = (rightY + leftY) / 2;

      ctx.fillStyle = 'blue';
      ctx.beginPath();
      ctx.arc(rightX, rightY, 2, 0, 2 * Math.PI);
      ctx.fill();
      ctx.beginPath();
      ctx.arc(leftX, leftY, 2, 0, 2 * Math.PI);
      ctx.fill();
      ctx.beginPath();
      ctx.arc(bottomX, bottomY, 2, 0, 2 * Math.PI);
      ctx.fill();

      ctx.fillStyle = 'red';
      ctx.beginPath();
      ctx.arc(topX, topY, 2, 0, 2 * Math.PI);
      ctx.fill();
    });
  }
};

export const drawScannedPoints = (
  scannedPoints: number[][],
  ctx: CanvasRenderingContext2D,
  source: HTMLVideoElement,
): void => {
  if (scannedPoints.length > 0) {
    const { pointsToScan } = getPointsToScan({ source });
    pointsToScan.forEach(({ point, drawPoint }) => {
      scannedPoints.some((value) => {
        if (value[0] === point[0] && value[1] === point[1]) {
          ctx.fillStyle = 'green';
          ctx.beginPath();
          ctx.arc(drawPoint[0], drawPoint[1], 10, 0, 2 * Math.PI); // Start point
          ctx.fill();
        }

        return null;
      });
    });
  }
};
