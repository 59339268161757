import React, { useState } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { languageSelector } from 'state/recoilState';
import { PATH } from 'constants/routes';
import { useModal } from 'hooks/useModal/useModal';

import { Navigation } from './Navigation';
import { ResendInvitation } from './ResendInvitation';
import { SetPosition } from './SetPosition';
import { SetTags } from './SetTags';
import { SetRole } from './SetRole';
import { SetAdvancedDetails } from './SetAdvancedDetails';
import { SetPayDetails } from './SetPayDetails';
import { SetTimeOffLimits } from './SetTimeOffLimits';
import { ClearPosition } from './ClearPosition';
import { ClearTags } from './ClearTags';

export const BulkActionsModal = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  const { state } = useLocation<{ ids?: string[] }>();
  const { ids } = state || {};

  const [selectedEmployeesIds] = useState(ids || []);

  const { baseRoute } = useModal({ wrapperSx: { minHeight: '500px' } });

  if (!selectedEmployeesIds.length) {
    return <Redirect to={baseRoute} />;
  }

  return (
    <Switch>
      <Route exact path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL[language]}`}>
        <Navigation />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__RESEND_INVITATION[language]}`}>
        <ResendInvitation />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__SET_POSITION[language]}`}>
        <SetPosition />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__SET_PAY_DETAILS[language]}`}>
        <SetPayDetails />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__SET_ROLE[language]}`}>
        <SetRole />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__SET_TAGS[language]}`}>
        <SetTags />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__SET_TIME_OFF_LIMITS[language]}`}>
        <SetTimeOffLimits />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__SET_ADVANCED_DETAILS[language]}`}>
        <SetAdvancedDetails />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__CLEAR_POSITION[language]}`}>
        <ClearPosition />
      </Route>
      <Route path={`${baseRoute}${PATH.BULK_ACTIONS_MODAL__CLEAR_TAGS[language]}`}>
        <ClearTags />
      </Route>
    </Switch>
  );
};
