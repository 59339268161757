import { useEffect } from 'react';

import { getCustomEventTargetElement } from 'utils/customEvents';

export const useCustomEventListener = (eventName: string, eventHandler: (data?: unknown) => void): void => {
  useEffect(() => {
    const element = getCustomEventTargetElement();
    const handleEvent = (event: CustomEvent | Event) => {
      const data = (event as CustomEvent).detail;
      eventHandler(data);
    };

    element.addEventListener(eventName, handleEvent, false);

    return () => {
      element.removeEventListener(eventName, handleEvent, false);
    };
  }, [eventHandler, eventName]);
};
