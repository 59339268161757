import { atom } from 'recoil';

import { AccountInfoAbout, AccountInfoCompany } from 'api/actions/user/userActions.types';
import { IndustryListItem, IndustryListItemSubcategory, TimeZoneListItem } from 'api/actions/data/dataActions.types';

export const timeZoneListAtom = atom<TimeZoneListItem[] | undefined>({
  key: 'timeZoneList',
  default: undefined,
});
type IndustryListAtomItemSubcategory = Omit<IndustryListItemSubcategory, 'id'> & { id: string };
type IndustryListAtomItem = Omit<IndustryListItem, 'id' | 'subcategories'> & {
  id: string;
  subcategories: IndustryListAtomItemSubcategory[];
};

export const industryListAtom = atom<IndustryListAtomItem[] | undefined>({
  key: 'industryList',
  default: undefined,
});

export type SignUpFormAtom = Partial<AccountInfoAbout & AccountInfoCompany>;

export const signUpFormAtom = atom<SignUpFormAtom | null>({
  key: 'signUpForm',
  default: null,
});
