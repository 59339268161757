import { motion, MotionProps } from 'framer-motion';
import React from 'react';
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui';

const AnimatedBox = motion(Box);

type Props = BoxOwnProps & {
  isScanned: boolean;
  x: number;
  y: number;
  aspectRatio: number;
};

export const Point = ({ isScanned, x, y, aspectRatio }: Props): React.ReactElement => {
  const wrapperSx: ThemeUIStyleObject = {
    display: 'block',
    size: ['16px', '20px'],
    position: 'absolute',
    top: `calc(50% + ${y * aspectRatio}px)`,
    left: `calc(50% + ${x * aspectRatio}px)`,
    transform: 'translate(-50%, -50%)',
    zIndex: 4,
  };

  const pointSx: ThemeUIStyleObject = {
    display: 'block',
    size: '100%',
    borderRadius: 'circle',
    border: '2px solid',
    borderColor: 'rgba(255,255,255,.35)',
  };

  const pointVariants: MotionProps['variants'] = {
    default: {
      scale: 1,
      backgroundImage: 'linear-gradient(270deg, rgba(255,255,255,.25) 0%, rgba(255,255,255,.35) 100%)',
    },
    scanned: {
      scale: [0.625, 1.75, 1.375],
      backgroundImage: 'linear-gradient(270deg, #3BC647 0%, #2BAA41 100%)',
    },
  };

  return (
    <AnimatedBox
      sx={wrapperSx}
      style={{
        x: '-50%',
        y: '-50%',
      }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{
        opacity: [0, 1, 1, 1],
        scale: [0, 1.15, 0.85, 1],
      }}
      transition={{
        type: 'spring',
        mass: 0.5,
      }}
    >
      <AnimatedBox
        sx={pointSx}
        variants={pointVariants}
        animate={isScanned ? 'scanned' : 'default'}
        transition={{
          type: 'spring',
          damping: 40,
          stiffness: 260,
        }}
      />
    </AnimatedBox>
  );
};

export const MemorizedPoint = React.memo(Point);
